import { useState } from "react";
import {
  Body,
  ConnectContent,
  Container,
  Content,
  CustomizeMenuItem,
  Header,
  InstallContent,
  Section,
  StandardStepConnect,
} from "./styles";
import { MdInstallMobile, MdPhonelinkSetup } from "react-icons/md";
import { RiDownloadCloud2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

export function AppBoltBt() {
  const { t } = useTranslation();

  const sidebarMenuItens = [
    {
      id: "install",
      icon: <MdInstallMobile />,
      label: `${t("appBolt.Download/Instalar")}`,
    },
    {
      id: "connect",
      icon: <MdPhonelinkSetup />,
      label: `${t("appBolt.Conectar Bolt")}`,
    },
  ];

  const [selectItemMenu, setSelectItemMenu] = useState<string>(
    sidebarMenuItens[0].id,
  );

  const handleItemClick = (itemId: string) => {
    setSelectItemMenu(itemId);
  };

  const connectionSteps = [
    {
      step: 1,
      title: `${t("appBolt.Antes de conectar")}`,
      img: "https://s3.amazonaws.com/retina.ibbx.tech/bolt-buetooth-step00.jpg",
      Description: `${t(
        "appBolt.Certifique-se que o Bolt está na última versão de firmware Wifi e seu dispositivo mobile está com a opção de bluetooth habilitada.",
      )}`,
    },
    {
      step: 2,
      title: `${t("appBolt.Buscando Bolt")}`,
      img: "https://s3.amazonaws.com/retina.ibbx.tech/bolt-buetooth-step01.jpg",
      Description: `${t(
        "appBolt.Com o Bolt ligado a energia, e as luzes piscanso em verde, abra o app Bolt Bluetooth e clique na lupa para buscar.",
      )}`,
    },
    {
      step: 3,
      title: `${t("appBolt.Conectar Bolt ao bluetooth")}`,
      img: "https://s3.amazonaws.com/retina.ibbx.tech/bolt-buetooth-step02.jpg",
      Description: `${t(
        "appBolt.Após fazer a busca, os bolts próximos serão exibidos na lista. Para conectar, basta clicar no card referente ao Bolt escolhido. A conexão bluetooth acontece automaticamente.",
      )}`,
    },
    {
      step: 4,
      title: `${t("appBolt.Configurar rede dinâmica")}`,
      img: "https://s3.amazonaws.com/retina.ibbx.tech/bolt-buetooth-step04.jpg",
      Description: `${t(
        "appBolt.Neste passo, basta apenas inserir os dados da rede e clicar em conectar.",
      )}`,
    },
    {
      step: 5,
      title: `${t("appBolt.Configurar rede estática")}`,
      img: "https://s3.amazonaws.com/retina.ibbx.tech/bolt-buetooth-step05.jpg",
      Description: `${t(
        "appBolt.Caso a rede seja estática, basta preencher todos os campos e clicar e conectar.",
      )}`,
    },
    {
      step: 6,
      title: `${t("appBolt.Conectando")}`,
      img: "https://s3.amazonaws.com/retina.ibbx.tech/bolt-buetooth-step01.jpg",
      Description: `${t(
        "appBolt.Neste último passo, o Bolt irá fazer a conexão com a rede wifi. O Aplicativo voltara para a tela de busca, indicando que os dados da rede wifi foram configurados com sucesso no bolt. Agora o Bolt irá fazer a conexão com a rede wifi. Este passo pode demorar entre 2 a 3 minutos. Então é importante aguardar.",
      )}`,
    },
  ];

  const renderInstall = () => {
    return (
      <InstallContent>
        <span>
          {t(
            "appBolt.Para baixar o aplicativo Bolt Bluetooth, basta apontar a câmera do seu celular Android para o qr-code abaixo ou clicar no botão de download.",
          )}
        </span>

        <div>
          {!isMobile && (
            <img
              src="https://s3.amazonaws.com/retina.ibbx.tech/bolt-bluetooth/bolt-bluetooth-apk-bolt-bt-1.3-qr-code.png"
              alt="qr-code bolt bluetooth"
            />
          )}
          <div>
            <Link
              to={
                "https://s3.amazonaws.com/retina.ibbx.tech/bolt-bluetooth/bolt-bluetooth-v1-3-0.apk"
              }
              target={"_blank"}
              style={{ textDecoration: "none" }}
            >
              <button>
                <RiDownloadCloud2Line />
                <span>Download Apk</span>
              </button>
            </Link>
          </div>

          {isMobile && (
            <>
              <p>
                <strong>{t("appBolt.Atenção:")}</strong>
                {t(
                  "appBolt.Disponível apenas para dispositivos Android por meio de um Apk (Android Application Package), não há opção de baixar pela PlayStore.",
                )}
              </p>
            </>
          )}
        </div>
      </InstallContent>
    );
  };

  const renderConnect = () => {
    return (
      <ConnectContent>
        {connectionSteps.map((steps) => (
          <StandardStepConnect>
            <div>
              <strong>{steps.step}</strong>
              <span>{steps.title}</span>
            </div>
            <div>
              <img src={steps.img} alt="" />
            </div>
            <div>
              <p>{steps.Description}</p>
            </div>
          </StandardStepConnect>
        ))}
      </ConnectContent>
    );
  };

  const renderItemsContent: { [key: string]: React.ReactNode } = {
    install: renderInstall(),
    connect: renderConnect(),
  };

  return (
    <Container>
      <Header>
        <img
          src="https://s3.amazonaws.com/retina.ibbx.tech/bolt-bluetooth-logo.webp"
          alt="logo bolt bluetooth"
        />
        <div>
          <strong>
            {t(
              "appBolt.O aplicativo Bolt-bluetooth foi desenvolvido para inserir dados de conexão do Wi-Fi no gateway IBBX BOLT via Bluetooth.",
            )}
          </strong>
          {!isMobile && (
            <>
              <p>
                {t(
                  "appBolt.Aplicativo usado para configurar e conectar o IBBX BOLT a uma rede Wifi por meio de conexão Bluetooth entre device e IBBX BOLT, seja em uma rede com IP dinâmico ou estático e mesmo em redes abertas (sem senha).",
                )}
              </p>
              <p>
                <strong>{t("appBolt.Atenção:")}</strong>
                {t(
                  "appBolt.Disponível apenas para dispositivos Android por meio de um Apk (Android Application Package), não há opção de baixar pela PlayStore.",
                )}
              </p>
            </>
          )}
        </div>
      </Header>

      <Body>
        <Section>
          {sidebarMenuItens.map((item) => (
            <CustomizeMenuItem
              onClick={() => handleItemClick(item.id)}
              selected={selectItemMenu === item.id}
            >
              {item.icon}
              <span>{item.label}</span>
            </CustomizeMenuItem>
          ))}
        </Section>
        <Content>{renderItemsContent[selectItemMenu]}</Content>
      </Body>
    </Container>
  );
}
