import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  & > div {
    overflow-x: hidden;
    width: 100%;
    padding-bottom: 10rem;
    & > div {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
      padding: 16px;
      padding-bottom: 160px;
    }
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${({ theme }) => theme.colors.iconsSecondary};
  }

  @media screen and (max-width: 767px) {
    &::-webkit-scrollbar {
      display: none;
    }
    overflow: auto;
    /* scrollbar-width: none; */
    margin-top: 1rem;
    max-width: 99%;

    & > div > div {
      padding: 0 0.2rem;
    }
  }
`;
