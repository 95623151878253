import styled from "styled-components";
import { css } from "styled-components";

interface IContainer {
  showSidebar?: boolean;
  isFiltered?: boolean;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  grid-area: 3 / 1 / 6 / 6;

  ${(props) =>
    props.showSidebar &&
    css`
      grid-area: 3 / 2 / 6 / 6;
    `}

  & > div {
    overflow-x: hidden;
    width: 100%;

    & > div {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
      /* padding-bottom: 160px; */
    }
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media screen and (max-width: 767px) {
    &::-webkit-scrollbar {
      display: none;
    }

    margin-top: ${(props) => (props.isFiltered ? "1.5rem" : "0.2rem")};
  }
  @media screen and (min-width: 799px) and (max-width: 826px) {
    & > div > div {
      padding: 0px;
    }
  }
`;
