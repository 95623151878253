import styled, { css } from "styled-components";
import { FrameBodyRight } from "../../components/frame/styles";

export const FrameBodyRightFacilities = styled(FrameBodyRight)`
  @media screen and (max-width: 767px) {
    & > div:nth-child(2) {
      .carousel-control-prev {
        opacity: 0;
      }
      .carousel-control-next-icon {
        opacity: 0;
      }
    }
  }
  ${(props) =>
    !props.isMobile &&
    css`
      overflow: hidden;
      & > div:first-child {
        display: flex;

        & > div {
          padding: 0;
          background-color: transparent;
          border: none;

          & > div {
            & > div {
              & > div {
                height: 100%;
                width: 100%;
                gap: 8px;

                & > div {
                  min-width: 750px;
                  padding: 8px 12px;
                  border-radius: 8px;
                  background-color: ${(props) =>
                    props.theme.colors.divBackground};
                }
              }
            }
          }
        }
      }

      .marquee-container {
        height: 100%;
      }
    `};
`;

export const LoadingTabContainer = styled.div<{ showSidebar?: boolean }>`
  background-color: ${(props) => props.theme.colors.divBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1440px) {
  }

  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
    width: 99%;
  }
`;
