import Frame from "../../../components/frame";
import { SidebarConfiguration } from "../../../components/SidebarConfiguration";
import { useEffect, useMemo, useState } from "react";
import { ThemeProvider } from "styled-components";

import { FrameBodyProfile } from "../Profile/styles";

import {
  appSelect,
  appSetCurrentCompany,
  setColorTheme,
} from "../../../store/slices/appSlice";

import dark from "../../../styles/themes/dark";
import light from "../../../styles/themes/light";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ResetColorButton } from "../../../components/ResetColorButton";
import { PROFILES, userSelect } from "../../../store/slices/userSlice";

import { useParams } from "react-router-dom";
import { error, success } from "../../../components/Toast";
import useApi from "../../../hooks/useApi";
import ColorPalette from "../../../components/ColorPalette";
import {
  ButtonsContainer,
  ColorSelect,
  ColorsSelectContainer,
  Container,
  ContainerTheme,
  CustomizeContent,
  CustomizeMenuItem,
  FormColorContainer,
  SaveButton,
  SidebarMenuItem,
  ThemeScreen,
} from "./styles";
import { IconsColorPicker } from "./IconsColorPicker";
import { MdOutlineColorLens } from "react-icons/md";
import { CustomNavbarIcons } from "./CustomNavbarIcons";
import { TbLayoutNavbarExpand, TbRulerMeasure } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { MetricUnits } from "./MetricUnits";

const Theme = () => {
  const { t } = useTranslation();

  const params = useParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelect);
  const app = useAppSelector(appSelect);

  const { companyId } = params;
  const { isReadOnly } = app;

  const sidebarMenuItens = useMemo(() => {
    let items = [
      {
        id: "navBar",
        icon: <MdOutlineColorLens />,
        label: `${t("themes.navBar")}`,
      },
      {
        id: "icons",
        icon: <TbLayoutNavbarExpand />,
        label: `${t("themes.icons")}`,
      },
      {
        id: "metricUnits",
        icon: <TbRulerMeasure />,
        label: `${t("themes.metricUnits")}`,
      },
    ];

    if (user.profile === PROFILES.CLIENTE_COMUM) {
      items = items.filter(({ id }) => id !== "navBar");
    }

    return items;
    // eslint-disable-next-line
  }, []);

  const [, setIsHovered] = useState(false);
  const [color, setColor] = useState<any | null>();
  const [iconsColor, setIconsColor] = useState<any | null>();
  const [selectedItem, setSelectedItem] = useState<string>(
    sidebarMenuItens[0].id,
  );
  const [selectedColor, setSelectedColor] = useState<string>("");

  const { request, processing } = useApi({
    path: `/companies/${companyId}/theme`,
  });
  const { request: requestThemeRepresentative } = useApi({
    path: "/representative-theme",
  });

  const handleItemClick = (itemId: string) => {
    setSelectedItem(itemId);
  };

  function reloadNavbar() {
    if (
      [PROFILES.REPRESENTANTE].includes(user.profile) &&
      app.currentCompany.id === 0
    ) {
      requestThemeRepresentative({ method: "get" }).then(
        ({ logoUrl, theme }: any) => {
          dispatch(setColorTheme(theme));
        },
      );
    } else {
      request({ method: "get" }).then(({ theme }) =>
        dispatch(appSetCurrentCompany({ ...app.currentCompany, theme })),
      );
    }
  }

  const loadColorsTheme = () => {
    if (companyId !== "ibbx") {
      request({ method: "get" }).then((response) => {
        setColor(response.theme?.navbarColor);
        setIconsColor(response.theme?.iconsColor);
      });
    }
  };

  useEffect(() => {
    if (companyId !== "ibbx") {
      loadColorsTheme();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedColor(color || "");
  }, [color]);

  const fetchData = (color: string, iconsColor: string) => {
    if ((color || iconsColor) && !processing) {
      request({
        method: "put",
        body: { navbarColor: color, iconsColor: iconsColor },
      })
        .then((response: any) => {
          success("Alteração feita com sucesso!");
          reloadNavbar();
        })
        .catch((response: any) => error("Erro ao realizar alteração"));
    }
  };

  const onColorChange = (color: any) => {
    setColor(color);
    setIconsColor(iconsColor);
  };

  const handleUnselectButton = () => {
    setColor("");
    setIconsColor("");
    setSelectedColor("");
  };

  function handleColorSave() {
    fetchData(color, iconsColor);
  }

  const renderCustomNavbar = () => {
    return (
      <FormColorContainer>
        <ColorsSelectContainer>
          <ColorSelect>
            <ColorPalette
              label={t("themes.Cor da barra de navegação")}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              handleChange={onColorChange}
            />

            <IconsColorPicker
              label={t("themes.Cor dos ícones")}
              selectedColor={iconsColor}
              onSelect={setIconsColor}
            />
          </ColorSelect>
        </ColorsSelectContainer>

        {!isReadOnly && (
          <ButtonsContainer>
            <ResetColorButton handleUnselectButton={handleUnselectButton} />
            <SaveButton type="button" onClick={handleColorSave}>
              {t("themes.save")}
            </SaveButton>
          </ButtonsContainer>
        )}
      </FormColorContainer>
    );
  };

  const renderAllContentItems: { [key: string]: React.ReactNode } = {
    navBar: renderCustomNavbar(),
    icons: <CustomNavbarIcons />,
    metricUnits: <MetricUnits />,
  };

  return (
    <ThemeProvider theme={app.darkMode ? dark : light}>
      <Frame hasMargin={false}>
        <FrameBodyProfile>
          <SidebarConfiguration />
          <ContainerTheme visible>
            <Container
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <h2>{t("themes.customIt")}</h2>

              <ThemeScreen>
                <SidebarMenuItem>
                  {sidebarMenuItens.map((item) => (
                    <CustomizeMenuItem
                      onClick={() => handleItemClick(item.id)}
                      selected={selectedItem === item.id}
                    >
                      {item.icon}
                      <span>{item.label}</span>
                    </CustomizeMenuItem>
                  ))}
                </SidebarMenuItem>

                <CustomizeContent>
                  {renderAllContentItems[selectedItem]}
                </CustomizeContent>
              </ThemeScreen>
            </Container>
          </ContainerTheme>
        </FrameBodyProfile>
      </Frame>
    </ThemeProvider>
  );
};

export default Theme;
