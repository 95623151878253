import styled, { css } from "styled-components";

export const Button = styled.button<{ selected?: boolean; hasLabel?: boolean }>`
  border: none;
  color: ${(props) =>
    props.selected
      ? props.theme.colors.iconsPrimary
      : props.theme.colors.iconsSecondary};
  background: transparent;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.1rem;
  text-transform: uppercase;
  ${(props) => props.theme.fonts.primary.H5SemiBold};
  font-size: 11px;
  padding: 0.25rem 0;
  position: relative;
  text-align: left;

  svg {
    opacity: 0;
    width: 0;
    height: auto;
    margin-left: 0;
  }

  ${({ selected }) =>
    selected &&
    css`
      svg {
        align-self: center;
        width: 0.75rem;
        height: auto;
        margin-left: 0.15rem;
        opacity: 0.8;
        transition: all 0.3s ease;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1.5px;
        background-color: ${({ theme }) => theme.colors.iconsPrimary};
        animation: slideIn 0.3s ease-in-out;
        opacity: 0.75;
      }
    `}

  :hover {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      border-radius: 1px;
      background-color: ${(props) => props.theme.colors.iconsSecondary};
      animation: slideIn 0.3s ease-in-out;
    }

    @keyframes slideIn {
      from {
        transform: scaleX(0);
        transform-origin: left;
      }
      to {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    font-size: 9px;

    svg {
      margin-left: 0;
    }
  }
`;
