export function TextTermsAndPoliciesEN() {
  return (
    <>
      <p>
        <strong>GENERAL TERMS AND CONDITIONS OF USE</strong>
      </p>
      <p>
        This instrument contains the general terms and conditions of use of the
        Platform as a Service (PaaS) provided by IBBX Innovation in Software and
        Hardware Systems LTD, a company registered under CNPJ No.
        31.958.399/0001-41, headquartered at Rua Elizabeta Armelin Rossi, 69,
        Capivari, São Paulo, ZIP Code 13.363-212, hereinafter referred to simply
        as IBBX.
      </p>
      <p>
        <strong>PREAMBLE</strong>
      </p>
      <p>
        <strong>
          ACCEPTANCE OF THESE TERMS AND CONDITIONS IS ABSOLUTELY ESSENTIAL FOR
          THE USE OF THE
        </strong>{" "}
        <em>
          {" "}
          <strong>SOFTWARE</strong>
        </em>{" "}
        <strong>.</strong>
      </p>
      <p>
        By clicking the box indicating your agreement, you are accepting these
        terms, declaring that you have read and agreed to the latest version of
        the software terms of use, thereby automatically binding yourself to the
        rules herein. The user must read these terms of use, paying attention to
        all related rules and policies, including specific norms, usage
        restrictions, and other procedures or conditions before entering into
        the contract.
      </p>
      <p>
        <strong>
          This contract becomes effective for the user on the date they click
          the "accept" button, considered the "effective date" of the agreement.
        </strong>
      </p>
      <p>
        The user acknowledges that operations corresponding to the acceptance of
        this contract, certain conditions and options, as well as any
        termination of this instrument and other changes, will be recorded in
        the software's databases, along with the date and time they were
        performed by the user. Such information may be used as evidence by the
        parties, regardless of any other formalities.
      </p>
      <p>
        <strong>The</strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>{" "}
        <strong>
          does not provide professional consultancy related to legal, financial,
          accounting, fiscal, or other professional services or consultancy.
          Users should consult a competent professional when they require such
          assistance.
        </strong>
      </p>
      <p>
        If the user is accepting this agreement on behalf of a third party, they
        declare and warrant that: (i) they have full legal authority to bind
        their employer (or the relevant entity) to these terms and conditions;
        (ii) they have read and understood this contract; and (iii) they agree,
        on behalf of the party they represent, to the terms herein.
      </p>
      <p>
        <strong>
          IBBX reserves the right to change this contract at any time, with
          changes becoming effective upon publication on its
        </strong>{" "}
        <em>
          {" "}
          <strong>website</strong>{" "}
        </em>{" "}
        <strong>
          or notification to the user by other means. In these cases, continued
          use by the user will indicate their agreement to the changes.
        </strong>
      </p>
      <p>
        By registering, accessing, and using the software in any manner,
        including browsing, viewing, downloading, generating, receiving, and
        transmitting any data, information, or messages, you expressly agree, on
        your own behalf and on behalf of your company or employer, to these
        terms, as periodically updated. Whether you are a registered user of the
        services or not, you agree to respect and comply with all provisions
        herein, as well as the legal notices governing the use of the
        applications and services.
      </p>
      <p>
        <strong>1. Definitions</strong>
      </p>
      <p>
        1.1. For the purposes of this instrument, all words and expressions
        listed below shall be understood according to their respective meanings:
      </p>
      <p>
        1.1.1. <strong>User:</strong> The individual responsible for managing
        the registration and usage on behalf of the business establishment
        wishing to manage its data through the use of the software service.
      </p>
      <p>
        1.1.2.{" "}
        <em>
          {" "}
          <strong>Software</strong>
        </em>{" "}
        <strong>:</strong> The tool called "Retina." The IBBX Industrial
        Monitoring Platform operates on a Platform as a Service (PaaS) model.
        PaaS functions by collecting information through sensors and gateways
        (hardware). The IBBX Platform collects data via wireless sensors,
        transmitting this data to IBBX gateways through the proprietary IBBX
        L2RC communication protocol. From a generic communication between
        gateways and the cloud, the collected data will be analyzed by the
        IBBXRetina software.
      </p>
      <p>
        1.1.3. <strong>Account Information:</strong> Information and data
        related to employees, the business establishment, including logins,
        passwords, and other necessary information to access, collect, store,
        and process information obtained through the software.
      </p>
      <p>
        1.1.4. <strong>Personal Information:</strong> Any information provided
        by the user that identifies them or the business establishment they
        represent, such as name, address, phone number, email, document number,
        etc.
      </p>
      <p>
        1.1.5. <strong>Infrastructure:</strong> The equipment for software and
        database storage used, including servers and other necessary equipment.
      </p>
      <p>
        1.1.6. <strong>Deployment:</strong> Refers to training the key user of
        the contracting business establishment to use the system correctly and
        effectively, using a test database.
      </p>
      <p>
        1.1.7. <strong>Qualified Personnel:</strong> Employees of the
        contracting business establishment with basic computer skills, knowledge
        of the company's production process, and operational training in the
        software, certified by IBBX to operate it optimally to achieve the
        desired results.
      </p>
      <p>
        1.1.8. <strong>Improvements (</strong>
        <em>
          <strong>upgrades)</strong>
        </em>
        : Refers to changes in the software that enhance its performance and
        operability or include new functionalities.
      </p>
      <p>
        <strong>2. Object</strong>
      </p>
      <p>
        2.1. The object of this contract is to establish the conditions of use
        for the IBBX Retina software in the form of "Platform as a Service,"
        which provides the user with online management of business information,
        particularly related to industrial monitoring activities.
      </p>
      <p>
        2.1.1. The contracted object does not include access to the internet or
        local infrastructure for the user in any way. Therefore, it is entirely
        and exclusively the user's responsibility to independently obtain the
        equipment, software, and services necessary to ensure their connection
        to the internet and the software that provides access to the services.
      </p>
      <p>
        2.2. Anyone intending to use the services must accept these terms and
        any other policies made available.
      </p>
      <p>
        <strong>3. Capacity to Contract and Registration</strong>
      </p>
      <p>
        3.1. To use the software, prior registration is required, in which the
        user must provide a valid email address, which will be confirmed
        subsequently through the sending of an automatic email, as well as other
        required data.
      </p>
      <p>
        3.2. The user must be at least 18 (eighteen) years of age, legally
        capable, and declare that they have read, understood, agreed to, and
        fully comply with the provisions of this term.
      </p>
      <p>
        3.3. The user declares that they are legally responsible and/or
        authorized to manage the necessary adhesion to the software.
      </p>
      <p>
        3.4. When requested, the user agrees to provide true, correct, updated,
        and complete information (the "registration data"), as requested in the
        provided registration format, under penalty of liability under the
        applicable law in force.
      </p>
      <p>
        3.5. IBBX will rely on your registration data to evaluate your business
        situation, provide information about the services, or alternatively,
        identify and/or contact the user.{" "}
        <strong>
          If the registration data is not true and correct, or is outdated and
          incomplete, IBBX may terminate the license and all current or future
          uses of the services, respecting the licensing agreement signed
          between the parties.
        </strong>
      </p>
      <p>
        3.6. The user will receive a password and account designation upon
        completing the registration process, and such data is personal and
        non-transferable (the "access data"). The user is solely and exclusively
        responsible for maintaining the confidentiality of such data, as well as
        for all activities that occur using their access data.
      </p>
      <p>3.6.1. In this regard, the user undertakes to:</p>
      <p>
        <strong>(i)</strong> Immediately notify IBBX of any unauthorized use of
        their access data or any other security breach, including but not
        limited to loss, theft, or misuse of their access data; and
      </p>
      <p>
        <strong>(ii)</strong> Log out of their account at the end of each usage
        session;
      </p>
      <p>
        <strong>(iii)</strong> Notify IBBX of software user disconnections;
      </p>
      <p>
        <strong>(iv)</strong> Maintain software user data up to date.
      </p>
      <p>
        3.6.2. IBBX shall not be liable for any loss or damage arising from your
        failure to comply with this section.
      </p>
      <p>
        <strong>
          4. Responsibility for Insertion and Management of Data in the
        </strong>{" "}
        <em>
          {" "}
          <strong>Software</strong>{" "}
        </em>
      </p>
      <p>
        4.1. Responsibility for the data provided and the use of the software:
      </p>
      <p>
        <strong>
          4.2. IBBX is not responsible for the existence, quantity, quality,
          state, integrity, or legitimacy of the data, content, and information
          entered by the user, as it does not control the use of the data,
          limiting itself to ensuring the system's functionality.
        </strong>
      </p>
      Claro, aqui está a tradução da parte solicitada:
      <p>
        <strong>
          4.3. In no event shall IBBX be liable for lost profits or any other
          damages or losses that the user may suffer due to the configurations
          established in the
        </strong>{" "}
        <em>
          {" "}
          <strong>software</strong>
        </em>
        <strong>.</strong>
      </p>
      <p>
        <strong>
          4.4. The responsibility for the services provided is limited to making
          available an
        </strong>{" "}
        <em>
          {" "}
          <strong>industrial monitoring software</strong>{" "}
        </em>
        <strong>
          as well as providing technical support and problem resolution, if
          necessary and in accordance with the licensing agreement.
        </strong>
      </p>
      <p>
        4.5. IBBX is not responsible for any errors and/or failures presented by
        the <em>software</em> resulting from issues with the user's computers,
        mobile devices, or network, and in these cases, the user assumes full
        responsibility for the error and/or failure presented.
      </p>
      <p>
        4.6. The user is responsible for all activity that occurs on their
        account accessing the services.
      </p>
      <p>
        <strong>
          4.7. The user is solely responsible for complying with all applicable
          laws to their business, including laws, regulations, and any licenses
          or contracts they are obligated to.
        </strong>
      </p>
      <p>
        4.8. The user warrants that their files are free of any <em>malware</em>
        , viruses, trojans, <em>spyware</em>, worms, or other malicious or
        harmful code.
      </p>
      <p>
        4.9. The user is responsible for: (a) maintaining the confidentiality of
        the password and administrator accounts; (b) designating who is
        authorized to access the administrator accounts; and (c) ensuring that
        all activities occurring in connection with the administrator accounts
        comply with the rights and duties contained in these terms.
      </p>
      <p>
        4.10. IBBX undertakes to keep the user's account information,
        administrative information, personal information, and access records
        confidential, storing this information in a secure environment,
        respecting the user's privacy, personal life, honor, and image, in
        accordance with the provisions of Law No. 12,965/2014 and the privacy
        policy.
      </p>
      <p>
        4.11. Except in cases of proven willful misconduct, at no time shall
        IBBX be liable for any loss or damage of any kind arising from misuse of
        the <em>software</em> or any other information, data, or service
        provided through this infrastructure.
      </p>
      <p>
        4.12. Under no circumstances shall IBBX be liable for any indirect
        damages of any kind that may, directly or indirectly, be attributable to
        the use or inability to use the <em>PaaS</em> or service provided
        through the infrastructure, even if advised of the possibility of such
        damages or if such damages were foreseeable.
      </p>
      <p>
        4.13. IBBX is not liable to the user for any delay or failure of the{" "}
        <em>software</em> to operate, or failure to access the infrastructure,
        due to any cause beyond reasonable control.
      </p>
      <p>
        4.14. The user is responsible for all obligations to third parties
        arising from the use of the <em>software</em>, including contractual,
        labor, tax, and regulatory liabilities.
      </p>
      <p>
        4.15. IBBX does not guarantee that the <em>software</em> will be
        uninterrupted or error-free, that defects will be corrected, or that the
        server on which it is made available or any system connected to it is
        free of viruses or other harmful components.
      </p>
      <p>
        <strong>4.16. IBBX is not responsible for:</strong>
      </p>
      <p>
        <strong>
          1. Operation failure, operation by unauthorized persons, or any other
          cause for which it is not at fault;
        </strong>
      </p>
      <p>
        <strong>
          2. User's legal deadlines for submitting tax documents or payments;
        </strong>
      </p>
      <p>
        <strong>
          3. Damages or losses resulting from administrative, managerial, or
          commercial decisions based on the information provided by the
        </strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>
        <strong>;</strong>
      </p>
      <p>
        <strong>
          4. Problems arising from acts of God or force majeure, as defined by
          law;
        </strong>
      </p>
      <p>
        <strong>
          5. Any problems resulting from third-party actions that may affect
          quality;
        </strong>
      </p>
      <p>
        <strong>
          6. Damages caused to third parties due to user's fault or willful
          misconduct;
        </strong>
      </p>
      <p>
        <strong>
          7. Reviewing the account information provided by the user, as well as
          other information obtained by the user or from
        </strong>{" "}
        <em>
          {" "}
          <strong>third-party websites</strong>{" "}
        </em>{" "}
        <strong>
          regarding the accuracy of data or legality, threat of violation, etc.
        </strong>
      </p>
      <p>
        4.17. The user hereby declares that they have the authority to provide
        the necessary information for the <em>software</em> and agrees to review
        and comply with all third-party product and service terms, will not use
        third-party services and products in a manner that infringes or violates
        the <em>software</em> or third-party rights, will bear additional costs
        charged by a third party for excess data access.
      </p>
      <p>
        4.18. IBBX undertakes to adopt appropriate security measures according
        to market standards to protect user information.{" "}
        <strong>
          However, the user acknowledges that no system, server, or
        </strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>{" "}
        <strong>is absolutely immune to attacks, and the</strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>{" "}
        <strong>
          shall not be responsible for any unauthorized deletion, obtaining,
          use, or disclosure of information resulting from attacks.
        </strong>
      </p>
      <p>4.19. The user expressly declares and warrants that:</p>
      <ol>
        <li>
          <p>
            They will not use the <em>software</em> for illegal purposes;
          </p>
        </li>
        <li>
          <p>
            They will not disseminate information about illegal activities and
            incitement to crime;
          </p>
        </li>
        <li>
          <p>They will not disseminate pornographic material;</p>
        </li>
        <li>
          <p>They will not disclose information regarding software piracy;</p>
        </li>
        <li>
          <p>
            They will not disclose copyrighted or confidential material without
            authorization from the respective owners;
          </p>
        </li>
        <li>
          <p>They will not impersonate another person, company, or entity;</p>
        </li>
        <li>
          <p>They will not collect or disclose user data;</p>
        </li>
        <li>
          <p>
            They will not modify, adapt, or reverse engineer the{" "}
            <em>software</em>;
          </p>
        </li>
        <li>
          <p>
            They will not send unsolicited messages, recognized as &quot;
            <em>spam</em>&quot;, &quot;<em>junk mail</em>&quot;, or chain
            letters;
          </p>
        </li>
        <li>
          <p>
            They will not use the <em>software</em> to send any types of
            computer viruses;
          </p>
        </li>
        <li>
          <p>They will not obtain or attempt to obtain unauthorized access;</p>
        </li>
        <li>
          <p>
            They will not interfere with the normal operation of the
            infrastructure.
          </p>
        </li>
      </ol>
      <p>
        4.20. IBBX reserves the right to immediately suspend or block the user's
        access to the infrastructure and to remove any information or data it
        deems a violation of any of these general terms, without notice and/or
        to make such information available when requested by public authorities
        or by court order.
      </p>
      <p>
        4.21. IBBX works and will make its best efforts to keep the
        infrastructure and services operational, however, all online services
        are subject to occasional interruptions and downtime.
      </p>
      <p>
        4.22. IBBX undertakes to provide the services under this contract using
        qualified personnel and observing the best applicable techniques.
        However, the user is responsible for verifying the correctness of input
        data and configurable operational parameters entered by the user, as
        well as for controlling the quality and consistency of output data and
        materials generated by using the <em>software</em>, which must always be
        verified before using them in any applications critical to their
        accuracy.
      </p>
      <p>
        4.23. IBBX is responsible to the user for compliance with labor, social
        security, hygiene, and workplace safety standards related to all
        personnel directly or indirectly employed in any way to fulfill the
        purpose of this contract.
      </p>
      <p>
        4.24. IBBX undertakes to repeat, at no cost to the user, any services
        performed improperly, as well as to repair any damage resulting from
        such events, provided that the user has previously complied with all
        conditions set forth in this contract.
      </p>
      <p>
        4.25. Under no circumstances shall IBBX be liable for losses or the
        creation of tax, labor, or social security liabilities for the user's
        business due to court decisions or legislative changes, municipal,
        state, or agreements/union conventions that alter the calculation
        parameters in the <em>software</em>, being responsible only for changes
        in federal legislation.
      </p>
      <p>
        <strong>
          4.26. IBBX shall not be liable for any actions arising before the
          signing of this contract, especially for any calculations and
          processes performed by
        </strong>{" "}
        <em>
          {" "}
          <strong>previous software</strong>{" "}
        </em>{" "}
        <strong>whose data has been migrated to the IBBX system.</strong>
      </p>
      <p>
        <strong>4.27. Finally, the user's responsibilities are:</strong>
      </p>
      <p>
        a) Operate the <em>software</em> in accordance with IBBX specifications
        and guidelines, including the set of activities for preparation,
        selection, and input of inherent and necessary information to achieve
        its objectives.
      </p>
      <p>
        b) Provide the necessary infrastructure for the <em>software</em> to
        function, ensuring the basic environment for executing the software's
        modules, as identified in Annex I, such as appropriate <em>hardware</em>{" "}
        (processor capacity, memory, disk space, among others), operating
        system, database, or other interdependent software, communication
        infrastructure (links, network equipment), and work environment.
      </p>
      <p>
        c) Maintain the necessary files to access the <em>PaaS</em> environment,
        as well as any data stored in the user's business infrastructure,
        ensuring their total security, including regular backup using
        appropriate means and commonly used media for this purpose.
      </p>
      <p>
        d) Control the quality of information input and produced by the{" "}
        <em>software</em> and the correct application of relevant legislation
        concerning the software's intended use.
      </p>
      <p>e) Provide only correct and complete information to IBBX.</p>
      <p>
        f) Ensure the availability of professionals for clarification of doubts,
        problem resolution, and execution of all previously defined activities,
        as well as making every effort to meet established deadlines.
      </p>
      <p>
        g) Resolve any problems arising from web access, acknowledging that IBBX
        has no control or responsibility over the user's business structure or
        remote intrusion protection tools. Any invasion or remote data access
        under its custody will not imply any software failure or metadata
        service failure.
      </p>
      <p>
        h) Take all necessary measures to ensure that the system is used in
        accordance with the terms and conditions of use and be responsible for
        any violations of IBBX's or any third party's intellectual property.
      </p>
      <p>
        <strong>5. Service Level</strong>
      </p>
      <p>
        <strong>
          5.1. Through the <em>software</em>, IBBX will make commercially
          reasonable efforts to make the software available at least 99.7%
          (ninety-nine point seven percent) during each service year.
        </strong>
      </p>
      <p>
        5.1.1. In the event that IBBX fails to meet the service level
        commitment, the user will be entitled to receive credit equivalent to 01
        (one) month's subscription fee.
      </p>
      <p>
        5.1.1.1. By "service year," we mean the preceding 365 (three hundred
        sixty-five) days from the date of a service level-related claim.
      </p>
      <p>
        5.2. If the user uses the <em>software</em> for a period of less than
        365 days, the corresponding service year will still be considered the
        preceding 365 days. However, the days before service usage will be
        considered 100% available. Operational downtime occurring before a valid
        service credit claim cannot be used for future claims.
      </p>
      <p>
        <strong>
          5.3. The service level commitment does not apply if unavailability
          circumstances result from:
        </strong>
      </p>
      <p>
        i) Power supply interruption or emergency stops not exceeding 2 (two)
        hours or occurring between 12 AM and 6 AM (Brasília time);
      </p>
      <p>
        ii) Factors beyond reasonable control of the <em>software</em>,
        including force majeure or internet access and related issues;
      </p>
      <p>iii) Any acts or omissions by the user or third parties;</p>
      <p>
        iv) Equipment, <em>software</em>, or other technologies used by the user
        preventing regular access;
      </p>
      <p>
        v) Failures of individual instances not attributable to user
        unavailability;
      </p>
      <p>vi) Changes made to user account information access;</p>
      <p>vii) Network management practices affecting its quality.</p>
      <p>
        <strong>6. Infringement Notification</strong>
      </p>
      <p>
        6.1. If any person, user or not, feels aggrieved regarding any issue
        generated by the <em>software</em>, they may send a written notification
        to IBBX reporting the issue, and IBBX will notify the user of
        appropriate measures without imposing any liability.
      </p>
      <p>
        6.2. Unilateral removal of any content or user by the <em>software</em>{" "}
        will depend on effective proof or strong evidence of illegality or
        violation of the law, third-party rights, and/or terms of use.
      </p>
      <p>
        6.3. Notifications should include the identification of the allegedly
        infringing material or necessary information for its proper
        identification and action against the user.
      </p>
      <p>
        6.3.1. The notifier will declare that the information in the
        notification is accurate and true, subject to civil and criminal
        liability for false statements, and that the notifier is authorized to
        act on behalf of the allegedly violated right holder.
      </p>
      <p>
        6.4. Notifications should be sent to the platform's email:{" "}
        <a href="mailto:support@ibbx.tech">support@ibbx.tech</a>.
      </p>
      <p>
        <strong>7. Term</strong>
      </p>
      <p>
        7.1. This contract will remain in effect for the duration of the
        licensing agreement separately executed between the parties.
      </p>
      <p>
        <strong>8. Technical Support</strong>
      </p>
      <p>
        8.1. Technical support service consists of consulting via chat or email
        for operational doubts of software users, by authorized individuals from
        the user's business establishment, provided by IBBX during business
        hours, understood as 9:00 AM to 12:00 PM and 1:30 PM to 6:00 PM,
        Brasília/BR time, Monday to Friday. IBBX reserves the right to request
        necessary analysis materials in the software's original standards and
        nomenclatures.
      </p>
      <p>
        8.2. All inquiries must come from employees or representatives of the
        contracting business establishment, previously identified and authorized
        to work with the software, possessing knowledge of its operation, used
        equipment, operating system, and utility programs.
      </p>
      <p>
        8.3. IBBX may require, at its discretion, prior registration of
        authorized personnel as a condition of accessing technical support,
        refusing to provide said service to those who have not received specific
        training for software operation.
      </p>
      <p>
        8.4. If material needs to be sent for analysis to IBBX, in cases where
        there is no IBBX error or failure, time spent resolving issues will be
        invoiced as operational consultancy, with presentation of the respective
        service report to the contracting business establishment and prior
        acknowledgment.
      </p>
      <p>
        8.5. Technical support, as well as operational consultancy, may be
        provided remotely using communication and connection tools allowing
        access to the contractor's computer for verification, adjustments, and
        other activities inherent to technical support and operational
        consultancy on the licensed software.
      </p>
      <p>
        <strong>9. Termination of Contract</strong>
      </p>
      <p>
        9.1. The user and the <em>software</em> may terminate these terms and
        conditions at any time, with notification, in accordance with the
        separately executed licensing agreement.
      </p>
      <p>
        9.2. IBBX reserves the right to terminate the contract, restrict, or
        suspend user service use at any time upon notification, whenever service
        usage violates this term and the licensing agreement terms.
      </p>
      <p>
        9.3. The user expressly authorizes IBBX to keep the information provided
        in its registry and to provide information in said registry to public
        authorities requesting it as permitted by law and its strategic,
        commercial, or technical partners, for offering better promotions and/or
        content, without individualizing users and business establishments, per
        the privacy policy terms.
      </p>
      <p>
        <strong>10. Database Use</strong>
      </p>
      <p>
        10.1. The user expressly agrees that IBBX and/or any of its partners may
        send informative email messages regarding specific service
        communications already available or to be made available, as well as
        other commercial messages, per the privacy policy. Should the user no
        longer wish to receive these messages, they must request cancellation
        from IBBX.
      </p>
      <p>
        10.2. Information on access date and time and the internet protocol
        address used by the user to access the <em>software</em> will remain
        stored for 6 (six) months from each access date, regardless of the legal
        and commercial relationship between the parties, complying with article
        15 of law no. 12,965/2014, and may be stored longer upon court order.
      </p>
      <p>
        <strong>11. Modifications</strong>
      </p>
      <p>
        11.1. IBBX reserves the right to modify the terms and conditions of this
        agreement or its service policies at any time, effective upon
        publication of an updated version of the service terms, in accordance
        with this contract.
      </p>
      <p>
        <strong>11. Intellectual Property Rights</strong>
      </p>
      <p>
        11.1. All rights and intellectual property related to the software and
        technology will remain the exclusive property of the company mentioned
        in the qualification of this contract, even if it develops new
        functionalities at the request and for the compensation of the user.
        Therefore, the technology subject to licensing under this contract,
        including its programs, flowcharts, enhancements, adaptations, and other
        functionalities, as well as all technical documentation, are the total
        and definitive property of the aforementioned company, so that the
        copyright and other intellectual property rights related to it are the
        same as those conferred to literary works, in accordance with the
        copyright legislation in force in the country, as expressly determined
        by Article 2 and paragraphs of Law 9,609/98.
      </p>
      <p>
        11.2. The user, upon acquiring the right to use the software, is only
        authorized to update it as established in this instrument, being
        prohibited from using methods such as reverse engineering,
        decompilation, or any other method that allows access to the software's
        source code.
      </p>
      <p>
        11.3. The user may not assign to third parties the software and/or
        documentation provided by IBBX under any pretext, committing itself,
        through its employees or agents, to keep the software accesses and
        received documentation in good custody.
      </p>
      <p>
        11.4. The software is configured in standardized mode, considering the
        dominant interpretation about the application of the legislation it aims
        to address, and in some cases, it will be possible to change parameters
        to adapt to specific situations or to reflect a particular
        interpretation of the legislation by the user, by signing a consent
        and/or responsibility agreement for this.
      </p>
      <p>
        <strong>
          11.5. Whenever the original system parameters are altered at the
          user's request, IBBX will be completely exempt from any liability for
          the respective data generation, as per the responsibility agreement to
          be signed by the contractor.
        </strong>
      </p>
      <p>
        <strong>
          11.6. Whenever the contractor signs a consent form regarding the
          parameters used, IBBX will be exempt from any liability for the
          respective data generation.
        </strong>
      </p>
      <p>
        11.7. Through this contract, the user is granted only the right to use
        the technology in question, without the need to present or provide the
        source code or internal structure of the product.
      </p>
      <p>
        11.8. The user also agrees not to use any robots or other automated
        devices or manual processes to monitor or copy content from the service,
        under penalty of being liable to IBBX for any losses and damages caused,
        including attorney's fees when necessary for the defense of IBBX's
        interests.
      </p>
      <p>
        11.9. IBBX reserves the right to modify, suspend, terminate, or
        discontinue any aspect of the software at any time, including the
        availability of any services, information, features, or functionalities,
        as well as to impose limitations on certain features, functionalities,
        or services or restrict user access to parts or all of the software,
        with prior notice.
      </p>
      <p>
        11.9.1. IBBX also reserves the right, at any time or for any reason, to
        control and/or change the appearance, development, and/or operations of
        the software at its sole discretion, as well as to establish and modify
        procedures for contact between the parties, without prior notice.
      </p>
      <p>
        11.10. Any violation of the software copyright will result in a fine of
        100 (one hundred) times the monthly software usage fee, regardless of
        judicially determined losses and damages and criminal complaints.
      </p>
      <p>
        <strong>12. Operational Advisory</strong>
      </p>
      <p>
        12.1. Operational advisory service will be provided to the user by IBBX,
        through the provision of one or more technicians for on-site or remote
        assistance, which is not covered by the software warranty or any other
        services specified in this contract, including, for example, training
        the contracting business establishment's team, backups, among others.
      </p>
      <p>
        12.2. In case of assistance for processing recoveries due to operational
        errors resulting from the adoption of techniques and methods different
        from those instructed in training or indicated in the documentation
        provided by IBBX, these services will be subject to prior technical
        analyses, with consequent cost estimates determined on a case-by-case
        basis.
      </p>
      <p>
        12.3. Time spent on queries related to the operating system, third-party
        applications or utilities, and products not owned by IBBX will be
        considered operational advisory.
      </p>
      <p>
        12.4. The technical hour for operational advisory will be equivalent to
        60 (sixty) minutes and the service will be provided during business
        hours, from 08:00 AM to 12:00 PM and 01:15 PM to 06:00 PM, Brasília/BR
        time, Monday to Friday.
      </p>
      <p>
        12.4.1. Services provided outside the aforementioned hours will be
        subject to an additional 50% (fifty percent) for overtime, and when
        provided on Saturdays, Sundays, and national or Capivari-SP holidays,
        the hourly rate will be increased by 100% (one hundred percent).
      </p>
      <p>
        12.5. The fees for technical hour of operational advisory will be the
        prevailing rates at the time of service execution and will be billed
        monthly, in accordance with this contract.
      </p>
      <p>
        12.6. Travel times will be charged based on the nearest IBBX unit to the
        user, subject to prior negotiation between the parties.
      </p>
      <p>
        12.7. Operational advisory cannot be requested by the user to request
        structural modifications to the software or development of other modules
        or functions not provided for in the original project, and IBBX is not
        obligated to accept requests in this regard.
      </p>
      <p>
        12.8. In the event of accepting the aforementioned structural
        modifications, IBBX will present a prior budget for the work to be
        carried out, and a specific contract instrument must be executed for
        this purpose.
      </p>
      <p>
        12.9. Services such as data recovery, peripheral configuration, and
        installation of third-party software will be considered technical
        advisory services, subject to billing based on prior estimates.
      </p>
      <p>
        <strong>13. Software Implementation</strong>
      </p>
      <p>
        13.1. The implementation process must follow IBBX's standard
        methodology, unless otherwise agreed upon and duly documented and
        approved in meeting minutes and action plans. The definitions of the
        activities to be developed, the methodology employed, and the respective
        priority levels are the competence and responsibility of IBBX, and must
        be fully followed by the user.
      </p>
      <p>
        13.2. In the event of any change in the technical operating environment
        that requires additional activities not foreseen in this contract, IBBX
        must be informed with a minimum advance notice of 30 (thirty) days,
        reserving the right to maintain or not maintain the implementation in
        the new environment, as well as to charge, or not, based on prior
        estimates, the costs of adapting the schedule. In this case, the
        deadline will be established by IBBX, which will also define the new
        value of the update.
      </p>
      <p>
        13.3. The contracting business establishment must appoint a collaborator
        or agent as the coordinator responsible for the software implementation
        and maintenance project, with knowledge of the operational environment
        related to the licensing contract.
      </p>
      <p>
        13.4. It will be IBBX's responsibility to train the employees and/or
        agents of the contracting business establishment, aiming to enable them,
        train them, and certify them to operate all software routines.
      </p>
      <p>
        13.5. IBBX is not responsible for training users on equipment operation
        and its respective operating system, and reserves the right to request
        the replacement of those who demonstrate unsatisfactory performance.
      </p>
      <p>
        13.6. In case of rework on implementation activities after software
        delivery, the time spent on executing these tasks will be charged as
        operational advisory.
      </p>
      <p>
        13.7. IBBX will coordinate and carry out the software implementation
        process, inserting and parameterizing information and business rules in
        full compliance with current legislation, and the contracting business
        establishment must sign the consent form after reviewing and validating
        the parameters entered in the software.
      </p>
      <p>
        <strong>14. Software Updates and Customization</strong>
      </p>
      <p>
        14.1. Software updates include all changes to programs and their
        respective documentation that IBBX may create, which require its update,
        complementation, or reprogramming, aiming at improvements or
        installation of new operations or due to changes in legislation.
      </p>
      <p>
        14.2. The legal interpretation of government-edited norms and their
        implementation in the software will be based on specialized publications
        and information disseminated about each subject in public domain
        communication vehicles. However, the responsibility for agreeing to the
        parameters will always lie with the contracting business establishment
        and the user.
      </p>
      <p>
        14.3. Divergent interpretations by the contracting business
        establishment and the user, when implemented, will be considered
        software customizations, and IBBX will not be obliged to implement
        changes solely based on the assessment of one user, but will be obliged
        to do so according to the request of the majority of its business
        establishment clients.
      </p>
      <p>
        14.4. Modifications imposed by associations, unions, or other specific
        groups may be implemented upon approval of a prior budget.
      </p>
      <p>
        14.5. The improvements and new features introduced by IBBX in the
        originally licensed software will be distributed to ensure that the user
        always has the latest version of the software.
      </p>
      <p>
        14.6. Enhancements to the software by IBBX at the written request of the
        user will be charged as customizations for the hours worked at the
        headquarters of the contracting business establishment for surveys,
        implementation, and training, upon presentation of the service report.
      </p>
      <p>
        14.7. Activities such as analysis, programming, testing, documentation,
        and distribution of changes made at IBBX's headquarters will be invoiced
        according to a prior budget or presentation of reports and results for
        each phase.
      </p>
      <p>
        14.8. The software update guarantee expressed in this clause does not
        cover customizations made to the software at the user's request.
      </p>
      <p>
        14.9. The Intellectual Property rights of the customizations made by
        IBBX in the software, even at the user's request, will be exclusive to
        IBBX and may be incorporated into versions distributed to other clients
        thereof, without any refund or reimbursement to the user or the
        contracting business establishment.
      </p>
      <p>
        <strong>15. Confidentiality</strong>
      </p>
      <p>
        15.1. The contractor declares that it will store the information of the
        contracting business establishment and the user with the same security
        and dedication for their protection as if they were its own, contracting
        appropriate equipment and software for the safekeeping of all documents
        and information in its possession.
      </p>
      <p>
        15.2. Other regulations regarding the collection, storage, and use of
        information are properly addressed in the Privacy Policy, which is an
        integral part of this document.
      </p>
      <p>
        <strong>16. General Provisions</strong>
      </p>
      <p>
        16.1. The user agrees that IBBX may disclose the execution of this
        instrument for commercial purposes, mentioning the user's name and brand
        in commercial campaigns, including the dissemination of written or oral
        messages by telephone for use on websites, newspapers, magazines, and
        other campaigns, even after the termination of these terms.
      </p>
      <p>
        16.2. Any omissions or doubts regarding the execution of this contract
        will be resolved through consultations and mutual understandings between
        the parties, signing an amendment whenever necessary.
      </p>
      <p>
        16.3. The parties expressly declare and agree that any delay or omission
        in exercising rights guaranteed by law or by this instrument will not
        constitute a novation or waiver of such rights, nor will it prejudice
        their eventual and timely exercise.
      </p>
      <p>
        16.4. Waivers of rights under law or this contract will only be valid if
        made in writing.
      </p>
      <p>
        16.5. The invalidity or unenforceability of any clause in this contract
        will not affect the validity and effectiveness of the others, which will
        remain in full force and effect. In this case, the parties must
        negotiate in good faith to draft a new clause to replace the one that is
        considered null or invalid, reflecting the parties' intent in this
        contract.
      </p>
      <p>
        16.6. All correspondence, notifications, and communications between the
        parties must be made via email, registered letter, notary notice, or any
        other suitable means that allow confirmation of receipt, addressed to
        the addresses stated in the preamble of this contract. Any party with a
        change of address, email, or phone number must immediately communicate
        this in writing to the other party.
      </p>
      <p>
        16.7. The user may not assign, sublicense, subcontract, transfer, or
        dispose of any rights and obligations under these terms of use without
        the prior consent of IBBX. IBBX may assign the contract or the rights
        arising from it to any of the economic group companies of which it is a
        part or may become a part in the future, obliging it to inform the user
        of its intention.
      </p>
      <p>
        16.8. Taxes and other fiscal charges due, directly or indirectly, by
        virtue of amounts paid or received through this contract or its
        execution, will be the responsibility of the taxpayer defined in the tax
        law, without the right to reimbursement.
      </p>
      <p>
        16.9. These terms of use (together with any terms and conditions agreed
        upon between the parties, including the commercial proposal, Privacy
        Policy, and licensing agreement) constitute the entire agreement between
        the parties and supersede all previous agreements.
      </p>
      <p>
        <strong>13. Jurisdiction and Applicable Law</strong>
      </p>
      <p>
        13.1. This contract will be governed by Brazilian law, with the parties
        electing the forum of Capivari, São Paulo, as the sole competent court
        to hear and settle any issues arising from this contract, expressly
        waiving any other jurisdiction, however privileged it may be.
      </p>
      <p>
        13.2. These terms of use and all relations arising from them are subject
        to the laws of the Federative Republic of Brazil.
      </p>
      <p>
        <strong>PRIVACY POLICY</strong>
      </p>
      <p>
        In line with its transparency policy with clients and pursuant to what
        is established by Law 13.709, dated August 14, 2018, IBBX describes and
        implements its Privacy Policy as follows.
      </p>
      <p>
        <strong>Definitions</strong>
      </p>
      <p>
        <strong>General Data Protection Law (LGPD)</strong>: regulates the
        processing of personal data, including in digital media, by natural or
        legal persons, public or private, with the aim of protecting fundamental
        rights of freedom, privacy, and the free development of the natural
        person's personality;
      </p>
      <p>
        <strong>Personal Data:</strong> information related to an identified or
        identifiable natural person;
      </p>
      <p>
        <strong>Sensitive Personal Data:</strong> personal data about racial or
        ethnic origin, religious belief, political opinion, union membership, or
        membership in religious, philosophical, or political organizations,
        health or sex life data, genetic or biometric data, when linked to a
        natural person;
      </p>
      <p>
        <strong>Anonymized Data:</strong> data related to a data subject that
        cannot be identified, considering the use of reasonable and available
        technical means at the time of processing;
      </p>
      <p>
        <strong>Data Subject:</strong> natural person to whom the personal data
        being processed refers;
      </p>
      <p>
        <strong>Controller:</strong> natural or legal person, public or private,
        responsible for decisions regarding the processing of personal data;
      </p>
      <p>
        <strong>Processor:</strong> natural or legal person, public or private,
        who processes personal data on behalf of the controller;
      </p>
      <p>
        <strong>Data Processing Agents:</strong> the controller and the
        processor;
      </p>
      <p>
        <strong>Processing:</strong> any operation performed with personal data,
        including collection, production, reception, classification, use,
        access, reproduction, transmission, distribution, processing, archiving,
        storage, elimination, evaluation, or control of information,
        modification, communication, transfer, dissemination, or extraction;
      </p>
      <p>
        <strong>Consent:</strong> free, informed, and unambiguous expression by
        which the data subject agrees to the processing of their personal data
        for a specific purpose;
      </p>
      <p>
        <strong>International Data Transfer:</strong> transfer of personal data
        to a foreign country or international organization of which the country
        is a member;
      </p>
      <p>
        <strong>Shared Data Use:</strong> communication, dissemination,
        international transfer, interconnection of personal data, or shared
        processing of personal databases by public agencies and entities in the
        performance of their legal duties, or between these and private
        entities, reciprocally, with specific authorization, for one or more
        processing modalities allowed by these public entities or between
        private entities;
      </p>
      <p>
        <strong>Data Protection Impact Report (DPIA):</strong> controller
        documentation containing a description of personal data processing
        processes that may pose risks to civil liberties and fundamental rights,
        as well as measures, safeguards, and risk mitigation mechanisms;
      </p>
      <p>
        <strong>National Authority (ANPD):</strong> indirect public
        administration body responsible for ensuring, implementing, and
        overseeing compliance with this Law.
      </p>
      <p>
        <strong>Data Protection Officer (DPO):</strong> natural person appointed
        by the controller who acts as a communication channel between the
        controller, data subjects, and the national authority;
      </p>
      <p>
        <strong>Principles</strong>
      </p>
      <p>
        Regarding the processing of personal data from its clients and based on
        what is determined by art. 6 of Law 13.709/2018, IBBX observes the
        following principles:
      </p>
      <p>
        I – Purpose: processing carried out for legitimate, specific, explicit,
        and informed purposes to the data subject, with no possibility of
        subsequent processing incompatible with these purposes;
      </p>
      <p>
        II – Adequacy: compatibility of the processing with the purposes
        informed to the data subject, according to the context of the
        processing;
      </p>
      <p>
        III – Necessity: limitation of the processing to the minimum necessary
        to achieve its purposes, encompassing relevant, proportionate, and not
        excessive data in relation to the purposes of data processing;
      </p>
      <p>
        IV – Free Access: guarantee to data subjects of easy and free
        consultation on the form and duration of the processing, as well as on
        the completeness of their personal data;
      </p>
      <p>
        V – Data Quality: guarantee to data subjects of accuracy, clarity,
        relevance, and updating of data, as needed and for the fulfillment of
        the purpose of their processing;
      </p>
      <p>
        VI – Transparency: guarantee to data subjects of clear, precise, and
        easily accessible information about the processing and the respective
        data processing agents, subject to trade secrets and industrial secrets;
      </p>
      <p>
        VII – Security: use of technical and administrative measures capable of
        protecting personal data from unauthorized access and accidental or
        unlawful destruction, loss, alteration, communication, or dissemination;
      </p>
      <p>
        VIII – Prevention: adoption of measures to prevent damages arising from
        the processing of personal data;
      </p>
      <p>
        IX – Non-discrimination: impossibility of processing for illicit or
        abusive discriminatory purposes;
      </p>
      <p>
        X – Accountability and Demonstrability: demonstration by the agent of
        the adoption of effective measures capable of proving compliance with
        data protection rules and the effectiveness of these measures.
      </p>
      <p>
        <strong>
          Processing of Personal Data (Lifecycle of Personal Data)
        </strong>
      </p>
      <p>
        The IBBX industrial monitoring platform operates on the Platform as a
        Service (PaaS) model. The PaaS operates by collecting information
        through Sensors and Gateways (Hardware). The IBBX Platform collects data
        through wireless sensors, transmitting this data to IBBX gateways,
        through the proprietary IBBX L2RC communication protocol. From a generic
        communication between the gateways and the cloud, the collected data
        will be analyzed by the IBBX Retina software, providing the customer
        with real-time monitoring of monitored assets and decision support.
        After data input, the data subject's personal information will be
        processed in a standardized manner, as described below:
      </p>
      <p>
        <strong>1. Data Origin</strong>
      </p>
      <p>
        The personal and sensitive data that IBBX undertakes to control and
        protect comes from the following sources:
      </p>
      <p>(i) personal data of IBBX employees;</p>
      <p>(ii) personal data of potential clients and clients of IBBX;</p>
      <p>(iii) personal data of suppliers and partners of IBBX.</p>
      <p>
        <strong>2. Purpose</strong>
      </p>
      <p>Considering:</p>
      <p>a) personal data of IBBX employees</p>
      <p>
        The collection of personal data from IBBX employees will not be used for
        subsequent processing other than already stated, except in cases of
        legal necessity (response to labor liabilities, court requests for legal
        proceedings, etc.).
      </p>
      <p>
        b) personal data of potential clients, clients, and partners of IBBX
      </p>
      <p>
        The collection of personal data from potential clients, clients, and
        partners of IBBX is specifically intended for marketing IBBX products
        and services and service provision. Personal data will be used for
        sending messages with commercial context, as well as information related
        to products and services offered by IBBX.
      </p>
      <p>
        The data will not be used for subsequent processing other than already
        stated, such as database enrichment, sale of databases to third parties,
        etc.
      </p>
      <p>c) personal data of suppliers and partners of IBBX.</p>
      <p>
        The collection of personal data from suppliers and partners of IBBX is
        specifically intended for commercial contracting for service provision.
        Personal data will be used to comply with specific legislation regarding
        the issuance of invoices and related matters, thus having legal support.
      </p>
      <p>
        The data will not be used for subsequent processing other than already
        stated, except in cases of legal necessity (response to labor
        liabilities, court requests for legal proceedings, etc.).
      </p>
      <p>
        <strong>3. Collection</strong>
      </p>
      <p>
        The data described in item 1 (Data Origin) will be collected through
        wireless sensors, transmitting this data to IBBX gateways, through the
        proprietary IBBX L2RC communication protocol.
      </p>
      <p>
        <strong>4. Classification</strong>
      </p>
      <p>Considering:</p>
      <p>a) personal data of IBBX employees</p>
      <p>
        For the collection of personal data from IBBX employees, there is no
        requirement for consent from data subjects as the personal data are
        supported by legal basis.
      </p>
      <p>b) personal data of potential clients and clients of IBBX</p>
      <p>
        When registering personal data for access to tests and/or purchase of
        IBBX products and services, the data subject is informed about the IBBX
        Privacy Policy (available on the website{" "}
        <a href="https://ibbx.tech\">ibbx.tech</a>) and how their data will be
        processed.
      </p>
      <p>
        By agreeing to the IBBX Privacy Policy, the data subject formally
        consents to the use of their personal and business data, in accordance
        with what is described in this document.
      </p>
      <p>
        Non-formal consent to the use of personal data by IBBX and/or its
        operators may result in potential clients or clients of IBBX facing
        access restrictions to advertised and marketed products and services.
      </p>
      <p>c) personal data of suppliers and partners of IBBX</p>
      <p>
        When initiating a relationship with IBBX, suppliers and partners are
        informed about the IBBX Privacy Policy.
      </p>
      <p>
        Formal consent occurs upon signing the service provision contract
        between the parties.
      </p>
      <p>
        By agreeing to the IBBX Privacy Policy, the data subject formally
        consents to the use of their personal data, in accordance with what is
        described in this document.
      </p>
      <p>
        Non-formal consent to the use of personal data by IBBX and/or its
        operators may result in potential suppliers or partners of IBBX being
        unable to provide services.
      </p>
      <p>
        <strong>5. Access</strong>
      </p>
      <p>
        IBBX ensures data subjects free access to their data upon prior written
        request. Using unique login and password provided by IBBX to the data
        subject, they can access their personal data at any time and request
        deletion, alteration/update, or any related information about this
        content.
      </p>
      <p>
        <strong>6. Modification</strong>
      </p>
      <p>
        IBBX is the only entity authorized to modify, correct, or alter the data
        subject's personal data, upon formal request (authorization) from the
        data subject.
      </p>
      <p>
        IBBX reserves the right to make requested modifications within 30 days
        of the formal request date.
      </p>
      <p>
        <strong>7. Update</strong>
      </p>
      <p>
        When necessary, and to ensure that the data subject's personal data
        remain valid, IBBX may update them via email or phone contact.
      </p>
      <p>
        <strong>8. Reproduction</strong>
      </p>
      <p>
        The reproduction of personal data that IBBX has access to considers:
      </p>
      <p>a) personal data of IBBX employees</p>
      <p>
        Employee personal data are reproduced for the IBBX accounting company
        (operator), responsible for processing payments and other labor rights
        guaranteed by law.
      </p>
      <p>b) personal data of potential clients and clients of IBBX</p>
      <p>
        IBBX reproduces the personal data of its potential clients and clients
        to partner companies (operators), which are instructed to process the
        data in accordance with IBBX's instructions (controller), as described
        in this document.
      </p>
      <p>c) personal data of suppliers and partners of IBBX</p>
      <p>
        Personal data of suppliers and partners of IBBX are reproduced for the
        IBBX accounting company (operator), responsible for processing payments
        and other commercial rights guaranteed by law.
      </p>
      <p>
        <strong>9. Transmission</strong>
      </p>
      <p>
        All personal data that IBBX has access to are transmitted to operators
        through password-protected spreadsheets and/or email.
      </p>
      <p>
        <strong>10. Processing</strong>
      </p>
      <p>
        Partner companies related to IBBX are instructed to process personal
        data according to the purpose stated in this document (IBBX Privacy
        Policy), and are prohibited from enriching databases or using them for
        any purpose not communicated and formalized by IBBX.
      </p>
      <p>
        <strong>11. Archiving</strong>
      </p>
      <p>
        Personal data controlled by IBBX are stored in IBBX's database and on
        partner company operators' servers (cloud).
      </p>
      <p>
        <strong>12. Deletion</strong>
      </p>
      <p>
        Personal data controlled by IBBX are archived in IBBX's database and on
        partner company operators' servers (cloud) indefinitely, unless
        requested for deletion by the data subject.
      </p>
      <p>
        Employee personal data are only deleted after the applicable legal
        deadlines for each type of information have expired.
      </p>
    </>
  );
}
