import { ReactGoogleChartEvent } from "react-google-charts";
import { useTheme } from "styled-components";
import { useEffect, useMemo, useState, memo } from "react";
import { isMobile } from "../../../utils/mediaQuery";

import { ChartProps, eventCallbacksArgs } from "./types";
import { getOptions } from "../../../utils/charts/getOptions";
import {
  getTooltips,
  hideInvisibleSerie,
  setTooltips,
} from "../../../utils/charts/general";
import { eventSelectGlobal } from "../../../utils/charts/chartEvents";
import LineChart from "../LineChart";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { appSelect } from "../../../store/slices/appSlice";
import buildChartTicks from "../../../utils/buildChartTicks";

const ChartFunction = ({
  chart,
  chartData,
  seriesControl,
  handleClickPoint,
  analysis,
  dateFormat,
  chartArea,
  multipleAxis,
  yTicks,
}: ChartProps) => {
  const { currentPosition: position } = useAppSelector(appSelect);
  const theme = useTheme();
  const { colors } = theme;
  const [values, setValues]: any = useState({ data: [], tooltips: [] });
  const { customItems } = useAppSelector(appSelect);

  const { t } = useTranslation();

  const options = useMemo(() => {
    let ticks = buildChartTicks(chartData);

    const options = getOptions({
      chartData,
      colors,
      seriesControl,
      analysis,
      dateFormat,
      isMobile: isMobile(),
      ticks,
      chartArea,
      chart,
      position,
      multipleAxis,
      yTicks,
    });

    if (yTicks && multipleAxis) {
      options.vAxes[1].gridlines = {
        color: "transparent",
        count: 0,
      };
    }

    return options;
    // eslint-disable-next-line
  }, [
    chartData,
    colors,
    seriesControl,
    analysis,
    dateFormat,
    chartArea,
    chart,
  ]);

  useEffect(() => {
    if (!chartData?.data) {
      setValues([{ data: [], tooltips: [] }]);
      return;
    }

    const { data: dataRaw, series } = chartData;
    const data = [series, ...(dataRaw || [])];

    const tooltips = getTooltips({
      dataRaw,
      seriesControl,
      chart,
      t: t,
      position,
      frequencyUnitCPM:
        customItems?.frequencyUnit === "CPM" && chart.name !== "HAR-CORR",
    });

    setValues({ data, tooltips });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  const displayData = useMemo(() => {
    const { data: dataRaw, tooltips: tooltipsRaw } = values;
    var data = dataRaw;
    var tooltips = tooltipsRaw;
    const { minValue } = chartData;

    if (seriesControl.filter((item) => !item.visible).length) {
      data = hideInvisibleSerie(data, seriesControl, +minValue);
    }

    data = setTooltips(data, tooltips);

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, analysis, seriesControl]);

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      eventName: "select",
      callback: ({ chartWrapper }: eventCallbacksArgs) => {
        eventSelectGlobal({
          chartWrapper,
          chartData,
          handleClickPoint,
          ticks: chartData.data.map(([date]: any) => date),
        });
      },
    },
  ];

  return (
    <LineChart
      chart={chart}
      options={options}
      data={displayData}
      chartEvents={isMobile() ? undefined : chartEvents}
      harmonics={!!analysis?.harmonics}
      animated={chart.type !== "DYNAMIC" && !analysis}
    />
  );
};

export const Chart = memo(ChartFunction);
