import { useRef } from "react";
import { Modal } from "../../components/Modal";
import { Container, Header, Main } from "./styles";
import { methodOptions } from "./types";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";
import useApi from "../../hooks/useApi";
import { success } from "../../components/Toast";
import { validateUrl } from "../../utils/utilsFunctions";
import { Formik, FormikProps } from "formik";
import FormSelect from "../../components/FormSelect";
import { FormikTextInput } from "../../components/textInput";
import { useSegment } from "../../hooks/useSegmentTrackEvent ";
import { t } from "i18next";

interface IAddEventsModal {
  show: boolean;
  handleClose: () => void;
  refresh: () => void;
}

export function AddEventsModal({
  refresh,
  show,
  handleClose,
}: IAddEventsModal) {
  const app = useAppSelector(appSelect);
  const { handleTrackEvent } = useSegment();

  const { id: companyId } = app.currentCompany;

  const { request, processing } = useApi({
    path: `/companies/${companyId}/api-triggers`,
  });

  const handleCLoseAndClean = () => {
    handleClose();
  };

  const handleSubmit = (values: any) => {
    if (processing) return;

    const body = {
      companyId,
      ...values,
    };

    request({ method: "post", body }).then((response) => {
      handleCLoseAndClean();
      success(`${t("api.Gatilho salvo com sucesso!")}`);
      refresh();
    });
  };

  const formRef = useRef<FormikProps<any>>(null);

  const triggerOptions = [
    { label: `${t("api.OcorrênciaCriada")}`, value: "OCCURRENCE_CREATED" },
  ];

  return (
    <Modal
      show={show}
      handleClose={handleCLoseAndClean}
      handleConfirm={() => {
        formRef.current?.handleSubmit();
        handleTrackEvent({
          event: "Api Novo evento - Salvar",
        });
      }}
      size="lg"
    >
      <Formik
        innerRef={formRef}
        initialValues={{ method: "POST", type: "OCCURRENCE_CREATED" }}
        onSubmit={handleSubmit}
        validate={(values) => {
          var errors: any = {};

          if (!values.url) {
            errors.url = `${t("api.Informe alguma Url")}`;
          } else if (!validateUrl(values.url)) {
            errors.url = `${t("api.Informe uma Url válida")}`;
          }

          return errors;
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Container>
            <Header>
              <span>{t("api.Novo Evento")}</span>
            </Header>
            <Main>
              <FormSelect
                name="type"
                label={`${t("api.Tipo de Evento")}`}
                options={triggerOptions}
                handleChange={(e) => {
                  setFieldValue("type", e.value);
                  handleTrackEvent({
                    event: `Api Novo Evento ${e.value}`,
                  });
                }}
                placeholder={`${t("api.Tipo de Evento")}`}
                notClearable
              />
              <FormSelect
                name="method"
                label={`${t("api.Método")}`}
                disabled={true}
                options={Object.values(methodOptions)}
                handleChange={(e) => setFieldValue("method", e.value)}
              />
              <FormikTextInput
                name="url"
                label={`${t("api.Endereço")}`}
                placeholder={`${t("api.Insira a URL")}`}
                error={errors.url}
              />
              <FormikTextInput
                name="bearer"
                label={`${t("api.Token de Autorização da URL")}`}
                type="textarea"
                placeholder={`${t("api.Token de Autorização da URL")}`}
              />
            </Main>
          </Container>
        )}
      </Formik>
    </Modal>
  );
};
