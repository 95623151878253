export const renderExtraInformations = [
  {
    title: "esg.plywoodTrees.upperTitle",
    text: "esg.plywoodTrees.text",
    link1:
      "https://www.gov.br/inmetro/pt-br/assuntos/acreditacao/comunicados/dicor-alerta/comunicado-do-programa-brasileiro-ghg-protocol-pbghg-valido-para-o-ciclo-2023-em-diante-sobre-verificacoes-nao-presenciais",
  },
  {
    title: "esg.tonOfCO2Compensated.upperTitle",
    text: "esg.tonOfCO2Compensated.text",
    link1:
      "https://www.gov.br/inmetro/pt-br/assuntos/acreditacao/comunicados/dicor-alerta/comunicado-do-programa-brasileiro-ghg-protocol-pbghg-valido-para-o-ciclo-2023-em-diante-sobre-verificacoes-nao-presenciais",
  },
  {
    title: "esg.batteriesNotDiscarded.upperTitle",
    text: "esg.batteriesNotDiscarded.text",
    link1:
      "https://www.tme.com/br/pt/news/library-articles/page/40692/Pilhas-de-litio-alcalinas-de-prata-e-outras-quais-sao-os-tipos-de-pilhas/",
    link2: "https://www.of-things.de/battery-life-calculator.php",
  },
  {
    title: "esg.undisposedToxicWaste.upperTitle",
    text: "esg.undisposedToxicWaste.text",
    link1: "https://en.wikipedia.org/wiki/AA_battery",
  },
  {
    title: "esg.energySaved.upperTitle",
    text: "esg.energySaved.text",
  },
  {
    title: "esg.ofEnergySaving.upperTitle",
    text: "esg.ofEnergySaving.text",
  },
  {
    title: "esg.dataBasedDecisionMaking.upperTitle",
    text: "esg.dataBasedDecisionMaking.text",
  },
];
