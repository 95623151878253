import { MdMonitor } from "react-icons/md";
import {
  Checkbox,
  Container,
  ContentIcons,
  Footer,
  IconsContent,
  SaveButton,
} from "./styles";
import { VscBell, VscListSelection } from "react-icons/vsc";
import { FaLeaf } from "react-icons/fa";
import useApi from "../../../../hooks/useApi";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { userSelect } from "../../../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedIcons } from "../../../../store/slices/navbarIconsSlice";
import { success } from "../../../../components/Toast";
import { useTranslation } from "react-i18next";
import { TbAppsFilled } from "react-icons/tb";
import { BsBroadcastPin } from "react-icons/bs";

export function CustomNavbarIcons() {
  const user = useAppSelector(userSelect);
  const path = `/users/${user.id}`;
  const { request } = useApi({ path });

  const [getCustomIcons, setGetCustomIcons] = useState({ icons: [] });

  const selectedIcons = useSelector(
    (state: any) => state.navbarIcons.selectedIcons,
  );
  const dispatch = useDispatch();

  const handleIconToggle = (iconId: string) => {
    const isSelected = selectedIcons.includes(iconId);
    const updatedIconsId = isSelected
      ? selectedIcons.filter((icon: any) => icon !== iconId)
      : [...selectedIcons, iconId];
    dispatch(setSelectedIcons(updatedIconsId));
  };

  const customItemsGet = () => {
    request({ method: "get" })
      .then((response) => setGetCustomIcons(response.customItems))
      .catch();
  };

  const handleSaveCustomIcons = () => {
    request({
      method: "put",
      body: {
        customItems: { ...getCustomIcons, icons: [...selectedIcons] },
      },
    }).then(() => success("Alterado com Sucesso"));
  };

  useEffect(() => {
    customItemsGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  const iconMap: any = {
    mainDashboard: {
      icon: <MdMonitor />,
      label: `${t("CustomNavbarIcons.generalDashboard")}`,
    },
    connectivity: {
      icon: <BsBroadcastPin />,
      label: `${t("CustomNavbarIcons.connectivityDashboard")}`,
    },
    occurrence: {
      icon: <VscListSelection />,
      label: `${t("CustomNavbarIcons.occurrenceDashboard")}`,
    },
    esgPanel: {
      icon: <FaLeaf />,
      label: `${t("CustomNavbarIcons.esgPannel")}`,
    },
    notifications: {
      icon: <VscBell />,
      label: `${t("CustomNavbarIcons.Notificações")}`,
    },
    hub: {
      icon: <TbAppsFilled />,
      label: "Hub",
    },
  };

  return (
    <Container>
      <span>{t("CustomNavbarIcons.select")}</span>
      <ContentIcons>
        {Object.keys(iconMap).map((iconId) => (
          <IconsContent key={iconId} onClick={() => handleIconToggle(iconId)}>
            <Checkbox
              type="checkbox"
              onChange={() => handleIconToggle(iconId)}
              checked={!selectedIcons.includes(iconId)}
            />
            {iconMap[iconId].icon}
            <span>{iconMap[iconId].label}</span>
          </IconsContent>
        ))}
      </ContentIcons>
      <Footer>
        <SaveButton type="button" onClick={() => handleSaveCustomIcons()}>
          {t("CustomNavbarIcons.save")}
        </SaveButton>
      </Footer>
    </Container>
  );
}
