export function TextTermsAndPoliciesPT() {
  return (
    <>
      <p>
        <strong>TERMOS GERAIS E CONDIÇÃO DE USO</strong>
      </p>
      <p>
        Este instrumento contém os termos gerais e condições de uso dos serviços
        de <em>Plataform as a Service</em> (PaaS), disponibilizados pela IBBX
        Inovação em Sistemas de Software e Hardware LTDA, empresa cadastrada no
        CNPJ sob o nº 31.958.399/0001-41, com sede na Rua Elizabeta Armelin
        Rossi, 69, Capivari, São Paulo, CEP 13.363-212, de agora em diante,
        denominada simplesmente IBBX.
      </p>
      <p>
        <strong>PREÂMBULO</strong>
      </p>
      <p>
        <strong>
          A ACEITAÇÃO DESTES TERMOS E CONDIÇÕES GERAIS É ABSOLUTAMENTE
          INDISPENSÁVEL À UTILIZAÇÃO DO
        </strong>{" "}
        <em>
          {" "}
          <strong>SOFTWARE</strong>
        </em>{" "}
        <strong>.</strong>
      </p>
      <p>
        Ao clicar na caixa que indica sua concordância, você estará aceitando os
        termos, declarando que leu e concordou com a versão mais recente dos
        termos de uso do <em>software</em>, vinculando-se, automaticamente, às
        regras aqui contidas. É necessário que o usuário leia os presentes
        termos de uso, atentando-se para todas as normas e políticas a ele
        relacionadas, incluindo suas normas específicas, restrições de uso e
        outros procedimentos ou condições antes de efetivar a contratação.
      </p>
      <p>
        <strong>
          Este contrato entra em vigor em relação ao usuário na data em que este
          clicar no botão &quot;aceito&quot;, sendo esta considerada a
          &quot;data efetiva&quot; da contratação.
        </strong>
      </p>
      <p>
        O usuário declara estar ciente de que as operações que correspondam à
        aceitação do presente contrato, de determinadas condições e opções, bem
        como eventual rescisão do presente instrumento e demais alterações,
        serão registradas nos bancos de dados do <em>software</em>, juntamente
        com a data e hora em que foram realizadas pelo usuário, podendo tais
        informações serem utilizadas como prova pelas partes, independentemente
        do cumprimento de qualquer outra formalidade.
      </p>
      <p>
        <strong>O</strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>{" "}
        <strong>
          não presta consultoria profissional relacionada às áreas jurídica,
          financeira, contábil, fiscal, ou de outros serviços ou consultoria
          profissional, devendo o usuário consultar os serviços de um
          profissional competente quando precisar desse tipo de assistência.
        </strong>
      </p>
      <p>
        Se o usuário estiver aceitando este termo em nome de terceiros, declara
        e garante que: (i) tem plena autoridade legal para sujeitar seu
        empregador (ou a entidade em questão) a estes termos e condições; (ii)
        leu e entendeu este contrato e (iii) concorda, em nome da parte que você
        representa, com os termos deste instrumento.
      </p>
      <p>
        <strong>
          A IBBX se reserva o direito de alterar este contrato a qualquer
          momento e que as alterações entrem em vigor quando divulgadas por meio
          de seu
        </strong>{" "}
        <em>
          {" "}
          <strong>website</strong>{" "}
        </em>{" "}
        <strong>
          ou quando informar o usuário por outros meios. Nestes casos, a
          continuação da utilização pelo usuário indicará sua concordância com
          as alterações.
        </strong>
      </p>
      <p>
        Registrando-se, acessando e utilizando o <em>software</em> de qualquer
        forma, incluindo navegação, visualização, <em>download</em>, geração,
        recebimento e transmissão de quaisquer dados, informações ou mensagens,
        você manifesta sua expressa concordância, em seu nome e em nome da sua
        empresa ou em nome do seu empregador para com estes termos, conforme
        periodicamente atualizados, seja você usuário registrado dos serviços ou
        não, pelo que você se compromete a respeitar e cumprir todas as
        disposições aqui contidas, bem como as disposições dos avisos legais que
        regulam a utilização dos aplicativos e dos serviços.
      </p>
      <p>
        <strong>1. Definições</strong>
      </p>
      <p>
        1.1. Para efeitos do presente instrumento, todas as palavras e
        expressões constantes da lista abaixo, deverão ser entendidas conforme o
        respectivo significado:
      </p>
      <p>
        1.1.1. <strong>Usuário:</strong> é a pessoa física responsável pela
        gestão do cadastro e pela utilização em nome do estabelecimento
        empresarial que deseja gerenciar seus dados a partir do uso do serviço
        oferecido pelo <em>software</em>.
      </p>
      <p>
        1.1.2.{" "}
        <em>
          {" "}
          <strong>Software</strong>
        </em>{" "}
        <strong>:</strong> é a ferramenta denominada &quot;retina&quot;. A{" "}
        <strong>Plataforma IBBX de monitoramento industrial</strong> opera do
        modelo de <em>Plataform as a Service</em> (PaaS). A PaaS funciona
        mediante a coleta de informações por Sensores e <em>Gateways</em> (
        <em>Hardwares</em>). A Plataforma IBBX coleta dados por meio de sensores{" "}
        <em>wireless</em>, transmitindo estes dados aos <em>gateways</em> IBBX,
        por meio do protocolo de comunicação proprietário IBBX L2RC. A partir de
        uma comunicação genérica entre os <em>gateways</em> e a nuvem, os dados
        coletados serão analisados pelo <em>software</em> IBBXRetina.
      </p>
      <p>
        1.1.3. <strong>Informações de conta:</strong> são as informações e dados
        relativos aos colaboradores, ao estabelecimento empresarial, incluindo{" "}
        <em>logins</em>, senhas e demais informações necessárias para acessar,
        coletar, armazenar e tratar as informações obtidas pelo{" "}
        <em>software</em>.
      </p>
      <p>
        1.1.4. <strong>Informações pessoais:</strong> são quaisquer informações
        disponibilizadas pelo usuário que o identifique ou ao estabelecimento
        empresarial que representa, tais como nome, endereço, telefone, e-mail,
        número de documentos, etc.
      </p>
      <p>
        1.1.5. <strong>Infraestrutura:</strong> é o equipamento de armazenagem
        do <em>software</em> e do banco de dados utilizado por este,
        incluindo-se o servidor e demais equipamentos necessários.
      </p>
      <p>
        1.1.6. <strong>Implantação:</strong> entenda-se como capacitar o
        usuário-chave do estabelecimento empresarial contratante, para a
        perfeita e correta utilização do sistema, com o uso de uma base de dados
        de testes.
      </p>
      <p>
        1.1.7. <strong>Pessoal habilitado:</strong> são os colaboradores do
        estabelecimento empresarial contratante, que têm noções básicas de
        informática, conhecem o processo produtivo da empresa e recebem
        treinamento operacional do <em>software</em>, estando, portanto,
        certificados pela IBBX a operá-lo da melhor forma para obter os
        resultados a que o <em>software</em> se destina.
      </p>
      <p>
        1.1.8. <strong>Melhorias (</strong>
        <em>
          <strong>upgrades)</strong>
        </em>
        : significam alterações no <em>software</em> que melhoram seu desempenho
        e operacionalidade ou que incluam novas funcionalidades.
      </p>
      <p>
        <strong>2. Objeto</strong>
      </p>
      <p>
        2.1. O objeto deste contrato é o estabelecimento das condições de uso do{" "}
        <em>software</em> Retina da IBBX, na forma{" "}
        <em>&quot;Plataform as a Service</em>&quot;, que proporciona ao usuário
        o gerenciamento <em>online</em> de informações empresariais,
        especialmente, aquelas vinculadas às atividades de monitoramento
        industrial.
      </p>
      <p>
        2.1.1. O objeto contratado não inclui, de forma alguma, acesso à
        internet e infraestrutura local ao usuário. Desta forma, será de
        integral e exclusiva responsabilidade do usuário obter, de forma
        independente e às suas custas, o equipamento, <em>softwares</em> e os
        serviços necessários para garantir sua conexão à internet e ao{" "}
        <em>software</em> que proporciona acesso aos serviços.
      </p>
      <p>
        2.2. Qualquer pessoa que pretenda utilizar os serviços, deverá aceitar
        estes termos e as demais políticas, eventualmente, disponibilizadas.
      </p>
      <p>
        <strong>3. Capacidade para contratar e do cadastro</strong>
      </p>
      <p>
        3.1. Para utilizar o <em>software,</em> é necessária a realização de um
        cadastro prévio, no qual o usuário deve informar um e-mail válido, que
        será confirmado na sequência por meio do envio de um e-mail automático,
        bem como demais dados requeridos.
      </p>
      <p>
        3.2. O usuário deverá ter, no mínimo, 18 (dezoito) anos de idade
        completos, capacidade civil e declarar que leu, compreendeu, concordou e
        está integralmente de acordo com o disposto neste termo.
      </p>
      <p>
        3.3. O usuário declara que é responsável legal e/ou possui autorização
        para realizar a gestão necessária para adesão ao <em>software</em>.
      </p>
      <p>
        3.4. Quando solicitado, o usuário concorda em fornecer informações
        verdadeiras, corretas, atualizadas e completas (os &quot;dados de
        cadastro&quot;), conforme solicitados no formato de cadastro
        disponibilizado, sob pena de responsabilização nos termos da legislação
        aplicável vigente.
      </p>
      <p>
        3.5. A IBBX dependerá dos seus dados de cadastro para avaliar sua
        situação de negócio, para fornecer informação sobre os serviços, ou,
        alternativamente, para identificar e/ou entrar em contato com o usuário.{" "}
        <strong>
          Se os dados de cadastro não forem verdadeiros e corretos, ou estiverem
          desatualizados e incompletos, a IBBX poderá encerrar o licenciamento e
          todos os usos correntes ou futuros dos serviços, respeitando o
          contrato de licenciamento firmado entre as partes.
        </strong>
      </p>
      <p>
        3.6. O usuário receberá uma senha e designação de conta no momento em
        que completar o processo de cadastro, sendo certo que tais dados são
        pessoais e intransferíveis (os &quot;dados de acesso&quot;). O usuário é
        o único e exclusivo responsável por manter a confidencialidade de tais
        dados, bem como por todas as atividades que ocorrerem mediante o emprego
        de seus dados de acesso.
      </p>
      <p>3.6.1. Neste sentido, o usuário obriga-se a:</p>
      <p>
        <strong>(i)</strong> Notificar imediatamente a IBBX de qualquer uso não
        autorizado de seus dados de acesso ou qualquer outra violação de
        segurança, incluindo, mas não se limitando, o extravio, perda ou roubo
        de seus dados de acesso; e
      </p>
      <p>
        <strong>(ii)</strong> Efetuar <em>logout</em> em sua conta ao final de
        cada sessão de utilização;
      </p>
      <p>
        <strong>(iii)</strong> Notificar a IBBX sobre desligamentos de usuários
        do <em>software;</em>
      </p>
      <p>
        <strong>(iv)</strong> Manter os dados dos usuários do <em>software</em>{" "}
        atualizados.
      </p>
      <p>
        3.6.2. A IBBX não será responsável por qualquer perda ou dano decorrente
        da sua falha em cumprir ao que estabelece esta seção.
      </p>
      <p>
        <strong>
          4. Da responsabilidade de inserção e da gestão dos dados no
        </strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>
      </p>
      <p>
        4.1. Da responsabilidade quanto aos dados disponibilizados e do uso do{" "}
        <em>software</em>:
      </p>
      <p>
        <strong>
          4.2. A IBBX não se responsabiliza pela existência, quantidade,
          qualidade, estado, integridade ou legitimidade dos dados, conteúdos e
          informações inseridas pelo usuário, na medida em que não realiza
          qualquer controle quanto ao uso dos dados, restringindo-se a garantir
          o funcionamento do sistema.
        </strong>
      </p>
      <p>
        <strong>
          4.3. Em nenhum caso a IBBX será responsável pelo lucro cessante ou por
          qualquer outro dano e/ou prejuízo que o usuário possa sofrer devido às
          configurações estabelecidas no
        </strong>{" "}
        <em>
          {" "}
          <strong>software</strong>
        </em>
        <strong>.</strong>
      </p>
      <p>
        <strong>
          4.4. A responsabilidade pelos serviços prestados restringe-se à
          disponibilização de um
        </strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>{" "}
        <strong>
          de monitoramento industrial, bem como à prestação de suporte técnico e
          resolução de problemas, se necessário e em conformidade com o contrato
          de licenciamento.
        </strong>
      </p>
      <p>
        4.5. A IBBX não se responsabiliza por eventuais erros e/ou falhas
        apresentadas pelo <em>software</em> que tenham por causa problemas nos
        computadores, dispositivos móveis ou na rede utilizada pelo usuário,
        sendo certo que, nesses casos, o usuário assume toda a responsabilidade
        pelo erro e/ou falha apresentada.
      </p>
      <p>
        4.6. O usuário é responsável por toda a atividade que ocorrer em sua
        conta de acesso aos serviços.
      </p>
      <p>
        <strong>
          4.7. O usuário é exclusivamente responsável pelo cumprimento de todas
          as leis aplicáveis ao seu negócio, incluindo leis e regulamentos, e
          quaisquer licenças ou contratos a que estiver obrigado.
        </strong>
      </p>
      <p>
        4.8. O usuário garante que seus arquivos estão livres de quaisquer{" "}
        <em>malware</em>, vírus, cavalos de troia, <em>spyware</em>, vermes,
        outro código malicioso ou danoso.
      </p>
      <p>
        4.9. O usuário se responsabiliza por: (a) manter a confidencialidade da
        senha e das contas de administrador; (b) designar quem está autorizado a
        acessar as contas de administrador e (c) assegurar que todas as
        atividades que ocorrerem em conexão com as contas de administrador
        cumpram os direitos e deveres contidos nestes termos.
      </p>
      <p>
        4.10. A IBBX responsabiliza-se por manter as informações de conta,
        informações administrativas e informações pessoais do usuário, bem como
        seus registros de acesso, em sigilo, sendo que as referidas informações
        serão armazenadas em ambiente seguro, sendo respeitadas a intimidade, a
        vida privada, a honra e a imagem do usuário, em conformidade com as
        disposições da lei nº 12.965/2014 e com a política de privacidade.
      </p>
      <p>
        4.11. Exceto no caso de conduta dolosa comprovada, em nenhum momento a
        IBBX será responsável por perda ou dano de qualquer natureza que surja
        como resultado de mal uso do <em>software</em> ou de qualquer outra
        informação, dados, ou serviço fornecido através desta infraestrutura.
      </p>
      <p>
        4.12. Em nenhuma hipótese, a IBBX será responsável por quaisquer danos
        indiretos, de qualquer natureza, que possam, direta ou indiretamente,
        ser atribuíveis ao uso, ou à incapacidade de uso do <em>PaaS</em> ou
        serviço prestado por meio da infraestrutura, mesmo se advertido sobre a
        possibilidade de tais danos ou se tais danos eram previsíveis.
      </p>
      <p>
        4.13. A IBBX não é responsável perante o usuário por qualquer atraso ou
        não execução do <em>software</em>, ou falha em acessar a infraestrutura,
        decorrente de qualquer causa além do razoável controle.
      </p>
      <p>
        4.14. O usuário é responsável por todas as obrigações perante terceiros
        decorrentes da utilização do <em>software</em>, incluindo contratuais,
        trabalhistas, tributários e passivos regulatórios.
      </p>
      <p>
        4.15. A IBBX não garante que o <em>software</em> será ininterrupto ou
        livre de erros, que defeitos serão corrigidos ou que o servidor em que
        ele é disponibilizado ou qualquer sistema conectado a ele está livre de
        vírus ou outros componentes nocivos.
      </p>
      <p>
        <strong>4.16. A IBBX não se responsabiliza por:</strong>
      </p>
      <p>
        <strong>
          1. Falha de operação, operação por pessoas não autorizadas ou qualquer
          outra causa em que não tenha culpa;
        </strong>
      </p>
      <p>
        <strong>
          2. Cumprimento dos prazos legais do usuário para a entrega de
          documentos fiscais ou pagamentos;
        </strong>
      </p>
      <p>
        <strong>
          3. Danos ou prejuízos decorrentes de decisões administrativas,
          gerenciais ou comerciais tomadas com base nas informações fornecidas
          pelo
        </strong>{" "}
        <em>
          {" "}
          <strong>software</strong>
        </em>
        <strong>;</strong>
      </p>
      <p>
        <strong>
          4. Problemas advindos de casos fortuitos ou de força maior, nos termos
          da legislação;
        </strong>
      </p>
      <p>
        <strong>
          5. Eventuais problemas oriundos de ações de terceiros, que possam
          interferir na qualidade;
        </strong>
      </p>
      <p>
        <strong>
          6. Danos causados a terceiros em razão de culpa ou dolo do usuário;
        </strong>
      </p>
      <p>
        <strong>
          7. Revisar as informações de conta fornecidas pelo usuário, bem como
          as demais informações obtidas pelo usuário ou por
        </strong>{" "}
        <em>
          {" "}
          <strong>sites</strong>{" "}
        </em>{" "}
        <strong>
          de terceiros, seja no que tange à precisão dos dados quanto à
          legalidade, ameaça de violação, etc.
        </strong>
      </p>
      <p>
        4.17. O usuário declara, neste ato, que possui autoridade para fornecer
        as informações necessárias para o <em>software</em> e concorda que
        analisará e cumprirá todos os termos de produto e serviço de terceiros,
        não utilizará os serviços e produtos de terceiros de forma que infrinja
        ou viole direitos do <em>software</em> ou de terceiros, que arcará com
        os custos adicionais por ventura cobrados por terceiro por excedentes de
        acesso aos dados.
      </p>
      <p>
        4.18. A IBBX responsabiliza-se a adotar as medidas de segurança
        adequadas ao padrão de mercado para a proteção das informações do
        usuário.{" "}
        <strong>
          Contudo, o usuário, desde já, reconhece que nenhum sistema, servidor
          ou
        </strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>{" "}
        <strong>está absolutamente imune a ataques, não sendo o</strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>{" "}
        <strong>
          responsável por qualquer exclusão, obtenção utilização ou divulgação
          não autorizada de informações que seja resultante de ataques.
        </strong>
      </p>
      <p>4.19. O usuário expressamente declara e garante que:</p>
      <ol>
        <li>
          <p>
            Não utilizará o <em>software</em> para fins ilegais;
          </p>
        </li>
        <li>
          <p>
            Não veiculará informações sobre atividades ilegais e incitação ao
            crime;
          </p>
        </li>
        <li>
          <p>Não veiculará material pornográfico;</p>
        </li>
        <li>
          <p>
            Não divulgará informação relativa à pirataria do <em>software</em>;
          </p>
        </li>
        <li>
          <p>
            Não divulgará material protegido por direitos autorais ou
            confidencialidade sem a autorização dos seus titulares;
          </p>
        </li>
        <li>
          <p>Não se fará passar por outra pessoa, empresa ou entidade;</p>
        </li>
        <li>
          <p>Não coletará ou divulgará dados dos usuários;</p>
        </li>
        <li>
          <p>
            Não modificará, adaptará nem fará engenharia reversa do{" "}
            <em>software</em>;
          </p>
        </li>
        <li>
          <p>
            Não enviará mensagens não solicitadas, reconhecidas como &quot;
            <em>spam&quot;</em>, &quot;<em>junk mail</em>&quot; ou correntes de
            correspondência;
          </p>
        </li>
        <li>
          <p>
            Não utilizará o <em>software</em> para enviar quaisquer tipos de
            vírus de computador;
          </p>
        </li>
        <li>
          <p>Não obterá ou tentará obter acesso não autorizado;</p>
        </li>
        <li>
          <p>Não interferirá com o funcionamento normal da infraestrutura.</p>
        </li>
      </ol>
      <p>
        4.20. A IBBX reserva-se o direito de suspender ou bloquear imediatamente
        o acesso do usuário à infraestrutura e a remover quaisquer informações
        ou dados que considere uma violação de qualquer um destes termos gerais,
        sem aviso prévio e/ou disponibilizar dita informação quando solicitada
        por órgãos públicos ou por ordem judicial.
      </p>
      <p>
        4.21. A IBBX trabalha e envidará os seus maiores esforços para manter a
        infraestrutura e os serviços em funcionamento, contudo, todos os
        serviços <em>online</em> estão sujeitos a interrupções e paradas
        ocasionais.
      </p>
      <p>
        4.22. A IBBX compromete-se a prestar os serviços objeto deste contrato
        com emprego de pessoal qualificado e observando as melhores técnicas
        aplicáveis. Entretanto, o usuário será a parte responsável pela
        verificação da idoneidade dos dados de entrada e inserção de parâmetros
        operacionais configuráveis pelo usuário, bem como pelo controle da
        qualidade e consistência dos dados de saída e materiais gerados pelo
        emprego do <em>software</em>, os quais deverão verificar sempre antes de
        utilizá-los em quaisquer aplicações que dependam de forma crítica da sua
        exatidão.
      </p>
      <p>
        4.23. A IBBX responsabiliza-se perante o usuário pela observância das
        normas trabalhistas, previdenciárias e de higiene e segurança do
        trabalho relativas a todo o pessoal que direta ou indiretamente, sob
        qualquer modalidade, venha a utilizar para dar cumprimento ao objeto
        deste contrato.
      </p>
      <p>
        4.24.A IBBX compromete-se a repetir, sem qualquer ônus para o usuário,
        quaisquer serviços realizados de forma deficiente, assim como a reparar
        qualquer dano decorrente deste tipo de evento, desde que o usuário tenha
        previamente observado todas as condições previstas neste contrato.
      </p>
      <p>
        4.25. A IBBX não se responsabiliza, em nenhuma hipótese, por perdas ou
        geração de passivos fiscais, trabalhistas ou previdenciários para o
        estabelecimento empresarial do usuário por força de decisões de
        tribunais ou de alterações legislativas, municipais, estaduais ou de
        acordos/convenções de sindicatos que venham a alterar os parâmetros de
        cálculo no <em>software</em>, responsabilizando-se apenas pelas mudanças
        de legislações federais.
      </p>
      <p>
        <strong>
          4.26. A IBBX não se responsabiliza por quaisquer ações geradas antes
          da assinatura do presente contrato, especialmente por quaisquer
          cálculos e processos realizados por
        </strong>{" "}
        <em>
          {" "}
          <strong>softwares</strong>{" "}
        </em>{" "}
        <strong>
          anteriores, cujos dados foram migrados para o sistema da IBBX.
        </strong>
      </p>
      <p>4.27. Por fim, são responsabilidades do usuário:</p>
      <p>
        a) Operar o <em>software</em> em conformidade com as especificações e
        orientações da IBBX, incluindo-se o conjunto de atividades de
        preparação, seleção e digitação das informações inerentes e necessárias
        para atingir os objetivos a que se propõe;
      </p>
      <p>
        b) Disponibilizar a infraestrutura necessária para o funcionamento do{" "}
        <em>software</em>, ou seja, garantir o ambiente básico para execução dos
        módulos do <em>software</em>, identificados no anexo i, tal como{" "}
        <em>hardware</em> adequado (capacidade de processador, memória, espaço
        em disco, entre outros), sistema operacional, banco de dados ou outros
        softwares interdependentes, infraestrutura de comunicação (
        <em>links</em>, equipamentos de rede) e ambiente de trabalho;
      </p>
      <p>
        c) Realizar a manutenção dos arquivos necessários para acessar o
        ambiente do <em>PaaS</em>, assim como todo e qualquer dado armazenado na
        infraestrutura do estabelecimento empresarial do usuário, zelando pela
        total segurança dos mesmos, inclusive pela realização regular de cópias
        de segurança (<em>backup&#39;s</em>) com o uso dos meios apropriados e
        mídias comumente utilizadas para este fim;
      </p>
      <p>
        d) Controlar a qualidade das informações introduzidas e produzidas pelo{" "}
        <em>software</em> e a correta aplicação da legislação vigente, qual seja
        pertinente aos propósitos de uso do <em>software</em>;
      </p>
      <p>e) Repassar somente informações corretas e completas à IBBX;</p>
      <p>
        f) Garantir a disponibilidade de profissionais para o esclarecimento de
        dúvidas, a resolução de problemas e executar todas as atividades
        previamente definidas, bem como, efetuar todos os esforços para o
        cumprimento dos prazos estabelecidos;
      </p>
      <p>
        g) Resolver quaisquer problemas advindos do acesso via web, visto que as
        partes anuem que a IBBX não tem controle e/ou responsabilidade sobre a
        estrutura do estabelecimento empresarial do usuário tampouco sobre as
        ferramentas de proteção contra invasões remotas, sendo que qualquer
        invasão ou acesso remoto a dados ou sob sua guarda não significará em
        hipótese alguma falha no <em>software</em> ou na prestação de serviços
        da metadados;
      </p>
      <p>
        h) tomar todas as medidas necessárias para que o sistema seja utilizado
        com observância dos termos gerais e condições de uso e se
        responsabilizará por quaisquer violações à propriedade intelectual da
        IBBX ou de qualquer terceiro.
      </p>
      <p>
        <strong>5. Nível de serviço</strong>
      </p>
      <p>
        <strong>5.1. Por meio do</strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>{" "}
        <strong>
          a IBBX empreenderá esforços comercialmente razoáveis para tornar o
          software disponível, no mínimo, 99,7% (noventa e nove vírgula sete por
          cento) durante cada ano de serviço.
        </strong>
      </p>
      <p>
        5.1.1. Na hipótese de a IBBX não cumprir o compromisso de nível de
        serviço, o usuário terá o direito de receber o crédito correspondente a
        01 (um) mês de mensalidade.
      </p>
      <p>
        5.1.1.1. Por &quot;ano de serviço&quot; entendem-se os 365 (trezentos e
        cinco) dias precedentes à data de uma reivindicação relacionada ao nível
        de serviço.
      </p>
      <p>
        5.2. Se o usuário estiver utilizando o <em>software</em> durante período
        inferior a 365 dias, o ano de serviço que lhe corresponde será, ainda
        assim, considerado como os 365 dias precedentes; no entanto, os dias
        anteriores a seu uso dos serviços serão considerados como de 100% de
        disponibilidade. Os períodos de inatividade operacional que ocorrerem
        antes de uma reivindicação de crédito de serviço procedente não poderão
        ser usados para efeito de reivindicações futuras.
      </p>
      <p>
        <strong>
          5.3. O compromisso de nível de serviço não se aplica caso as
          circunstâncias de indisponibilidade resultem (i) de uma interrupção do
          fornecimento de energia elétrica ou paradas emergenciais não
          superiores a 2 (duas) horas ou que ocorram no período das 24h às 6:00h
          (horário de Brasília); (ii) forem causadas por fatores que fujam ao
          razoável controle do
        </strong>{" "}
        <em>
          {" "}
          <strong>software</strong>
        </em>
        <strong>
          , inclusive casos de força maior ou de acesso à internet e problemas
          correlatos; (iii) resultem de quaisquer atos ou omissões do usuário ou
          de terceiros; (iv) resultem do equipamento,
        </strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>{" "}
        <strong>
          ou outras tecnologias que o usuário usar e que impeçam o acesso
          regular; (v) resultem de falhas de instâncias individuais não
          atribuíveis à indisponibilidade do usuário; (vi) resultem de
          alterações realizadas na forma de acesso às informações de conta do
          usuário; (vii) resultem de práticas de gerenciamento da rede que
          possam afetar sua qualidade.
        </strong>
      </p>
      <p>
        <strong>6. Notificação sobre infrações</strong>
      </p>
      <p>
        6.1. Caso qualquer pessoa, usuário ou não, se sinta lesado em relação a
        qualquer problema gerado pelo <em>software</em>, poderá encaminhar à
        IBBX uma notificação, por escrito, relatando a demanda, a qual
        notificará o usuário com as providências cabíveis, sem que lhe seja
        imputada qualquer responsabilidade.
      </p>
      <p>
        6.2. A retirada de qualquer conteúdo ou usuário pelo <em>software</em>,
        de forma unilateral, dependerá de efetiva comprovação ou forte evidência
        da ilegalidade ou infração à lei, direitos de terceiros e/ou aos termos
        de uso.
      </p>
      <p>
        6.3. As notificações deverão ainda conter a identificação do material
        que supostamente representa a infração ou informações necessárias para a
        devida identificação do conteúdo e providências junto ao usuário.
      </p>
      <p>
        6.3.1. O notificante declarará que as informações contidas na
        notificação são precisas e verdadeiras, sob pena de incorrer nas
        consequentes responsabilidades cíveis e penais, e que o notificante está
        autorizado a agir em nome do titular do direito supostamente violado.
      </p>
      <p>
        6.4. As notificações deverão ser encaminhadas à plataforma para o
        seguinte e-mail:{" "}
        <a href="mailto:suporte@ibbx.tech">suporte@ibbx.tech</a>.
      </p>
      <p>
        <strong>7. Vigência</strong>
      </p>
      <p>
        7.1. O presente contrato vigerá enquanto perdurar o contrato de
        licenciamento firmado entre as partes em separado.
      </p>
      <p>
        <strong>8. Suporte técnico</strong>
      </p>
      <p>
        8.1. O serviço de suporte técnico consistirá em consultoria, via{" "}
        <em>chat</em> ou e-mail, para solução de dúvidas de caráter
        exclusivamente operacional dos usuários do <em>software</em>, às pessoas
        habilitadas e devidamente autorizadas pelo estabelecimento empresarial
        do usuário, e que será prestado pela IBBX, em sua sede, em horário
        comercial, entendido como das 09h00min às 12h00min e 13h30min às
        18h00min, horário de Brasília/BR, de segunda-feira a sexta-feira. A IBBX
        reserva-se o direito de solicitar material para análise que julgar
        necessário, nos padrões e nomenclaturas originais do <em>software</em>.
      </p>
      <p>
        8.2. Todas as consultas deverão ser feitas através de funcionários e/ou
        prepostos do estabelecimento empresarial contratante, previamente
        identificados, habilitados a trabalhar com o <em>software</em>, os quais
        deverão possuir conhecimento de sua operação, do equipamento utilizado,
        do sistema operacional, bem como de programas utilitários.
      </p>
      <p>
        8.3. A IBBX poderá exigir, a seu critério, o prévio cadastramento do
        pessoal habilitado como condição de acesso ao suporte técnico,
        recusando-se a fornecer o aludido serviço àqueles que não tenham
        recebido treinamento específico para operação do <em>software</em>.
      </p>
      <p>
        8.4. Caso haja necessidade de remessa de material para análise para a
        IBBX, nos casos em que não se configurar erro ou falha da IBBX, os
        tempos gastos para a resolução dos problemas serão faturados como
        assessoria operacional, mediante apresentação do respectivo relatório de
        atendimento ao estabelecimento empresarial contratante e após o prévio
        conhecimento deste.
      </p>
      <p>
        8.5. O suporte técnico, assim como a assessoria operacional, poderá ser
        prestado por acesso remoto, solução que utiliza ferramentas de
        comunicação e conexão que permite acessar o computador do contratante
        para atividades de verificação, ajustes e demais atividades inerentes ao
        suporte técnico e assessoria operacional sobre o <em>software</em>{" "}
        licenciado.
      </p>
      <p>
        <strong>9. Extinção do contrato</strong>
      </p>
      <p>
        9.1. O usuário e o <em>software</em> poderão rescindir estes termos e
        condições a qualquer momento, mediante notificação, em conformidade com
        o que reza o contrato de licenciamento firmado entre as partes.
      </p>
      <p>
        9.2. A IBBX reserva-se o direito de rescindir o contrato, restringir ou
        suspender o uso dos serviços por parte do usuário, mediante notificação,
        a qualquer tempo, sempre que a utilização dos serviços violar o presente
        termo e na forma do contrato de licenciamento.
      </p>
      <p>
        9.3. O usuário autoriza expressamente a IBBX a manter em seu cadastro as
        informações fornecidas por ele, bem como autoriza a fornecer as
        informações constantes de referido cadastro a autoridades públicas que
        as solicitarem conforme permitido pela legislação em vigor e a seus
        parceiros estratégicos, comerciais ou técnicos, com a finalidade de
        oferecer melhores condições de promoções e/ou conteúdo, desde que não
        individualize os usuários e estabelecimentos empresariais, nos termos da
        política de privacidade.
      </p>
      <p>
        <strong>10. Utilização da base de dados</strong>
      </p>
      <p>
        10.1. O usuário expressamente aceita que a IBBX e/ou qualquer de seus
        parceiros enviem mensagens de e-mail de caráter informativo, relativas a
        comunicações específicas referentes a serviços já disponibilizados ou
        que venham a ser disponibilizados, bem como outras mensagens de natureza
        comercial, nos termos da política de privacidade. Caso o usuário não
        deseje mais receber referidas mensagens, deverá solicitar o cancelamento
        do seu envio junto à IBBX.
      </p>
      <p>
        10.2. As informações referentes à data e hora de acesso e ao endereço de
        protocolo de internet utilizado pelo usuário para acessar o{" "}
        <em>software</em> permanecerão armazenadas por 06 (seis) meses, a contar
        da data de cada acesso realizado, independentemente do término da
        relação jurídica e comercial entre as partes, em cumprimento ao disposto
        no artigo 15 da lei nº 12.965/2014, podendo ser armazenados por um
        período maior de tempo mediante ordem judicial.
      </p>
      <p>
        <strong>11. Modificações</strong>
      </p>
      <p>
        11.1. A IBBX reserva o direito de modificar os termos e condições do
        presente termo ou as suas políticas relativas ao serviço a qualquer
        momento, efetivadas após a publicação de uma versão atualizada do termo
        sobre o serviço, em conformidade a este contrato.
      </p>
      <p>
        <strong>11. Direitos de Propriedade Intelectual</strong>
      </p>
      <p>
        11.1. Todos os direitos e propriedade intelectual no tocante ao{" "}
        <em>software</em> e à tecnologia permanecerão na propriedade exclusiva
        da empresa citada na qualificação deste contrato, mesmo que esta venha a
        desenvolver novas funcionalidades a pedido e remuneração do usuário.
        portanto, a tecnologia objeto do licenciamento pelo presente contrato,
        incluindo seus programas, fluxogramas, aperfeiçoamentos, adaptações e
        demais funcionalidades, assim como toda a documentação técnica são de
        propriedade total e definitiva da empresa supracitada, de forma que os
        direitos autorais e outros direitos de propriedade intelectual relativos
        ao mesmo são iguais aos conferidos às obras literárias, nos moldes da
        legislação de direitos autorais vigentes no país, conforme expressa
        determinação do artigo 2º e parágrafos da Lei 9.609/98.
      </p>
      <p>
        11.2. O usuário, ao adquirir o direito de uso do <em>software</em>,
        estará apenas autorizado a atualizá-lo na forma estabelecida no presente
        instrumento, sendo a ele vedada à utilização de métodos tais como,
        engenharia reversa, descompilação, ou qualquer outro, que possibilite o
        acesso ao código-fonte do <em>software</em>.
      </p>
      <p>
        11.3. O usuário não poderá ceder a terceiros o <em>software</em> e/ou
        documentação fornecidos pela IBBX sob qualquer pretexto,
        comprometendo-se por seus empregados ou prepostos a manter em boa guarda
        os acessos do <em>software</em> e a documentação recebida.
      </p>
      <p>
        11.4. O <em>software</em> é configurado em modo padronizado, tendo em
        vista a interpretação dominante acerca da aplicação da legislação a que
        este se propõe, sendo que em algumas hipóteses será possível alterar
        parâmetros, visando sua adaptação às situações específicas ou para que
        reflitam uma interpretação particular do próprio usuário acerca da
        legislação, mediante a assinatura de termo de anuência e/ou termo de
        responsabilidade por esta.
      </p>
      <p>
        <strong>
          11.5. Sempre que os parâmetros originais do sistema forem alterados
          por ordem do usuário, a IBBX será totalmente isenta de qualquer
          responsabilidade pela respectiva geração de dados, conforme termo de
          responsabilidade a ser assinado pela contratante.
        </strong>
      </p>
      <p>
        <strong>
          11.6. Sempre que a contratante assinar termo de anuência quanto aos
          parâmetros utilizados, a IBBX será isenta de qualquer responsabilidade
          pela respectiva geração de dados.
        </strong>
      </p>
      <p>
        11.7. Através deste contrato, é cedido ao usuário apenas o direito de
        uso da tecnologia em questão, sem a necessidade da apresentação ou
        fornecimento do código fonte ou estrutura interna do produto.
      </p>
      <p>
        11.8. O usuário também concorda que não utilizará nenhum robô ou outros
        dispositivos automatizados ou processos manuais para monitorar ou copiar
        conteúdo do serviço, sob pena de responder perante a IBBX pelas perdas e
        danos que causar, incluindo honorários advocatícios, quando necessários
        para a defesa dos interesses da IBBX.
      </p>
      <p>
        11.9. A IBBX reserva-se o direito de modificar, suspender, terminar ou
        descontinuar qualquer aspecto do <em>software</em> a qualquer tempo,
        incluindo a disponibilidade de quaisquer serviços, informações,
        características ou funcionalidades, bem como de impor limitações a
        certas características, funcionalidades ou serviços ou restringir
        usuário a partes ou à totalidade do <em>software</em>, mediante prévia
        notificação.
      </p>
      <p>
        11.9.1. A IBBX reserva-se, também, o direito de, a qualquer tempo ou
        título, controlar e/ou alterar a aparência, o desenvolvimento e/ou
        operações do <em>software</em> a seu exclusivo critério, bem como
        estabelecer e modificar os procedimentos para o contato entre as partes,
        sem a necessidade de notificação prévia.
      </p>
      <p>
        11.10. Qualquer violação ao direito do autor do <em>software</em>{" "}
        importará numa multa no valor de 100 (cem) vezes o valor mensal de uso
        do software, independente de perdas e danos apuradas judicialmente e
        interpelações criminais.
      </p>
      <p>
        <strong>12. Da assessoria operacional</strong>
      </p>
      <p>
        12.1. Será prestado o serviço de assessoria operacional para o usuário
        pela IBBX, mediante disponibilização um ou mais técnicos para
        atendimento, local ou remoto, que não seja coberta pela garantia do
        próprio <em>software</em> ou por qualquer dos demais serviços
        especificados neste contrato, aplicando-se, por exemplo, para
        treinamento de equipe do estabelecimento empresarial contratante, cópias
        de segurança, entre outras.
      </p>
      <p>
        12.2. Em caso de assessoria para recuperações de processamento, devido a
        erros operacionais por adoção de técnicas e métodos diversos dos
        instruídos no treinamento ou indicados na documentação disponibilizada
        pela IBBX, estes serviços estarão sujeitos a análises técnicas prévias,
        com consequentes orçamentações de custos determinados caso a caso.
      </p>
      <p>
        12.3. O tempo aplicado em consultas inerentes ao sistema operacional,
        aplicativos de terceiros ou utilitários e produtos não pertencentes à
        IBBX será considerado como assessoria operacional.
      </p>
      <p>
        12.4. A hora técnica da assessoria operacional será equivalente a 60
        (sessenta) minutos e o serviço será prestado em horário comercial,
        considerado das 08h00min às 12h00min e 13h15min às 18h00min, conforme
        horário de Brasília/BR, de segunda-feira a sexta-feira.
      </p>
      <p>
        12.4.1. Serviços prestados fora do horário supracitado serão acrescidos
        do percentual de hora extraordinária em 50% (cinquenta por cento), e
        quando prestados em sábados, domingos e feriados nacionais ou da cidade
        de Capivari-SP, o valor hora terá acréscimo de 100% (cem por cento).
      </p>
      <p>
        12.5. Os valores dos honorários por hora técnica de assessoria
        operacional serão os vigentes na época da execução dos serviços e serão
        faturados mensalmente, nos termos desse contrato.
      </p>
      <p>
        12.6. Os tempos gastos em deslocamentos serão cobrados tomando-se como
        base a unidade da IBBX mais próxima do usuário, mediante prévia
        negociação entre as partes.
      </p>
      <p>
        12.7. A assessoria operacional não poderá ser acionada pelo usuário para
        solicitar modificações estruturais no <em>software</em> ou
        desenvolvimento de outros módulos, ou funções não previstas no projeto
        original, não sendo a IBBX obrigada a aceitar solicitações neste
        sentido.
      </p>
      <p>
        12.8. Na hipótese de aceitar as modificações estruturais supracitadas, a
        IBBX apresentará orçamento prévio para realização do trabalho, devendo
        ser firmado instrumento de contrato específico para tal finalidade.
      </p>
      <p>
        12.9. Os serviços como recuperação de dados, configuração de periféricos
        e instalação de <em>softwares</em> de terceiros serão considerados como
        serviços de assessoria técnica, sujeita a cobrança mediante orçamento
        prévio.
      </p>
      <p>
        <strong>13. Da implantação do</strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>
      </p>
      <p>
        13.1. O processo de implantação deverá seguir a metodologia padrão da
        IBBX, salvo acordo entre as partes, devidamente documentado e aprovado
        em atas de reunião e em planos de ação. As definições das atividades a
        serem desenvolvidas, a metodologia empregada e o respectivo nível de
        prioridades são de competência e responsabilidade da IBBX, devendo ser
        integralmente seguidas pelo usuário.
      </p>
      <p>
        13.2. No caso de troca de qualquer componente do ambiente técnico de
        operação que implique atividades adicionais às previstas neste contrato,
        a IBBX deverá ser informada com antecedência mínima de 30 (trinta) dias,
        reservando-se o direito de manter ou não a implantação no novo ambiente,
        bem como de cobrar, ou não, mediante orçamento prévio, os custos de
        adaptação do cronograma. Neste caso, o prazo será estabelecido pela
        IBBX, que definirá também o novo valor da atualização.
      </p>
      <p>
        13.3. O estabelecimento empresarial contratante deverá designar um
        colaborador ou preposto como coordenador responsável pelo projeto de
        implantação e manutenção do <em>software</em>, com conhecimentos do
        ambiente operacional relacionado ao contrato de licenciamento.
      </p>
      <p>
        13.4. Caberá à IBBX o treinamento dos funcionários e/ou prepostos do
        estabelecimento empresarial contratante, visando habilitá-los,
        capacitá-los e certificá-los a operar todas as rotinas do{" "}
        <em>software</em>.
      </p>
      <p>
        13.5. A IBBX não se responsabiliza pelo treinamento dos usuários na
        operação do equipamento e de seu respectivo sistema operacional, e se
        reserva o direito de requerer a substituição daqueles que apresentem
        desempenho insatisfatório.
      </p>
      <p>
        13.6. Havendo a necessidade de retrabalho nas atividades de implantação,
        após a entrega do <em>software</em>, o tempo despendido para a execução
        destas tarefas será cobrado como assessoria operacional.
      </p>
      <p>
        13.7. A IBBX coordenará e realizará o processo de implantação do{" "}
        <em>software</em>, efetuando a inserção e a parametrização de
        informações e regras de negócio em total conformidade com a legislação
        vigente, devendo o estabelecimento empresarial contratante firmar o
        termo de anuência após analisar e validar os parâmetros inseridos no{" "}
        <em>software</em>.
      </p>
      <p>
        <strong>14. Da atualização e da customização do software</strong>
      </p>
      <p>
        14.1. A atualização do <em>software</em> compreende todas as alterações
        de programas e de sua respectiva documentação que a IBBX venha a criar e
        que torne necessária à sua atualização, complementação ou reprogramação,
        visando melhorias ou instalação de novas operações ou por alterações na
        legislação.
      </p>
      <p>
        14.2. A interpretação legal das normas editadas pelo governo e a sua
        implementação no <em>software</em> serão efetuadas com base nas
        publicações especializadas e divulgadas sobre cada matéria em veículos
        de comunicação de domínio público. Contudo, a responsabilidade pela
        anuência dos parâmetros será sempre do estabelecimento empresarial
        contratante e do usuário.
      </p>
      <p>
        14.3. As interpretações divergentes por parte do estabelecimento
        empresarial contratante e do usuário, quando implementadas, serão
        consideradas como customizações do <em>software</em>, sendo que a IBBX
        não será obrigada a implementar alterações única e exclusivamente
        baseada na avaliação de um usuário, mas se obrigará a fazê-lo segundo
        requerimento da maioria de seus estabelecimentos empresariais clientes.
      </p>
      <p>
        14.4. As modificações impostas por associações, sindicatos ou outros
        grupos específicos poderão ser implementadas mediante aprovação de
        orçamento prévio.
      </p>
      <p>
        14.5. As melhorias e as novas funções introduzidas pela IBBX no{" "}
        <em>software</em> originalmente licenciado serão distribuídas visando
        dotar o usuário sempre com a última versão do <em>software</em>.
      </p>
      <p>
        14.6. Poderão ser realizadas complementações no <em>software</em> pela
        IBBX por solicitação escrita do usuário. Nesse caso, serão cobradas como
        customizações as horas trabalhadas na sede do estabelecimento
        empresarial contratante para levantamentos, implantação e treinamento,
        mediante apresentação do relatório de atendimento.
      </p>
      <p>
        14.7. As atividades de análise, programação, testes, documentação e
        distribuição das alterações efetuadas na sede da IBBX serão faturadas
        conforme orçamento prévio ou apresentação de relatórios e resultados de
        cada fase.
      </p>
      <p>
        14.8. A garantia de atualização do software expressa nesta cláusula não
        abrange as customizações feitas no software por solicitação do usuário.
      </p>
      <p>
        14.9. A Propriedade Intelectual das customizações realizadas pela IBBX
        no <em>software</em>, mesmo que por solicitação do usuário, será
        exclusiva da IBBX e poderá ser incorporada às versões distribuídas a
        outros clientes desta, sem que caiba qualquer tipo de reembolso ou
        ressarcimento ao usuário ou ao estabelecimento empresarial contratante.
      </p>
      <p>
        <strong>15. Da confidencialidade</strong>
      </p>
      <p>
        15.1. A contratada declara que armazenará as informações do
        estabelecimento empresarial contratante e do usuário com a mesma
        segurança e dedicação para a respectiva proteção como se suas fossem,
        contratando equipamentos e <em>softwares</em> adequados para a guarda de
        todos os documentos e informações que estiverem em sua posse.
      </p>
      <p>
        15.2. As demais regulamentações a respeito da coleta, guarda e uso das
        informações estão devidamente tratadas na Política de Privacidade, sendo
        parte integrante deste documento.
      </p>
      <p>
        <strong>16. Das disposições gerais</strong>
      </p>
      <p>
        16.1. O usuário concorda que a IBBX poderá divulgar a celebração deste
        instrumento para fins comerciais, fazendo menção ao nome e à marca do
        usuário em campanhas comerciais, podendo, inclusive, divulgar mensagens
        enviadas de forma escrita ou oral, por telefone, para uso em{" "}
        <em>sites</em>, jornais, revistas e outras campanhas, mesmo após a
        rescisão destes termos.
      </p>
      <p>
        16.2. Os casos omissos e quaisquer dúvidas relativas à execução deste
        contrato serão resolvidos por meio de consultas e mútuos entendimentos
        entre as partes, assinando-se termo de aditamento sempre que necessário.
      </p>
      <p>
        16.3. As partes expressamente declaram e concordam que a demora ou
        omissão no exercício de direitos que lhes sejam assegurados por lei ou
        pelo presente instrumento não constituirá novação ou renúncia a tais
        direitos, nem prejudicará seu eventual e oportuno exercício.
      </p>
      <p>
        16.4. A renúncia a direitos que lhes assistam em razão de lei ou do
        presente contrato somente será válida se formalizada por escrito.
      </p>
      <p>
        16.5. A nulidade ou invalidade de qualquer das cláusulas do presente
        contrato não prejudicará a validade e eficácia das demais, que
        permanecerão em pleno vigor e efeito, devendo as partes, nesse caso,
        negociarem de boa-fé, a redação de nova cláusula em substituição à que
        for considerada nula ou inválida, refletindo a intenção das partes no
        presente contrato.
      </p>
      <p>
        16.6. Todas as correspondências, notificações e comunicações entre as
        partes deverão ser feitas via e-mail, carta protocolada, notificação
        cartorária ou qualquer outro meio idôneo que permita confirmação de
        recebimento, direcionadas aos endereços constantes do preâmbulo do
        presente contrato, devendo a parte que tiver alteração de endereço,
        e-mail ou telefone, comunicar a outra imediatamente, por escrito.
      </p>
      <p>
        16.7. O usuário não poderá ceder, sublicenciar, subcontratar, transferir
        ou dispor de quaisquer direitos e obrigações no âmbito destes termos de
        uso sem o consentimento prévio da IBBX. A IBBX poderá ceder o contrato
        ou os direitos dele decorrentes a qualquer uma das sociedades do grupo
        econômico do qual faz parte ou que possa vir a fazer parte no futuro,
        ficando obrigada a comunicar ao usuário sua intenção.
      </p>
      <p>
        16.8. Os tributos e demais encargos fiscais que forem devidos, direta ou
        indiretamente, em virtude dos valores pagos ou recebidos através deste
        contrato ou em virtude de sua execução serão de responsabilidade do
        contribuinte assim definido na norma tributária, sem direito a
        reembolso.
      </p>
      <p>
        16.9. Estes termos de uso (junto com quaisquer termos e condições
        firmadas entre as partes, incluindo a proposta comercial, a Política de
        Privacidade e o contrato de licenciamento) contém o acordo integral
        entre as partes e substituem todos os acordos anteriores.
      </p>
      <p>
        <strong>13. Do foro e da legislação aplicável</strong>
      </p>
      <p>
        13.1. O presente contrato será regido pela legislação brasileira,
        elegendo as partes o foro de Capivari, São Paulo, como único competente
        para conhecer e dirimir quaisquer questões oriundas do presente
        contrato, com expressa renúncia a qualquer outro, por mais privilegiado
        que seja.
      </p>
      <p>
        13.2. Estes termos de uso e todas as relações dele decorrentes são
        submetidas às leis da República Federativa do Brasil.
      </p>
      <p>
        <strong>POLÍTICA DE PRIVACIDADE</strong>
      </p>
      <p>
        Em alinhamento à sua política de transparência junto aos clientes e em
        decorrência do que estabelece a Lei 13.709, de 14 de agosto de 2018, a
        IBBX descreve e implementa sua Política de Privacidade, conforme segue.
      </p>
      <p>
        <strong>Definições</strong>
      </p>
      <p>
        <strong>Lei Geral de Proteção de Dados (LGPD)</strong>: dispõe sobre o
        tratamento de dados pessoais, inclusive nos meios digitais, por pessoa
        natural ou por pessoa jurídica de direito público ou privado, com o
        objetivo de proteger os direitos fundamentais de liberdade e de
        privacidade e o livre desenvolvimento da personalidade da pessoa
        natural;
      </p>
      <p>
        <strong>Dado pessoal:</strong> informação relacionada a pessoa natural
        identificada ou identificável;
      </p>
      <p>
        <strong>Dado pessoal sensível:</strong> dado pessoal sobre origem racial
        ou étnica, convicção religiosa, opinião política, filiação a sindicato
        ou a organização de caráter religioso, filosófico ou político, dado
        referente à saúde ou à vida sexual, dado genético ou biométrico, quando
        vinculado a uma pessoa natural;
      </p>
      <p>
        <strong>Dado anonimizado:</strong> dado relativo a titular que não possa
        ser identificado, considerando a utilização de meios técnicos razoáveis
        e disponíveis na ocasião de seu tratamento;
      </p>
      <p>
        <strong>Titular</strong>: pessoa natural a quem se referem os dados
        pessoais que são objeto de tratamento;
      </p>
      <p>
        <strong>Controlador:</strong> pessoa natural ou jurídica, de direito
        público ou privado, a quem competem as decisões referentes ao tratamento
        de dados pessoais;
      </p>
      <p>
        <strong>Operador:</strong> pessoa natural ou jurídica, de direito
        público ou privado, que realiza o tratamento de dados pessoais em nome
        do controlador;
      </p>
      <p>
        <strong>Agentes de tratamento:</strong> o controlador e o operador;
      </p>
      <p>
        <strong>Tratamento:</strong> toda operação realizada com dados pessoais,
        como as que se referem a coleta, produção, recepção, classificação,
        utilização, acesso, reprodução, transmissão, distribuição,
        processamento, arquivamento, armazenamento, eliminação, avaliação ou
        controle da informação, modificação, comunicação, transferência, difusão
        ou extração;
      </p>
      <p>
        <strong>Consentimento:</strong> manifestação livre, informada e
        inequívoca pela qual o titular concorda com o tratamento de seus dados
        pessoais para uma finalidade determinada;
      </p>
      <p>
        <strong>Transferência internacional de dados:</strong> transferência de
        dados pessoais para país estrangeiro ou organismo internacional do qual
        o país seja membro;
      </p>
      <p>
        <strong>Uso compartilhado de dados:</strong> comunicação, difusão,
        transferência internacional, interconexão de dados pessoais ou
        tratamento compartilhado de bancos de dados pessoais por órgãos e
        entidades públicos no cumprimento de suas competências legais, ou entre
        esses e entes privados, reciprocamente, com autorização específica, para
        uma ou mais modalidades de tratamento permitidas por esses entes
        públicos, ou entre entes privados;
      </p>
      <p>
        <strong>
          Relatório de impacto à proteção de dados pessoais (DPIA):
        </strong>{" "}
        documentação do controlador que contém a descrição dos processos de
        tratamento de dados pessoais que podem gerar riscos às liberdades civis
        e aos direitos fundamentais, bem como medidas, salvaguardas e mecanismos
        de mitigação de risco;
      </p>
      <p>
        <strong>Autoridade nacional (ANPD):</strong> órgão da administração
        pública indireta responsável por zelar, implementar e fiscalizar o
        cumprimento desta Lei.
      </p>
      <p>
        <strong>Encarregado (DPO):</strong> pessoa natural, indicada pelo
        controlador, que atua como canal de comunicação entre o controlador, os
        titulares e a autoridade nacional;
      </p>
      <p>
        <strong>Princípios</strong>
      </p>
      <p>
        Concernente ao tratamento dos dados pessoais oriundos de seus clientes e
        com base no que determina o art. 6º da Lei 13.709/2018, a IBBX observa
        os seguintes princípios:
      </p>
      <p>
        I – finalidade: realização do tratamento para propósitos legítimos,
        específicos, explícitos e informados ao titular, sem possibilidade de
        tratamento posterior de forma incompatível com essas finalidades;
      </p>
      <p>
        II – adequação: compatibilidade do tratamento com as finalidades
        informadas ao titular, de acordo com o contexto do tratamento;
      </p>
      <p>
        III – necessidade: limitação do tratamento ao mínimo necessário para a
        realização de suas finalidades, com abrangência dos dados pertinentes,
        proporcionais e não excessivos em relação às finalidades do tratamento
        de dados;
      </p>
      <p>
        IV – livre acesso: garantia, aos titulares, de consulta facilitada e
        gratuita sobre a forma e a duração do tratamento, bem como sobre a
        integralidade de seus dados pessoais;
      </p>
      <p>
        V – qualidade dos dados: garantia, aos titulares, de exatidão, clareza,
        relevância e atualização dos dados, de acordo com a necessidade e para o
        cumprimento da finalidade de seu tratamento;
      </p>
      <p>
        VI – transparência: garantia, aos titulares, de informações claras,
        precisas e facilmente acessíveis sobre a realização do tratamento e os
        respectivos agentes de tratamento, observados os segredos comercial e
        industrial;
      </p>
      <p>
        VII – segurança: utilização de medidas técnicas e administrativas aptas
        a proteger os dados pessoais de acessos não autorizados e de situações
        acidentais ou ilícitas de destruição, perda, alteração, comunicação ou
        difusão;
      </p>
      <p>
        VIII – prevenção: adoção de medidas para prevenir a ocorrência de danos
        em virtude do tratamento de dados pessoais;
      </p>
      <p>
        IX – não discriminação: impossibilidade de realização do tratamento para
        fins discriminatórios ilícitos ou abusivos;
      </p>
      <p>
        X – responsabilização e prestação de contas: demonstração, pelo agente,
        da adoção de medidas eficazes e capazes de comprovar a observância e o
        cumprimento das normas de proteção de dados pessoais e, inclusive, da
        eficácia dessas medidas.
      </p>
      <p>
        <strong>
          Tratamento de dados pessoais (Ciclo de vida dos dados pessoais)
        </strong>
      </p>
      <p>
        A Plataforma IBBX de monitoramento industrial opera no modelo{" "}
        <em>Plataform as a Service</em> (PaaS). A <em>PaaS</em> funciona
        mediante a coleta de informações por Sensores e Gateways (Hardwares). A
        Plataforma IBBX coleta dados por meio de sensores <em>wireless</em>,
        transmitindo estes dados aos gateways IBBX, por meio do protocolo de
        comunicação proprietário IBBX L2RC. A partir de uma comunicação genérica
        entre os gateways e a nuvem, os dados coletados serão analisados pelo
        software IBBX Retina, garantindo ao cliente capacidade de monitoramento
        em tempo real dos ativos monitorados e apoio a tomada de decisão. Após a
        introdução de dados, o titular terá suas informações pessoais tratadas
        de maneira padronizada, em conformidade descrito a seguir:
      </p>
      <p>
        <strong>1. Origem dos dados</strong>
      </p>
      <p>
        Os dados pessoais e sensíveis que a IBBX se compromete a controlar e
        proteger são oriundos das seguintes fontes:
      </p>
      <p>(i) dados pessoais dos colaboradores da IBBX;</p>
      <p>(ii) os dados pessoais de potenciais clientes e clientes IBBX;</p>
      <p>(iii) os dados pessoais de fornecedores e parceiros IBBX.</p>
      <p>
        <strong>2. Finalidade</strong>
      </p>
      <p>Considerando:</p>
      <p>a) os dados pessoais dos colaboradores da IBBX</p>
      <p>
        A coleta dos dados pessoais de colaboradores da IBBX não será usada para
        tratamento posterior diferente do já exposto, salvo em casos de
        necessidade legal (resposta à passivos trabalhistas, solicitação da
        justiça para fins processuais, etc).
      </p>
      <p>
        b) os dados pessoais de potenciais clientes, clientes e parceiros da
        IBBX
      </p>
      <p>
        A coleta dos dados pessoais de potenciais clientes, clientes e parceiros
        da IBBX, tem como finalidade específica a comercialização dos produtos e
        serviços oferecidos pela IBBX e a prestação de serviços. Os dados
        pessoais serão usados para envio de mensagens com contexto comercial,
        bem como informações relacionadas aos produtos e serviços oferecidos
        pela IBBX.
      </p>
      <p>
        Os dados não serão usados para tratamento posterior diferente do já
        exposto tal como enriquecimento de base de dados, venda de base de dados
        à terceiros, etc.
      </p>
      <p>c) os dados pessoais de fornecedores e parceiros da IBBX.</p>
      <p>
        A coleta dos dados pessoais de fornecedores e parceiros da IBBX tem como
        finalidade específica a contratação comercial para prestação de
        serviços. Os dados pessoais serão usados para cumprimento da legislação
        específica no que tange à emissão de notas fiscais e correlatos tendo,
        portanto, amparo legal.
      </p>
      <p>
        Os dados não serão usados para tratamento posterior diferente do já
        exposto, salvo em casos de necessidade legal (resposta à passivos
        trabalhistas, solicitação da justiça para fins processuais, etc).
      </p>
      <p>
        <strong>3. Coleta</strong>
      </p>
      <p>
        Os dados descritos no item 1 (Origem dos dados) serão coletados por meio
        de sensores <em>wireless</em>, transmitindo estes dados aos gateways
        IBBX, por meio do protocolo de comunicação proprietário IBBX L2RC
      </p>
      <p>
        <strong>4. Classificação</strong>
      </p>
      <p>Considerando:</p>
      <p>a) os dados pessoais dos colaboradores da IBBX</p>
      <p>
        Para coleta dos dados pessoais dos colaboradores da IBBX não há
        solicitação do consentimento de seus titulares por serem os dados
        pessoais amparados por base legal.
      </p>
      <p>b) os dados pessoais de potenciais clientes e clientes da IBBX</p>
      <p>
        Ao cadastrar os dados pessoais para acesso aos testes e/ou aquisição dos
        produtos e serviços da IBBX, o titular é informado sobre a Política de
        Privacidade IBBX (disponibilizada no site{" "}
        <a href="https://ibbx.tech\">ibbx.tech</a>) e sobre como seus dados
        serão tratados.
      </p>
      <p>
        Ao concordar com a Política de Privacidade da IBBX, o titular consente
        formalmente com o uso de seus dados pessoais e empresariais, em
        consonância com o descrito nesse documento.
      </p>
      <p>
        O não consentimento formal do uso de seus dados pessoais pela da IBBX
        e/ou seus operadores, pode acarretar ao potencial cliente ou cliente da
        IBBX, impedimentos ou restrições de acesso aos produtos e serviços
        divulgados e comercializados.
      </p>
      <p>c) os dados pessoais de fornecedores e parceiros da IBBX</p>
      <p>
        Ao iniciar um relacionamento com a da IBBX, os fornecedores e parceiros
        são comunicados sobre a Política de Privacidade da IBBX.
      </p>
      <p>
        A formalização do consentimento ocorre na assinatura do contrato de
        prestação de serviços entre as partes.
      </p>
      <p>
        Ao concordar com a Política de Privacidade da IBBX, o titular consente
        formalmente o uso de seus dados pessoais, em consonância com o descrito
        nesse documento.
      </p>
      <p>
        O não consentimento formal do uso de seus dados pessoais pela IBBX e/ou
        seus operadores, pode acarretar ao potencial fornecedor ou parceiro da
        IBBX a inviabilidade da prestação de serviços.
      </p>
      <p>
        <strong>5. Acesso</strong>
      </p>
      <p>
        A IBBX assegura ao titular o livre acesso a seus dados a partir de
        prévia solicitação por escrito. A partir de <em>login</em> e senha
        únicos fornecidos pela IBBX ao titular, o mesmo poderá acessar seus
        dados pessoais a qualquer momento e solicitar a exclusão,
        alteração/atualização ou quaisquer informações correlatas a esse
        conteúdo.
      </p>
      <p>
        <strong>6. Modificação</strong>
      </p>
      <p>
        A IBBX é a única autorizada a modificar, corrigir ou alterar os dados
        pessoais do titular, a partir da solicitação formal (autorização) do
        mesmo.
      </p>
      <p>
        A IBBX se reserva o direito de efetuar as modificações solicitadas em
        até 30 dias da data da solicitação formal.
      </p>
      <p>
        <strong>7. Atualização</strong>
      </p>
      <p>
        Quando necessário, e, para assegurar que os dados pessoais do titular
        permaneçam válidos, a IBBX poderá atualizá-los por meio de e-mail ou
        contato telefônico.
      </p>
      <p>
        <strong>8. Reprodução</strong>
      </p>
      <p>A reprodução dos dados pessoais que a IBBX tem acesso considera:</p>
      <p>a) os dados pessoais dos colaboradores da IBBX</p>
      <p>
        Os dados pessoais de colaboradores são reproduzidos para a empresa de
        contabilidade da IBBX (operadora), responsável pelo processamento dos
        pagamentos e demais direitos trabalhistas assegurados por lei.
      </p>
      <p>b) os dados pessoais de potenciais clientes e clientes da IBBX</p>
      <p>
        A IBBX reproduz os dados pessoais de seus potenciais clientes e clientes
        para empresas parceiras (operadoras), que são orientadas a tratar os
        dados em alinhamento ao que define a da IBBX (controlador), em
        conformidade com o descrito nesse documento.
      </p>
      <p>c) os dados pessoais de fornecedores e parceiros da IBBX</p>
      <p>
        Os dados pessoais de fornecedores e parceiros da IBBX são reproduzidos
        para a empresa de contabilidade da IBBX (operadora), responsável pelo
        processamento dos pagamentos e demais direitos comerciais assegurados
        por lei.
      </p>
      <p>
        <strong>9. Transmissão</strong>
      </p>
      <p>
        Todos os dados pessoais a que a IBBX tem acesso são transmitidos para as
        operadoras através de planilhas eletrônicas protegidas por senha e/ou
        por e-mail.
      </p>
      <p>
        <strong>10. Processamento</strong>
      </p>
      <p>
        As empresas operadoras que se relacionam com a IBBX são orientadas a
        tratar os dados pessoais de acordo com a finalidade explicitada nesse
        documento (Política de Privacidade da IBBX), sendo proibidas de
        enriquecimento de base e utilização destes para qualquer outra
        finalidade não comunicada e formalizada pela da IBBX.
      </p>
      <p>
        <strong>11. Arquivamento</strong>
      </p>
      <p>
        Os dados pessoais que a IBBX controla são armazenados no banco de dados
        da IBBX e nos servidores (<em>cloud</em>) de empresas parceiras
        operadoras.
      </p>
      <p>
        <strong>12. Eliminação</strong>
      </p>
      <p>
        Os dados pessoais que a IBBX controla ficam arquivados no banco de dados
        da IBBX e nos servidores (<em>cloud</em>) de empresas parceiras
        operadoras por tempo indeterminado, salvo quando solicitado a exclusão
        por parte do titular.
      </p>
      <p>
        Dados pessoais dos colaboradores da IBBX são eliminados somente após
        expirar os prazos legais aplicáveis a cada tipo de informação.
      </p>
    </>
  );
}
