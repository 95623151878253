import { useEffect, useMemo, useState } from "react";
import { Chart } from "../../../components/charts/chart";
import {
  formatGlobalResponse,
  getSerieName,
} from "../../../utils/charts/general";
import theme from "../../../styles/themes/default";
import { useLocation, useParams } from "react-router";
import queryString from "../../../utils/queryString";
import { getChartMocked } from "../types";
import useApi from "../../../hooks/useApi";
import { useTranslation } from "react-i18next";
import {
  AxisSelector,
  BetaBedge,
  Button,
  Footer,
  InputContainer,
  ParametersContainer,
  RegressionContainer,
  Result,
  TendencyTag,
} from "./styles";
import { Loading } from "../../../components/Loading";
import { ButtonSave } from "../../../components/Modal/styles";
import { ButtonCancel } from "../../../modals/ConfirmModal/styles";
import { formatDate } from "../../../utils/utilsFunctions";
import { EmptyChart } from "../../../components/ExpandedEvidence/styles";
import { RiFileChartLine } from "react-icons/ri";
import useSeriesControl from "../../../hooks/useSeriesControl";

interface IParameterRegression {
  daysToPredict?: number;
  valueToPredict?: number;
  minValueFilter?: number;
}
interface IRegression {
  analysis?: any;
}

const emptyParameters = {
  daysToPredict: undefined,
  valueToPredict: undefined,
  minValueFilter: 0,
};
export const Regression = ({ analysis }: IRegression) => {
  const [chartData, setChartData] = useState<any>();
  const [selected, setSelected] = useState<string>();
  const [charts, setCharts] = useState<any>({});
  const [regressionParameters, setRegressionParameters] =
    useState<IParameterRegression>(emptyParameters);
  const [defaultSeriesControl, setDefaultSeriesControl] = useState<any[]>([]);

  const location = useLocation();
  const params = useParams();

  const { t } = useTranslation();

  const { positionId } = params;

  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
    // eslint-disable-next-line
  }, [location.search]);

  let chartName = queryParams.chartName || "AC-RMS";
  let type = queryParams?.type === "DYNAMIC" ? "DYNAMIC" : "GLOBAL";

  // const [seriesControl, setSeriesControl] = useState<ISerieControl[]>(
  //   getSeriesControl(theme, chartData),
  // );
  const { seriesControl, buildSeriesControl } = useSeriesControl();

  const { request, processing } = useApi({
    path: `/positions/${positionId}`,
  });

  const chart = useMemo(() => {
    if (!Object.keys(charts || {}).length) {
      return;
    }
    return charts?.[type]?.filter((item: any) => item.name === chartName)[0];
    // eslint-disable-next-line
  }, [charts]);

  function loadChart() {
    let mockedChart = getChartMocked(chartName);
    if (mockedChart) {
      setCharts({ [mockedChart?.type]: [mockedChart] });
    } else {
      request({
        method: "get",
        queryStringParameters: { ...queryParams },
        pathParameters: "/chartList",
      }).then((response) => {
        setCharts(response?.charts);
      });
    }
  }

  const getChartsData = ({
    regression,
    newParameters,
  }: {
    regression?: boolean;
    newParameters?: any;
  }) => {
    var queryStringParameters = {
      ...queryParams,
      ...regressionParameters,
      serieSelectedByUser: selected,
      ...newParameters,
    };

    request({
      method: "get",
      pathParameters: regression ? `/charts/${chartName}/regression` : null,
      queryStringParameters,
    }).then((response) => {
      response = formatGlobalResponse(response);

      setDefaultSeriesControl(response.defaultSeries || []);
      buildSeriesControl(response);

      if (response?.defaultSeries?.length && !selected) {
        setSelected(response.defaultSeries[0]);
      }
      setChartData(response);
    });
  };

  const handleClear = () => {
    setRegressionParameters({
      daysToPredict: 0,
      valueToPredict: 0,
      minValueFilter: 0,
    });

    getChartsData({ regression: true, newParameters: emptyParameters });
  };

  const handleChange = ({
    field,
    value,
  }: {
    field:
      | "serieSelectedByUser"
      | "daysToPredict"
      | "valueToPredict"
      | "minValueFilter";
    value: string;
  }) => {
    setRegressionParameters({ ...regressionParameters, [field]: value });
  };

  const handleClearTheOtherInput = ({
    field,
  }: {
    field:
      | "serieSelectedByUser"
      | "daysToPredict"
      | "valueToPredict"
      | "minValueFilter";
  }) => {
    setRegressionParameters({ ...regressionParameters, [field]: "" });
  };

  useEffect(() => {
    loadChart();
    getChartsData({ regression: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, queryParams]);

  const handleSelect = (series: string) => {
    setSelected(series);
  };

  // eslint-disable-next-line
  const [dateRegression, seriesValue, valueRegression] = useMemo(() => {
    if (chartData?.data.length) {
      return chartData?.data.reverse()[0];
    }

    return [0, 0, 0];
  }, [chartData?.data]);

  const renderTendencyLabel = (regressionAngle: number) => {
    let data = {
      label: "NEUTRA",
      color: "lightgray",
    };

    if (regressionAngle > 0) {
      data = { label: t("High"), color: theme.colors.statusNormal };
    }

    if (regressionAngle < 0) {
      data = { label: t("Low"), color: theme.colors.statusRisk };
    }

    return (
      <TendencyTag tendencyColor={data.color}>
        <label>{data.label}</label>
      </TendencyTag>
    );
  };

  return (
    <RegressionContainer>
      <>
        {processing ? (
          <RegressionContainer>
            <Loading />
          </RegressionContainer>
        ) : chartData && chart ? (
          <>
            <div>
              <Chart
                chart={chart}
                chartData={chartData}
                seriesControl={seriesControl}
                analysis={{ regression: true }}
              />
            </div>
          </>
        ) : (
          <EmptyChart>
            <RiFileChartLine size={45} />
            <span>{t("analysis.emptyGraph")}</span>
          </EmptyChart>
        )}
      </>
      <ParametersContainer>
        <InputContainer>
          <h5>
            <BetaBedge>
              <label>Beta</label>
            </BetaBedge>
            {t("Regression Parameters")}
          </h5>
          <div>
            <label>{t("Axis")}: </label>
            <AxisSelector>
              {defaultSeriesControl?.map((serie: string, index: number) => (
                <Button
                  isSelected={serie === selected}
                  key={index}
                  onClick={() => handleSelect(serie)}
                >
                  {getSerieName(serie, chart, t)}
                </Button>
              ))}
            </AxisSelector>
          </div>
          {chartData?.hasOwnProperty("regressionAngle") && (
            <div>
              <label>{t("Tendency")}:</label>
              {renderTendencyLabel(chartData?.regressionAngle || 0)}
            </div>
          )}
          <div>
            <label>
              {t("Enter the number of days in the future you want to predict")}
            </label>
            <input
              type="number"
              onFocus={() =>
                handleClearTheOtherInput({ field: "valueToPredict" })
              }
              min={0}
              value={Number(regressionParameters?.daysToPredict) || ""}
              onChange={(e) => {
                handleChange({
                  field: "daysToPredict",
                  value: e.target.value,
                });
              }}
            />
          </div>

          {!!chartData?.regressionAngle && !!valueRegression ? (
            <Result>
              <label>{t("Regression Value")}:</label>
              <div>
                <div>
                  <label>{t("Value")}:</label>
                  <label>
                    {typeof valueRegression === "number"
                      ? valueRegression.toFixed(3)
                      : null}
                    {chartData?.metricUnit || null}
                  </label>
                </div>
                <div>
                  <label>{t("Date")}:</label>
                  <label>{formatDate(new Date(dateRegression))}</label>
                </div>
              </div>
            </Result>
          ) : null}
        </InputContainer>
        <Footer>
          <ButtonCancel onClick={handleClear}>{t("Clear")}</ButtonCancel>
          <ButtonSave onClick={() => getChartsData({ regression: true })}>
            {t("Analize")}
          </ButtonSave>
        </Footer>
      </ParametersContainer>
    </RegressionContainer>
  );
};
