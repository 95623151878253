import styled from "styled-components";

export const AlarmConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  & > span {
    ${(props) => ({ ...props.theme.fonts.secondary.H1Regular })};
  }
`;

export const AlarmLabel = styled.div`
  display: flex;
  margin-left: auto;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;

  label {
    ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};
    background: ${(props) => props.theme.colors.statusAlert};
    padding: 10px;
    width: 100%;
    height: 34px;
    border-radius: 4px;
  }

  label:last-child {
    background: ${(props) => props.theme.colors.statusRisk};
  }
`;

export const AlarmDescription = styled.div`
  display: flex;
  width: 70%;
  margin-left: auto;
  gap: 1.5rem;

  span {
    width: 50%;
    ${(props) => ({ ...props.theme.fonts.primary.H5Bold })};
  }
`;

export const AlarmSetupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  & > div:last-child {
    width: 70%;
    display: flex;
    gap: 1rem;
  }
`;

export const FormAlertAndRisk = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: ${(props) => props.theme.colors3.color0};
  ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};

  input {
    padding: 6px;
    width: 70%;
    min-width: 10;
    height: 34px;
    outline: 0;
    background: transparent;
    border: none;
    border-radius: 4px;
    color: ${(props) => props.theme.colors3.color0};

    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }

  div {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    input,
    select,
    textarea,
    label,
    p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    input,
    select,
    textarea,
    label,
    p {
      font-size: 16px;
    }
    width: 100%;
  }
`;

export const LabelHours = styled.div`
  width: 100%;
  min-width: 10;
  height: 34px;
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.colorTools};
  border-radius: 4px;
  color: ${(props) => props.theme.colors3.color0};

  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center;

  div {
    width: 30%;
    color: ${(props) => props.theme.colors3.color0} !important;
    opacity: 0.6;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 100%;
`;

export const AlarmsContainer = styled.div<{ center?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media screen and (max-width: 767px) {
    overflow-x: auto;
    display: flex;
    justify-content: ${({ center }) => center};

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ContainerTimeModal = styled.div`
  & > label:first-child {
    ${(props) => props.theme.fonts.primary.H1Semibold};
    color: ${(props) => props.theme.colors.iconsSecondary};
    padding: 0.5rem;
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: 0.5rem;
  width: 100%;
  ${(props) => props.theme.fonts.primary.H2Bold}
  display: flex;
  align-items: center;
`;

export const HourContainer = styled.div`
  ${(props) => props.theme.fonts.primary.H4Regular};
  margin-top: 1rem;
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

export const InputContainer = styled.div<{ error?: boolean }>`
  width: 48%;

  & > label {
    padding-left: 5px;
    margin-bottom: 5px;
  }

  & > div {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    & > svg {
      position: absolute;
      right: 10px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input {
    height: 36px;
    &:focus {
      border: 1px solid ${(props) => props.theme.colors.white} !important;
    }
  }

  input {
    ${(props) => props.theme.fonts.primary.H4Regular};
    width: 100%;
    min-height: 34px;
    color: ${(props) => props.theme.colors.iconsPrimary};
    background: ${(props) => props.theme.colors.background};
    border-radius: 4px;
    padding: 8px;
    border: 1px solid
      ${(props) =>
        props.error ? "red !important" : props.theme.colors.cardHighlight};

    &:hover {
      border: 1px solid ${(props) => props.theme.colors.IconsTools};
    }

    &:focus-visible {
      outline: none;
      border: 1px solid ${(props) => props.theme.colors.IconsTools};
    }
  }

  @media screen and (max-width: 767px) {
    & > input {
      font-size: 1rem !important;
    }
  }
`;

export const Subtitle = styled.label`
  ${(props) => props.theme.fonts.secondary.H2Regular};
  font-size: 14px;
  color: ${(props) => props.theme.colors.iconsSecondary};
  margin-top: 0.5rem;
`;
