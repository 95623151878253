import { Document, PDFViewer, Page, View, Text } from "@react-pdf/renderer";
import { useAppSelector } from "../../../store/hooks";
import { userSelect } from "../../../store/slices/userSlice";
import theme from "../../../styles/themes/default";
import { Modal } from "../../Modal";
import { Container, EmptyReport } from "./styles";

interface ModalPrint {
  show?: any;
  handleClose?: any;
  conversation?: any;
  insights?: boolean;
}

export const renderInsightDocument = ({ conversation }: any) => {
  return (
    <Document>
      <Page style={{ backgroundColor: "white" }}>
        <View
          style={{
            backgroundColor: theme.backgroundColors.background2,
            color: theme.colors.iconsPrimary,
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "extrabold",
            marginBottom: "10px",
          }}
        >
          <Text>{"Retina Insights"}</Text>
        </View>
        {conversation?.map((msg: any) => (
          <View
            wrap={false}
            style={{
              display: "flex",
              alignItems:
                msg.isText || !msg.isQuestion ? "flex-start" : "flex-end",
              padding:
                msg.isText || !msg.isQuestion ? "0 0 0 10px" : "0 10px 0 0",
            }}
          >
            <View
              style={{
                width: !msg.isText && !msg.isQuestion ? "55%" : "65%",
                border: `3px solid ${theme.colors.iconsSecondaryAlpha}`,
                padding: "15px",
                borderRadius: "10px",
                marginBottom: "10px",
                backgroundColor: "white",
                color: "black",
              }}
            >
              <Text>{msg.message}</Text>
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
};

export const ModalPrintChat = ({
  show,
  handleClose,
  conversation,
  insights,
}: ModalPrint) => {
  const user = useAppSelector(userSelect);

  const renderDocument = () => {
    return (
      <Document>
        <Page wrap>
          <View
            style={{
              backgroundColor: theme.backgroundColors.background2,
              color: theme.colors.iconsPrimary,
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "extrabold",
            }}
          >
            <Text>RetinAI Chat</Text>
          </View>
          {conversation?.map((msg: any) =>
            msg.isQuestion ? (
              <>
                <View
                  style={{
                    padding: "10px",
                    paddingLeft: "30px",
                    backgroundColor: theme.colors.iconsPrimary,
                    paddingTop: "20px",
                  }}
                >
                  <Text>{user.name.split(" ")[0]}</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    padding: "10px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    backgroundColor: theme.colors.iconsPrimary,
                  }}
                >
                  <Text>
                    <br />
                    {[msg.message]}
                  </Text>
                </View>
              </>
            ) : (
              <View
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                  paddingBottom: "10px",
                }}
              >
                <Text
                  style={{
                    backgroundColor: theme.colors.ibbxOrange,
                    width: "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "8px",
                    borderRadius: "8px",
                    marginBottom: "10px",
                  }}
                >
                  RetinAI
                </Text>
                <Text>{[msg.message.replaceAll("\n", "")]}</Text>
              </View>
            ),
          )}
        </Page>
      </Document>
    );
  };

  return (
    <Container>
      <Modal
        show={show}
        size="lg"
        handleClose={handleClose}
        hideCancelButton
        handleConfirm={handleClose}
        confirmLabel="Fechar"
      >
        {conversation.length ? (
          <PDFViewer
            className="pdf-viewer"
            style={{ width: "100%", height: "600px" }}
          >
            {insights
              ? renderInsightDocument({ conversation })
              : renderDocument()}
          </PDFViewer>
        ) : (
          <EmptyReport>
            <label>Não há histórico de conversa a ser impresso</label>
          </EmptyReport>
        )}
      </Modal>
    </Container>
  );
};
