import { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import { chartDataEmpty, chartDataModel } from "../../charts/chart/types";
import { SensorStatusComponentProps } from "../types";
import { EmptyChart, Footer } from "../styles";
import { RiFileChartLine } from "react-icons/ri";
import { Chart } from "../../charts/chart";
import { SeriesSelector } from "../../charts/seriesSelector";
import { Loading } from "../../Loading";
import { useTranslation } from "react-i18next";
import { SignalQualityChartWrapp } from "./styles";
import useSeriesControl from "../../../hooks/useSeriesControl";
import { IChart } from "../../../store/interfaces";

export const SignalQualityStatus = ({
  position,
  period,
}: SensorStatusComponentProps) => {
  const [processing, setProcessing] = useState(false);
  const [chartData, setChartData] = useState<chartDataModel>(chartDataEmpty);

  const { request } = useApi({
    path: `/positions/${position.id || position.positionId}/signal-quality`,
  });

  const { seriesControl, buildSeriesControl, handleSeries } =
    useSeriesControl();

  useEffect(() => {
    getChartData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position.id || position.positionId, period]);

  const { t } = useTranslation();

  // eslint-disable-next-line
  const translatedSeriesNames: any = {
    signal: `${t("modalSensorStatus.signalQuality")}`,
    Alerta: `${t("dictionary.status.Alert")}`,
  };

  function getChartData() {
    if (!position) {
      return;
    }

    setProcessing(true);

    const queryStringParameters = { period };
    request({ method: "get", queryStringParameters })
      .then((response: any) => {
        response.data = response.data.map(([date, ...rest]: any[]) => [
          new Date(date),
          ...rest,
        ]);

        response.series = ["date", "Qualidade de Coleta", "dBm"];

        setChartData(response);
        buildSeriesControl(response);
      })
      .finally(() => setProcessing(false));
  }

  const chart: IChart = {
    name: "sinalQuality",
    type: "GLOBAL",
    positionId: position.id,
    label: `${t("modalSensorStatus.signalQuality")}`,
    series: ["signalQuality", "dBm"],
    seriesName: [`${t("modalSensorStatus.signalQuality")}`, "RSSI"],
    metricUnits: chartData.metricUnits || { x: "date", y: "%" },
  };

  function renderChart() {
    if (!chartData?.data?.length) {
      return (
        <EmptyChart>
          <RiFileChartLine size={45} />
          <span>{t("modalSensorStatus.noDataOnPeriod")}</span>
        </EmptyChart>
      );
    }

    const tooltipDescription = (chartName: string) => {
      switch (chartName) {
        case `${t("modalSensorStatus.signalQuality")}`:
          return `${t(
            "modalSensorStatus.Total de pontos recebidos dividido pela quantidade de pontos esperados a receber pelo dispositivo.",
          )}`;
        case "dBm":
          return `${t("cardBolt.Indicador de intensidade do sinal recebido")}`;
        default:
          return "";
      }
    };

    return (
      <SignalQualityChartWrapp>
        <Chart
          chartData={chartData}
          seriesControl={seriesControl}
          chart={chart}
          multipleAxis={true}
          yTicks={[-120, -100, -80, -60, -40, -20, 0, 20, 40, 60, 80, 100, 120]}
        />
        <Footer>
          <SeriesSelector
            seriesControl={seriesControl}
            handleSeries={handleSeries}
            chart={chart}
            getTooltipDescription={tooltipDescription}
          />
        </Footer>
      </SignalQualityChartWrapp>
    );
  }

  return <>{processing ? <Loading /> : renderChart() || <></>}</>;
};
