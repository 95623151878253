import { Container } from "./styles";

export const TableHarmonics = ({ data, isMechanical, chart }: any) => {
  const { name } = chart;

  const getDataUnity = () => {
    if (name === "FFT-V") {
      return "mm/s";
    } else if (name.includes("AC")) {
      return "g";
    } else if (name.includes("ENV")) {
      return "gE";
    }
  };

  const findClosestValue = (array: any) => {
    return array?.filter((item: any) => item)[0]?.toFixed(3);
  };

  const array = data?.slice(1);

  const harmonicsValues: any[] = [];

  for (let value in array) {
    if (array[value][1]) {
      const index = Number(value);

      let horizontal: any;
      let vertical: any;
      let axial: any;

      horizontal = array[index][3]?.toFixed(3);
      if (!horizontal) {
        horizontal = findClosestValue([
          array[index - 2][3],
          array[index - 1][3],
          array[index + 1][3],
          array[index + 2][3],
        ]);
      }

      vertical = array[index][5]?.toFixed(3);
      if (!vertical) {
        vertical = findClosestValue([
          array[index - 2][5],
          array[index - 1][5],
          array[index + 1][5],
          array[index + 2][5],
        ]);
      }

      axial = array[index][7]?.toFixed(3);
      if (!axial) {
        axial = findClosestValue([
          array[index - 2][7],
          array[index - 1][7],
          array[index + 1][7],
          array[index + 2][7],
        ]);
      }

      harmonicsValues.push({
        harmonic: array[value][1],
        horizontal,
        vertical,
        axial,
      });
    }
  }

  const unity = isMechanical ? getDataUnity() : "A";

  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>Harmonica</th>
            <th>Amp. Horizontal</th>
            <th>Amp. Vertical</th>
            <th>Amp. Axial</th>
          </tr>
        </thead>
        <tbody>
          {harmonicsValues &&
            harmonicsValues?.map((item: any) => (
              <tr>
                <td>{item.harmonic}</td>
                <td>
                  {item.horizontal} {item.horizontal ? unity : null}
                </td>
                <td>
                  {item.vertical} {item.vertical ? unity : null}
                </td>
                <td>
                  {item.axial} {item.axial ? unity : null}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Container>
  );
};
