import { createContext } from "react";

interface IOccurrenceListContext {
  records: any[];
  pagination: any;
  handleGetRecords: () => any;
  processing: boolean;
}

const DEFAULT: IOccurrenceListContext = {
  records: [],
  pagination: {},
  handleGetRecords: () => null,
  processing: false
};

const OccurrenceListContext = createContext<IOccurrenceListContext>(DEFAULT);

export default OccurrenceListContext;
