import { ComponentProps, HTMLAttributes } from "react";
import { Container } from "./styles";

export function BetaBadge(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <Container {...props}>
      <span>Beta</span>
    </Container>
  );
}
