import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

// AWS
import { Amplify, API } from "aws-amplify";
import { awsConfig } from "../awsConfig";
import { error } from "../../components/Toast";
import { notifyDiscordError } from "../../utils/notifyDiscord";

//Configuração para acesso ao Cognito e ao API Gateway
Amplify.configure(awsConfig);
const apiRetinaCustomer = awsConfig?.API?.endpoints?.[0]?.name;

export interface occurrenceModel {
  companyName: string;
  facilityName: string;
  assetName: string;
  occurrence: string;
  id: number;
  positionId: number;
  positionName: string;
  assetId: number;
  sensorTypeName: string;
  sensorTypeId: number;
  diagnostic: string;
  recommendation: string;
  comments: string;
  dueDate: Date | null | string;
  sysStatusId: number;
  statusCaption: string;
  createdAt: string;
  updatedAt: string;
  closed: boolean;
  duration?: any;
  caption?: string;
  printAll?: boolean;
}

export const occurrenceEmpty: occurrenceModel = {
  companyName: "",
  facilityName: "",
  assetName: "",
  occurrence: "",
  id: 0,
  positionId: 0,
  positionName: "",
  assetId: 0,
  sensorTypeName: "",
  sensorTypeId: 0,
  diagnostic: "",
  recommendation: "",
  comments: "",
  dueDate: null,
  sysStatusId: 2,
  statusCaption: "",
  createdAt: "",
  updatedAt: "",
  closed: false,
};

interface SliceState {
  processing: boolean;
  occurrences: occurrenceModel[];
}

const initialState: SliceState = {
  processing: false,
  occurrences: [],
};

interface AssetOccurrencesGetProps {
  companyId: string;
  facilityId: string;
  assetId: string;
}

interface PositionOccurrencesGetProps {
  positionId: string;
}

export const STATUS_OCCURRENCE = {
  RISK: "RISK",
  RISK_ANALYSIS: "RISK_ANALYSIS",
  NORMAL: "NORMAL",
  ALERT: "ALERT",
  ALERT_ANALYSIS: "ALERT_ANALYSIS",
  ANALYSIS: "ANALYSIS",
};

export const STATUS_OCCURRENCE_LABEL: { [key: string]: string } = {
  RISK: "RISCO",
  RISK_ANALYSIS: "RISCO",
  NORMAL: "NORMAL",
  ALERT: "ALERTA",
  ALERT_ANALYSIS: "ALERTA",
  ANALYSIS: "ANALYSIS",
};

export const STATUS_OCCURRENCE_ID = {
  1: "NORMAL",
  2: "ALERT_ANALYSIS",
  3: "ALERT",
  4: "RISK_ANALYSIS",
  5: "RISK",
};

export const STATUS_OCCURRENCE_OPTIONS = {
  RISK: {
    label: "Risco",
    value: 5,
  },
  RISK_ANALYSIS: {
    label: "Risco análise",
    value: 4,
  },
  ALERT: {
    label: "Alerta",
    value: 3,
  },
  ALERT_ANALYSIS: {
    label: "Alerta análise",
    value: 2,
  },
  NORMAL: {
    label: "Normal",
    value: "1",
  },
};

export const getAssetOccurrences = createAsyncThunk(
  "occurrences/assetOccurrencesGET",
  async ({ companyId, facilityId, assetId }: AssetOccurrencesGetProps) => {
    const path = `/companies/${companyId}/facilities/${facilityId}/assets/${assetId}/occurrences`;
    const data: occurrenceModel[] = await new Promise((resolve, reject) => {
      API.get(apiRetinaCustomer, path, {})
        .then((occurrences) => {
          resolve(occurrences);
        })
        .catch(async (error) => {
          await notifyDiscordError({
            title: `Erro na API`,
            description: path,
          });
          error(error.message);
          reject(error);
        });
    });
    return data;
  },
);

export const getPositionOccurrences = createAsyncThunk(
  "occurrences/positionOccurrencesGET",
  async ({ positionId }: PositionOccurrencesGetProps) => {
    const path = `/positions/${positionId}/occurrences`;
    const data: occurrenceModel[] = await new Promise((resolve, reject) => {
      API.get(apiRetinaCustomer, path, {})
        .then((occurrences) => {
          resolve(occurrences);
        })
        .catch(async (error) => {
          await notifyDiscordError({
            title: `Erro na API`,
            description: path,
          });
          reject(error);
        });
    });
    return data;
  },
);

interface OccurrencePostProps {
  sysStatusId: number;
  comments: string;
  diagnostic?: string;
  dueDate?: Date;
  recommendation?: string;
  diagnostdueDateic?: string;
}

export const postOccurrence = createAsyncThunk(
  "occurrences/occurrencePOST",
  async (body: OccurrencePostProps, { getState }) => {
    const { app } = getState() as RootState;

    const path = `/occurrences`;
    const data: occurrenceModel[] = await new Promise((resolve, reject) => {
      API.post(apiRetinaCustomer, path, {
        body: {
          ...body,
          companyId: app.currentCompany.id,
          facilityId: app.currentFacility.id,
          assetId: app.currentAsset.id,
          positionId: app.currentPosition.id,
        },
      })
        .then((occurrence) => {
          resolve(occurrence);
        })
        .catch(async (error) => {
          await notifyDiscordError({
            title: `Erro na API`,
            description: path,
          });
          reject(error);
        });
    });
    return data;
  },
);

interface OccurrencePutProps {
  occurrenceId: number;
  body: {
    sysStatusId: number;
    comments: string;
    diagnostic?: string;
    dueDate?: Date;
    recommendation?: string;
    actionComments: string;
  };
}

export const putOccurrence = createAsyncThunk(
  "occurrences/occurrencePUT",
  async ({ occurrenceId, body }: OccurrencePutProps, { getState }) => {
    const { app } = getState() as RootState;

    const path = `/occurrences/${occurrenceId}`;
    const data: occurrenceModel[] = await new Promise((resolve, reject) => {
      API.put(apiRetinaCustomer, path, {
        body: {
          ...body,
          companyId: app.currentCompany.id,
          facilityId: app.currentFacility.id,
          assetId: app.currentAsset.id,
          positionId: app.currentPosition.id,
        },
      })
        .then((occurrence) => {
          resolve(occurrence);
        })
        .catch(async (error) => {
          await notifyDiscordError({
            title: `Erro na API`,
            description: path,
          });
          reject(error);
        });
    });
    return data;
  },
);

interface OccurrenceDeleteProps {
  occurrenceId: number;
  actionComments: string;
}

export const deleteOccurrence = createAsyncThunk(
  "occurrences/occurrenceDELETE",
  async ({ occurrenceId, actionComments }: OccurrenceDeleteProps) => {
    const path = `/occurrences/${occurrenceId}`;
    const data: occurrenceModel[] = await new Promise((resolve, reject) => {
      API.del(apiRetinaCustomer, path, { body: { actionComments } })
        .then((occurrence) => {
          resolve(occurrence);
        })
        .catch(async (error) => {
          await notifyDiscordError({
            title: `Erro na API`,
            description: path,
          });
          reject(error);
        });
    });
    return data;
  },
);

interface CloseOccurrencePutProps {
  occurrenceId: number;
  actionComments: string;
}

export const putCloseOccurrence = createAsyncThunk(
  "occurrences/occurrenceClosePUT",
  async ({ occurrenceId, actionComments }: CloseOccurrencePutProps) => {
    const path = `/occurrences/${occurrenceId}/close`;
    const data: occurrenceModel[] = await new Promise((resolve, reject) => {
      API.put(apiRetinaCustomer, path, { body: { actionComments } })
        .then((occurrence) => {
          resolve(occurrence);
        })
        .catch(async (error) => {
          await notifyDiscordError({
            title: `Erro na API`,
            description: path,
          });
          reject(error);
        });
    });
    return data;
  },
);

interface ReOpenOccurrencePutProps {
  occurrenceId: number;
  actionComments: string;
}

export const putReOpenOccurrence = createAsyncThunk(
  "occurrences/occurrenceReOpenPUT",
  async ({ occurrenceId, actionComments }: ReOpenOccurrencePutProps) => {
    const path = `/occurrences/${occurrenceId}/reopen`;
    const data: occurrenceModel[] = await new Promise((resolve, reject) => {
      API.put(apiRetinaCustomer, path, { body: { actionComments } })
        .then((occurrence) => {
          resolve(occurrence);
        })
        .catch(async (error) => {
          await notifyDiscordError({
            title: `Erro na API`,
            description: path,
          });
          reject(error);
        });
    });
    return data;
  },
);

const occurrenceSlice = createSlice({
  name: "occurrence",
  initialState,
  reducers: {
    setOccurrences: (state, action: PayloadAction<occurrenceModel[]>) => {
      state.occurrences = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssetOccurrences.pending, (state) => {
        state.processing = true;
      })
      .addCase(getAssetOccurrences.fulfilled, (state, action) => {
        state.processing = false;
        state.occurrences = action.payload;
      })
      .addCase(getAssetOccurrences.rejected, (state, action) => {
        state.processing = false;
        error(action.error?.message);
      })
      .addCase(getPositionOccurrences.pending, (state) => {
        state.processing = true;
      })
      .addCase(getPositionOccurrences.fulfilled, (state, action) => {
        state.processing = false;
        state.occurrences = action.payload;
      })
      .addCase(getPositionOccurrences.rejected, (state, action) => {
        state.processing = false;
        error(action.error?.message);
      })
      .addCase(postOccurrence.pending, (state) => {
        state.processing = true;
      })
      .addCase(postOccurrence.fulfilled, (state) => {
        state.processing = false;
      })
      .addCase(postOccurrence.rejected, (state, action) => {
        state.processing = false;
        error(action.error?.message);
      })
      .addCase(putOccurrence.pending, (state) => {
        state.processing = true;
      })
      .addCase(putOccurrence.fulfilled, (state) => {
        state.processing = false;
      })
      .addCase(putOccurrence.rejected, (state, action) => {
        state.processing = false;
        error(action.error?.message);
      })
      .addCase(deleteOccurrence.pending, (state) => {
        state.processing = true;
      })
      .addCase(deleteOccurrence.fulfilled, (state) => {
        state.processing = false;
      })
      .addCase(deleteOccurrence.rejected, (state, action) => {
        state.processing = false;
        error(action.error?.message);
      })
      .addCase(putCloseOccurrence.pending, (state) => {
        state.processing = true;
      })
      .addCase(putCloseOccurrence.fulfilled, (state) => {
        state.processing = false;
      })
      .addCase(putCloseOccurrence.rejected, (state, action) => {
        state.processing = false;
        error(action.error?.message);
      })
      .addCase(putReOpenOccurrence.pending, (state) => {
        state.processing = true;
      })
      .addCase(putReOpenOccurrence.fulfilled, (state) => {
        state.processing = false;
      })
      .addCase(putReOpenOccurrence.rejected, (state, action) => {
        state.processing = false;
        error(action.error?.message);
      });
  },
});

export const occurrenceSelect = (state: RootState) => state.occurrence;

export const { setOccurrences } = occurrenceSlice.actions;

export default occurrenceSlice.reducer;
