import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  color: ${(props) => props.theme.colors.iconsSecondary};
  position: relative;

  h3 {
    margin: 0;
    margin-left: 12px;
    ${(props) => props.theme.fonts.primary.H3SemiBold};
    display: flex;
    gap: 12px;
  }
`;

export const OwnerBadgeField = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: transparent;
  margin-top: -4px;
  span {
    ${(props) => props.theme.fonts.primary.H5Regular};
    font-size: 10px;
    margin: 0;
  }
`;

export const Alerts = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.cardHighlight};
  background: ${(props) => props.theme.colors.cardHighlight};
  /* font-size: 2rem; */
  width: 0.1rem;
  height: 5rem;
  margin: auto 0.6rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  span {
    ${(props) => props.theme.fonts.primary.H1boldCard};
    margin: 0;
  }
  label {
    ${(props) => props.theme.fonts.primary.H4Bold};
    line-break: loose;
  }
  hr {
    width: 36%;
    min-width: 55px;
    height: 3px;
    border: none;
    border-radius: 8px;
    margin: auto;
    opacity: 1;
  }
  /* Tablet */
  @media screen and (min-width: 799px) and (max-width: 1280px) {
    min-width: 16.5rem;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    gap: 0.5rem;

    & > div {
      width: 48%;
    }
  }
`;

export const StatusDataTemplate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;

  @media screen and (max-width: 1445px) {
    span {
      ${(props) => props.theme.fonts.primary.H2boldMobile};
      margin: 0;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    span {
      font-size: 3.5rem;
    }
  }
`;

export const DataNormal = styled(StatusDataTemplate)`
  hr {
    background-color: ${(props) =>
      props.theme.colors.iconsSecondary} !important;
  }
`;

export const DataAlert = styled(StatusDataTemplate)`
  hr {
    background-color: ${(props) => props.theme.colors.statusAlert} !important;
  }
`;

export const DataRisk = styled(StatusDataTemplate)`
  hr {
    background-color: ${(props) => props.theme.colors.statusRisk} !important;
  }
`;

export const DataCritical = styled(StatusDataTemplate)`
  label {
    color: ${(props) => props.theme.colors.statusRisk} !important;
    filter: brightness(1.1);
  }
  hr {
    background-color: ${(props) => props.theme.colors.statusRisk} !important;
  }
`;

export const SwitchTotal = styled.button`
  display: flex;
  position: absolute;
  top: 0;
  left: 11rem;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  padding: 0.15rem 0.25rem;

  span {
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${(props) => props.theme.colors.iconsSecondary};
    margin: 0;
  }

  &:hover {
    filter: brightness(0.8);
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
    transition: all 0.3s ease;
  }
`;
