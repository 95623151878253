import { useEffect, useMemo, useState } from "react";
import ModalCompound from "../../../../../../../components/ModalCompound";
import useApi from "../../../../../../../hooks/useApi";
import { Select } from "../../../../../../../components/Select";
import { useField, useFormikContext } from "formik";
import useRetinaTranslation from "../../../../../../../hooks/useRetinaTranslation";
import { extractProperty } from "../../../../../../../utils/utilsFunctions";
import { UserFacilitiesAddFields } from "./styles";

interface IUserFacilitiesAdd {
  show: boolean;
  handleClose: () => void;
  disabled?: boolean;
}

export default function UserCompaniesAdd({
  show,
  handleClose,
}: IUserFacilitiesAdd) {
  const { translateLabels } = useRetinaTranslation();

  const [{ value: profile }] = useField("profile");
  const [{ value: userCompanies }] = useField("userCompanies");

  const { setFieldValue } = useFormikContext();
  const { request: requestCompanies } = useApi({
    path: "/companies-options",
  });
  const [companiesOptions, setCompanies] = useState<any[]>([]);

  const [company, setCompany] = useState<any>(null);
  const [permission, setPermission] = useState<any>({
    value: "DEFAULT",
    label: translateLabels("Editor"),
  });
  // eslint-disable-next-line
  const [deniedPermission, setDeniedPermission] = useState<any>(null);

  function loadCompanies() {
    requestCompanies({
      method: "get",
    }).then((response: any[]) => {
      setCompanies(response);
    });
  }

  useEffect(() => {
    loadCompanies();
    // eslint-disable-next-line
  }, []);

  const handleChangeCompany = (value: any) => {
    setCompany(value || null);
  };

  const handleChangePermission = (value: any) => {
    setPermission(value);
  };

  function handleSubmit() {
    if (!company?.value) {
      return;
    }

    const result = (userCompanies || []).concat({
      companyName: company?.label,
      companyId: company?.value,
      isReadOnly: permission?.value === "READ_ONLY",
      deniedPermissions: {},
      action: "CREATE",
    });

    setFieldValue("userCompanies", result);
    closeForm();
  }

  const companies = useMemo(() => {
    const companiesId = extractProperty(userCompanies, "companyId");
    return companiesOptions.filter(({ value, isReadOnly }) => {
      return !companiesId.includes(value) && !isReadOnly;
    });
  }, [companiesOptions, userCompanies]);

  const permissions = useMemo(() => {
    return [
      { value: "DEFAULT", label: translateLabels("Editor") },
      { value: "READ_ONLY", label: translateLabels("Viewer") },
    ];
  }, [translateLabels]);

  const renderPermission = () => {
    switch (true) {
      case profile === "CLIENTE_MASTER":
        return (
          <Select
            label={translateLabels("Permission")}
            value={permission}
            handleChange={handleChangePermission}
            options={permissions}
          />
        );
      default:
        return <></>;
    }
  };

  function closeForm() {
    setCompany(null);
    setPermission({ value: "DEFAULT", label: translateLabels("Editor") });
    handleClose();
  }

  return (
    <ModalCompound show={show} handleClose={closeForm}>
      <ModalCompound.Body>
        <h1>{translateLabels("Add")}</h1>
        <UserFacilitiesAddFields>
          <Select
            label={translateLabels("Company")}
            value={company}
            handleChange={handleChangeCompany}
            options={companies}
          />
          {renderPermission()}
        </UserFacilitiesAddFields>
      </ModalCompound.Body>
      <ModalCompound.Footer>
        <ModalCompound.Cancel handleClose={closeForm} />
        <ModalCompound.Save handleConfirm={handleSubmit} />
      </ModalCompound.Footer>
    </ModalCompound>
  );
}
