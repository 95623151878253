import { useEffect, useRef, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import useOnClickOutSide from "../../hooks/useOnClickOutside";
import {
  SelectDropdown,
  SidebarSelectContainer,
  SidebarSelectDiv,
  Span,
} from "./styles";
import { SidebarSelectProps } from "./types";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import queryString from "../../utils/queryString";

export const SidebarSelect = ({
  callback,
  options,
  processing,
  selected,
  label,
  name = "",
  defaultLabel,
  disableColor,
}: SidebarSelectProps) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropdown, setDropdown] = useState(false);

  const location = useLocation();

  const queryParams = queryString.parse(location.search);

  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  useOnClickOutSide(dropdownRef, () => setDropdown(false), selectRef);

  const handleSelect = (item: any) => {
    callback(item);
    setDropdown(false);

    if (!name) {
      return;
    }

    if (item.id) {
      searchParams.set(name, item.id);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(name);
      setSearchParams(searchParams);
    }
  };

  function getItemName(item: any) {
    const itemName = typeof item === "string" ? item : item?.name;
    if (!item?.id || item === "Todos Setores") {
      return defaultLabel || t("sidebar.sectors.default");
    }
    return itemName;
  }

  return (
    <>
      <SidebarSelectContainer>
        {label && <label> {label}</label>}
        <SidebarSelectDiv
          ref={selectRef}
          onClick={() => setDropdown(!dropdown)}
        >
          {getItemName(selected)}
          {dropdown ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
        </SidebarSelectDiv>
        <SelectDropdown ref={dropdownRef} visible={dropdown}>
          {!processing &&
            !!options?.length &&
            options.map((item: any) => (
              <div
                key={item?.id || item?.id === 0 || item}
                onClick={() => handleSelect(item)}
              >
                {!disableColor && (
                  <div>
                    <Span
                      sectorColor={
                        item?.name !== "Todos Setores" &&
                        (item?.color || "#0099ff")
                      }
                    ></Span>
                  </div>
                )}
                <span>{getItemName(item)}</span>
                {item?.name === selected?.name && <BsCheckLg />}
              </div>
            ))}
        </SelectDropdown>
      </SidebarSelectContainer>
    </>
  );
};
