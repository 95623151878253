import Marquee from "react-fast-marquee";

import Frame from "../../components/frame";

import { ReactComponent as IndustryIcon } from "../../images/industry.svg";
import { ReactComponent as EyeWorldIcon } from "../../images/eyeworld.svg";
import { ReactComponent as BlocksIcon } from "../../images/blocks.svg";
import { ReactComponent as CirclesIcon } from "../../images/circles.svg";
import { ReactComponent as InfinityIcon } from "../../images/infinity.svg";
import { ReactComponent as SunIcon } from "../../images/sun.svg";
import { ReactComponent as ShapeCircle } from "../../images/shape1.svg";
import { ReactComponent as Tree } from "../../images/tree.svg";
import { ReactComponent as NoLeafTree } from "../../images/noLeafTree.svg";
import { ReactComponent as BgGrassMobile } from "../../images/bg-grass01.svg";
import { ReactComponent as BackgroundGrass } from "../../images/background-grass.svg";
import { ReactComponent as BackgroundEsg } from "../../images/esg-bg.svg";

import {
  EsgOdsContainer,
  Footer,
  FooterContent,
  FrameBodyMainEsg,
  FrameBodyRightEsg,
  OdsContainer,
} from "./styles";
import { isMobile } from "react-device-detect";
import { TreeComponent } from "./Tree";

import { RenderContent } from "./RenderContent";
import { useTranslation } from "react-i18next";

export function EsgPanel() {
  const { t } = useTranslation();
  return (
    <Frame>
      <>
        <FrameBodyMainEsg>
          <TreeComponent />
          <FrameBodyRightEsg visible={true}>
            <RenderContent />
          </FrameBodyRightEsg>
          <Footer>
            <FooterContent>
              <span>{t("esg.footer")}</span>
              <EsgOdsContainer>
                <div>
                  <Marquee
                    speed={100}
                    gradientWidth={24}
                    gradientColor={[37, 67, 48]}
                    style={{
                      gap: "20px",
                      display: "flex",
                      overflow: "hidden",
                    }}
                  >
                    <div style={{ gap: "20px", display: "flex" }}>
                      <OdsContainer style={{ backgroundColor: "#9A2242" }}>
                        <IndustryIcon />
                        <span>{t("esg.decentWorkAndEconomicGrowth")}</span>
                      </OdsContainer>
                      <OdsContainer style={{ backgroundColor: "#417C44" }}>
                        <EyeWorldIcon />
                        <span>{t("esg.actionAgainstGlobalClimateChange")}</span>
                      </OdsContainer>
                      <OdsContainer style={{ backgroundColor: "#E46930" }}>
                        <BlocksIcon />
                        <span>
                          {t("esg.industryInnovationAndInfrastructure")}
                        </span>
                      </OdsContainer>
                      <OdsContainer style={{ backgroundColor: "#1D4768" }}>
                        <CirclesIcon />
                        <span>
                          {t("esg.partnershipsAndMeansOfImplementation")}
                        </span>
                      </OdsContainer>
                      <OdsContainer style={{ backgroundColor: "#B88831" }}>
                        <InfinityIcon />
                        <span>
                          {t("esg.responsibleConsumptionAndProduction")}
                        </span>
                      </OdsContainer>
                      <OdsContainer style={{ backgroundColor: "#EEBC28" }}>
                        <SunIcon />
                        <span>{t("esg.cleanAndAffordableEnergy")}</span>
                      </OdsContainer>
                    </div>
                  </Marquee>
                </div>
              </EsgOdsContainer>
            </FooterContent>
          </Footer>
          <ShapeCircle className="shape1" />
          <ShapeCircle className="shape2" />
          <Tree className="tree-full" />
          <NoLeafTree className="no-leaf-tree" />
          <BackgroundGrass className="bg-grass" />
          {isMobile && <BgGrassMobile className="bg-grass-mobile" />}
          <BackgroundEsg className="bg-esg" />
        </FrameBodyMainEsg>
      </>
    </Frame>
  );
}
