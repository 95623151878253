import styled from "styled-components";
import { FrameBodyMain, FrameBodyRight } from "../../components/frame/styles";

export const OccurrenceTableHeaderContainer = styled.div`
  padding: 16px;
  padding-bottom: 0;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;

export const FrameBodyRightOccurrences = styled(FrameBodyRight)`
  width: 100%;
  margin: 0;
  padding-bottom: 8px;
  @media screen and (max-width: 767px) {
    opacity: 1;
    min-height: 11rem;
    margin-top: 3.6rem;
    overflow: hidden;

    & > div {
      height: 132px;
      padding-inline: 4px;

      .carousel-indicators {
        margin-bottom: -3rem;
        button {
          width: 4rem;
        }
      }
    }
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  height: 142px;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    content: "";
    height: 130px;
    position: absolute;
    width: 100px;
    z-index: 2;
    background: linear-gradient(
      to right,
      ${(props) => props.theme.colors.background},
      transparent
    );
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
`;

export const SliderTrack = styled.div`
  flex-direction: row;
  display: flex;
  gap: 22px;
  position: relative;
`;

export const ScrollElementBase = styled.div<{ isPaused?: boolean }>`
  width: inherit;
  height: inherit;
  position: absolute;
  left: 0%;
  top: 0%;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ScrollElementPrimary = styled(ScrollElementBase)`
  @keyframes primary {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
  animation: primary 50s linear infinite;
  animation-play-state: ${(props) => (props.isPaused ? "paused" : "running")};
`;

export const ScrollElementSecondary = styled(ScrollElementBase)`
  @keyframes secondary {
    from {
      left: 100%;
    }
    to {
      left: 0%;
    }
  }
  animation: secondary 50s linear infinite;
  animation-play-state: ${(props) => (props.isPaused ? "paused" : "running")};
`;

export const FrameDashboardRollBase = styled.div`
  background-color: ${(props) => props.theme.colors.divBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  height: 120px;
  width: 361px !important;
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 6px 0;
    width: 100%;
    gap: 4px !important;

    & > div {
      padding: 0px 16px !important;
    }
    & > span:first-child, & > h3 {
      margin: 0;
      margin-left: 6px;
      margin-top: -2px;
      ${(props) => props.theme.fonts.primary.H5SemiBold};
      color: ${(props) => props.theme.colors.IconsTools};
      font-size: 11px;
      text-align: center;
    }
  }

  @media (max-width: 767px) {
    min-width: 100%;
    height: 8.5rem !important;
    scroll-snap-align: start;
  }
  @media screen and (max-width: 1537px) {
    height: 90px;
  }
`;

export const CardMonitoredPointsDashboard = styled(FrameDashboardRollBase)`
  min-width: 552px !important;
  @media screen and (max-width: 1537px) {
    width: 446px !important;
  }
  @media screen and (min-width: 1921px) {
    width: 480px !important;
  }

  & > div {
    display: flex;
    span {
      left: -44px;
    }

    & > span:nth-child(2) {
      transform: rotate(270deg);
      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      margin: 0;
      ${(props) => props.theme.fonts.secondary.H1Regular};
      color: ${(props) => props.theme.colors.IconsTools};
      opacity: 0.8;
    }

    & > div {
      width: 100%;
      & > div:nth-child(even) {
        display: none;
      }
      & > div {
        span {
          ${(props) => props.theme.fonts.primary.H2boldMobile};
          line-height: 50px;
          font-weight: 600;
        }
        label {
          ${(props) => props.theme.fonts.primary.H5SemiBold};
        }
        @media screen and (max-width: 1537px) {
          span {
            ${(props) => props.theme.fonts.primary.H1Semibold};
          }
          label {
            ${(props) => props.theme.fonts.primary.H6Bold};
            font-size: 10px;
            line-height: 12px;
          }
        }
      }

      & > div:nth-child(5) {
        label {
          color: ${(props) => props.theme.colors.statusNormal};
        }
      }
      & > div:nth-child(7) {
        label {
          color: ${(props) => props.theme.colors.statusAlert};
        }
      }
      & > div:nth-child(9) {
        label {
          color: ${(props) => props.theme.colors.statusRisk};
        }
      }

      & > div:nth-child(11) {
        label {
          color: ${(props) => props.theme.colors.statusRisk};
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: 22rem !important;
    & > div {
      & > div {
        gap: 0.4;
        flex-direction: row;
        justify-content: space-around;
        padding: 0 0.5rem !important;
        & > div {
          width: 25%;
          span {
            ${(props) => props.theme.fonts.primary.H2boldMobile};
            line-height: 50px;
            font-weight: 600;
            font-size: 38px;
          }
          label {
            ${(props) => props.theme.fonts.primary.H5Regular};
          }
        }
      }
    }
  }
`;

export const CardIoTConnectivityDashboard = styled(FrameDashboardRollBase)`
  width: 426px !important;

  @media screen and (max-width: 1537px) {
    width: 346px !important;
  }
  @media screen and (min-width: 1921px) {
    width: 480px !important;
  }

  & > div {
    gap: 0 !important;

    display: flex;

    justify-content: space-between;
    & > div {
      padding: 0;
      height: 120px;
      margin-top: 0;
      display: flex;
      gap: 2rem;
    }

    & > div > div:nth-child(1) {
      span {
        font-size: 1.6rem;
      }

      & > div {
        height: 5.5rem;
        width: 5.5rem;
        & > div {
          height: 85%;
          width: 85%;
        }
      }

      @media screen and (max-width: 1537px) {
        span {
          font-size: 1.3rem;
        }
        & > div {
          height: 4rem;
          width: 4rem;
        }
      }
    }
    & > div > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 2rem;

      & > div {
        display: flex;
        align-items: center;
        gap: 0;
      }

      span {
        ${(props) => props.theme.fonts.primary.H2boldMobile};
        line-height: 50px;
      }

      label {
        ${(props) => props.theme.fonts.primary.H5Bold};
      }

      @media screen and (max-width: 1537px) {
        span {
          ${(props) => props.theme.fonts.primary.H1Semibold};
        }
        label {
          ${(props) => props.theme.fonts.primary.H6Bold};
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    & > div {
      max-width: 23.5rem;
      span {
        left: -32px;
      }

      & > div {
        & > div:nth-child(1) {
          margin-top: 0;
          & > div {
            height: 6rem;
            width: 6rem;
          }
        }
        & > div:nth-child(2) {
          margin-top: 0;
        }

        justify-content: space-around;
        margin-left: 0.5rem;
        & > div {
          span {
            ${(props) => props.theme.fonts.primary.H2boldMobile};
            line-height: 50px;
            font-weight: 600;
          }
          label {
            ${(props) => props.theme.fonts.primary.H5Regular};
          }
        }
      }
    }

    & > div:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const CardMonitoringStatusDashboard = styled(FrameDashboardRollBase)`
  width: 460px !important;
  @media screen and (max-width: 1537px) {
    width: 386px !important;
  }
  @media screen and (min-width: 1921px) {
    width: 480px !important;
  }

  & > div {
    span {
      left: -38px;
    }

    & > div {
      margin: 0;
      div {
        & > div:nth-child(even) {
          display: none;
        }
        & > div {
          span {
            order: 1;
            ${(props) => props.theme.fonts.primary.H2boldMobile};
            line-height: 50px;
            font-weight: 600;
          }
          label {
            order: 2;

            ${(props) => props.theme.fonts.primary.H5SemiBold};
          }
          hr {
            display: none;
            margin-top: 8px;
          }
          @media screen and (max-width: 1537px) {
            span {
              ${(props) => props.theme.fonts.primary.H1Semibold};
            }
            label {
              ${(props) => props.theme.fonts.primary.H6Bold};
              font-size: 10px;
              line-height: 12px;
            }
          }
        }
        & > div:nth-child(3) {
          label {
            color: ${(props) => props.theme.colors.statusAlert};
          }
        }
        & > div:nth-child(5) {
          label {
            order: 2;
            color: ${(props) => props.theme.colors.statusRisk};
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    & > div {
      & > div {
        justify-content: space-around;
        margin-left: 0.5rem;

        & > div {
          & > div {
            width: 30%;
            span {
              ${(props) => props.theme.fonts.primary.H2boldMobile};
              line-height: 50px;
              font-weight: 600;
            }
            label {
              ${(props) => props.theme.fonts.primary.H5Regular};
            }
          }
        }
      }
    }
  }
`;

export const CardAssetTypesCountDashboard = styled(FrameDashboardRollBase)`
  width: 600px !important;
  @media screen and (max-width: 1537px) {
    width: 500px !important;
  }
  @media screen and (min-width: 1921px) {
    width: 632px !important;
  }

  & > div {
    & > div {
      padding: 2% 4%;
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      & > div {
        width: 32.5%;
        @media screen and (max-width: 1537px) {
          width: 49% !important;
        }
        & > div:first-child {
          margin-bottom: 0 !important;
        }
      }
      @media screen and (max-width: 1537px) {
        flex-direction: row;
      }
    }
    span {
      left: -50px;
    }

    & > div {
      ul > div {
        margin-bottom: 0;
        label {
          max-width: 130px;
          @media screen and (max-width: 1537px) {
            max-width: 186px;
          }
        }
      }
      span {
        margin: 0;
        ${(props) => props.theme.fonts.primary.H4Regular};
      }

      svg {
        margin-bottom: 4px;
      }
    }
  }
`;

export const CardDiagnosticHistoryDashboard = styled(FrameDashboardRollBase)`
  width: 600px !important;
  @media screen and (max-width: 1537px) {
    width: 500px !important;
  }
  @media screen and (min-width: 1921px) {
    width: 632px !important;
  }

  & > div {
    & > div {
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 8px;

      & > div {
        width: 32.3%;
        @media screen and (max-width: 1537px) {
          width: 49% !important;
        }

        & > div:first-child {
          margin-bottom: 0 !important;
        }
      }
      @media screen and (max-width: 1537px) {
        flex-direction: row;
      }
    }

    & > span:first-child {
      left: -30px;
    }

    & > div {
      ul > div {
        margin-bottom: 0;
        label {
          max-width: 130px;
          @media screen and (max-width: 1537px) {
            max-width: 186px;
          }
        }
      }
      span {
        margin: 0;
        ${(props) => props.theme.fonts.primary.H4Regular};
      }

      svg {
        margin-bottom: 4px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    & > div {
      & > div {
        margin-left: 0.5rem;
      }
    }
  }
`;

export const CardDiagnosticDashboard = styled(FrameDashboardRollBase)`
  width: 600px !important;
  @media screen and (max-width: 1537px) {
    width: 344px !important;
  }
  @media screen and (min-width: 1921px) {
    width: 632px !important;
  }

  & > div {
    & > div {
      padding: 2% 4%;
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      & > div {
        width: 49%;

        & > div:first-child {
          margin-bottom: 0 !important;
        }
      }
      @media screen and (max-width: 1537px) {
        flex-direction: column;
      }
    }
    & > span:first-child {
      left: -30px;
    }

    & > div {
      span {
        margin: 0;
        ${(props) => props.theme.fonts.primary.H4Regular};
      }

      svg {
        margin-bottom: 4px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    & > div {
      & > div {
        margin-left: 0.5rem;
      }
    }
  }
`;

export const CardOccurrenceDashboard = styled(FrameDashboardRollBase)`
  width: 420px !important;
  @media screen and (max-width: 1537px) {
    width: 300px !important;
  }
  @media screen and (min-width: 1921px) {
    width: 424px !important;
  }
  & > div {
    span {
      left: -20px;
    }
  }
  & > div {
    justify-content: space-between;
    & > div {
      & > div {
        margin: 0;
        span {
          ${(props) => props.theme.fonts.primary.H2boldMobile};
          line-height: 50px;
          font-weight: 600;
        }
        label {
          ${(props) => props.theme.fonts.primary.H5SemiBold};
        }
        @media screen and (max-width: 1537px) {
          span {
            ${(props) => props.theme.fonts.primary.H1Semibold};
          }
          label {
            ${(props) => props.theme.fonts.primary.H6Bold};
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
      & > div {
        justify-content: space-evenly;
        & > div:nth-child(2) {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    & > div {
      & > div {
        justify-content: space-around;
        margin-left: 0.5rem;

        & > div {
          & > div {
            width: 30%;
            span {
              ${(props) => props.theme.fonts.primary.H2boldMobile};
              line-height: 50px;
              font-weight: 600;
            }
            label {
              ${(props) => props.theme.fonts.primary.H5Regular};
            }
          }
        }
      }
    }
  }
`;

export const CardHourMeterDashboard = styled(FrameDashboardRollBase)`
  /* @media screen and (max-width: 1537px) {
    width: 265px !important;
  }
  @media screen and (min-width: 1921px) {
    width: 424px !important;
  }
  & > div {
    & > div {
      & > span:first-child {
        margin: 0;
        ${(props) => props.theme.fonts.secondary.H1Regular};
        opacity: 0.8;
        text-align: center;
        position: absolute;
        left: -56px;
        transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        @media screen and (max-width: 1537px) {
          left: -42px;
        }
      }
    }
  } */

  @media screen and (max-width: 767px) {
    padding: 0.5rem;
    & > div {
      flex-direction: column;
    }
  }
`;

export const CardConsumptionDashboard = styled(FrameDashboardRollBase)`
  @media screen and (max-width: 1537px) {
    width: 365px !important;
  }

  @media screen and (min-width: 1921px) {
    width: 424px !important;
  }

  & > div {
    & > div:nth-child(1) {
      width: 00px;
    }
    & > div:nth-child(2) {
      width: 90%;
    }

    & > div {
      & > span:first-child {
        margin: 0;
        ${(props) => props.theme.fonts.secondary.H1Regular};
        opacity: 0.8;
        text-align: center;
        position: absolute;
        left: -66px;
        transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        @media screen and (max-width: 1537px) {
          left: -66px;
        }
      }
    }
  }
`;

export const FrameBodyMainOccurrences = styled(FrameBodyMain)`
  flex-direction: column;
  height: auto;
  max-height: 100vh;

  @media screen and (max-width: 767px) {
    overflow-x: hidden;
  }
`;

export const MarqueeContainer = styled.div`
  width: 100%;
  position: relative;

  & > div > div > div {
  }

  .marquee {
    & > div {
      width: 100%;
      & > div {
        flex: 1;
      }
    }
  }
`;

export const SpeedButtonsContainer = styled.button`
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  z-index: 2;
  width: 80px;
  height: 80px;
  background-color: ${(props) => props.theme.colors.cardBackground};
  transition: opacity 0.2s;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: ${(props) => props.theme.colors.iconsSecondary};

  box-shadow: inset 9px 5px 35px -10px rgba(0, 0, 0, 0.1);

  svg {
    width: 28px;
    height: auto;
  }

  span {
    ${(props) => props.theme.fonts.primary.H1Semibold};
    font-size: 22px;
    margin: 6px;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    filter: brightness(1.1);
    opacity: 1;
  }
`;

export const SpeedContainerLeft = styled(SpeedButtonsContainer)`
  left: 8px;

  svg {
    transform: rotate(180deg);
  }
`;

export const SpeedContainerRight = styled(SpeedButtonsContainer)`
  right: 8px;
`;

export const PauseButton = styled(SpeedButtonsContainer)`
  right: 50%;

  :hover {
    opacity: 1;
  }
`;
