import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex-direction: column;
  border-radius: 6px;

  @media screen and (max-width: 767px) {
    height: 50vh;
    gap: 1rem;
  } ;
`;

export const CardTotalReport = styled.div`
  min-width: 138px;
  height: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  border-radius: 6px;
  padding: 16px 8px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.divBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  -webkit-box-shadow: 9px 9px 5px -7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 9px 9px 5px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 9px 9px 5px -7px rgba(0, 0, 0, 0.2);

  @media screen and (min-width: 1537px) {
    min-width: 240px;
  }

  span {
    text-align: center;
  }

  color: ${(props) => props.theme.colors.iconsSecondary};

  div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) => props.theme.fonts.primary.H2boldCard};
    line-height: 50px;
  }

  div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) => props.theme.fonts.primary.H4Regular};
  }

  @media screen and (max-width: 767px) {
    min-width: 7.2rem;

    span {
      text-align: center;
    }
  } ;
`;

export const RepresentativeDataReport = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.passwordBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  gap: 24px;
  -webkit-box-shadow: inset 9px 9px 5px -7px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 9px 9px 5px -7px rgba(0, 0, 0, 0.08);
  box-shadow: inset 9px 9px 5px -7px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 767px) {
    height: 12rem;
    gap: 1rem;
    padding: 0 1rem;
  } ;
`;
