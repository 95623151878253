import styled from "styled-components";

export const RatingButton = styled.button<{
  like?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
  border: none;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    svg {
      filter: brightness(1.2);
    }
  }
`;

export const Container = styled.div`
  ${(props) => props.theme.fonts.primary.H4Regular};
  display: flex;
  gap: 1rem;
  padding: 0.2rem 0 0.2rem 0.8rem;
`;
