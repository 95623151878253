import React, { ReactNode } from "react";

import { Container } from "./styles";
import { WelcomeTitle } from "./Compounds/WelcomeTitle";
import { AnalyticsTabTitle } from "./Compounds/AnalyticsTabTitle";
import { UserForm } from "./Compounds/UserForm";
import { AnalysisInput } from "./Compounds/AnalysisInput";
import { GetApiKey } from "./Compounds/GetApiKey";
import { ConfirmButton } from "./Compounds/ConfirmButton";
import { CancelButton } from "./Compounds/CancelButton";
import { FooterContainer } from "./Compounds/FooterContainer";
import { AccordionSection } from "./Compounds/AccordionSection";
import { BodyContainer } from "./Compounds/BodyContainer";
import { BetaBadge } from "./Compounds/BetaBadge";

interface AnalyticsComponentProps {
  children: ReactNode;
}

export function AnalyticsComponent({ children }: AnalyticsComponentProps) {
  return <Container>{children}</Container>;
}

AnalyticsComponent.Title = WelcomeTitle;
AnalyticsComponent.TabTitle = AnalyticsTabTitle;
AnalyticsComponent.UserForm = UserForm;
AnalyticsComponent.Input = AnalysisInput;
AnalyticsComponent.GetApi = GetApiKey;
AnalyticsComponent.ConfirmButton = ConfirmButton;
AnalyticsComponent.CancelButton = CancelButton;
AnalyticsComponent.AccordionSection = AccordionSection;
AnalyticsComponent.Body = BodyContainer;
AnalyticsComponent.Footer = FooterContainer;
AnalyticsComponent.Beta = BetaBadge;
