import { EmptyList } from "../PositionList/styles";
import { ImFileEmpty } from "react-icons/im";
import { useTranslation } from "react-i18next";
import OccurrenceListContext from "./context";
import HeaderTable from "./components/HeaderTable";
import InfiniteScrollDesktop from "./components/InfiniteScrollDesktop";
import ContainerTabDesktop from "./components/ContainerTabDesktop";
import ListTabDesktop from "./components/ListTabDesktop";
import ListPageDesktop from "./components/ListPageDesktop";
import ContainerPageDesktop from "./components/ContainerPageDesktop";
import FilteredLabels from "./components/FilteredLabels";

interface IOccurrenceList {
  update: any;
  refreshSidebar?: () => void;
  records: any;
  children: JSX.Element | JSX.Element[];
  pagination: any;
  handleGetRecords: any;
  processing: boolean;
}

function Empty() {
  const { t } = useTranslation();
  return (
    <EmptyList>
      <ImFileEmpty size={60} />
      <p>{t("OccurrenceList.notFound")}</p>
    </EmptyList>
  );
}

const OccurrenceList = ({
  update,
  refreshSidebar = () => null,
  records,
  children,
  handleGetRecords,
  pagination,
  processing,
}: IOccurrenceList) => {
  
  return (
    <OccurrenceListContext.Provider
      value={{
        records,
        pagination,
        handleGetRecords,
        processing,
      }}
    >
      <>{children}</>
    </OccurrenceListContext.Provider>
  );
};

OccurrenceList.ContainerTabDesktop = ContainerTabDesktop;
OccurrenceList.ContainerPageDesktop = ContainerPageDesktop;
OccurrenceList.ListPageDesktop = ListPageDesktop;
OccurrenceList.ListTabDesktop = ListTabDesktop;

OccurrenceList.InfiniteScrollDesktop = InfiniteScrollDesktop;
OccurrenceList.HeaderTable = HeaderTable;
OccurrenceList.FilteredLabels = FilteredLabels;
OccurrenceList.Empty = Empty;
export default OccurrenceList;
