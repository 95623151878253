import styled from "styled-components";

export const ContainerModalComments = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  span {
    ${(props) => props.theme.fonts.primary.H3SemiBold}
  }

  & > div {
    color: ${(props) => props.theme.colors3.color0};
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    flex-direction: column;
    gap: 0.5rem;

    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      gap: 1rem;
      border-radius: 6px;
      border: none;
      background: ${(props) => props.theme.colors.ibbxOrange};
      color: ${(props) => props.theme.colors.white};
      svg {
        font-size: 1rem;
      }
      span {
        ${(props) => props.theme.fonts.primary.H4Bold}
      }

      &:hover {
        border: 1px solid ${(props) => props.theme.colors.iconsPrimary};
        opacity: 0.9;
      }

      &:active {
        border: none;
        box-shadow: 0 0 0 1px ${(props) => props.theme.colors.ibbxOrange};
      }
    }
  }

  textarea {
    width: 100%;
    height: 10rem;
    background: transparent;
    padding: 6px;
    border: none;
    border-radius: 4px;
    resize: none;
    background: ${(props) => props.theme.colors.background};

    ${(props) => props.theme.fonts.primary.H4Regular}
    color: ${(props) => props.theme.colors.textPrimary};
    outline: none;
  }
`;

// ========= Card comentários feitos ===========

export const CardCommented = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    width: 100%;
    height: 100%;
    margin-bottom: 0.75rem;

    & > div:last-child {
      width: 100%;
      min-height: 4rem;
      max-height: 12rem;
      padding: 0 0.5rem;
      overflow: auto;
      border-radius: 6px;
      background: ${(props) => props.theme.colors.cardSectorBackground};
    }

    & > span {
      color: ${(props) => props.theme.colors.textPrimary};
    }

    strong {
      ${(props) => props.theme.fonts.primary.H4Regular}
    }
  }
`;

export const CardInfo = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.iconsPrimary};
  margin-bottom: 10px;
  ${(props) => props.theme.fonts.primary.H5Bold}
  background: ${(props) => props.theme.colors.divBackground};
  margin-bottom: 1rem;
`;

export const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  gap: 0.5rem;
  background: transparent;
  color: ${(props) => props.theme.colors.iconsPrimary};
  border: 1px solid transparent;
  ${(props) => props.theme.fonts.primary.H5Bold}

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    transition: all 0.3s ease;
  }

  svg {
    cursor: pointer;
    :hover {
      color: ${(props) => props.theme.colors.ibbxOrange};
    }
    &:active {
      /* background: transparent !important; */
      border: 1px solid transparent !important;
      color: ${(props) => props.theme.colors.ibbxOrange};
    }
  }
`;
