import { useState } from "react";
import { Carousel } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { IoMdFastforward, IoMdPause, IoMdPlay } from "react-icons/io";

import { useTheme } from "styled-components";
import CardAssetsStatus from "../../../components/CardAssetsStatus";
// import CardAssetTypesCount from "../../../components/CardAssetTypesCount";
// import CardDiagnosticHistory from "../../../components/CardDiagnosticHistory";
import CardIoTConnectivity from "../../../components/CardIoTConnectivity";
import CardOccurrencesCount from "../../../components/CardOccurrencesCount";
import CardOccurrencesStatus from "../../../components/CardOccurrencesStatus";
import { isMobile } from "../../../utils/mediaQuery";
import {
  CardIoTConnectivityDashboard,
  CardMonitoredPointsDashboard,
  CardMonitoringStatusDashboard,
  CardOccurrenceDashboard,
  MarqueeContainer,
  PauseButton,
  SpeedContainerLeft,
  SpeedContainerRight,
} from "../styles";

function MarqueeDashboard() {
  const { theme } = useTheme();

  const [speed, setSpeed] = useState(50);
  const [clickCount, setClickCount] = useState(0);
  const [motionDirection, setMotionDirection] = useState<"left" | "right">(
    "left",
  );
  const [paused, setPaused] = useState<boolean>(true);

  const handleClickLeft = () => {
    let newSpeed = speed;

    switch (clickCount) {
      case 0:
        newSpeed = 150;
        setMotionDirection("left");
        break;
      case 1:
        newSpeed = 300;
        setMotionDirection("left");
        break;
      case 2:
        newSpeed = 50;
        setMotionDirection("left");
        break;
      default:
        break;
    }

    setSpeed(newSpeed);

    setClickCount(clickCount + 1);
    if (clickCount === 2) {
      setClickCount(0);
    }
  };

  const handlePause = () => {
    setPaused(!paused);
  };

  const handleClickRight = () => {
    let newSpeed = speed;

    switch (clickCount) {
      case 0:
        newSpeed = 150;
        setMotionDirection("right");
        break;
      case 1:
        newSpeed = 300;
        setMotionDirection("right");
        break;
      case 2:
        newSpeed = 50;
        setMotionDirection("right");
        break;
      default:
        break;
    }

    setSpeed(newSpeed);

    setClickCount(clickCount + 1);
    if (clickCount === 2) {
      setClickCount(0);
    }
  };

  let label;

  switch (speed) {
    case 50:
      label = "1x";
      break;
    case 150:
      label = "2x";
      break;
    case 300:
      label = "3x";
      break;
  }

  if (!isMobile()) {
    return (
      <MarqueeContainer>
        <Marquee
          pauseOnHover={true}
          speed={speed}
          style={{ gap: "20px", display: "flex", overflow: "hidden" }}
          gradientColor={theme === "dark" ? [11, 13, 18] : [227, 232, 238]}
          gradientWidth={200}
          direction={motionDirection}
          play={paused}
        >
          <div style={{ gap: "20px", display: "flex" }}>
            {/* <CardAssetTypesCountDashboard>
              <CardAssetTypesCount />
            </CardAssetTypesCountDashboard>
            <CardDiagnosticHistoryDashboard>
              <CardDiagnosticHistory />
            </CardDiagnosticHistoryDashboard> */}
            <CardMonitoredPointsDashboard>
              <CardAssetsStatus />
            </CardMonitoredPointsDashboard>
            <CardIoTConnectivityDashboard>
              <CardIoTConnectivity />
            </CardIoTConnectivityDashboard>
            <CardMonitoringStatusDashboard>
              <CardOccurrencesStatus />
            </CardMonitoringStatusDashboard>
            <CardOccurrenceDashboard>
              <CardOccurrencesCount />
            </CardOccurrenceDashboard>
          </div>
        </Marquee>
        <SpeedContainerRight onClick={handleClickRight}>
          <span>{label}</span>
          <IoMdFastforward />
        </SpeedContainerRight>
        <SpeedContainerLeft onClick={handleClickLeft}>
          <IoMdFastforward />
          <span>{label}</span>
        </SpeedContainerLeft>
        <PauseButton onClick={handlePause}>
          {!paused ? <IoMdPlay /> : <IoMdPause />}
        </PauseButton>
      </MarqueeContainer>
    );
  } else {
    return (
      <Carousel>
        {/* <Carousel.Item>
          <CardAssetTypesCountDashboard>
            <CardAssetTypesCount />
          </CardAssetTypesCountDashboard>
        </Carousel.Item>
        <Carousel.Item>
          <CardDiagnosticHistoryDashboard>
            <CardDiagnosticHistory />
          </CardDiagnosticHistoryDashboard>
        </Carousel.Item> */}
        <Carousel.Item>
          <CardMonitoredPointsDashboard>
            <CardAssetsStatus />
          </CardMonitoredPointsDashboard>
        </Carousel.Item>
        <Carousel.Item>
          <CardIoTConnectivityDashboard>
            <CardIoTConnectivity />
          </CardIoTConnectivityDashboard>
        </Carousel.Item>
        <Carousel.Item>
          <CardMonitoringStatusDashboard>
            <CardOccurrencesStatus />
          </CardMonitoringStatusDashboard>
        </Carousel.Item>
        <Carousel.Item>
          <CardOccurrenceDashboard>
            <CardOccurrencesCount />
          </CardOccurrenceDashboard>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default MarqueeDashboard;
