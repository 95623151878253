import { ReactNode } from "react";
import "react-modern-drawer/dist/index.css";
import { DrawerContainer, Toogle } from "./styles";
import ModernDrawer from "react-modern-drawer"

interface DrawerComponentProps {
  children: ReactNode;
  isOpen: boolean;
  direction: "left" | "right" | "top" | "bottom";
  icon?: ReactNode;
  label?: string;
  title?: string;
  size?: string | number;
  handleClose?: () => void;
  handleOpen?: () => void;
  zIndex?: number;
}

export function DrawerComponent({
  children,
  icon,
  label,
  title,
  isOpen,
  handleClose,
  handleOpen,
  direction,
  ...DrawerProps
}: DrawerComponentProps) {
  return (
    <>
      <Toogle onClick={handleOpen} title={title}>
        {icon}
        <span>{label}</span>
      </Toogle>
      <DrawerContainer>
        <ModernDrawer
          open={isOpen}
          onClose={handleClose}
          direction={direction}
          {...DrawerProps}
        >
          {children}
        </ModernDrawer>
      </DrawerContainer>
    </>
  );
}
