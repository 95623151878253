import styled from "styled-components";

export const Container = styled.div``;

export const ContainerScanner = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 20px;
  gap: 24px;
  width: 100%;
  opacity: ${(props) => (props.disabled ? 0.2 : "")};
  position: relative;

  h1 {
    ${(props) => props.theme.fonts.primary.H2Bold};
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  span {
    ${(props) => props.theme.fonts.primary.H5Regular}
  }

  svg {
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    gap: 10px;

    & > div {
      display: flex;
      flex-direction: column;

      width: 100%;
      input {
        ${(props) => props.theme.fonts.primary.H4Regular};
        width: 100%;
        min-height: 36px;
        margin-top: 4px;
        color: ${(props) => props.theme.colors.iconsPrimary};
        background: ${(props) => props.theme.colors.background};
        border: ${(props) => props.theme.colors.cardHighlight};
        border-radius: 4px;
        padding: 8px;
        border: 1px solid ${(props) => props.theme.colors.cardHighlight};

        &:hover {
          border: 1px solid ${(props) => props.theme.colors.IconsTools};
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    input {
      font-size: 1rem;
    }
    padding: 0.3rem;
  }
`;

export const SwitchCam = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  right: 3.5rem;
  bottom: 3.5rem;
  border-radius: 50%;
  z-index: 2;

  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};

  svg {
    font-size: 1.75rem;
    color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  }

  @media screen and (max-width: 767px) {
    right: 2.5rem;
    bottom: 2.5rem;
  }
`;
