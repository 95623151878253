import { useMemo, useRef, useState } from "react";
import {
  DeletedRestore,
  HeaderEvidence,
  OccurrenceEvidenceContainer,
  OccurrenceEvidenceHover,
  OccurrenceEvidenceImageContainer,
  OpenPdfEvidence,
  PositionIdContainer,
  SysStatusIdContainer,
} from "./stylest";
import { useTheme } from "styled-components";
import { IoTriangle } from "react-icons/io5";
import { MdDelete, MdOpenInNew } from "react-icons/md";
import { FaChartLine } from "react-icons/fa";
import { ConfirmModalV2 } from "../../../modals/ConfirmModalV2";
import { PROFILES, userSelect } from "../../../store/slices/userSlice";
import { useAppSelector } from "../../../store/hooks";

import { ReactComponent as CreatedByRetina } from "../../../images/createdByRetina.svg";
import { ReactComponent as CreatedByAI } from "../../../images/createdByAi.svg";
import { ReactComponent as CreatedByNoc } from "../../../images/createdByNoc.svg";
import { ReactComponent as CreatedManual } from "../../../images/createdManual.svg";

import { useTranslation } from "react-i18next";
import { BsFiletypePdf } from "react-icons/bs";
import { useSegment } from "../../../hooks/useSegmentTrackEvent ";
import useOccurrenceEvidence from "./useOcccurrenceEvidence";
import { TbArrowBackUp } from "react-icons/tb";
import { isMobile } from "react-device-detect";
import useRetinaTranslation from "../../../hooks/useRetinaTranslation";

interface IOccurrenceEvidence {
  evidence: any;
  refresh: () => void;
  positionName?: string;
  setExpandedEvidence?: (value: any) => any;
  disable?: boolean;
}

export default function OccurrenceEvidence({
  evidence,
  refresh = () => null,
  positionName,
  setExpandedEvidence,
  disable,
}: IOccurrenceEvidence) {
  const user = useAppSelector(userSelect);

  const { handleTrackEvent } = useSegment();
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { buildStatusCaptionById: transStatusCaptionById } =
    useRetinaTranslation();
  const { toggleDelete, title, analysisLink } = useOccurrenceEvidence({
    evidence,
    refresh,
  });

  const deleteMasterRef = useRef<HTMLButtonElement>(null);
  const deleteClientRef = useRef<HTMLButtonElement>(null);

  const [deleteSelectedEvidence, setDeleteSelectedEvidence] = useState(false);

  const urlProp = useMemo(() => {
    return theme === "dark" ? "chartDark" : "url";
  }, [theme]);

  const userAdmin = [
    PROFILES.EXECUTIVO_MASTER,
    PROFILES.MESA_MASTER,
    PROFILES.MESA_ANALISTA,
    PROFILES.REPRESENTANTE,
  ].includes(user.profile);

  const isClient = [PROFILES.CLIENTE_COMUM, PROFILES.CLIENTE_MASTER].includes(
    user.profile,
  );

  const isClientView = [PROFILES.CLIENTE_MASTER_VIEW].includes(user.profile);

  const getPercent = () => {
    if (evidence.type !== "CHART" || evidence.openedByAI) {
      return <></>;
    }

    let icon = <IoTriangle color={"red"} />;
    const { condition } = evidence?.metadata ?? {};
    if (condition === "DECREASE") {
      icon = (
        <IoTriangle color={"red"} style={{ transform: "rotate(180deg)" }} />
      );
    }
    const percent = Math.round((evidence?.percent ?? 0) * 100) || "";

    return (
      <>
        {icon} <span>{percent && `${percent}%`}</span>
      </>
    );
  };

  const renderIconCreatedBy = () => {
    if (evidence?.openedByAI) {
      return <CreatedByAI />;
    } else if (
      ["EXECUTIVO_MASTER", "MESA_MASTER"].includes(evidence?.profile)
    ) {
      return <CreatedByNoc />;
    } else if (
      ["CLIENTE_MASTER", "CLIENTE_COMUM"].includes(evidence?.profile)
    ) {
      return <CreatedManual />;
    } else if (evidence?.createdBy === null) {
      return <CreatedByRetina />;
    } else {
      return "";
    }
  };

  const renderIconDescription = () => {
    if (evidence.openedByAI) {
      return t(
        "occurrencePanel.OccurrenceEvidence.Criado pela inteligência artificial IBBx.",
      );
    } else if (evidence?.profile === "EXECUTIVO_MASTER") {
      return t("occurrencePanel.OccurrenceEvidence.Adicionada pelo NOC.");
    } else if (
      evidence?.profile === "CLIENTE_MASTER" ||
      evidence?.profile === "CLIENTE_COMUM"
    ) {
      return t("occurrencePanel.OccurrenceEvidence.Adicionada manualmente.");
    } else if (evidence.createdBy === null) {
      return t(
        "occurrencePanel.OccurrenceEvidence.Criado automaticamente pelos alertas pré-definidos.",
      );
    }
  };

  function handleContainerClick(event: any) {
    const handleViewPDF = () => window.open(evidence.url, "_blank");
    const preventClickOnLink = (event.target as HTMLElement).closest("a");

    if (preventClickOnLink) {
      return;
    }

    if (deleteClientRef?.current?.contains(event.target)) {
      return;
    }
    if (deleteMasterRef?.current?.contains(event.target)) {
      return;
    }

    if (evidence.type === "PDF") {
      return handleViewPDF();
    }

    setExpandedEvidence && setExpandedEvidence(evidence);
  }
  const handleButtonClick = (link: string) => {
    window.open(link, "_blank");
  };

  const CHART_STATUS_PHRASE: any = {
    5: "Evidência em Risco",
    4: "Evidência em Análise",
    3: "Evidência em Alerta",
    2: "Evidência em Alerta",
  };

  const handleToggleDelete = () => {
    toggleDelete();
    setDeleteSelectedEvidence(false);
  };

  return (
    <>
      <OccurrenceEvidenceContainer deleted={evidence.deletedAt}>
        <HeaderEvidence>
          <div>
            <span>
              {title} {getPercent()}
            </span>
          </div>

          <div>
            {evidence.positionName && userAdmin && (
              <>
                <span>{evidence.positionName ?? ""}</span>
                <span> Id: {evidence.positionId}</span>
              </>
            )}

            {!!evidence.positionId && (
              <button
                onClick={() =>
                  handleButtonClick(`/positions/${evidence.positionId}`)
                }
                title={`${t(
                  "occurrencePanel.OccurrenceEvidence.Ir até o ponto",
                )}`}
              >
                <MdOpenInNew />
              </button>
            )}
          </div>
        </HeaderEvidence>
        <OccurrenceEvidenceImageContainer
          deleted={evidence.deletedAt}
          onClick={handleContainerClick}
        >
          <OccurrenceEvidenceHover
            isMobile={isMobile}
            deleted={evidence.deletedAt}
          >
            {!disable && (
              <>
                {analysisLink && evidence.type === "CHART" && (
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      handleButtonClick(analysisLink);
                    }}
                    title={`${t(
                      "occurrencePanel.OccurrenceEvidence.Ver detalhes desta evidência no gráfico",
                    )}`}
                  >
                    <FaChartLine />
                  </button>
                )}
                {!isClientView && userAdmin && (
                  <DeletedRestore
                    restore={evidence.deletedAt}
                    ref={deleteMasterRef}
                    onClick={() => {
                      setDeleteSelectedEvidence(true);
                      handleTrackEvent({
                        event:
                          "PAINEL OCORRÊNCIA - CARD EVIDÊNCIA, Excluir Evidência",
                      });
                    }}
                    title={
                      evidence.deletedAt
                        ? ""
                        : `${t(
                            "occurrencePanel.OccurrenceEvidence.Excluir Evidência",
                          )}`
                    }
                  >
                    {evidence.deletedAt ? (
                      <>
                        <span>
                          {t("occurrencePanel.OccurrenceEvidence.Restaurar")}
                        </span>
                        <TbArrowBackUp />
                      </>
                    ) : (
                      <MdDelete />
                    )}
                  </DeletedRestore>
                )}
              </>
            )}

            {!isClientView &&
              isClient &&
              evidence.type === "IMAGE" &&
              !evidence.deletedAt && (
                <button
                  ref={deleteClientRef}
                  onClick={() => setDeleteSelectedEvidence(true)}
                  title={`${t(
                    "occurrencePanel.OccurrenceEvidence.Excluir Evidência",
                  )}`}
                >
                  {evidence.deletedAt ? <TbArrowBackUp /> : <MdDelete />}
                </button>
              )}
          </OccurrenceEvidenceHover>
          {evidence.type === "CHART" && (
            <SysStatusIdContainer
              sysStatusId={evidence.sysStatusId ?? 1}
              title={CHART_STATUS_PHRASE[evidence.sysStatusId] ?? ""}
            >
              <span>
                {evidence.sysStatusId > 1 &&
                  transStatusCaptionById(evidence.sysStatusId, true)}
              </span>
            </SysStatusIdContainer>
          )}

          <PositionIdContainer>
            <div title={renderIconDescription()}>{renderIconCreatedBy()}</div>
          </PositionIdContainer>

          {evidence.type !== "PDF" ? (
            <img
              src={evidence[urlProp]}
              width={"fit-content"}
              height={"260px"}
              alt=""
            />
          ) : (
            <OpenPdfEvidence>
              <BsFiletypePdf />
              <span>{t("attachmentsEvidenceModal.clickToView")}</span>
            </OpenPdfEvidence>
          )}
        </OccurrenceEvidenceImageContainer>
        {deleteSelectedEvidence && (
          <ConfirmModalV2
            text={`${t(
              "occurrencePanel.OccurrenceEvidence.Tem certeza que deseja excluir essa evidência?",
            )}`}
            show={deleteSelectedEvidence}
            handleClose={() => setDeleteSelectedEvidence(false)}
            handleConfirm={handleToggleDelete}
          />
        )}
      </OccurrenceEvidenceContainer>
    </>
  );
}
