import styled, { keyframes } from "styled-components";
import { HideMenu } from "../../Companies/CardCompany/styles";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors.iconsSecondary};

  span {
    ${(props) => props.theme.fonts.primary.H3SemiBold}
  }
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  button {
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.colors.iconsSecondary};
    margin-left: 16px;
    align-self: center;
  }
  @media screen and (max-width: 767px) {
    span {
      margin: 0.25rem;
      font-size: 0.75rem;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 0;
  max-height: 100%;
  min-height: 90%;
  gap: 2.5%;

  @media screen and (max-width: 767px) {
    height: auto;
    min-height: auto;
    gap: 1%;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    gap: 0;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 25%;

    span {
      ${(props) => props.theme.fonts.primary.H2boldCard};
      margin: 0;
    }

    label {
      ${(props) => props.theme.fonts.primary.H4Bold};
      text-align: center;
    }

    @media screen and (max-width: 1445px) {
      br {
        display: none;
      }
      span {
        ${(props) => props.theme.fonts.primary.H2boldCard};
        margin: 0;
      }

      label {
        ${(props) => props.theme.fonts.primary.H5Bold};
        text-align: center;
      }
    }

    // Windows 100% - Browser 125% / Windows 125% - Browser 100%
    @media screen and (min-width: 1271px) and (max-width: 1524px) {
      flex: 1 1 140px !important;
    }

    // Windows 100% - Browser 150%
    @media screen and (min-width: 767px) and (max-width: 1270px) {
      span {
        ${(props) => props.theme.fonts.primary.H1Semibold};
      }
      label {
        font-size: 10px;
      }
    }

    @media screen and (max-width: 767px) {
      width: 48%;
      position: relative;

      span {
        font-size: 1.75rem;
        line-height: 46px;
      }

      label {
        font-size: 0.75rem;
      }
    }
  }
`;

export const InfoButtonContainer = styled(HideMenu)`
  @media screen and (max-width: 767px) {
    right: 0;
    top: 0;
  }
`;

export const InfoContainer = styled.div<{ left?: number }>`
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: ${(props) => props.theme.colors.iconsSecondary};
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.cardBackground};
  border-radius: 6px;
  padding: 8px;
  right: 36px;
  top: 5px;
  flex-direction: column;
  gap: 8px;
  z-index: 2;
  span {
    ${(props) => props.theme.fonts.primary.H5Regular};
    border: 1px solid transparent;
  }

  @media screen and (max-width: 767px) {
    left: 0px;
    top: 2rem;
    left: ${(props) => props.left && props.left - 280 + "px"};
  }
`;
export const Triangle = styled.div<{ left?: number }>`
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  right: 30px;
  top: 12px;
  border-left: 6px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  position: absolute;
  transform: rotate(360deg);

  @media screen and (max-width: 767px) {
    top: 23px;
  }
`;

export const Data = styled.div``;
