import { PiChartLineUpLight, PiWaveSineDuotone } from "react-icons/pi";
import { useState } from "react";
import { MdOutlineStackedLineChart } from "react-icons/md";
import { AnalysisToolsProps, EnumAnalysisTools } from "./types";
import { RiBarChartLine, RiFocus3Line, RiLineChartFill } from "react-icons/ri";
import { AlertContent, AnalisysTool, AnalisysToolsContainer } from "./styles";
import { BsChatRightText, BsFillGearFill, BsSoundwave } from "react-icons/bs";
import { SiSimpleanalytics } from "react-icons/si";
import { BearingIcon } from "../../../components/ImgSvg";
import { HarmonicsForm } from "../HarmonicsForm";
import { BearingForm } from "../BearingForm";
import { NoiseFilterForm } from "../NoiseFilterForm";
import { GearingForm } from "../GearingForm";
import { PROFILES, userSelect } from "../../../store/slices/userSlice";
import { useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import { FaThermometerHalf } from "react-icons/fa";

function AnalysisTools({
  chart,
  setAnalysis,
  position,
  noiseFilter,
  setNoiseFilter,
  hiddenItem,
}: AnalysisToolsProps) {
  const user = useAppSelector(userSelect);
  const [selectedTool, setSelectedTool] = useState<EnumAnalysisTools>("NONE");
  const [showModalHarmonics, setShowModalHarmonics] = useState(false);
  const [showModalNoiseFilter, setShowModalNoiseFilter] = useState(false);
  const [showModalBearings, setshowModalBearings] = useState(false);
  const [showModalGearing, setShowModalGearing] = useState(false);

  const bearingsOptions: any = ["GAIOLA", "BPFI", "BPFO", "BSF"];
  const { details } = position;

  function handleSelectTool(toolName: EnumAnalysisTools) {
    if (selectedTool === toolName) {
      setAnalysis({});
      setSelectedTool("NONE");
      return;
    }

    switch (toolName) {
      case "GEARING":
        setShowModalGearing(true);
        break;
      case "BEARINGS":
        setshowModalBearings(true);
        break;
      case "HARMONICS":
        setShowModalHarmonics(true);
        break;
      case "HARMONICS_CLICK":
        setAnalysis({ harmonicsClick: true });
        break;
      case "CASCADE":
        setAnalysis({ cascade: true });
        if (noiseFilter) {
          setNoiseFilter(null);
        }
        break;
      case "FILTER":
        if (!!noiseFilter?.toString()) {
          setNoiseFilter(null);
        } else {
          setShowModalNoiseFilter(true);
        }
        break;
      case "COMMENTS":
        setAnalysis({ commentMode: true });
        break;
      case "CROSSHAIR":
        setAnalysis({ cross: true });
        break;
      case "PHASES":
        setAnalysis({ phasesAnalysis: true });
        break;
      case "TEMPERATURE_CORRELATION":
        setAnalysis({ temperatureCorrelation: true });
        break;
      case "TWF-FREQUENCY":
        setAnalysis({ twfFrequency: true });
        break;
      case "REGRESSION":
        setAnalysis({ regression: true });
        break;
    }

    const openModal = ["BEARINGS", "HARMONICS", "FILTER", "GEARING"];
    if (!openModal.includes(toolName)) {
      setSelectedTool(toolName);
    } else {
      setSelectedTool("NONE");
      setAnalysis({});
    }
  }

  const handleConfirmHarmonics = (
    fundamental: number,
    harmonics: number,
    order: number,
  ) => {
    setAnalysis({ harmonics: { active: true, fundamental, harmonics, order } });
    setSelectedTool("HARMONICS");
    setShowModalHarmonics(false);
  };

  function handleConfirmGearings(harmonic: number) {
    setAnalysis({
      gearing: harmonic,
    });
    setSelectedTool("GEARING");
    setShowModalGearing(false);
  }
  const handleConfirmNoiseFilter = (value: number | null) => {
    setNoiseFilter(value);
    setShowModalNoiseFilter(false);
  };

  const handleConfirmBearings = (stamps: any, harmonics: any) => {
    setAnalysis({
      bearingStamps: {
        stamps,
        harmonics,
        visible: bearingsOptions,
      },
    });
    setSelectedTool("BEARINGS");
    setshowModalBearings(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <AnalisysToolsContainer>
        {chart?.metricUnits?.x?.toUpperCase() === "HZ" && (
          <>
            <AnalisysTool
              title={`${t("AnalysisTools.noiseFilter")}`}
              selected={!!noiseFilter?.toString()}
              onClick={() => {
                handleSelectTool("FILTER");
              }}
            >
              <BsSoundwave />
            </AnalisysTool>
            <AnalisysTool
              title={`${t("AnalysisTools.harmonic")}`}
              selected={selectedTool === "HARMONICS"}
              onClick={() => {
                handleSelectTool("HARMONICS");
              }}
            >
              <RiLineChartFill />
            </AnalisysTool>
            <AnalisysTool
              title={`${t("AnalysisTools.harmonic")}`}
              selected={selectedTool === "HARMONICS_CLICK"}
              onClick={() => {
                handleSelectTool("HARMONICS_CLICK");
              }}
            >
              <RiBarChartLine />
            </AnalisysTool>
            <AnalisysTool
              title={`${t("AnalysisTools.cascade")}`}
              selected={selectedTool === "CASCADE"}
              onClick={() => handleSelectTool("CASCADE")}
            >
              <MdOutlineStackedLineChart />
            </AnalisysTool>
            {chart.name !== "HAR-CORR" && (
              <>
                <AnalisysTool
                  title={`${t("AnalysisTools.bearingTag")}`}
                  selected={selectedTool === "BEARINGS"}
                  onClick={() => handleSelectTool("BEARINGS")}
                  isCustomSvg
                >
                  <BearingIcon />
                </AnalisysTool>
                <AnalisysTool
                  title={`${t("AnalysisTools.gearingTag")}`}
                  selected={selectedTool === "GEARING"}
                  onClick={() => handleSelectTool("GEARING")}
                >
                  <BsFillGearFill />
                </AnalisysTool>
              </>
            )}
          </>
        )}
        {chart?.name === "SEN-CORR" && (
          <>
            <AnalisysTool
              title={`${t("AnalysisTools.phaseAnalyzer")}`}
              selected={selectedTool === "PHASES"}
              onClick={() => handleSelectTool("PHASES")}
            >
              <SiSimpleanalytics />
            </AnalisysTool>
          </>
        )}
        {!hiddenItem && (
          <AnalisysTool
            title="Cursor"
            selected={selectedTool === "CROSSHAIR"}
            onClick={() => handleSelectTool("CROSSHAIR")}
          >
            <RiFocus3Line />
          </AnalisysTool>
        )}
        {!hiddenItem &&
          [PROFILES.EXECUTIVO_MASTER, PROFILES.MESA_MASTER].includes(
            user.profile,
          ) &&
          ["AC-RMS", "V-RMS"].includes(chart.name) && (
            <>
              <AnalisysTool
                title="Temperature"
                selected={selectedTool === "TEMPERATURE_CORRELATION"}
                onClick={() => handleSelectTool("TEMPERATURE_CORRELATION")}
              >
                <FaThermometerHalf />
              </AnalisysTool>
            </>
          )}

        {chart?.type === "DYNAMIC" && (
          <>
            <AnalisysTool
              title={`${t("AnalysisTools.comments")}`}
              selected={selectedTool === "COMMENTS"}
              onClick={() => handleSelectTool("COMMENTS")}
            >
              <BsChatRightText />
            </AnalisysTool>
            {selectedTool === "COMMENTS" && (
              <AlertContent>
                <span>
                  {t(
                    "commentModal.Para facilitar inserção de comentários, isole a linha do gráfico nas opções ao final da página.",
                  )}
                </span>
              </AlertContent>
            )}
          </>
        )}
        {chart?.name === "SINAL-BRUTO-AC" && (
          <>
            <AnalisysTool
              title={`${t("AnalysisTools.Frequency")}`}
              selected={selectedTool === "TWF-FREQUENCY"}
              onClick={() => handleSelectTool("TWF-FREQUENCY")}
            >
              <PiWaveSineDuotone />
            </AnalisysTool>
          </>
        )}
        {!hiddenItem && chart.type === "GLOBAL" && (
          <>
            <AnalisysTool
              title={`${t("chartSeries.Regression")}`}
              selected={selectedTool === "REGRESSION"}
              onClick={() => handleSelectTool("REGRESSION")}
            >
              <PiChartLineUpLight />
            </AnalisysTool>
          </>
        )}
      </AnalisysToolsContainer>
      <HarmonicsForm
        show={showModalHarmonics}
        handleConfirm={handleConfirmHarmonics}
        handleClose={() => setShowModalHarmonics(false)}
        chart={chart.name}
        rotation={details?.pointRotation ? +details.pointRotation : 0}
      />
      <GearingForm
        show={showModalGearing}
        handleConfirm={handleConfirmGearings}
        handleClose={() => setShowModalGearing(false)}
        chart={chart.name}
        rotation={details?.pointRotation ? +details.pointRotation : 0}
        gerings={details?.containsGear ? +details.containsGear : 0}
      />
      <BearingForm
        show={showModalBearings}
        handleClose={() => setshowModalBearings(false)}
        handleConfirm={handleConfirmBearings}
        position={position}
      />
      <NoiseFilterForm
        show={showModalNoiseFilter}
        handleConfirm={handleConfirmNoiseFilter}
        handleClose={() => setShowModalNoiseFilter(false)}
      />
    </>
  );
}

export default AnalysisTools;
