import styled from "styled-components";

export const AxisSelectorContainer = styled.div`
  display: flex;

  button {
    background-color: transparent;
    border: none;
  }
`;

export const AxisDiv = styled.div`
  color: ${(props) => props.theme.colors.iconsSecondary};
  margin-bottom: 10px;

  & > div:nth-child(1) {
    label {
      font-size: 11px;
      margin-bottom: 8px;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;

    label {
      font-size: 13px;
      margin-right: 36px;
    }
  }
`;

export const ButtonAxis = styled.button<{
  selected?: boolean;
  color?: string;
  noColors?: boolean;
}>`
  display: flex;
  background-color: transparent;
  border: none;
  height: 100%;
  align-items: center;
  opacity: ${({ selected }) => !selected && 0.8};

  label {
    cursor: pointer;
    ${({ theme }) => theme.fonts.primary.H4Bold};
    color: ${({ theme }) => theme.colors.iconsPrimary};
  }

  span {
    max-height: 10px;
    margin-right: 8px;
    padding: 4px 6px;
    background: ${({ theme, selected, color, noColors }) => {
      if (noColors) {
        return selected ? theme.colors.iconsSecondary : "transparent";
      }
      return color || "transprent";
    }};
    border: 1px solid ${({ theme }) => theme.colors.iconsSecondary};
    border-radius: 6px;
    color: ${({ theme, selected, color }) =>
      color || theme.colors.iconsSecondary};
  }
  &:hover {
    opacity: 1;
    span {
      border: ${(props) =>
        !props.disabled && "1px solid " + props.theme.colors.ibbxOrange};
      color: ${(props) => props.theme.colors.ibbxOrange};
    }
  }
`;
