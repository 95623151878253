import { PDFDownloadLink } from "@react-pdf/renderer";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { FaRegLightbulb } from "react-icons/fa";
import { HiChevronRight } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { MdThumbDown, MdThumbUp } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import { Modal } from "../../components/Modal";
import { NamePlusTooltip } from "../../components/NamePlusTooltip";
import {
  ModalPrintChat,
  renderInsightDocument,
} from "../../components/RetinaChat/ModalPrintChat";

import { DotsAnimation } from "../../components/RetinaChat/styles";
import { success } from "../../components/Toast";
import useApi from "../../hooks/useApi";
import useOnClickOutSide from "../../hooks/useOnClickOutside";

import {
  Container,
  Conversation,
  MotherQuestion,
  Message,
  Title,
  MessageContainer,
  OptionsContainer,
  Options,
  Button,
} from "./styles";
import { useTranslation } from "react-i18next";

interface InsightModalProps {
  show?: boolean | any;
  ref?: any;
  occurrenceId?: number | string;
  handleClose?: any;
  assetType?: string;
  diagnostic?: string;
  assetId?: number;
}

export const InsightModal = ({
  show,
  ref,
  occurrenceId,
  handleClose,
  assetType,
  diagnostic,
  assetId,
}: InsightModalProps) => {
  const assetPrefix = assetId ? `/assets/${assetId}` : "";
  const { request } = useApi({
    path: `${assetPrefix}/occurrences/${occurrenceId}/insights`,
  });

  const { t } = useTranslation();

  const { request: requestPost } = useApi({
    path: "/send-message",
  });

  const { request: requestRating } = useApi({
    path: `${assetPrefix}/insights-rating/${occurrenceId}`,
  });

  const [insightsList, setInsightsList] = useState<any>();
  const [processing, setProcessing] = useState<boolean>(false);
  const [context, setContext] = useState<any>([]);
  const [conversation, setConversation] = useState<any>([]);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [showPDFModal, setShowPDFModal] = useState<boolean>(false);

  const scrollRef = useRef<any>(null);
  const optionsRef = useRef<any>(null);

  useOnClickOutSide(optionsRef, () => setShowOptions(false));

  const isMinimumQtyOfMessage = context?.length > 4;

  const blockClick = conversation?.[conversation?.length - 1]?.isRating;

  useEffect(() => {
    const initialQuestions = insightsList?.answer
      ?.split("\n")
      .filter((topic: any) => topic.length > 5)
      .map((item: any, index: number) => {
        return {
          id: index,
          isQuestion: false,
          message: item,
          renderTime: getRenderTime(index),
          print: true,
        };
      });

    setContext([
      `Pergunta: ${insightsList?.prompt}. Resposta: ${insightsList?.answer}. `,
    ]);

    setConversation(initialQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsList]);

  const handleRateInsight = (method: string) => {
    requestRating({ method: "put", body: { method } }).then((response) =>
      success(`${t("InsightModal.rateInsight")}`),
    );
  };

  const getRenderTime = (index: number) => {
    switch (index) {
      case 0:
        return "0.25";
      case 1:
        return "0.5";
      case 2:
        return "0.75";
      case 3:
        return "1";
      case 4:
        return "1.25";
      case 5:
        return "1.5";
      case 6:
        return "1.75";
    }
  };

  const extractObjectFromString = (stringOriginal: string) => {
    const string = stringOriginal.replaceAll("\n", "");

    const startIndex = string.indexOf("{");
    const endIndex = string.indexOf("}");

    const extracted = string.substring(startIndex, endIndex + 1);

    return JSON.parse(extracted);
  };

  const checkBlacklist = (bulletpoint: string) => {
    if (
      bulletpoint.includes("procedimentos de manutenção") ||
      bulletpoint.includes("testes") ||
      bulletpoint.includes("teste")
    ) {
      return "";
    }

    return bulletpoint.replace(/[0-9]/g, "-").replaceAll(".", " ");
  };

  const askToChat = (question: string) => {
    setProcessing(true);
    requestPost({
      method: "post",
      skipToast: true,
      body: {
        context: context?.join(""),
        question: `Levando em consideração a conversa acima, responda como posso resolver a sugestão :  ${question}.
          Me responda em formato de texto , e depois me de sugestões de como posso fazer para resolver a sugestão em 7 bulletpoints e retorne os bulletpoints dentro de um array javascript.
          Use os seguintes exemplos, retornando dentro de um objeto Javascript:
          exemplo 1:
          {"text": "Para realizar a sugestão, primeiro voce Lorem ipsum dolor sit amet, minim veniam, quis nostrud . Depois você pode uis aute irure dolor in reprehenderit in voluptate velit esse cillum",
          "bulletpoints": [
              "1. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
              "2. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
              "3. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
              "4. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
              "5. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
              "6. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
              "7. Lorem ipsum dolor sit amet, consectetur adipiscing elit"
          ]
            } 
            
            exemplo 2:
            {"text": "Para fazer Lorem ipsum dolor sit amet, faça minim veniam, quis nostrud . Depois você pode uis aute irure dolor in reprehenderit in voluptate velit esse cillum",
            "bulletpoints": [
                "1. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "2. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
            ]
              }   
          
          `,
        maxTokens: 2000,
      },
    })
      .then((response) => {
        const formatedAnswer = extractObjectFromString(response.answer);

        let answerFormatted = [
          ...conversation,
          {
            id: conversation.length,
            message:
              formatedAnswer.text + ` \n ${t("InsightModal.knowMoreMessage")}`,
            isText: true,
            renderTime: "0.5",
            print: true,
          },
          {
            id: conversation.length + 1,
            message: checkBlacklist(formatedAnswer.bulletpoints[0]),
            renderTime: "1",
            print: true,
          },
          {
            id: conversation.length + 2,
            message: checkBlacklist(formatedAnswer.bulletpoints[1]),
            renderTime: "1.5",
            print: true,
          },
          {
            id: conversation.length + 3,
            message: checkBlacklist(formatedAnswer.bulletpoints[2]),
            renderTime: "2",
            print: true,
          },
          {
            id: conversation.length + 4,
            message: checkBlacklist(formatedAnswer.bulletpoints[3]),
            renderTime: "2.50",
            print: true,
          },
          {
            id: conversation.length + 5,
            message: checkBlacklist(formatedAnswer.bulletpoints[4]),
            renderTime: "3",
            print: true,
          },
        ];

        if (isMinimumQtyOfMessage) {
          answerFormatted = [
            ...answerFormatted,
            {
              id: conversation.length,
              message:
                "Chegamos ao fim dessa experiência ! Espero que minhas respostas tenham te ajudado. Voce gostou da nossa conversa? ",
              isText: true,
              renderTime: "2",
            },
            {
              id: conversation.length + 1,
              message: "Sim",
              isRating: true,
              isPositive: true,
              renderTime: "2.25",
            },
            {
              id: conversation.length + 2,
              message: "Não",
              isRating: true,
              isPositive: false,
              renderTime: "2.5",
            },
          ];
        }

        setConversation(answerFormatted);

        setContext([
          ...context,
          ` Pergunta: Me de uma resposta detalhada de como posso ${question
            .replaceAll("-", "")
            .toLowerCase()}?, Resposta: ${
            formatedAnswer.text
          } ${formatedAnswer.bulletpoints.join(",")}`,
        ]);

        setTimeout(() => setProcessing(false), 500);
      })
      .catch((error: any) => {
        setConversation([
          ...conversation,
          {
            id: conversation.length,
            message: "Erro ao gerar respostas, pergunte novamente",
            isError: true,
            isText: true,
            renderTime: "0.5",
          },
        ]);
        setProcessing(false);
      });
  };

  const handleClickQuestion = (
    question: string,
    isRating?: boolean,
    answer?: any,
    options?: any,
  ) => {
    if (blockClick && answer?.length > 4) {
      return;
    }

    if (!processing && !isRating && !options) {
      setConversation([
        ...conversation,
        {
          id: conversation.length,
          message: question,
          isQuestion: true,
          print: true,
        },
      ]);
    } else if (isRating) {
      setProcessing(true);

      const method = answer === "Sim" ? "LIKE" : "DISLIKE";

      handleRateInsight(method);

      setConversation([
        ...conversation,
        {
          id: conversation.length,
          message: "Obrigado pela avaliação! O que deseja fazer a seguir?",
          isQuestion: false,
          isText: true,
          renderTime: "1",
        },
        {
          id: conversation.length + 1,
          message: "Reiniciar Chat",
          isQuestion: false,
          renderTime: "1.5",
          options: true,
        },
        {
          id: conversation.length + 2,
          message: "Imprimir",
          isQuestion: false,
          renderTime: "2",
          options: true,
        },
        {
          id: conversation.length + 2,
          message: "Fechar Retina Insights",
          isQuestion: false,
          renderTime: "2.5",
          options: true,
        },
      ]);

      setTimeout(() => setProcessing(false), 500);
    } else if (options) {
      if (question === "Reiniciar Chat") {
        handleCleanChat();
      } else if (question === "Imprimir") {
        setShowPDFModal(true);
      } else if (question === "Fechar Retina Insights") {
        handleClose();
      }
    }
  };

  const handleCleanChat = () => {
    const deletedConversation = conversation.filter((item: any) =>
      [0, 1, 2, 3, 4].includes(item.id),
    );

    setConversation(deletedConversation);
  };

  useEffect(() => {
    setProcessing(true);
    if (show) {
      request({ method: "get" }).then((response) => {
        setInsightsList(response);
        setProcessing(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (conversation?.[conversation?.length - 1]?.isQuestion) {
      askToChat(
        conversation?.[conversation?.length - 1]?.message
          .replace(/[0-9]/g, "")
          .toLowerCase()
          .replaceAll("-", "")
          .replaceAll(".", ""),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  //autoscrol
  useEffect(() => {
    if (processing) {
      const intervalId = setInterval(() => {
        scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
      }, 10);

      return () => {
        clearInterval(intervalId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, processing]);

  return (
    <Modal
      show={show}
      handleClose={() => handleClose(false)}
      size="lg"
      ref={ref}
      changeFotter
    >
      <>
        <Title processing={processing}>
          <span>
            <FaRegLightbulb size={22} />
          </span>
          <label>Retina Insights</label>
        </Title>
        <Container ref={scrollRef}>
          <OptionsContainer>
            {!isMobile && showOptions && (
              <>
                <Options ref={optionsRef} onClick={(e) => e.preventDefault()}>
                  {!isMobile && (
                    <NamePlusTooltip position={"top"} tooltip="Imprimir">
                      <span
                        data-tip
                        data-for="report"
                        onClick={() => setShowPDFModal(true)}
                      >
                        <CgFileDocument size={20} />
                      </span>
                    </NamePlusTooltip>
                  )}
                  <NamePlusTooltip position={"top"} tooltip="Reiniciar Chat">
                    <span
                      onClick={() => {
                        handleCleanChat();
                      }}
                      data-tip
                      data-for="delete"
                    >
                      <RxUpdate size={20} />
                    </span>
                  </NamePlusTooltip>
                  <button
                    onClick={(e: any) => {
                      e.preventDefault();
                      setShowOptions(false);
                    }}
                  >
                    <HiChevronRight size={22} />
                  </button>
                </Options>
              </>
            )}
            {isMobile && (
              <>
                <Options ref={optionsRef} onClick={(e) => e.preventDefault()}>
                  <NamePlusTooltip position={"top"} tooltip="Imprimir">
                    <>
                      <PDFDownloadLink
                        onClick={(e: any) => e.preventDefault()}
                        document={renderInsightDocument({ conversation })}
                        fileName={"Retina Insights"}
                      >
                        <span data-tip data-for="report">
                          <CgFileDocument size={20} />
                        </span>
                      </PDFDownloadLink>
                    </>
                  </NamePlusTooltip>
                  <NamePlusTooltip position={"top"} tooltip="Reiniciar Chat">
                    <span
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleCleanChat();
                      }}
                      data-tip
                      data-for="delete"
                    >
                      <RxUpdate size={20} />
                    </span>
                  </NamePlusTooltip>
                </Options>
              </>
            )}

            {!isMobile && (
              <Button
                onClick={(e: any) => {
                  e.preventDefault();
                  setShowOptions(true);
                }}
              >
                <BiDotsVerticalRounded size={25} />
              </Button>
            )}

            <Button
              onClick={(e) => {
                e.preventDefault();
                handleClose(false);
              }}
            >
              <IoMdClose size={25} />
            </Button>
          </OptionsContainer>
          <MotherQuestion>
            {`Seu equipamento ${assetType} foi diagnosticado com ${diagnostic}. Abaixo preparamos algumas sugestões para te ajudar a solucionar o problema:`}
          </MotherQuestion>
          <Conversation>
            {conversation
              ?.filter((item: any) => item.message)
              .map((item: any, index: number) =>
                !item.isQuestion ? (
                  <MessageContainer key={index}>
                    <Message
                      onClick={(e: any) => {
                        e.preventDefault();
                        if (!item.isQuestion && !item.isText) {
                          handleClickQuestion(
                            item.message,
                            item.isRating,
                            item.message,
                            item.options,
                          );
                        }
                      }}
                      isText={item.isText}
                      time={`${item.renderTime}s`}
                      isError={item.isError}
                      isRating={item.isRating}
                      isPositiveRating={item.isPositive}
                      blockClick={blockClick}
                      isQuestion={item.isQuestion}
                    >
                      <label>{item.message}</label>
                      {item.isRating && item.isPositive && (
                        <span>
                          <MdThumbUp size={22} />
                        </span>
                      )}
                      {item.isRating && !item.isPositive && (
                        <span>
                          <MdThumbDown size={22} />
                        </span>
                      )}
                    </Message>
                  </MessageContainer>
                ) : (
                  <MessageContainer isQuestion={true}>
                    <Message isQuestion={item.isQuestion}>
                      <label>Me dê mais detalhes sobre:</label>

                      <label>{item.message}</label>
                    </Message>
                  </MessageContainer>
                ),
              )}
            {processing ? (
              <DotsAnimation>
                <div></div>
                <div></div>
                <div></div>
              </DotsAnimation>
            ) : null}
          </Conversation>
        </Container>
        {showPDFModal && (
          <ModalPrintChat
            show={showPDFModal}
            handleClose={(e: any) => {
              e.preventDefault();
              setShowPDFModal(false);
            }}
            conversation={[
              ...conversation,
              {
                id: -1,
                message: `Seu equipamento ${assetType} foi diagnosticado com ${diagnostic}. Abaixo preparamos algumas sugestões para te ajudar a solucionar o problema:`,
                isText: true,
                print: true,
              },
            ]
              .sort((a, b) => a.id - b.id)
              .filter((item: any) => item.print)}
            insights
          />
        )}
      </>
    </Modal>
  );
};
