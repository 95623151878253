import styled, { css } from "styled-components";

export const Containers = styled.div<{ ref: any; isNotAnalysis?: any }>`
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: ${({ theme }) => theme.colors.divBackground};
  ${({ theme }) => theme.fonts.primary.H3SemiBold};
  border-radius: 6px;
  padding: 12px 12px;

  ${({ isNotAnalysis }) =>
    isNotAnalysis &&
    css`
      border: 1px solid ${(props) => props.theme.colors.cardHighlight};
      &:hover {
        border: 1px solid ${(props) => props.theme.colors.cardBackground};
      }
    `}

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }
`;

export const ContainerChart = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LuminaStatusBulbContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  a,
  button {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 32px;
    height: 32px;
  }

  button {
    right: 40px;
  }
`;

export const StatusLabel = styled.span<{ color: string }>`
  position: absolute;
  bottom: 10px;
  left: 10px;
  ${(props) => props.theme.fonts.primary.H1Semibold};
  color: ${(props) => props.color};
`;

export const StatusIcon = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 150px;
    color: ${(props) => props.color};
  }
`;

export const ContainerTopLumina = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  & > div {
    display: flex;
    gap: 0.5rem;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    grid-template-columns: 2fr 2fr 1fr;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    height: 4rem;
    justify-content: space-between;

    & > div:nth-child(1) {
      width: 65%;
      height: 1.8rem;
      text-align: left;
      padding: 0 0 0 0.3rem !important;
      svg {
        font-size: 10px !important;
      }
    }
    & > div:nth-child(2) {
      width: 100%;
      height: 1.8rem;
      padding: 0 !important;
      margin-top: 6px;

      & > button:nth-child(2) {
        position: absolute;
        top: 0px;
        right: 48px;
        max-height: 24px;
      }

      & > button:last-of-type {
        position: absolute;
        top: 0px;
        right: 0px !important;
        max-height: 24px;
      }
    }
  }
`;

export const ContainerLuminaStatusTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  color: ${(props) => props.theme.colors.iconsSecondary};

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    svg {
      width: 1.5rem;
      height: auto;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 4px;
    padding-left: 6% !important;

    svg {
      width: 1.6rem;
      height: auto;
    }

    padding-left: 0px;
    div &:nth-child(1) {
    }
    //ORDENAÇÃO da div dos botões de seleção de período (dia, semana, mês e ano)
    div &:nth-child(2) {
      width: 100%;
      order: 3;
      z-index: 1;
    }
    div &:nth-child(3) {
    }
  }
`;

export const ContainerLuminaStatusActions = styled(ContainerLuminaStatusTitle)`
  justify-content: flex-end;
`;
