import styled from "styled-components";

export const Container = styled.div<{ error: boolean }>`
  padding: 2rem 2rem 0 2rem;
  width: 100%;
  height: 100%;

  & > div:last-child {
    display: flex;
    flex-direction: column;

    input {
      ${(props) => props.theme.fonts.primary.H4Regular};
      width: 100%;
      min-height: 34px;
      color: #314d4a;
      background: #d1d9b2;
      border-radius: 4px;
      padding: 8px;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid ${(props) => props.theme.colors.IconsTools};
      }

      &:focus-visible {
        outline: none;
        border: 1px solid ${(props) => props.theme.colors.IconsTools};
      }
    }

    & > label:first-child {
      ${(props) => props.theme.fonts.primary.H5Regular};
      font-weight: 800;
      margin-bottom: 4px;
      color: #314d4a;
    }

    & > label:last-child {
      ${(props) => props.theme.fonts.primary.H5Regular};
      font-weight: 800;
      margin-top: 4px;
      color: red;
    }
  }

  .no-spinners::-webkit-outer-spin-button,
  .no-spinners::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .no-spinners {
    -moz-appearance: textfield;
  }
`;

export const Welcome = styled.div`
  margin-bottom: 2rem;

  & > label:first-child {
    ${(props) => props.theme.fonts.primary.H1Semibold};
    margin-bottom: 20px;
    color: #314d4a;
  }
  & > label:last-child {
    ${(props) => props.theme.fonts.primary.H2Bold};
    margin-bottom: 20px;
    color: #314d4a;
  }
`;
