import { Modal as RBModal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ConfirmModal } from "../../modals/ConfirmModal";
import { ButtonCancel, ButtonSave, ModalFrame } from "./styles";
import { ModalProps } from "./types";
import { useTranslation } from "react-i18next";
// import 'bootstrap/dist/css/bootstrap.min.css';

export function Modal({
  show,
  handleClose,
  hideSaveButton,
  hideCancelButton,
  handleConfirm,
  confirmLabel,
  cancelLabel,
  children,
  height,
  ajustes,
  borda,
  circular,
  color,
  changeFotter,
  confirmCancel,
  disabled,
  backgroundColor,
  borderColor,
  fontColor,
  confirmModalText,
  ...rest
}: ModalProps) {
  const location = useLocation();
  const { pathname } = location;

  const isEsgPanel = pathname.includes("esgPanel");

  const { t } = useTranslation();

  return (
    <>
      <RBModal
        style={{ zIndex: "10000", padding: 0, overflow: "hidden" }}
        show={show}
        onHide={handleClose}
        centered
        contentClassName="border-0"
        dialogClassName="modal-100w"
        {...rest}
      >
        <ModalFrame
          className={`${circular} border-${borda || 1} ${color}`}
          height={height}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
        >
          {/* <RBModal.Header style={{border: 'none',}}>
            <RBModal.Title></RBModal.Title>
          </RBModal.Header> */}

          <RBModal.Body className={`border-0 ${ajustes}`}>
            {children}
          </RBModal.Body>

          {!changeFotter && (
            <RBModal.Footer className="border-0">
              {!hideCancelButton && confirmCancel && (
                <ConfirmModal
                  onConfirm={handleClose}
                  text={
                    confirmModalText
                      ? confirmModalText
                      : t("commonText.wishToCancel")
                  }
                  isEsgPanel={isEsgPanel}
                />
              )}
              {!hideCancelButton && !confirmCancel && (
                <ButtonCancel
                  type="button"
                  onClick={handleClose}
                  isEsgPanel={isEsgPanel}
                  id="cancel"
                >
                  {cancelLabel || t("commonText.cancel")}
                </ButtonCancel>
              )}
              {!hideSaveButton && (
                <ButtonSave
                  type="button"
                  disabled={!!disabled}
                  onClick={handleConfirm}
                >
                  {confirmLabel || t("commonText.save")}
                </ButtonSave>
              )}
            </RBModal.Footer>
          )}
        </ModalFrame>
      </RBModal>
    </>
  );
}
