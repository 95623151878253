import { useTranslation } from "react-i18next";
import { GetButton } from "./styles";

interface IConfirmButton {
  handleConfirm: () => void;
  changeToConfirm?: boolean;
  type?: "submit" | "button";
}

export function ConfirmButton({
  handleConfirm,
  changeToConfirm,
  type = "submit",
}: IConfirmButton) {
  const { t } = useTranslation();
  return (
    <GetButton type={type} onClick={handleConfirm}>
      <span>{t("commonText.save")}</span>
    </GetButton>
  );
}
