import styled, { css } from "styled-components";

export const OccurrencePanelHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: fit-content;
  gap: 1rem;
  padding-left: 2.5rem;

  & > div:first-child {
    display: flex;
    align-items: center;
    gap: 1rem;

    & > span {
      ${(props) => props.theme.fonts.primary.H1Regular};
      font-size: 1.2rem;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0 !important;
    gap: 0;

    span {
      width: 100%;
      text-align: center;
    }
  }

  @media screen and (max-width: 1536px) {
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    & > span {
      font-size: 1rem;
    }
  }
`;

export const CategoryContainer = styled.div<{
  categoryType?: "SENSITIVE_INSPECTION" | "MANUAL_COLLECT" | null;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 0.35rem 0.65rem;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background-color: ${({ categoryType, theme }) =>
    categoryType === "SENSITIVE_INSPECTION"
      ? theme.colors.sentitiveInspection
      : theme.colors.manualCollect};

  span {
    ${(props) => props.theme.fonts.secondary.H1SemiBold};
  }
`;

export const MenuButton = styled.button<{ openMenu?: boolean }>`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme, openMenu }) =>
    openMenu ? theme.colors.headerCardSecondary : "transparent"};
  border: none;
  position: relative;
  color: ${(props) => props.theme.colors.iconsPrimary};
  transition: all 0.3s ease;
  &:hover {
    background-color: ${(props) => props.theme.colors.headerCardSecondary};

    & > svg {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 767px) {
    z-index: 12;
  }
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  right: 2.9rem;
  top: 23px;
  border-left: 6px solid ${(props) => props.theme.colors.headerCardSecondary};
  position: absolute;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const MenuContaner = styled.div<{ isMobile?: boolean }>`
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background-color: ${(props) => props.theme.colors.cardBackground};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  top: 1rem;
  right: 3.25rem;
  z-index: 3;
  border-radius: 6px;
  padding: 0.3rem;

  @media screen and (max-width: 767px) {
    width: 65%;
    height: 100vh;
    top: 0;
    right: 0;
    border-radius: 6px 0 6px;
    justify-content: flex-start;
    padding: 1rem;
    border-right: 1px solid transparent;
    z-index: 11;
  }
`;

export const MenuItem = styled.button<{ disable?: boolean }>`
  border: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 0.5rem;
  color: ${(props) => props.theme.colors.iconsSecondary};
  background-color: transparent;
  pointer-events: ${({ disable }) => (disable ? "none" : "")};
  opacity: ${({ disable }) => (disable ? "0.4" : "1")};

  & > span {
    white-space: nowrap;
  }

  border: 1px solid transparent;
  ${(props) => props.theme.fonts.primary.H4Regular};
  transition: filter 0.3s ease;
  & > svg {
    width: 1rem;
    height: auto;
  }

  &:hover {
    /* background-color: ${(props) =>
      props.theme.colors.headerCardSecondary}; */
    filter: brightness(1.1);
    font-weight: 600;
    border-radius: 6px;
  }

  @media screen and (max-width: 767px) {
    align-items: flex-start !important;
    ${(props) => props.theme.fonts.primary.H1Regular};
    font-size: 1rem;
    height: 3rem;

    span {
      text-align: left;
    }

    &:hover {
      background-color: transparent;

      &:active {
        background-color: ${(props) => props.theme.colors.headerCardSecondary};
      }
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    font-size: 12px;
  }
`;

export const MenuItemsContainer = styled.div`
  display: flex;
  width: fit-content;
  justify-content: flex-end;
  gap: 1rem;
  margin-right: 1rem;

  & > button {
    padding: 0.5rem 0;
    &:hover {
      background-color: transparent;
      border-radius: 0 !important;
      filter: brightness(1.1);
      font-weight: 600;
      border-radius: 6px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
        animation: slideIn 0.3s ease-in-out;
      }
    }
  }

  @keyframes slideIn {
    from {
      transform: scaleX(0);
      transform-origin: left;
    }
    to {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
  }
`;

export const MenuMobile = styled.div``;

export const MenuMobileBackground = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 0.6;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
`;

export const CriticalityButton = styled.button<{ disabled?: boolean }>`
  background: transparent;
  border: none;
  border-radius: 100%;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  :hover {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: normal;
        background: none;
      `}
  }
`;
