import { t } from "i18next";
import i18n from "../../i18n";
import { BlackBoxTerms, TermsParagraph, TermsTitle } from "./styles";
import { TextTermsAndPoliciesPT } from "./TranslatedTerms/indexPt";
import { TextTermsAndPoliciesES } from "./TranslatedTerms/indexES";
import { TextTermsAndPoliciesEN } from "./TranslatedTerms/indexEN";

export function TextTermsAndPolicies() {
  return (
    <>
      <TermsTitle>{t("Terms.Termos de serviço")}</TermsTitle>

      <BlackBoxTerms>
        <TermsParagraph>
          {i18n.resolvedLanguage === "pt" ? (
            <TextTermsAndPoliciesPT />
          ) : i18n.resolvedLanguage === "en" ? (
            <TextTermsAndPoliciesEN />
          ) : (
            <TextTermsAndPoliciesES />
          )}
        </TermsParagraph>
      </BlackBoxTerms>
    </>
  );
}
