import { isMobile, isTablet } from "react-device-detect";
import QrReader from "react-web-qr-reader";
import { ContainerScanner, SwitchCam } from "./styles";
import { MdCameraswitch, MdOutlineCameraswitch } from "react-icons/md";
import { useState } from "react";
import { isTabletOnly } from "../../utils/mediaQuery";

interface IQRCodeReader {
  handleError: (error: any) => void;
  handleScan: (result: any) => void;
  delay: number;
  setCameraDenied?: () => void;
}

export function QRCodeReader({
  handleScan,
  delay,
  handleError,
}: IQRCodeReader) {
  const [facingMode, setFacingMode] = useState<"user" | "environment">(
    isMobile ? "environment" : "user",
  );

  const handleCameraChange = () => {
    setFacingMode((prevCam) => (prevCam === "user" ? "environment" : "user"));
  };
  return (
    <ContainerScanner>
      <div>
        <div>
          {(isMobile || isTabletOnly()) && (
            <SwitchCam onClick={handleCameraChange}>
              {facingMode === "user" ? (
                <MdOutlineCameraswitch />
              ) : (
                <MdCameraswitch />
              )}
            </SwitchCam>
          )}
          <QrReader
            delay={delay}
            onError={handleError}
            onScan={handleScan}
            facingMode={facingMode}
          />
        </div>
      </div>
    </ContainerScanner>
  );
}
