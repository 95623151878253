import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { appSelect, setAppDiagnostics } from "../store/slices/appSlice";
import useApi from "./useApi";

export default function useApp() {
  const dispatch = useAppDispatch();
  const { diagnostics } = useAppSelector(appSelect);
  const { request: fetchDiagnostics } = useApi({ path: "/diagnostics" });

  const loadDiagnostics = useCallback(() => {
    if (!diagnostics.length) {
      fetchDiagnostics({ method: "get" }).then((response) => {
        dispatch(setAppDiagnostics(response));
      });
    }
    // eslint-disable-next-line
  }, [diagnostics]);

  return { loadDiagnostics };
}
