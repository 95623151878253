import { t } from "i18next";
import { occurrenceListModel } from "../OccurrenceList/types";

export type OccurrenceListItemProps = {
  occurrence: occurrenceListModel;
  refresh: () => void;
  refreshSidebar: any;
};

export const getStatusLabel = (sysStatusId: 2 | 3 | 4 | 5 | null) => {
  switch (sysStatusId) {
    case 2:
    case 3:
      return "Alerta";
    case 4:
    case 5:
      return "Risco";
    case null:
      return "Avaria";
    default:
      return "Indefinido";
  }
};

export function getActionLabel(action: string) {
  switch (action) {
    case "delete":
      return "excluída";
    case "close":
      return "fechada";
    case "reopen":
      return "reaberta";
    default:
      return "alterada";
  }
}

export const getActionTime = (dueDate: string | null) => {
  if (!dueDate) {
    return t("occurrencesTab.occurrenceListItem.unassigned");
  }

  const dayMs = 86400000;
  const hoursMs = 3600000;
  const difDateEpoch = new Date(dueDate).getTime() - new Date().getTime();
  var label = t("commonText.day");
  var value = 0;
  if (difDateEpoch < dayMs && difDateEpoch > dayMs * -1) {
    // const getDate =Math.floor(difDateEpoch / hoursMs)
    label = "hora";
    value = Math.floor(difDateEpoch / hoursMs);
  } else {
    value = Math.floor(difDateEpoch / dayMs);
  }

  if (value !== 1) {
    label += "s";
  }

  return `${value} ${label}`;
};
