import { SelectorContainer, Serie, Color } from "./styles";
import { SeriesSelectorProps } from "./types";
import { NamePlusTooltip } from "../../NamePlusTooltip";
import useSeriesControl from "../../../hooks/useSeriesControl";

export const SeriesSelector = ({
  seriesControl,
  handleSeries,
  chart,
  getTooltipDescription,
}: SeriesSelectorProps) => {
  const { getSerieLabel } = useSeriesControl();
  return (
    <SelectorContainer>
      {seriesControl
        .filter((serie: any) => !serie.hidden)
        .map((serie, index) => (
          <NamePlusTooltip
            key={index}
            tooltip={getTooltipDescription && getTooltipDescription(serie.name)}
            position="top"
          >
            <Serie
              key={index}
              disabled={!handleSeries}
              onClick={() => {
                handleSeries && handleSeries(index);
              }}
            >
              <Color color={serie.color} toggle={serie.visible} />
              <span> {getSerieLabel(serie.name, chart)}</span>
            </Serie>
          </NamePlusTooltip>
        ))}
    </SelectorContainer>
  );
};
