import styled from "styled-components";

export const ReactTableEmptyBody= styled.div`
  width: inherit;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  height: 160px;

  & > div {
    width: 100%;
    background-color: transparent;
  }
`