import { useTheme } from "styled-components";
import { useState } from "react";
import useTranslator from "./useTranslator";
import { IChart } from "../store/interfaces";

function getDefaultIndex(
  serie: string,
  defaultSeries: string[],
  index: number,
) {
  for (let defaultIndex in defaultSeries) {
    if (defaultSeries[defaultIndex] === serie) {
      return defaultIndex;
    }
  }

  return index;
}

export interface ISerieControl {
  name: string;
  visible: boolean;
  color: string;
  alarm: boolean;
  hidden: boolean;
  index: number;
}

interface useSeriesControlArgs {
  customGetColor?: any;
}

export default function useSeriesControl(args?: useSeriesControlArgs) {
  const { customGetColor } = args || {};
  const theme = useTheme();
  const [seriesControl, setSeriesControl] = useState<ISerieControl[]>([]);
  const translator = useTranslator("chartSeries");

  function handleSeries(lineIndex: number) {
    var newSeriesControl = seriesControl.map((serie, index) => {
      if (index === lineIndex) {
        serie.visible = !serie.visible;
      }
      return serie;
    });
    setSeriesControl(newSeriesControl);
  }

  const defaultGetSerieColor = (
    name: string,
    index: number,
    defaultSeries?: string[],
  ) => {
    const { colors } = theme;
    const COLOR_SERIES: { [key: string]: string } = {
      0: colors.statusNormal,
      1: colors.statusAlert,
      2: colors.statusRisk,
    };
    if (
      name === "Alerta" ||
      name === "Alerta Superior" ||
      name === "Alerta Inferior" ||
      name === "Limite"
    ) {
      return colors.alarmAlerta;
    } else if (
      name === "Risco" ||
      name === "Risco Superior" ||
      name === "Risco Inferior" ||
      name === "Extremo"
    ) {
      return colors.alarmRisco;
    } else {
      if (defaultSeries) {
        var defaultIndex = getDefaultIndex(name, defaultSeries, index);
        return COLOR_SERIES[defaultIndex];
      }
      return COLOR_SERIES[index];
    }
  };

  const getSerieColor = customGetColor || defaultGetSerieColor;

  function buildSeriesControl(response: any, analysis?: any) {
    if (!response?.series?.length) {
      setSeriesControl([]);
      return [];
    }

    const series = response.series.slice(1).map((name: string, i: number) => {
      const hiddenAxis = response?.hiddenAxes?.[name];

      const result = {
        name: translator(name),
        visible: !hiddenAxis,
        hidden: !!hiddenAxis,
        color: getSerieColor(name, i, response.defaultSeries),
        alarm: [
          "Alerta",
          "Risco",
          "Risco Superior",
          "Risco Inferior",
          "Alerta Superior",
          "Alerta Inferior",
          "Limite",
          "Extremo",
        ].includes(name),
        maxValue: response?.maxValuePerSeries?.[name],
        index: 0,
      };

      if (analysis?.temperatureCorrelation && name === "1") {
        result.index = 1;
        result.color = theme.colors.ibbxOrange;
        result.name = translator("Temperatura");
      }

      if (response.multipleAxis) {
        result.index = i;
      }

      return result;
    });

    setSeriesControl(series);
    return series;
  }

  function getSerieLabel(serieName: string, chart: IChart) {
    if (["Alerta", "Risco"].includes(serieName)) {
      return serieName;
    }

    const chartSeriesString = (chart.series || []).map((serie) =>
      serie.toString(),
    );

    let seriesName = chart?.seriesName || [];
    if (!seriesName.length) {
      seriesName = chart?.series || [];
    }

    const indexSerie = chartSeriesString.indexOf(serieName);
    const label: any = seriesName[indexSerie];

    if (!label) {
      return serieName;
    }

    if (label?.toString() === "1") {
      return "Amplitude";
    }

    return translator(label);
  }

  return { seriesControl, buildSeriesControl, handleSeries, getSerieLabel };
}
