import styled, { css } from "styled-components";

export const ProfileButton = styled.div<{
  active?: boolean;
  navbarColor?: string;
}>`
  color: ${(props) => props.theme.colors.ibbxOrange};
  background: ${(props) => {
    if (props.active && !props.navbarColor) {
      return props.theme.colors.cardBackground;
    }
    if (props.active && props.navbarColor) {
      return props.navbarColor;
    }
    return "transparent";
  }};

  ${(props) =>
    props.navbarColor &&
    css`
      filter: brightness(0.9);
    `}

  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.colors.ibbxOrange}!important;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    z-index: 103;
    position: relative;
  }

  @media screen and (max-width: 767px) {
    z-index: 100;
    position: relative;
  }
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 6px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  right: 13px;
  top: 42px;
  position: absolute;
`;

export const DarkmodeButton = styled.div`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  /* width: 54px; */
  height: 26px;
  border-radius: 20px;

  /* &:hover {
    background-color: ${(props) => props.theme.colors.cardBackground};
  } */
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    display: flex;
    align-items: center;
    justify-content: center;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }
`;

export const DarkmodeArea = styled.div`
  width: 100%;
  height: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    ${(props) => ({ ...props.theme.fonts.primary.H5Bold })};
    color: ${(props) => props.theme.colors.iconsPrimary};
    margin-bottom: 0 !important;
    background-color: transparent;
    border: none;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 50%;
    height: 60px;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 70%;
    padding: 1.375rem 0.625rem;

    button {
      ${(props) => ({ ...props.theme.fonts.primary.H2Bold })};
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1280px) {
    padding: 0 10px;
    height: auto;
    & > div {
      label {
        white-space: nowrap;
      }
    }
  }
`;

export const MenuItem = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  margin: 8px 0;

  color: ${(props) => props.theme.colors.iconsPrimary};
  text-decoration: none;
  display: block;
  position: relative;

  &:hover {
    background-color: ${(props) => props.theme.colors.cardBackground};
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    width: 50%;
  }

  @media screen and (max-width: 767px) {
    width: 70%;
  }
`;

export const MenuSep = styled.div`
  width: 100%;
  border-bottom: 2px solid ${(props) => props.theme.colors.cardHighlight};
  margin-bottom: 18px;

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    width: 50%;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 767px) {
    width: 70%;
  }
`;

export const Container = styled.div`
  width: 208px;
  position: absolute;
  right: 0px;
  top: 47px;
  background-color: ${(props) => props.theme.colors.headerBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  text-align: left;
  ${(props) => ({ ...props.theme.fonts.primary.H5Bold })};
  color: ${(props) => props.theme.colors.iconsPrimary};

  padding: 16px;

  p {
    margin: 0;
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  z-index: 200;

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    border: none;
    background: ${(props) => props.theme.colors.headerBackground};
    right: 0px;
    top: -10px;
    border-radius: 0;
    width: 100vw !important;
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
    ${(props) => ({ ...props.theme.fonts.primary.H2Bold })};
    overflow: hidden;
  }

  @media screen and (max-width: 767px) {
    z-index: 99;
    border: none;
    background: ${(props) => props.theme.colors.headerBackground};
    right: 0px;
    bottom: -8px;
    border-radius: 0;
    width: 100vw !important;
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    ${(props) => ({ ...props.theme.fonts.primary.H2Bold })};
    overflow: hidden;
    margin-right: -12px;
    padding-top: 6rem;
    border-top: 1px solid ${(props) => props.theme.colors.passwordBackground};
  }

  @media screen and (min-width: 767px) and (max-width: 1280px) {
    width: 208px !important;
    height: auto !important;

    position: absolute;
    right: 0px;
    top: 47px;
    background-color: ${(props) => props.theme.colors.headerBackground};
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    text-align: left;
    ${(props) => ({ ...props.theme.fonts.primary.H5Bold })};
    color: ${(props) => props.theme.colors.iconsPrimary};

    padding: 16px;

    p {
      margin: 0;
      margin-bottom: 16px;
    }

    z-index: 200;

    div,
    a {
      width: 100%;
    }
  }
`;

export const LogoutQuestion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.iconsPrimary};
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    font-size: 1rem;
  }

  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
`;

export const LanguageContainer = styled(DarkmodeArea)`
  gap: 1rem;
  justify-content: flex-start;
  height: auto;
  padding: 0;
  cursor: pointer;
  position: relative;
  span {
    min-width: 4rem;
  }

  label {
    width: 4rem;
  }
  &:hover {
    button {
      scale: calc(1.05);
    }
  }

  @media screen and (max-width: 767px) {
    margin: 0;
    gap: 2rem;

    label {
      width: 6rem;
    }
  }
`;

export const LanguageButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto !important;
  padding: 1px !important;
  position: absolute;
  right: 2.6rem;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 16px;
  border: none;
  background-color: ${(props) => props.theme.colors.inputBlock};
  svg {
    height: 26px;
    width: auto;
  }

  span {
    margin-right: 8px;
  }

  transition: all 0.2s;
`;
