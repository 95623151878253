import { useEffect, useMemo, useState } from "react";
import { Document, PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { isMobile } from "react-device-detect";
import QRCode from "react-qr-code";

import { Modal } from "../../Modal";
import { Loading } from "../../Loading";

import { Container, DownloadPDF, LoadingContainer } from "./styles";

import { useTranslation } from "react-i18next";
import { PDFOccurrenceReport } from "../PDFOccurrenceReport";
import useApi from "../../../hooks/useApi";
import { PROFILES, userSelect } from "../../../store/slices/userSlice";
import { useAppSelector } from "../../../store/hooks";
import { useOccurrenceHistory } from "../../../hooks/useOccurrenceHistory";
import { appSelect } from "../../../store/slices/appSlice";

interface IModalReport {
  assetId?: number;
  occurrenceId?: number;
  occurrence?: any;
  evidences?: any;
  show: boolean;
  handleClose: () => void;
  allOccurrencId?: any[];
}

export const ModalOccurrenceReport = ({
  show,
  assetId,
  occurrenceId,
  handleClose,
  occurrence: occurrenceProps,
  evidences: evidencesProps,
}: IModalReport) => {
  const user = useAppSelector(userSelect);
  const { diagnostics } = useAppSelector(appSelect);

  const [occurrenceState, setOccurrenceState] = useState<any>(null);
  const [evidencesState, setEvidencesState] = useState<any>(null);

  const { request } = useApi({
    path: `/assets/${assetId}/occurrences/${occurrenceId}`,
  });

  const { getHistory } = useOccurrenceHistory(user.isClient);

  const occurrence = useMemo(() => {
    if (occurrenceProps) {
      return occurrenceProps;
    }

    return occurrenceState;
    // eslint-disable-next-line
  }, [occurrenceState]);

  const history: any[] = useMemo(() => {
    return getHistory(occurrence);
    // eslint-disable-next-line
  }, [occurrence?.history]);

  function loadOccurrence() {
    if (!occurrenceProps) {
      request({ method: "get" }).then(setOccurrenceState);
    }
  }

  function loadEvidences() {
    if (!evidencesProps) {
      request({ method: "get", pathParameters: "evidences" }).then(
        setEvidencesState,
      );
    }
  }

  const userClient = [
    PROFILES.CLIENTE_COMUM,
    PROFILES.CLIENTE_MASTER,
    PROFILES.CLIENTE_MASTER_VIEW,
  ].includes(user.profile);

  useEffect(() => {
    Promise.all([loadOccurrence(), loadEvidences()]);
    //eslint-disable-next-line
  }, []);

  const evidences = useMemo(() => {
    if (evidencesProps) {
      return evidencesProps;
    }

    return evidencesState;
    // eslint-disable-next-line
  }, [evidencesState]);

  const { t } = useTranslation();

  function renderDocument() {
    return (
      <Document>
        <PDFOccurrenceReport
          occurrence={occurrence}
          evidences={evidences}
          userClient={userClient}
          diagnostics={diagnostics}
          history={history}
        />
      </Document>
    );
  }

  function renderContent() {
    if (!occurrence || !evidences) {
      return (
        <>
          <LoadingContainer>
            <Loading />
            <span>{t("modalReport.searchingOccurrenceInfo")}</span>
          </LoadingContainer>
        </>
      );
    }

    if (isMobile) {
      return (
        <PDFDownloadLink
          document={renderDocument()}
          fileName={
            occurrence?.companyName +
            " - " +
            occurrence?.facilityName +
            ` - ${t("modalReport.occurrence")} ` +
            occurrence?.assetId
          }
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              `${t("modalReport.loadingPDF")}`
            ) : (
              <DownloadPDF>
                <button>
                  <span>{`${t("modalReport.downloadPDF")}`}</span>
                </button>
              </DownloadPDF>
            )
          }
        </PDFDownloadLink>
      );
    }

    return (
      <PDFViewer
        className="pdf-viewer"
        style={{ width: "100%", height: "600px" }}
      >
        {occurrence && renderDocument()}
      </PDFViewer>
    );
  }

  return (
    <>
      {occurrence && (
        <div style={{ display: "none" }}>
          <QRCode
            id="QRCode"
            value={`${process.env.REACT_APP_URL_HOST}/companies/${occurrence.companyId}/facilities/${occurrence.facilityId}/assets/${occurrence.assetId}`}
          />
        </div>
      )}
      <Modal
        show={show}
        size="lg"
        handleClose={handleClose}
        hideCancelButton
        handleConfirm={handleClose}
        confirmLabel={`${t("commonText.close")}`}
      >
        <Container>{renderContent()}</Container>
      </Modal>
    </>
  );
};
