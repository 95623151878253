import styled from "styled-components";

export const Container = styled.div`
  color: ${(props) => props.theme.colors.iconsPrimary};
  display: flex;
`;

export const EmptyReport = styled.div`
  ${(props) => props.theme.fonts.primary.H4Regular};
  color: ${(props) => props.theme.colors.iconsPrimary};
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;