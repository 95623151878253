import {
  Page,
  Text,
  Image as ImagePDF,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import theme from "../../../styles/themes/default";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/utilsFunctions";
import { useCallback, useMemo } from "react";
import i18n from "../../../i18n";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  sectionTitle: {
    fontWeight: "bold",
    fontSize: 12,
    marginBottom: 3,
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    borderTop: "2px solid black",
    borderBottom: "2px solid black",
    marginBottom: 10,
  },
  sectionItemContainer: {
    display: "flex",
    width: "25%",
    height: "30px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  sectionItemTitle: {
    paddingLeft: 5,
    paddingVertical: 2,
    fontWeight: 400,
    fontSize: 8,
    border: "1px solid gray",
  },
  sectionPrimaryTitle: {
    paddingLeft: 5,
    paddingVertical: 2,
    fontWeight: 400,
    fontSize: 6,
  },
  sectionSecondatyTitle: {
    paddingLeft: 5,
    paddingVertical: 2,
    fontWeight: 400,
    fontSize: 8,
  },
  sectionItemText: {
    fontWeight: 400,
    fontSize: 10,
    paddingVertical: 4,
    textAlign: "center",
    border: "1px solid gray",
    color: "#555",
  },
  titleSecondary: {
    fontWeight: 600,
    fontSize: 10,
    paddingVertical: 4,
    textAlign: "left",
    color: "#1c1a1a",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
interface IPDFOccurrenceReport {
  occurrence: any;
  evidences: any[];
  userClient: any;
  diagnostics: any[];
  history: any;
}

export const PDFOccurrenceReport = ({
  occurrence,
  evidences,
  diagnostics,
  history,
}: IPDFOccurrenceReport) => {
  const { t } = useTranslation();

  const lang = useMemo(() => {
    return i18n.language;
    // eslint-disable-next-line
  }, [i18n, i18n.language]);

  const translateDiagnostic = useCallback(
    (name: any) => {
      const diagnostic: any = diagnostics.find(
        (item: any) => item.name === name,
      );
      if (diagnostic) {
        return diagnostic?.translation?.[lang] || diagnostic.name;
      }
      return t("occurrenceAssetListItem.Anomalia");
    },
    // eslint-disable-next-line
    [diagnostics, lang],
  );

  const closedComment = useMemo(() => {
    if (occurrence?.closedAt) {
      return (
        occurrence?.history?.find(
          ({ type }: any) => type === "CLOSE_OCCURRENCE",
        )?.comments ?? "-"
      );
    }

    return "-";
  }, [occurrence]);

  const displayableEvidences = useMemo(() => {
    return (evidences ?? [])?.filter(
      (item: any) => item && item?.type !== "PDF",
    );
    // eslint-disable-next-line
  }, []);

  function getStatusColor(sysStatusId: number, type: string) {
    if (type === "CORRECTIVE") {
      return theme.colors.statusBroken;
    }

    switch (sysStatusId) {
      case 7:
        return "#CE3878";
      case 6:
        return "#CE3878";
      case 5:
        return "#CE3878";
      case 4:
        return "#F37035";
      case 3:
      case 2:
        return "#DEA40F";
      default:
        return "#01D5BF";
    }
  }

  function getStatusCaption(sysStatusId: number, type: string) {
    if (type === "CORRECTIVE") {
      return `${t("dictionary.status.AVARIA")}`;
    }

    switch (sysStatusId) {
      case 7:
        return `${t("dictionary.status.CRITICAL")}`;
      case 6:
        return `${t("dictionary.status.RISCO")}`;
      case 5:
        return `${t("dictionary.status.RISCO")}`;
      case 4:
        return `${t("dictionary.status.ANÁLISE")}`;
      case 3:
      case 2:
        return `${t("dictionary.status.ALERTA")}`;
      default:
        return `${t("dictionary.status.NORMAL")}`;
    }
  }

  function getCommentType(type: string) {
    switch (type) {
      case "REOPEN":
        return "REABERTURA";
      case "EDITION":
        return "EDIÇÃO";
      case "CLOSE":
        return "FECHAMENTO";
    }
  }

  return (
    <>
      <Page style={styles.body}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          fixed
        >
          {occurrence?.logoUrl && (
            <ImagePDF
              style={{
                width: 90,
                position: "absolute",
                height: "40px",
                top: -15,
                left: 0,
              }}
              src={`${occurrence.logoUrl}?a=${new Date().getTime()}`}
              fixed
            />
          )}
          <Text
            style={{
              fontSize: 14,
              fontWeight: 700,
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "96%",
              paddingLeft: "37%",
            }}
          >
            {t("modalReport.PDFOccurrenceReport.title")}
            {occurrence.id}
          </Text>

          <View style={{ display: "flex", flexDirection: "column" }}>
            <Text
              style={{
                fontWeight: 400,
                fontSize: 10,
                color: "gray",
                alignContent: "flex-end",
              }}
            >
              {t("modalReport.PDFReport.emission")}
            </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: 10,
                color: "gray",
                alignContent: "flex-end",
                paddingRight: "15px",
              }}
            >
              {new Intl.DateTimeFormat("pt-BR", {
                dateStyle: "short",
                timeStyle: "medium",
              }).format(new Date())}
            </Text>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderTop: "2px solid black",
          }}
        >
          <View
            style={{
              display: "flex",
              width: "50%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Text style={styles.sectionItemTitle}>
              {t("commonText.company")}: {occurrence?.companyName}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              width: "50%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Text style={styles.sectionItemTitle}>
              {t("commonText.facility")}: {occurrence?.facilityName}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "2px solid black",
          }}
        >
          <View
            style={{
              display: "flex",
              width: "50%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Text style={styles.sectionItemTitle}>
              {t("commonText.asset")}: {occurrence?.assetName}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              width: "50%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          ></View>
        </View>

        <Text
          style={{
            fontWeight: "bold",
            fontSize: 9,
            marginBottom: 3,
            marginTop: 10,
          }}
        >
          {t("modalReport.PDFReport.extraInformation")}
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "2px",
            borderTop: "2px solid black",
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 9,
              marginBottom: 3,
              marginTop: 3,
            }}
          >
            {t("commonText.facility")}:
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "2px",
            }}
          >
            <Text
              style={{
                width: "50%",
                fontSize: 8,
              }}
            >
              {t("modalReport.PDFReport.description")}
            </Text>
            <Text
              style={{
                width: "50%",
                fontSize: 8,
              }}
            >
              {t("modalReport.PDFReport.value")}
            </Text>
          </View>

          <View
            style={{
              border: "1px solid gray",
            }}
          >
            {occurrence?.assetInfo?.map((item: any, index: any) => (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderTop: "1px solid gray",
                }}
                key={index}
              >
                <Text
                  style={{
                    width: "50%",
                    fontSize: 8,
                    padding: "2px",
                  }}
                >
                  {item.key}
                </Text>

                <Text
                  style={{
                    width: "50%",
                    fontSize: 8,
                    padding: "2px",
                  }}
                >
                  {item.value}
                </Text>
              </View>
            ))}
          </View>

          <Text
            style={{
              fontWeight: "bold",
              fontSize: 9,
              marginBottom: 3,
              marginTop: 3,
            }}
          >
            {t("commonText.occurrence")}:
          </Text>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "2px",
            }}
          >
            <Text
              style={{
                width: "50%",
                fontSize: 8,
              }}
            >
              {t("modalReport.PDFReport.description")}
            </Text>
            <Text
              style={{
                width: "50%",
                fontSize: 8,
              }}
            >
              {t("modalReport.PDFReport.value")}
            </Text>
          </View>

          <View
            style={{
              border: "1px solid gray",
            }}
          >
            {occurrence.occurrenceCustomFields &&
              occurrence?.occurrenceCustomFields
                ?.filter(
                  (field: any) =>
                    field.units?.includes("all") ||
                    field.units?.includes(occurrence?.facilityId),
                )
                .map((item: any, index: any) => {
                  const value = occurrence?.extra?.occurrenceCustomFields?.find(
                    (element: any) => element.id === item.id,
                  )?.value;

                  return (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        borderTop: "1px solid gray",
                      }}
                      key={index}
                    >
                      <Text
                        style={{
                          width: "50%",
                          fontSize: 8,
                          padding: "2px",
                        }}
                      >
                        {item.key}
                      </Text>

                      <Text
                        style={{
                          width: "50%",
                          fontSize: 8,
                          padding: "2px",
                        }}
                      >
                        {value}
                      </Text>
                    </View>
                  );
                })}
          </View>
        </View>

        <Text
          style={{
            fontWeight: "bold",
            fontSize: 12,
            marginBottom: 3,
            marginTop: 10,
          }}
        >
          {t("modalReport.PDFReport.occurrenceInfo")}
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderTop: "2px solid black",
          }}
        >
          <View style={styles.sectionItemContainer}>
            <Text style={styles.sectionItemTitle}>
              {" "}
              {t("modalReport.PDFReport.openingDate")}:
            </Text>
            <Text style={styles.sectionItemText}>
              {new Intl.DateTimeFormat("pt-BR", {
                dateStyle: "short",
                timeStyle: "medium",
              }).format(new Date(occurrence.createdAt))}
            </Text>
          </View>
          <View style={styles.sectionItemContainer}>
            <Text style={styles.sectionItemTitle}>
              {t("modalReport.PDFReport.dateToAction")}:
            </Text>
            <Text style={styles.sectionItemText}>
              {!occurrence.dueDate
                ? `${t("occurrencesTab.occurrenceListItem.unassigned")}`
                : occurrence.dueDate
                ? new Intl.DateTimeFormat("pt-BR", {
                    dateStyle: "short",
                    timeStyle: "medium",
                  }).format(new Date(occurrence.dueDate))
                : " "}
            </Text>
          </View>
          <View style={{ ...styles.sectionItemContainer, width: "16%" }}>
            <Text style={styles.sectionItemTitle}>
              {t("modalReport.PDFReport.occurrenceNumber")}:
            </Text>
            <Text style={styles.sectionItemText}>{occurrence.id}</Text>
          </View>
          <View style={{ ...styles.sectionItemContainer, width: "16%" }}>
            <Text style={styles.sectionItemTitle}>Status : </Text>
            <Text
              style={{ ...styles.sectionItemText, textTransform: "uppercase" }}
            >
              {occurrence?.closedAt
                ? t("commonText.closed")
                : t("commonText.open")}
            </Text>
          </View>
          <View style={{ ...styles.sectionItemContainer, width: "18%" }}>
            <Text style={styles.sectionItemTitle}>{t("commonText.type")}:</Text>
            <Text
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                backgroundColor: getStatusColor(
                  occurrence.sysStatusId,
                  occurrence.type,
                ),
                fontSize: "10px",
                color: "white",
                height: "70%",
                fontWeight: 1000,
                paddingTop: "3px",
              }}
            >
              {getStatusCaption(
                occurrence.sysStatusId,
                occurrence.type,
              ).toUpperCase()}
            </Text>
          </View>
        </View>
        {occurrence?.closedAt && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              borderTop: "2px solid black",
            }}
          >
            <View style={styles.sectionItemContainer}>
              <Text style={styles.sectionItemTitle}>Data de Fechamento</Text>
              <Text style={styles.sectionItemText}>
                {new Intl.DateTimeFormat("pt-BR", {
                  dateStyle: "short",
                  timeStyle: "medium",
                }).format(new Date(occurrence?.closedAt))}
              </Text>
            </View>
            <View style={styles.sectionItemContainer}>
              <Text style={styles.sectionItemTitle}>Fechado por:</Text>
              <Text style={styles.sectionItemText}>
                {occurrence?.closedByName}
              </Text>
            </View>
            <View style={styles.sectionItemContainer}>
              <Text style={styles.sectionItemTitle}>
                {t("commonText.ratingDiagnostic")}:
              </Text>
              <Text style={styles.sectionItemText}>
                {occurrence.validAnalysis
                  ? t("commonText.valid")
                  : t("commonText.invalid")}
              </Text>
            </View>
            <View style={styles.sectionItemContainer}>
              <Text style={styles.sectionItemTitle}>
                {t("commonText.intervention")}:
              </Text>
              <Text style={styles.sectionItemText}>
                {occurrence.hadIntervention
                  ? t("commonText.radio.yes")
                  : t("commonText.radio.no")}
              </Text>
            </View>
          </View>
        )}
        {occurrence?.diagnostics?.length && (
          <View>
            <Text style={styles.titleSecondary}>
              {t("ocurrenceRegisterModal.diagnostics")}:
            </Text>

            <View>
              {(occurrence?.diagnostics ?? []).map((item: any) => (
                <View
                  style={{
                    display: "flex",
                    border: "1px solid gray",
                    padding: "4px",
                    flexWrap: "wrap",
                    gap: "4px",
                    flexDirection: "row",
                    marginBottom: "4px",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      width: "48%",
                      flexDirection: "column",
                      border: "1px solid #e5e5e5",
                    }}
                  >
                    <Text style={styles.sectionPrimaryTitle}>
                      {t("ocurrenceRegisterModal.diagnostic")}:
                    </Text>
                    <Text style={styles.sectionSecondatyTitle}>
                      {translateDiagnostic(item?.diagnostic || "")}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flex: "1",
                      flexDirection: "column",
                      border: "1px solid #e5e5e5",
                    }}
                  >
                    <Text style={styles.sectionPrimaryTitle}>
                      {t("ocurrenceRegisterModal.recommendation")}:
                    </Text>
                    <Text style={styles.sectionSecondatyTitle}>
                      {item?.recommendation}
                    </Text>
                  </View>

                  <View
                    style={{
                      display: "flex",
                      width: "100%",
                      borderBottom: "1px solid gray",
                      border: "1px solid #e5e5e5",
                    }}
                  >
                    <Text style={styles.sectionPrimaryTitle}>Comentário</Text>
                    <Text style={styles.sectionSecondatyTitle}>
                      {item?.comments}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        )}
        {occurrence?.closedAt && (
          <>
            <View>
              <Text style={styles.sectionItemTitle}>
                {t("occurrencesTab.occurrenceListItem.comments")} :
              </Text>
              <Text
                style={{
                  ...styles.sectionItemTitle,
                  color: "#555",
                  borderBottom: "2px solid black",
                }}
              >
                {closedComment || "-"}
              </Text>
            </View>
          </>
        )}
        {occurrence.occurrenceHistoryComments?.length && (
          <View>
            <View
              style={{
                display: "flex",
                width: "100%",
                minHeight: "40px",
                borderBottom: "2px solid black",
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  marginBottom: 3,
                  marginTop: 10,
                }}
              >
                {t("commonText.history")}
              </Text>

              {occurrence?.occurrenceHistoryComments
                .sort(
                  (a: any, b: any) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime(),
                )
                .map((comment: any) => (
                  <View style={{ marginBottom: "10px" }} wrap={false}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <View style={{ width: "50%" }}>
                        <Text
                          style={{
                            ...styles.sectionItemTitle,
                            textOverflow: "ellipsis",
                          }}
                        >
                          Usuário:{" "}
                          <Text style={{ color: "#555" }}>{comment.name}</Text>
                        </Text>
                      </View>
                      <View style={{ width: "25%" }}>
                        <Text
                          style={{
                            ...styles.sectionItemTitle,
                            textOverflow: "ellipsis",
                          }}
                        >
                          {t("commonText.type")} :{" "}
                          <Text style={{ color: "#555" }}>
                            {getCommentType(comment.type)}
                          </Text>
                        </Text>
                      </View>
                      <View style={{ width: "25%" }}>
                        <Text
                          style={{
                            ...styles.sectionItemTitle,
                            textOverflow: "ellipsis",
                          }}
                        >
                          {t("commonText.date")} :{" "}
                          <Text style={{ color: "#555" }}>
                            {new Intl.DateTimeFormat("pt-BR", {
                              dateStyle: "short",
                              timeStyle: "medium",
                            })
                              .format(new Date(comment.createdAt))
                              .replace(",", "")}
                          </Text>
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text
                        style={{
                          border: "1px solid gray",
                          paddingLeft: 5,
                          paddingVertical: 2,
                          fontWeight: 400,
                          fontSize: 8,
                        }}
                      >
                        {t("occurrencesTab.occurrenceListItem.comments")} :{" "}
                        <Text style={{ color: "#555" }}>
                          {comment.comments}
                        </Text>
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          </View>
        )}

        {!!displayableEvidences.length && (
          <>
            <Text style={styles.sectionTitle}>
              {t("modalReport.PDFReport.evidence")} :
            </Text>
            <View
              style={{
                padding: "10px 25px",
                flexWrap: "wrap",
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              {displayableEvidences.map((evidence: any) => (
                <View style={{ border: "1px solid lightgray" }} wrap={false}>
                  <Text
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 800,
                      fontSize: 10,
                      paddingVertical: 4,
                      textAlign: "center",
                      color: "#555",
                      paddingLeft: "15px",
                      border: "1px solid gray",
                    }}
                  >
                    {evidence.label} - {evidence.positionName}
                  </Text>
                  <ImagePDF
                    src={{
                      uri: evidence.url,
                      method: "GET",
                      headers: { "Cache-Control": "no-cache" },
                      body: "",
                    }}
                    style={{ width: 450, height: 200 }}
                  ></ImagePDF>
                </View>
              ))}
            </View>
          </>
        )}

        <Text style={styles.sectionTitle}>
          {t("modalReport.PDFReport.historic")} :
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            borderBottom: "2px solid black",
          }}
        >
          {history.reverse().map((item: any) => (
            <View
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Text style={styles.sectionItemTitle}>
                {formatDate(+item.date, { type: "FULL" })}
              </Text>
              <Text style={styles.sectionItemTitle}>{item.userName}</Text>
              <Text style={styles.sectionItemTitle}>{item.action}</Text>
            </View>
          ))}
        </View>

        <View style={styles.footer} fixed>
          <Text style={{ fontSize: 10 }}>
            IBBx Retina Web ©{new Date().getFullYear()}
          </Text>
          <ImagePDF
            style={{ width: 15, marginLeft: 10 }}
            src="https://ibbx-retina-images.s3.amazonaws.com/ibbxLogo.png"
          />
        </View>
      </Page>
    </>
  );
};
