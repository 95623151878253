import styled, { css } from "styled-components";
import { FrameBodyRight } from "../../../components/frame/styles";

import { ButtonSave } from "../../../components/Modal/styles";
import { FormDiv, ProfileScreen } from "../Profile/styles";

export const ContainerTheme = styled(FrameBodyRight)`
  width: 100%;
  margin-left: 0;
  height: 100%;

  @media ${({ theme }) => theme.mediaQuery.small} {
    opacity: 1;
    width: 100vw;
    margin-left: 0;
    height: calc(100% - 5rem);
    overflow: hidden;
  }
`;

export const Container = styled.div`
  padding: 2% 4% 4%;
  height: 100%;
  & > h2 {
    ${(props) => props.theme.fonts.primary.H1Semibold};
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
  background-color: ${(props) => props.theme.colors.background};

  @media ${({ theme }) => theme.mediaQuery.small} {
    & > h2 {
      margin: 0.6rem 0px 1rem 4rem;
      font-size: 1.25rem;
    }
  }
`;

export const ThemeScreen = styled(ProfileScreen)`
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 16px;
  overflow: hidden;

  @media ${({ theme }) => theme.mediaQuery.small} {
    width: 100%;
    height: 90%;
    overflow: hidden;
  }

  @media screen and (min-width: 768px) and (max-width: 1536px) {
    height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const SidebarMenuItem = styled.div`
  background: ${(props) => props.theme.colors.passwordBackground};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 24rem;
  height: 100%;

  @media screen and (max-width: 767px) {
    width: fit-content;
  }

  @media screen and (min-width: 768px) and (max-width: 1197px) {
    width: fit-content;
    height: fit-content;
    flex-direction: row;
  }
`;

export const CustomizeContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;

  h3 {
    ${(props) => props.theme.fonts.primary.H2Bold};
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  & > div {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    padding: 1rem;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    overflow: auto;

    & > div {
      padding: 0;
    }
  }
`;

export const CustomizeMenuItem = styled.div<{ selected?: boolean }>`
  background: ${(props) => props.selected && props.theme.colors.divBackground};
  ${(props) => props.theme.fonts.primary.H4Regular}
  height: 40px;
  color: ${(props) => props.theme.colors.textPrimary};

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  svg {
    width: 1.4rem;
    height: auto;
    color: ${(props) => props.selected && props.theme.colors.textPrimary};
  }

  span {
    scale: ${(props) => props.selected && "1.04"};
  }

  &:hover {
    opacity: 0.7;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      animation: slideIn 0.3s ease-in-out;
    }
  }

  @keyframes slideIn {
    from {
      transform: scaleX(0);
      transform-origin: left;
    }
    to {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0px 8px;
    height: 2.8rem;
    span {
      display: none;
    }
  }
`;

export const FormColorContainer = styled(FormDiv)`
  width: 100%;
  padding-inline: 20px;

  & > label {
    ${(props) => props.theme.fonts.primary.H4Regular};
    color: ${(props) => props.theme.colors.iconsPrimary};
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 767px) {
    overflow: auto;
    & > label {
      display: none;
    }
  }
`;

export const ColorsSelectContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 1rem;

  @media ${({ theme }) => theme.mediaQuery.small} {
    flex-direction: column;
    gap: 0rem;
  }
`;

export const ColorSelect = styled.div`
  display: flex;
  padding-top: 0.3rem;
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  border-radius: 8px;
  width: 100%;

  & > span {
    ${(props) => props.theme.fonts.primary.H4Regular};
    color: ${(props) => props.theme.colors.iconsPrimary};
    margin-bottom: 0.5rem;
  }

  @media ${({ theme }) => theme.mediaQuery.small} {
    padding: 1rem 0;
    flex-direction: column !important;
  }
`;

export const StandatdColors = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  & > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  @media ${({ theme }) => theme.mediaQuery.small} {
    margin-top: 0.5rem;
    padding: 0;
    width: 100%;

    & > div {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.3rem;
    }
  }
  @media screen and (min-width: 2401px) {
    & > div {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.3rem;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1197px) {
    & > div {
      width: 100%;
      height: 50vh;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      overflow: hidden auto;
      &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px;
        @media screen and (max-width: 767px) {
          width: 3px !important;
        }
      }

      &::-webkit-scrollbar-track {
        background: ${(props) => props.theme.colors.background};
        width: 20px;
        border-radius: 100vw;
        margin-block: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
        height: 100%;
        max-height: 5rem;
        border-radius: 100vw;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.colors.iconsSecondary};
      }

      &::-webkit-scrollbar-thumb:active {
        background: ${(props) => props.theme.colors.iconsSecondary};
      }
    }
  }
`;

export const ThemeButton = styled.button<{
  selected?: boolean;
  chosing?: boolean;
}>`
  border: none;
  background: none;
  transition: transform 0.1s ease-in-out;
  padding: 0.3rem;

  svg {
    border-radius: 8px;
  }

  ${(props) =>
    props.chosing &&
    css`
      transform: scale(1.05);
    `};

  ${(props) =>
    props.selected &&
    css`
      transform: scale(1.03);
      border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
      border-radius: 8px;
    `}

  :hover {
    transform: scale(1.03);
  }

  & > svg {
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1197px) {
    flex: auto 1;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  width: 100%;
  padding: 1rem 0;
  float: right;

  @media screen and (max-width: 767px) {
    margin-top: 15px;
    width: 100%;
    justify-content: space-between;

    & > div > button {
      width: fit-content;
      padding: 0 0.5rem;
    }
  }
`;

export const CustomizeColor = styled.div``;

export const SaveButton = styled(ButtonSave)`
  width: 6rem;
  float: left;
`;
