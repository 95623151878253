import { IApp } from ".";
import useApi from "../../../../hooks/useApi";
import { IUserSlice } from "../../../../store/interfaces";
import { PROFILES } from "../../../../store/slices/userSlice";

interface IuseApps {
  user?: any;
  companyId?: any;
}
export const useApps = ({ user, companyId }: IuseApps) => {
  const { request, processing } = useApi({
    path: `/companies/${getCompanyId(user)}/apps`,
  });

  const { request: requestCompanies } = useApi({
    path: `/companies`,
  });

  const retrieveApps = async () => {
    return request({ method: "get" });
  };

  const retrieveAppsOnLoad = async (user: IUserSlice) => {
    return requestCompanies({
      method: "get",
      pathParameters: `${getCompanyId(user)}/apps`,
    });
  };

  const createApp = async (app: IApp) => {
    return request({ method: "post", body: app });
  };

  const updateApp = async (app: IApp) => {
    return request({ method: "put", body: app });
  };

  const retrieveApp = async (appId: number) => {
    return request({ method: "get", pathParameters: `${appId}` });
  };

  const deleteApp = async (appId: number) => {
    return request({ method: "del", pathParameters: `${appId}` });
  };

  function getCompanyId(user: any) {
    if (companyId) {
      return companyId;
    }

    const COMPANY_LEVEL_USERS = [
      PROFILES.CLIENTE_COMUM,
      PROFILES.CLIENTE_MASTER_VIEW,
    ];
    if (COMPANY_LEVEL_USERS.includes(user?.profile)) {
      return user?.company;
    }

    return "ibbx";
  }

  return {
    retrieveApps,
    createApp,
    updateApp,
    retrieveApp,
    deleteApp,
    processingApps: processing,
    retrieveAppsOnLoad,
  };
};
