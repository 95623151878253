import {
  ButtonContainer,
  CardCommented,
  CardInfo,
  ContainerModalComments,
} from "./styles";

import React, { useCallback, useMemo, useState } from "react";
import { format } from "date-fns";
import { MdDeleteOutline } from "react-icons/md";
import { useTranslation } from "react-i18next";
import useApi from "../../hooks/useApi";
import { IChartCommentModal } from "./types";
import ModalCompound from "../../components/ModalCompound";
import ConfirmDeleteModal from "../ConfirmDeleteForm";

export default function ChartCommentModal({
  positionId,
  graphicType,
  body,
  handleClose,
  refresh = () => null,
}: IChartCommentModal) {
  const { date, axis, data, index, show } = body;
  const { t } = useTranslation();
  const path = `/positions/${positionId}/charts/${graphicType}/comments`;
  const { request } = useApi({ path });

  const [comment, setComment] = useState(data?.comment ?? "");
  const [deleteModal, setDeleteModal] = useState(false);

  const author = useMemo(() => data?.author ?? "", [data]);

  function postComment() {
    const body = { comment, axis, index, date };
    request({ method: "post", body }).then(() => {
      handleClose();
      refresh();
    });
  }

  function deleteComment(comments?: string) {
    request({ method: "del", body }).then(() => {
      setDeleteModal(false);
      handleClose();
      refresh();
    });
  }

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setComment(event.target.value);
    },
    [],
  );

  const renderBody = () => {
    if (!body.data) {
      return (
        <>
          <ContainerModalComments>
            <span>{t("commentModal.newComment")}</span>

            <div>
              <textarea
                value={comment}
                onChange={handleChange}
                spellCheck={false}
                rows={3}
                cols={60}
                placeholder={`${t("commentModal.insert")}`}
              ></textarea>

              <button onClick={() => postComment()}>
                <span>{t("dictionary.actions.Save")}</span>
              </button>
            </div>
          </ContainerModalComments>
        </>
      );
    }

    return (
      <CardCommented>
        <div>
          <CardInfo>
            <span>
              {data?.date
                ? format(new Date(data.date), "dd/MM/yyyy HH:mm")
                : ""}
            </span>
            <span>
              {t("commentModal.by")}: {author}
            </span>
          </CardInfo>
          <div>
            <strong>{comment}</strong>
          </div>
        </div>
        <ButtonContainer onClick={() => setDeleteModal(true)}>
          <span>{t("commentModal.Remover comentário")}</span>
          <MdDeleteOutline size={16} />
        </ButtonContainer>
      </CardCommented>
    );
  };

  return (
    <>
      <ModalCompound show={show} size="sm" handleClose={handleClose}>
        <ModalCompound.Body>{renderBody()}</ModalCompound.Body>
      </ModalCompound>
      {deleteModal && (
        <ConfirmDeleteModal
          id={0}
          show={deleteModal}
          handleClose={() => setDeleteModal(false)}
          context={`${t("confirmModalDelete.deleteCommentContext")}`}
          handleConfirm={deleteComment}
        />
      )}
    </>
  );
}
