import { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import {
  APITabContainer,
  AddButton,
  ClipBoardToCopy,
  ContainerMyAPI,
  Content,
  DeleteButton,
  DocContainer,
  DocRedirect,
  EmptyContainer,
  FrameBodyMyAPI,
  Header,
  MyApiArea,
  SecondTableContainer,
  TableBody,
  TableHead,
} from "./styles";
import { SidebarConfiguration } from "../../../components/SidebarConfiguration";
import Frame from "../../../components/frame";
import { useParams } from "react-router-dom";
import { ButtonSave } from "../../../components/Modal/styles";
import copy from "copy-to-clipboard";
import { MdCopyAll, MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "../../../components/Tabs";
import { AddEventsModal } from "../../../modals/AddEventsModal";
import { TbPlus } from "react-icons/tb";
import { success } from "../../../components/Toast";
import { CiCloudOff } from "react-icons/ci";
import { isMobile } from "react-device-detect";
import { ConfirmModalV2 } from "../../../modals/ConfirmModalV2";
import { IoMdOpen } from "react-icons/io";
import { useSegment } from "../../../hooks/useSegmentTrackEvent ";

interface ITrigger {
  bearer: string;
  companyId: number;
  method: string;
  pk: string;
  sk: number;
  url: string;
}

export default function Integration() {
  const { companyId } = useParams();

  const { t } = useTranslation();
  const { request } = useApi({ path: `/companies/${companyId}/api-token` });
  const { request: requestTriggers, processing: processingTriggers } = useApi({
    path: `/companies/${companyId}/api-triggers`,
  });
  const { handleTrackEvent } = useSegment();

  const [triggersList, setTriggersList] = useState<any>([]);
  const [token, setToken] = useState<string | null>("");
  const [copyToClipboard, setCopyToClipboard] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>("TRIGGERS");
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [triggerDetail, setTriggerDetail] = useState<{
    pk: string;
    sk: number;
  }>({
    pk: "",
    sk: 0,
  });
  const [showEventsModal, setShowEventsModal] = useState<boolean>(false);

  const triggerLabel: any = {
    OCCURRENCE_CREATED: "Criação de Ocorrência",
  };

  const isProduction = process.env.REACT_APP_ENV === "production";

  const handleRedirectToAPIDoc = () => {
    window.open(
      isProduction
        ? "https://doc.api.ibbx.tech"
        : "https://qa.doc.api.ibbx.tech/",
      "_blank",
    );

    handleTrackEvent({
      event: "Api Documentação",
    });
  };

  useEffect(() => {
    request({ method: "get" }).then(({ accessToken }) => {
      setToken(accessToken);
    });

    loadTriggersList();
    // eslint-disable-next-line
  }, [companyId]);

  function loadTriggersList() {
    requestTriggers({ method: "get" }).then((response) =>
      setTriggersList(response || []),
    );
  }

  function handleShowConfirmDelete(pk: string, sk: number) {
    setTriggerDetail({
      pk,
      sk,
    });
    setShowDeleteModal(true);
  }

  function handleCloseConfirmDelete() {
    setShowDeleteModal(false);
  }

  function handleDeleteTrigger(pk: string, sk: number) {
    if (!processingTriggers) {
      requestTriggers({
        method: "del",
        body: {
          pk: triggerDetail?.pk,
          sk: triggerDetail?.sk,
        },
      }).then((response) => {
        loadTriggersList();
        setShowDeleteModal(false);
        success("Gatilho excluído com sucesso!");
      });

      handleCloseConfirmDelete();
    }
  }

  function handleNewToken() {
    request({ method: "put" }).then(({ accessToken }) => {
      setToken(accessToken);
    });

    handleTrackEvent({
      event: "Api Gerar Novo Token",
    });
  }

  function handleCopy() {
    if (token) {
      const success = copy(token);
      setCopyToClipboard(true);
      if (success) {
        setTimeout(() => {
          setCopyToClipboard(false);
        }, 1500);
      }
    }
  }

  function handleOpenAddEventsModal() {
    setShowEventsModal(true);
    handleTrackEvent({
      event: "Api Documentação",
    });
  }

  function handleChangeTap(tab: string) {
    setCurrentTab(tab);
  }

  function formatTriggerType(trigger: string) {
    const splitted: string = trigger.split("-")[1];

    return triggerLabel[splitted];
  }

  return (
    <Frame hasMargin={false}>
      <FrameBodyMyAPI>
        <SidebarConfiguration />
        <MyApiArea visible backgroundWhite>
          <ContainerMyAPI>
            <Header>
              <h2>{`${t("api.Integração")}`}</h2>
            </Header>
            <Tabs currentTab={currentTab} handleChangeTab={handleChangeTap}>
              <Tab label="API" key={"API"}>
                <APITabContainer>
                  <DocContainer>
                    <h2>
                      {`${t("api.Documentação")}`}
                      <DocRedirect onClick={handleRedirectToAPIDoc}>
                        <IoMdOpen />
                      </DocRedirect>
                    </h2>
                  </DocContainer>

                  <h2>{`${t("api.Meu Token")}`}</h2>

                  <ClipBoardToCopy
                    copyToClipboard={copyToClipboard}
                    onClick={handleCopy}
                  >
                    <p>
                      {token ??
                        t(
                          "api.Você não tem um Token, clique no botão abaixo para gerar um",
                        )}
                    </p>
                    {token && (
                      <button
                        onClick={handleCopy}
                        title={`${t("api.Copiar Token")}`}
                      >
                        <MdCopyAll />
                      </button>
                    )}
                    {copyToClipboard && (
                      <span>{`${t(
                        "api.Token copiado para a área de transferência",
                      )}`}</span>
                    )}
                  </ClipBoardToCopy>

                  <div>
                    <ButtonSave onClick={handleNewToken}>{`${t(
                      "api.Gerar novo Token",
                    )}`}</ButtonSave>
                  </div>
                </APITabContainer>
              </Tab>
              <Tab label={`${t("api.Gatilho")}s`} key="TRIGGERS">
                <Content>
                  <table>
                    <TableHead>
                      <tr>
                        <th className="method">{t("api.Método")}</th>
                        <th className="trigger">{t("api.Gatilho")}</th>
                        {!isMobile && (
                          <>
                            <th className="url">URL</th>
                            <th className="auth">{t("api.Autenticação")}</th>
                          </>
                        )}
                        <th className="action">
                          <AddButton onClick={handleOpenAddEventsModal}>
                            <TbPlus />
                          </AddButton>
                        </th>
                      </tr>
                    </TableHead>
                  </table>

                  <SecondTableContainer>
                    <table>
                      <TableBody>
                        {triggersList.map((trigger: ITrigger) => (
                          <tr>
                            <td className="method">{trigger.method}</td>
                            <td className="trigger">
                              {formatTriggerType(trigger.pk)}
                            </td>
                            {!isMobile && (
                              <>
                                <td className="url">
                                  <div>{trigger.url}</div>
                                </td>
                                <td className="auth">
                                  <div>{trigger.bearer}</div>
                                </td>
                              </>
                            )}
                            <td className="action">
                              <DeleteButton
                                onClick={() =>
                                  handleShowConfirmDelete(
                                    trigger.pk,
                                    trigger.sk,
                                  )
                                }
                              >
                                <MdDelete size={18} />
                              </DeleteButton>
                            </td>
                          </tr>
                        ))}
                      </TableBody>
                    </table>
                  </SecondTableContainer>
                  {!processingTriggers && !triggersList.length && (
                    <EmptyContainer>
                      <CiCloudOff size={55} />
                      <label>{t("api.Não há gatilhos cadastrados")}</label>
                    </EmptyContainer>
                  )}
                </Content>
              </Tab>
            </Tabs>
            <AddEventsModal
              show={showEventsModal}
              refresh={loadTriggersList}
              handleClose={() => setShowEventsModal(false)}
            />
            <ConfirmModalV2
              show={showDeleteModal}
              handleConfirm={handleDeleteTrigger}
              handleClose={() => setShowDeleteModal(false)}
              text="Tem certeza que deseja excluir esse gatilho?"
            />
          </ContainerMyAPI>
        </MyApiArea>
      </FrameBodyMyAPI>
    </Frame>
  );
}
