import { ColorOption, ColorPickerContainer } from "./styles";

type Props = {
  selectedColor: string;
  onSelect: (color: string) => void;
  label?: any;
};

export function IconsColorPicker({ selectedColor, onSelect, label }: Props) {
  const colors = [
    "#000",
    "#0C0E13",
    "#1E222F",
    "#3a4058",
    "#dddfed",
    "#FFFFFF",
  ];

  return (
    <ColorPickerContainer>
      <span>{label}</span>
      <div>
        {colors.map((color) => (
          <ColorOption
            key={color}
            color={color}
            selected={selectedColor === color}
            onClick={() => onSelect(color)}
          />
        ))}
      </div>
    </ColorPickerContainer>
  );
}
