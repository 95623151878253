import { useRef, useState } from "react";
import {
  Container,
  ContainerOccurrenceAsset,
  TooltipContainer,
} from "./styles";
import { FaRegLightbulb } from "react-icons/fa";
import useOnClickOutSide from "../../hooks/useOnClickOutside";
import { MdTipsAndUpdates } from "react-icons/md";

interface InsightButtonProps {
  expanded?: boolean;
  occurrenceId?: number;
  show?: any;
  handleClick?: any;
  assetOccurrence?: boolean;
}
export const InsightButton = ({
  show,
  expanded,
  occurrenceId,
  handleClick,
  assetOccurrence,
}: InsightButtonProps) => {
  const [showInsight, setShowInsight] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const ref = useRef(null);

  useOnClickOutSide(ref, () => setShowInsight(false));

  return (
    <>
      {assetOccurrence ? (
        <ContainerOccurrenceAsset
          onClick={(e) => {
            e.preventDefault();
            handleClick(true);
          }}
          light={showInsight}
          show={show}
          ref={ref}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {showTooltip && <TooltipContainer>Retina Insights</TooltipContainer>}
          <MdTipsAndUpdates size={22} />
        </ContainerOccurrenceAsset>
      ) : (
        <Container
          onClick={(e) => handleClick(true)}
          light={showInsight}
          show={show}
          ref={ref}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {showTooltip && <TooltipContainer>Retina Insights</TooltipContainer>}
          <FaRegLightbulb size={22} />
        </Container>
      )}
    </>
  );
};
