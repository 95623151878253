import styled from "styled-components";

export const Toogle = styled.button`
  border: none;
  background-color: transparent;
`;

export const DrawerContainer = styled.div`
  @media ${({ theme }) => theme.mediaQuery.large} {
    .EZDrawer__container {
      right: -92px !important;
    }
  }
`;
