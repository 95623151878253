import { ReactNode } from "react";
import { ChartActionsContent, ChartContent, Container } from "./styles";
import { t } from "i18next";

interface SensorCardMobileProps {
  id: number | string;
  title: number | string;
  horizontal: number | string;
  vertical: number | string;
  series: number | string;
  actions: ReactNode;
  type: number | string;
}

export function SensorCardMobile({
  id,
  title,
  horizontal,
  vertical,
  series,
  actions,
  type,
}: SensorCardMobileProps) {
  return (
    <Container>
      <div>
        <div>
          <ChartContent>
            <label>ID: </label>
            <span> {id}</span>
          </ChartContent>
          <ChartContent style={{ flex: 1 }}>
            <label>{t("newSensor.Título")}: </label>

            <span> {title}</span>
          </ChartContent>
        </div>
        <div>
          <ChartContent>
            <label>{t("newSensor.Tipo")}</label>
            <span>{type}</span>
          </ChartContent>
          <ChartContent>
            <label>Horizontal</label>
            <span>{horizontal}</span>
          </ChartContent>
          <ChartContent>
            <label>Vertical</label>
            <span>{vertical}</span>
          </ChartContent>
          <ChartContent style={{ flex: 1 }}>
            <label>{t("newSensor.Séries")}</label>
            <span>{series}</span>
          </ChartContent>
        </div>
      </div>
      <ChartActionsContent>
        <label>{t("newSensor.Ações")}</label>
        <div>{actions}</div>
      </ChartActionsContent>
    </Container>
  );
}
