import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

type TooltipProps = {
  tooltip: any;
  position: "right" | "left" | "top" | "bottom";
  children: any;
  disabled?: boolean;
};

export const NamePlusTooltip = ({
  tooltip,
  position,
  children,
  disabled,
}: TooltipProps) => {
  return (
    <>
      {!disabled && tooltip ? (
        <OverlayTrigger
          key={tooltip}
          placement={position}
          overlay={
            <Tooltip id={`tooltip-${tooltip}}`} style={{ zIndex: 10000 }}>
              {tooltip}
            </Tooltip>
          }
        >
          {children}
        </OverlayTrigger>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
