import { WelcomeContent } from "./styles";

interface WelcomeTitleProps {
  title: string;
  description?: string;
  secondDescription?: string;
  firstLi?: string;
  secondtLi?: string;
  thirdLi?: string;
}

export function WelcomeTitle({
  title,
  description,
  secondDescription,
  firstLi,
  secondtLi,
  thirdLi,
}: WelcomeTitleProps) {
  return (
    <WelcomeContent>
      <span>{title}</span>
      {description && (
        <>
          <p>{description}</p>
          <p>{secondDescription}</p>
          <ul>
            <li>{firstLi}</li>
            <li>{secondtLi}</li>
            <li>{thirdLi}</li>
          </ul>
        </>
      )}
    </WelcomeContent>
  );
}
