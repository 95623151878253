import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100% !important;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0.8rem;
  padding-top: 1.2rem;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 1229px) and (max-width: 1536px) {
    padding: 0.5rem;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  gap: 0.6rem;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;

  & > span {
    ${(props) => props.theme.fonts.primary.H4Bold}
    text-align: center;
    font-size: 12px;
    border-bottom: 2px solid #859988;

    @media screen and (min-width: 1229px) and (max-width: 1536px) {
      align-self: center;
      width: 80%;
    }
  }

  & > div {
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px;
      @media screen and (max-width: 767px) {
        width: 3px !important;
      }
    }

    &::-webkit-scrollbar-track {
      background: #d5eed0;
      width: 20px;
      border-radius: 100vw;
      margin-block: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #859988;
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #9dc3a1;
    }

    &::-webkit-scrollbar-thumb:active {
      background: #4b6c57;
    }

    & > p {
      ${(props) => props.theme.fonts.primary.H5Regular}
      text-align: center;
    }
  }

  @media screen and (max-width: 767px) {
    & > div {
      & > p {
        ${(props) => props.theme.fonts.primary.H4Regular}
      }
    }
  }

  @media screen and (min-width: 1229px) and (max-width: 1536px) {
    & > div {
      & > p {
        font-size: 0.7rem;
      }
    }
  }
`;

export const LinkArea = styled.div<{ numberOfLinks?: any }>`
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.numberOfLinks === 2 ? "space-between" : "flex-end"};
  ${(props) => props.theme.fonts.primary.H5Regular}
  font-size: 11px;
  margin-top: 0.4rem;

  & > a {
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    text-decoration: none;
    border: 1px solid transparent;

    &:hover {
      filter: brightness(1.1);
      text-decoration: underline;
    }
  }
  & > a:first-child {
    background-color: transparent;
    border: 1px solid #859988;
    color: #859988;
  }
  & > a:last-child {
    background-color: #859988;
    color: #f6f8ee;
  }

  @media screen and (min-width: 1229px) and (max-width: 1536px) {
    ${(props) => props.theme.fonts.primary.H6Bold}
  }
`;
