import styled, { keyframes } from "styled-components";

export const FrequencyContainer = styled.div`
  ${(props) => props.theme.fonts.primary.H4Regular};
  color: ${(props) => props.theme.colors.iconsSecondary};
  width: 100%;
  padding: 0.3rem 7rem;
  display: flex;
  /* gap: 0.4rem; */
  position: absolute;
  left: 6.5rem;
  top: 0.8rem;
  align-items: center;

  & > div {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    border-radius: 6px;
    padding: 6px;
    background: ${(props) => props.theme.backgroundColors.background1};
    display: flex;
    gap: 0.5rem;

    & > div {
      display: flex;
      gap: 0.5rem;

      & > div {
        display: flex;
        gap: 0.15rem;
        align-items: flex-start !important;
        height: 100%;

        label {
          height: 100%;
        }
        & > label:last-child {
          font-size: 11px;
        }
      }
    }
  }
`;

const circleFirst = keyframes`
    0% {
      r: 9;
      opacity: 1;
    }
    25%{
      r: 10;
      opacity: 1;
    }
    50% {
      r: 11;
      opacity: 1;
    }
    75% {
      r: 12;
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;

const circleLast = keyframes`
    0% {
      r: 3;
    }
    25%{
      r: 7;
    }
    50% {
      r: 3;
    }
    75% {
      r: 7;
    }
    100% {
      r: 3;
    }
`;

export const ContainerChart = styled.div<{
  isHovered?: boolean;
  isGlobalNotAnalysis?: boolean;
  hasHarmonics?: boolean;
}>`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;

  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: ${({ hasHarmonics }) => (hasHarmonics ? "90%" : "100%")};

    & > div {
      width: inherit;
      height: 100%;
      position: absolute;
    }
  }

  div > svg > g > g > g:nth-child(1) {
    circle:first-child {
      animation: ${circleFirst}
        ${(props) => props.isGlobalNotAnalysis && `2s infinite;`};
      r: 7;
    }
    circle:last-child {
      animation: ${circleLast}
        ${(props) => props.isGlobalNotAnalysis && `2s infinite;`};
    }
  }

  div > svg > g > g > g:nth-child(2) {
    circle:first-child {
      animation: ${circleFirst}
        ${(props) => props.isGlobalNotAnalysis && `2s infinite;`};
      r: 7;
    }
    circle:last-child {
      animation: ${circleLast}
        ${(props) => props.isGlobalNotAnalysis && `2s infinite;`};
    }
  }

  div > svg > g > g > g:nth-child(3) {
    circle:first-child {
      animation: ${circleFirst}
        ${(props) => props.isGlobalNotAnalysis && `2s infinite;`};
      r: 7;
    }
    circle:last-child {
      animation: ${circleLast}
        ${(props) => props.isGlobalNotAnalysis && `2s infinite;`};
    }
  }

  &:hover {
    div > svg > g > g > g:nth-child(1) {
      circle:first-child {
        animation: ${circleFirst}
          ${(props) => props.isGlobalNotAnalysis && `0s infinite;`};
        r: 7;
      }
      circle:last-child {
        animation: ${circleLast}
          ${(props) => props.isGlobalNotAnalysis && `0s infinite;`};
      }
    }

    div > svg > g > g > g:nth-child(2) {
      circle:first-child {
        animation: ${circleFirst}
          ${(props) => props.isGlobalNotAnalysis && `0s infinite;`};
        r: 7;
      }
      circle:last-child {
        animation: ${circleLast}
          ${(props) => props.isGlobalNotAnalysis && `0s infinite;`};
      }
    }

    div > svg > g > g > g:nth-child(3) {
      circle:first-child {
        animation: ${circleFirst}
          ${(props) => props.isGlobalNotAnalysis && `0s infinite;`};
        r: 7;
      }
      circle:last-child {
        animation: ${circleLast}
          ${(props) => props.isGlobalNotAnalysis && `0s infinite;`};
      }
    }
  }

  div.google-visualization-tooltip {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    background-color: ${(props) => props.theme.colors.headerBackground};
    ${(props) => props.theme.fonts.secondary.H2Regular}
    border-radius: 6px;
    box-shadow: none;
    width: 100%;
    min-width: fit-content;
    max-width: max-content;

    p {
      margin-bottom: 3px !important;
    }

    hr {
      color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      margin-top: 3px !important;
      margin-bottom: 3px !important;
    }
  }

  div.google-visualization-tooltip > ul > li > span {
    color: ${(props) => props.theme.colors.iconsSecondary} !important;
  }

  div > svg {
    &:active {
      cursor: crosshair;
      & > rect {
        fill: lightblue;
        fill-opacity: 0.15;
      }
    }
    & > rect {
      fill: transparent;
    }
  }
`;
