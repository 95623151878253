import { NavBar } from "../frame/NavBar";
import { FrameProps } from "../frame/types";
import { Body, Container } from "./styles";

export const FrameOccurrences = ({
  children,
  headerFixed,
  id,
  ...rest
}: FrameProps) => {
  return (
    <Container {...rest}>
      <NavBar fixed={headerFixed} />
      <Body>{children}</Body>
    </Container>
  );
};
