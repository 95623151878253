type ManageNotificationsProps = {
  title: string;
  type?: string;
  condition?: string;
  value: boolean;
  notifications: any;
};

export function manageNotifications({
  title,
  type,
  condition,
  value,
  notifications,
}: ManageNotificationsProps) {
  if (title === "ALL") {
    return notifications.reduce(
      (updatedNotifications: any, notification: any) => {
        const [notificationTitle, values] = notification;

        const hasConditions = Object.keys(values).some(
          (notificationType) => typeof values[notificationType] === "object",
        );

        if (hasConditions) {
          const updatedValues = Object.keys(values).reduce(
            (updatedNoticationConditions, notificationCondition) => {
              const updatedConditions = Object.keys(
                values[notificationCondition],
              ).reduce(
                (updatedNotificationConditionTypes, notificationType) => {
                  return {
                    ...updatedNotificationConditionTypes,
                    [notificationType]: value,
                  };
                },
                {},
              );
              return {
                ...updatedNoticationConditions,
                [notificationCondition]: updatedConditions,
              };
            },
            {},
          );
          return {
            ...updatedNotifications,
            [notificationTitle]: updatedValues,
          };
        }

        const updatedValues = Object.keys(values).reduce(
          (updatedNotificationTypes: any, notification: any) => {
            return { ...updatedNotificationTypes, [notification]: value };
          },
          {},
        );

        return { ...updatedNotifications, [notificationTitle]: updatedValues };
      },
      {},
    );
  }

  if (type === "ALL" && condition) {
    return notifications.reduce(
      (updateNotifications: any, notification: any) => {
        const [notificationTitle, values] = notification;

        if (notificationTitle !== title) {
          return { ...updateNotifications, [notificationTitle]: values };
        }

        const updatedValues = {
          ...values,
          [condition]: Object.keys(values[condition]).reduce(
            (acc: any, notificationType: any) => {
              acc[notificationType] = value;
              return acc;
            },
            {},
          ),
        };

        return { ...updateNotifications, [notificationTitle]: updatedValues };
      },
      {},
    );
  }

  if (type === "ALL") {
    return notifications.reduce(
      (updateNotifications: any, notification: any) => {
        const [notificationTitle, values] = notification;

        if (notificationTitle !== title) {
          return { ...updateNotifications, [notificationTitle]: values };
        }

        const hasConditions = Object.keys(values).some(
          (notificationType) => typeof values[notificationType] === "object",
        );

        if (hasConditions) {
          const updatedValues = Object.keys(values).reduce(
            (updateNotificationsConditions, notificationCondition) => {
              const updatedConditions = Object.keys(
                values[notificationCondition],
              ).reduce(
                (updateNotificationsConditionsType, notificationType) => {
                  return {
                    ...updateNotificationsConditionsType,
                    [notificationType]: value,
                  };
                },
                {},
              );
              return {
                ...updateNotificationsConditions,
                [notificationCondition]: updatedConditions,
              };
            },
            {},
          );

          return { ...updateNotifications, [notificationTitle]: updatedValues };
        }

        const updatedValues = Object.keys(values).reduce(
          (upddateNotificationType: any, notificationType: any) => {
            return { ...upddateNotificationType, [notificationType]: value };
          },
          {},
        );

        return { ...updateNotifications, [notificationTitle]: updatedValues };
      },
      {},
    );
  }

  return notifications.reduce((updateNotifications: any, notification: any) => {
    const [notificationTitle, values] = notification;

    if (notificationTitle !== title) {
      return { ...updateNotifications, [notificationTitle]: values };
    }

    const hasConditions = Object.keys(values).some(
      (notificationType) => typeof values[notificationType] === "object",
    );

    if (hasConditions) {
      const updatedValues = Object.keys(values).reduce(
        (updateNotificationsConditions, notificationCondition) => {
          const updatedConditions = Object.keys(
            values[notificationCondition],
          ).reduce((updateNotificationsConditionsType, notificationType) => {
            return {
              ...updateNotificationsConditionsType,
              [notificationType]:
                notificationType === type && condition === notificationCondition
                  ? value
                  : values[notificationCondition][notificationType],
            };
          }, {});
          return {
            ...updateNotificationsConditions,
            [notificationCondition]: updatedConditions,
          };
        },
        {},
      );
      return { ...updateNotifications, [notificationTitle]: updatedValues };
    }

    const updatedValues = Object.keys(values).reduce(
      (upddateNotificationType: any, notificationType: any) => {
        return {
          ...upddateNotificationType,
          [notificationType]:
            type === notificationType ? value : values[notificationType],
        };
      },
      {},
    );

    return { ...updateNotifications, [notificationTitle]: updatedValues };
  }, {});
}
