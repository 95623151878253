import styled, { css } from "styled-components";

export const Container = styled.div<{
  isOccurrenceClosed?: boolean;
  isExpanded?: boolean;
}>`
  width: 100%;
  border: 2px solid ${(props) => props.theme.colors.cardUnderlineLight};
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  background-color: ${({ isOccurrenceClosed, theme }) =>
    isOccurrenceClosed
      ? theme.colors.divBackgroundAlpha
      : theme.colors.headerBackground};
  opacity: ${({ isOccurrenceClosed }) => (isOccurrenceClosed ? "0.7" : "1")};
  display: flex;

  table {
    width: 100%;
    overflow: hidden;
    ${({ isExpanded }) =>
      isExpanded &&
      css`
        tr:first-child {
          display: flex;
          align-items: center;
          position: relative;
          width: 100%;
        }

        display: flex;
        flex-direction: column;
      `}
  }

  table > tr {
    display: flex;

    > td {
      padding: 0 0.5rem;
      display: flex;
    }
  }

  .occurrenceId {
    width: 7rem;
  }
  .customField {
    width: 8rem;
  }
  .occurrenceDate {
    width: 7rem;
  }
  .assetName {
    flex: 1;
  }
  .evidence {
    width: 15rem;

    :hover {
      & > div {
        button {
          svg {
            opacity: 1;
          }
        }
      }
    }
  }
  .diagnostic {
    width: 15rem;
    position: relative;
  }
  .timeForAction {
    width: 10rem;
  }

  @media screen and (max-width: 767px) {
    table {
      min-height: 3rem;
      height: 100%;

      & > tr {
        height: 100%;
        td {
          height: 100%;

          div {
            height: 100%;
          }

          align-items: center;
        }
      }
    }
    .occurrenceDate {
      width: 7rem;
      align-items: center;
      height: 100%;
    }
    .assetName {
      flex: 1;
      align-items: center;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    .occurrenceId {
      width: 6rem;
    }
    .customField {
      width: 8rem;
    }
    .occurrenceDate {
      width: 7rem;
    }
    .assetName {
      flex: 1;
    }
    .evidence {
      width: 13rem;

      :hover {
        & > div {
          button {
            svg {
              opacity: 1;
            }
          }
        }
      }
    }
    .diagnostic {
      width: 10rem;
      position: relative;
    }
    .timeForAction {
      width: 8rem;
    }
  }
`;

export const OccurrenceItem = styled.div<{
  occurrenceStatus?: boolean;
  limitedSpanWidth?: boolean;
  noRelevantInfo?: boolean;
  hideButton?: boolean;
  expandOccurrence?: boolean;
  isCritical?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  openOccurrence?: boolean;
}>`
  ${({ theme }) => theme.fonts.secondary.H2Regular};
  font-size: 12px;
  color: ${(props) => props.theme.colors.textPrimary};
  display: flex;
  align-items: center;
  padding: 0.25rem 0;
  background-color: ${({ theme, occurrenceStatus }) =>
    occurrenceStatus ? theme.colors.cardUnderlineLight : "transparent"};
  height: 100%;

  @keyframes oscilateColor {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.1;
    }
  }

  @keyframes flicker-2 {
    0%,
    100% {
      opacity: 1;
    }
    41.99% {
      opacity: 1;
    }
    42% {
      opacity: 0;
    }
    43% {
      opacity: 0;
    }
    43.01% {
      opacity: 1;
    }
    45.99% {
      opacity: 1;
    }
    46% {
      opacity: 0;
    }
    46.9% {
      opacity: 0;
    }
    46.91% {
      opacity: 1;
    }
    51.99% {
      opacity: 1;
    }
    52% {
      opacity: 0;
    }
    52.8% {
      opacity: 0;
    }
    52.81% {
      opacity: 1;
    }
  }

  ${({ isCritical, openOccurrence }) =>
    isCritical === 7 &&
    openOccurrence &&
    css`
      position: relative;
      animation: flicker-2 2s linear infinite both;

      ::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: red;
        opacity: 0.1;
        pointer-events: none;
        animation: oscilateColor 0.9s ease-in-out infinite;
      }
    `};

  span {
    opacity: ${({ noRelevantInfo }) => (noRelevantInfo ? "0.5" : "1")};
  }

  & > svg {
    margin-left: 0.8rem;
    width: 26px;
    height: auto;
  }

  & > button {
    display: ${(props) => props.hideButton && "none"};
    width: 2rem;
    height: 2rem;
    background: none;
    border: none;

    svg {
      color: ${(props) => props.theme.colors.iconsSecondary};
      width: 1.1rem;
      height: auto;
      opacity: 0.5;
      margin: auto;
      rotate: ${({ expandOccurrence }) =>
        expandOccurrence ? "180deg" : "0deg"};
      transition: all 0.4s ease-in-out;
    }
  }

  ${({ limitedSpanWidth }) =>
    limitedSpanWidth &&
    css`
      & > span {
        width: 90%;
      }
    `}

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    font-size: 11px;
  }
`;

export const StatusOccurrence = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.cardUnderlineLight};
  padding: 0.4rem 0;
  /* margin-right: 0.5rem; */
  span {
    ${({ theme }) => theme.fonts.primary.H4Regular};
  }
`;

export const OccurrenceStatusLabel = styled.label<{
  sysStatusId: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  type: "PREDICTIVE" | "CORRECTIVE";
}>`
  /* transform: rotate(270deg); */
  font-size: ${({ sysStatusId }) => sysStatusId === 4 && "12px"};
  ${({ theme }) => theme.fonts.primary.H4Bold};
  color: ${({ theme, sysStatusId, type }) => {
    const { colors } = theme;
    if (type === "CORRECTIVE") {
      return colors.statusBroken;
    }

    switch (sysStatusId) {
      case 7:
      case 6:
      case 5:
        return colors.statusRisk;
      case 4:
        return colors.ibbxOrange;
      case 3:
      case 2:
        return colors.statusAlert;
      case 1:
        return colors.normalStatus;
    }
  }};

  @media screen and (max-width: 767px) {
    color: ${({ theme, sysStatusId }) => {
      const { colors } = theme;
      switch (sysStatusId) {
        case 5:
          return colors.statusRisk;
        case 4:
          return colors.ibbxOrange;
        case 3:
        case 2:
          return colors.statusAlert;
        case 1:
          return colors.normalStatus;
      }
    }};
    ${({ theme }) => theme.fonts.primary.H5Bold};
    color: ${({ theme, sysStatusId }) =>
      typeof sysStatusId !== "number" && theme.colors.statusBroken};
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    font-size: 12px;
  }
`;

export const ActionTime = styled.div<{ instant?: boolean }>`
  width: 100%;
  height: fit-content;
  display: flex;
  margin-right: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.35rem;
  background-color: ${(props) => props.theme.colors.iconsSecondary};
  border-radius: 1rem;
  color: ${(props) => props.theme.colors.background};
  font-weight: 700;

  ${({ instant }) =>
    instant &&
    css`
      background-color: ${(props) => props.theme.colors.statusRisk};
      animation: colorChange 2s linear infinite alternate both;
      color: ${(props) => props.theme.colors.white};
    `}

  @keyframes colorChange {
    0% {
      background: #ce3878;
    }
    25% {
      background: #ab5379;
    }
    50% {
      background: #ce3878;
    }
    75% {
      background: #9f1f56;
    }
    100% {
      background: #930a45;
    }
  }
`;

export const IsOccurrenceClosed = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  right: 0.5rem;
  top: 50%;
  transform: translatey(-50%);
  color: ${(props) => props.theme.colors.iconsSecondary};

  svg {
    width: 100%;
    height: auto;
  }
`;

export const AggravatedOccurrence = styled(IsOccurrenceClosed)`
  svg {
    width: 1rem;
    height: auto;
  }
`;

export const ContractContaner = styled(IsOccurrenceClosed)<{
  categoryType?: "SENSITIVE_INSPECTION" | "MANUAL_COLLECT" | null;
}>`
  border: 2px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  border-radius: 100%;

  ${({ theme }) => theme.fonts.secondary.H1Bold}
  background-color: ${({ categoryType, theme }) =>
    categoryType === "SENSITIVE_INSPECTION"
      ? theme.colors.sentitiveInspection
      : theme.colors.manualCollect};
  span {
    margin: auto;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    right: 0.25rem;
  }
`;

export const RetinaInsigthsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: 0.5rem;
`;

export const RiskStatusLabel = styled.div`
  width: 6rem;
  @media screen and (max-width: 767px) {
    width: 30%;
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    width: 5rem;
  }
`;

export const EvidenceTdContainer = styled.div<{ hideButton?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;

  & > button {
    display: ${(props) => props.hideButton && "none"};
    width: 2rem;
    height: 2rem;
    background: none;
    border: none;

    svg {
      color: ${(props) => props.theme.colors.iconsSecondary};
      width: 1rem;
      height: auto;
      opacity: 0.5;
      margin: auto;
      transition: 0.2s ease-in-out;
    }
  }
`;
