import styled from "styled-components";
import { StandardOccurrenceStyled } from "../styles";

export const OccurrenceHistoryContainer = styled(StandardOccurrenceStyled)`
  padding: 1rem 0.5rem;
  margin-bottom: 3rem;
  gap: 0.5rem;
  position: relative;
  overflow: hidden;
  ${(props) => props.theme.fonts.primary.H4Regular};
  border-radius: 0 0 8px 8px;

  @media screen and (max-width: 767px) {
    margin-right: 0rem;
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    padding: 0.5rem;
  }
  @media screen and (min-width: 768px) and (max-width: 1280px) {
    margin-bottom: 6rem;
  }
`;

export const HistoryDashedLine = styled.div`
  border-left: 2px dashed ${(props) => props.theme.colors.headerCardSecondary};
  position: absolute;
  width: 2px;
  height: 100%;
  margin: 2.1rem 0px 0px 1.1rem;
  @media screen and (max-width: 767px) {
    margin: 2rem 0px 0px 1.05rem;
  }
`;
export const HistoryItems = styled.div`
  margin-left: 0.6rem;
  display: flex;
  gap: 0.5rem;
  z-index: 2;
`;
export const HistoryData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  svg {
    width: 1rem;
    height: auto;
    color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > div:first-child {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    background-color: ${(props) => props.theme.colors.occurrenceInfoBackground};
  }

  @media screen and (max-width: 767px) {
    div {
      span {
        text-align: center;
        font-size: 0.75rem !important;
        line-height: 16px !important;
        ${(props) => props.theme.fonts.primary.H5Regular};
      }
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    div {
      span {
        text-align: center;
        font-size: 0.75rem !important;
        line-height: 16px !important;
        ${(props) => props.theme.fonts.primary.H5Regular};
      }
    }
  }
`;
export const HistoryInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.25rem 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background-color: ${(props) => props.theme.colors.cardBackground};
  border-radius: 6px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    padding: 0 1rem;

    & > span {
      align-self: baseline;
    }

    & > span:first-child {
      ${(props) => props.theme.fonts.primary.H5Regular};
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
    & > span:last-child {
      ${(props) => props.theme.fonts.primary.H4Regular};
      font-size: 12px;
    }
  }

  & > div:first-child {
    padding-left: 0;
  }
  & > div:last-child {
    padding-right: 0;
  }

  & > div:not(:last-child) {
    border-right: 2px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 0.1rem;

    div {
      span {
        font-size: 0.75rem !important;
        line-height: 16px !important;
        ${(props) => props.theme.fonts.primary.H5Regular};
      }
      justify-content: flex-start;
      padding: 0.2rem;
    }
    & > div:not(:last-child) {
      border-right: none;
      border-bottom: 2px solid
        ${(props) => props.theme.colors.iconsSecondaryAlpha};
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    padding: 0.25rem 0.5rem;
    & > div {
      gap: 0.3rem;
      padding: 0 0.5rem;

      & > span:last-child {
        font-size: 11px;
      }
    }
  }
`;
