import { RootState } from "../../../../store/store";
import { Container } from "./styles";
import { useSelector } from "react-redux";
interface IContainerTabDesktop {
  isFiltered?: boolean;
  showSidebar?: boolean;
  children: JSX.Element | Array<JSX.Element | false>;
}

export default function ContainerTabDesktop({
  children,
  isFiltered,
  showSidebar,
}: IContainerTabDesktop) {
  const hideDashboardCharts = useSelector(
    (state: RootState) => state.dashboardToggle.hideDashboardCharts,
  );
  return (
    <Container
      isFiltered={isFiltered}
      showSidebar={showSidebar}
      isExpanded={hideDashboardCharts}
    >
      {children}
    </Container>
  );
}
