import { ReactNode } from "react";
import { ReasonContainer } from "./styles";

interface IReasonContent {
  children?: ReactNode;
  title?: ReactNode;
}

export function ReasonContent({ children, title }: IReasonContent) {
  return (
    <ReasonContainer>
      <strong>{title}</strong>
      {children}
    </ReasonContainer>
  );
}
