import {
  GoogleChartControl,
  GoogleChartWrapper,
  GoogleViz,
  ReactGoogleChartProps,
} from "react-google-charts";
import { chartAreaType } from "../../../utils/charts/getOptions";
import { ISerieControl } from "../../../hooks/useSeriesControl";
import { IChart, IPosition } from "../../../store/interfaces";
import { IAnalysis } from "../../../pages/Analysis/AnalysisTools/types";

export type explorerType = {
  actions?: string[];
  keepInBounds?: boolean;
};

export type chartPointsModel = {
  index: number;
  color: string;
  name: string;
};

export type chartDataModel = {
  data: any[];
  series: any[];
  minValue: number;
  maxValue: number;
  points?: chartPointsModel[];
  [key: string]: any;
  ticks?: any[];
};

export const chartDataEmpty = {
  data: [],
  series: [],
  minValue: 0,
  maxValue: 1,
};

export type ChartProps = {
  position?: IPosition;
  chart: IChart;
  date?: number;
  chartData: chartDataModel;
  seriesControl: ISerieControl[];
  handleClickPoint?: (txt: string, ref: string) => void;
  getChartComments?: () => void;
  dateFormat?: string;
  tooltipConfig?: any;
  analysis?: IAnalysis;
  multipleAxis?: any;
  // Custom Config
  chartArea?: chartAreaType;
  yTicks?: any[];
};

export const defaultChartArea = {
  height: "82%",
  width: "88%",
  bottom: "15%",
};

export const defaultOptions = {
  chartArea: defaultChartArea,
  selectionMode: "single",
  backgroundColor: "transparent",
  tooltip: { isHtml: true },
  aggregationTarget: "none",
  legend: "none",
  interpolateNulls: true,
  //explorer: action,
};

export const defaultHAxis = {
  minorGridlines: { color: "transparent" },
  direction: 1,
  textStyle: {
    fontSize: 10,
  },
};

export const defaultVAxis = {
  minorGridlines: { color: "transparent" },
  textStyle: {
    fontSize: 10,
  },
  format: "short",
  viewWindowMode: "explicit",
};

export type showModalCommentModel = {
  show: boolean;
  index: number;
  axis: string;
  comment: any;
};

export const showModalCommentEmpty: showModalCommentModel = {
  show: false,
  index: 0,
  axis: "",
  comment: null,
};

export type axisModel = {
  value: number;
  unit: string;
};

export type dataTypeModel =
  | "DEFAULT"
  | "HARMONICS"
  | "BEARINGS"
  | "COMMENTS"
  | "PHASES";

export type axisXDataModel = "DATE" | "FREQUENCY" | "SECONDS" | "UNKNOWN";

export type dataMapModel =
  | "DATE"
  | "FREQUENCY"
  | "SECONDS"
  | "UNKNOWN"
  | "VALUE"
  | "NULL"
  | "TOOLTIP"
  | "ANNOTATION"
  | "ANNOTATIONTEXT"
  | "STYLE"
  | "ALARM";

export type eventCallbacksArgs = {
  chartWrapper: GoogleChartWrapper;
  controlWrapper?: GoogleChartControl | undefined;
  props: ReactGoogleChartProps;
  google: GoogleViz;
  eventArgs: any;
};

export type eventSelectArgs = {
  chartWrapper: GoogleChartWrapper;
  chartData: any;
  isMobile: boolean;
  ticks: any[];
  handleClickPoint?: any;
  comments?: any;
  setShowModalComment?: (param: any) => void;
};
