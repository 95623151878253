import { IChart } from "../../../store/interfaces";

export type CascadeGraphProps = {
  chart: IChart;
  selectDate: number;
  selectedSerie?: string;
};

export type ChartData = {
  data: Array<Array<number>>;
  series: string[];
  date: number;
};

export function getCascadeLayout(
  colors: any,
  metricUnits: any,
  cascadeSize: any,
  rotation: any,
): Partial<Plotly.Layout> {
  const { width, height } = cascadeSize;

  return {
    legend: {
      x: 1.1,
      y: 0.5,
    },
    autosize: true,
    width,
    height,
    // title: title,
    margin: {
      t: 0,
      l: 0,
      b: 95,
      r: 0,
      pad: 0,
    },
    paper_bgcolor: "transparent",
    hoverlabel: {
      bordercolor: colors.cardHighlight,
      // bgcolor: colors.headerBackground,
      namelength: -1,
      font: {
        color: colors.textSecondary,
        family: "Work Sans",
        size: 16,
      },
    },
    font: {
      family: "Work Sans",
      color: colors.iconsSecondary,
      size: 12,
    },

    scene: {
      camera: {
        center: {
          x: 0,
          y: 0,
          z: 0,
        },
        eye: { x: rotation.x, y: rotation.y, z: rotation.z },
        up: { x: 0, y: 1, z: 0 },
      },

      xaxis: {
        title: `FREQUÊNCIA: ${metricUnits.x}`,
        showgrid: false,
        spikecolor: colors.ibbxOrange,
        spikethickness: 2,
        gridcolor: "red",
      },
      yaxis: {
        title: `AMPLITUDE: ${metricUnits.y}`,
        showgrid: false,
        spikecolor: colors.ibbxOrange,
        spikethickness: 6,
      },

      zaxis: {
        title: "",
        nticks: 10,
        spikecolor: colors.ibbxOrange,
        spikesides: false,
        spikethickness: 1,
      },
      aspectmode: "manual",
      aspectratio: { x: 1, y: 1, z: 1 },

      dragmode: "zoom",
    },
  };
}
