import {
  ActionTime,
  AggravatedOccurrence,
  Container,
  ContractContaner,
  IsOccurrenceClosed,
  OccurrenceItem,
  OccurrenceStatusLabel,
  RetinaInsigthsContainer,
  RiskStatusLabel,
  StatusOccurrence,
} from "./styles";

import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import { getActionTime } from "./types";
import { BiCheckDouble } from "react-icons/bi";
import { InsightButton } from "../InsightButton";
import { useMemo, useRef, useState } from "react";
import { InsightModal } from "../../modals/InsightModal";
import useOnClickOutSide from "../../hooks/useOnClickOutside";
import { useTranslation } from "react-i18next";
import useRetinaTranslation from "../../hooks/useRetinaTranslation";
import { ExpandedOccurrenceItem } from "./ExpandedOccurrenceItem";
import { BsChevronDown } from "react-icons/bs";
import { FaArrowUpShortWide } from "react-icons/fa6";
import { useSegment } from "../../hooks/useSegmentTrackEvent ";
import { extractProperty } from "../../utils/utilsFunctions";
import { useAppSelector } from "../../store/hooks";
import { PROFILES, userSelect } from "../../store/slices/userSlice";
import { isMobile } from "react-device-detect";

interface IOccurrenceAssetListItem {
  occurrence: any;
  setExpand: ({
    occurrenceId,
    show,
  }: {
    occurrenceId: number;
    show: boolean;
  }) => void;
  expanded: { occurrenceId: number; show: boolean };
  refresh?: () => void;
}

export function OccurrenceAssetListItem({
  occurrence,
  setExpand,
  expanded,
  refresh = () => null,
}: IOccurrenceAssetListItem) {
  const { handleTrackEvent } = useSegment();
  const user = useAppSelector(userSelect);

  const params = useParams();

  const companyLevel = params.companyId;
  const facilityLevel = params.facilityId;

  const favoriteCustomFields = useMemo(() => {
    const customFields = occurrence?.occurrenceCustomFields?.filter(
      (field: any) =>
        field.favorite === true &&
        (field.units.includes("all") ||
          (field.units.includes(occurrence?.facilityId) && facilityLevel)),
    );

    return customFields || [];
  }, [
    facilityLevel,
    occurrence?.facilityId,
    occurrence?.occurrenceCustomFields,
  ]);

  // const navigate = useNavigate();
  const { assetId, id } = occurrence;

  const { t } = useTranslation();
  const { translateDiagnostic } = useRetinaTranslation();

  const [showModal, setShowModal] = useState<boolean>(false);

  const dateFormat = "dd/MM/yyyy, HH:mm:ss";
  const getDate = new Date(occurrence.createdAt) || null;
  const occurrenceDate = getDate ? format(getDate, dateFormat) : "";

  const modalRef = useRef(null);
  const buttonRef = useRef(null);

  const userAdmin = [
    PROFILES.EXECUTIVO_MASTER,
    PROFILES.MESA_MASTER,
    PROFILES.MESA_ANALISTA,
    PROFILES.REPRESENTANTE,
  ].includes(user.profile);

  const getCategoryName = (categoryName: string) => {
    switch (categoryName) {
      case "SENSITIVE_INSPECTION":
        return `${t("Checklist de Inspeção Sensitiva")}`;
      case "MANUAL_COLLECT":
        return `${t("OccurrenceForm.Coleta Manual")}`;
      default:
        return "";
    }
  };

  useOnClickOutSide(modalRef, () => setShowModal(false), buttonRef);

  function getStatusLabel() {
    if (occurrence.occurrenceType === "CORRECTIVE") {
      return t("occurrenceAssetListItem.Avaria");
    }

    switch (occurrence.sysStatusId) {
      case 1:
        return "Normal";
      case 3:
      case 2:
        return t("occurrenceAssetListItem.Alerta");
      case 4:
        return t("occurrenceAssetListItem.Análise");
      case 6:
      case 5:
        return t("occurrenceAssetListItem.Risco");
      case 7:
        return t("OccurrenceHistory.Crítico");
    }

    return;
  }

  const translateEvidence: any = {
    Temperatura: t("occurrenceAssetListItem.Temperatura"),
    "Corrente Elétrica": t("occurrenceAssetListItem.Corrente Elétrica"),
    Vibração: t("occurrenceAssetListItem.Vibração"),
    "Vibração e Temperatura": t(
      "occurrenceAssetListItem.Vibração e Temperatura",
    ),
  };

  const getTranslatedEvidence = (evidence: any) => {
    if (evidence) {
      return translateEvidence[evidence || ""] || evidence;
    }
    return "";
  };

  const getActualDateTime = getActionTime(occurrence.dueDate).includes("-") ? (
    <ActionTime instant>
      <span>{t("occurrenceAssetListItem.Imediato")}</span>
    </ActionTime>
  ) : getActionTime(occurrence.dueDate).includes("Não Atribuído") ? (
    <ActionTime>
      <span>{t("occurrenceAssetListItem.Em Análise")}</span>
    </ActionTime>
  ) : (
    getActionTime(occurrence.dueDate)
  );

  const handleExpand = ({
    occurrenceId,
    e,
  }: {
    occurrenceId: number;
    e: any;
  }) => {
    e.preventDefault();

    if (occurrenceId === expanded.occurrenceId) {
      setExpand({ occurrenceId: occurrenceId, show: !expanded.show });
    } else {
      setExpand({ occurrenceId: occurrenceId, show: true });
    }

    handleTrackEvent({
      event: "TAB OCCURRENCE ITEM: Expandir Evidência",
    });
  };

  const getEvidenceCondition = (evidence: any) => {
    if (evidence?.metadata?.condition === "INCREASE") {
      return t("occurrenceAssetListItem.Aumento da");
    } else if (evidence?.metadata?.condition === "DECREASE") {
      return t("occurrenceAssetListItem.Redução da");
    } else {
      return "";
    }
  };

  const evidence = useMemo(() => {
    const evidences =
      (occurrence?.evidences ?? []).filter((item: any) => {
        return !!item?.measure;
      }) ?? [];
    const measures = extractProperty(evidences ?? [], "measure");

    if (!evidences.length) {
      return t("occurrenceAssetListItem.Anomalia");
    }

    if (measures.includes("Vibração") && measures.includes("Temperatura")) {
      return `${t(
        "occurrenceAssetListItem.Aumento da",
      )} ${getTranslatedEvidence("Vibração e Temperatura")}`;
    }

    return `${getEvidenceCondition(evidences[0])} ${getTranslatedEvidence(
      measures[0],
    )}`;
    // eslint-disable-next-line
  }, [occurrence.evidences]);

  return (
    <Container
      as={Link}
      isOccurrenceClosed={!!occurrence.closedAt}
      isExpanded={expanded?.occurrenceId === occurrence.id && expanded?.show}
      to={`/assets/${assetId}/occurrences/${id}`}
      onClick={(e) => {
        if (expanded?.occurrenceId === occurrence.id && expanded?.show) {
          e.preventDefault();
        }
      }}
    >
      <RiskStatusLabel>
        <OccurrenceItem
          occurrenceStatus
          isCritical={occurrence.sysStatusId}
          openOccurrence={!occurrence?.closedAt}
        >
          <StatusOccurrence>
            <OccurrenceStatusLabel
              sysStatusId={occurrence.sysStatusId}
              type={occurrence.occurrenceType}
            >
              {getStatusLabel()}
            </OccurrenceStatusLabel>
          </StatusOccurrence>
        </OccurrenceItem>
      </RiskStatusLabel>
      <table>
        <tr>
          {!isMobile && (
            <td className="occurrenceId">
              <OccurrenceItem key={id}>
                <span>{occurrence.id}</span>
              </OccurrenceItem>
            </td>
          )}
          {!isMobile &&
            companyLevel &&
            favoriteCustomFields &&
            occurrence?.occurrenceCustomFields && (
              <>
                {favoriteCustomFields.map((field: any) => {
                  return (
                    <td key={field.id} className="customField">
                      <OccurrenceItem key={field.id}>
                        <span style={{ minWidth: "60px", width: "100%" }}>
                          {occurrence?.extra?.occurrenceCustomFields?.find(
                            (item: any) => item.id === field.id,
                          )?.value || "-"}
                        </span>
                      </OccurrenceItem>
                    </td>
                  );
                })}
              </>
            )}
          <td className="occurrenceDate">
            <OccurrenceItem key={id}>
              <span>{occurrenceDate}</span>
            </OccurrenceItem>
          </td>
          <td className="assetName">
            <OccurrenceItem key={id}>
              <span>{occurrence.assetName} </span>
            </OccurrenceItem>
          </td>

          {!isMobile && (
            <>
              <td className="evidence">
                <OccurrenceItem
                  key={id}
                  // hideButton={occurrence.evidences?.[0] === null}
                  onClick={(e) =>
                    handleExpand({ occurrenceId: occurrence.id, e })
                  }
                  expandOccurrence={
                    expanded?.occurrenceId === occurrence.id && expanded?.show
                  }
                >
                  <button title="Visualização rápida das evidências">
                    <BsChevronDown />
                  </button>
                  <span>{evidence}</span>
                </OccurrenceItem>
              </td>
              <td className="diagnostic">
                <OccurrenceItem limitedSpanWidth>
                  <span>{translateDiagnostic(occurrence.diagnostic)}</span>
                  {occurrence.diagnostic && (
                    <RetinaInsigthsContainer ref={buttonRef}>
                      <InsightButton
                        assetOccurrence
                        show={
                          (occurrence.diagnostic && !occurrence.closedAt) ||
                          false
                        }
                        occurrenceId={occurrence.id}
                        handleClick={setShowModal}
                      />
                    </RetinaInsigthsContainer>
                  )}
                </OccurrenceItem>
              </td>
              <td className="timeForAction">
                <OccurrenceItem key={id}>
                  <span>{getActualDateTime}</span>
                </OccurrenceItem>
              </td>
            </>
          )}
          {!!occurrence.closedAt && (
            <IsOccurrenceClosed
              title={`${t("occurrenceAssetListItem.Ocorrência Fechada")}`}
            >
              <BiCheckDouble />
            </IsOccurrenceClosed>
          )}

          {occurrence.sysStatusId === 6 && !occurrence.closedAt && (
            <AggravatedOccurrence
              title={`${t("OccurrenceForm.title.Agravamento")}`}
            >
              <FaArrowUpShortWide />
            </AggravatedOccurrence>
          )}

          {occurrence?.category && !occurrence?.closedAt && userAdmin && (
            <ContractContaner
              categoryType={occurrence?.category}
              title={getCategoryName(occurrence?.category)}
            >
              <span>
                {occurrence?.category === "SENSITIVE_INSPECTION" ? "S" : "M"}
              </span>
            </ContractContaner>
          )}
        </tr>
        {expanded?.occurrenceId === occurrence.id &&
          expanded?.show === true && (
            <>
              <ExpandedOccurrenceItem
                evidences={occurrence.evidences}
                occurrence={occurrence}
                refresh={refresh}
              />
            </>
          )}
      </table>

      {showModal && (
        <InsightModal
          show={showModal}
          assetId={assetId}
          occurrenceId={occurrence.id}
          handleClose={setShowModal}
          assetType={occurrence.assetName.toString()}
          diagnostic={occurrence.diagnostic || ""}
        />
      )}
    </Container>
  );
}
