import styled from "styled-components";

export const BearingIconStyle = styled.svg`
  width: 20px;
  height: 20px;
  overflow: visible;
  path {
      fill: ${(props) => props.theme.colors.iconsSecondary};
    }
  :hover {
    fill: ${(props) => props.theme.colors.ibbxOrange};
    stroke: ${(props) => props.theme.colors.ibbxOrange};

    path {
      fill: ${(props) => props.theme.colors.ibbxOrange};
    }
  }
`;
