import styled from "styled-components";

export const RecallSelect = styled.div`
  .form-select {
    background-color: ${(props) => props.theme.colors.inputBlock};
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    color: ${(props) => props.theme.colors.iconsSecondary};
    font-size: 14px;

    & > option {
      padding: 0.5rem 0;
    }
  }
`;

export const Error = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  ${(props) => props.theme.fonts.secondary.H1SemiBold}
  color: ${(props) => props.theme.colors.alarmRisco};
  text-align: center;
`;
