import styled from "styled-components";

export const ContainerActivator = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  gap: 1rem;
  width: 100%;
  opacity: ${(props) => (props.disabled ? 0.2 : "")};

  & > div:first-child {
    width: 100%;
    input {
      ${(props) => props.theme.fonts.primary.H4Regular};
      width: 100%;
      min-height: 36px;
      margin-top: 4px;
      color: ${(props) => props.theme.colors.iconsPrimary};
      background: ${(props) => props.theme.colors.background};
      border: ${(props) => props.theme.colors.cardHighlight};
      border-radius: 4px;
      padding: 8px;
      border: 1px solid ${(props) => props.theme.colors.cardHighlight};

      &:hover {
        border: 1px solid ${(props) => props.theme.colors.IconsTools};
      }
    }
  }
`;

export const ActivatorFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  padding-right: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 767px) {
    margin: 1rem 0 0 0;
    padding: 0;
    justify-content: space-around;

    button {
      margin: 0;
    }
  }
`;
