import { useTranslation } from "react-i18next";
import { Modal } from "../../components/Modal";
import { ContainerConfirmModal } from "../ConfirmModal/styles";
import { ConfirmModalV2Props } from "./types";

export const ConfirmModalV2 = ({
  show,
  text,
  handleConfirm,
  indexNumber,
  handleClose,
}: ConfirmModalV2Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        handleClose={() => {
          handleClose();
        }}
        text={text}
        confirmLabel={`${t("commonText.confirm")}`}
        size={"sm"}
        handleConfirm={() => {
          handleConfirm(indexNumber);
        }}
      >
        <ContainerConfirmModal>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {text}
          </label>
        </ContainerConfirmModal>
      </Modal>
    </>
  );
};
