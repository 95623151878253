import { useEffect, useState } from "react";
import { FrameOccurrences } from "../../components/FrameOccurrences";
import {
  FrameBodyRightOccurrences,
  FrameBodyMainOccurrences,
  OccurrenceTableHeaderContainer,
} from "./styles";
import MarqueeDashboard from "./MarqueeDashboard";
import { isMobileOnly } from "react-device-detect";
import ReturnButton from "../../components/ReturnButton";
import useDashboard from "../../hooks/useDashboard";
import { OccurrenceAssetListItem } from "../../components/OccurrenceAssetListItem";
import { OccurrenceListRow } from "../../components/OccurrenceListTab/styles";
import useOccurrenceList from "../../hooks/useOccurrenceList";
import OccurrenceList from "../../components/OccurrenceList";
import OccurrenceForm from "../../modals/OccurrenceForm";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";

interface IExpanded {
  occurrenceId: number;
  show: boolean;
}
const Occurrences = () => {
  const { loadOccurrencesPage } = useDashboard();
  const [expanded, setExpanded] = useState<IExpanded>({
    occurrenceId: -1,
    show: false,
  });

  const { getRecords, handleGetRecords, pagination, processing, records } =
    useOccurrenceList();

  const { occurrenceRegister } = useAppSelector(appSelect);

  useEffect(() => {
    loadOccurrencesPage();
    // eslint-disable-next-line
  }, [location.search]);

  return (
    <FrameOccurrences scrollable>
      <FrameBodyMainOccurrences>
        {occurrenceRegister.show && (
          <OccurrenceForm show={occurrenceRegister.show} refresh={getRecords} />
        )}
        {isMobileOnly && <ReturnButton />}
        <FrameBodyRightOccurrences visible={true}>
          <MarqueeDashboard />
        </FrameBodyRightOccurrences>
        <OccurrenceList
          records={records}
          processing={processing}
          pagination={pagination}
          handleGetRecords={handleGetRecords}
          update={null}
        >
          <>
            <OccurrenceTableHeaderContainer>
              <OccurrenceList.HeaderTable showSidebar={false} />
            </OccurrenceTableHeaderContainer>
            <OccurrenceList.ListPageDesktop>
              <OccurrenceList.InfiniteScrollDesktop>
                {records.map((item: any) => (
                  <OccurrenceListRow key={item.id}>
                    <OccurrenceAssetListItem
                      key={item.id}
                      occurrence={item}
                      expanded={expanded}
                      setExpand={setExpanded}
                      refresh={getRecords}
                    />
                  </OccurrenceListRow>
                ))}
              </OccurrenceList.InfiniteScrollDesktop>
            </OccurrenceList.ListPageDesktop>
          </>
        </OccurrenceList>
      </FrameBodyMainOccurrences>
    </FrameOccurrences>
  );
};

export default Occurrences;
