import styled from "styled-components";
import lightTheme from "../../../../styles/themes/light";
import { ButtonNewUser, TableActionsRow } from "../../Users/styles";

export const UsersActionContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const UsersContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 767px) {
    height: 60vh;
  }
`;

export const ButtonAddUser = styled(ButtonNewUser)`
  width: 40px;
  ${(props) => props.theme.fonts.primary.H5SemiBold};
  position: absolute;
  right: 0;
  top: -45px;

  @keyframes button-on {
    0% {
      transform: rotateY(90deg);
      transform-origin: right;
      opacity: 0;
    }
    100% {
      transform: rotateY(0);
      transform-origin: right;
      opacity: 1;
    }
  }
  :hover {
    opacity: 1;
  }

  :hover:after {
    content: attr(data-translate);
    height: 40px;
    width: 160px;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.IconsTools};
    border-radius: 8px;
    color: ${(props) => props.theme.colors.cardHighlight};
    animation: button-on 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  }

  @media screen and (max-width: 767px) {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    position: absolute;
    z-index: 10;
    right: 3.65rem;
    top: auto;
    bottom: -1.75rem;

    svg {
      width: 3rem;
      height: auto;
    }
    :hover:after {
      content: none;
    }
  }
`;

export const TableWrapper = styled.div`
  margin-top: 10px;
  height: 100%;
  overflow: auto;
  
  .table {
    --bs-table-bg: ${props => props.theme.theme};
  }

  & > div {
    min-height: 240px;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => lightTheme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => lightTheme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => lightTheme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => lightTheme.colors.iconsSecondary};
  }

  & > div {
    tbody > tr > td {
      ${lightTheme.fonts.secondary.H1Regular};
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
    tbody > tr > td:last-child {
      width: 110px;
    }
  }

  @media screen and (max-width: 767px) {
    &::-webkit-scrollbar {
      display: none;
    }

    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;

    & > div {
      tbody > tr > td:last-child {
        width: 20px;
      }
    }
  }
`;

export const TableActionsRowRepresentatives = styled(TableActionsRow)``;

export const ColumnHeader = styled.div`
  margin: 8px 0 4px 0;
  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H5Bold};

  & > span {
    display: flex;
    align-items: center;
  }
`;

type UserStatusProps = {
  active?: boolean;
};

export const UserStatus = styled.div<UserStatusProps>`
  background-color: ${(props) =>
    props.active
      ? lightTheme.colors.statusNormal
      : lightTheme.colors.colorTools};
  border-radius: 28px;
  padding: 5px 10px;
  width: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    ${(props) => props.theme.fonts.secondary.H1Bold};
    text-transform: uppercase;
    color: #fff;
  }

  @media screen and (max-width: 767px) {
    width: 3rem;

    & > span {
      ${(props) => props.theme.fonts.secondary.H3Bold};
      text-transform: uppercase;
      color: #fff;
    }
  }
`;
