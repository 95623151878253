import { useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../components/Modal";
import {
  ExpandedEvidencePdfContainer,
  ExpandedEvidenceContainer,
  ExpandedChartContainer,
  ExpandedChartTitle,
  HeaderContainer,
  ExpandedEvidenceEmptyContainer,
  ModalContainer,
} from "./styles";
import { ExpandedEvidenceProps } from "./types";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "@kokarn/react-zoom-pan-pinch";
import { BsFiletypePdf } from "react-icons/bs";
import { useTheme } from "styled-components";
import { MdOutlineHideImage } from "react-icons/md";
import { formatDate } from "../../utils/utilsFunctions";
import useRetinaTranslation from "../../hooks/useRetinaTranslation";
import { isTablet } from "react-device-detect";

const ExpandedEvidence = ({
  show,
  handleClose,
  closeLabel,
  evidence,
}: ExpandedEvidenceProps) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  closeLabel = closeLabel || `${t("commonText.close")}`;
  const zoomRef = useRef<ReactZoomPanPinchRef>(null);

  const [scale, setScale] = useState(1);
  const { getGraphicLabel } = useRetinaTranslation();

  const selectedResourceUrl = useMemo(() => {
    if (evidence?.chartDark && theme === "dark") {
      return evidence?.chartDark;
    }
    return evidence?.url;
  }, [evidence, theme]);

  const title = useMemo(() => {
    const { metadata } = evidence;
    const { condition } = metadata ?? {};
    const conditionKey = condition === "DECREASE" ? "Redução" : "Aumento";
    const conditionLabel = t(
      `occurrencePanel.OccurrenceEvidence.${conditionKey}`,
    );

    let date = formatDate(+metadata?.date, { type: "FULL" });
    const graphic = getGraphicLabel(metadata?.chartLabel ?? "");
    if (evidence.openedByAI) {
      return `Anomalia ${graphic} ${date}`;
    } else if (
      evidence.type === "CHART" &&
      metadata.alarmType === "VARIATION"
    ) {
      date = formatDate(+metadata?.maxDate, { type: "FULL" });
      return `Variação ${metadata?.periodName} ${graphic} ${date}`;
    } else if (evidence.type === "CHART") {
      const labelOf = t("occurrencePanel.OccurrenceEvidence.da");
      return `${conditionLabel} ${labelOf} ${graphic} ${date}`;
    }

    return evidence.label;
    // eslint-disable-next-line
  }, [evidence]);

  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleConfirm={handleClose}
      hideCancelButton
      confirmLabel={closeLabel}
      size={isTablet ? "lg" : "xl"}
    >
      <ModalContainer>
        <ExpandedChartTitle>
          <HeaderContainer>
            <div>{title}</div>
          </HeaderContainer>
        </ExpandedChartTitle>
        <ExpandedChartContainer>
          <div>
            <ExpandedEvidenceContainer isPdf={evidence?.type === "PDF"}>
              {evidence?.type === "PDF" && (
                <div className="pdf">
                  <label>{evidence?.label}</label>
                  <ExpandedEvidencePdfContainer
                    onClick={() => window.open(evidence.url, "_blank")}
                  >
                    <BsFiletypePdf />
                    <span>{t("attachmentsEvidenceModal.clickToView")}</span>
                  </ExpandedEvidencePdfContainer>
                </div>
              )}
              {evidence?.type !== "PDF" && (
                <div>
                  <TransformWrapper
                    ref={zoomRef}
                    initialScale={1}
                    onZoom={(ref: ReactZoomPanPinchRef) =>
                      setScale(ref.state.scale)
                    }
                    doubleClick={{ disabled: true }}
                    panning={{
                      disabled: scale === 1,
                      velocityDisabled: true,
                    }}
                  >
                    <TransformComponent>
                      <img src={selectedResourceUrl} alt={"foto-equipamento"} />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              )}
              {!evidence && (
                <ExpandedEvidenceEmptyContainer>
                  <MdOutlineHideImage size={60} />
                  <span>{`${t("attachmentsEvidenceModal.noEvidence")}`}</span>
                </ExpandedEvidenceEmptyContainer>
              )}
            </ExpandedEvidenceContainer>
          </div>
        </ExpandedChartContainer>
      </ModalContainer>
    </Modal>
  );
};

export default ExpandedEvidence;
