import styled from "styled-components";

export const Container = styled.div``;

export const SidebarBoltList = styled.div`
  overflow-y: auto !important;
  overflow-x: hidden !important;
  margin-top: 10px !important;
  min-height: 200px;
  height: 100%;
  display: flex !important;
  flex-direction: column !important;
  gap: 10px;

  & > button:last-child {
    margin-bottom: 18px;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media screen and (max-width: 767px) {
    & > button:last-child {
      margin-bottom: 0px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    overflow-y: auto;
    scrollbar-width: none;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
  }
  @media screen and (min-width: 767px) and (max-width: 1280px) {


    
    & > a:last-child {
      margin-bottom: 8rem;

    }
  }

`;

export const EmptyBolts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  padding-top: 20px;

  svg {
    margin-bottom: 12px;
    font-size: 3rem;
  }

  @media screen and (max-width: 767px) {
    svg {
      margin-bottom: 0.5rem;
      font-size: 2rem;
    }
  }
`;
