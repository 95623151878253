import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import {
  GeneralInfo,
  InfoContainer,
  EvidenceListCheckContainer,
  OccurrenceEvidencesGroupContainer,
  OccurrenceEvidencesGroupHeader,
  OccurrenceEvidencesGroupItems,
  OccurrencePanelContainer,
  PrimaryTitle,
  SecondaryTitle,
  EvidenceContainer,
  DiagnosticContainer,
  DiagnosticTable,
  StandardContainer,
  HistorycHeader,
  CollapseEvidences,
  CollapseHistory,
  StandardButtonEvidence,
  NoHistory,
  GeneralInfoMobile,
  InfoContainerMobile,
  EvidenceMenuMobile,
  GeneralInfoHeader,
  CollapseGeneralInfo,
  StatusFlag,
  ExtraInfo,
  RestoreEvidence,
  EmptyEvidenceContainer,
} from "./styles";
import OccurrencePanelHeader from "./OccurrencePanelHeader";
import OccurrenceEvidence from "./OccurrenceEvidence";
import {
  MdClose,
  MdDeleteForever,
  MdDeselect,
  MdEdit,
  MdOpenInNew,
  MdOutlineSelectAll,
} from "react-icons/md";
import { formatDate } from "../../utils/utilsFunctions";
import { OccurrencePanelProps, getActionTime } from "./types";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { success } from "../Toast";
import { HiCheck } from "react-icons/hi";
import OccurrenceHistory from "./OccurrenceHistory";
import { AddEvidenceButton } from "./AddEvidenceButton";
import { Collapse } from "react-bootstrap";
import { ConfirmModalV2 } from "../../modals/ConfirmModalV2";
import { OccurrenceHistoryContainer } from "./OccurrenceHistory/styles";
import { PROFILES, userSelect } from "../../store/slices/userSlice";
import { useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";
import useRetinaTranslation from "../../hooks/useRetinaTranslation";
import { isMobileOnly } from "react-device-detect";
import { Link, useParams } from "react-router-dom";
import ExpandedEvidence from "../ExpandedEvidence";
import { useSegment } from "../../hooks/useSegmentTrackEvent ";
import { FaTrashRestore } from "react-icons/fa";
import { TbTrash, TbTrashOff } from "react-icons/tb";

interface CollapseState {
  diagnostic: boolean;
  evidences: boolean;
  historic: boolean;
  generalInfo: boolean;
}

export default function OccurrencePanel({
  asset,
  occurrence,
  diagnostics,
  refresh = () => null,
}: OccurrencePanelProps) {
  const { handleTrackEvent } = useSegment();

  const { companyId, facilityId, id } = occurrence;

  const { assetId, occurrenceId } = useParams();

  const { request } = useApi({
    path: `/assets/${assetId}/occurrences/${occurrenceId}`,
  });
  const user = useAppSelector(userSelect);

  const { t } = useTranslation();

  const { translateDiagnostic } = useRetinaTranslation();

  const [evidences, setEvidences] = useState<any[]>([]);
  const [expandedEvidence, setExpandedEvidence] = useState<any>(null);
  const [openEditEvidence, setOpenEditEvidence] = useState(false);
  const [selectedEvidence, setSelectedEvidence] = useState<number[]>([]);
  const [selectionEvidence, setSelectionEvidence] = useState(false);
  const [openCollapse, setOpenCollapse] = useState<CollapseState>({
    diagnostic: true,
    evidences: true,
    historic: true,
    generalInfo: true,
  });
  const [deleteSelectedEvidence, setDeleteSelectedEvidence] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);

  const userAdmin = [
    PROFILES.EXECUTIVO_MASTER,
    PROFILES.MESA_MASTER,
    PROFILES.MESA_ANALISTA,
    PROFILES.REPRESENTANTE,
  ].includes(user.profile);

  const userClient = [
    PROFILES.CLIENTE_COMUM,
    PROFILES.CLIENTE_MASTER,
    PROFILES.CLIENTE_MASTER_VIEW,
  ].includes(user.profile);

  const isClientView = [PROFILES.CLIENTE_MASTER_VIEW].includes(user.profile);

  const handleCollapseItem = (itemName: keyof CollapseState) => {
    setOpenCollapse((prevState) => ({
      ...prevState,
      [itemName]: !prevState[itemName],
    }));
  };

  function loadEvidences() {
    const pathParameters = "evidences";
    const queryStringParameters = { showDeleted };
    request({ method: "get", pathParameters, queryStringParameters }).then(
      (response: any) => {
        if (showDeleted) {
          response = response.filter(({ deletedAt }: any) => !!deletedAt);
        }
        setEvidences(response);
      },
    );
  }

  const deletedEvidences = evidences.filter(
    ({ deletedAt }: any) => !!deletedAt,
  );

  useEffect(() => {
    if (occurrence.id) {
      loadEvidences();
    }
    // eslint-disable-next-line
  }, [occurrence.id, occurrence.sysStatusId, showDeleted]);

  useEffect(() => {
    if (showDeleted && deletedEvidences.length === 0) {
      setShowDeleted(!showDeleted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedEvidences.length === 0]);

  const deleteSelectedEvidences = () => {
    const selectedEvidencesIds = selectedEvidence.map(
      (index: any) => evidences[index].id,
    );
    request({
      method: "del",
      pathParameters: `/evidences`,
      body: { ids: selectedEvidencesIds },
    }).then(() => {
      loadEvidences();
      success(
        `${selectedEvidence.length} ${
          selectedEvidence.length > 1
            ? t("occurrencePanel.Evidências excluídas com sucesso!")
            : t("occurrencePanel.Evidência excluída com sucesso!")
        }`,
      );
      setDeleteSelectedEvidence(false);
      refresh();
    });
    setSelectedEvidence([]);
  };

  function getStatusLabel() {
    if (occurrence.type === "CORRECTIVE") {
      return "Avaria";
    }

    switch (occurrence.sysStatusId) {
      case 7:
        return t("OccurrenceHistory.Crítico");
      case 6:
      case 5:
        return t("occurrenceAssetListItem.Risco");
      case 4:
        return t("occurrenceAssetListItem.Análise");
      case 3:
      case 2:
        return t("occurrenceAssetListItem.Alerta");
      case 1:
        return "Normal";
    }
  }

  const handleSelection = () => {
    setSelectionEvidence(!selectionEvidence);

    setSelectedEvidence([]);
  };

  const handleSelectedEvidence = (evidenceIndex: number) => {
    if (selectedEvidence.includes(evidenceIndex)) {
      setSelectedEvidence((prevSelected) =>
        prevSelected.filter((index) => index !== evidenceIndex),
      );
    } else {
      setSelectedEvidence((prevSelected) => [...prevSelected, evidenceIndex]);
    }
  };

  const disableButton = selectedEvidence.length === 0;

  const renderEvidenceMenu = () => {
    return (
      <div>
        {!showDeleted && (
          <>
            {!selectionEvidence && (
              <AddEvidenceButton
                refresh={loadEvidences}
                occurrence={occurrence}
              />
            )}
            <StandardButtonEvidence onClick={handleSelection} selectEvidence>
              {!selectionEvidence ? (
                <>
                  {t("occurrencePanel.Selecionar")} <MdOutlineSelectAll />
                </>
              ) : (
                <>
                  {t("occurrencePanel.Cancelar")} <MdDeselect />
                </>
              )}
            </StandardButtonEvidence>
          </>
        )}

        {selectionEvidence && (
          <div>
            {" "}
            <span>{selectedEvidence.length} </span>
            <span>
              {selectedEvidence.length === 1
                ? t("occurrencePanel.Evidência selecionada")
                : t("occurrencePanel.Evidências selecionadas")}
            </span>
          </div>
        )}

        {selectionEvidence && (
          <StandardButtonEvidence
            disable={!!disableButton}
            onClick={() => {
              setDeleteSelectedEvidence(true);
              handleTrackEvent({
                event: "PAINEL OCORRÊNCIA - Excluir Evidência",
              });
            }}
          >
            {t("occurrencePanel.Excluir")} <MdDeleteForever />
          </StandardButtonEvidence>
        )}

        {user.admin && !selectionEvidence && (
          <RestoreEvidence
            onClick={() => {
              setShowDeleted(!showDeleted);
              setSelectionEvidence(false);
            }}
            title="Restarar evidências excluídas"
          >
            {showDeleted ? (
              <>
                <span>{t("occurrencePanel.Cancelar")}</span>
                <TbTrashOff />
                {deletedEvidences.length >= 1 && (
                  <span>({deletedEvidences.length})</span>
                )}
              </>
            ) : (
              <>
                <span>{t("occurrencePanel.OccurrenceEvidence.Restaurar")}</span>
                <FaTrashRestore />
              </>
            )}
          </RestoreEvidence>
        )}
      </div>
    );
  };

  const renderButtonMenuEvidence = () => {
    return (
      <>
        {openEditEvidence ? (
          <button
            onClick={() => setOpenEditEvidence(!openEditEvidence)}
            title={`${t("occurrencePanel.Fechar")}`}
          >
            <MdClose />
          </button>
        ) : (
          <button
            onClick={() => setOpenEditEvidence(!openEditEvidence)}
            title={`${t("occurrencePanel.Editar Evidências")}`}
          >
            <MdEdit />
          </button>
        )}
      </>
    );
  };

  const openMenuEvidences = () => {
    if (userAdmin && openCollapse.evidences) {
      return renderEvidenceMenu();
    } else if (userClient && openCollapse.evidences) {
      return renderButtonMenuEvidence();
    }
  };

  function canUserSelect(evidence: any) {
    if (user.admin) {
      return true;
    }
    if (evidence.createdBy === user.id) {
      return true;
    }
    return false;
  }

  return (
    <OccurrencePanelContainer>
      <OccurrencePanelHeader
        occurrence={occurrence}
        evidences={evidences}
        asset={asset}
        diagnostics={diagnostics}
        occurrenNumber={occurrence.id}
        refresh={refresh}
        categoryName={occurrence?.category}
        statusId={occurrence?.sysStatusId}
      />

      <div>
        <StandardContainer>
          <GeneralInfoHeader>
            {t("occurrencePanel.Informações Gerais")}

            <StatusFlag
              showStatus={openCollapse.generalInfo === false}
              statusRisk={occurrence.sysStatusId}
              type={occurrence.type}
            >
              <span>{getStatusLabel()}</span>
            </StatusFlag>
          </GeneralInfoHeader>
          <CollapseGeneralInfo
            showStatus={openCollapse.generalInfo === false}
            title={`${
              openCollapse.generalInfo
                ? t("occurrencePanel.Esconder")
                : t("occurrencePanel.Mostrar")
            }`}
          >
            {openCollapse.generalInfo ? (
              <IoIosArrowDropup
                aria-controls="collapse"
                onClick={() => handleCollapseItem("generalInfo")}
              />
            ) : (
              <IoIosArrowDropdown
                aria-controls="collapse"
                onClick={() => handleCollapseItem("generalInfo")}
              />
            )}
          </CollapseGeneralInfo>
          <Collapse in={openCollapse.generalInfo}>
            <GeneralInfo id="collapse">
              {isMobileOnly && (
                <GeneralInfoMobile>
                  <InfoContainerMobile>
                    <SecondaryTitle>
                      {t("occurrencePanel.Status")}
                    </SecondaryTitle>
                    <span>
                      {occurrence?.deletedAt
                        ? t("occurrencePanel.Excluida")
                        : occurrence?.closedAt
                        ? t("occurrencePanel.Fechada")
                        : t("occurrencePanel.Aberta")}
                    </span>
                  </InfoContainerMobile>
                  <InfoContainerMobile
                    statusRisk={occurrence.sysStatusId}
                    type={occurrence.type}
                  >
                    <SecondaryTitle statusRisk>
                      {t("occurrencePanel.Criticidade")}
                    </SecondaryTitle>
                    <span>{getStatusLabel()}</span>
                  </InfoContainerMobile>
                </GeneralInfoMobile>
              )}
              <div>
                <InfoContainer>
                  <SecondaryTitle>
                    {t("occurrencePanel.Ativo")}
                    <div style={{ cursor: "pointer" }}>
                      <Link
                        to={`/companies/${companyId}/facilities/${facilityId}/assets/${assetId}`}
                        target="_blank"
                      >
                        <MdOpenInNew size={12} />
                      </Link>
                    </div>
                  </SecondaryTitle>
                  <span style={{ display: "flex", gap: "8px" }}>
                    <Link
                      to={`/companies/${companyId}/facilities/${facilityId}/assets/${assetId}`}
                      target="_blank"
                    >
                      {asset?.name}
                    </Link>
                  </span>
                </InfoContainer>
                <InfoContainer>
                  <SecondaryTitle>
                    {t("occurrencePanel.Empresa")}
                  </SecondaryTitle>
                  <span>{asset?.companyName}</span>
                </InfoContainer>
                <InfoContainer>
                  <SecondaryTitle>
                    {t("occurrencePanel.Unidade")}
                  </SecondaryTitle>
                  <span>{asset?.facilityName}</span>
                </InfoContainer>
                <InfoContainer className="hourAndAction">
                  <SecondaryTitle>
                    {t("occurrencePanel.Data de Abertura")}
                  </SecondaryTitle>
                  <span>{formatDate(occurrence.createdAt)}</span>
                </InfoContainer>
                <InfoContainer className="hourAndAction">
                  <SecondaryTitle>
                    {t("occurrencePanel.Tempo para Ação")}
                  </SecondaryTitle>
                  <span>
                    {getActionTime(occurrence.dueDate, occurrence.createdAt)}
                  </span>
                </InfoContainer>
                {!isMobileOnly && (
                  <>
                    <InfoContainer>
                      <SecondaryTitle>
                        {t("occurrencePanel.Status")}
                      </SecondaryTitle>
                      <span>
                        {occurrence?.deletedAt
                          ? t("occurrencePanel.Excluida")
                          : occurrence?.closedAt
                          ? t("occurrencePanel.Fechada")
                          : t("occurrencePanel.Aberta")}
                      </span>
                    </InfoContainer>

                    <InfoContainer
                      statusRisk={occurrence.sysStatusId}
                      type={occurrence.type}
                    >
                      <SecondaryTitle statusRisk>
                        {t("occurrencePanel.Criticidade")}
                      </SecondaryTitle>
                      <span>{getStatusLabel()}</span>
                    </InfoContainer>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1px",
                  flexDirection: "column",
                }}
              >
                {(!!asset?.info?.filter((item: any) => item.value !== undefined)
                  ?.length ||
                  occurrence?.extra?.occurrenceCustomFields) && (
                  <ExtraInfo>
                    <PrimaryTitle>
                      {t("modalReport.PDFReport.extraInformation")}
                    </PrimaryTitle>
                  </ExtraInfo>
                )}

                {!!asset?.info?.filter((item: any) => item.value !== undefined)
                  ?.length && (
                  <ExtraInfo>
                    <PrimaryTitle>{t("commonText.equipment")}</PrimaryTitle>
                    <div>
                      {asset?.info?.map((item: any, index: number) => (
                        <InfoContainer key={index} extraInfo>
                          <span>{item.key}:</span>
                          <span>{item.value}</span>
                        </InfoContainer>
                      ))}
                    </div>
                  </ExtraInfo>
                )}

                {occurrence?.occurrenceCustomFields &&
                  occurrence?.extra?.occurrenceCustomFields && (
                    <ExtraInfo>
                      <PrimaryTitle>{t("commonText.occurrence")}</PrimaryTitle>
                      <div>
                        {occurrence?.occurrenceCustomFields
                          .filter(
                            (field: any) =>
                              field.units?.includes("all") ||
                              field.units?.includes(facilityId),
                          )
                          ?.map((item: any, index: number) => {
                            const value =
                              occurrence?.extra?.occurrenceCustomFields?.find(
                                (element: any) => element.id === item.id,
                              )?.value;

                            return (
                              <InfoContainer key={index} extraInfo>
                                <span>{item.key}:</span>
                                <span>{value}</span>
                              </InfoContainer>
                            );
                          })}
                      </div>
                    </ExtraInfo>
                  )}
              </div>

              <DiagnosticContainer>
                <DiagnosticTable>
                  <thead>
                    <tr>
                      <th>
                        <span>{t("occurrencePanel.Diagnóstico")}</span>{" "}
                      </th>
                      <th>
                        <span>{t("occurrencePanel.Recomendação")}</span>{" "}
                      </th>
                      <th>
                        <span>{t("occurrencePanel.Comentário")}</span>{" "}
                      </th>
                      {/* <th>
                      <span></span>
                    </th> */}
                    </tr>
                  </thead>
                  {occurrence.diagnostics &&
                    occurrence.diagnostics[0] !== null && (
                      <tbody>
                        {occurrence.diagnostics?.map(
                          (item: any, index: number) => (
                            <tr key={index}>
                              <td>
                                <span>
                                  {translateDiagnostic(item?.diagnostic ?? "")}
                                </span>{" "}
                              </td>
                              <td>
                                <span> {item?.recommendation ?? ""}</span>{" "}
                              </td>
                              <td>
                                <span> {item?.comments ?? ""}</span>{" "}
                              </td>
                              {/* <td>
                              {item?.diagnostic && (
                                <DeleteEvidence
                                  disable={!!occurrence.closedAt}
                                  onClick={() => deleteDiagnostic(item)}
                                  title={`t("occurrencePanel.Excluir o diagóstico") ${item?.diagnostic}`}
                                >
                                  <IoMdTrash />
                                </DeleteEvidence>
                              )}
                            </td> */}
                            </tr>
                          ),
                        )}
                      </tbody>
                    )}
                </DiagnosticTable>

                {occurrence.diagnostics &&
                  occurrence.diagnostics.length === 0 && (
                    <NoHistory>
                      <span>
                        {t("OccurrenceHistory.Sem histórico até o momento.")}
                      </span>
                    </NoHistory>
                  )}
              </DiagnosticContainer>
            </GeneralInfo>
          </Collapse>
        </StandardContainer>

        <StandardContainer>
          <OccurrenceEvidencesGroupHeader>
            <span>
              {t("occurrencePanel.Evidências")} {showDeleted && "excluídas"}
            </span>
            {!occurrence?.deletedAt && (
              <>
                {isMobileOnly && <div>{renderButtonMenuEvidence()}</div>}
                {!isClientView && !isMobileOnly && (
                  <div>
                    {openEditEvidence && renderEvidenceMenu()}
                    {openMenuEvidences()}
                  </div>
                )}
              </>
            )}
          </OccurrenceEvidencesGroupHeader>
          {isMobileOnly && (
            <EvidenceMenuMobile openEditEvidence={openEditEvidence}>
              {renderEvidenceMenu()}
            </EvidenceMenuMobile>
          )}
          <CollapseEvidences
            title={`${
              openCollapse.evidences
                ? t("occurrencePanel.Esconder")
                : t("occurrencePanel.Mostrar")
            }`}
          >
            {openCollapse.evidences ? (
              <IoIosArrowDropup
                aria-controls="collapse"
                onClick={() => {
                  handleCollapseItem("evidences");
                  userClient && setOpenEditEvidence(false);
                }}
              />
            ) : (
              <IoIosArrowDropdown
                aria-controls="collapse"
                onClick={() => handleCollapseItem("evidences")}
              />
            )}
          </CollapseEvidences>
          <Collapse in={openCollapse.evidences}>
            <OccurrenceEvidencesGroupContainer id="collapse">
              <OccurrenceEvidencesGroupItems>
                {(evidences ?? []).map(
                  (evidence: any, evidenceIndex: number) => (
                    <EvidenceContainer key={evidenceIndex}>
                      {selectionEvidence && (
                        <EvidenceListCheckContainer
                          disabled={!canUserSelect(evidence)}
                          checked={selectedEvidence.includes(evidenceIndex)}
                          onClick={() =>
                            canUserSelect(evidence) &&
                            handleSelectedEvidence(evidenceIndex)
                          }
                        >
                          <button>
                            <HiCheck />
                          </button>
                        </EvidenceListCheckContainer>
                      )}
                      <OccurrenceEvidence
                        disable={!!occurrence?.deletedAt}
                        evidence={evidence}
                        refresh={() =>
                          Promise.all([refresh(), loadEvidences()])
                        }
                        positionName={evidence?.position}
                        setExpandedEvidence={setExpandedEvidence}
                      />
                    </EvidenceContainer>
                  ),
                )}
              </OccurrenceEvidencesGroupItems>
              {showDeleted && !deletedEvidences && (
                <EmptyEvidenceContainer>
                  <TbTrash />
                  <span>Não há evidencias exluídas</span>
                </EmptyEvidenceContainer>
              )}
            </OccurrenceEvidencesGroupContainer>
          </Collapse>
        </StandardContainer>
        <StandardContainer>
          <HistorycHeader>
            <span>{t("occurrencePanel.Histórico")}</span>
          </HistorycHeader>
          <CollapseHistory
            title={`${
              openCollapse.historic
                ? t("occurrencePanel.Esconder")
                : t("occurrencePanel.Mostrar")
            }`}
          >
            {openCollapse.historic ? (
              <IoIosArrowDropup
                aria-controls="collapse"
                onClick={() => handleCollapseItem("historic")}
              />
            ) : (
              <IoIosArrowDropdown
                aria-controls="collapse"
                onClick={() => handleCollapseItem("historic")}
              />
            )}
          </CollapseHistory>
          <Collapse in={openCollapse.historic}>
            <OccurrenceHistoryContainer id="collapse">
              <OccurrenceHistory occurrence={occurrence} refresh={refresh} />
            </OccurrenceHistoryContainer>
          </Collapse>
        </StandardContainer>
      </div>
      {deleteSelectedEvidence && (
        <ConfirmModalV2
          text={`${
            selectedEvidence.length > 1
              ? t(
                  "occurrencePanel.Tem certeza que deseja excluir as evidências selecionadas?",
                )
              : t(
                  "occurrencePanel.Tem certeza que deseja excluir esta evidência?",
                )
          }`}
          show={deleteSelectedEvidence}
          handleClose={() => setDeleteSelectedEvidence(false)}
          handleConfirm={deleteSelectedEvidences}
        />
      )}
      {!!expandedEvidence && (
        <ExpandedEvidence
          evidence={expandedEvidence}
          handleClose={() => setExpandedEvidence(null)}
          show={!!expandedEvidence}
        />
      )}
    </OccurrencePanelContainer>
  );
}
