import { useMemo } from "react";
import CountUp from "react-countup";

import { useAppSelector } from "../../store/hooks";
import {
  CardStatusIoTContainer,
  CardIoTConnectivityContainer,
  DoughnutChartStatusIot,
  DoughnutHoleStatusIot,
} from "./styles";

import { useTranslation } from "react-i18next";
import { dashboardSelect } from "../../store/slices/dashboardSlice";

const CardIoTConnectivity = ({ id }: any) => {
  const { t } = useTranslation();
  const dashboard = useAppSelector(dashboardSelect);

  const { connectedPositions, activatedPositions } = useMemo(() => {
    return (
      dashboard.iotConnectivity?.data || {
        connectedPositions: 0,
        activatedPositions: 0,
      }
    );

    // eslint-disable-next-line
  }, [dashboard.iotConnectivity]);

  return (
    <CardStatusIoTContainer>
      <span>{t("cards.iotConnectivity.title")}</span>
      <CardIoTConnectivityContainer>
        <div>
          <DoughnutChartStatusIot
            connectivity={connectedPositions / activatedPositions}
          >
            <DoughnutHoleStatusIot>
              <span>
                <CountUp
                  start={
                    Math.floor(
                      (connectedPositions / activatedPositions) * 100,
                    ) || 0
                  }
                  end={Math.floor(
                    (connectedPositions / activatedPositions) * 100,
                  )}
                  duration={0.8}
                  separator="."
                />
                %
              </span>
            </DoughnutHoleStatusIot>
          </DoughnutChartStatusIot>
        </div>
        <div>
          <div>
            <span>
              <CountUp
                start={activatedPositions || 0}
                end={activatedPositions}
                duration={0.8}
                separator={"."}
              />
            </span>
            <label>{t("cards.iotConnectivity.activated")}</label>
          </div>
          <div>
            <span>
              <CountUp
                start={connectedPositions || 0}
                end={connectedPositions}
                duration={0.8}
                separator={"."}
              />
            </span>
            <label>{t("cards.iotConnectivity.connected")}</label>
          </div>
        </div>
      </CardIoTConnectivityContainer>
    </CardStatusIoTContainer>
  );
};

export default CardIoTConnectivity;
