import styled from "styled-components";

export const Container = styled.div`
  padding: 10px 10px;
`;

export const Title = styled.label`
  ${(props) => props.theme.fonts.primary.H1Semibold};
`;

export const CardsContainer = styled.div`
  ${(props) => props.theme.fonts.primary.H4Regular};

  font-size: 1rem;
  margin-top: 25px;

  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.6rem;
    padding: 6px;

    & > label {
      display: flex;
      gap: 8px;
      user-select: none;
      cursor: pointer;
    }
  }
`;
export const WarningLabel = styled.footer<{ error?: boolean }>`
  ${(props) => props.theme.fonts.primary.H4Regular};
  color: ${(props) =>
    props.error ? "red" : props.theme.colors.iconsSecondary};
  margin-top: 30px;
`;
