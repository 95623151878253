import { useRef } from "react";
import Resizer from "react-image-file-resizer";
import { ImFileEmpty } from "react-icons/im";

import { ImageUploadFormProps } from "./types";
import { ButtonSelectFile, Container, ImageContainer } from "./styles";
import { useTranslation } from "react-i18next";

export function ImageUploadForm({
  handleChangeImage,
  image,
  placeholderEmpty,
  placeholderFilled,
}: ImageUploadFormProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleChange = function (event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    if (!event.target || !event.target.files || !event.target.files[0]) {
      return;
    }

    try {
      Resizer.imageFileResizer(
        event.target.files[0],
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          handleChangeImage(uri as string);
        },
        "base64",
        200,
        200,
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container id="form-file-upload">
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        onChange={handleChange}
      />
      <ImageContainer>
        <ButtonSelectFile
          className="upload-button"
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.click();
            }
          }}
        >
          {image ? (
            <>
              <img src={image} alt="imagem" />
              <span>
                {placeholderFilled ||
                  `${t("ImageUploadForm.placeholderFilled")}`}
              </span>
            </>
          ) : (
            <>
              <ImFileEmpty size={60} />
              <span>
                {placeholderEmpty || `${t("ImageUploadForm.placeholderEmpty")}`}
              </span>
            </>
          )}
        </ButtonSelectFile>
      </ImageContainer>
    </Container>
  );
}
