import { useTranslation } from "react-i18next";
import { Modal } from "../../components/Modal";

import { Container } from "./styles";

import { DecisionModalProps } from "./types";

const DecisionModal = ({
  show,
  onConfirm,
  onCalcel,
  text,
  confirmLabel,
  cancelLabel,
}: DecisionModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      handleClose={onCalcel}
      handleConfirm={onConfirm}
      size="sm"
      confirmLabel={confirmLabel || "OK"}
      cancelLabel={cancelLabel || `${t("DecisionModal.DecisionModal")}`}
    >
      <Container>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {text}
        </label>
      </Container>
    </Modal>
  );
};

export default DecisionModal;
