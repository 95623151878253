import { Buffer } from "buffer";
import { transparentLoginUrls } from "../../../../utils/transparentLoginUrls";
import { transparentAuthenticate } from "../../../../utils/transparentAuthenticate";
import { IRequest } from "../../../../hooks/useApi";

export const checkCredentials = (
  values: any,
  request: ({ method, pathParameters, body }: IRequest) => Promise<any>,
) => {
  const newValues = values.map((tab: any) => {
    const newTab = { ...tab };

    if (
      tab.url &&
      transparentLoginUrls
        .map((transparentUrl) => transparentUrl.replace(/\/$/, ""))
        .some((normalizedTransparentUrl) =>
          normalizedTransparentUrl.includes(tab.url.replace(/\/$/, "")),
        )
    ) {
      const isTextEncoded = (text: string) => {
        try {
          const decodedText = Buffer.from(text, "base64").toString();
          const isPrintable = /^[\x20-\x7E]*$/.test(decodedText);
          return decodedText !== text && isPrintable;
        } catch (error) {
          return false;
        }
      };

      if (tab.credentials && tab.credentials.password) {
        let iframe = document.querySelector(
          'iframe[title="Bi"]',
        ) as HTMLIFrameElement;

        let credentials = tab.credentials;

        const encodedPassword = tab.credentials.password;

        if (
          !isTextEncoded(tab.credentials.password) &&
          tab.url.includes("automate")
        ) {
          newTab.credentials.password =
            Buffer.from(encodedPassword).toString("base64");

          credentials.password =
            Buffer.from(encodedPassword).toString("base64");
        }

        if (iframe) {
          if (iframe.src.includes(tab.url)) {
            transparentAuthenticate({
              email: credentials.email,
              password: credentials.password,
              url: tab.url,
              setAnalyticsUrl: (url: string) => {
                iframe.src = url;
              },
              request,
            });
          }
        }
      }
    }

    return newTab;
  });

  return { newValues };
};
