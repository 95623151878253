import { BsLightbulbFill } from "react-icons/bs";
import { IoIosPulse } from "react-icons/io";
import {
  MdBolt,
  MdCenterFocusWeak,
  MdDeviceThermostat,
  MdFiberNew,
} from "react-icons/md";
import { SensorIconProps } from "./types";

import { ReactComponent as PressureIco } from "../../images/pressure.svg";
import { ReactComponent as FlowRateIcon } from "../../images/flow.svg";
import { ReactComponent as AquaLevelIcon } from "../../images/level.svg";

import RS485ModBus from "../../images/modBus.svg";

import { IconImage } from "./styles";

export const SensorIcon = ({ id, ...rest }: SensorIconProps) => {
  const getSensorIcon = (sensorTypeId: number) => {
    switch (sensorTypeId) {
      case 9:
        return <MdCenterFocusWeak {...rest} />;
      case 8:
        return <IconImage src={RS485ModBus} alt="" {...rest} />;
      case 7:
        return <AquaLevelIcon {...rest} />;
      case 6:
        return <FlowRateIcon {...rest} />;
      case 5:
        return <BsLightbulbFill {...rest} />;
      case 4:
        return <MdDeviceThermostat {...rest} />;
      case 3:
        return <PressureIco {...rest} />;
      case 2:
        return <MdBolt {...rest} />;
      case 1:
        return <IoIosPulse {...rest} />;
      default:
        return <MdFiberNew {...rest} />;
    }
  };

  return <>{getSensorIcon(id)}</>;
};
