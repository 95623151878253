import React, { useState } from "react";
import { Form, LabelHours, Title } from "./styles";
import { ModalNoiseFilterProps } from "./types";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/Modal";

export const NoiseFilterForm = ({
  show,
  handleClose,
  handleConfirm,
}: ModalNoiseFilterProps) => {
  const [value, setValue] = useState<any>(undefined);

  const { t } = useTranslation();

  const blockInvalidCharacter = (e: {
    key: string;
    preventDefault: () => any;
  }) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  return (
    <Modal
      handleClose={handleClose}
      show={show}
      handleConfirm={() => handleConfirm(value?.toString() ? value : null)}
      confirmLabel={`${t("NoiseFilterForm.save")}`}
    >
      <>
        <Title>{t("NoiseFilterForm.filterConfig")}</Title>
        <Form>
          <div>
            <LabelHours>
              <label>Valor</label>
              <input
                value={value}
                onChange={(e) => setValue(+e.target.value)}
                type="number"
                placeholder={`${t("NoiseFilterForm.typeValue")}`}
                onKeyDown={blockInvalidCharacter}
              />
            </LabelHours>
          </div>
        </Form>
      </>
    </Modal>
  );
};
