import { IHarmonicsFFT } from "../../pages/Analysis/HarmonicsForm";
import { addAnnotations, annotationsFirstRow } from "./general";

interface ArgsGetHarmonics {
  data: any;
  harmonics: IHarmonicsFFT;
  frequencyUnitCPM?: boolean;
}

interface IHarmonicValue {
  freq: number;
  index: number;
}

export const getHarmonics = ({
  data,
  harmonics,
  frequencyUnitCPM,
}: ArgsGetHarmonics) => {
  const { fundamental, order, dontShowFrequency } = harmonics;
  let harmonicsUsed = 0;
  let harmonicPosition: IHarmonicValue[] = Array(harmonics.harmonics)
    .fill(0)
    .map((_, index) => ({
      freq: +(fundamental + fundamental * order * index).toFixed(2),
      index: index + 1,
    }));

  const unit = frequencyUnitCPM ? "cpm" : "Hz";

  const harmonicData = data.reduce((acc: any, item: any, i: number) => {
    let nextValue = data[i + 1]?.[0] || 0;
    let harmonic = harmonicPosition[harmonicsUsed]?.freq || 0;

    if (i === 0) {
      acc.push(addAnnotations(item, annotationsFirstRow));
    } else if (item[0] === harmonic) {
      acc.push(
        addAnnotations(item, [
          dontShowFrequency
            ? `${harmonicsUsed + 1}X`
            : `${harmonic.toFixed(2)} ${unit}`,
          "PLACEHOLDER",
        ]),
      );

      harmonicsUsed++;
    } else if (item[0] < harmonic && nextValue > harmonic) {
      const filterHarmonicsToAdd = harmonicPosition.filter(
        ({ freq }) => freq > item[0] && freq < nextValue,
      );

      acc.push(addAnnotations(item, [null, null]));
      filterHarmonicsToAdd.forEach(({ freq, index }) => {
        item[0] = +freq.toFixed(2);
        acc.push(
          addAnnotations(item, [
            dontShowFrequency ? `${index}X` : `${freq.toFixed(2)} ${unit}`,
            "PLACEHOLDER",
          ]),
        );
        harmonicsUsed++;
      });

      acc.push(addAnnotations(item, [null, null]));
    } else {
      acc.push(addAnnotations(item, [null, null]));
    }

    return acc;
  }, []);

  return harmonicData;
};
