import styled from "styled-components";

interface IGraphicContainer {
  withBorder?: boolean;
  relative?: boolean;
}

export const GraphicContainer = styled.div<IGraphicContainer>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.divBackground};
  ${({ theme }) => theme.fonts.primary.H3SemiBold};
  border-radius: 6px;
  padding: 4px 0 4px 0;
  z-index: 2;

  ${({ withBorder, theme }) =>
    withBorder && `border: 1px solid ${theme.colors.cardHighlight}`};
  &:hover {
    ${({ withBorder, theme }) =>
      withBorder && `border: 1px solid ${theme.colors.cardBackground}`};
  }
  ${({ relative }) => relative && `position: relative`};
`;

interface IGraphicFooterContainer {
  absolute?: boolean;
}

export const GraphicFooterContainer = styled.div<IGraphicFooterContainer>`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 50px;

  & > div {
    display: flex;
    gap: 8px;
  }

  ${({ absolute }) =>
    absolute ? { position: "absolute", bottom: 4, "z-index": 1 } : ""};

  @media screen and (max-width: 767px) {
    & > div > div {
      gap: 0.1rem;
    }
  }
`;

export const GraphicFooterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.divBackground};
  border: 1px solid transparent !important;
  color: ${(props) => props.theme.colors.iconsSecondary};
  padding: 3px;

  &:hover {
    border-radius: 6px;
    color: ${(props) => props.theme.colors.ibbxOrange};
    border: 1px solid ${(props) => props.theme.colors.cardHighlight} !important;
  }
  &:active {
    color: ${(props) => props.theme.colors.ibbxOrange};
    background-color: ${(props) => props.theme.colors.cardHighlight};
    font-size: 25px;
    transform: scale(0.9);
  }

  @media screen and (max-width: 767px) {
    padding: 0 0.5rem;
  }
`;
