import queryString from "../../utils/queryString";
import { useEffect, useRef, useState, useMemo } from "react";
import { MdClose, MdMenu } from "react-icons/md";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";
import { setOccurrenceRegister } from "../../store/slices/appSlice";

import { PROFILES, userSelect } from "../../store/slices/userSlice";
import {
  AddButton,
  Container,
  ContainerSelect,
  FilterContainers,
  ToogleShowHideSidebarMobile,
} from "./styles";

import { Select } from "../Select";
import useOnClickOutSide from "../../hooks/useOnClickOutside";
import FilterPeriodPickerMobile from "./FilterPeriodPickerMobile";
import { statusOptions, updateQueryString } from "../FilterBar/types";
import useFilters from "../../hooks/useFilters";
import { ROUTES_PATH } from "../../routes/RoutesPath";
import { getUrlPattern } from "../../utils/getUrlPattern";
import { GroupBase, StylesConfig } from "react-select";
import OccurrenceHiearchyModal from "../../modals/OccurrenceHiearchyModal";
import { TbPlus } from "react-icons/tb";
import { RiEqualizerFill } from "react-icons/ri";
import { isMobile } from "../../utils/mediaQuery";
import { useTranslation } from "react-i18next";

type FilterBarMobileProps = {
  isDashboard?: boolean;
  assetList?: any;
};

export const FilerBarMobile = ({
  isDashboard,
  assetList,
}: FilterBarMobileProps) => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const refFilterMobile = useRef(null);
  useOnClickOutSide(refFilterMobile, () => setShowSidebar(false));

  const { t } = useTranslation();
  const app = useAppSelector(appSelect);
  const user = useAppSelector(userSelect);
  const {
    currentCompany,
    currentFacility,
    currentAsset,
    monitoringOptions,
    currentPosition,
  } = app;

  const dispatch = useAppDispatch();

  const theme = useTheme();
  const location = useLocation();
  const params = useParams();
  const urlPattern = getUrlPattern(location, params);

  const [, setSearchParams] = useSearchParams();
  const query: any = queryString.parse(location.search);

  const [hiearchyModal, setHiearchyModal] = useState(false);
  const [filterEmphasys, setFilterEmphasys] = useState<boolean>(false);

  const { CLIENTE_COMUM } = PROFILES;
  const isClient = user.isClient;
  const isCommonClient = CLIENTE_COMUM === user.profile;
  const isOccurrencePage = urlPattern.includes("occurrences");
  // const isCompaniesPage = ROUTES_PATH.COMPANIES === urlPattern;
  const isFacilitiesPage = ROUTES_PATH.FACILITIES === urlPattern;
  const isAssetsPage = ROUTES_PATH.ASSETS === urlPattern;
  const isAssetPage = ROUTES_PATH.ASSET === urlPattern;
  const isPagePosition = ROUTES_PATH.POSITIONS === urlPattern;

  const skipCompany = isFacilitiesPage;
  const skipFacility = skipCompany || isAssetsPage;
  const skipAsset = skipFacility || isAssetPage;

  function findItem(options: any[], value: number) {
    const item = options.find((item) => item.value === value);
    return item;
  }

  const { changeCompany, changeFacility, changeSector, changeAsset, companyOptions, facilityOptions, assetOptions, sectorOptions } =
    useFilters({});

  // eslint-disable-next-line
  const handleEmphasysFilter = () => {
    const query: any = queryString.parse(location.search);

    if (!filterEmphasys) {
      setSearchParams(new URLSearchParams({ ...query, emphasys: "true" }));
    } else {
      delete query.emphasys;
      setSearchParams(new URLSearchParams(query));
    }

    setFilterEmphasys(!filterEmphasys);
  };

  const company = useMemo(() => {
    if (!companyOptions.length) {
      return null;
    }

    if (query.companyId || (isClient && isOccurrencePage)) {
      const id = query?.companyId || user.company;
      const item = findItem(companyOptions, +id);
      if (isOccurrencePage) {
        changeCompany(item);
      }
      return item;
    }

    if (currentCompany.id) {
      return { value: currentCompany.id, label: currentCompany.name };
    }

    return null;
    // eslint-disable-next-line
  }, [companyOptions, query.companyId, currentCompany]);

  const facility = useMemo(() => {
    let item = null;
    if (!facilityOptions.length) {
      return item;
    }

    if (query.facilityId || (isCommonClient && isOccurrencePage)) {
      const id = query?.facilityId || user.facility;
      item = facilityOptions.find(({ value }) => value === +id);
      if (item && isOccurrencePage) {
        changeFacility(item);
      }
      return item;
    }

    if (currentFacility.id) {
      return { value: currentFacility.id, label: currentFacility.name };
    }
    return null;
    // eslint-disable-next-line
  }, [facilityOptions, query.facilityId, currentFacility]);

  const sector = useMemo(() => {
    if (!sectorOptions.length) {
      return null;
    }

    if (query.sectorId) {
      const item = sectorOptions.find(
        ({ value }) => value === +query.sectorId,
      );

      return item;
    }

    return null;
    // eslint-disable-next-line
  }, [sectorOptions, query.sectorId]);

  const asset = useMemo(() => {
    if (!assetOptions.length) {
      return null;
    }

    if (query.assetId) {
      const item = assetOptions.find(
        ({ value }) => value === +query.assetId,
      );
      if (item && isOccurrencePage) {
        changeAsset(item);
      }

      return item;
    }

    if (currentAsset.id) {
      return { value: currentAsset.id, label: currentAsset.name };
    }
    return null;
    // eslint-disable-next-line
  }, [assetOptions, query.assetId, currentAsset]);

  const {
    loadCompanyOptions,
    loadFacilityOptions,
    loadSectorOptions,
    loadAssetOptions,
    loadMonitoringOptions,
  } = useFilters({
    companyId: company?.value,
    facilityId: facility?.value,
    sectorId: sector?.value,
    assetId: asset?.value,
  });

  const [status, setStatus] = useState<any>(null);
  const [monitoringType, setMonitoringType] = useState<any>(null);

  useEffect(() => {
    if (query.statusId) {
      const item = statusOptions.find(({ value }) => value === +query.statusId);
      setStatus(item);
    }
  }, [query.statusId]);

  useEffect(() => {
    if (!monitoringOptions.data.length) {
      return;
    }

    if (query["sensorTypeId"]) {
      const item = findItem(monitoringOptions.data, +query["sensorTypeId"]);
      setMonitoringType(item);
    }
    // eslint-disable-next-line
  }, [query["sensorTypeId"], monitoringOptions]);

  useEffect(() => {
    Promise.all([
      loadCompanyOptions(skipCompany),
      loadFacilityOptions(skipFacility),
      loadSectorOptions(),
      loadAssetOptions(skipAsset),
      loadMonitoringOptions(),
    ]);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!facility) {
      loadFacilityOptions();
    }
    if (!sector) {
      loadSectorOptions();
    }
    if (!asset) {
      loadAssetOptions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, facility, sector]);

  const changeQueryParameters = (field: string, e?: any) => {
    const query: any = queryString.parse(location.search);

    switch (field) {
      case "COMPANY":
        query.companyId = e?.value;
        updateQueryString(query, "companyId", setSearchParams);
        return;
      case "FACILITY":
        query.facilityId = e?.value;
        updateQueryString(query, "facilityId", setSearchParams);
        return;
      case "SECTOR":
        query.sectorId = e?.value;
        updateQueryString(query, "sectorId", setSearchParams);
        return;
      case "ASSET":
        query.assetId = e?.value;
        updateQueryString(query, "assetId", setSearchParams);
        return;
      case "STATUS":
        query.statusId = e?.value;
        updateQueryString(query, "statusId", setSearchParams);

        return;
      case "SENSOR_TYPE":
        query["sensorTypeId"] = e?.value;
        updateQueryString(query, "sensorTypeId", setSearchParams);
        return;
    }
  };

  const handleChange = (field: string, e: any) => {
    switch (field) {
      case "COMPANY":
        if (isOccurrencePage) {
          changeCompany(e);
        }
        break;
      case "FACILITY":
        if (isOccurrencePage) {
          changeFacility(e);
        }
        break;
      case "SECTOR":
        changeSector(e);
        break;
      case "ASSET":
        if (isOccurrencePage) {
          changeAsset(e);
        }
        break;
      case "STATUS":
        setStatus(e);
        break;
      case "SENSOR_TYPE":
        setMonitoringType(e);
        break;
    }
    changeQueryParameters(field, e);
  };

  const styles: StylesConfig<any, false, GroupBase<any>> = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: theme.colors.divBackgroundAlpha,
      color: theme.colors.iconsSecondary,
      // border: "1px solid ${(props) => props.theme.colors.divBackgroundAlpha}",
      borderRadius: "6px",
      boxShadow: "none",
      fontWeight: 800,
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: theme.colors.iconsSecondary,
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: "none",
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
      color: "red",
      height: "35px",
      position: "relative",

      "& > div": {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
      [`input[type="text"]:focus`]: {
        boxShadow: "none",
      },
    }),

    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: theme.colors.iconsSecondary,
      alignItems: "center",
      fontSize: isMobile() ? "12px" : "16px",
      display: "flex",
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      ...(company !== null ? { display: "none" } : {}),
      maxWidth: "200px",
      color: theme.colors.iconsSecondary,
      position: "absolute",
      alignItems: "left",
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: theme.colors.background,
      marginTop: 0,
      fontColor: theme.colors.iconsPrimary,
      borderColor: theme.colors.cardHighlight,
      borderRadius: "8px",
      fontWeight: 800,
      fontSize: "12px",
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      maxHeight: "9rem",
      overflowX: "hidden",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      ":hover": { backgroundColor: theme.colors.headerBackground },
      backgroundColor: state.isSelected
        ? theme.colors.cardBackground
        : "transparent",
    }),
  };

  function handleAdd() {
    if (isPagePosition) {
      dispatch(
        setOccurrenceRegister({
          show: true,
          id: null,
          companyId: currentCompany.id,
          facilityId: currentFacility.id,
          assetId: currentAsset.id,
          positionId: currentPosition.id,
          sysSensorTypeId: currentPosition.positionSensorTypeId,
        }),
      );
    } else {
      setHiearchyModal(true);
    }
  }

  const indicator = { DropdownIndicator: () => null };
  return (
    <div>
      {hiearchyModal && (
        <OccurrenceHiearchyModal
          show={hiearchyModal}
          handleClose={() => setHiearchyModal(false)}
          handleConfirm={() => null}
        />
      )}
      <Container assetList={assetList}>
        <div onClick={() => setShowSidebar(true)}>
          <RiEqualizerFill size={28} />
        </div>
        {!showSidebar && !assetList && (
          <AddButton onClick={handleAdd}>
            <TbPlus />
          </AddButton>
        )}
      </Container>
      {showSidebar && (
        <>
          <FilterContainers
            ref={refFilterMobile}
            isDashboard={isDashboard}
            assetList={assetList}
          >
            <ToogleShowHideSidebarMobile
              isOpen={showSidebar}
              onClick={() => setShowSidebar(!showSidebar)}
            >
              {showSidebar ? <MdClose size={28} /> : <MdMenu size={32} />}
            </ToogleShowHideSidebarMobile>
            <ContainerSelect>
              <span>Empresa</span>
              <Select
                handleChange={(e) => {
                  handleChange("COMPANY", e);
                }}
                options={companyOptions}
                value={company}
                placeholder={`${t("FilterBarMobile.companies")}`}
                styles={styles}
                isClearable={!isFacilitiesPage}
                components={
                  (isClient ||
                    isFacilitiesPage ||
                    isAssetsPage ||
                    isAssetPage ||
                    isPagePosition) &&
                  indicator
                }
                disabled={
                  isClient ||
                  isFacilitiesPage ||
                  isAssetsPage ||
                  isAssetPage ||
                  isPagePosition
                }
                isSearchable
              />
              <span>Unidade</span>
              <Select
                handleChange={(e) => {
                  handleChange("FACILITY", e);
                }}
                value={facility}
                options={facilityOptions}
                placeholder={`${t("FilterBarMobile.facilities")}`}
                styles={styles}
                isClearable={
                  !isCommonClient &&
                  !isAssetsPage &&
                  !isAssetPage &&
                  !isPagePosition
                }
                components={
                  isCommonClient ||
                  isAssetsPage ||
                  isAssetPage ||
                  isPagePosition
                    ? indicator
                    : {}
                }
                disabled={
                  isCommonClient ||
                  isAssetsPage ||
                  isAssetPage ||
                  isPagePosition
                }
              />
              <span>Setor</span>
              {!isAssetPage && !isPagePosition && !assetList && (
                <Select
                  handleChange={(e) => {
                    handleChange("SECTOR", e);
                  }}
                  value={sector}
                  options={sectorOptions}
                  placeholder={`${t("FilterBarMobile.sector")}`}
                  styles={styles}
                  isClearable={!isAssetPage || !isPagePosition}
                  components={isAssetPage || isPagePosition ? indicator : {}}
                  disabled={isAssetPage || isPagePosition}
                />
              )}
              <span>Equipamento</span>
              {!assetList && (
                <Select
                  handleChange={(e) => {
                    handleChange("ASSET", e);
                  }}
                  value={asset}
                  options={assetOptions}
                  placeholder={`${t("FilterBarMobile.assets")}`}
                  styles={styles}
                  isClearable={!isAssetPage || !isPagePosition}
                  components={isAssetPage || isPagePosition ? indicator : {}}
                  disabled={isAssetPage || isPagePosition}
                />
              )}
              <span>Status</span>
              <Select
                handleChange={(e) => handleChange("STATUS", e)}
                value={status}
                options={statusOptions.filter(
                  (option) => assetList || option.value !== 1,
                )}
                placeholder={"Status"}
                styles={styles}
                isClearable
              />
              <span>Monitoramento</span>
              {!isPagePosition && !assetList && (
                <Select
                  handleChange={(e) => handleChange("SENSOR_TYPE", e)}
                  value={monitoringType}
                  options={monitoringOptions.data}
                  placeholder={`${t("FilterBarMobile.monitoring")}`}
                  styles={styles}
                  isClearable
                />
              )}
              <span>{t("FilterBarMobile.period")}</span>
              {!assetList && <FilterPeriodPickerMobile />}
            </ContainerSelect>
          </FilterContainers>
        </>
      )}
    </div>
  );
};
