export const cascadeMediaScreenSize = (
  innerWidth: number,
  innerHeight: number,
) => {
  if (innerWidth > 1600 && innerHeight > 960) {
    return {
      width: 1300,
      height: 1145,
    };
  }
  return {
    width: 800,
    height: 350,
  };
};

export const isMobile = () => {
  return window.innerWidth <= 767;
};

export const largeDesktop = () => {
  return window.innerWidth >= 2133;
};

export const mediaQuerie = () => {
  return {
    desktop: window.innerWidth >= 1023.01,
    mobile: window.innerWidth <= 1022.99,
  };
};

export const isTabletOnly = () => {
  return window.innerWidth >= 768 && window.innerWidth <= 1280;
};

export const getScreenResolution = () => {
  if (window.innerWidth <= 767) {
    return "Mobile";
  }
  if (window.innerWidth >= 768 && window.innerWidth <= 1535) {
    return "Tablet";
  }
  if (window.innerWidth >= 1536 && window.innerWidth <= 1920) {
    return "Desktop";
  }
  if (window.innerWidth > 1920) {
    return "Large Desktop";
  }
};
