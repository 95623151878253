import { FaExternalLinkAlt, FaStar } from "react-icons/fa";
import {
  AppDescription,
  AppName,
  CardMenu,
  ContainerInfo,
  Content,
  Cover,
  DescriptionContainer,
  FavoriteApp,
  GetAppButton,
  GetAppContainer,
  GoToApp,
  GoToAppLink,
  Header,
  OnHoverContent,
} from "./styles";
import { useRef, useState } from "react";
import { MdOutlineImageNotSupported } from "react-icons/md";
import { useTranslation } from "react-i18next";
import useOnClickOutSide from "../../../hooks/useOnClickOutside";
import RoundIconButton from "../../../components/RoundIconButton";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IApp } from "../../Configuration/CompanyDetails/CompanyApps";

interface IStardardHubApp {
  id: number;
  url?: string | undefined;
  image?: string;
  title: string;
  available?: boolean;
  app: IApp;
  facilityIds?: any[];
  appDescription: string | undefined;
  favorite?: boolean;
  editableApp?: boolean;
  handleOpenEdit: (appId: number) => void;
  handleFavorite: (app: IApp) => void;
  handleDelete: (appId: number) => void;
}

export function StardardHubApp({
  image,
  title,
  available,
  appDescription,
  url,
  editableApp,
  favorite,
  handleOpenEdit,
  handleFavorite,
  handleDelete,
  app,
  id,
}: IStardardHubApp) {
  const [isHovered, setIsHovered] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleRedirect = () => {
    if (available) {
      window.open(url, "_blank");
    }
  };

  const handleOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const cardRef = useRef<any>();

  const { t } = useTranslation();

  useOnClickOutSide(cardRef, () => setIsMenuOpen(false));

  const handleMouseOver = () => {
    setIsHovered(true);
  };
  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <Content
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      available={!available}
      cardAnimation={!editableApp && isHovered}
    >
      {isHovered && !editableApp ? (
        <OnHoverContent>
          <Header>
            <AppName>
              <span>{title}</span>
            </AppName>

            <GoToApp block={!available} type="button">
              <GoToAppLink to={`${url}`} target="_blank">
                <FaExternalLinkAlt />
              </GoToAppLink>
            </GoToApp>
          </Header>
          <AppDescription>
            {appDescription ? (
              <p>{appDescription}</p>
            ) : (
              <div>
                <span>{t("Nenhuma descrição adicionada")}</span>
              </div>
            )}
          </AppDescription>
          <Cover onHover={isHovered}>
            {image ? (
              <img src={image} alt="imagem-App" />
            ) : (
              <div>
                <MdOutlineImageNotSupported />
                <span>{t("Sem imagem de capa")}</span>
              </div>
            )}
          </Cover>
        </OnHoverContent>
      ) : (
        <>
          <Cover onClick={handleRedirect}>
            {image ? (
              <img src={image} alt="imagem-App" />
            ) : (
              <div>
                <MdOutlineImageNotSupported />
                <span>{t("Sem imagem de capa")}</span>
              </div>
            )}

            {editableApp && (
              <>
                <FavoriteApp>
                  {favorite && <FaStar color={"yellow"} />}
                </FavoriteApp>
              </>
            )}
          </Cover>
          <DescriptionContainer />
          <ContainerInfo>
            <AppName>
              <span>{title}</span>
            </AppName>

            <RoundIconButton
              handleClick={() => handleOpenMenu()}
              styles={{
                position: "absolute",
                right: "0.5rem",
                top: "0.5rem",
              }}
            >
              <BsThreeDotsVertical />
            </RoundIconButton>

            {isMenuOpen && (
              <CardMenu ref={cardRef}>
                {available && (
                  <div onClick={() => handleFavorite(app)}>
                    {favorite ? t("Desfavoritar") : t("Favoritar")}
                  </div>
                )}
                <div onClick={() => handleOpenEdit(id)}>
                  {t("cardBolt.edit")}
                </div>
                <div onClick={() => handleDelete(app.id)}>
                  {t("cardBolt.delete")}
                </div>
              </CardMenu>
            )}
            <GetAppContainer>
              <GetAppButton isAvailable={available}>
                {available ? t("Disponível") : t("Indisponível")}
              </GetAppButton>
              <GoToApp block={!available} type="button">
                <GoToAppLink to={`${url}`} target="_blank">
                  <FaExternalLinkAlt />
                </GoToAppLink>
              </GoToApp>
            </GetAppContainer>
          </ContainerInfo>
        </>
      )}
    </Content>
  );
}
