import styled, { css } from "styled-components";

export const ButtonReturn = styled.button<{
  specialStyle?: boolean;
  isIosDevices?: any;
}>`
  position: absolute;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  bottom: 1rem;
  left: 0.75rem;
  width: 2.75rem;
  height: 2.75rem;
  ${(props) => props.theme.fonts.primary.H4Regular}
  background: ${(props) => props.theme.colors.background};
  border: ${(props) => props.theme.colors.cardHighlight};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  color: ${(props) => props.theme.colors.iconsPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  z-index: 10;

  ${({ isIosDevices }) =>
    isIosDevices &&
    css`
      bottom: 4rem;
    `}

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.cardUnderlineLightAlpha};
    svg {
      filter: brightness(1.1);
    }
  }

  ${({ specialStyle }) =>
    specialStyle &&
    css`
      top: 0.5rem;
      left: 0.25rem;
      z-index: 101;
      scale: 0.85;
      button {
        min-width: 2rem !important;
        height: 2rem;
        border-radius: 50%;
        span {
          display: none;
        }
      }

      @media screen and (max-width: 767px) {
        top: -3.9rem;
        left: 0.25rem;
        z-index: 101;
        scale: 0.85;
        button {
          min-width: 2rem !important;
          height: 2rem;
          border-radius: 50%;
          span {
            display: none;
          }
        }
      }
    `}
`;
