import { useMemo } from "react";
import { useTheme } from "styled-components";
import queryString from "../../../utils/queryString";
import { useLocation, useSearchParams } from "react-router-dom";
import { Select } from "../../../components/Select";
import { GlobalPrecisionSelectContainer } from "./styles";
import { useTranslation } from "react-i18next";

function GlobalPrecisionSelect() {
  const location = useLocation();
  const { colors } = useTheme();
  const { t } = useTranslation();

  const queryParams = queryString.parse(location.search);

  const precisionOptions = [
    { value: null, label: t("commonText.default") },
    { value: "NONE", label: t("commonText.complete") },
    { value: "600000", label: `10 ${t("commonText.minutes")}` },
  ];

  const value = useMemo(() => {
    return (
      precisionOptions.find(
        ({ value }) => value?.toString() === queryParams.precision,
      ) || {
        value: null,
        label: t("commonText.default"),
      }
    );
    // eslint-disable-next-line
  }, [queryParams.precision]);

  const customStyles = {
    input: (provided: any, state: any) => ({
      ...provided,
      width: "200px",
    }),

    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: colors.cardBackground,
      borderColor: colors.cardHighlight,
      color: colors.iconsSecondary,
      boxShadow: "none",
      borderRadius: "6px",
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: colors.iconsSecondary,
    }),

    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: "none",
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      background: colors.cardBackground,
      fontWeight: "600",
      fontSize: "12px",
      color: colors.iconsSecondary,
    }),
  };

  const [, setSearchParams] = useSearchParams();

  function handleChange(e: any) {
    if (e?.value) {
      setSearchParams({ ...queryParams, precision: e.value });
    } else {
      delete queryParams.precision;
      setSearchParams(queryParams as any);
    }
  }

  return (
    <GlobalPrecisionSelectContainer>
      <Select
        value={value}
        options={precisionOptions}
        styles={customStyles}
        handleChange={handleChange}
        placeholder={t("commonText.period") as string}
      />
    </GlobalPrecisionSelectContainer>
  );
}

export default GlobalPrecisionSelect;
