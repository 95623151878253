import { Button } from "./styles";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import useQueryParams from "../../hooks/useQueryParams";

interface IOrderByButtonAssetOccurrence {
  orderByCode?: string;
  type?: "ORDER" | "CLEAR";
  label?: string;
}

export const OrderByButtonAssetOccurrence = ({
  orderByCode,
  type,
  label,
}: IOrderByButtonAssetOccurrence) => {
  const { queryParams: query } = useQueryParams();

  const isSelected = useMemo(() => {
    if (query?.orderBy?.split(" ").includes(orderByCode)) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.orderBy]);

  const [, setSearchParams] = useSearchParams();

  const updateQuery = () => {
    if (query?.orderBy?.includes(orderByCode)) {
      const currentOrderByQueryInArray = query?.orderBy.split(" ");
      const removedOrderByCode = currentOrderByQueryInArray.filter(
        (item: any) => item !== orderByCode,
      );

      let newParams = { ...query, orderBy: removedOrderByCode.join(" ") };

      setSearchParams(new URLSearchParams(newParams));
    } else {
      const newParams = {
        ...query,
        orderBy: query.orderBy
          ? query.orderBy + " " + orderByCode
          : orderByCode,
      };
      setSearchParams(new URLSearchParams(newParams));
    }
  };

  const clearQuery = () => {
    const newParams = { ...query };
    delete newParams.orderBy;
    setSearchParams(new URLSearchParams(newParams));
  };

  const handleClick = () => {
    if (type === "ORDER") {
      updateQuery();
    } else if (type === "CLEAR") {
      clearQuery();
    }
  };

  return (
    <Button
      hasLabel={!!label}
      selected={isSelected}
      onClick={handleClick}
      title={type === "ORDER" ? "Ordenar" : "Limpar Ordenações"}
    >
      {label && <span>{label}</span>}
      {type === "ORDER" && <FaChevronDown />}
    </Button>
  );
};
