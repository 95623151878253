import React, { useMemo, Fragment } from "react";
import { BsRecordCircleFill } from "react-icons/bs";
import { NoHistory } from "../styles";
import {
  HistoryDashedLine,
  HistoryData,
  HistoryInfo,
  HistoryItems,
} from "./styles";
import { formatDate } from "../../../utils/utilsFunctions";
import { TbArrowNarrowRight } from "react-icons/tb";
import { AssetOccurrence } from "../../../pages/Occurrence/types";
import { useOccurrenceHistory } from "../../../hooks/useOccurrenceHistory";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useAppSelector } from "../../../store/hooks";
import { PROFILES, userSelect } from "../../../store/slices/userSlice";

type OccurrenceHistoryProps = {
  occurrence: AssetOccurrence;
  refresh?: () => void;
};

export default function OccurrenceHistory({
  occurrence,
  refresh,
}: OccurrenceHistoryProps) {
  const user = useAppSelector(userSelect);

  const userClient = [
    PROFILES.CLIENTE_COMUM,
    PROFILES.CLIENTE_MASTER,
    PROFILES.CLIENTE_MASTER_VIEW,
  ].includes(user.profile);

  const { getHistory } = useOccurrenceHistory(userClient);

  const { t } = useTranslation();

  const history: any[] = useMemo(() => {
    return getHistory(occurrence);
    // eslint-disable-next-line
  }, [occurrence.history, i18n.language]);

  const lastHistoryListed = [...history].sort((a, b) => +b.date - +a.date);

  return (
    <>
      {history?.length ? (
        lastHistoryListed.map((item: any, index: number) => (
          <Fragment key={index}>
            <HistoryDashedLine />
            <HistoryItems>
              <HistoryData>
                <div>
                  <BsRecordCircleFill />
                </div>
                <div>
                  <span>{formatDate(+item.date, { type: "FULL" })}</span>
                </div>
                <div>
                  <TbArrowNarrowRight />
                </div>
              </HistoryData>
              <HistoryInfo>
                <div>
                  <span>{t("OccurrenceHistory.Usuário:")}</span>
                  <span>{item.userName}</span>
                </div>
                <div>
                  <span>{t("OccurrenceHistory.Ação:")}</span>
                  <span>{item.action}</span>
                </div>
                {!!item.comments && (
                  <div>
                    <span>{t("OccurrenceHistory.Comentário:")}</span>
                    <span>{item.comments}</span>
                  </div>
                )}
              </HistoryInfo>
            </HistoryItems>
          </Fragment>
        ))
      ) : (
        <NoHistory>
          <span>{t("OccurrenceHistory.Sem histórico até o momento.")}</span>
        </NoHistory>
      )}
    </>
  );
}
