import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, Container } from "./styles";
import { getUrlPattern } from "../../../../utils/getUrlPattern";

import { ROUTES_PATH } from "../../../../routes/RoutesPath";
import { BsBroadcastPin } from "react-icons/bs";
import { useAppSelector } from "../../../../store/hooks";
import { appSelect } from "../../../../store/slices/appSlice";
import { Joyride } from "../../../Joyride";
import { useEffect, useState } from "react";
import { CallBackProps } from "react-joyride";
import { defaultSteps, firstSteps } from "./steps";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import { useDispatch } from "react-redux";
import { setShowSidebar } from "../../../../store/slices/sidebarSlice";

export function NavigateToConnectivity({
  navbarColor,
  runJoyride,
  setRunJoyride,
  visible,
}: {
  navbarColor?: string;
  runJoyride?: boolean;
  setRunJoyride?: (value: boolean) => void;
  visible?: boolean;
}) {
  const { currentCompany: company, currentFacility: facility } =
    useAppSelector(appSelect);
  const params = useParams();
  const location = useLocation();
  const urlPattern = getUrlPattern(location, params);

  const dispatch = useDispatch();

  const [firstRun, setFirstRun] = useState(false);

  const { t } = useTranslation();

  const [steps, setSteps] = useState(defaultSteps({ t, isMobileOnly }));
  const [alertNewFunctionality, setAlertNewFunctionality] = useState(false);

  const navigate = useNavigate();

  const isCurrentPage = [
    ROUTES_PATH.COMPANY_CONNECTIVY,
    ROUTES_PATH.FACILITY_CONNETIVITY,
  ].includes(urlPattern);

  const handleClick = (e: any) => {
    if (isCurrentPage) {
      e.preventDefault();
    }
  };

  function getUrl() {
    const { companyId, facilityId } = params;

    if (facilityId)
      return `/companies/${companyId}/facilities/${facilityId}/connectivity`;

    if (companyId) return `/companies/${companyId}/connectivity`;

    if (company.id && facility.id)
      return `/companies/${company.id}/facilities/${facility.id}/connectivity`;

    if (company.id) return `/companies/${company.id}/connectivity`;

    return "/";
  }

  const { pathname } = location;

  useEffect(() => {
    const joyrideCompanyFacilitieLocalStorage = localStorage.getItem(
      "retina:JoyrideCompanyFacilitie",
    );
    const alertNewFunctionalityDateLocalStorage = localStorage.getItem(
      "retina:alertNewFunctionalityDate",
    );

    setFirstRun(
      !pathname.includes("connectivity") &&
        joyrideCompanyFacilitieLocalStorage !== "false",
    );

    const currentTime = new Date().getTime();
    const newTime = new Date(currentTime + 1000 * 60 * 60 * 24 * 2); // 48 hours

    if (!alertNewFunctionalityDateLocalStorage) {
      localStorage.setItem(
        "retina:alertNewFunctionalityDate",
        newTime.toString(),
      );

      setAlertNewFunctionality(true);
    } else {
      const alertNewFunctionalityDate = new Date(
        alertNewFunctionalityDateLocalStorage,
      );
      setAlertNewFunctionality(true);

      if (currentTime > alertNewFunctionalityDate.getTime()) {
        setAlertNewFunctionality(false);
      }
    }
  }, [location]);

  const JoyrideConnectivity = ({ action, step }: CallBackProps) => {
    const { target } = step;

    if (action === "skip" || action === "reset" || action === "close") {
      localStorage.setItem("retina:JoyrideConnectivity", "false");
      localStorage.setItem("retina:JoyrideCompanyFacilitie", "false");
      setRunJoyride && setRunJoyride(false);
      dispatch(setShowSidebar(true));
    }

    if (
      (target === "#gatewayConnectivity" || target === "#connected") &&
      isMobileOnly
    ) {
      dispatch(setShowSidebar(false));
    }
  };

  const JoyrideCompanyFacilities = ({ action }: CallBackProps) => {
    if (action === "next") {
      navigate(getUrl());
    }

    if (action === "skip") {
      localStorage.setItem("retina:JoyrideCompanyFacilitie", "false");
    }
  };

  return (
    <>
      <Container
        navbarcolor={navbarColor}
        onClick={handleClick}
        to={getUrl()}
        selected={isCurrentPage}
        visible={visible}
        id="connectivity"
      >
        <BsBroadcastPin />
        {runJoyride && (
          <Joyride
            run={runJoyride}
            steps={steps}
            callback={JoyrideConnectivity}
          />
        )}
        {alertNewFunctionality && <Alert>{t("ConnectivityJoyirde.New")}</Alert>}
      </Container>
      <Joyride
        run={firstRun}
        steps={firstSteps({ t })}
        callback={JoyrideCompanyFacilities}
        progress={false}
        locale={{
          last: t("ConnectivityJoyirde.Access") ?? "",
          next: t("ConnectivityJoyirde.Access") ?? "",
          skip: t("ConnectivityJoyirde.Leave it for later") ?? "",
        }}
        skipButton={true}
      />
    </>
  );
}
