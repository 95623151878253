import { useRef, useState } from "react";
import queryString from "../../../utils/queryString";
import { useLocation, useSearchParams } from "react-router-dom";
import { CloseButton, DateContainer, DatePickerContainer } from "./styles";
import { FilterPeriodPickerProps } from "./types";
import ptBR from "date-fns/locale/pt-BR";
import DatePicker, { registerLocale } from "react-datepicker";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiCalendar } from "react-icons/bi";
import useOnClickOutSide from "../../../hooks/useOnClickOutside";
import { isMobile } from "react-device-detect";

function FilterPeriodPicker({ updateQueryString }: FilterPeriodPickerProps) {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  registerLocale("ptBR", ptBR);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const ref = useRef(null);

  const isDateRangeEmpty = !!dateRange?.filter((item: any) => item !== null)
    .length;

  useOnClickOutSide(ref, () => {
    !isDateRangeEmpty && setShowDatePicker(false);
  });

  function handleDatePicker(value: any) {
    const params: any = queryString.parse(location.search);

    if (value[0] === null && value[1] === null) {
      setShowDatePicker(false);
      params.startDate = null;
      params.endDate = null;
    }

    if (value[0]) {
      params.startDate = value[0].getTime();
    }
    if (value[1]) {
      params.endDate = value[1].getTime();
    }

    setDateRange(value);

    updateQueryString(params, "startDate", setSearchParams);
    updateQueryString(params, "endDate", setSearchParams);
  }

  return (
    <DateContainer selected={showDatePicker} ref={ref}>
      {showDatePicker ? (
        <>
          <DatePickerContainer>
            <DatePicker
              autoFocus
              dateFormat={isMobile ? "dd/MM" : "dd/MM/yyyy"}
              selectsRange={true}
              locale={ptBR}
              startDate={startDate}
              endDate={endDate}
              onChange={(value) => handleDatePicker(value)}
              isClearable={true}
            />
          </DatePickerContainer>

          {startDate === null && endDate === null && (
            <CloseButton onClick={() => setShowDatePicker(!showDatePicker)}>
              <AiFillCloseCircle />
            </CloseButton>
          )}
        </>
      ) : (
        <button onClick={() => setShowDatePicker(!showDatePicker)}>
          <BiCalendar size={26} />
        </button>
      )}
    </DateContainer>
  );
}

export default FilterPeriodPicker;
