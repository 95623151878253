import styled, { keyframes } from "styled-components";
import { HideMenu } from "../../Companies/CardCompany/styles";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors.iconsSecondary};
  display: flex;
  flex-direction: column;

  span {
    ${(props) => props.theme.fonts.primary.H3SemiBold}
  }
`;

export const pulseCircle = keyframes`
  0% {
    transform: scale(0.4);
    opacity: 0.8;
  }
  80% {
    transform: scale(2.5);
    opacity: 0;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
`;

export const CirclePulse = styled.div`
  position: absolute;
  border: 1px solid ${(props) => props.theme.colors.iconsPrimary};
  border-radius: 50px;
  width: 16px;
  height: 16px;
  bottom: 26px;

  animation: ${pulseCircle} 1.5s ease-in-out infinite both;

  @media screen and (max-width: 767px) {
    right: 45%;
    transform: translateX(-50%);
    bottom: 0.6rem;
  }
`;

export const CirclePulse2 = styled(CirclePulse)`
  animation: ${pulseCircle} 1.5s ease-in-out 0.5s infinite both;
`;

export const cloudPulse = keyframes`
0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`;

export const rotate = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
`;

export const StandardCard = styled.div`
  display: flex;
  width: 160px;
  height: 140px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 90%;
    & > div {
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 3rem;
    }

    span {
      font-size: 0.75rem !important;
    }
  }
`;

export const ModetypeContainer = styled(StandardCard)`
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  position: relative;
  padding: 8px 0;
  width: 160px !important;

  span {
    margin: 0;
  }

  & > span:nth-child(1) {
    color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  }

  & > span:nth-child(2) {
    margin-top: 12px;
    ${(props) => props.theme.fonts.primary.H2Bold};
  }

  svg {
    animation: ${cloudPulse} 1.5s 0.4s infinite both;
  }
`;

export const OperationModeContainer = styled.div<{ syncCard?: boolean }>`
  position: relative;
  min-width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ syncCard }) => (syncCard ? "flex-start" : "center")};
  align-items: center;
  gap: 4px;

  svg:nth-child(2) {
    animation: ${rotate} 4s linear infinite both;
  }
`;

export const ButtonChangeMode = styled(StandardCard)`
  background-color: ${(props) => props.theme.colors.cardHighlight};
  padding: 6px;
  border: 1px solid ${(props) => props.theme.colors.cardBackground};
  cursor: pointer;
  width: 160px !important;

  svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
    margin-bottom: 8px;
  }

  span {
    margin: 0;
    ${(props) => props.theme.fonts.primary.H3SemiBold};
    color: ${(props) => props.theme.colors.iconsSecondary};
    text-align: center;
  }

  :hover {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
    opacity: 0.6;
    box-shadow: 2px 1px 1px ${(props) => props.theme.colors.headerBackground};
  }

  :active {
    opacity: 1;
    transform: scale(0.98);
  }
`;

export const SelectModeContainer = styled.div`
  display: flex;
  width: 150px;
  height: 150px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.cardBackground};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  button {
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.colors.iconsSecondary};
    margin-left: 16px;
    align-self: center;
  }

  @media screen and (max-width: 767px) {
    span {
      margin: 0.25rem;
      font-size: 0.75rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 767px) {
    gap: 0.5rem;
    align-items: flex-end;

    & > div:first-child {
      padding: 0.5rem 0;
      span {
        font-size: 1rem;
      }
    }
  }
`;

export const InfoButtonContainer = styled(HideMenu)`
  @media screen and (max-width: 767px) {
    right: 0px;
    top: 0px;
  }
`;

export const InfoContainer = styled.div<{ left?: number }>`
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: ${(props) => props.theme.colors.iconsSecondary};
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.cardBackground};
  border-radius: 6px;
  padding: 8px;
  right: 36px;
  top: 5px;
  flex-direction: column;
  gap: 8px;
  z-index: 2;
  span {
    ${(props) => props.theme.fonts.primary.H5Regular};
    border: 1px solid transparent;
  }

  @media screen and (max-width: 767px) {
    left: 0px;
    top: 2rem;
    left: ${(props) => props.left && props.left - 280 + "px"};
  }
`;

export const Triangle = styled.div<{ left?: number }>`
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  right: 30px;
  top: 12px;
  border-left: 6px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  position: absolute;
  transform: rotate(360deg);

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const borderAnimation = keyframes`
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(200);
    opacity: 1;
  }
`;

export const ProgreesBarContinuous = styled.div<{ almostDone?: any }>`
  position: relative;
  width: 100%;
  height: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.divBackgroundAlpha};
  border-radius: 6px;
  overflow: hidden;
  flex-direction: column;
  border: 1px solid lightblue;

  span {
    z-index: 1;
    ${(props) => props.theme.fonts.primary.H4Regular};
  }
  span:first-child {
    ${(props) => props.theme.fonts.primary.H1Semibold};
    font-size: 2rem;
    line-height: 1.75rem;
  }

  &::before {
    content: "";
    position: absolute;
    width: 35px;
    height: 200%;
    background-color: ${({ theme, almostDone }) =>
      almostDone && almostDone <= 5
        ? theme.colors.alarmRisco
        : theme.colors.p30};

    filter: blur(5px);
    animation: ${borderAnimation}
      ${({ almostDone }) => (almostDone && almostDone <= 5 ? "5s" : "15s")}
      linear infinite;
  }
  &::after {
    content: "";
    position: absolute;
    background-color: ${(props) => props.theme.colors.cardHighlight};
    inset: 4px;
    border-radius: 6px;
  }

  @media screen and (max-width: 767px) {
    width: 71px;
  }
`;

export const OperationDescription = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 100% !important;
  bottom: 0rem;
  transform: translateY(-50%);
  padding: 0rem !important;
  border-radius: 0 !important;

  span {
    text-align: center;
  }
  @media screen and (max-width: 767px) {
    bottom: auto;
    margin-bottom: 0.25rem;
  }
`;
