import { Form, Formik } from "formik";

import { FormikTextInput } from "../../../../components/textInput";
import useApi from "../../../../hooks/useApi";
import { cnpjValidator } from "../../../../utils/cnpjValidator";

import { CancelButton, Container, Footer, SaveButton } from "./styles";

import { FormAddNewRepresentativeProps, FORM_EMPTY } from "./types";
import { useTranslation } from "react-i18next";

const FormAddNewRepresentative = ({
  onCancel,
  onRefresh,
}: FormAddNewRepresentativeProps) => {
  const { request } = useApi({ path: "/representatives " });

  const handleSubmit = (values: any) => {
    request({ method: "post", body: values }).then(() => {
      onRefresh();
      onCancel();
    });
  };

  const { t } = useTranslation();

  return (
    <Container>
      <h2>{t("Representatives.FormAddNewRepresentative.new")}</h2>
      <Formik
        initialValues={FORM_EMPTY}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        validate={(values: any) => {
          const errors: any = {};
          if (!values.name.trim()) {
            errors.name = `${t(
              "Representatives.FormAddNewRepresentative.mandatoryName",
            )}`;
          }

          if (!values.document) {
            errors.document = `${t(
              "Representatives.FormAddNewRepresentative.mandatoryCnpj",
            )}`;
          } else if (values.document && !cnpjValidator(values.document)) {
            errors.document = `${t(
              "Representatives.FormAddNewRepresentative.invalidCnpj",
            )}`;
          }

          return errors;
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, errors }) => (
          <Form>
            <FormikTextInput
              name="name"
              label={`${t("Representatives.FormAddNewRepresentative.name")}`}
              error={errors.name}
            />
            <FormikTextInput
              name="document"
              label={`${t("Representatives.FormAddNewRepresentative.cnpj")}`}
              inputMode="numeric"
              error={errors.document}
            />
            <Footer>
              <CancelButton type="button" onClick={onCancel}>
                {t("Representatives.FormAddNewRepresentative.cancel")}
              </CancelButton>
              <SaveButton type="submit">
                {t("Representatives.FormAddNewRepresentative.save")}
              </SaveButton>
            </Footer>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default FormAddNewRepresentative;
