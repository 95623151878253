import styled, { css } from "styled-components";
import { getStatusColorById } from "../../../utils/getStatusColor";

export const OccurrenceEvidenceContainer = styled.div<{ deleted?: boolean }>`
  color: ${(props) => props.theme.colors.iconsPrimary};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};

  span {
    ${({ theme }) => theme.fonts.primary.H4Regular};
  }

  img {
    border-radius: 6px;
  }

  ${({ deleted }) =>
    deleted &&
    css`
      &:hover {
        outline: 3px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
      }
    `}
`;

export const HeaderEvidence = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem 0.25rem 0.5rem;
  text-decoration: none;
  background-color: ${(props) => props.theme.colors.headerCardSecondary};
  span {
    ${({ theme }) => theme.fonts.primary.H5Regular};
  }

  button {
    border: none;
    background-color: transparent;
    margin-left: 0.25rem;
    color: ${(props) => props.theme.colors.iconsSecondary};
    transition: all 0.3s ease;
    &:hover {
      filter: brightness(1.5);
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    span {
      font-size: 0.6rem;
    }
  }
`;

interface IOccurrenceEvidenceImageContainer {
  deleted?: boolean;
  isMobile?: boolean;
}

export const OccurrenceEvidenceImageContainer = styled.div<IOccurrenceEvidenceImageContainer>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 260px;

  ${({ deleted }) =>
    deleted &&
    css`
      img {
        opacity: 0.4;
      }
    `}
`;

export const OccurrenceEvidenceHover = styled.div<IOccurrenceEvidenceImageContainer>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 1rem 0.5rem;
  gap: 0.5rem;

  button {
    width: 2rem;
    height: 2rem;
    border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
    background-color: ${(props) => props.theme.colors.divBackground};
    color: ${(props) => props.theme.colors.iconsSecondary};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border-radius: 100%;
    transition: all 0.2s ease;
    svg {
      width: 1.25rem;
      height: auto;
    }

    &:hover {
      transform: scale(1.05);
      filter: brightness(1.2);
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      opacity: 1;
    `};
  ${({ deleted }) =>
    deleted &&
    css`
      opacity: 1;
    `};

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export const DeletedRestore = styled.button<{ restore?: boolean }>`
  border: 1px solid red;
  ${({ restore }) =>
    restore &&
    css`
      border-radius: 6px !important;
      width: auto !important;
      gap: 0.25rem;
      span {
      }
    `}
`;

export const PositionIdContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  right: 0.5rem;
  bottom: 0.75rem;
  z-index: 10;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 1.75rem;
      height: auto;
    }
  }
`;

interface ISysStatusIdContainer {
  sysStatusId: 1 | 2 | 3 | 4 | 5;
}

export const SysStatusIdContainer = styled.div<ISysStatusIdContainer>`
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.5rem;
  border-radius: 0 4px 0 0;
  display: flex;

  height: 1rem;
  justify-content: center;
  align-items: center;
  background-color: ${({ sysStatusId, theme }) =>
    getStatusColorById(theme, sysStatusId)};

  span {
    ${({ theme }) => theme.fonts.primary.H5Regular};
    font-size: 0.65rem;
    color: ${(props) => props.theme.colors.statusText};
  }
`;

export const ZoomEvidenceContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const OpenPdfEvidence = styled.button`
  border: none;
  position: absolute;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  color: ${(props) => props.theme.colors.iconsSecondary};
  opacity: 0.75;
  transition: all 0.3s ease;
  z-index: 2;
  svg {
    width: 5rem;
    height: auto;
  }

  &:hover {
    opacity: 1;
  }
`;
