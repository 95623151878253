function findPrecision(range: number) {
  switch (true) {
    case range < 600000:
      return 60000;
    case range < 3600000:
      return 60000;
    case range < 14400000:
      return 600000;
    case range < 86400000:
      return 1800000;
    case range >= 86400000:
      return 3600000;
    default:
      return 1;
  }
}

export default function buildChartTicks(chartData: any) {
  if (chartData?.ticks) {
    return chartData.ticks;
  }

  const dates = (chartData?.data || []).map(([date]: any[]) => date);

  if (dates.length < 2) {
    return dates;
  }

  const minDate = Math.min(...dates);
  const maxDate = Math.max(...dates);
  const range = maxDate - minDate;
  const precision = findPrecision(range);

  const totalTicks = Math.ceil(range / precision);
  let ticks = [];
  for (let i = 0; i < totalTicks; i++) {
    ticks.push(Math.floor((minDate + i * precision) / precision) * precision);
  }

  return ticks.map((date) => new Date(date));
}
