import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.textPrimary};
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: 'Inter';

    &::-webkit-scrollbar { 
      width: 0;
      background: transparent;
    }
    scrollbar-width: none;

    overscroll-behavior: none;

    @media screen and (max-width:767px) {
      overflow: hidden;
    };

    @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
      overflow: hidden;
    };
    
  }

  button {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
    color: ${({ theme }) => theme.colors.iconsPrimary};
  }

  button.button_pattern_test {
    padding: 4px;
    color: white;
    
    &:hover {
      border:1px solid transparent;
    }
  }

  button.floating_icon_1{
    position: absolute;
    border-radius: 100%;
    background-color: ${(props) => props.theme.colors.cardBackground};
    color: ${(props) => props.theme.colors.iconsPrimary};
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    width: 32px;
    height: 32px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 18px;
    }
    &:hover {
      border: none;
      svg {
        font-size: 22px;
      }
    }
  }

  input {
    &:focus{
      outline: none;
    }
  }

  button.graphic_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 100%;
    padding: 3px;
    background-color: ${(props) => props.theme.colors.divBackground};
    border: 1px solid transparent !important;
    color: ${(props) => props.theme.colors.iconsSecondary};

    &:hover {
      border-radius: 6px;
      color: ${(props) => props.theme.colors.ibbxOrange};
      border: 1px solid ${(props) =>
        props.theme.colors.cardHighlight} !important;
    }

    &:active {
      color: ${(props) => props.theme.colors.ibbxOrange};
      background-color: ${(props) => props.theme.colors.cardHighlight};
      font-size: 25px;
      transform: scale(0.9);
    }
  }
 
  h1.form_title {
    width: 100%;
    text-align: left;
    ${(props) => props.theme.fonts.primary.H1Semibold};
    color: ${(props) => props.theme.colors.textPrimary};
    margin-bottom: 16px;

    @media screen and (max-width: 767px) {
      ${(props) => props.theme.fonts.primary.H2Bold};
    }
  }

  span.error {
    ${(props) => props.theme.fonts.secondary.H3Regular};
    color: red;
  }

 div {
  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media screen and (max-width: 767px) {
    &::-webkit-scrollbar {
      display: none;
    }
    -webkit-scrollbar-width: none;
    -moz-scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
 }

  div.gap_0_5{
    gap: 0.5rem;
  }

 div.gap_1{
  gap: 1rem;
 }

 div.gap_2{
  gap: 2rem;
 }

 div.form_row {
  display: flex;
  flex-direction: row;
  padding: 0;

  @media screen  and (max-width: 767px){
   flex-direction: column;
   gap: 1rem;

   & > div {
    width: 100%;
   }
  }
 }

 div.column {
  display: flex;
  flex-direction: column;
 }
`;

export const BUTTON_CLASSES = {
  FLOATING_ICON: "floating_icon_1",
};
