import { useEffect, useState } from "react";
import { GroupBase, StylesConfig } from "react-select";
import { components } from "react-select";
import { FiChevronDown } from "react-icons/fi";
import { CompanySelectProps } from "./types";
import { useTheme } from "styled-components";
import { Select } from "../../Select";
import { CompanySelectContainer } from "./styles";
import { useAppSelector } from "../../../store/hooks";
import { appSelect } from "../../../store/slices/appSlice";
import { useParams } from "react-router";

const CompanySelect = ({ options, handleChange }: CompanySelectProps) => {
  const { colors, fonts } = useTheme();
  const params = useParams();
  const { currentCompany } = useAppSelector(appSelect);

  const [companyName, setCompanyName] = useState<any>({
    value: "",
    label: "",
  });

  useEffect(() => {
    if (currentCompany.id) {
      setCompanyName({ value: currentCompany.id, label: currentCompany.name });
    } else {
      var company = options?.find(
        (item) => +item.value === +(params.companyId || 0),
      );
      setCompanyName(
        company ? { value: company.value, label: company.label } : null,
      );
    }
  }, [options, currentCompany, params.companyId]);

  const handleChangeValue = (value: any) => {
    handleChange(value);
  };

  const styles: StylesConfig<any, false, GroupBase<any>> = {
    control: (base) => ({
      ...base,
      backgroundColor: "transparent",
      borderColor: "transparent",
      outline: "none",
      color: colors.textPrimary,
      height: "29px",
      marginTop: "0px",
      borderRadius: "8px",
      minWidth: "270px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "transparent",
        outline: "none",
      },
    }),
    clearIndicator: (base) => ({
      ...base,
      backgroundColor: "inherit",
    }),
    menu: (base) => ({
      ...base,
      minWidth: "200px",
      overflow: "auto",
    }),
    singleValue: (base) => ({
      ...base,
      color: colors.textPrimary,
    }),
    menuList: (base) => ({
      ...base,
      background: colors.cardBackground,
      maxHeight: "200px",
      overflow: "auto",
    }),
    option: (base, props) => ({
      ...base,
      ...fonts.primary.H5Regular,
      color: colors.textPrimary,
      fontWeight: props.isSelected ? 600 : 400,
      backgroundColor: "inherit",
      "&:hover": {
        backgroundColor: colors.cardHighlight,
      },
    }),
    valueContainer: (base) => ({
      ...base,
      backgroundColor: "inherit",
      color: colors.textPrimary,
      ...fonts.primary.H3SemiBold,
    }),
    placeholder: (base) => ({
      ...base,
      color: colors.cardHighlight,
      ...fonts.primary.H4Bold,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      maxHeight: "50px",
    }),
  };

  return (
    <CompanySelectContainer>
      <Select
        value={companyName}
        options={options || []}
        handleChange={handleChangeValue}
        isSearchable
        isClearable={false}
        components={{
          DropdownIndicator: (props: any) => (
            <components.DropdownIndicator {...props}>
              <FiChevronDown size={20} />
            </components.DropdownIndicator>
          ),
        }}
      />
    </CompanySelectContainer>
  );
};

export default CompanySelect;
