import styled from "styled-components";
import { VscCalendar } from "react-icons/vsc";

export const Container = styled.div<{ absolute?: boolean }>`
  ${(props) => (props.absolute ? "position: absolute" : "")};
  top: -4px;

  right: 64px;
  /* height: 55px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  ${(props) => props.theme.fonts.primary.H5Bold};
  z-index: 100;

  & .DatePicker-container {
    background-color: ${(props) => props.theme.colors.cardBackground};
    color: ${(props) => props.theme.colors.iconsSecondary};
    border-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 6px;
    border: none;
    ${(props) => props.theme.fonts.primary.H5Bold}
    padding: 10px;
    outline: none !important;

    & > input {
      outline: none !important;
      border: none !important;

      &:focus-visible {
        outline: none !important;
      }
    }
  }

  & .react-datepicker__header {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-color: ${(props) => props.theme.colors.cardHighlight};
    & div {
      color: ${(props) => props.theme.colors.iconsSecondary} !important;
    }
  }

  & .DatePicker-container-calendar {
    background-color: ${(props) => props.theme.colors.cardBackground};
    border-color: ${(props) => props.theme.colors.cardHighlight};

    & .react-datepicker__day {
      color: ${(props) => props.theme.colors.iconsSecondary};

      &--selected {
        background-color: ${(props) => props.theme.colors.cardHighlight};
      }
    }
  }

  & .css-rgaq3d-menu {
    overflow-y: auto;
    overflow-x: hidden;

    & > div {
      &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px;
        @media screen and (max-width: 767px) {
          width: 3px !important;
        }
      }

      &::-webkit-scrollbar-track {
        background: ${(props) => props.theme.colors.background};
        width: 20px;
        border-radius: 100vw;
        margin-block: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
        height: 100%;
        max-height: 5rem;
        border-radius: 100vw;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.colors.iconsSecondary};
      }

      &::-webkit-scrollbar-thumb:active {
        background: ${(props) => props.theme.colors.iconsSecondary};
      }
    }
  }

  // Seletores de data e período dos gráficos globais
  @media screen and (max-width: 767px) {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: auto;
    flex-direction: column;
    margin-bottom: 0.5rem;

    & > div {
      width: 98%;
      margin: 0.5rem 0 0 0;
    }

    & .DatePicker-container {
      width: 100%;
      z-index: 2;
    }
  }
`;

export const CalendarIcon = styled(VscCalendar)`
  color: ${(props) => props.theme.colors.iconsSecondary};
`;

export const ContainerDatePicker = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.cardBackground};
  height: 38px;
  margin-right: 10px;
  border-color: ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  ${(props) => props.theme.fonts.primary.H5Bold}
  font-size: 16px !important;
  padding: 10px;
  z-index: 100;

  @media screen and (max-width: 767px) {
    font-size: 16px !important;
    position: relative;

    svg {
      position: absolute;
      right: 1rem;
    }
  }
`;

export const RenderLabelContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  width: 100%;
  height: auto;
  justify-content: center;
  cursor: pointer;

  & > div:nth-child(2) {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  }
  span {
    padding: 0.25rem;
  }
`;
