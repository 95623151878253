import { OwnerBadgeContainer } from "./styles";

interface IOwnerBadge {
  toolTiptitle?: string;
  badgeDescription: string;
  badgeColor: "NOC" | "INTEGRATOR" | "CLIENT" | null | undefined;
}

export function OwnerBadge({
  toolTiptitle,
  badgeDescription,
  badgeColor,
}: IOwnerBadge) {
  const analysisOwnerBadge = (owner: string) => {
    switch (owner) {
      case "NOC":
        return "NOC";
      case "INTEGRATOR":
        return "INT";
      case "CLIENT":
        return "USER";
      default:
        return "";
    }
  };

  const analysisOwnerOptions = [
    { value: null, label: "Sem Analista" },
    { value: "NOC", label: "NOC IBBX" },
    { value: "INTEGRATOR", label: "Integrador responsável" },
    { value: "CLIENT", label: "Cliente" },
  ];

  const selectedLabelOption = analysisOwnerOptions.find(
    (option) => option.value === toolTiptitle,
  );

  return (
    <OwnerBadgeContainer
      analysisOwner={badgeColor}
      title={selectedLabelOption && selectedLabelOption.label}
    >
      <span>{analysisOwnerBadge(badgeDescription)}</span>
    </OwnerBadgeContainer>
  );
}
