import styled from "styled-components";

export const ModalContainer = styled.div`
  height: 80vh;

  @media screen and (min-width: 768px) and (max-width: 1280px) {
    height: auto;
  }
  @media screen and (min-width: 827px) and (max-width: 1279px) {
    height: 65vh;
  }
`;

export const ExpandedChartTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) => ({ ...props.theme.fonts.primary.H1Regular })};
  margin: 0px 0 24px 0;

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    ${(props) => ({ ...props.theme.fonts.primary.H2Bold })}
  }
`;

export const ExpandedChartContainer = styled.div`
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};

  display: flex;
  width: 100%;

  & > div {
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: center;

    & > div {
      /* height: 100%; */
      /* overflow: hidden; */
    }
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
  }

  @media screen and (min-width: 827px) and (max-width: 1279px) {
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 5px;
  & > div {
    & > div {
      width: 150px;
      justify-content: flex-start;
      ${(props) => ({ ...props.theme.fonts.primary.H5SemiBold })};
    }
  }
`;

export const EmptyChart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  & span {
    margin-top: 10px;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.fonts.primary.H3SemiBold};
  font-weight: 400;
  & > div:first-child {
    width: 100%;
  }
`;

export const ExpandedEvidenceContainer = styled.div<{ isPdf?: boolean }>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & > div {
    width: 100%;
    max-height: 700px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  div > div {
    margin: auto;
    width: auto;
    height: 100%;
    cursor: ${({ isPdf }) => (isPdf ? "pointer" : "zoom-in")};
    &:active {
      cursor: grab;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
  label {
    ${(props) => props.theme.fonts.primary.H4Regular};
    color: ${(props) => props.theme.colors.iconsSecondary};
    padding: 0 0 0.5rem 0.2rem;
  }

  @media screen and (max-width: 767px) {
    height: auto;

    img {
      height: 18rem;
      width: 22rem;
    }
  }

  .pdf {
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    & > div {
      max-height: 568px;
    }
  }

  @media screen and (min-width: 827px) and (max-width: 1279px) {
    & > div {
      width: 100%;
      max-height: 28rem;

      img {
        aspect-ratio: auto;
        max-height: 28rem;
      }
    }
  }
`;

export const ExpandedEvidencePdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  cursor: pointer;

  & > svg {
    font-size: 4rem;
    color: ${({ theme }) => theme.colors.iconsSecondary};
  }

  span {
    ${({ theme }) => theme.fonts.primary.H2Bold};
    color: ${({ theme }) => theme.colors.iconsSecondary};
  }
`;

export const ExpandedEvidenceEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > span {
    margin-top: 20px;
    color: ${(props) => props.theme.colors3.color0};
    ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  }
`;
