import { IBearingStamps } from "../../pages/Analysis/StampsSelector";
import { addAnnotations, annotationsFirstRow } from "./general";

export function getBearingStamps(
  data: any,
  bearingStamps: IBearingStamps,
  frequencyUnit?: string,
) {
  var stampUsed = 0;

  var stampPosition: any = bearingStamps.stamps.sort(
    (a: any, b: any) => a.value - b.value,
  );

  var stampPositionHarmonic: any[] = [];

  const cpmFactor = frequencyUnit === "CPM" ? 60 : 1;
  const frequencyUnitLabel = frequencyUnit === "CPM" ? "cpm" : "Hz";

  for (let harmonica = 1; harmonica <= bearingStamps.harmonics; harmonica++) {
    for (let valor in stampPosition) {
      stampPositionHarmonic.push({
        stamp: stampPosition[valor].label.toUpperCase(),
        label: stampPosition[valor].label + " " + String(harmonica),
        value: stampPosition[valor].value * harmonica * cpmFactor,
      });
    }
  }

  stampPositionHarmonic = stampPositionHarmonic.sort(
    (a: any, b: any) => a.value - b.value,
  );

  stampPositionHarmonic = stampPositionHarmonic.filter((item) =>
    bearingStamps.visible.includes(item.stamp),
  );

  const harmonicData = data.reduce((agg: any, item: any, index: number) => {
    // Próximo valor do Array data

    var next = data[index + 1];

    // Próxima Harmônica para Plotar
    var nextHarmonic = stampPositionHarmonic[stampUsed];

    if (index === 0) {
      agg.push(addAnnotations(item, annotationsFirstRow));
    } else if (nextHarmonic && item[0] === nextHarmonic.value) {
      agg.push(
        addAnnotations(item, [
          `${nextHarmonic.value.toFixed(1)}${frequencyUnitLabel}      ${
            nextHarmonic.label
          }`,
          "teste2",
        ]),
      );

      stampUsed++;
    } else if (
      nextHarmonic &&
      item[0] < nextHarmonic.value &&
      (!next || next[0] > nextHarmonic.value)
    ) {
      const filterHarmonicsToAdd = stampPositionHarmonic.filter(
        (i: any) => i.value > item[0] && next && i.value < next[0],
      );

      filterHarmonicsToAdd.forEach((i: any) => {
        let newItem = [...item];
        newItem[0] = +i.value;

        agg.push(
          addAnnotations(newItem, [
            `${i.value.toFixed(1)} ${frequencyUnitLabel} ${i.label}`,
            "teste2",
          ]),
        );
        stampUsed++;
      });
      agg.push(addAnnotations(item, [null, null]));
    } else {
      agg.push(addAnnotations(item, [null, null]));
    }

    var sortedArray = agg.sort(function (a: any, b: any) {
      return b[0] - a[0];
    });

    return sortedArray;
  }, []);
  return harmonicData;
}
