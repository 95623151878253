export function extractProperty(array: any[], property: string) {
  return (array || []).map((item) => {
    return item[property];
  });
}

export function roundNumber(number: number, precision: number = 2) {
  if (number?.toString()) {
    return +number.toFixed(precision);
  }
}

export function formatDate(date: any, options: any = { type: "DEFAULT" }) {
  const { type, lang = options.lang || "pt-BR" } = options;
  if (!date) {
    return "";
  }

  let dateOptions: any = {
    dateStyle: "short",
  };

  if (type === "FULL") {
    dateOptions = { timeStyle: "short", dateStyle: "short" };
  }

  if (type === "HOUR") {
    dateOptions = { timeStyle: "short" };
  }

  if (type === "SHORT") {
    dateOptions = {
      month: "short",
      day: "numeric",
    };
  }

  if (!isNaN(date)) {
    date = +date;
  }

  try {
    return new Intl.DateTimeFormat(lang, dateOptions).format(new Date(date));
  } catch {
    return "";
  }
}

export function labeledNumber(number: any, singular: string, plural?: string) {
  const text = number === 1 ? singular : plural || singular;
  if (number) {
    return `${number} ${text}`;
  }
}

export function validateUrl(url: string) {
  // eslint-disable-next-line
  const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
  return !!regex.test(url);
}
export function validateAnalyticsUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

export function csvToJson(csvString: any) {
  const lines = csvString.split("\n");

  let result = [];
  var headers = lines[0].split(";");

  for (var i = 1; i < lines.length; i++) {
    var obj: any = {};
    var currentline = lines[i].split(";");

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  return result;
}

export function isNumValid(value: any) {
  if (!value?.toString()) {
    return false;
  }

  if (isNaN(value)) {
    return false;
  }

  if (+value < 0) {
    return false;
  }

  return true;
}

export function validNumOr(number: any, replace: any) {
  if (isNumValid(number)) {
    return +number;
  }

  return replace;
}

export function didChange(value1: any, value2: any) {
  switch (true) {
    case typeof value1 === "object" && typeof value2 === "object":
      return JSON.stringify(value1) !== JSON.stringify(value2);
    default:
      return value1 !== value2;
  }
}
