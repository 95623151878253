import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.divBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.75rem;

  strong {
    ${(props) => props.theme.fonts.primary.H3SemiBold};
  }

  p {
    ${(props) => props.theme.fonts.primary.H4Regular};
    margin-bottom: 0;
  }
  span {
    ${(props) => props.theme.fonts.primary.H4Regular};
  }

  @media screen and (max-width: 767px) {
    border: none;
    width: 100%;
    height: 70%;
    padding: 0;

    strong {
      font-size: 12px;
    }

    p {
      font-size: 10px;
    }
    span {
      font-size: 12px;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    p {
      font-size: 10px;
    }
    strong {
      font-size: 14px;
    }
    span {
      font-size: 12px;
    }
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  gap: 1rem;
  padding: 1rem;

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 8px;
    border: 2px solid ${(props) => props.theme.colors.lightOrange};
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const Body = styled.body`
  display: flex;
  flex: 1;
  background-color: transparent;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  border-radius: 8px;

  @media screen and (max-width: 767px) {
  }
`;

export const Section = styled.section`
  width: 9rem;
  height: 100%;
  background: ${(props) => props.theme.colors.passwordBackground};
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: 767px) {
    width: 4rem;
  }
`;

export const CustomizeMenuItem = styled.div<{ selected?: boolean }>`
  background: ${(props) => props.selected && props.theme.colors.divBackground};
  ${(props) => props.theme.fonts.primary.H4Regular}
  color: ${(props) => props.theme.colors.textPrimary};
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  svg {
    width: 2rem;
    height: auto;
    color: ${(props) => props.selected && props.theme.colors.textPrimary};
  }

  span {
    scale: ${(props) => props.selected && "1.04"};
  }

  &:hover {
    opacity: 0.7;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      animation: slideIn 0.3s ease-in-out;
    }
  }

  @keyframes slideIn {
    from {
      transform: scaleX(0);
      transform-origin: left;
    }
    to {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 8px;
    span {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  overflow: hidden;
`;

export const StandardContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const InstallContent = styled(StandardContent)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      & > a:last-child {
        color: ${(props) => props.theme.colors.iconsSecondaryAlpha};

        &:hover {
          color: ${(props) => props.theme.colors.iconsSecondary};
        }
        transition: all 0.3s ease;
      }
    }
  }

  img {
    /* width: 14rem; */
    height: 60%;
    border-radius: 8px;
    border: 3px solid white;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.iconsSecondary};
    gap: 1rem;
    padding: 1rem 2rem;
    border-radius: 8px;

    svg {
      width: 3rem;
      height: auto;
    }

    &:hover {
      border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
      filter: brightness(0.75);
      transition: all 0.3s ease;
    }
  }
  @media screen and (max-width: 767px) {
    & > div {
      flex-direction: column;
    }
  }
`;
export const ConnectContent = styled(StandardContent)`
  overflow: auto;
  padding: 0.5rem;
  gap: 1.5rem;
  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
    @media screen and (max-width: 767px) {
      width: 3px !important;
    }
  }
`;

export const StandardStepConnect = styled.div`
  min-width: 20rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  p {
    font-size: 12px;
  }

  & > div {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }

  & > div:first-child {
    gap: 0.5rem;
  }

  & > div:nth-child(2) {
    flex: 1;
    overflow: hidden auto;
    align-items: flex-start;
    img {
      width: 100%;
    }
  }
  & > div:last-child {
    height: 5rem;
    overflow: hidden auto;
    align-items: flex-start;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    p {
      font-size: 10px;
    }
    strong {
      font-size: 14px;
    }
    span {
      font-size: 12px;
    }

    & > div:last-child {
      height: 4rem;
    }
  }
`;
