import { useMemo } from "react";
import Chart from "react-google-charts";
import { useTheme } from "styled-components";
import getTooltip from "../../../utils/charts/getTooltip";
import { defaultChartArea } from "../chart/types";
import { ContainerChart } from "./styles";
import { ColumnChartProps, hourmeterChartArea } from "./types";
import { useTranslation } from "react-i18next";

export const ColumnChart = ({
  chart,
  chartData,
  seriesControl,
  hourmeter,
  data,
  options,
}: ColumnChartProps) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const dataMemo = useMemo(() => {
    if (data) {
      return data;
    }

    const { series: seriesRaw, data: dataRaw } = chartData || {};
    if (!chartData?.data.length) {
      return [];
    }

    type seriesTypeModel = {
      dataIndex: number;
      serieIndex: number;
      type: "VALUE" | "DATE" | "POINT" | "TOOLTIP";
    };

    let seriesType: seriesTypeModel[] = [];
    let series = seriesRaw?.reduce((acc, value, index) => {
      const dataIndex = index;
      acc.push(value);
      const serieIndex = seriesType.length;
      const type = index ? "VALUE" : "DATE";
      seriesType.push({ dataIndex, type, serieIndex });

      if (index > 0) {
        acc.push({ type: "string", role: "tooltip", p: { html: true } });
        seriesType.push({ dataIndex, type: "TOOLTIP", serieIndex });
      }

      return acc;
    }, []);

    let dataResult = dataRaw?.map((row: any[]) => {
      return seriesType.map((serieType) => {
        const { dataIndex, serieIndex, type } = serieType;

        if (type === "TOOLTIP") {
          if (row[dataIndex]?.toString()) {
            const serie = series[serieIndex];
            const value = row[dataIndex];
            const axisX = {
              value: row[0],
              unit: chart?.metricUnits.x,
            };
            const axisY = {
              value,
              unit: chart?.metricUnits.y,
            };
            return getTooltip({ chart, axisX, axisY, serie, t: t });
          } else {
            return null;
          }
        } else {
          return row[dataIndex];
        }
      });
    });

    return [series, ...(dataResult || [])];
    // eslint-disable-next-line
  }, [chart, chartData]);

  const seriesMemo = useMemo(() => {
    return seriesControl.reduce((acc: any, serie, index) => {
      const alarmLine = {
        type: "line",
        visibleInLegend: false,
        lineDashStyle: [2, 2, 20, 2, 20, 2],
        tooltip: false,
        pointsVisible: false,
        pointSize: 0,
        enableInteractivity: false,
      };

      acc[index] = {
        color: serie.visible ? serie.color : "none",
        tooltip: true,
        ...(serie.alarm ? alarmLine : {}),
      };

      return acc;
    }, {});
  }, [seriesControl]);

  const defaultHAxis = {
    minorGridlines: { color: "transparent" },
    direction: 1,
    textStyle: {
      fontSize: 10,
    },
  };

  const defaultVAxis = {
    minorGridlines: { color: "transparent" },
    textStyle: {
      fontSize: 10,
    },
    format: "short",
    viewWindowMode: "explicit",
  };

  const format = useMemo(() => {
    if (chartData?.period === "YEAR") {
      return "MM/yyyy";
    } else if (chartData?.period === "MONTH") {
      return "dd/MM";
    } else if (chartData?.period === "WEEK") {
      return "dd/MM";
    } else if (chartData?.period === "DAY") {
      return "HH:mm";
    } else {
      return "dd/MM/yyyy HH:mm";
    }
  }, [chartData?.period]);

  const optionsMemo = useMemo(() => {
    if (options) {
      return options;
    }
    var ticks = chartData?.data.map((val: any) => val[0]);

    return {
      chartArea: hourmeter ? hourmeterChartArea : defaultChartArea,
      backgroundColor: "transparent",
      aggregationTarget: "none",
      legend: "none",
      animation: {
        startup: true,
        duration: 700,
        easing: "out",
      },
      hAxis: {
        ...defaultHAxis,
        ticks,
        format,
        textStyle: {
          ...defaultHAxis.textStyle,
          color: colors.iconsSecondary,
        },
        gridlines: {
          color: colors.cardHighlight,
        },
      },
      vAxis: {
        ...defaultVAxis,
        textStyle: {
          ...defaultVAxis.textStyle,
          color: colors.iconsSecondary,
        },
        gridlines: {
          color: colors.cardHighlight,
        },
      },
      series: seriesMemo,
      tooltip: { isHtml: true },
      visibleInLegend: false,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [format, chartData, seriesMemo, colors]);

  return (
    <ContainerChart>
      <Chart
        chartType="ColumnChart"
        options={optionsMemo}
        chartLanguage={"pt-Br"}
        style={{ width: "inherit", height: "100%" }}
        data={dataMemo}
        width="100%"
      />
    </ContainerChart>
  );
};
