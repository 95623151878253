import { useEffect, useState } from "react";

import { useAppSelector } from "../../../store/hooks";
import { ESGForm } from "../../../modals/ESGForm";
import { CardEsgData } from "../CardEsgData";
import {
  CardEsg,
  CardsContainer,
  Co2Emission,
  Co2EmissionContent,
  Content,
  ContentTitle,
  EditButton,
  RetinaCoinContainer,
  ShowExtraInfo,
  SvgContainer,
} from "../styles";
import { Carousel } from "react-bootstrap";
import { NamePlusTooltip } from "../../../components/NamePlusTooltip";
import { MdClose, MdInfo, MdModeEdit } from "react-icons/md";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "../../../utils/queryString";
import { PROFILES, userSelect } from "../../../store/slices/userSlice";
import useApi from "../../../hooks/useApi";
import { renderExtraInformations } from "../EsgExtraInformations/renderExtraInformations";
import CountUp from "react-countup";

import { ReactComponent as HandWithTree } from "../../../images/hand-tree.svg";
import { ReactComponent as Co2 } from "../../../images/co2.svg";
import { ReactComponent as Battery } from "../../../images/battery.svg";
import { ReactComponent as ToxicTrash } from "../../../images/toxic-trash.svg";
import { ReactComponent as Data } from "../../../images/data.svg";
import { ReactComponent as EnergyCost } from "../../../images/energy-cost.svg";
import { ReactComponent as EnergySaving } from "../../../images/energy-saving.svg";
import { ReactComponent as RetinaCoinImage } from "../../../images/retina-coin.svg";
import { ReactComponent as RetinaCoinSymbol } from "../../../images/retina-coin-rc.svg";

import { CardExtraInfo } from "../EsgExtraInformations/CardExtraInfo";
import { GiWorld } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../components/Loading";
import { IoReturnUpBack } from "react-icons/io5";
// import { FaGlobeAmericas } from "react-icons/fa";
import { BsGlobeAmericas } from "react-icons/bs";
import { appSelect } from "../../../store/slices/appSlice";

export function RenderContent() {
  const { isReadOnly } = useAppSelector(appSelect);
  const { profile, company } = useAppSelector(userSelect);
  const { t } = useTranslation();

  const [showCardExtaInfo, setShowCardExtaInfo] = useState(
    new Array(7).fill(false),
  );

  const handleShowExtraInformation = (index: number) => {
    setShowCardExtaInfo((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const location = useLocation();

  const companyId = location.search
    ? queryString.parse(location.search)?.companyId
    : "ibbx";

  const { request, processing } = useApi({
    path: `/companies/${companyId}/esg-summary`,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (
      [PROFILES.CLIENTE_COMUM].includes(profile) &&
      +company !== +(companyId || 0)
    ) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const [esgSummary, setEsgSummary] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [showCo2Info, setShowCo2Info] = useState(false);

  const getEsgNumbers = () => {
    request({ method: "get" }).then((response) => setEsgSummary(response));
  };

  const handleOpenModal = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);

    if (!esgSummary?.averageKWhConsumption) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (!isMobileOnly) {
      setShowCo2Info(true);

      const previewCo2Info = setTimeout(() => {
        setShowCo2Info(false);
      }, 5000);

      return () => clearTimeout(previewCo2Info);
    }
  }, []);

  useEffect(() => {
    getEsgNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      esgSummary &&
      !esgSummary?.averageKWhConsumption &&
      companyId &&
      companyId !== "ibbx" &&
      !isReadOnly
    ) {
      setShow(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esgSummary]);

  const strignSavingEnergyValue = esgSummary?.savingsEnergy.toString();
  const countSavingEnergyValue = strignSavingEnergyValue?.length;

  const savingsEnergyFormattedValue =
    esgSummary?.savingsEnergy.toLocaleString("pt-BR");

  const savingsEnergyFormattedValueNumber = parseFloat(
    savingsEnergyFormattedValue,
  );

  const dropSavingEnergyValue = esgSummary?.savingsEnergy.toLocaleString(
    "pt-BR",
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  );

  const retinaCoinScore =
    Number(esgSummary?.compensatedTrees) +
    Number(esgSummary?.nonDiscartedBatteryPerYear);

  // eslint-disable-next-line
  const renderRetinaCoin = () => {
    return (
      <RetinaCoinContainer>
        <div>
          <span>{t("esg.retinaCoinBalance")}</span>
          {companyId !== "ibbx" && (
            <NamePlusTooltip
              tooltip={`${t("esg.editAvgCostPerkWh")}`}
              position={"left"}
            >
              <button onClick={handleOpenModal}>
                <MdModeEdit />
              </button>
            </NamePlusTooltip>
          )}
        </div>
        <div>
          <div>
            <RetinaCoinSymbol />
            <span>
              <CountUp
                start={retinaCoinScore || 0}
                end={retinaCoinScore}
                duration={6}
                decimals={2}
                separator={"."}
              />
            </span>
          </div>
          <RetinaCoinImage />
        </div>
      </RetinaCoinContainer>
    );
  };

  const StandardInfo = (
    <>
      <strong style={{ fontSize: "14px" }}>{t("esg.co2Emission")}</strong>

      <p style={{ marginTop: "0.4rem", fontSize: "0.7rem" }}>
        {`${t("esg.esgIbbxDashboardDescription.part1")} `}
        <a href="https://ghgprotocol.org/" target="_blank" rel="noreferrer">
          GHG Protocol
        </a>
        {` ${t("esg.esgIbbxDashboardDescription.part2")}`}
      </p>
    </>
  );

  // eslint-disable-next-line
  const sourceInfoCard = () => {
    return (
      <CardEsg noPointerCursor>
        <>
          <SvgContainer style={{ backgroundColor: " #e6eee5" }}>
            <GiWorld />
            <footer
              style={{
                background:
                  "linear-gradient(0deg, #d4dcd2 0.04%, #d1ded2 100%)",
              }}
            />
          </SvgContainer>
          <CardEsgData decimals={2} contentDescription={StandardInfo} />
        </>
      </CardEsg>
    );
  };

  const compensatedTrees = () => {
    return (
      <CardEsg onClick={() => handleShowExtraInformation(0)}>
        {showCardExtaInfo[0] ? (
          <>
            <CardExtraInfo
              title={t(renderExtraInformations[0].title)}
              content={t(renderExtraInformations[0].text)}
              link1={renderExtraInformations[0].link1}
            />
            <ShowExtraInfo title="Voltar">
              <IoReturnUpBack />
            </ShowExtraInfo>
          </>
        ) : (
          <>
            <ShowExtraInfo title="Entenda o cálculo">
              <MdInfo />
            </ShowExtraInfo>
            <SvgContainer style={{ backgroundColor: " #d5eed0" }}>
              <HandWithTree className="tree-compensated" />
              <footer
                style={{
                  background:
                    "linear-gradient(0deg, #d5eed0 0.04%, #9dc3a1 100%)",
                }}
              />
            </SvgContainer>
            <CardEsgData
              value={esgSummary?.compensatedTrees}
              description={`${t("esg.plywoodTrees.title")}`}
              decimals={2}
            />
          </>
        )}
      </CardEsg>
    );
  };

  const tonCO2Compensated = () => {
    return (
      <CardEsg onClick={() => handleShowExtraInformation(1)}>
        {showCardExtaInfo[1] ? (
          <>
            <CardExtraInfo
              title={t(renderExtraInformations[1].title)}
              content={t(renderExtraInformations[1].text)}
              link1={renderExtraInformations[1].link1}
            />
            <ShowExtraInfo title="Voltar">
              <IoReturnUpBack />
            </ShowExtraInfo>
          </>
        ) : (
          <>
            <ShowExtraInfo title="Entenda o cálculo">
              <MdInfo />
            </ShowExtraInfo>
            <SvgContainer style={{ backgroundColor: " #CEF1FF" }}>
              <Co2 className="co2" />
              <Co2 className="co2-2" />
              <Co2 className="co2-3" />
              <footer
                style={{
                  background:
                    "linear-gradient(0deg, #CEF1FF 0.04%, #8DCCE7 100%)",
                }}
              />
            </SvgContainer>
            <CardEsgData
              value={esgSummary?.tonCO2Compensated}
              description={`${t("esg.tonOfCO2Compensated.title")}`}
              decimals={2}
            />
          </>
        )}
      </CardEsg>
    );
  };

  const nonDiscartedBatteryPerYear = () => {
    return (
      <CardEsg onClick={() => handleShowExtraInformation(2)}>
        {showCardExtaInfo[2] ? (
          <>
            <CardExtraInfo
              title={t(renderExtraInformations[2].title)}
              content={t(renderExtraInformations[2].text)}
              link1={renderExtraInformations[2].link1}
              link2={renderExtraInformations[2].link2}
              link2Name={`${t("esg.calculator")}`}
            />
            <ShowExtraInfo title="Voltar">
              <IoReturnUpBack />
            </ShowExtraInfo>
          </>
        ) : (
          <>
            <ShowExtraInfo title="Entenda o cálculo">
              <MdInfo />
            </ShowExtraInfo>
            <SvgContainer style={{ backgroundColor: " #FFF1CF" }}>
              <Battery className="battery" />
              <footer
                style={{
                  background:
                    "linear-gradient(0deg, #FFF1CF 0.04%, #FEE19A 100%)",
                }}
              />
            </SvgContainer>
            <CardEsgData
              value={esgSummary?.nonDiscartedBatteryPerYear}
              description={`${t("esg.batteriesNotDiscarded.title")}`}
              decimals={0}
            />
          </>
        )}
      </CardEsg>
    );
  };

  const toxicTrashNonDiscarded = () => {
    return (
      <CardEsg onClick={() => handleShowExtraInformation(3)}>
        {showCardExtaInfo[3] ? (
          <>
            <CardExtraInfo
              title={t(renderExtraInformations[3].title)}
              content={t(renderExtraInformations[3].text)}
              link1={renderExtraInformations[3].link1}
            />
            <ShowExtraInfo title="Voltar">
              <IoReturnUpBack />
            </ShowExtraInfo>
          </>
        ) : (
          <>
            <ShowExtraInfo title="Entenda o cálculo">
              <MdInfo />
            </ShowExtraInfo>
            <SvgContainer style={{ backgroundColor: " #FFD4CA" }}>
              <ToxicTrash className="toxic-trash" />
              <footer
                style={{
                  background:
                    "linear-gradient(0deg, #FFD4CA 0.04%, #F4B9AB 100%)",
                }}
              />
            </SvgContainer>
            <CardEsgData
              value={esgSummary?.toxicTrashNonDiscarded.toFixed(1)}
              infoRight="Kg"
              description={`${t("esg.undisposedToxicWaste.title")}`}
              decimals={2}
            />
          </>
        )}
      </CardEsg>
    );
  };

  const savedEnergyKWAh = () => {
    return (
      <CardEsg onClick={() => handleShowExtraInformation(4)}>
        {showCardExtaInfo[4] ? (
          <>
            <CardExtraInfo
              title={t(renderExtraInformations[4].title)}
              content={t(renderExtraInformations[4].text)}
            />
            <ShowExtraInfo title="Voltar">
              <IoReturnUpBack />
            </ShowExtraInfo>
          </>
        ) : (
          <>
            <ShowExtraInfo title="Entenda o cálculo">
              <MdInfo />
            </ShowExtraInfo>
            <SvgContainer style={{ backgroundColor: " #DFF1FF" }}>
              <EnergySaving className="energy-saving" />
              <footer
                style={{
                  background:
                    "linear-gradient(0deg, #DFF1FF 0.04%, #B2D4EF 100%)",
                }}
              />
            </SvgContainer>
            <CardEsgData
              value={esgSummary?.savedEnergyKWAh}
              infoRight="KWAh"
              description={`${t("esg.energySaved.title")}`}
              decimals={0}
            />
          </>
        )}
      </CardEsg>
    );
  };

  const savingsEnergy = () => {
    return (
      <CardEsg onClick={() => handleShowExtraInformation(5)}>
        {showCardExtaInfo[5] ? (
          <>
            <CardExtraInfo
              title={t(renderExtraInformations[5].title)}
              content={t(renderExtraInformations[5].text)}
            />
            <ShowExtraInfo title="Voltar">
              <IoReturnUpBack />
            </ShowExtraInfo>
          </>
        ) : (
          <>
            <ShowExtraInfo title="Entenda o cálculo">
              <MdInfo />
            </ShowExtraInfo>
            <SvgContainer style={{ backgroundColor: " #BFFFCD" }}>
              <EnergyCost className="energy-cost" />
              <footer
                style={{
                  background:
                    "linear-gradient(0deg, #BFFFCD 0.04%, #91E5B3 100%)",
                }}
              />
            </SvgContainer>
            <CardEsgData
              kwh={countSavingEnergyValue}
              infoLeft="R$"
              energySavingValue={`R$${dropSavingEnergyValue}`}
              infoRight={
                esgSummary?.savingsEnergy > 999
                  ? "Mil"
                  : esgSummary?.savingsEnergy > 1000000
                  ? "Mi"
                  : ""
              }
              value={savingsEnergyFormattedValueNumber}
              description={`${t("esg.ofEnergySaving.title")}`}
            />
          </>
        )}
      </CardEsg>
    );
  };

  const closedRatedOccurrece = () => {
    return (
      <CardEsg onClick={() => handleShowExtraInformation(6)}>
        {showCardExtaInfo[6] ? (
          <>
            <CardExtraInfo
              title={t(renderExtraInformations[6].title)}
              content={t(renderExtraInformations[6].text)}
            />
            <ShowExtraInfo title="Voltar">
              <IoReturnUpBack />
            </ShowExtraInfo>
          </>
        ) : (
          <>
            <ShowExtraInfo title="Entenda o cálculo">
              <MdInfo />
            </ShowExtraInfo>
            <SvgContainer style={{ backgroundColor: " #FFC59B" }}>
              <Data className="data-decision" />
              <footer
                style={{
                  background:
                    "linear-gradient(0deg, #FFC59B 0.04%, #F3A771 100%)",
                }}
              />
            </SvgContainer>
            <CardEsgData
              value={esgSummary?.closedRatedOccurrece}
              description={`${t("esg.dataBasedDecisionMaking.title")}`}
              decimals={0}
            />
          </>
        )}
      </CardEsg>
    );
  };

  const renderCardsMobile = () => {
    return (
      <Carousel>
        <Carousel.Item>{compensatedTrees()}</Carousel.Item>
        <Carousel.Item>{tonCO2Compensated()}</Carousel.Item>
        <Carousel.Item>{nonDiscartedBatteryPerYear()}</Carousel.Item>

        <Carousel.Item>{toxicTrashNonDiscarded()}</Carousel.Item>

        <Carousel.Item>{savedEnergyKWAh()}</Carousel.Item>

        <Carousel.Item>{savingsEnergy()}</Carousel.Item>

        <Carousel.Item>{closedRatedOccurrece()}</Carousel.Item>
      </Carousel>
    );
  };

  const renderCards = () => {
    return (
      <>
        {compensatedTrees()}
        {tonCO2Compensated()}
        {nonDiscartedBatteryPerYear()}
        {toxicTrashNonDiscarded()}
        {savedEnergyKWAh()}
        {savingsEnergy()}
        {closedRatedOccurrece()}
      </>
    );
  };

  return (
    <Content>
      {companyId !== "ibbx" && !isReadOnly && (
        <EditButton
          onClick={handleOpenModal}
          title={`${t("esg.editAvgCostPerkWh")}`}
        >
          <MdModeEdit size={22} />
        </EditButton>
      )}

      <Co2Emission
        title={showCo2Info ? "" : `${t("esg.co2Emission")}`}
        onClick={() => setShowCo2Info(!showCo2Info)}
        showCo2Info={showCo2Info}
      >
        {showCo2Info ? (
          <MdClose />
        ) : (
          <>
            <span>?</span>
            <BsGlobeAmericas />
          </>
        )}
      </Co2Emission>

      <Co2EmissionContent showInfo={showCo2Info}>
        {StandardInfo}
      </Co2EmissionContent>

      <ContentTitle>
        <span>{t("esg.title.part1")}</span>
        <span>{t("esg.title.part2")}</span>
      </ContentTitle>
      <CardsContainer>
        {isMobile ? (
          <>{renderCardsMobile()}</>
        ) : (
          <>{processing ? <Loading height="100%" /> : renderCards()}</>
        )}
      </CardsContainer>
      {show && (
        <ESGForm
          show={show}
          handleClose={handleClose}
          setShow={setShow}
          averageKWhConsumption={esgSummary?.averageKWhConsumption}
          refresh={getEsgNumbers}
        />
      )}
    </Content>
  );
}
