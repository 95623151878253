import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { userSelect } from "../store/slices/userSlice";
import { appSelect } from "../store/slices/appSlice";

type RouteProps = {
  children: JSX.Element;
  isGuest?: boolean;
  isPrivate?: boolean;
  isTerms?: boolean;
  isNewPassword?: boolean;
  role?: string[];
};

export const Route = ({
  children,
  isGuest,
  isPrivate,
  isNewPassword,
  isTerms,
  role,
}: RouteProps) => {
  const user = useAppSelector(userSelect);
  const app = useAppSelector(appSelect);
  const location = useLocation();

  if (isGuest && user.id !== "") {
    return <Navigate to="/" />;
  }

  if (isPrivate && user.id === "") {
    let from = encodeURI(`${location.pathname}${location.search}`);
    if (from.includes("logout") || from.includes("clear-cache")) {
      from = "/";
    }
    return <Navigate to={`/login?from=${from}`} />;
  }

  if (isNewPassword && user.loginNewPassword) {
    const from = encodeURI(`${location.pathname}${location.search}`);
    return <Navigate to={`/new-password?from=${from}`} />;
  }

  if (isTerms && user.id === "") {
    return <Navigate to="/login" />;
  }

  if (isPrivate && user.terms !== 1) {
    const from = encodeURI(`${location.pathname}${location.search}`);
    return <Navigate to={`/terms?from=${from}`} />;
  }

  if (role && !role.includes(user.profile)) {
    return <Navigate to={app.homeUrl} />;
  }

  return children;
};
