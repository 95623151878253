import { forwardRef, useImperativeHandle, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaMapMarkedAlt } from "react-icons/fa";
import { useLocation, useParams } from "react-router";
import useApi from "../../../hooks/useApi";
import { useAppSelector } from "../../../store/hooks";
import { appSelect } from "../../../store/slices/appSlice";
import theme from "../../../styles/themes/default";
import { getUrlPattern } from "../../../utils/getUrlPattern";
import { FrameFiltersMapsDiv, FrameMapsIcon } from "./styles";
import { useSegment } from "../../../hooks/useSegmentTrackEvent ";

const RetinaMapsButton = forwardRef(
  (
    {
      hide,
      size,
    }: {
      hide?: boolean;
      size?: string;
    },
    ref,
  ) => {
    const app = useAppSelector(appSelect);

    const location = useLocation();
    const params = useParams();
    const { handleTrackEvent } = useSegment();

    const urlPattern = getUrlPattern(location, params);

    const [processing, setProcessing] = useState(false);

    const companyId = urlPattern === "/" ? 0 : app.currentCompany.id;
    const { request } = useApi({
      path: `/companies/${companyId}/hopara-token`,
    });

    const handleClick = async () => {
      if (!processing) {
        setProcessing(true);
        const token = await request({ method: "get" });

        try {
          await fetch(`${process.env.REACT_APP_HOPARA_AUTH}/persist-token`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({ token }),
            headers: {
              "Content-Type": "application/json",
            },
          });

          let path = `${process.env.REACT_APP_HOPARA_LINK}/visualization/${
            companyId || ""
          }`;

          handleTrackEvent({
            event: "Retina Maps (Hopara)",
          });

          window.open(path, "_blank");
          setProcessing(false);
        } catch (err) {
          console.error(err);
        }
      }
    };

    useImperativeHandle(ref, () => ({
      click() {
        handleClick();
      },
    }));

    return (
      <>
        <FrameFiltersMapsDiv onClick={() => !ref && handleClick()} hide={hide}>
          {!processing ? (
            <>
              <FrameMapsIcon>
                <FaMapMarkedAlt color={theme.colors.textPrimary} size={size} />
              </FrameMapsIcon>
            </>
          ) : (
            <div className="spinner">
              <Spinner animation="border" size="sm" />
            </div>
          )}
        </FrameFiltersMapsDiv>
      </>
    );
  },
);

export default RetinaMapsButton;
