import { useNavigate, useParams } from "react-router-dom";
import { PROFILES, userLogout, userSelect } from "../store/slices/userSlice";
import { useTranslation } from "react-i18next";
import { ICompany } from "../pages/Facilities/types";
import { IFacility } from "../pages/Assets/types";
import { IAsset } from "../pages/Asset/types";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  appSelect,
  appSetBreadcrumb,
  appSetCurrentAsset,
  appSetCurrentCompany,
  appSetCurrentFacility,
  appSetCurrentPosition,
  setAppDeniedPermissions,
  setAppIsReadOnly,
  setContract,
} from "../store/slices/appSlice";
import { error, warning } from "../components/Toast";
import { IPosition } from "../store/interfaces";
import { useCallback, useState } from "react";
import useRequest from "./useRequest";

function usePageLevel(path: string) {
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelect);
  const app = useAppSelector(appSelect);
  const { t } = useTranslation();

  const [processing, setProcessing] = useState(false);

  const { request, cancel } = useRequest({ path });

  function loadMaster() {
    dispatch(appSetCurrentCompany(null));
    dispatch(setContract("BOTH"));
  }

  function putCompany(id: number, name: string, response: any) {
    dispatch(appSetCurrentCompany({ ...response, id, name }));
  }

  function putFacility(id: number, name: string, response: any) {
    dispatch(appSetCurrentFacility({ ...response, id, name }));
  }

  function putAsset(id: number, name: string, response: any) {
    dispatch(appSetCurrentAsset({ ...response, id, name }));
  }

  function handleError(err: any) {
    if (err.toString().includes("Network Error")) {
      warning(t("dictionary.labels.No internet connection.") as string);
      return "Network Error";
    }
  }

  const loadCompany = useCallback(() => {
    setProcessing(true);
    request
      .then(({ id, name, ...response }: ICompany) => {
        // Handle Permissions
        dispatch(setAppIsReadOnly(response.isReadOnly || false));
        dispatch(setAppDeniedPermissions(response.deniedPermissions || {}));

        putCompany(id, name, response);

        dispatch(setContract(response.contract ?? "PaaS"));
      })
      .catch((err) => {
        if (
          err.message !== "Cancel Request" &&
          params.companyId?.toString() !== user.company?.toString()
        ) {
          navigate("/");
        }

        handleError(err);
      })
      .finally(() => {
        setProcessing(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadFacility() {
    setProcessing(true);
    request
      .then(({ id, name, ...response }: IFacility) => {
        // Handle Permissions
        dispatch(setAppIsReadOnly(response.isReadOnly || false));
        dispatch(setAppDeniedPermissions(response.deniedPermissions || {}));

        putCompany(response.companyId, response.companyName, response);
        putFacility(id, name, response);
        dispatch(setContract(response.contract ?? "PaaS"));
      })
      .catch((err) => {
        if (err.message === "Cancel Request") {
          return;
        }

        handleError(err);

        error(`${t("assets.errorFacilityNotFound")}`);
        // if ([PROFILES.CLIENTE_COMUM].includes(user.profile)) {
        //   dispatch(userLogout()).unwrap();
        // } else {
        //   navigate("/");
        // }

        if(err.message !== "Cancel Request" && 
          params.facilityId?.toString() !== user.facility?.toString()
        ) {
          navigate("/");
        } 
        
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  function loadAsset() {
    setProcessing(true);

    request
      .then(({ id, name, ...response }: IAsset) => {
        // Handle Permissions
        dispatch(setAppIsReadOnly(response.isReadOnly || false));
        dispatch(setAppDeniedPermissions(response.deniedPermissions || {}));

        putCompany(response.companyId, response.companyName, response);
        putFacility(response.facilityId, response.facilityName, response);
        putAsset(id, name, response);
      })
      .catch((err) => {
        if (err.message !== "Cancel Request") {
          navigate("/");
        }

        handleError(err);
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  function loadPosition(onLoad?: Function) {
    setProcessing(true);
    request
      .then((response: IPosition) => {
        if (onLoad) {
          onLoad(response);
        }

        // Handle Permissions
        dispatch(setAppIsReadOnly(response.isReadOnly || false));
        dispatch(setAppDeniedPermissions(response.deniedPermissions || {}));

        if (response.companyId !== app.currentCompany.id) {
          putCompany(response.companyId, response.companyName, response);
        }

        if (response.facilityId !== app.currentFacility.id) {
          putFacility(response.facilityId, response.facilityName, response);
        }

        if (response.assetId !== app.currentAsset.id) {
          putAsset(response.assetId, response.assetName, response);
        }

        dispatch(appSetCurrentPosition(response));
      })
      .catch((err) => {
        const error = handleError(err);
        if (error !== "Network Error") {
          return navigate("/");
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  return {
    loadMaster,
    loadCompany,
    loadFacility,
    loadAsset,
    loadPosition,
    cancelRequest: cancel,
    levelProcessing: processing,
  };
}

export default usePageLevel;
