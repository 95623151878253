import { IUserSlice } from "../store/interfaces";
import { PROFILES, getProfile } from "../store/slices/userSlice";

export function isUserClient(profile: string) {
  return [
    PROFILES.CLIENTE_COMUM,
    PROFILES.CLIENTE_MASTER,
    PROFILES.CLIENTE_MASTER_VIEW,
  ].includes(profile);
}

export function isUserAnyClienteMaster(profile: string) {
  return [PROFILES.CLIENTE_MASTER, PROFILES.CLIENTE_MASTER_VIEW].includes(
    profile,
  );
}

export function isUserClienteMaster(profile: string) {
  return PROFILES.CLIENTE_MASTER === profile;
}

export function isUserClienteComum(profile: string) {
  return PROFILES.CLIENTE_COMUM === profile;
}

export function isUserAdministrator(profile: string) {
  return [
    PROFILES.EXECUTIVO_MASTER,
    PROFILES.MESA_MASTER,
    PROFILES.REPRESENTANTE,
  ].includes(profile);
}

export function isUserIbbx(profile: string) {
  return [PROFILES.EXECUTIVO_MASTER, PROFILES.MESA_MASTER].includes(profile);
}

export function isUserAnalyst(profile: string) {
  return [
    PROFILES.EXECUTIVO_MASTER,
    PROFILES.MESA_MASTER,
    PROFILES.MESA_ANALISTA,
    PROFILES.REPRESENTANTE,
  ].includes(profile);
}

export function isUserRepresentative(profile: string) {
  return PROFILES.REPRESENTANTE === profile;
}

export function isReadOnly(profile: string) {
  return profile === PROFILES.CLIENTE_MASTER_VIEW;
}

export const getUserHome = (profile: string, user: any) => {
  switch (true) {
    // Acesso a todas companies e facilities
    case isUserAnalyst(profile):
      return "companies";
    // Acesso a todas facilities da mesma company
    case isUserAnyClienteMaster(profile):
      return "facilities";
    // Acesso a 1 ou mais unidade da mesma copany
    case isUserClienteComum(profile):
      // Se Cliente Comum tiver apenas uma unidade
      if (user.userFacilities?.length === 1) {
        return "assets";
      }
      return "facilties";
    default:
      return "assets";
  }
};

export function getUserHomeFromUrl(homeUrl: string) {
  if (homeUrl.includes("assets")) {
    return "assets";
  }

  if (homeUrl.includes("facilities")) {
    return "facilities";
  }

  return "companies";
}

export function getUserHomeUrl({
  profile,
  company,
  facility,
  user,
}: {
  profile: string;
  company: string;
  facility: string;
  user: any;
}) {
  switch (true) {
    // Acesso a todas companies e facilities
    case isUserAnalyst(profile):
      return "/";
    // Acesso a todas facilities da mesma company
    case isUserClienteMaster(profile):
      const { userCompanies } = user;
      const firstCompanyId = (userCompanies || [])[0]?.companyId;
      // Se Cliente Master tiver apenas uma empresa
      if (userCompanies?.length === 1) {
        return `/companies/${firstCompanyId}/facilities`;
      }
      return `/`;
    case isUserAnyClienteMaster(profile):
      return `/companies/${company}/facilities`;
    // Acesso a 1 ou mais unidade da mesma copany
    case isUserClienteComum(profile):
      const { userFacilities } = user;
      const firstFacilityId = (userFacilities || [])[0]?.facilityId;
      // Se Cliente Comum tiver apenas uma unidade
      if (userFacilities?.length === 1) {
        return `/companies/${company}/facilities/${firstFacilityId}/assets`;
      }
      return `/companies/${company}/facilities`;
    default:
      return "/";
  }
}

function setUserProfile(args: any) {
  const profile = getProfile(args);

  return {
    profile: profile,
    isClient: isUserClient(profile),
    admin: isUserAdministrator(profile),
    ibbx: isUserIbbx(profile),
    isAnalyst: isUserAnalyst(profile),
    readonly: isReadOnly(profile),
  };
}

export function parseUserAttributes(userAttributes: any, loggedUser: any) {
  const userInfo = userAttributes.reduce((acc: any, att: any) => {
    return {
      ...acc,
      [att.Name]: att.Value,
    };
  }, {});

  // Verify if user has accpet terms
  const { zoneinfo } = userInfo;
  const { terms } = JSON.parse(zoneinfo);

  const result: Omit<
    IUserSlice,
    | "home"
    | "homeUrl"
    | "loginMessage"
    | "loginNewPassword"
    | "loginTmpPassword"
  > = {
    id: userInfo.sub,
    name: userInfo.name,
    email: userInfo.email,
    company: userInfo.family_name,
    facility: userInfo.given_name,
    terms: terms ? 1 : 0,
    ...setUserProfile(loggedUser.profile),
  };

  return result;
}

export function parseUserNewPassword(payload: any) {
  const userAttributes = payload.challengeParam.userAttributes;

  const result: Omit<
    IUserSlice,
    | "home"
    | "homeUrl"
    | "loginMessage"
    | "loginNewPassword"
    | "loginTmpPassword"
  > = {
    id: userAttributes.username,
    name: userAttributes.name,
    email: userAttributes.email,
    company: userAttributes.family_name,
    facility: userAttributes.given_name,
    terms: 0,
    ...setUserProfile(userAttributes.profile),
  };

  return result;
}

export function parseUserLogin() {}
