import { ReactNode, useRef, useState } from "react";

import { Collapse } from "react-bootstrap";

import {
  Container,
  Content,
  Header,
  MenuContainer,
  OpenMenuButton,
} from "./styles";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import useOnClickOutSide from "../../../../../hooks/useOnClickOutside";
import { useAppSelector } from "../../../../../store/hooks";
import { userSelect } from "../../../../../store/slices/userSlice";
import { appSelect } from "../../../../../store/slices/appSlice";

interface IAccordion {
  label?: string;
  icon?: ReactNode;
  children: ReactNode;
  moveUp?: any;
  moveDown?: any;
  moveFirst?: any;
  moveLast?: any;
  handleDeleteTab?: any;
  hiddenTab?: any;
  handleHideTab?: any;
  isBlock?: boolean;
  index?: number;
  createdBy?: string;
  tabId?: any;
  createdByName?: string;
  createdDate?: any;
}

export function AccordionSection({
  label,
  icon,
  children,
  handleDeleteTab,
  moveLast,
  moveFirst,
  moveDown,
  moveUp,
  handleHideTab,
  hiddenTab,
  isBlock,
  index,
  createdBy,
  createdByName,
  tabId,
  createdDate,
}: IAccordion) {
  const { isReadOnly } = useAppSelector(appSelect);
  const user = useAppSelector(userSelect);
  const { id: userId } = user;

  const [openColapse, setOpenColapse] = useState(hiddenTab ? false : true);
  const [openMenu, setOpenMenu] = useState(false);

  const buttonRef = useRef(null);

  useOnClickOutSide(buttonRef, () => setOpenMenu(false));

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const date = new Date(createdDate);
  const formattedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;

  return (
    <Container>
      <Header isHidden={hiddenTab}>
        <div onClick={() => setOpenColapse(!openColapse)} style={{ flex: "1" }}>
          {openColapse ? (
            <MdOutlineKeyboardArrowUp
              aria-controls="collapse"
              onClick={() => setOpenColapse(!openColapse)}
            />
          ) : (
            <MdOutlineKeyboardArrowDown
              aria-controls="collapse"
              onClick={() => setOpenColapse(!openColapse)}
            />
          )}

          <div>{icon}</div>
          <span>{label}</span>
          {createdByName && <p>{`Criado por: ${createdByName}`}</p>}
          <p>{`Data: ${formattedDate}`}</p>
        </div>
        {!isReadOnly && (
          <OpenMenuButton onClick={handleOpenMenu} ref={buttonRef}>
            {<BsThreeDotsVertical />}
            {openMenu && (
              <MenuContainer isHidden={hiddenTab}>
                <div onClick={moveFirst}>Mover para o primeira</div>
                <div onClick={moveUp}>Mover para cima</div>
                <div onClick={moveDown}>Mover para baixo</div>
                <div onClick={moveLast}>Mover para o final</div>

                {isBlock || createdBy !== userId ? (
                  <div onClick={() => handleHideTab(!hiddenTab, index, tabId)}>
                    {hiddenTab ? "Voltar aba" : "Esconder aba"}
                  </div>
                ) : (
                  <div onClick={handleDeleteTab}>Excluir aba</div>
                )}
              </MenuContainer>
            )}
          </OpenMenuButton>
        )}
      </Header>
      <Collapse in={openColapse}>
        <Content isHidden={hiddenTab} id="collapse">
          {children}
        </Content>
      </Collapse>
    </Container>
  );
}
