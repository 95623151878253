import { ReactGoogleChartEvent } from "react-google-charts";
import { useTheme } from "styled-components";
import { useEffect, useMemo, useState, memo, Fragment } from "react";
import { isMobile } from "../../../utils/mediaQuery";

import { ChartProps, eventCallbacksArgs, COMMENT_EMPTY } from "./types";
import { getHarmonics } from "../../../utils/charts/getHarmonics";
import { getBearingStamps } from "../../../utils/charts/getBearingStamps";
import { getComments } from "../../../utils/charts/getComments";
import { getPhasesAnalysis } from "../../../utils/charts/getPhasesAnalysis";
import { getDynamicOptions } from "../../../utils/charts/getOptions";
import useApi from "../../../hooks/useApi";
import {
  getTooltips,
  hideInvisibleSerie,
  setTooltips,
} from "../../../utils/charts/general";
import {
  eventSelectDynamic,
  eventSelectDynamicTWFFrequency,
  eventSelectFundamental,
} from "../../../utils/charts/chartEvents";
import LineChart from "../LineChart";
import { getGearingStamps } from "../../../utils/charts/getGearingStamp";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { appSelect } from "../../../store/slices/appSlice";
import { useParams } from "react-router-dom";
import ChartCommentModal from "../../../modals/ChartCommmentModal";
import { IChartCommentBody } from "../../../modals/ChartCommmentModal/types";
import { FrequencyContainer } from "./styles";
import { VscTriangleLeft } from "react-icons/vsc";

const DynamicChartFunction = ({
  chart,
  chartData,
  seriesControl,
  handleClickPoint,
  analysis,
  date,
  chartArea,
  setAnalysis = () => null,
}: ChartProps) => {
  const theme = useTheme();
  const { currentPosition: position } = useAppSelector(appSelect);
  const { colors } = theme;
  const [values, setValues]: any = useState({ data: [], tooltips: [] });
  const [comments, setComments] = useState(null);
  const [twfFrequency, setTwfFrequency] = useState<any[]>([]);
  const [modalComment, setModalComment] =
    useState<IChartCommentBody>(COMMENT_EMPTY);

  const { customItems } = useAppSelector(appSelect);
  const { positionId } = useParams();

  const path = `/positions/${positionId}/charts/${chart?.name}`;
  const { request } = useApi({ path });

  const { t } = useTranslation();

  const options = useMemo(() => {
    let ticks = chartData.ticks;

    const options = getDynamicOptions({
      chartData,
      colors,
      seriesControl,
      analysis,
      isMobile: isMobile(),
      ticks,
      chartArea,
      position,
      chart,
    });

    return options;
    // eslint-disable-next-line
  }, [chartData, colors, seriesControl, analysis, chartArea, chart]);

  useEffect(() => {
    if (!chartData?.data) {
      setValues([{ data: [], tooltips: [] }]);
      return;
    }

    const { data: dataRaw, series } = chartData;
    const data = [series, ...(dataRaw || [])];

    const tooltips = getTooltips({
      dataRaw,
      seriesControl,
      chart,
      t: t,
      frequencyUnitCPM:
        customItems?.frequencyUnit === "CPM" && chart.name !== "HAR-CORR",
    });

    setValues({ data, tooltips });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  useEffect(() => {
    if (!!analysis?.commentMode) {
      loadComments();
    }

    if (!analysis?.twfFrequency) {
      setTwfFrequency([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysis]);

  const frequencyTool = useMemo(() => {
    if (!analysis?.twfFrequency || twfFrequency.length !== 2)
      return { hz: "", cpm: "" };

    const sorted = twfFrequency
      .sort((a, b) => b.time - a.time)
      .sort()
      .map((item) => {
        return item.time;
      });

    const [maxValue, minValue] = sorted;

    const interval = maxValue - minValue;

    const frequency = (1 / interval).toFixed();

    return { hz: frequency, cpm: (+frequency * 60).toFixed() };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twfFrequency, analysis?.twfFrequency]);

  const displayData = useMemo(() => {
    const { data: dataRaw, tooltips: tooltipsRaw } = values;
    var data = dataRaw;
    var tooltips = tooltipsRaw;
    const { minValue, series } = chartData;

    if (seriesControl.filter((item) => !item.visible).length) {
      data = hideInvisibleSerie(data, seriesControl, +minValue);
    }

    const { commentMode, harmonics, bearingStamps, phasesAnalysis, gearing } =
      analysis || {};
    if (commentMode && comments) {
      data = getComments(data, comments, series, seriesControl);
    } else {
      data = setTooltips(data, tooltips);
    }

    if (harmonics) {
      data = getHarmonics({
        data,
        harmonics,
        frequencyUnitCPM:
          customItems?.frequencyUnit === "CPM" && chart.name !== "HAR-CORR",
      });
    } else if (bearingStamps) {
      data = getBearingStamps(data, bearingStamps, customItems?.frequencyUnit);
    } else if (phasesAnalysis) {
      data = getPhasesAnalysis(data);
    } else if (gearing) {
      data = getGearingStamps(data, gearing, customItems?.frequencyUnit);
    }

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, analysis, seriesControl, comments]);

  function loadComments() {
    const params = {
      method: "get" as "get",
      pathParameters: "comments",
      queryStringParameters: { date },
    };

    request(params).then((response) => {
      const validSeries = seriesControl
        .filter(({ alarm }: any) => !alarm)
        .map(({ name }: any) => name);

      for (let serie of Object.keys(response)) {
        if (!validSeries.includes(serie)) {
          delete response[serie];
        }
      }
      setComments(response);
    });
  }

  const chartEvents: ReactGoogleChartEvent[] = useMemo(() => {
    switch (true) {
      case analysis?.twfFrequency:
        return [
          {
            eventName: "select",
            callback: ({ chartWrapper: wrapper }: eventCallbacksArgs) => {
              eventSelectDynamicTWFFrequency({
                wrapper,
                chartData,
                twfFrequency,
                setTwfFrequency,
              });
            },
          },
        ];
      case analysis?.commentMode:
        return [
          {
            eventName: "select",
            callback: ({ chartWrapper: wrapper }: eventCallbacksArgs) => {
              eventSelectDynamic({
                wrapper,
                chartData,
                comments,
                setModalComment,
              });
            },
          },
        ];
      case analysis?.harmonicsClick:
        return [
          {
            eventName: "select",
            callback: ({ chartWrapper }: eventCallbacksArgs) => {
              eventSelectFundamental({
                chartWrapper,
                chartData,
                handleEvent: (freq: any) => {
                  setAnalysis({
                    ...analysis,
                    harmonics: {
                      active: true,
                      fundamental: freq,
                      harmonics: 10,
                      order: 1,
                      dontShowFrequency: true
                    },
                  });
                },
              });
            },
          },
        ];
      default:
        return [];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysis, comments]);

  return (
    <>
      {analysis?.twfFrequency && (
        <FrequencyContainer>
          <VscTriangleLeft />
          <div>
            {twfFrequency?.length ? (
              <>
                <label>{t("Frequência entre Pontos")}</label>
                <div>
                  <div>
                    <label>{frequencyTool?.hz} </label>
                    <label>Hz</label>
                  </div>
                  <div>
                    <label>{frequencyTool?.cpm} </label>
                    <label>RPM</label>
                  </div>
                </div>
              </>
            ) : (
              <label>{t("Selecione dois pontos no gráfico abaixo")}</label>
            )}
          </div>
        </FrequencyContainer>
      )}
      <LineChart
        chart={chart}
        options={options}
        data={displayData}
        chartEvents={chartEvents}
        harmonics={!!analysis?.harmonics}
        animated={chart.type !== "DYNAMIC" && !analysis}
      />
      {modalComment.show && (
        <ChartCommentModal
          body={{ ...modalComment, date: date as number }}
          handleClose={() => setModalComment({ ...modalComment, show: false })}
          graphicType={chart.name}
          positionId={+(positionId ?? "0")}
          refresh={loadComments}
        />
      )}
    </>
  );
};

const DynamicChart = memo(DynamicChartFunction);

export default DynamicChart;
