import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import {
  FormHarmonic,
  FormHarmonicTitle,
  FormInputType,
  LabelHours,
} from "./styles";
import { GearingFormProps } from "./types";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { appSelect } from "../../../store/slices/appSlice";
import { Modal } from "../../../components/Modal";

export const GearingForm = ({
  show,
  handleClose,
  handleConfirm,
  chart,
}: GearingFormProps) => {
  const { customItems, currentPosition } = useAppSelector(appSelect);
  const { details } = currentPosition;
  const rotation = details?.pointRotation ? +details.pointRotation : null;
  const gearings = details?.containsGear ? +details.containsGear : null;

  const [frequency, setFrequency] = useState<number | null>(rotation);
  const [gearing1, setGearing1] = useState<number | null>(gearings);
  const [gearing2, setGearing2] = useState<any>(undefined);
  const [unit, setUnit] = useState("RPM");

  useEffect(() => {
    if (rotation) {
      setFrequency(rotation);
    }
    // eslint-disable-next-line
  }, []);

  const blockInvalidCharacter = (e: {
    key: string;
    preventDefault: () => any;
  }) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleRpm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFrequency(+event.target.value);
  };

  const handleGearing1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGearing1(+event.target.value);
  };

  const handleGearing2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGearing2(+event.target.value);
  };

  const handleConfirmAnalisys = () => {
    let value = unit === "RPM" ? (frequency || 0) / 60 : frequency || 0;

    if (customItems?.frequencyUnit === "CPM") {
      value = value * 60;
    }

    let gearing = Math.max(gearing1 || 0, gearing2 || 0);
    if (gearing1 && gearing2) {
      gearing = Math.max(gearing1, gearing2) / Math.min(gearing1, gearing2);
    }
    const harmonic = value * gearing;
    handleConfirm(harmonic);
  };

  const { t } = useTranslation();

  function changeUnit(unit: "RPM" | "FUNDAMENTAL") {
    setUnit(unit);
    setFrequency(null);
  }

  return (
    <Modal
      handleClose={handleClose}
      show={show}
      handleConfirm={handleConfirmAnalisys}
      confirmLabel={`${t("GearingForm.save")}`}
    >
      <>
        <FormHarmonicTitle>{t("GearingForm.gearingConfig")}</FormHarmonicTitle>
        <FormInputType>
          <label>{t("GearingForm.calculateHarmonic")}</label>
          <Form.Group style={{ display: "flex", gap: "15px" }}>
            <Form.Label style={{ display: "flex", gap: "5px" }}>
              <Form.Check
                type="radio"
                checked={unit === "RPM"}
                onChange={() => changeUnit("RPM")}
              />
              {t("GearingForm.rotation")}(rpm)
            </Form.Label>

            <Form.Label style={{ display: "flex", gap: "5px" }}>
              <Form.Check
                type="radio"
                checked={unit === "FUNDAMENTAL"}
                onChange={() => changeUnit("FUNDAMENTAL")}
              />
              Fundamental(Hz)
            </Form.Label>
          </Form.Group>
        </FormInputType>
        <FormHarmonic>
          <div>
            <LabelHours>
              <label>
                {unit === "RPM"
                  ? `${t("GearingForm.rotation")}`
                  : "Fundamental"}
              </label>
              <input
                value={frequency ?? ""}
                onChange={handleRpm}
                type="number"
                placeholder={`${t("GearingForm.typeValue")}`}
                onKeyDown={blockInvalidCharacter}
              />
            </LabelHours>
            <LabelHours>
              <label>{t("GearingForm.engrening")} 1</label>
              <input
                value={gearing1 ?? ""}
                onChange={handleGearing1}
                type="number"
                placeholder={`${t("GearingForm.typeValue")}`}
                onKeyDown={blockInvalidCharacter}
              />
            </LabelHours>
            <LabelHours>
              <label>{t("GearingForm.engrening")} 2</label>
              <input
                value={gearing2 ?? ""}
                onChange={handleGearing2}
                type="number"
                placeholder={`${t("GearingForm.typeValue")}`}
                onKeyDown={blockInvalidCharacter}
              />
            </LabelHours>
          </div>
        </FormHarmonic>
      </>
    </Modal>
  );
};
