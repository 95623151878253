import styled from "styled-components";
import { getBadgeColorByOwner } from "../../utils/getStatusColor";

export const OwnerBadgeContainer = styled.div<{
  analysisOwner?: "NOC" | "INTEGRATOR" | "CLIENT" | null | undefined;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 16px;
  background-color: ${({ analysisOwner, theme }) =>
    getBadgeColorByOwner(theme, analysisOwner)};
  color: ${({ theme }) => theme.colors.iconsSecondary};
  border: 2px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};

  span {
    ${(props) => props.theme.fonts.primary.H5Bold};
    font-size: 9px;
    height: auto;
    color: ${(props) => props.theme.colors.white};
  }
`;
