import styled from "styled-components";
import { HeaderDiv } from "../Profile/styles";
import {
  ConfigurationPagesContent,
  FrameBodyMain,
} from "../../../components/frame/styles";

export const FrameBodyNotifications = styled(FrameBodyMain)`
  height: 93vh;
  padding: 0;
`;

export const NotificationsArea = styled(ConfigurationPagesContent)`
  width: 100%;
  height: calc(100% - 8px);
  margin-left: 1rem;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.headerBackground};
  flex-direction: column;
  padding: 1.25rem 2rem;
  border-radius: 6px;
  gap: 3rem;

  //mobile:
  @media screen and (max-width: 767px) {
    opacity: 1;
    padding: 4px;
    margin-left: 0;
    height: 100%;
    width: 100%;
    gap: 1rem;
    & > div {
      & > div:nth-child(2) {
        margin-bottom: 1rem;
        justify-content: space-evenly;
        div {
          font-size: 1.2rem;
        }
        & > div:first-child {
          margin-left: 0;
        }
      }
    }
  }

  // telas com 125% de zoom:
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    gap: 2rem;
  }
`;

export const Header = styled(HeaderDiv)`
  width: fit-content;
  display: flex;
  gap: 0.75rem;
  margin-right: auto;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 1.75rem;
      color: ${(props) => props.theme.colors.iconsSecondaryAlpha};

      :hover {
        color: ${(props) => props.theme.colors.iconsSecondary};
      }
      transition: all 0.3s ease;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 0.5rem 0 0 0.5rem;
    h2 {
      font-size: 1.5rem;
    }
  }
`;

export const NotificationContainer = styled.div`
  display: flex;
  width: 75%;
  height: 86%;
  flex-direction: column;
  color: ${(props) => props.theme.colors.iconsPrimary};
  gap: 1rem;

  /* border: 1px solid lightblue; */

  & > span {
    margin-top: 1rem;
    ${(props) => ({ ...props.theme.fonts.primary.H4Regular })};
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
    @media screen and (max-width: 767px) {
      width: 3px !important;
    }
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media screen and (min-width: 768px) and (max-width: 1197px) {
    flex-direction: column;
  }
  .form-check-input[type="checkbox"] {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    cursor: pointer;
  }
  .form-check-input {
    margin-left: 0.1rem;
    scale: 1.2;
  }

  .form-check-input[type="checkbox"]:checked {
    background-color: ${(props) => props.theme.colors.ibbxOrange};
    border: transparent;
    stroke: red;
  }

  .form-check-input[type="checkbox"]:focus {
    box-shadow: 0 0 0 0.25rem rgb(243, 112, 53, 0.25);
    border-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
  }

  .form-check {
    padding-left: 0.75rem;
  }

  @media screen and (max-width: 767px) {
    padding: 0 0.25rem;
    width: 100%;
    gap: 0.5rem;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    width: 85%;
    padding: 0 0.25rem;
    & > div {
      padding: 0;
      div {
        padding-left: 0;
      }
      span {
        font-size: 0.75rem;
      }
    }
    .form-check-input {
      scale: 1 !important;
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem;
  width: 100%;

  @media screen and (max-width: 767px) {
    padding: 1rem;
    margin-top: 0.5rem;

    width: 100%;
  }
`;

export const LoadingContainer = styled.div`
  border: none;
  background: none !important;

  & > div {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
  }
`;

export const NotificationTypeContainer = styled.div`
  display: flex;
`;

export const LeftSide = styled.div`
  width: 50%;
`;

export const RightSide = styled.div`
  width: 50%;
`;

export const NotificationsTable = styled.table`
  /* margin: 0 auto; */
  th {
    padding: 0.75rem 1rem;
  }
  td {
    padding: 0.5rem 1rem;
    label {
      font-size: 14px;
    }
  }
  th {
    border-bottom: 2px solid
      ${(props) => props.theme.colors.iconsSecondaryAlpha};
    background-color: ${(props) => props.theme.colors.cardUnderlineLightAlpha};
    color: ${(props) => props.theme.colors.iconsSecondary};

    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      svg {
        width: 1rem;
        height: auto;
      }
      span {
        font-size: 14px;
        font-weight: 400;
      }

      button {
        opacity: 0;
        border: none;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: ${(props) => props.theme.colors.iconsSecondary};
        width: 1rem;
        height: 1rem;
        transition: all 0.3s ease;

        &:hover {
          border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
        }
      }

      &:hover {
        button {
          opacity: 1;
        }
      }
    }
  }

  tr {
    border-bottom: 1px solid
      ${(props) => props.theme.colors.cardUnderlineLightAlpha};
    padding: 0.5rem 0;
  }

  .activity {
    width: auto;
  }

  .notifications-type {
    width: 12.5%;
  }

  @media screen and (max-width: 767px) {
    th {
      div {
        span:not(:first-child) {
          display: none;
        }

        button {
          display: none;
        }
      }
    }

    th:not(:first-child) {
      div {
        justify-content: center;
      }
    }

    td {
      padding: 0.5rem 0.5rem;
      div {
        padding: 0 !important;
      }
      label {
        font-size: 0.85rem;
      }
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    th {
      div {
        span {
          font-size: 0.75rem;
        }
      }
    }

    td {
      padding: 0.35rem 0.5rem;
      label {
        font-size: 0.75rem;
      }
    }
  }
`;

export const NotificationsItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  gap: 2rem;
  overflow: auto;

  //mobile:
  @media screen and (max-width: 767px) {
    gap: 1.5rem;
  }
`;

export const NotificationsCollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 0.5rem;

  & > div {
    padding: 1rem 0;
    border-radius: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.barColor};
  }

  //mobile:
  @media screen and (max-width: 767px) {
    padding: 0.5rem 0;
  }

  // telas com 125% de zoom:
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    padding: 0.5rem 0;
  }
`;

export const Blocked = styled.div`
  width: 1.5rem !important;
  height: 1.5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  .block {
    font-size: 1.5rem;
    z-index: 1;
  }
  .whatsApp {
    position: absolute;
    font-size: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem !important;
    height: 1rem !important;
  }
`;
