import styled, { css, keyframes } from "styled-components";

export const SidebarContent = styled.div`
  position: relative;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    height: 100%;
  }
`;

export const ToogleShowHideSidebar = styled.button<{ isOpen?: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: none;
  background-color: ${(props) => props.theme.colors.cardBackground};
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
  z-index: 2;
  top: 1rem;
  right: ${({ isOpen }) => (isOpen ? "1rem" : "1.25rem")};
  svg {
    color: ${(props) => props.theme.colors.background};
    transform: ${({ isOpen }) => (isOpen ? "rotate(0)" : "rotate(180deg)")};
    transition: all 0.5s ease;
  }
  transition: all 0.5s;

  &:hover {
    svg {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    top: 8px;
    right: 0.75rem;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    right: 0.5rem;
    top: 0.5rem;
    height: 1.75rem;
    width: 1.75rem;
  }
`;

export const Container = styled.div<{ isOpen?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 1rem 1rem;
  align-items: center;
  background-color: ${(props) => props.theme.colors.headerBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  width: 296px;
  transition: 0.3s;
  height: 100%;
  border-radius: 6px;
  margin: 0 0 0 0.2rem;
  gap: 1rem;

  & > div {
    width: 100%;
  }

  ${({ isOpen }) =>
    !isOpen &&
    css`
      width: 70px;
      padding: 4rem 0.5rem 1rem 0.5rem;
    `}

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    margin-left: 16px;
    margin-right: 0px;
    width: 288px;

    input,
    select,
    textarea {
      font-size: 16px;
    }
  }

  @media ${({ theme }) => theme.mediaQuery.small} {
    transition: width 0.2s linear, opacity 0.1s linear;
    margin-left: 30px;
    margin-right: 0px;
    padding: 10px;

    input,
    select,
    textarea {
      font-size: 16px;
    }
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    width: ${(props) => (props.isOpen ? "212px" : "60px")};
    padding: 2rem 0.5rem 1.5rem 0.5rem;
    margin-left: 0;

    & > div:first-child {
      padding-top: 2rem;
    }
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: ${(props) => (props.isOpen ? "212px" : "60px")};
    padding: 1rem 0.5rem 1.5rem 0.5rem;
    overflow: hidden;

    & > div:first-child {
      padding-top: 2rem;
    }
  }
`;

export const SidebarConfigurationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    @keyframes leftoToRigth {
      from {
        opacity: 0;
        transform: translateX(-60px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }

    & > span {
      width: 100%;
      color: ${(props) => props.theme.colors.textPrimary};
      ${(props) => props.theme.fonts.primary.H4Regular};
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-transform: uppercase;
      /* animation: leftoToRigth 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; */
    }

    @media ${({ theme }) => theme.mediaQuery.large} {
      & > div > div > div > div {
        min-width: 100px;

        .css-fbacxu-indicatorContainer {
          padding: 0 !important;
        }
        & > div {
          padding: 0 !important;
          font-size: 12px;
        }
      }
    }
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: ${(props) => props.theme.colors.ibbxOrange};
  border-radius: 50px;
  outline: ${(props) => props.theme.colors.iconsSecondaryAlpha} solid 2px;
  z-index: 2;
  svg {
    width: 30px;
    height: auto;
  }

  img {
    width: 45px;
    height: 45px;
    border-radius: 50px;
  }
`;

export const Menu = styled.div<{
  isOpen?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding-left: 0;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.5rem;
    padding-left: 0;
    position: relative;
    overflow: hidden auto;
  }

  ${({ isOpen }) =>
    !isOpen &&
    css`
      a,
      li {
        justify-content: center;
        align-items: center;
        padding: 0.25rem 0;
      }
    `}

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
    @media ${({ theme }) => theme.mediaQuery.small} {
      width: 3px !important;
    }
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media ${({ theme }) => theme.mediaQuery.small} {
    height: calc(100% - 8rem);
    & > div:first-child {
      height: 100%;
    }
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    & > div:first-child {
      gap: 0.5rem;
      margin-top: 0;
    }
  }
`;

export const MenuItensContainer = styled.div<{
  isOpen?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding-left: 0;
`;

export const MenuListItem = styled.div<{
  active?: boolean;
  hide?: boolean;
}>`
  position: relative;
  svg {
    width: 1.5rem;
    height: auto;
  }
`;

export const MenuItem = styled.li<{
  active?: boolean;
  hide?: boolean;
}>`
  color: ${(props) => props.theme.colors.iconsSecondary} !important;
  ${(props) => props.theme.fonts.primary.H4Regular}
  position: relative;
  list-style: none;
  text-decoration: none;
  display: ${(props) => (props.hide ? "none" : "flex")};
  align-items: center;
  gap: 0.5rem;
  transition: 0.3s;
  padding: 0.3rem 1rem;
  width: 100%;

  svg {
    width: 1.5rem;
    height: auto;
  }
  ul {
    padding-left: 0;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      border-radius: 32px;
      font-size: 0.9rem;
      font-weight: 600;
    `}

  button {
    div {
      svg {
        color: ${(props) => props.theme.colors.iconsSecondary} !important;
      }
    }
  }

  @keyframes leftoToRigth {
    from {
      opacity: 0;
      transform: translateX(-60px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  span {
    /* animation: leftoToRigth 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; */
  }

  :hover {
    color: ${(props) => props.theme.colors.textPrimary};
    background-color: ${(props) => props.theme.colors.cardBackground};
    border-radius: 32px;
    opacity: 1;
    transform: scale(0.98);
    cursor: pointer;
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    font-size: 11px;
    margin-bottom: 0;
  }
`;

export const VersionContainer = styled.div`
  ${(props) => props.theme.fonts.primary.H4Regular};
  opacity: 0.6;
  display: flex;
  color: ${(props) => props.theme.colors.iconsSecondary};
  justify-content: center;
  align-items: center !important;
  text-align: center;
  width: 100%;
  height: 3rem;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  }
  transition: all 0.3s ease;
  @media ${({ theme }) => theme.mediaQuery.large} {
    font-size: 0.75rem;
  }
`;

// ======= MOBILE ========

export const ShowUpToDown = keyframes`
0% {
    transform: scaleX(0);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }

`;

export const ContainerMobile = styled(Container)<{ isOpen?: boolean }>`
  width: 70vw;
  margin: 0;
  height: 100vh;
  border: none;
  position: absolute;
  z-index: 11;
  transition: 0.3s;

  -webkit-box-shadow: 160px 0px 0px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 160px 0px 0px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 160px 0px 0px 0px rgba(0, 0, 0, 0.3);
  animation: ${ShowUpToDown} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  & > ul {
    width: 100%;
    /* margin-left: 2.5rem; */
  }
`;

export const Mobile = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 2;
  & > div:last-child {
    background-color: #543;
    opacity: 0.6;
  }
`;

export const ToogleShowHideSidebarMobile = styled(ToogleShowHideSidebar)<{
  isOpen?: boolean;
}>`
  z-index: 12;
  width: 2rem;
  height: 2rem;
  top: -3.5rem;
  left: 0.5rem;
  z-index: 101;
  position: absolute;
  transition: none;

  svg {
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  box-shadow: ${(props) =>
    props.isOpen ? "0px 4px 21px rgba(0, 0, 0, 0.25)" : "none"};
  background-color: ${(props) =>
    props.isOpen ? props.theme.colors.cardBackground : "transparent"};
`;
