import styled from "styled-components";

export const Button = styled.button`
  width: 126px;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding: 3px 3px;
  background: none;
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: none;
  border-radius: 6px;

  :hover {
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  }

  ${(props) => props.theme.fonts.primary.H5SemiBold};

  @media screen and (max-width: 767px) {
    width: 80px;
  }
`;
