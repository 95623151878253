import styled from "styled-components";

export const CardStatusIoTContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  color: ${(props) => props.theme.colors.iconsSecondary};

  span {
    margin-left: 12px;
    ${(props) => props.theme.fonts.primary.H3SemiBold}
  }

  & > div {
  }

  @media screen and (max-width: 1445px) {
    & > div {
      margin-top: 0px;
    }
  }
`;

export const CardIoTConnectivityContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 20%;
  // Porcentagem
  & > div:nth-child(1) {
    display: flex;

    & > div {
      height: 8.5rem;
      width: 8.5rem;

      & > div {
        height: 80%;
        width: 80%;
      }
    }

    span {
      ${(props) => props.theme.fonts.primary.H3SemiBold};
      font-size: 2.5rem;
      margin: 0;
    }
  }
  // Valores
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;

    span {
      ${(props) => props.theme.fonts.primary.H2Bold};
      font-size: 1.8rem;
      margin: 0;
    }

    label {
      ${(props) => props.theme.fonts.primary.H4Bold};
      font-size: 1.2rem;
    }

    div {
      gap: 0.8rem;
      display: flex;
      flex-direction: column;
    }
  }

  // Mobile
  @media screen and (max-width: 767px) {
    & > div {
      margin-top: 1rem;
    }
    // Porcentagem
    > div:nth-child(1) {
      & > div {
        height: 9rem;
        width: 9rem;
      }
    }
  }

  /* Tablet ambas L&P */
  @media screen and (min-width: 799px) and (max-width: 1280px) {
    padding: 0.8rem;
    min-width: 16rem;
    gap: 10%;
    // Porcentagem
    & > div:nth-child(1) {
      & > div {
        height: 6.5rem;
        width: 6.5rem;

        & > div {
          height: 85%;
          width: 85%;
        }
      }
    }
    // Valores
    & > div:nth-child(2) {
      gap: 1rem;
      label {
        font-size: 1rem;
      }
    }
  }
`;

export const CardStatusIoTBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;

    span {
      ${(props) => props.theme.fonts.primary.H2boldCard};
      margin: 0;
    }

    label {
      ${(props) => props.theme.fonts.primary.H4Bold};
      text-align: center;
    }

    @media screen and (max-width: 1445px) {
      span {
        font-size: 2.2rem;
      }
      label {
        font-size: 0.8rem;
      }
    }

    @media screen and (max-width: 945px) {
      span {
        font-size: 1.8rem;
      }
      label {
        font-size: 0.6rem;
      }
    }

    @media screen and (max-width: 767px) {
      span {
        font-size: 2.7rem;
      }
      label {
        font-size: 1rem;
      }
    }
  }
`;

export const CardStatusIoTPercent = styled(CardStatusIoTBox)`
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 50%;
`;

export const DoughnutHoleStatusIot = styled.div`
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.cardBackground};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DoughnutChartStatusIot = styled.div<{ connectivity: number }>`
  border-radius: 50%;
  background: conic-gradient(
    ${({ theme }) => theme.colors.cardHighlight} 0deg
      ${({ connectivity }) =>
        connectivity ? Math.floor(360 * (1 - connectivity)) : 360}deg,
    ${({ theme }) => theme.colors.iconsSecondary}
      ${({ connectivity }) =>
        connectivity ? Math.ceil(360 * (1 - connectivity)) : 0}deg
      360deg
  );
  display: flex;
  align-items: center;
  justify-content: center;
`;
