import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type filterOptionDataType = {
  value: number;
  label: string;
  logoUrl?: string;
};

export type filterOptionType = {
  companyId?: number | string;
  facilityId?: number | string;
  sectorId?: number | string;
  assetId?: number | string;
  data: filterOptionDataType[];
};

const SELECT_OPTION_LIST_EMPTY = {
  options: [],
};

export type SelectOptionList = {
  options: SelectOption[];
  companyId?: number | string;
  facilityId?: number | string;
  sectorId?: number | string;
  assetId?: number | string;
};

export type SelectOption = {
  value: number | string;
  label: string;
  logoUrl?: string;
};

interface SliceState {
  processing: boolean;
  companies: SelectOptionList;
  facilities: SelectOptionList;
  sectors: SelectOptionList;
  assets: SelectOptionList;
  positions: SelectOptionList;
  status: SelectOptionList;
  occurrenceStatus: SelectOptionList;
  solutionStatus: SelectOptionList;
  sensorTypes: SelectOptionList;
  openedBy: SelectOptionList;
  closedAt: SelectOptionList;
  analysisOwner: SelectOptionList;
}

const initialState: SliceState = {
  processing: false,
  companies: SELECT_OPTION_LIST_EMPTY,
  facilities: SELECT_OPTION_LIST_EMPTY,
  sectors: SELECT_OPTION_LIST_EMPTY,
  assets: SELECT_OPTION_LIST_EMPTY,
  positions: SELECT_OPTION_LIST_EMPTY,
  status: {
    options: [
      { value: 1, label: "Normal" },
      { value: 2, label: "Alerta em Análise" },
      { value: 3, label: "Alerta" },
      { value: 4, label: "Análise" },
      { value: 5, label: "Risco" },
      { value: 7, label: "Crítico" },
    ],
  },
  occurrenceStatus: {
    options: [
      { value: 3, label: "Alerta" },
      { value: 5, label: "Risco" },
    ],
  },
  // Opções de Status para Clientes SOLaaS
  solutionStatus: {
    options: [
      { value: 1, label: "Normal" },
      { value: 3, label: "Alerta" },
      { value: 5, label: "Risco" },
      { value: 7, label: "Crítico" },
    ],
  },
  sensorTypes: SELECT_OPTION_LIST_EMPTY,
  openedBy: {
    options: [
      { value: "ai", label: "Interligência Artificial" },
      { value: "alarm", label: "Alarmes" },
    ],
  },
  closedAt: {
    options: [
      { value: "opened", label: "Abertas" },
      { value: "closed", label: "Fechadas" },
    ],
  },
  analysisOwner: {
    options: [
      { value: "NOC", label: "Noc Ibbx" },
      { value: "INTEGRATOR", label: "Integrador" },
      { value: "CLIENT", label: "Cliente" },
    ],
  },
};

const selectOptionsSlice = createSlice({
  name: "position",
  initialState: initialState,
  reducers: {
    setCompanyOptions: (state, action: PayloadAction<SelectOptionList>) => {
      state.companies = action.payload;
    },
    setFacilityOptions: (state, action: PayloadAction<SelectOptionList>) => {
      state.facilities = action.payload;
    },
    setSectorOptions: (state, action: PayloadAction<SelectOptionList>) => {
      state.sectors = action.payload;
    },
    setAssetOptions: (state, action: PayloadAction<SelectOptionList>) => {
      state.assets = action.payload;
    },
    setPositionOptions: (state, action: PayloadAction<SelectOptionList>) => {
      state.positions = action.payload;
    },
    setStatusOptions: (state, action: PayloadAction<SelectOptionList>) => {
      state.status = action.payload;
    },
    setSensorTypes: (state, action: PayloadAction<SelectOptionList>) => {
      state.sensorTypes = action.payload;
    },
    setOpenedBy: (state, action: PayloadAction<SelectOptionList>) => {
      state.openedBy = action.payload;
    },
  },
});

export const selectOptionsSelect = (state: RootState) => state.selectOptions;

export const {
  setCompanyOptions,
  setFacilityOptions,
  setSectorOptions,
  setAssetOptions,
  setPositionOptions,
  setStatusOptions,
  setSensorTypes,
  setOpenedBy,
} = selectOptionsSlice.actions;

export default selectOptionsSlice.reducer;
