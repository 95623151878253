import { ReactNode } from "react";
import { Form, Formik } from "formik";

import { UrlContainer } from "./styles";
import { FormikTextInput } from "../../../../textInput";

interface AnalyticsTabTitleProps {
  handleChange: any;
  analyticsTabName?: string;
  placeholder?: string;
  value?: any;
  isUrlValid?: boolean;
  icon?: ReactNode;
  handleReset?: any;
  resetTitle?: string;
}

export function AnalyticsTabTitle({
  handleChange,
  placeholder,
  analyticsTabName,
  value,
  isUrlValid,
}: AnalyticsTabTitleProps) {
  return (
    <UrlContainer isUrlValid={isUrlValid}>
      <div>
        <Formik initialValues={value} onSubmit={handleChange}>
          <Form>
            <FormikTextInput
              placeholder={placeholder}
              name="analyticsTabName"
              label={analyticsTabName}
            />
          </Form>
        </Formik>
      </div>
    </UrlContainer>
  );
}
