import styled from "styled-components";

export const FormHarmonicTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  /* margin-left: 2.5%; */
  width: 97.5%;
  margin: 0px 0 20px;

  ${(props) => ({ ...props.theme.fonts.primary.H2Bold })};
`;

export const FormBearing = styled.form`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 0;
  border: none;
  gap: 21px;

  color: ${(props) => props.theme.colors3.color0};
  ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    & > div {
      gap: 6px;
      & > label {
        margin: 0 0 0 6px;
      }

      & > input {
        &::placeholder {
          font-size: 12px;
        }
      }
    }
  }

  select {
    padding: 0px;
    width: 100%;
    min-width: 10;
    height: 34px;
    outline: 0;
    margin-bottom: 16px;
    background: ${(props) => props.theme.colors.headerBackground};
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    border-radius: 4px;
    color: ${(props) => props.theme.colors3.color0};
    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
    }
  }
`;

export const LabelBearings = styled.div`
  width: 100%;
  min-width: 10;
  height: 34px;
  margin-bottom: 12px;
  background: ${(props) => props.theme.colors.headerBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 4px;
  color: ${(props) => props.theme.colors3.color0};

  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center;

  div {
    width: 30%;
    color: ${(props) => props.theme.colors3.color0} !important;
    opacity: 0.6;
  }
`;
