/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import useApi from "../../hooks/useApi";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";
import { Container } from "./styles";

/* @ts-ignore */
import { Hopara } from "@hopara/react";
import { Loading } from "../Loading";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getUrlPattern } from "../../utils/getUrlPattern";
import { ROUTES_PATH } from "../../routes/RoutesPath";
import useStateLocalStorage from "../../hooks/useStateLocalStorage";
import queryString from "../../utils/queryString";
import { userSelect } from "../../store/slices/userSlice";

export const RetinaMapsEmbbeded = ({
  hide,
  isConnectivity,
}: {
  hide?: boolean;
  isConnectivity?: any;
}) => {
  const app = useAppSelector(appSelect);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector(userSelect);
  const urlPattern = getUrlPattern(location, params);
  const queryParams = queryString.parse(location.search);
  const [searchParams, setSearchParams] = useSearchParams();

  const [show3D, setShow3D] = useState<boolean>(false);
  const [show2D, setShow2D] = useState<boolean>(true);

  const [viewProcess, setViewProcess] = useState<boolean>(
    !!queryParams.mapsConnectivity || false,
  );

  const env =
    process.env.REACT_APP_ENV === "production" ? "production" : "test";

  const { companyId, assetId, facilityId = "" } = params;

  const page = useMemo(() => {
    switch (true) {
      case [ROUTES_PATH.COMPANIES].includes(urlPattern):
        return "companies";
      case [ROUTES_PATH.FACILITIES].includes(urlPattern):
        return "facilities";
      case [
        ROUTES_PATH.ASSETS,
        ROUTES_PATH.FACILITY_CONNETIVITY,
        ROUTES_PATH.COMPANY_CONNECTIVY,
      ].includes(urlPattern):
        return "assets";
      default:
        return "";
    }
  }, [urlPattern]);

  const { request, processing } = useApi({
    path: `/companies/${companyId || 0}/hopara-token`,
  });

  const { request: requestHoparaAsset } = useApi({
    path: `/hopara/assets`,
  });

  const { request: requestHoparaPositions } = useApi({
    path: `/hopara/positions`,
  });

  const { request: requestHoparaAssetPositionsMetrics } = useApi({
    path: `/assets/${assetId}/hopara-asset-positions-metrics`,
  });

  const { request: requestHoparaCompanies } = useApi({
    path: `/hopara/companies`,
  });

  const { request: requestHoparaFacilities } = useApi({
    path: `/hopara/facilities`,
  });

  const { request: requestHoparaGateways } = useApi({
    path: `/hopara/gateways`,
  });

  const [localStorageHopara, setLocalStorageHopara] = useStateLocalStorage(
    `${page}-hopara-token${queryParams.sidetab === "bolt" ? "-bolt" : ""}`,
    {
      token: "",
      date: new Date().getTime(),
    },
  );

  useEffect(() => {
    if (queryParams.mapsConnectivity && !viewProcess) {
      setViewProcess(true);
    }
  }, [queryParams]);

  function isDateFiveDaysOlder(dateEpoch: number) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;

    const today = new Date().getTime();
    const date2 = new Date(dateEpoch).getTime();

    const differenceInDays = Math.floor((today - date2) / millisecondsPerDay);

    return differenceInDays >= 5;
  }

  const getHoparaToken = async () => {
    const tokenHopara = await request({
      method: "get",
      queryStringParameters: params.facilityId ? { facilityId } : {},
    });
    setLocalStorageHopara({
      token: tokenHopara,
      date: new Date().getTime(),
      companyId: companyId,
      facilityId: facilityId,
      user: user.id,
    });
  };

  const shouldHaveInitialRow = useMemo(() => {
    if (assetId) {
      return {
        layerId: "assets",
        rowId: `${assetId}`,
      };
    }

    if (
      app.currentFacility.id ||
      isConnectivity ||
      [
        ROUTES_PATH.FACILITY_CONNETIVITY,
        ROUTES_PATH.COMPANY_CONNECTIVY,
      ].includes(urlPattern)
    ) {
      return {
        layerId: "facilities",
        rowId: `${app.currentFacility.id}`,
      };
    }

    if (companyId) {
      return {
        layerId: "company",
        rowId: `${companyId}`,
      };
    }

    return "";
  }, [assetId, isConnectivity, app.currentFacility.id]);

  const visualizationId = useMemo(() => {
    if (
      isConnectivity ||
      [
        ROUTES_PATH.FACILITY_CONNETIVITY,
        ROUTES_PATH.COMPANY_CONNECTIVY,
      ].includes(urlPattern)
    ) {
      return "conectividade";
    }

    if (viewProcess) {
      return `${companyId}-processo`;
    }

    if (assetId && !show2D && show3D) {
      return `${companyId}-ativo-3d-2`;
    }

    if (assetId && show2D && !show3D) {
      return `${companyId}-ativo-2d-2`;
    }

    if (companyId) {
      return `${companyId}`;
    }
  }, [assetId, isConnectivity, companyId, show2D, show3D, viewProcess]);

  const fallbackVisualizationId = useMemo(() => {
    if (viewProcess) {
      return "processo";
    }

    if (!companyId) {
      return "mapa-geral";
    }

    if (!assetId) {
      return "mapa-2";
    }

    if (!show2D && show3D) {
      return `ativo-3d-2`;
    }

    if (show2D && !show3D) {
      return "ativo-2d-2";
    }
  }, [assetId, show3D, show2D, viewProcess]);

  const shouldRefreshToken = () => {
    const isDifferentCompany = localStorageHopara.companyId !== companyId;
    const isDifferentFacility = localStorageHopara.facilityId !== facilityId;
    const isTokenFiveDaysOld = isDateFiveDaysOlder(localStorageHopara.date);
    const isTokenMissing = !localStorageHopara.token;
    const isDifferentUser = user.id !== localStorageHopara.user;
    const isCompaniesLevel = !localStorageHopara.companyId;

    if (
      isTokenMissing ||
      isTokenFiveDaysOld ||
      isDifferentCompany ||
      isDifferentUser ||
      isCompaniesLevel ||
      isDifferentFacility
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (shouldRefreshToken()) {
      getHoparaToken();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, facilityId, urlPattern, assetId]);

  const queryStringParameters = useMemo(() => {
    return { assetId, facilityId, companyId };
  }, []);

  const dataLoaders = useMemo(() => {
    return [
      {
        name: "companies",
        source: "retina",
        loader: async () => {
          return await requestHoparaCompanies({
            method: "get",
            queryStringParameters,
          }).then((response) => {
            return response;
          });
        },
      },
      {
        name: "facilities",
        source: "retina",
        loader: async () => {
          return await requestHoparaFacilities({
            method: "get",
            queryStringParameters,
          }).then((response) => {
            return response;
          });
        },
      },
      {
        name: "gateways",
        source: "retina",
        loader: async () => {
          if (queryParams?.facilityId) {
            queryStringParameters.facilityId = queryParams.facilityId;
          }
          return await requestHoparaGateways({
            method: "get",
            queryStringParameters,
          }).then((response) => {
            return response;
          });
        },
      },
      {
        name: "assets",
        source: "retina",
        loader: async () => {
          return await requestHoparaAsset({
            method: "get",
            queryStringParameters,
          }).then((response) => {
            return response;
          });
        },
      },
      {
        name: "positions",
        source: "retina",
        loader: async () => {
          return await requestHoparaPositions({
            method: "get",
            queryStringParameters,
          }).then((response) => {
            return response.positions;
          });
        },
      },
      {
        name: "metrics",
        source: "retina",
        loader: async () => {
          if (!assetId) return;

          return await requestHoparaAssetPositionsMetrics({
            method: "get",
          }).then((response) => {
            return response.metrics;
          });
        },
      },
    ];
  }, []);

  const callbacks = useMemo(() => {
    return [
      {
        name: "goToAsset",
        callback: (row: any) => {
          const {
            assetId,
            facilityId,
            companyId,
            company_id,
            facility_id,
            asset_id,
          } = row;

          navigate(
            `/companies/${companyId || company_id}/facilities/${
              facilityId || facility_id
            }/assets/${assetId || asset_id}?tab=maps`,
          );
        },
      },
      {
        name: "goToFacility",
        callback: (row: any) => {
          const { facilityId, facility_id, companyId, company_id } = row;

          navigate(
            `/companies/${companyId || company_id}/facilities/${
              facilityId || facility_id
            }/assets?tab=maps&mapsConnectivity=true`,
          );
        },
      },
      {
        name: "goToCompany",
        callback: (row: any) => {
          const { companyId } = row;

          const companyIdparams = params?.companyId;

          const company = companyId || companyIdparams;

          navigate(`/companies/${company}/facilities?tab=maps`);
        },
      },
      {
        name: "goTo3D",
        callback: () => {
          setShow3D(true);
          setShow2D(false);
        },
      },
      {
        name: "goTo2D",
        callback: () => {
          setShow2D(true);
          setShow3D(false);
        },
      },
      {
        name: "goToProcess",
        callback: () => {
          setViewProcess(true);
        },
      },
      {
        name: "goToMap",
        callback: () => {
          setViewProcess(false);
          if (searchParams.has("mapsConnectivity")) {
            searchParams.delete("mapsConnectivity");
            setSearchParams(searchParams);
          }
        },
      },
    ];
  }, [fallbackVisualizationId]);

  return (
    <Container hide={hide}>
      {localStorageHopara.token && !processing ? (
        <Hopara
          visualizationId={visualizationId}
          env={env}
          accessToken={localStorageHopara.token}
          darkMode={app.darkMode}
          fallbackVisualizationId={fallbackVisualizationId}
          initialRow={shouldHaveInitialRow}
          dataLoaders={dataLoaders}
          // debug={env !== "production"}
          callbacks={callbacks}
        />
      ) : (
        <Loading />
      )}
    </Container>
  );
};