function clearQuery(
  item: any,
  arrayToDelete: string[],
  setSearchParams: Function,
) {
  for (let query of arrayToDelete) {
    delete item[query];
  }
  setSearchParams(new URLSearchParams(item));
}

export function updateQueryString(
  item: any,
  field: string,
  setSearchParams: Function,
) {
  let arrayToDelete = [field];
  const currentIndex = selectFields.indexOf(field);
  if (currentIndex !== -1) {
    arrayToDelete = selectFields.slice(currentIndex);
  }

  if (item[field]) {
    setSearchParams(new URLSearchParams(item));
    arrayToDelete = arrayToDelete.slice(1);
  }
  clearQuery(item, arrayToDelete, setSearchParams);
}

export const selectFields = ["companyId", "facilityId", "sectorId", "assetId"];

export const statusOptions = [
  { value: 1, label: "Normal" },
  { value: 2, label: "Alerta Análise" },
  { value: 3, label: "Alerta" },
  { value: 4, label: "Risco Análise" },
  { value: 5, label: "Risco" },
];
