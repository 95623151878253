import { useContext } from "react";
import OccurrenceListContext from "../../context";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loading } from "../../../Loading";
interface IInfiniteScrollDesktop {
  children: JSX.Element | false | Array<JSX.Element | false>;
}

export default function InfiniteScrollDesktop({
  children,
}: IInfiniteScrollDesktop) {
  const { handleGetRecords, records, pagination, processing } = useContext(
    OccurrenceListContext,
  );

  return (
    <InfiniteScroll
      next={handleGetRecords}
      dataLength={records?.length}
      hasMore={pagination?.page < pagination?.totalPages}
      loader={null}
      scrollableTarget="scrollableDiv"
    >
      {children} {processing && <Loading />}
    </InfiniteScroll>
  );
}
