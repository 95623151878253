import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SidebarState {
  showSidebar: boolean;
}

const initialState: SidebarState = {
  showSidebar: true,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setShowSidebar: (state, action: PayloadAction<boolean>) => {
      state.showSidebar = action.payload;
    },
  },
});

export const { setShowSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
