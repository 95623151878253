import styled from "styled-components";

export const SelectContainer = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 4px;

  label {
    display: flex;
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${(props) => props.color || props.theme.colors.iconsPrimary};
    gap: 6px;
  }

  span {
    ${(props) => props.theme.fonts.secondary.H3Regular} !important;
    color: red !important;
  }

  div {
    &::-webkit-scrollbar {
      width: 6px !important;
    }

    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.background};
      width: 20px;
      border-radius: 100vw;
      margin-block: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.iconsSecondaryAlpha};
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.iconsSecondary};
    }

    &::-webkit-scrollbar-thumb:active {
      background: ${({ theme }) => theme.colors.iconsSecondary};
    }
  }
`;

export const OptionWithImageContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
`;
