import { useEffect, useMemo } from "react";
import CountUp from "react-countup";

import { Loading } from "../Loading";
import { useAppSelector } from "../../store/hooks";
import {
  Box,
  CardOccurrenceContainer,
  OccurrenceBox,
  OccurrenceData,
} from "./styles";
import { SeparatorCardDashboard } from "../CardMonitoredPoints/styles";
import { useTranslation } from "react-i18next";
import useDashboard from "../../hooks/useDashboard";
import { dashboardSelect } from "../../store/slices/dashboardSlice";

function CardOccurrencesCount({ id }: any) {
  const { loadOccurrencesCount } = useDashboard();
  const { occurrencesCount } = useAppSelector(dashboardSelect);

  const countOccurrences = useMemo(() => {
    return occurrencesCount?.data ?? { countOpen: 0, countClosed: 0 };
  }, [occurrencesCount?.data]);

  const { t } = useTranslation();

  useEffect(() => {
    loadOccurrencesCount();
    const interval = setInterval(() => {
      loadOccurrencesCount();
    }, 600000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CardOccurrenceContainer>
      <span>{t("CardOccurrencesCount.occurrences")}</span>
      <Box>
        {occurrencesCount?.processing ? (
          <Loading marginTop="0px" />
        ) : (
          <OccurrenceBox>
            <OccurrenceData>
              <span>
                <CountUp
                  start={countOccurrences.countOpen || 0}
                  end={countOccurrences.countOpen}
                  duration={0.8}
                  separator={"."}
                />
              </span>
              <label>{t("CardOccurrencesCount.opened")}</label>
            </OccurrenceData>
            <SeparatorCardDashboard></SeparatorCardDashboard>
            <OccurrenceData>
              <span>
                <CountUp
                  start={countOccurrences.countClosed || 0}
                  end={countOccurrences.countClosed}
                  duration={0.8}
                  separator={"."}
                />
              </span>
              <label>{t("CardOccurrencesCount.closed")}</label>
            </OccurrenceData>
          </OccurrenceBox>
        )}
      </Box>
    </CardOccurrenceContainer>
  );
}

export default CardOccurrencesCount;
