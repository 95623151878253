import styled, { css } from "styled-components";

const StandardStyles = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors.textPrimary};

  h2 {
    ${(props) => props.theme.fonts.primary.H2Bold};
    font-size: 1.5rem;
  }
  label {
    ${(props) => props.theme.fonts.primary.H3SemiBold};
  }
  span {
    ${(props) => props.theme.fonts.primary.H4Regular};
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 1rem;
    }
    label {
      ${(props) => props.theme.fonts.primary.H4Regular};
    }
    span {
      ${(props) => props.theme.fonts.primary.H5Regular};
    }
  }
`;

export const OptionChartButton = styled.button<{ active?: boolean }>`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  ${(props) => props.theme.fonts.primary.H4Regular};
  color: ${(props) => props.theme.colors.iconsSecondary};
  ${({ active }) =>
    active &&
    css`
      background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      color: ${(props) => props.theme.colors.iconsPrimary};
      border: 1px solid ${(props) => props.theme.colors.iconsPrimary};
    `}
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    color: ${(props) => props.theme.colors.iconsPrimary};
  }
  @media screen and (max-width: 767px) {
    flex: 1;
  }
`;

export const Container = styled(StandardStyles)`
  flex-direction: column;
  gap: 1rem;
  min-height: 30rem;
`;

export const Header = styled(StandardStyles)`
  padding: 0.75rem;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 0;
    gap: 0.25rem;
  }
`;

export const ModalTitle = styled(StandardStyles)`
  gap: 0.75rem;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;

export const OptionsContainer = styled(StandardStyles)`
  width: fit-content;
  gap: 2rem;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const Content = styled(StandardStyles)`
  padding: 0 1rem;
  gap: 1rem;

  flex-direction: column;
`;

export const ChartContainer = styled(StandardStyles)`
  display: flex;
  flex-direction: column;
  height: 370px;
  cursor: text;

  h2 {
    flex: 0 0 20px;
    ${({ theme }) => theme.fonts.primary.H3SemiBold};
    margin-bottom: 4px;
  }

  footer {
    padding: unset;
    padding-left: 2rem;
    display: flex;
    align-items: center;

    & > div {
      & > button {
        align-items: center;
      }
    }
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 0.75rem;
    }
  }
`;
