import ReactJoyride, { CallBackProps } from "react-joyride";
import darkTheme from "../../styles/themes/dark";
import lightTheme from "../../styles/themes/light";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";

interface JoyrideProps {
  steps: any[];
  run?: boolean;
  callback?: (props: CallBackProps) => void;
  progress?: boolean;
  skipButton?: boolean;
  locale?: {
    back?: string | JSX.Element | undefined;
    close?: string | JSX.Element | undefined;
    last?: string | JSX.Element | undefined;
    next?: string | JSX.Element | undefined;
    skip?: string | JSX.Element | undefined;
  };
}

export function Joyride({
  run,
  steps,
  callback,
  progress = true,
  skipButton = true,
  locale,
}: JoyrideProps) {
  const app = useAppSelector(appSelect);

  const { t } = useTranslation();

  const { darkMode } = app;

  const selectedTheme = darkMode ? darkTheme : lightTheme;

  if (!locale) {
    locale = {
      back: <FaArrowLeft />,
      close: t("dictionary.actions.Close") as string,
      last: t("dictionary.actions.Last") as string,
      next: t("dictionary.actions.Next") as string,
      skip: t("dictionary.actions.Skip") as string,
    };
  }

  return (
    <ReactJoyride
      steps={steps}
      run={run}
      continuous={true}
      showProgress={progress}
      showSkipButton={skipButton}
      locale={locale}
      callback={callback}
      styles={{
        options: {
          beaconSize: 18,
          backgroundColor: selectedTheme.colors.divBackground,
          arrowColor: selectedTheme.colors.divBackground,
          textColor: selectedTheme.colors3.color0,
          primaryColor: selectedTheme.colors.ibbxOrange,
          overlayColor: "rgba(0, 0, 0, 0.65)",
        },
      }}
    />
  );
}
