import styled, { keyframes } from "styled-components";

export const Span = styled.div<{ sectorColor: string | "" }>`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: ${(props) => props.sectorColor};
  margin-right: 6px;
`;

export const SidebarSelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    user-select: none;
    ${(props) => props.theme.fonts.primary.H4Regular};
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
`;

export const SidebarSelectDiv = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  background-color: ${(props) => props.theme.colors.cardBackground};
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  ${(props) => props.theme.fonts.primary.H5Bold}
  color: ${(props) => props.theme.colors.iconsSecondary};

  & > svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
  }
`;

export const ScaleUpToDown = keyframes`
     0% {
    transform: scaleY(0.7);
    transform-origin: 100% 0%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
`;

export const SelectDropdown = styled.div<{
  visible: boolean;
}>`
  display: ${(props) => (props.visible ? "flex" : "none")};
  user-select: none;
  flex-direction: column;
  position: absolute;
  z-index: 6 !important;
  margin-left: 4px;
  top: 36px;
  right: 0px;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.cardBackground};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  z-index: 2;
  padding: 10px 4px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  animation: ${ScaleUpToDown} 0.15s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  & > svg {
    position: absolute;
    right: 12px;
    top: 13.33px;
    width: 20px;
    color: ${(props) => props.theme.colors.iconsSecondary};
    &:hover {
      cursor: pointer;
    }
  }

  & > div {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0.5rem;
    user-select: none;
    cursor: pointer;
    ${(props) => props.theme.fonts.primary.H5SemiBold};
    color: ${(props) => props.theme.colors.textSecondary};

    span {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & > svg {
      height: 10px;
      width: 20px;
      margin-left: 5px;
      margin-bottom: 2px;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.cardHighlight};
    }
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    width: 72%;
    font-size: 1rem;
    background: ${(props) => props.theme.colors.headerBackground};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    gap: 20px;
  }
`;
