import ModalCompound from "../../ModalCompound";
import {
  ModalBodyContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  SeriesContainer,
  SeveralSeriesContainer,
} from "../styles";
import { InputRadio } from "../../InputRadio";
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikTextInput } from "../../textInput";
import { MdAdd, MdClose } from "react-icons/md";

interface ISensorChartModal {
  show: boolean;
  handleClose: () => void;
  editingChart: any;
  chartsData: any[];
  setChartsData: (args: any[]) => void;
}

export default function SensorChartModal({
  show,
  handleClose,
  editingChart,
  setChartsData,
  chartsData,
}: ISensorChartModal) {
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<any>>(null);
  const [newSeries, setNewSeries] = useState("");
  const [seriesList, setSeriesList] = useState<any[]>([]);

  const handleAddSeries = () => {
    if (newSeries.trim() !== "") {
      setSeriesList([...seriesList, newSeries]);
      setNewSeries("");
    }
  };

  function handleCancel() {
    setSeriesList([]);
    formRef.current?.resetForm();
    handleClose();
  }

  const handleSaveEditedChart = (updatedChart: any) => {
    const newChart = {
      ...updatedChart,
      metricUnits: {
        x: updatedChart.horizontalUnit || "",
        y: updatedChart.verticalUnit || "",
      },
      series: seriesList,
    };
    const updatedChartsData = chartsData.map((chart: any) =>
      chart.name === editingChart.name ? newChart : chart,
    );

    setChartsData(updatedChartsData);
    handleClose();
  };

  const handleSubmitModal = (values: any) => {
    const metricUnits = {
      x: values.horizontalUnit || "",
      y: values.verticalUnit || "",
    };

    const newChart = {
      name: values.name.toUpperCase(),
      label: values.label,
      type: values.type,
      metricUnits: metricUnits,
      series: seriesList,
    };
    const updateChartsdata = [...chartsData, newChart];
    setChartsData(updateChartsdata);
    formRef.current?.resetForm();
    handleClose();
  };

  useEffect(() => {
    if (editingChart) {
      setSeriesList(editingChart.series);
      const isGlobal = editingChart.type === "GLOBAL";
      formRef.current?.setValues({
        name: editingChart.name,
        label: editingChart.label,
        type: editingChart.type,
        horizontalUnit: isGlobal ? "" : editingChart?.metricUnits?.x || "",
        verticalUnit: editingChart?.metricUnits?.y || "",
      });
    }
  }, [editingChart]);

  return (
    <ModalCompound
      show={show}
      handleClose={() => {
        handleClose();
      }}
    >
      <Formik
        initialValues={editingChart || {}}
        onSubmit={editingChart ? handleSaveEditedChart : handleSubmitModal}
        innerRef={formRef}
      >
        {({ errors }: any) => (
          <Form>
            <ModalCompound.Body>
              <ModalHeaderContainer>
                <span>
                  {editingChart
                    ? t("newSensor.Editar")
                    : t("newSensor.Adicionar novo")}
                  {t("newSensor.Gráfico")}
                </span>
              </ModalHeaderContainer>

              <ModalBodyContainer>
                <div>
                  <FormikTextInput
                    name="name"
                    label={`${t("newSensor.ID do Gráfico")}`}
                    placeholder={`${t("newSensor.ID do Gráfico")}`}
                    type="text"
                    error={errors.name}
                  />
                  <FormikTextInput
                    name="label"
                    label={`${t("newSensor.Nome do Gráfico")}`}
                    placeholder={`${t("newSensor.Nome do Gráfico")}`}
                    type="text"
                    error={errors.label}
                  />
                </div>
                <div>
                  <InputRadio
                    name="type"
                    label={`${t("newSensor.Tipo")}`}
                    options={[
                      { value: "GLOBAL", label: "Temporal" },
                      { value: "DYNAMIC", label: "Static" },
                    ]}
                    error={errors.type}
                  />
                </div>
                <span>{t("newSensor.Unidades")}</span>
                <div>
                  <FormikTextInput
                    name="horizontalUnit"
                    label={`Horizontal`}
                    placeholder={`Horizontal`}
                    type="text"
                    error={errors.horizontalUnit}
                  />
                  <FormikTextInput
                    name="verticalUnit"
                    label={`Vertical`}
                    placeholder={`Vertical`}
                    type="text"
                    error={errors.verticalUnit}
                  />
                </div>
                <SeriesContainer>
                  <label>{t("newSensor.Adicionar Séries")}</label>

                  <SeveralSeriesContainer>
                    <div>
                      <input
                        type="text"
                        value={newSeries}
                        onChange={(e) =>
                          setNewSeries(e.target.value.toUpperCase())
                        }
                        placeholder={`${t("newSensor.Nova série")}`}
                      />
                      <button type="button" onClick={handleAddSeries}>
                        <MdAdd />
                      </button>
                    </div>

                    <div>
                      {seriesList.map((series, index) => (
                        <div key={index}>
                          {series}
                          <button
                            type="button"
                            onClick={() => {
                              const updatedSeriesList = seriesList.filter(
                                (_, idx) => idx !== index,
                              );
                              setSeriesList(updatedSeriesList);
                            }}
                          >
                            <MdClose />
                          </button>
                        </div>
                      ))}
                      {seriesList.length === 0 && (
                        <span>{t("newSensor.Nenhuma série adicionada.")}</span>
                      )}
                    </div>
                  </SeveralSeriesContainer>
                </SeriesContainer>
              </ModalBodyContainer>
            </ModalCompound.Body>
            <ModalCompound.Footer>
              <ModalFooterContainer>
                <ModalCompound.Cancel handleClose={handleCancel} />
                <ModalCompound.Save type="submit" />
              </ModalFooterContainer>
            </ModalCompound.Footer>
          </Form>
        )}
      </Formik>
    </ModalCompound>
  );
}
