import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const BreadcrumbLink = styled(Link)<{ color?: string }>`
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 300px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  span {
    font-size: 0.85rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  text-transform: none;
  text-decoration: none;

  color: ${(props) => props.color}!important;

  font-weight: 700;

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    span {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 767px) {
    max-width: 300px;
    height: auto;
    margin: auto;
    padding: 0 0.5rem;
  }
`;

export const BreadcrumbContainer = styled.div<{
  color?: string;
  isMobile?: boolean;
}>`
  color: ${(props) => props.color};
  display: flex;
  text-align: right;

  ${({ isMobile }) =>
    isMobile &&
    css`
      color: ${(props) => props.theme.colors.iconsSecondary};
      overflow-x: auto;
      display: flex;
      align-items: center;
      margin-left: 0.5rem;

      & > div {
        a {
          font-size: 8px;
        }
      }
    `}

  /* justify-content: end; */

  /* @media (max-width: 767px) {
    & > div:nth-child(n + 3) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    & > div:nth-child(n + 4) {
      display: none;
    }
  } */

  & > div {
    display: flex;
  }
`;

export const BreadcrumbSeparator = styled.div`
  height: 45px;
  padding-top: 10px;
  font-size: 0.9rem;
  display: flex;
  gap: 0.25rem;
`;
