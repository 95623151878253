import { DefaultTheme, css } from "styled-components";

const theme: DefaultTheme = {
  status: true,
  theme: "dark",
  backgroundColors: {
    background0: "#0C0E13",
    background1: "#161A25",
    background2: "#1E222F",
    background3: "#252A3B",
    background4: "#3A4058",
  },

  colors3: {
    color0: "#FFFFFF",
    color1: "#ADB1CF",
    color2: "#252A3B",
  },

  colors: {
    alarmRisco: "#FF0000",
    alarmAlerta: "#FFD700",
    white: "#FFFFFF",
    normalStatus: "#01D5BF",
    statusText: "#FFFFFF",
    colorTools: "#9CA3C6",
    clock: "#ce3878",
    p30: "#01d5bf",
    IconsTools: "#BDC1DA",
    backgroundStatus: "#252a3c4d",
    StatusTools: "#CE3878",
    background: "#0B0D12",
    headerBackground: "#161A25",
    divBackground: "#1E222F",
    divBackgroundAlpha: "#1E222F64",
    cardBackground: "#252A3C",
    cardHighlight: "#3A4058",
    iconsSecondary: "#ADB2CF",
    iconsSecondaryAlpha: "#3A4058",
    iconsPrimary: "#DDDFED",
    textSecondary: "#EBECF4",
    textPrimary: "#FFFFFF",
    itemsHighlight: " #01D5BF",
    statusNormal: "#01D5BF",
    statusAlert: "#DEA40F",
    lightOrange: "#FF9B30",
    ibbxOrange: "#F37035",
    statusRisk: "#CE3878",
    failureAlert: "#DEA40F",
    lightBackground: "#F3F6F8",
    inputBackground: "#0B0D12",
    inputBlock: "#161A25",
    passwordBackground: "#1E222F",
    headerCard: "#2B3040",
    headerCardSecondary: "#232737",
    BackgroundShadow: "#1E222F",
    BackgroundOccurrence: "#BDC1DA60",
    cardSectorBackground: "#2B3040",
    cardUnderlineLight: "#3A4058",
    cardUnderlineLightAlpha: "#3A405860",
    statusBroken: "#5756bf",
    buttonSecondary: "#ADB2CF",
    occurrenceInfoBackground: "#161A25",
    insightBulb: "#ffffff00",
    barColor: "#3A4058",
    sentitiveInspection: "#3f2f63",
    manualCollect: "#1d273a",
    backgroundNavigation: "#0b0d12cc",
    itemBackground: "#3A4058",
  },

  fonts: {
    primary: {
      H1boldCard: {
        "font-family": "Lexend",
        "font-weight": "400",
        "font-size": "72px",
        "line-height": "90px",
      },
      H1Semibold: {
        "font-family": "Lexend",
        "font-weight": "600",
        "font-size": "32px",
        "line-height": "38px",
      },
      H1Regular: {
        "font-family": "Lexend",
        "font-weight": "500",
        "font-size": "24px",
        "line-height": "38px",
      },
      H2boldCard: {
        "font-family": "Lexend",
        "font-weight": "400",
        "font-size": "56px",
        "line-height": "70px",
      },
      H2boldMobile: {
        "font-family": "Lexend",
        "font-weight": "400",
        "font-size": "42px",
        "line-height": "70px",
      },
      H2Bold: {
        "font-family": "Lexend",
        "font-weight": "700",
        "font-size": "18px",
        "line-height": "22px",
      },
      H3SemiBold: {
        "font-family": "Lexend",
        "font-weight": "600",
        "font-size": "16px",
        "line-height": "20px",
      },
      H4Bold: {
        "font-family": "Lexend",
        "font-weight": "700",
        "font-size": "14px",
        "line-height": "18px",
      },
      H4Regular: {
        "font-family": "Lexend",
        "font-weight": "400",
        "font-size": "14px",
        "line-height": "18px",
      },
      H5Bold: {
        "font-family": "Lexend",
        "font-weight": "700",
        "font-size": "12px",
        "line-height": "15px",
      },
      H5SemiBold: {
        "font-family": "Lexend",
        "font-weight": "600",
        "font-size": "12px",
        "line-height": "15px",
      },
      H5Regular: {
        "font-family": "Lexend",
        "font-weight": "400",
        "font-size": "12px",
        "line-height": "15px",
      },
      H6Bold: {
        "font-family": "Lexend",
        "font-weight": "700",
        "font-size": "8px",
        "line-height": "10px",
      },
      H6BoldCard: {
        "font-family": "Lexend",
        "font-weight": "700",
        "font-size": "9px",
        "line-height": "10px",
      },
    },
    secondary: {
      H1BoldCard: {
        "font-family": "Work Sans",
        "font-weight": "700",
        "font-size": "14px",
        "line-height": "14px",
      },
      H1BoldTable: {
        "font-family": "Work Sans",
        "font-weight": "700",
        "font-size": "16px",
        "line-height": "normal",
      },
      H1RegularTable: {
        "font-family": "Work Sans",
        "font-weight": "400",
        "font-size": "16px",
        "line-height": "normal",
      },
      H1Bold: {
        "font-family": "Work Sans",
        "font-weight": "700",
        "font-size": "12px",
        "line-height": "14px",
      },
      H1SemiBold: {
        "font-family": "Work Sans",
        "font-weight": "600",
        "font-size": "12px",
        "line-height": "14px",
      },
      H1Regular: {
        "font-family": "Work Sans",
        "font-weight": "400",
        "font-size": "12px",
        "line-height": "14px",
      },
      H2Regular: {
        "font-family": "Work Sans",
        "font-weight": "400",
        "font-size": "11px",
        "line-height": "13px",
      },
      H2RegularCard: {
        "font-family": "Work Sans",
        "font-weight": "500",
        "font-size": "13px",
        "line-height": "14px",
      },
      H3Bold: {
        "font-family": "Work Sans",
        "font-weight": "700",
        "font-size": "10px",
        "line-height": "12px",
      },
      H3Medium: {
        "font-family": "Work Sans",
        "font-weight": "500",
        "font-size": "10px",
        "line-height": "12px",
      },
      H3Regular: {
        "font-family": "Work Sans",
        "font-weight": "400",
        "font-size": "10px",
        "line-height": "12px",
      },
      H4Bold: {
        "font-family": "Work Sans",
        "font-weight": "700",
        "font-size": "9px",
        "line-height": "11px",
        "text-transform": "uppercase",
      },
      H5Bold: {
        "font-family": "Work Sans",
        "font-weight": "700",
        "font-size": "32px",
        "line-height": "38px",
      },
    },
  },

  mediaQuery: {
    //mobile
    small: "(max-width: 767px)",
    //tablet
    medium: "(min-width: 768px) and (max-width: 1280px)",
    //small desktop (125% de zoom)
    large: "(min-width: 1281px) and (max-width: 1536px)",
  },
};

export default theme;
