import styled, { css } from "styled-components";
import { ProfileScreen } from "../Profile/styles";
import {
  ConfigurationPagesContent,
  FrameBodyMain,
} from "../../../components/frame/styles";
import lightTheme from "../../../styles/themes/light";

export const FrameBodyMyAPI = styled(FrameBodyMain)`
  height: 100%;
  padding: 0;
`;

export const DevicesContainer = styled(ConfigurationPagesContent)`
  padding: 2% 4% 4%;
  width: 100%;
  height: calc(100% - 32px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background};

  & > div:first-child {
    display: flex;
    align-items: center;
    gap: 1rem;
    & > h2 {
      ${(props) => props.theme.fonts.primary.H1Semibold};
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }

  @media screen and (max-width: 767px) {
    opacity: 1;
    padding: 4px;
    margin-left: 0;
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    height: calc(100% - 8rem);
  }
`;

export const DeviceContent = styled(ProfileScreen)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  margin-top: 16px;

  @media screen and (max-width: 767px) {
    width: 100%;
    gap: 1.75rem;
  }
`;

export const Header = styled.div`
  width: 100%;
  padding: 0.75rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 767px) {
    div {
      width: 100%;
    }
  }
`;

export const TableContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 1rem;
  justify-content: center;

  table {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.BackgroundShadow};
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
  thead,
  tbody {
    display: block;
  }
  thead {
    ${(props) => props.theme.fonts.primary.H4Bold};
    background: ${({ theme }) => theme.colors.backgroundStatus};
    border-bottom: 2px solid ${({ theme }) => theme.colors.BackgroundShadow};
    text-transform: uppercase;
  }
  tbody {
    overflow-y: auto;
    height: calc(100% - 2rem);

    &::-webkit-scrollbar {
      width: 6px !important;
      height: 6px;
      @media screen and (max-width: 767px) {
        width: 3px !important;
      }
    }

    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.colors.background};
      width: 20px;
      border-radius: 100vw;
      margin-block: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }

    &::-webkit-scrollbar-thumb:active {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }
  }
  tr {
    padding: 1rem;
  }

  tr:nth-child(even) {
    background: ${({ theme }) => theme.colors.cardBackground};
  }

  th,
  td {
    min-width: 20rem;
    width: 50%;
    padding: 0.5rem;
  }

  th:first-child,
  td:first-child {
    display: flex;
    min-width: 8rem;
    height: 100%;
    justify-content: space-around;
  }

  td {
    padding: 0.5rem;
    ::first-letter {
      text-transform: uppercase;
    }
    ${(props) => props.theme.fonts.primary.H4Regular}
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 0.75rem;
    }
  }

  @media screen and (max-width: 767px) {
    table {
      width: 100%;
      height: 100%;
    }
    thead {
      text-transform: none;

      th {
        height: 100%;
      }
    }

    th,
    td {
      min-width: 5rem;
      width: 50%;
      padding: 0.5rem;
    }
    th:first-child,
    td:first-child {
      display: flex;
      min-width: 5rem;
      height: 100%;
      justify-content: space-around;
    }
  }
`;

export const EditingMode = styled.button<{ block?: boolean }>`
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  color: ${(props) => props.theme.colors.iconsSecondary};

  svg {
    font-size: 0.75rem;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
    scale: calc(1.05);
  }

  ${({ block }) =>
    block &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
  transition: all 0.3s ease;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid ${(props) => props.theme.colors.iconsPrimary};
  align-items: flex-end;

  input {
    height: 24px;
    border: none;
    outline: none;
    ${lightTheme.fonts.primary.H4Regular};
    width: 100%;
    padding-left: 10px;
    background-color: transparent;
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  svg {
    font-size: 25px;
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  &:focus {
    border-bottom: 1px solid ${lightTheme.colors.ibbxOrange};
  }

  @media screen and (max-width: 767px) {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
    border-radius: 8px;
    padding: 0.5rem;
  }
`;
