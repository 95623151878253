import { useEffect, useState } from "react";
import { Column } from "react-table";
import { Loading } from "../../../../components/Loading";
import ReactTable from "../../../../components/ReactTable";
import useApi from "../../../../hooks/useApi";
import {
  ColumnHeader,
  TableWrapper,
  UsersContainer,
  UserStatus,
} from "./styles";
import { RepresentativeCompaniesProps } from "./types";
import { useTranslation } from "react-i18next";

const RepresentativeCompanies = ({
  representative,
}: RepresentativeCompaniesProps) => {
  const [companies, setCompanies] = useState([]);
  const [, setPagination] = useState({});

  const { t } = useTranslation();

  const { request, processing } = useApi({
    path: `/representatives`,
  });

  useEffect(() => {
    request({
      method: "get",
      pathParameters: `${representative?.id}/companies`,
    }).then((response) => {
      setCompanies(response.data);
      setPagination(response.pagination);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [representative?.id]);

  return (
    <UsersContainer>
      {processing ? (
        <Loading />
      ) : (
        <TableWrapper>
          <ReactTable
            responsive
            columns={
              [
                {
                  id: "name",
                  accessor: "name",
                  Header: () => (
                    <ColumnHeader>
                      <span>{t("Representatives.Companies.name")}</span>
                    </ColumnHeader>
                  ),
                },
                {
                  id: "createdAt",
                  accessor: "createdAt",
                  Header: () => (
                    <ColumnHeader>
                      <span>{t("Representatives.Companies.createdAt")}</span>
                    </ColumnHeader>
                  ),
                  Cell: ({ value }: any) => {
                    return (
                      <span>
                        {new Intl.DateTimeFormat("pt-BR", {
                          dateStyle: "short",
                          timeStyle: "medium",
                        }).format(new Date(value))}
                      </span>
                    );
                  },
                },
                {
                  id: "contract",
                  accessor: "contract",
                  Header: () => (
                    <ColumnHeader>
                      <span>{t("Representatives.Companies.contract")}</span>
                    </ColumnHeader>
                  ),
                  Cell: ({ value }: any) => {
                    return (
                      <UserStatus active={value === "ACTIVE"}>
                        <span>{value === "ACTIVE" ? "ativo" : "P30"}</span>
                      </UserStatus>
                    );
                  },
                },
              ] as Column<object>[]
            }
            data={companies as object[]}
            renderEmptyResult={
              <span>{t("Representatives.Companies.noCompanies")}</span>
            }
          />
        </TableWrapper>
      )}
    </UsersContainer>
  );
};

export default RepresentativeCompanies;
