import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useRetinaTranslation from "../../../hooks/useRetinaTranslation";
import useApi from "../../../hooks/useApi";
import { success } from "../../Toast";
import { useLocation } from "react-router-dom";
import i18n from "../../../i18n";

export default function useOccurrenceEvidence({ evidence, refresh }: any) {
  const { buildEvidenceLabel } = useRetinaTranslation();
  const { t } = useTranslation();

  const title = useMemo(() => {
    return buildEvidenceLabel(evidence.label, evidence.metadata);
    // return evidence?.profile
    // eslint-disable-next-line
  }, [evidence, i18n.language]);

  const { assetId, assetOccurrenceId, id } = evidence;

  const location = useLocation();

  // eslint-disable-next-line
  const analysisLink = useMemo(() => {
    if (evidence.type !== "CHART") {
      return null;
    }

    const { chartName, positionId, date, metadata } = evidence;
    const baseLink = `/positions/${positionId}/analysis?chartName=${chartName}`;
    const queryItems = [];

    if (chartName?.includes("FFT") || chartName === "HAR-CORR") {
      queryItems.push("type=DYNAMIC");
      queryItems.push(`date=${new Date(metadata?.rxDate || date).getTime()}`);
      queryItems.push(`txDate=${new Date(metadata?.rxDate || date).getTime()}`);
    } else {
      const epoch = new Date(date).getTime();
      queryItems.push("type=GLOBAL");
      queryItems.push("period=CUSTOM");
      queryItems.push(
        `minDate=${metadata.minDate ? metadata.minDate : epoch - 24 * 3600000}`,
      );
      queryItems.push(
        `maxDate=${metadata.maxDate ? metadata.maxDate : epoch + 1 * 3600000}`,
      );
    }
    const currentLink = location.pathname;

    queryItems.push(`from=${currentLink}`);

    const link = `${baseLink}&${queryItems.join("&")}`;
    return link;
    // eslint-disable-next-line
  }, []);

  const { request } = useApi({
    path: `/assets/${assetId}/occurrences/${assetOccurrenceId}/evidences/${id}`,
  });

  function toggleDelete() {
    const method = evidence.deletedAt ? "put" : "del";
    const pathParameters = evidence.deletedAt ? "restore" : "";
    const message = evidence.deletedAt ? "restaurada" : "removida";

    request({ method, pathParameters }).then(() => {
      refresh();
      success(
        `${t(
          `occurrencePanel.OccurrenceEvidence.Evidência ${message} com sucesso!`,
        )}`,
      );
    });
  }

  return { title, toggleDelete, analysisLink };
}
