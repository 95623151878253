import CountUp from "react-countup";

import { Container } from "./styles";
import { EnvironmentProps } from "../types";

export function CardEsgData({
  value,
  infoLeft,
  infoRight,
  description,
  decimals,
  kwh,
  energySavingValue,
  contentDescription,
}: EnvironmentProps) {
  return (
    <Container
      kwh={kwh}
      energySavingValue={energySavingValue}
      value={value}
      theresContentDescription={contentDescription}
    >
      {value && (
        <div>
          <span>{infoLeft} </span>
          <span>
            <CountUp
              start={value || 0}
              end={value}
              duration={3}
              decimals={decimals}
              separator={","}
            />
          </span>
          <span>{infoRight}</span>
        </div>
      )}
      <div>
        <span>{description}</span>
        {contentDescription && <p>{contentDescription}</p>}
      </div>
    </Container>
  );
}
