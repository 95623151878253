import { useEffect, useState } from "react";
import useApi from "./useApi";

interface IUseFetch {
  path: string;
  autoRefresh?: boolean;
  seconds?: number;
  queryStringParameters?: Object;
  fetchManually?: boolean;
}

export const useFetch = ({
  path,
  autoRefresh,
  seconds,
  queryStringParameters,
  fetchManually,
}: IUseFetch) => {
  let secondsInterval = (seconds || 300) * 1000;
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();

  const { request, processing } = useApi({ path });

  const fetchData = () => {
    request({ method: "get", queryStringParameters: queryStringParameters })
      .then((response: any) => {
        setData(response);
      })
      .catch((error) => setError(error));
  };

  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(() => {
        fetchData();
      }, secondsInterval);

      return () => clearInterval(interval);
    }

    if (!fetchManually) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    processing,
    error,
    refresh: () => fetchData(),
    setData: (value: any) => setData(value),
  };
};
