import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DashboardState {
  hideDashboardCharts: boolean;
}

const initialState: DashboardState = {
  hideDashboardCharts: true,
};

const dashboardToggleSlice = createSlice({
  name: "dashboardToggle",
  initialState,
  reducers: {
    setHideDashboardCharts: (state, action: PayloadAction<boolean>) => {
      state.hideDashboardCharts = action.payload;
    },
  },
});

export const { setHideDashboardCharts } = dashboardToggleSlice.actions;
export default dashboardToggleSlice.reducer;
