import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SidebarState {
  showNewDeviceDrawer: boolean;
}

const initialState: SidebarState = {
  showNewDeviceDrawer: false,
};

const addNewDeviceSlice = createSlice({
  name: "addSensorDevice",
  initialState,
  reducers: {
    setShowNewDeviceDrawer: (state, action: PayloadAction<boolean>) => {
      state.showNewDeviceDrawer = action.payload;
    },
  },
});

export const { setShowNewDeviceDrawer } = addNewDeviceSlice.actions;
export default addNewDeviceSlice.reducer;
