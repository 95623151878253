import { useRef, useState } from "react";
import queryString from "../../../../utils/queryString";
import { useLocation, useSearchParams } from "react-router-dom";
import { CloseButton, DateContainer, DatePickerContainer } from "./styles";
import ptBR from "date-fns/locale/pt-BR";
import DatePicker, { registerLocale } from "react-datepicker";
import { AiFillCloseCircle } from "react-icons/ai";
import { isMobile } from "react-device-detect";
import useOnClickOutSide from "../../../../hooks/useOnClickOutside";
import { endOfDay, startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line
type GlobalPeriodPickerProps = {
  dateRange: [any, any];
  setDateRange: (value: [any, any]) => void;
};

function GlobalPeriodPicker({
  dateRange,
  setDateRange,
}: GlobalPeriodPickerProps) {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  registerLocale("ptBR", ptBR); // @TODO When apply translate

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, endDate] = dateRange;

  const ref = useRef(null);

  const isDateRangeEmpty = !!dateRange?.filter((item: any) => item !== null)
    .length;

  useOnClickOutSide(ref, () => {
    !isDateRangeEmpty && setShowDatePicker(false);
  });

  function handleDatePicker(value: any) {
    const params: any = queryString.parse(location.search);

    if (value[0] === null && value[1] === null) {
      setShowDatePicker(false);
      params.minDate = null;
      params.maxDate = null;
    }

    if (value[0]) {
      params.minDate = startOfDay(value[0]).getTime();
    } else {
      delete params.minDate;
    }
    if (value[1]) {
      params.maxDate = endOfDay(value[1]).getTime();
    } else {
      delete params.maxDate;
    }

    setDateRange(value);
    setSearchParams(params);
  }

  return (
    <DateContainer ref={ref}>
      <>
        <DatePickerContainer>
          <DatePicker
            dateFormat={isMobile ? "dd/MM" : "dd/MM/yyyy"} // @TODO TRANSLATE
            selectsRange={true}
            locale={ptBR}
            startDate={startDate}
            endDate={endDate}
            onChange={(value) => handleDatePicker(value)}
            isClearable={true}
            placeholderText={t("commonText.period") as string}
          />
        </DatePickerContainer>

        {startDate === null && endDate === null && (
          <CloseButton onClick={() => setShowDatePicker(!showDatePicker)}>
            <AiFillCloseCircle />
          </CloseButton>
        )}
      </>
    </DateContainer>
  );
}

export default GlobalPeriodPicker;
