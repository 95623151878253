import { ChromePicker, ColorResult } from "react-color";
import { Container } from "./styles";

const ColorPalette = ({
  selectedColor,
  setSelectedColor,
  handleChange,
  label,
}: any) => {
  const handleColorChange = (color: ColorResult) => {
    setSelectedColor(color.hex);
    handleChange(color.hex);
  };

  return (
    <Container>
      <span>{label}</span>
      <ChromePicker color={selectedColor} onChange={handleColorChange} />
    </Container>
  );
};

export default ColorPalette;
