import styled, { css } from "styled-components";

export const IconsColorPicker = styled.div<{ selected?: boolean }>``;

export const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 0 1.25rem;

  & > div {
    background-color: ${(props) => props.theme.colors.cardUnderlineLightAlpha};
    border-radius: 6px;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 767px) {
    align-items: flex-start;
    padding: 0.5rem;

    span {
      ${(props) => props.theme.fonts.primary.H4Regular};
      color: ${(props) => props.theme.colors.iconsPrimary};
    }

    & > div {
      padding: 0.5rem;
      grid-template-columns: repeat(6, 1fr);
    }
  }
`;

export const ColorOption = styled.div<{ color?: string; selected?: boolean }>`
  flex: 1;
  height: 4rem;
  background-color: ${(props) => props.color};
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  ${(props) =>
    props.selected &&
    css`
      transform: scale(1.05);
      border: 2px solid #ff9b3094;
    `}

  :hover {
    transform: scale(1.03);
  }

  @media screen and (max-width: 767px) {
  }
`;
