import { useTranslation } from "react-i18next";
import { OrderByButtonAssetOccurrence } from "../../../OrderByButtonAssetOccurrence";
import { HeaderTableContainer } from "./styles";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";

interface IHeaderTable {
  showSidebar?: boolean;
  favoriteCustomField?: any;
}
export default function HeaderTable({
  showSidebar,
  favoriteCustomField,
}: IHeaderTable) {
  const { t } = useTranslation();
  const params = useParams();
  const facilityLevel = params.companyId;

  const tableTitles = [
    { label: t("occurrenceAssetList.Criticidade"), orderByCode: "sysStatusId" },
    { label: t("occurrenceAssetList.Nº Ocorrência"), orderByCode: "id" },
    { label: t("occurrenceAssetList.Data/Horário"), orderByCode: "createdAt" },
    { label: t("occurrenceAssetList.Equipamento"), orderByCode: "assetName" },
    { label: t("occurrenceAssetList.Evidência"), orderByCode: "evidence" },
    { label: t("occurrenceAssetList.Diagnóstico"), orderByCode: "diagnostic" },
    {
      label: t("occurrenceAssetList.Tempo para ação"),
      orderByCode: "expiring",
    },
    { label: "", orderByCode: "closed" },
  ];

  if (favoriteCustomField && facilityLevel) {
    (favoriteCustomField || []).forEach((fields: any) => {
      if (fields) {
        const newElement = {
          label: fields?.key,
          orderByCode: fields?.id,
        };

        // Inserir na segunda posição
        tableTitles.splice(2, 0, newElement);
      }
    });
  }

  const filteredTableTitles = isMobile
    ? tableTitles.filter(
        (title) =>
          !["id", "evidence", "diagnostic", "expiring", "closed"].includes(
            title.orderByCode,
          ),
      )
    : tableTitles;

  const customFieldtoClassName = filteredTableTitles[2].orderByCode;

  return (
    <HeaderTableContainer
      showSidebar={showSidebar}
      customField={
        !favoriteCustomField?.some((field: any) => field === undefined)
      }
      customFieldtoClassName={customFieldtoClassName}
    >
      <table>
        <thead>
          {filteredTableTitles.map(({ label, orderByCode }: any, index) => (
            <th key={index} className={`occurrence-${orderByCode}`}>
              <OrderByButtonAssetOccurrence
                orderByCode={orderByCode}
                type={"ORDER"}
                label={label}
              />
            </th>
          ))}
        </thead>
      </table>
    </HeaderTableContainer>
  );
}
