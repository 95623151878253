import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 0.5rem;

  label {
    ${(props) => props.theme.fonts.primary.H4Regular}
  }
`;

export const Input = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  border-radius: 3px;
  outline: none;
  background-color: ${(props) => props.theme.colors.lightBackground};
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: ${(props) => props.theme.colors.ibbxOrange};
    border: 2px solid ${(props) => props.theme.colors.lightOrange};
  }

  &:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 6px;
    top: 25%;
    left: 20%;
    transform: translate(-50%, -50%);
    border: 2px solid white;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    background: transparent;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:checked:before {
    opacity: 1;
  }

  @media screen and (max-width: 767px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
