import { useEffect, useRef, useMemo } from "react";
import { CSVLink } from "react-csv";
import { getSerieName } from "../../../utils/charts/general";
import { error } from "../../Toast";
import { useTranslation } from "react-i18next";
import { IChart } from "../../../store/interfaces";

type ChartDataExportProps = {
  chart: IChart;
  chartData: any;
  setRunning: (value: any) => void;
  handleClose?: () => void;
};

function ChartDataExport({
  chart,
  chartData,
  setRunning,
  handleClose,
}: ChartDataExportProps) {
  const csvRef = useRef<any>(null);

  const { t } = useTranslation();

  const formattedDatatoCsv = useMemo(() => {
    const data = [];

    for (let row of chartData.data) {
      const label =
        chart.type === "GLOBAL"
          ? "Data"
          : chart.metricUnits.x === "Hz"
          ? "Frequência (Hz)"
          : "Tempo (s)";

      const object: any = {
        [label]:
          chart.type === "GLOBAL"
            ? new Intl.DateTimeFormat("pt-BR", {
                dateStyle: "short",
                timeStyle: "medium",
              })
                .format(new Date(row[0]))
                .replace(",", "")
            : row[0],
      };

      for (let serieIndex in chartData.series) {
        if (+serieIndex === 0) {
          continue;
        }

        const serie =
          getSerieName(chartData.series[+serieIndex], chart) ||
          `${t("ChartDataExport.valor")}`;

        object[serie] =
          row[+serieIndex] !== null
            ? Number(+row[+serieIndex].toFixed(chart.type === "GLOBAL" ? 2 : 5))
                .toString()
                .replace(".", ",")
            : row[+serieIndex];
      }

      if (row.includes("MANUAL")) {
        Object.assign(object, { Coleta: `${t("ChartDataExport.manual")}` });
      }

      data.push(object);
    }

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData.data]);

  useEffect(() => {
    if (formattedDatatoCsv?.length) {
      if (handleClose) {
        handleClose();
      }
      csvRef.current.link.click();
      setRunning(false);
    } else {
      setRunning(false);
      error(`${t("ChartDataExport.noData")}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedDatatoCsv]);

  return (
    <CSVLink
      asyncOnClick
      separator=";"
      style={{ display: "none" }}
      ref={csvRef}
      filename={`${t("ChartDataExport.data")} ${chart.label}`}
      className="csv"
      data={formattedDatatoCsv || []}
    ></CSVLink>
  );
}

export default ChartDataExport;
