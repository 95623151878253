import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Form } from "react-bootstrap";
import {
  FormHarmonic,
  FormHarmonicTitle,
  FormInputType,
  LabelHours,
} from "./styles";
import { ModalHarmonicsProps } from "./types";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { appSelect } from "../../../store/slices/appSlice";
import { Modal } from "../../../components/Modal";

export interface IHarmonicsFFT {
  active: boolean;
  fundamental: number;
  harmonics: number;
  order: number;
  dontShowFrequency?: boolean;
}

export const HarmonicsForm = ({
  show,
  handleClose,
  handleConfirm,
  chart,
}: ModalHarmonicsProps) => {
  const { customItems, currentPosition } = useAppSelector(appSelect);
  const { details } = currentPosition;
  const rotation = details?.pointRotation ? details?.pointRotation : null;

  const [frequency, setFrequency] = useState(rotation);
  const [harmonics, setHarmonics] = useState(4);
  const [order, setOrder] = useState(1);
  const [unit, setUnit] = useState("RPM");

  const isVibration = useMemo(() => {
    if (["HAR-CORR"].includes(chart)) {
      setFrequency(60);
      return false;
    }
    return true;
  }, [chart]);

  useEffect(() => {
    if (rotation) {
      setFrequency(rotation);
    }
    // eslint-disable-next-line
  }, []);

  const handleUnit = useCallback(
    (value: string) => {
      if (value === "RPM" && frequency) {
        setFrequency(frequency * 60);
      }
      if (value === "FUNDAMENTAL" && frequency) {
        setFrequency(frequency / 60);
      }
      setUnit(value);
    },
    [frequency],
  );

  const blockInvalidCharacter = (e: {
    key: string;
    preventDefault: () => any;
  }) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleRpm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFrequency(+event.target.value);
  };

  const handleHarmonics = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHarmonics(+event.target.value);
  };

  const handleOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrder(+event.target.value);
  };

  const { t } = useTranslation();

  const handleConfirmAnalisys = () => {
    const value = frequency ?? 0;
    let fundamental = unit === "RPM" && isVibration ? value / 60 : value;

    if (customItems?.frequencyUnit === "CPM") {
      fundamental = fundamental * 60;
    }

    handleConfirm(fundamental, harmonics, order);
  };

  return (
    <Modal
      handleClose={handleClose}
      show={show}
      handleConfirm={handleConfirmAnalisys}
      confirmLabel={`${t("HarmonicsForm.save")}`}
    >
      <>
        <FormHarmonicTitle>
          {t("HarmonicsForm.harmonicConfig")}
        </FormHarmonicTitle>
        {isVibration && (
          <FormInputType>
            <label>{t("HarmonicsForm.calculateHarmonic")}</label>
            <Form.Group style={{ display: "flex", gap: "15px" }}>
              <Form.Label style={{ display: "flex", gap: "5px" }}>
                <Form.Check
                  type="radio"
                  checked={unit === "RPM"}
                  onChange={() => handleUnit("RPM")}
                />
                {t("HarmonicsForm.rotation")}(rpm)
              </Form.Label>

              <Form.Label style={{ display: "flex", gap: "5px" }}>
                <Form.Check
                  type="radio"
                  checked={unit === "FUNDAMENTAL"}
                  onChange={() => handleUnit("FUNDAMENTAL")}
                />
                Fundamental(Hz)
              </Form.Label>
            </Form.Group>
          </FormInputType>
        )}
        <FormHarmonic>
          <div>
            <LabelHours>
              <label>
                {unit === "RPM" && isVibration
                  ? `${t("HarmonicsForm.rotation")}`
                  : "Fundamental"}
              </label>
              <input
                value={frequency}
                onChange={handleRpm}
                type="number"
                min="0"
                placeholder={`${t("HarmonicsForm.typeValue")}`}
                onKeyDown={blockInvalidCharacter}
              />
            </LabelHours>
            <LabelHours>
              <label>Harmônicas</label>

              <input
                value={harmonics}
                onChange={handleHarmonics}
                type="number"
                min="0"
                placeholder={`${t("HarmonicsForm.typeValue")}`}
                onKeyDown={blockInvalidCharacter}
              />
            </LabelHours>
            <LabelHours>
              <label>Ordens</label>

              <input
                value={order}
                onChange={handleOrder}
                type="number"
                min="0"
                placeholder={`${t("HarmonicsForm.typeValue")}`}
                onKeyDown={blockInvalidCharacter}
              />
            </LabelHours>
          </div>
        </FormHarmonic>
      </>
    </Modal>
  );
};
