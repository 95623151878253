import { useState, useMemo } from "react";
import {
  BatteryStatusContainer,
  ModalSensorStatusTitleRight,
  SensorStatusTitle,
} from "./styles";
import { ModalSensorStatusProps, sensorStatusPeriodType } from "./types";
import SensorStatusPeriodSelector from "./SensorStatusPeriodSelector";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal";

import { SignalQualityStatus } from "./SignalQualityStatus";
import { BatteryStatus } from "./BatteryStatus";
import RoundIconButton from "../RoundIconButton";
import { MdClose } from "react-icons/md";
import { BetaBadge } from "../Analytics/AnalyticsComponent/Compounds/BetaBadge";

export type batteryModeType = "voltage" | "percentage";

export const ModalSensorStatus = ({
  show,
  handleClose,
  position,
  detail,
}: ModalSensorStatusProps) => {
  const [period, setPeriod] = useState<sensorStatusPeriodType>("RECENT");
  const { t } = useTranslation();

  const type =
    detail === "battery"
      ? `${t("modalSensorStatus.battery")}`
      : `${t("modalSensorStatus.signalQuality")}`;
  const title = `${position?.positionName}: ${type}`;

  const height = useMemo(
    () => (detail === "battery" ? "80vh" : "540px"),
    [detail],
  );

  const size = useMemo(() => (detail === "battery" ? "xl" : "lg"), [detail]);

  if (!position) {
    return <></>;
  }

  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleConfirm={handleClose}
      height={height}
      size={size}
      changeFotter
      confirmLabel={`${t("commonText.close")}`}
    >
      <BatteryStatusContainer>
        <SensorStatusTitle>
          {title}{" "}
          <ModalSensorStatusTitleRight>
            {/* <SensorStatusPeriodSelector period={period} setPeriod={setPeriod} /> */}
            <RoundIconButton handleClick={handleClose}>
              <MdClose />
            </RoundIconButton>
          </ModalSensorStatusTitleRight>
        </SensorStatusTitle>
        {detail === "battery" ? (
          <BatteryStatus position={position} period={period} />
        ) : (
          <SignalQualityStatus position={position} period={period} />
        )}
      </BatteryStatusContainer>
    </Modal>
  );
};
