import styled from "styled-components";

export const ExpandedOccurrenceActions = styled.div`
  display: flex;
  width: fit-content;
  justify-content: flex-end;
  gap: 1rem;
  margin-right: 1rem;
  margin-left: auto;

  & > button,
  a {
    padding: 0.5rem 0;

    &:hover {
      background-color: transparent;
      border-radius: 0 !important;
      filter: brightness(1.1);
      font-weight: 600;
      border-radius: 6px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
        animation: slideIn 0.3s ease-in-out;
      }
    }
  }

  @keyframes slideIn {
    from {
      transform: scaleX(0);
      transform-origin: left;
    }
    to {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
  }
`;
export const ExpandedOccurrenceActionButton = styled.button<{
  disable?: boolean;
}>`
  border: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 0.5rem;
  color: ${(props) => props.theme.colors.iconsSecondary};
  background-color: transparent;
  pointer-events: ${({ disable }) => (disable ? "none" : "")};
  opacity: ${({ disable }) => (disable ? "0.4" : "1")};

  & > span {
    white-space: nowrap;
  }

  border: 1px solid transparent;
  ${(props) => props.theme.fonts.primary.H4Regular};
  transition: filter 0.3s ease;
  & > svg {
    width: 1rem;
    height: auto;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.headerCardSecondary};

    filter: brightness(1.1);
    font-weight: 600;
    border-radius: 6px;
  }

  @media screen and (max-width: 767px) {
    align-items: flex-start !important;
    ${(props) => props.theme.fonts.primary.H1Regular};
    font-size: 1rem;
    height: 3rem;

    span {
      text-align: left;
    }

    &:hover {
      background-color: transparent;

      &:active {
        background-color: ${(props) => props.theme.colors.headerCardSecondary};
      }
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    font-size: 12px;
  }
`;

export const ExpandedEvidencesContainer = styled.tr`
  height: 100%;
  overflow-x: auto;
  gap: 1rem;
  display: flex;
  padding: 0.5rem;

  & .fc-scroller {
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
    @media screen and (max-width: 767px) {
      width: 3px !important;
    }
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media screen and (min-width: 768px) and (max-width: 1280px) {
    & > div {
      flex-basis: 80%;
    }
  }
`;

export const EmptyEvidencesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const GoToOccurrenceButton = styled.button`
  position: relative;
  width: 20px;
  height: 20px;
  background: red;
`;
