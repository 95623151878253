import "react-phone-number-input/style.css";
import PhoneInput, {
  Country,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useFormikContext } from "formik";
import { Container } from "./styles";
import i18n from "../../i18n";
import { useEffect, useMemo, useState } from "react";
import EnglishCountryNames from "react-phone-number-input/locale/en.json";
import SpanishCountryNames from "react-phone-number-input/locale/es.json";
import PortugueseCountryNames from "react-phone-number-input/locale/pt-BR.json";
import { LoadingInput } from "../LoadingInput";
import { get } from "lodash";

interface IPhoneInputComponent {
  name: string;
  background?: string;
  error?: any;
  label?: string | null | undefined;
  loading?: boolean;
  disabled?: boolean;
}
export const PhoneInputComponent = ({
  name,
  background,
  label,
  error,
  loading = false,
  disabled = false,
}: IPhoneInputComponent) => {
  const { setFieldValue, values = {}, errors }: any = useFormikContext();

  const countrySettings: { defaultCountry: Country; countryNames: any } =
    useMemo(() => {
      switch (i18n.resolvedLanguage) {
        case "en": {
          return { defaultCountry: "US", countryNames: EnglishCountryNames };
        }
        case "es": {
          return { defaultCountry: "ES", countryNames: SpanishCountryNames };
        }

        default: {
          return {
            defaultCountry: "BR",
            countryNames: { ...PortugueseCountryNames, NL: "Holanda" },
          };
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.resolvedLanguage]);

  return (
    <Container background={background} disabled={disabled} error={errors[name]}>
      <label>{label}</label>
      {loading ? (
        <LoadingInput />
      ) : (
        <PhoneInput
          name={name}
          international
          value={get(values, name) || ""}
          onChange={(e) => setFieldValue(name, e)}
          defaultCountry={countrySettings.defaultCountry}
          focusInputOnCountrySelection
          labels={countrySettings.countryNames}
        />
      )}
    </Container>
  );
};
