import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  /* margin-left: 2.5%; */
  width: 97.5%;
  margin: 0px 0 20px;
  ${(props) => ({ ...props.theme.fonts.primary.H2Bold })};
`;

export const Form = styled.form`
  color: ${(props) => props.theme.colors3.color0};
  ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
`;

export const LabelHours = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors3.color0};
  flex-direction: column;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;

  label {
    margin: 0 0 6px 16px;
    width: 100%;
  }

  input {
    padding: 6px;
    width: 100%;
    height: 34px;
    outline: 0;
    border: none;
    border-radius: 4px;
    color: ${(props) => props.theme.colors3.color0};
    background: ${(props) => props.theme.colors.background};
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;
