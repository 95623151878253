import { BearingIconStyle } from "./styles";

export const BearingIcon = () => {
  return (
    <BearingIconStyle
      width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      
      <path fill-rule="evenodd" clip-rule="evenodd" d="M37 20C37 29.3888 29.3888 37 20 37C10.6112 37 3 29.3888 3 20C3 10.6112 10.6112 3 20 3C29.3888 3 37 10.6112 37 20ZM40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM23.6666 20C23.6666 22.025 22.025 23.6666 19.9999 23.6666C17.9749 23.6666 16.3333 22.025 16.3333 20C16.3333 17.9749 17.9749 16.3333 19.9999 16.3333C22.025 16.3333 23.6666 17.9749 23.6666 20ZM26.6666 20C26.6666 23.6819 23.6818 26.6666 19.9999 26.6666C16.318 26.6666 13.3333 23.6819 13.3333 20C13.3333 16.3181 16.318 13.3333 19.9999 13.3333C23.6818 13.3333 26.6666 16.3181 26.6666 20ZM9.5239 26.6666C10.5759 26.6666 11.4287 25.8139 11.4287 24.7619C11.4287 23.7099 10.5759 22.8571 9.5239 22.8571C8.47193 22.8571 7.61914 23.7099 7.61914 24.7619C7.61914 25.8139 8.47193 26.6666 9.5239 26.6666ZM12.4287 14.2381C12.4287 15.29 11.5759 16.1428 10.5239 16.1428C9.47193 16.1428 8.61914 15.29 8.61914 14.2381C8.61914 13.1861 9.47193 12.3333 10.5239 12.3333C11.5759 12.3333 12.4287 13.1861 12.4287 14.2381ZM30.4763 26.6666C31.5283 26.6666 32.3811 25.8139 32.3811 24.7619C32.3811 23.7099 31.5283 22.8571 30.4763 22.8571C29.4243 22.8571 28.5715 23.7099 28.5715 24.7619C28.5715 25.8139 29.4243 26.6666 30.4763 26.6666ZM32.3811 15.2381C32.3811 16.29 31.5283 17.1428 30.4763 17.1428C29.4243 17.1428 28.5715 16.29 28.5715 15.2381C28.5715 14.1861 29.4243 13.3333 30.4763 13.3333C31.5283 13.3333 32.3811 14.1861 32.3811 15.2381ZM18.0959 30.221C17.5699 31.132 17.882 32.297 18.7931 32.823C19.7041 33.3489 20.869 33.0368 21.395 32.1258C21.921 31.2147 21.6089 30.0498 20.6978 29.5238C19.7868 28.9978 18.6219 29.31 18.0959 30.221ZM19.3028 9.96578C18.3918 9.4398 18.0797 8.27486 18.6057 7.36383C19.1316 6.4528 20.2966 6.14065 21.2076 6.66664C22.1186 7.19262 22.4308 8.35756 21.9048 9.26859C21.3788 10.1796 20.2139 10.4918 19.3028 9.96578Z" fill="#9CA3C6"/>

    </BearingIconStyle>
  );
};
