import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const Content = styled(motion.div)<{
  available?: boolean;
  cardAnimation?: boolean;
}>`
  position: relative;
  /* width: ${({ cardAnimation }) => (!cardAnimation ? "250px" : "316px")};
  height: ${({ cardAnimation }) => (!cardAnimation ? "266px" : "306px")}; */

  width: 250px;
  height: 266px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  cursor: pointer;
  z-index: 1;

  span {
    color: #dddfed;
  }

  ${({ available }) =>
    available &&
    css`
      cursor: not-allowed;
    `}
`;

export const OnHoverContent = styled(motion.div)`
  width: 316px;
  height: 306px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 0.75rem;
  background-color: #232631db;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Header = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AppName = styled(motion.div)`
  flex: 1;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${(props) => props.theme.fonts.primary.H3SemiBold};
`;

export const GetAppButton = styled(motion.button)<{ isAvailable?: boolean }>`
  display: flex;
  background-color: ${(props) => props.theme.colors.cardUnderlineLightAlpha};
  color: ${({ isAvailable }) => (isAvailable ? "#dddfed" : "#adb2cf50")};
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 8px;
  ${(props) => props.theme.fonts.primary.H4Regular};
`;

export const GoToApp = styled(motion.button)<{ block?: boolean }>`
  border: none;
  background-color: transparent;

  ${({ block }) =>
    block &&
    css`
      pointer-events: none;
      cursor: not-allowed;
    `}
`;

export const GoToAppLink = styled(Link)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.cardUnderlineLightAlpha};
  color: #dddfed;

  &:hover {
    background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    color: #dddfed;
    transition: 0.3s all ease;
  }
`;

export const AppDescription = styled(motion.div)`
  ${(props) => props.theme.fonts.secondary.H2Regular};
  font-size: 0.75rem;
  color: #dddfed;
  display: flex;
  height: 100%;
  overflow: hidden auto;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &::-webkit-scrollbar {
    width: 3px !important;
    height: 3px;
    @media screen and (max-width: 767px) {
      width: 3px !important;
    }
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }
`;

export const Cover = styled(motion.div)<{ onHover?: boolean }>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0 0;
  position: relative;
  background-color: #3a405860;

  ${({ onHover }) =>
    onHover &&
    css`
      border-radius: 8px;
    `}

  img {
    width: fit-content;
    height: fit-content;
  }

  & > div {
    width: 100%;
    height: 100%;
    color: #dddfed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    svg {
      width: 3rem;
      height: auto;
    }
    span {
      ${(props) => props.theme.fonts.primary.H3SemiBold};
      font-weight: 500;
    }
  }
`;

export const DescriptionContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  background-color: #0b0d1240;
  border-radius: 0 0 8px 8px;
`;

export const GetAppContainer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ContainerInfo = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FavoriteApp = styled.section`
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  background-color: transparent;
  top: 0.5rem;
  left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
    height: auto;
  }
`;

export const CardMenu = styled.div`
  ${(props) => props.theme.fonts.primary.H4Regular};
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-end;
  background: ${(props) => props.theme.backgroundColors.background2} !important;
  border-radius: 8px;
  gap: 0 !important;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  right: 2.25rem;
  top: 0.25rem;
  /* width: 6rem; */
  /* max-width: 8rem; */
  height: fit-content !important;

  & > div {
    width: 100%;
    padding: 0.5rem 0.75rem;

    :hover {
      background: ${(props) => props.theme.colors.cardHighlight};
    }
  }

  :hover {
    cursor: pointer !important;
  }
`;
