import styled from "styled-components";

export const BatteryDetailsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  height: calc(80vh - 80px - 1rem);
  background-color: ${({ theme }) => theme.colors.backgroundNavigation};
  gap: 16px;
  flex-wrap: wrap;
  padding: 1rem;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border-radius: 1rem;

  @media screen and (max-width: 767px) {
    height: calc(50vh - 80px - 1rem);
  }
`;

export const BatteryChartWrapp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 250px;
  padding: 0px 20px;
  gap: 1rem;

  h2 {
    flex: 0 0 20px;
    ${({ theme }) => theme.fonts.primary.H3SemiBold};
    margin-bottom: 4px;
  }
  & > div:nth-child(1) {
    flex: 1 1 20px;
    height: 100%;
  }
  & > div:last-child {
    margin: 0;
    flex: 0 0 20px;

    & > div {
      padding: 0;
      margin: 0;
    }
  }
`;

export const BatteryStatusChartContainer = styled.div`
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  gap: 10px;
  display: flex;
  width: 100%;
  min-height: 52vh;
  max-height: 60vh;

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    overflow: auto;
  }
`;

export const BatteryInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: calc(100% - 400px);
  height: calc(80vh - 80px - 1rem);
  padding: 1rem;

  @media screen and (max-width: 767px) {
    width: calc(100%);
  }
`

export const BatteryChart = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  width: 100%;
  height: 100%;
  
  h2 {
    flex: 0 0 20px;
    ${({ theme }) => theme.fonts.primary.H3SemiBold};
    margin-bottom: 4px;
  }

  @media screen and (max-width: 767px) {
    height: 500px;
  }
`

export const BatteryStatusLoadingContainer = styled.div`
  width: 100%;
  height: calc(80vh - 80px - 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BatteryDetails = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  align-items: start;
  justify-content: start;
`

export const BatteryChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 90%;
  align-items: start;
  justify-content: start;

  @media screen and (max-width: 767px) {
    overflow: auto;
  }
`

export const BatteryInfoEmpty = styled.div`
  display: flex;
  width: 100%;
  height: 60vh;
  justify-content: center;
  align-items: center;
`