import queryString from "querystring";
import { useLocation, useSearchParams } from "react-router-dom";

import { SidebarInsideTab } from "../../../components/Sidebar/styles";
import { Loading } from "../../../components/Loading";

import CardBolt from "../CardBolt";

import { SidebarBoltsProps } from "./types";

import { error, success } from "../../../components/Toast";

import { EmptyBolts, SidebarBoltList } from "./styles";
import { BoltForm } from "../../../modals/BoltForm";
import { ImFileEmpty } from "react-icons/im";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import ConfirmDeleteBolt from "../../../modals/ConfirmDeleteBolt";
import useApi from "../../../hooks/useApi";

const SidebarBolt = ({
  bolts,
  processing,
  showModalFormBolt,
  setShowModalFormBolt,
  refresh,
  handleClickBolt,
}: SidebarBoltsProps) => {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const [selectedBolt, setSelectedBolt] = useState<any>();
  const [showBoltModalDelete, setShowBoltModalDelete] = useState(false);

  const { request } = useApi({ path: "/companies" });

  async function handleClickEdit(bolt: any) {
    setSelectedBolt(bolt);
    setShowModalFormBolt(true);
    refresh();
  }

  const handleClickDelete = (gatewayId: string | number) => {
    setSelectedBolt(gatewayId);
    setShowBoltModalDelete(true);
  };

  const handleBoltDelete = (
    comments?: string,
    type?: string,
    reason?: string,
  ) => {
    let body = {
      comments,
      type,
      reason: `${type} ${reason}`,
    };
    const { companyId, facilityId, gatewayId } = selectedBolt || {};
    request({
      method: "del",
      pathParameters: `${companyId}/facilities/${facilityId}/bolts/${gatewayId}`,
      body,
    })
      .then(() => {
        success(`${t("sidebarBolts.deleteSuccessMessage")}`);
        setShowBoltModalDelete(false);
        refresh();
        setSelectedBolt(null);
      })
      .catch((err: any) => {
        error(err.message);
      });
  };

  const handleCloseForm = () => {
    setShowModalFormBolt(false);
    setSelectedBolt(null);
  };

  const [cardSelected, setCardSelected] = useState<any>();

  const handleBoltClick = (item: any, index: number) => {
    handleClickBolt(item);

    const params = queryString.parse(location.search?.replace("?", ""));

    const newSearchParams = new URLSearchParams({
      ...params,
      boltId: item.gatewayId,
    });

    if (cardSelected === index) {
      setCardSelected(null);
    } else {
      setCardSelected(index);
    }

    setSearchParams(newSearchParams);
  };

  const renderBoltsCard = () => {
    return (
      <>
        {bolts.length === 0 && (
          <EmptyBolts>
            <ImFileEmpty size={60} />
            <span>{t("sidebarBolts.emptyMessage")}</span>
          </EmptyBolts>
        )}
        {bolts.map((item: any, index: number) => {
          return (
            <CardBolt
              bolt={item}
              key={item.gatewayId}
              handleEdit={() => handleClickEdit(item)}
              handleDelete={() => handleClickDelete(item)}
              reloadBolt={() => refresh()}
              handleClick={() => handleBoltClick(item, index)}
              selected={cardSelected === index}
              setSelected={() => setCardSelected(null)}
            />
          );
        })}
      </>
    );
  };

  return (
    <SidebarInsideTab>
      <SidebarBoltList>
        {processing ? <Loading /> : renderBoltsCard()}
      </SidebarBoltList>

      {showBoltModalDelete && (
        <ConfirmDeleteBolt
          show={showBoltModalDelete}
          handleClose={() => setShowBoltModalDelete(false)}
          handleConfirm={handleBoltDelete}
          required
          isActivator
        />
      )}
      {showModalFormBolt && (
        <BoltForm
          show={showModalFormBolt}
          handleClose={handleCloseForm}
          selectedBolt={selectedBolt}
          handleSuccess={refresh}
        />
      )}
    </SidebarInsideTab>
  );
};

export default SidebarBolt;
