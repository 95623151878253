import { useEffect, useState, useMemo } from "react";
import CountUp from "react-countup";

import { Box, Container, Data, Separator } from "./styles";
import { useLocation, useParams } from "react-router-dom";
import { getUrlPattern } from "../../utils/getUrlPattern";
import { ROUTES_PATH } from "../../routes/RoutesPath";
import useApi from "../../hooks/useApi";
import queryString from "../../utils/queryString";
import { useTranslation } from "react-i18next";

const CardAssetsStatus = () => {
  const location = useLocation();
  const params = useParams();
  const urlPattern = getUrlPattern(location, params);

  const { t } = useTranslation();

  const occurrenceQuery = useMemo(() => {
    if (urlPattern === ROUTES_PATH.OCCURRENCES) {
      return queryString.parse(location.search);
    }

    return null;
    // eslint-disable-next-line
  }, [location]);

  const [data, setData] = useState({
    total: 0,
    monitored: 0,
    normal: 0,
    alert: 0,
    risk: 0,
    critical: 0,
  });

  const { request } = useApi({ path: "/assets-status" });

  const loadMonitoredPoints = () => {
    let queryStringParameters: any = {};

    if (occurrenceQuery) {
      queryStringParameters = {
        companyId: params.companyId,
      };
    }

    if (urlPattern === ROUTES_PATH.ASSETS) {
      queryStringParameters.facilityId = params.facilityId;
    }

    if (urlPattern === ROUTES_PATH.OCCURRENCES) {
      queryStringParameters = {
        companyId: occurrenceQuery?.companyId,
        facilityId: occurrenceQuery?.facilityId,
        sectorId: occurrenceQuery?.sectorId,
        assetId: occurrenceQuery?.assetId,
      };
    }

    request({ method: "get", queryStringParameters, skipToast: true }).then(
      (response) => {
        setData(response);
      },
    );
  };

  useEffect(() => {
    loadMonitoredPoints();

    const interval = setInterval(() => {
      loadMonitoredPoints();
    }, 60 * 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occurrenceQuery]);

  return (
    <Container>
      <h3>{t("CardAssetsStatus.assets")}</h3>
      <Box>
        <>
          <>
            <Data>
              <span>
                <CountUp
                  start={data.total || 0}
                  end={data.total}
                  duration={0.8}
                  separator="."
                />
              </span>
              <label>{t("CardAssetsStatus.registered")}</label>
            </Data>
            <Separator></Separator>
          </>

          <Data>
            <span>
              <CountUp
                start={data.monitored || 0}
                end={data.monitored}
                duration={0.8}
                separator="."
              />
            </span>
            <label>{t("CardAssetsStatus.monitored")}</label>
          </Data>
          <Separator></Separator>
          <Data>
            <span>
              <CountUp
                start={data.normal || 0}
                end={data.normal}
                duration={0.8}
                separator="."
              />
            </span>
            <label>{t("dictionary.status.Normal")}</label>
          </Data>
          <Separator></Separator>
          <Data>
            <span>
              <CountUp
                start={data.alert || 0}
                end={data.alert}
                duration={0.8}
                separator="."
              />
            </span>
            <label>{t("CardAssetsStatus.alert")}</label>
          </Data>
          <Separator></Separator>
          <Data>
            <span>
              <CountUp
                start={data.risk || 0}
                end={data.risk}
                duration={0.8}
                separator="."
              />
            </span>
            <label>{t("CardAssetsStatus.risk")}</label>
          </Data>
          <Separator></Separator>
          <Data>
            <span>
              <CountUp
                start={data.critical || 0}
                end={data.critical}
                duration={0.8}
                separator="."
              />
            </span>
            <label>{t("CardAssetsStatus.critical")}</label>
          </Data>
        </>
      </Box>
    </Container>
  );
};

export default CardAssetsStatus;
