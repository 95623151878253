import { Container } from "./styles";

interface IListTabDesktop {
  isFiltered?: boolean;
  showSidebar?: boolean;
  children: JSX.Element | JSX.Element[];
}

export default function ListTabDesktop({ children, ...rest }: IListTabDesktop) {
  return (
    <Container {...rest} id="scrollableDiv">
      {children}
    </Container>
  );
}
