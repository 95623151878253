import styled, { css } from "styled-components";
import { getStatusColorById } from "../../../utils/getStatusColor";

export const ButtonAnalisysContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3%;
  border: 1px solid red;
  width: 45%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const ContainerTop = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  & > div {
    display: flex;
    gap: 0.5rem;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    grid-template-columns: 2fr 2fr 1fr;
  }
  
  @media screen and (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    height: 4rem;
    justify-content: space-between;

    & > div:nth-child(1) {
      width: 65%;
      height: 1.8rem;
      text-align: left;
      padding: 0 0 0 0.3rem !important;
      svg {
        font-size: 10px !important;
      }
    }
    & > div:nth-child(2) {
      width: 100%;
      height: 1.8rem;
      padding: 0 !important;
      margin-top: 6px;

      & > button:nth-child(2) {
        position: absolute;
        top: 0px;
        right: 48px;
        max-height: 24px;
      }
      & > button:last-of-type {
        position: absolute;
        top: 0px;
        right: 0px !important;
        max-height: 24px;
      }
    }
  }
`;

// header dos gráficos globais - dinâmicos
export const ContainerItens = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${(props) => props.theme.colors.iconsSecondary};

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    svg {
      width: 1.5rem;
      height: auto;
    }
  }

  @media screen and (max-width: 767px) {
    padding-left: 6% !important;

    svg {
      width: 1.6rem;
      height: auto;
    }

    padding-left: 0px;
    div &:nth-child(1) {
    }
    //ORDENAÇÃO da div dos botões de seleção de período (dia, semana, mês e ano)
    div &:nth-child(2) {
      width: 100%;
      order: 3;
      z-index: 1;
    }
    div &:nth-child(3) {
    }
  }
`;

export const ButtonsAlert = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
  padding: 3px;
  background-color: ${(props) => props.theme.colors.divBackground};
  border: 1px solid transparent !important;
  color: ${(props) => props.theme.colors.iconsSecondary};

  &:hover {
    border-radius: 6px;
    color: ${(props) => props.theme.colors.ibbxOrange};
    border: 1px solid ${(props) => props.theme.colors.cardHighlight} !important;
  }
  &:active {
    color: ${(props) => props.theme.colors.ibbxOrange};
    background-color: ${(props) => props.theme.colors.cardHighlight};
    font-size: 25px;
    transform: scale(0.9);
  }
`;

export const ButtonDownload = styled.button<{ thereIsChartData?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
  padding: 3px;
  background-color: ${(props) => props.theme.colors.divBackground};
  border: 1px solid transparent !important;
  color: ${(props) => props.theme.colors.iconsSecondary};
  opacity: ${(props) => (props.thereIsChartData ? "0.3" : "1")};
  cursor: ${(props) =>
    props.thereIsChartData ? "not-allowed" : "pointer"} !important;
  ${(props) =>
    !props.thereIsChartData &&
    css`
      &:hover {
        border-radius: 6px;
        color: ${(props) => props.theme.colors.ibbxOrange};
        border: 1px solid ${(props) => props.theme.colors.cardHighlight} !important;
      }
      &:active {
        color: ${(props) => props.theme.colors.ibbxOrange};
        background-color: ${(props) => props.theme.colors.cardHighlight};
        font-size: 25px;
        transform: scale(0.9);
      }
    `};
`;

export const FrameButtons = styled.button`
  width: 56px;
  height: 35px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  background: ${(props) => props.theme.colors.cardBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  /* margin: 22px 8px 0 0; */
  margin-right: 8px;
  &:first-child {
    /* margin-left: 24px; */
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.divBackground};
  }

  svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
  svg {
    &:hover {
      border-radius: 4px;
    }
    &:active {
      padding: 1px;
    }
  }
`;

export const CleanButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: 62px;
    height: 26px;
    margin: 16px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colors.cardHighlight};
    border: none;
    border-radius: 4px;
    ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};
    color: ${(props) => props.theme.colors3.color0};

    &:hover {
      border: 1px solid ${(props) => props.theme.colors.iconsPrimary};
    }

    &:active {
      color: ${(props) => props.theme.colors.iconsSecondary};
      border: 1px solid ${(props) => props.theme.colors.background};
    }
  }

  svg {
    margin-right: 1px;
  }
`;

export const FormAlertAndRisk = styled.form`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  /* max-width: 674px; */
  height: 100%;
  /* max-height: 262px; */

  margin-top: 0;
  border: none;
  gap: 20px;
  padding: 12px;

  color: ${(props) => props.theme.colors3.color0};
  ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};

  label {
    background: ${(props) => props.theme.colors.statusAlert};
    padding: 10px;
    width: 100%;
    height: 34px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  label:last-child {
    background: ${(props) => props.theme.colors.statusRisk};
  }

  input {
    padding: 6px;
    width: 70%;
    min-width: 10;
    height: 34px;
    outline: 0;
    /* margin-bottom: 20px; */
    background: transparent;
    border: none;
    /* border: 1px solid ${(props) => props.theme.colors.colorTools}; */
    border-radius: 4px;
    color: ${(props) => props.theme.colors3.color0};

    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    input,
    select,
    textarea,
    label,
    p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    input,
    select,
    textarea,
    label,
    p {
      font-size: 16px;
    }
    width: 100%;
  }
`;

export const LabelHours = styled.div`
  width: 100%;
  min-width: 10;
  height: 34px;
  margin-bottom: 20px;
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.colorTools};
  border-radius: 4px;
  color: ${(props) => props.theme.colors3.color0};

  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center;

  div {
    width: 30%;
    color: ${(props) => props.theme.colors3.color0} !important;
    opacity: 0.6;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 100%;
`;

export const GraphicMenuButtonInfo = styled.div`
  button {
    margin-top: 2px;
    margin-left: 8px;
    position: unset;
    right: 32px;
    top: 2px;
    color: red;
  }
`;

export const ChartName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      width: 1.25rem;
      height: auto;
    }
  }
`;

export const StatusLabel = styled.label<{
  statusId: 1 | 2 | 3 | 4 | 5;
  stopAnimation?: 1 | 2 | 3;
}>`
  display: flex;
  padding: 4px;
  background-color: ${({ statusId, theme }) =>
    getStatusColorById(theme, statusId)};
  ${(props) => ({ ...props.theme.fonts.secondary.H3Bold })};
  text-transform: uppercase;
  color: white;
  border-radius: 4px;
  box-shadow: 0px 6px 8px -3px rgba(0, 0, 0, 0.4);
  animation: ${({ stopAnimation }) =>
    stopAnimation && stopAnimation === 3
      ? "none"
      : "vibrate-1 0.4s linear infinite both"};

  @keyframes vibrate-1 {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translate(-1px, 1px);
    }
    40% {
      transform: translate(-1px, -1px);
    }
    60% {
      transform: translate(1px, 1px);
    }
    80% {
      transform: translate(1px, -1px);
    }
    100% {
      transform: translate(0);
    }
  }
`;

export const InformLabel = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  position: absolute;
  top: 1.5rem;
  border-radius: 6px;
  z-index: 1;
  ${(props) => ({ ...props.theme.fonts.secondary.H2Regular })};

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    ${(props) => ({ ...props.theme.fonts.secondary.H3Regular })};
  }

  @media screen and (max-width: 767px) {
    top: 3.75rem;
    ${(props) => ({ ...props.theme.fonts.secondary.H4Bold })};
    text-transform: lowercase;

    span {
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;
