import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background-color: ${(props) => props.theme.colors.occurrenceInfoBackground};
  position: relative;
  gap: 1rem;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  header {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    ${(props) => props.theme.fonts.primary.H2Bold};
  }

  section {
    display: flex;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    padding: 1rem 0.75rem;
    border-radius: 8px;

    span {
      font-size: 10px;
      font-weight: 400;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: auto;

    .hidenButton {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0.5rem;
    height: calc(100% - 3.5rem);
  }
`;

export const FirstSection = styled.section`
  gap: 1rem;
  height: 10rem;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    flex: 1;
    flex-direction: column;
  }
  & > div:last-child {
    width: 35%;
    height: 100%;
    border: 1px dashed ${(props) => props.theme.colors.cardUnderlineLightAlpha};
    border-radius: 8px;
    &:hover {
      border: 1px dashed ${(props) => props.theme.colors.iconsSecondaryAlpha};
      transition: all 0.3s ease;
    }

    div {
      height: 100%;
      max-height: 10rem;
      width: 100%;

      img {
        height: 100%;
        width: fit-content;
        border-radius: 8px;
      }
      button {
        right: 6px;
        top: 6px;
      }
      span,
      svg {
        color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
        &:hover {
          color: ${(props) => props.theme.colors.iconsSecondary};
          transition: all 0.3s ease;
        }
        font-size: 0.85rem;
      }
    }
  }
`;
export const SecondSection = styled.section`
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  label {
    ${(props) => props.theme.fonts.primary.H4Bold};
  }
  & > div {
    display: flex;
    width: 100%;
    gap: 1rem;
  }

  thead {
    ${(props) => props.theme.fonts.primary.H5SemiBold};
    background: ${({ theme }) => theme.colors.BackgroundShadow};
    border-bottom: 2px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
  }
  tr {
    padding: 1rem;
  }
  tr:nth-child(even) {
    background: ${({ theme }) => theme.colors.cardBackground};
  }

  th {
    padding: 0.5rem;
    width: 15%;
  }
  td {
    padding: 0.5rem;
    width: 15%;
    border-right: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 0.75rem;
    }
  }

  th:last-child {
  }
  td:last-child {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    border: none;
  }

  @media screen and (max-width: 767px) {
    overflow: hidden auto;
    flex: none;
    height: 50vh;
  }
`;

export const AddNewSensorContainer = styled.div`
  background-color: ${(props) => props.theme.colors.cardBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  ${(props) => props.theme.fonts.primary.H5Bold};
  color: ${(props) => props.theme.colors.iconsSecondary};

  svg {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 767px) {
    padding: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.25rem;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
    transition: all 0.3s ease;
  }
`;

export const AddNewChartHeader = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  ${(props) => props.theme.fonts.primary.H3SemiBold};
  color: ${(props) => props.theme.colors.iconsSecondary};
  font-weight: 400;
  cursor: pointer;
  font-size: 0.9rem;
  &:hover {
    font-weight: 700;
    transition: all 0.3s ease;
  }
`;

export const AddNewChartButton = styled.button`
  background: ${(props) => props.theme.colors.ibbxOrange};
  background: ${(props) => props.disabled && props.theme.colors.lightOrange};
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  border: 1px solid transparent;
  align-items: center;
  ${(props) => props.theme.fonts.primary.H5SemiBold};
  svg {
    font-size: 1.5rem;
  }
  &:hover {
    border: 1px solid
      ${(props) =>
        props.disabled
          ? "#BDC1DA"
          : props.theme.colors.iconsPrimary} !important;
    background: ${(props) =>
      props.disabled ? "#BDC1DA" : props.theme.colors.ibbxOrange};
  }

  &:active {
    border: 1px solid transparent;
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.iconsPrimary};
    svg {
      font-size: 1.45rem;
    }
  }
  transition: all 0.3s ease;
`;

export const EmptyChartData = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};

  span {
    ${(props) => props.theme.fonts.primary.H4Regular};
    font-size: 1rem !important;
  }
  span:last-child {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
    transition: all 0.3s ease;
  }
`;

export const EditingMode = styled.button`
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  color: ${(props) => props.theme.colors.iconsSecondary};

  svg {
    font-size: 0.75rem;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
    scale: calc(1.05);
  }
  transition: all 0.3s ease;
`;

export const ModalHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  span {
    ${(props) => props.theme.fonts.primary.H3SemiBold};
  }
`;
export const ModalBodyContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  width: 100%;
  height: 100%;
  flex-direction: column;

  span {
    ${(props) => props.theme.fonts.primary.H4Regular};
  }

  & > div {
    display: flex;
    width: 100%;
    gap: 1rem;
  }

  & > div:nth-child(2) {
    & > div {
      & > div {
        width: 100%;
        label {
          ${(props) => props.theme.fonts.primary.H4Regular};
          font-size: 0.875rem;
          input {
            width: 1.15rem;
            height: 1.15rem;
            cursor: pointer;
          }
          input[type="radio"]::selection {
            color: red;
          }
        }
      }
    }
  }
`;

export const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .hidenButton {
    display: none;
  }
`;

export const SeriesContainer = styled.div`
  width: 100%;
  height: 7.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem !important;
  label {
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${({ theme }) => theme.colors.iconsPrimary};
  }
  & > div:first-child {
    label {
      ${(props) => props.theme.fonts.primary.H4Regular};
    }
  }
`;

export const SeveralSeriesContainer = styled.div`
  width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;

  & > div:first-child {
    display: flex;
    gap: 1rem;
    button {
      border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
      color: ${(props) => props.theme.colors.iconsSecondary};
      border-radius: 8px;
      svg {
        width: 2rem;
        height: auto;
      }
    }
    input {
      ${(props) => props.theme.fonts.primary.H4Regular};
      width: 100%;
      min-height: 34px;
      color: ${(props) => props.theme.colors.iconsPrimary};
      background: ${(props) => props.theme.colors.background};
      border-radius: 4px;
      padding: 8px;
      border: 1px solid ${(props) => props.theme.colors.cardHighlight};

      &:hover {
        border: 1px solid ${(props) => props.theme.colors.IconsTools};
      }

      &:focus-visible {
        outline: none;
        border: 1px solid ${(props) => props.theme.colors.IconsTools};
      }
    }
  }

  & > div:last-child {
    display: flex;
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    padding: 0.5rem;
    border-radius: 8px;
    height: 100%;
    gap: 0.5rem;

    & > span {
      color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};
      margin: auto;
    }

    & > div {
      ${(props) => ({ ...props.theme.fonts.primary.H4Bold })};
      padding: 2px 12px;
      background-color: ${(props) => props.theme.colors.iconsPrimary};
      color: ${(props) => props.theme.colors.background};
      border-radius: 50px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      svg {
        width: 18px;
        height: auto;
      }

      button {
        border: none;
      }

      &:hover {
        svg {
          transform: scale(1.2);
          transition: all 0.2s ease-in-out;
        }
      }

      @media only screen and (max-width: 1536px) {
        ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};
        font-size: 10px;
      }

      @media screen and (max-width: 767px) {
        margin: 0.2rem 0.1rem;
        background-color: transparent;
        color: ${(props) => props.theme.colors.iconsPrimary};
        text-decoration: underline;
        padding: 0 0.1rem !important;
        max-width: fit-content;
        min-width: 2.2rem;
        flex: 1;

        span {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    & > input {
      font-size: 1rem !important;
    }
  }
`;
