import Steps from "../../../Joyride/components";

export const defaultSteps = ({
  t,
  isMobileOnly,
}: {
  t: any;
  isMobileOnly: boolean;
}) => {
  const width = window.innerWidth;
  const isMediumScreen = width > 768 && width < 1281;

  return [
    {
      target: "#sidebar",
      content: (
        <Steps.Root>
          <Steps.Title>
            {t("ConnectivityJoyirde.Gateway Registration and Management")}
          </Steps.Title>
          <Steps.Content>
            {t("ConnectivityJoyirde.View all your gateways here")}
          </Steps.Content>
        </Steps.Root>
      ),
      disableBeacon: true,
      placement: isMobileOnly ? "auto" : "right",
    },
    {
      target: "#gatewayConnectivity",
      content: (
        <Steps.Root>
          <Steps.Title>
            {t("ConnectivityJoyirde.Follow the general status of the Bolts")}
          </Steps.Title>
          <Steps.List>
            <Steps.ListItems>
              {t("ConnectivityJoyirde.Operation")}
            </Steps.ListItems>
            <Steps.ListItems>
              {t("ConnectivityJoyirde.Synchronization")}
            </Steps.ListItems>
            <Steps.ListItems>{t("ConnectivityJoyirde.Update")}</Steps.ListItems>
            <Steps.ListItems>
              {t("ConnectivityJoyirde.Offline")}
            </Steps.ListItems>
          </Steps.List>
        </Steps.Root>
      ),
      disableBeacon: true,
      placement: isMediumScreen ? "right" : "auto",
    },
    {
      target: "#connected",
      content: (
        <Steps.Root>
          <Steps.Title>
            {t("ConnectivityJoyirde.Endpoint Status Overview")}
          </Steps.Title>
          <Steps.Content>
            {t("ConnectivityJoyirde.To complement Connectivity management")}
          </Steps.Content>
        </Steps.Root>
      ),
      disableBeacon: true,
    },
    {
      target: "#connected",
      content: (
        <Steps.Root>
          <Steps.Content>
            {t(
              "ConnectivityJoyirde.Enjoy the new Connectivity journey here at Retina",
            )}
          </Steps.Content>
        </Steps.Root>
      ),
      disableBeacon: true,
    },
  ];
};

export const firstSteps = ({ t }: { t: any }) => {
  return [
    {
      target: "#connectivity",
      content: (
        <Steps.Root>
          <Steps.Title>
            {t("ConnectivityJoyirde.Welcome to the Connectivity area")}
          </Steps.Title>
          <Steps.Content>
            {t("ConnectivityJoyirde.In this section")}
          </Steps.Content>
        </Steps.Root>
      ),
      disableBeacon: true,
      continuous: false,
      showSkipButton: true,
    },
    {
      target: "#connectivity",
      content: "",
    },
  ];
};
