import { ISerieControl } from "../../hooks/useSeriesControl";

const formatSerie = (series: string[]) => {
  var styledIndex: number[] = [];
  var newSeries = series.reduce((acc: any[], serie: string, i: number) => {
    acc.push(serie);

    if (!["Alerta", "Risco"].includes(serie) && i > 0) {
      acc.push({ type: "string", role: "style" });
      styledIndex.push(i);
    }
    return acc;
  }, []);

  return { newSeries, styledIndex };
};

function getSerieStyle(serieControl: ISerieControl, hasComment: boolean) {
  const color = `; fill-color: ${serieControl?.color};`;
  const style = `point { visible: true; size: 5.5; shape-type: circle ${color} }`;

  return hasComment && serieControl?.visible ? style : null;
}

export const getComments = (
  data: any,
  comments: any,
  series: any,
  seriesControl: any,
) => {
  var commentsPositions = Object.values(comments).map((e: any) =>
    Object.keys(e).map((key) => +key),
  );

  var seriesStyled: number[] = [];
  var commentsData = data.reduce((agg: any, row: any[], index: number) => {
    if (index === 0) {
      const { newSeries, styledIndex } = formatSerie(series);
      seriesStyled = styledIndex;
      agg.push(newSeries);
    } else {
      var stylePerSerie: Array<string | null> = [];
      for (let i = 0; i < commentsPositions.length; i++) {
        const indexHasComment = commentsPositions[i].includes(index - 1);
        stylePerSerie.push(getSerieStyle(seriesControl[i], indexHasComment));
      }

      var serieIndex = 0;
      agg.push(
        row.reduce((acc: any, value: any, i: number) => {
          acc.push(value);
          // Se for série, insere estilo
          if (seriesStyled.includes(i)) {
            acc.push(stylePerSerie[serieIndex]);
            serieIndex++;
          }

          return acc;
        }, []),
      );
    }

    return agg;
  }, []);

  return commentsData;
};
