import { useMemo, useRef, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  DateInputContainer,
  ContainerDatePicker,
  LabelContainer,
  DateInputFieldContainer,
} from "./styles";
import { CalendarIcon } from "../DynamicDateSelect/styles";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import { isMobile } from "react-device-detect";
import InfoButton from "../../modals/PositionForm/InputRender/InfoButton";
import { t } from "i18next";
import { LoadingInput } from "../LoadingInput";
import { MS_IN_DAY, MS_IN_SECOND } from "../../constants/dates";

interface IDateInput {
  name: string;
  label?: any;
  format?: string;
  showIcon?: boolean;
  minDate?: any;
  maxDate?: any;
  placeholder?: string;
  disabled?: boolean;
  important?: boolean;
  isLoading?: boolean;
  error?: string;
  setDateAsEndOfDay?: boolean;
}

export function DateInput({
  label,
  name,
  format,
  showIcon,
  minDate,
  maxDate,
  placeholder,
  disabled,
  important,
  isLoading,
  error,
  setDateAsEndOfDay,
}: IDateInput) {
  const { setFieldValue, setFieldError } = useFormikContext() || {};
  const [field] = useField(name) || [];
  registerLocale("ptBR", ptBR);

  const dateTime = useMemo(
    () => (field?.value && new Date(field?.value)) || null,
    // eslint-disable-next-line
    [field.value],
  );

  function getDateAtTheEndOfDay(val: Date) {
    const dateEpochInMs = new Date(val).getTime();
    const dateAtStartOfDay = Math.floor(dateEpochInMs / MS_IN_DAY) * MS_IN_DAY;
    const dateAtEndOfDay = dateAtStartOfDay + MS_IN_DAY - MS_IN_SECOND;
    return new Date(dateAtEndOfDay);
  }

  function getNewDateValue(val: Date | null) {
    if (val === null) {
      return null;
    }

    if (setDateAsEndOfDay) {
      return getDateAtTheEndOfDay(val);
    }

    return val;
  }

  const handleDateChange = (val: Date | null) => {
    const date = getNewDateValue(val);
    setFieldValue(name, date);
    if (error && date) {
      setFieldError(name, "");
    }
  };

  const pickerRef = useRef<any>(null);

  useEffect(() => {
    if (isMobile && pickerRef.current !== null) {
      pickerRef.current.input.readOnly = true;
    }
    // eslint-disable-next-line
  }, [isMobile, pickerRef]);

  return (
    <DateInputContainer>
      <LabelContainer>
        {label && <label>{label}</label>}
        {important && (
          <InfoButton
            name={"date-important-button"}
            hasValue={!!field?.value}
            content={
              !!field?.value ? (
                <>
                  <strong>
                    {t("PositionForm.InputRender.inputAdviseStrong")}
                  </strong>{" "}
                  {t("PositionForm.InputRender.fullfilled")}
                </>
              ) : (
                <>
                  <strong>
                    {t("PositionForm.InputRender.inputAdviseStrong")}
                  </strong>{" "}
                  {t("PositionForm.InputRender.inputAdvise")}
                </>
              )
            }
          />
        )}
      </LabelContainer>
      {!!isLoading && <LoadingInput />}
      {!isLoading && (
        <DateInputFieldContainer disabled={disabled} error={error}>
          <ContainerDatePicker>
            <div>
              <DatePicker
                {...field}
                onFocus={(e) => isMobile && (e.target.readOnly = true)}
                ref={pickerRef}
                name={name}
                maxDate={
                  maxDate
                    ? new Date(new Date(maxDate).getTime() + MS_IN_DAY)
                    : null
                }
                value={dateTime}
                selected={dateTime}
                locale={ptBR}
                className="DatePicker-container"
                dateFormat={format || "dd/MM/yyyy"}
                showPopperArrow={false}
                placeholderText={placeholder}
                onChange={handleDateChange}
                minDate={
                  minDate
                    ? new Date(new Date(minDate).getTime() + MS_IN_DAY)
                    : null
                }
              />
              {showIcon && <CalendarIcon size={25} />}
            </div>
          </ContainerDatePicker>
        </DateInputFieldContainer>
      )}
      {error && <span className="error">{error}</span>}
    </DateInputContainer>
  );
}
