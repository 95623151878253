import { useEffect, useState, useRef } from "react";
import { FormBearing, FormHarmonicTitle } from "./styles";
import { ModalBearingProps, modalBearingTypeEmpty } from "./types";
import { Form, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import useApi from "../../../hooks/useApi";
import { Modal } from "../../../components/Modal";
import FormSelect from "../../../components/FormSelect";
import { Loading } from "../../../components/Loading";
import { FormikTextInput } from "../../../components/textInput";

export const BearingForm = ({
  show,
  handleClose,
  handleConfirm,
  position,
}: ModalBearingProps) => {
  const [bearingCodeOptions, setBearingCodeOptions] = useState<any>([]);

  const { request, processing } = useApi({ path: "/bearing-codes" });

  const fetchBearingCode = () => {
    request({ method: "get" }).then((response: any) => {
      setBearingCodeOptions(response);
    });
  };

  const formRef = useRef<FormikProps<any>>(null);
  const { t } = useTranslation();
  const { details } = position;

  const changeBearingCodesOptions = () => {
    const currentBearingCodes = bearingCodeOptions?.filter((bearing: any) =>
      details?.bearingCodes.includes(bearing.value),
    );

    const filterdOriginalList = bearingCodeOptions?.filter(
      (bearing: any) => !details?.bearingCodes.includes(bearing.value),
    );
    setBearingCodeOptions([...currentBearingCodes, ...filterdOriginalList]);
  };

  const loadDefaultValues = () => {
    const defaultValues = {
      ...modalBearingTypeEmpty,
      bearingType: details?.bearingType,
      rpm: details?.pointRotation ? +details.pointRotation : null,
    };
    let bearingCode = null;

    if (details?.bearingCodes && details?.bearingCodes.length) {
      bearingCode = details?.bearingCodes[0] || null;

      changeBearingCodesOptions();
    } else if (details?.bearingCode) {
      bearingCode = details?.bearingCode;
    }
    defaultValues.bearingCode = bearingCode;

    formRef.current?.setValues(defaultValues);
  };

  useEffect(() => {
    fetchBearingCode();
    // eslint-disable-next-line
  }, [show]);

  useEffect(() => {
    if (!processing) {
      loadDefaultValues();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processing]);

  const handleSubmit = (values: any) => {
    const currentCode = bearingCodeOptions.find(
      (item: any) => item.value === values.bearingCode,
    );

    const { BPFI, BPFO, BSF, GAIOLA } = currentCode;
    const fundamental = values.rpm;

    const array = [
      { label: "BPFI", value: BPFI * fundamental },
      { label: "BPFO", value: BPFO * fundamental },
      { label: "BSF", value: BSF * fundamental },
      { label: "GAIOLA", value: GAIOLA * fundamental },
    ];

    handleConfirm(array, values.harmonics);
    formRef.current?.setFieldValue("rpm", 0);
  };

  return (
    <Modal
      handleClose={handleClose}
      show={show}
      handleConfirm={() => formRef.current?.handleSubmit()}
      confirmLabel={`${t("BearingForm.save")}`}
    >
      <>
        <FormHarmonicTitle>{t("BearingForm.bearingTag")}</FormHarmonicTitle>
        <Formik
          innerRef={formRef}
          initialValues={modalBearingTypeEmpty}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <FormBearing>
                {processing ? (
                  <Loading />
                ) : (
                  <div>
                    <FormSelect
                      handleChange={(item: any) =>
                        setFieldValue("bearingCode", item.value)
                      }
                      name="bearingCode"
                      label={`${t("BearingForm.bearingCode")}`}
                      options={bearingCodeOptions}
                      placeholder={`${t("BearingForm.select")}`}
                      notClearable
                      searchable
                    />
                    <FormikTextInput
                      name="rpm"
                      type="number"
                      label="RPM"
                      placeholder={`${t("BearingForm.typeValue")}`}
                    />
                    <FormikTextInput
                      name="harmonics"
                      type="number"
                      label={`${t("BearingForm.harmonicQty")}`}
                      placeholder={`${t("BearingForm.typeQty")}`}
                    />
                  </div>
                )}
              </FormBearing>
            </Form>
          )}
        </Formik>
      </>
    </Modal>
  );
};
