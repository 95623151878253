import { useTranslation } from "react-i18next";

export default function useTranslator(key: string) {
  const { t } = useTranslation();
  function translate(value: string) {
    const translationKey = `${key}.${value}`;
    const result = t(translationKey);
    if (result === translationKey) {
      return value;
    }
    return result as string;
  }

  return translate;
}
