import { useEffect, useMemo, useState } from "react";
import { t } from "i18next";

import { IoInformationCircleSharp } from "react-icons/io5";

import { Modal } from "../Modal";

import { NamePlusTooltip } from "../NamePlusTooltip";
import useApi from "../../hooks/useApi";

import { Loading } from "../Loading";
import { SeriesSelector } from "../charts/seriesSelector";
import type { IChart } from "../../store/interfaces";
import { type chartDataModel } from "../charts/chart/types";
import LineChart from "../charts/LineChart";
import { defaultChartOptions } from "../../utils/charts/getOptions";
import { ChartType, IModalSensorStatusProps } from "./types";
import {
  ChartContainer,
  Container,
  Content,
  Header,
  ModalTitle,
  OptionChartButton,
  OptionsContainer,
} from "./styles";
import useGoogleChartTooltips from "../charts/chart/useGoogleChartTooltips";
import { ISerieControl } from "../../hooks/useSeriesControl";
import { ColumnChart } from "../charts/ColumnChart";
import { ButtonCancel } from "../Modal/styles";

export function ModalBoltMonitoring({
  show,
  handleClose,
  gateway,
}: IModalSensorStatusProps) {
  const [selectedChart, setSelectedChart] = useState<ChartType>(
    ChartType.CONNECTIVITY,
  );
  const [data, setData] = useState([]);

  const chartOptions = [
    {
      type: ChartType.CONNECTIVITY,
      label: t("dictionary.labels.Connectivity"),
      metricUnit: "%",
    },
    { type: ChartType.RSSI, label: "RSSI", metricUnit: "dBm" },
    { type: ChartType.BATTERY, label: t("cardBolt.Bateria"), metricUnit: "V" },
  ];

  const { request: requestGatewayMetrics, processing } = useApi({
    path: `/gateways/${gateway.gatewayId}/metrics`,
  });

  const handleChartChange = (chartType: ChartType) => {
    setSelectedChart(chartType);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chartTypeOptions = useMemo(
    () => ({
      [ChartType.CONNECTIVITY]: {
        series: [t("dictionary.labels.Connectivity")],
        title: t(`Connection Percentage during the hour (%)`),
        metricUnit: "%",
      },
      [ChartType.RSSI]: {
        series: ["RSSI"],
        title: `${t("cardBolt.Indicador de intensidade do sinal recebido")}`,
        metricUnit: "dBm",
      },
      [ChartType.BATTERY]: {
        series: [t(`Battery Voltage`)],
        title: t(`Battery Voltage`),
        metricUnit: "V",
      },
    }),
    [],
  );

  const chart: IChart = useMemo(
    () => ({
      name: "battery",
      type: "GLOBAL",
      positionId: 0,
      label: `${t("modalSensorStatus.battery")}`,
      series: ["voltage", "expected"],
      seriesName: ["Data", chartTypeOptions[selectedChart].title],
      metricUnits: { x: "date", y: chartTypeOptions[selectedChart].metricUnit },
    }),
    [chartTypeOptions, selectedChart],
  );

  const seriesControl: ISerieControl[] = useMemo(() => {
    return [
      {
        name: chartTypeOptions[selectedChart].series[0],
        label: chartTypeOptions[selectedChart].series[0],
        visible: true,
        color: "#01D5BF",
        alarm: false,
        hidden: false,
        index: 0,
      },
    ];
  }, [chartTypeOptions, selectedChart]);

  useEffect(() => {
    requestGatewayMetrics({
      method: "get",
      queryStringParameters: {
        startDate: new Date().getTime() - 1000 * 60 * 60 * 24 * 15,
        endDate: new Date().getTime(),
      },
    }).then((response) => {
      setData(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chartData = useMemo<null | chartDataModel>(() => {
    if (!data.length) {
      return null;
    }

    let minValue = +Infinity;
    let maxValue = -Infinity;

    const formattedData = data.map(
      ({
        date,
        healthCheckLastHourPercentage,
        connectionRssi,
        batteryVoltage,
      }) => {
        let value: any = [];

        switch (selectedChart) {
          case ChartType.CONNECTIVITY:
            value = healthCheckLastHourPercentage || 0;
            break;
          case ChartType.RSSI:
            value = connectionRssi || 0;
            break;
          case ChartType.BATTERY:
            value = batteryVoltage || 0;
            break;
          default:
            break;
        }

        if (value > maxValue) {
          maxValue = value;
        }

        if (value < minValue) {
          minValue = value;
        }

        return [new Date(date), value];
      },
    );

    const ticks = formattedData.map((item) => {
      return item[0];
    });

    return {
      data: [["Data", t("dictionary.labels.Value")], ...formattedData],
      series: [
        {
          name: chartTypeOptions[selectedChart].metricUnit,
          visible: true,
          color: "#01D5BF",
          alarm: false,
          hidden: false,
          index: 0,
        },
      ],
      minValue,
      maxValue,
      ticks,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedChart]);

  const extraTooltipInfos = useMemo(() => {
    if (selectedChart === ChartType.CONNECTIVITY) {
      return [
        {
          serieIndex: 0,
          labels: data.map(({ wifiSSID }) =>
            wifiSSID ? "Wifi SSID" : "Operadora",
          ),
          values: data.map(
            ({ wifiSSID, connectionOperator }) =>
              wifiSSID || connectionOperator,
          ),
        },
      ];
    }

    return [];
  }, [data, selectedChart]);

  const { tooltipsForData, mergeTooltipWithData } = useGoogleChartTooltips({
    chart,
    seriesControl,
    dataRaw: (chartData?.data || []).slice(1),
    extraTooltipInfos,
  });

  const displayData = useMemo(() => {
    return mergeTooltipWithData(chartData?.data || []);
  }, [chartData, tooltipsForData]);

  const chartTitle = useMemo(() => {
    return t(chartTypeOptions[selectedChart].title);
  }, [chartTypeOptions, selectedChart]);

  const modifiedChartOptions = useMemo(() => {
    const detailsFromSelectedChart = chartOptions.find(
      (item: any) => item.type === selectedChart,
    );

    const { metricUnit } = detailsFromSelectedChart || {};

    defaultChartOptions.vAxes["0"].title = metricUnit || "";

    if (selectedChart === ChartType.CONNECTIVITY) {
      defaultChartOptions.vAxes["0"].viewWindow = { min: 0, max: 120 };
    } else {
      defaultChartOptions.vAxes["0"].viewWindow = { min: -120, max: 120 };
    }

    defaultChartOptions.vAxes["0"].viewWindow = { min: -120, max: 120 };

    return defaultChartOptions;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultChartOptions, selectedChart]);

  const columChartOptions = useMemo(() => {
    defaultChartOptions.vAxes["0"].viewWindow = { min: 0, max: 120 };
    defaultChartOptions.hAxis.format = "dd/MM/yyyy HH:mm";
    defaultChartOptions.hAxis.ticks = chartData?.ticks as never[];
    defaultChartOptions.explorer = {
      actions: ["dragToZoom", "rightClickToReset"],
      axis: "horizontal",
      keepInBounds: true,
      maxZoomIn: 4.0,
    };

    return defaultChartOptions;
  }, [defaultChartOptions, selectedChart]);

  function renderChart() {
    if (processing) {
      return <Loading />;
    }

    if (!chartData) {
      return;
    }

    if (selectedChart === ChartType.CONNECTIVITY) {
      modifiedChartOptions.vAxes["0"].viewWindow = { min: 0, max: 100 };
    }
    if (selectedChart === ChartType.RSSI) {
      modifiedChartOptions.vAxes["0"].viewWindow = { min: -120, max: 120 };
    }

    if (selectedChart === ChartType.BATTERY) {
      modifiedChartOptions.vAxes["0"].viewWindow = { min: 0, max: 5 };
    }

    return (
      <>
        <h2>{chartTitle}</h2>
        {selectedChart === ChartType.CONNECTIVITY ? (
          <ColumnChart
            data={displayData}
            options={columChartOptions}
            chart={chart}
            seriesControl={seriesControl}
          />
        ) : (
          <LineChart options={modifiedChartOptions} data={displayData} />
        )}
        <footer>
          <SeriesSelector seriesControl={seriesControl} chart={chart} />
        </footer>
      </>
    );
  }

  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleConfirm={handleClose}
      size="xl"
      changeFotter
      confirmLabel={`${t("commonText.close")}`}
      clo
    >
      <Container>
        <Header>
          <ModalTitle>
            <h2>{t("Bolt Monitoring Charts")}</h2>

            <NamePlusTooltip
              tooltip={`${t("cardBolt.Dados coletados dos últimos 15 dias")}`}
              position="top"
            >
              <div style={{ cursor: "help" }}>
                <IoInformationCircleSharp size={24} />
              </div>
            </NamePlusTooltip>
          </ModalTitle>

          <OptionsContainer>
            {chartOptions.map((option) => (
              <OptionChartButton
                key={option.type}
                onClick={() => handleChartChange(option.type)}
                active={option.type === selectedChart}
              >
                {option.label}
              </OptionChartButton>
            ))}
          </OptionsContainer>
        </Header>
        <Content>
          <ChartContainer>{renderChart()}</ChartContainer>
        </Content>
        <div style={{ marginLeft: "auto" }}>
          <ButtonCancel onClick={handleClose}>
            {t("commonText.close")}
          </ButtonCancel>
        </div>
      </Container>
    </Modal>
  );
}
