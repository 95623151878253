import styled from "styled-components";
import { css } from "styled-components";

export const RoundButton = styled.button<{ disabled?: boolean }>`
  cursor: pointer;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.iconsSecondary};
  min-height: 25px;
  min-width: 25px;
  border: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 60px;
    cursor: pointer;
  }
  &:active {
    transform: scale(0.9);
  }

  & > * {
    pointer-events: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;

      cursor: not-allowed !important;
      &:hover {
        background-color: transparent !important;
        border-radius: 60px;
      }
      &:active {
        transform: none;
      }
    `}
`;
