import styled, { css } from "styled-components";

export const Container = styled.div<{ hasHint?: string }>`
  width: 4rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.75rem;
  ${(props) => ({ ...props.theme.fonts.primary.H4Regular })};

  span > svg {
    opacity: 0;
  }
  :hover {
    span > svg {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }

  ${({ hasHint }) =>
    hasHint &&
    css`
      span {
        position: relative;

        svg {
          position: absolute;
          font-size: 1.25rem;
          right: -1.5rem;
          top: 0.04rem;
        }
      }
    `}

  //mobile:
  @media screen and (max-width: 767px) {
    gap: 0.25rem;
    width: 2rem;
    align-items: flex-start;
  }

  // telas com 125% de zoom:
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    gap: 0.25rem;
  }
`;

export const IconButton = styled.button<{
  active?: boolean;
  blocked?: boolean;
}>`
  border: none;
  svg {
    color: ${({ active, theme }) =>
      active ? theme.colors.ibbxOrange : theme.colors.iconsSecondary};
    opacity: ${({ active }) => (active ? "1" : "0.3")};
    width: 1.5rem;
    height: auto;
  }
  svg {
    ${({ blocked, active }) =>
      active &&
      blocked &&
      css`
        color: ${(props) => props.theme.colors.iconsSecondary};
        opacity: 0.3;
      `}
  }
  //mobile:
  @media screen and (max-width: 767px) {
    svg {
      width: 1.5rem;
      height: auto;
    }
  }

  // telas com 125% de zoom:
  @media screen and (min-width: 767px) and (max-width: 1536px) {
  }
`;
