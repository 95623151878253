import { ColumnHeader, ColumnField, DeleteUserFacilityButton } from "./styles";
import ReactTable from "../../../../../../components/ReactTable";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import ModalCompound from "../../../../../../components/ModalCompound";
import { useField, useFormikContext } from "formik";
import useRetinaTranslation from "../../../../../../hooks/useRetinaTranslation";
import { MdDelete } from "react-icons/md";
import { useCallback, useMemo } from "react";

interface IUserFacilitiesComponent {
  show: boolean;
  handleClose: () => void;
}

export default function UserHiearchyComponent({
  show,
  handleClose,
}: IUserFacilitiesComponent) {
  const { setFieldValue } = useFormikContext();
  const [{ value: profile }] = useField("profile");
  const [{ value: userCompanies }] = useField("userCompanies");
  const [{ value: userFacilities }] = useField("userFacilities");

  const { t } = useTranslation();
  const { translateLabels } = useRetinaTranslation();

  function findPermission(args: any) {
    if (args.isReadOnly) {
      return translateLabels("Viewer");
    }

    return translateLabels("Editor");
  }

  const handleDelete = useCallback(
    (args: any) => {
      if (profile === "CLIENTE_MASTER") {
        const items = userCompanies.filter(
          (item: any) => item.companyId !== args.companyId,
        );

        setFieldValue("userCompanies", items);
        return;
      }

      const items = userFacilities.filter(
        (item: any) => item.facilityId !== args.facilityId,
      );

      setFieldValue("userFacilities", items);
    },
    // eslint-disable-next-line
    [profile, userCompanies, userFacilities],
  );

  const list: object[] = useMemo(() => {
    if (profile === "CLIENTE_MASTER") {
      return userCompanies || [];
    }
    return userFacilities || [];
  }, [userCompanies, userFacilities, profile]);

  return (
    <ModalCompound show={show} handleClose={handleClose}>
      <ModalCompound.Body>
        <h1>{translateLabels("Facilities")}</h1>
        <ReactTable
          responsive
          columns={
            [
              ...(["MESA_ANALISTA", "CLIENTE_MASTER"].includes(profile)
                ? [
                    {
                      id: "companyName",
                      accessor: "companyName",
                      Header: () => (
                        <ColumnHeader>
                          <span>{translateLabels("Company")}</span>
                        </ColumnHeader>
                      ),
                      Cell: ({ value }: any) => {
                        return (
                          <ColumnField>
                            <span>{value}</span>
                          </ColumnField>
                        );
                      },
                    },
                  ]
                : []),
              ...(["MESA_ANALISTA", "CLIENTE_COMUM"].includes(profile)
                ? [
                    {
                      id: "facilityName",
                      accessor: "facilityName",
                      Header: () => (
                        <ColumnHeader>
                          <span>{translateLabels("Name")}</span>
                        </ColumnHeader>
                      ),
                      Cell: ({ value }: any) => {
                        return (
                          <ColumnField>
                            <span>{value}</span>
                          </ColumnField>
                        );
                      },
                    },
                  ]
                : []),
              {
                id: "permission",
                accessor: "permission",
                Header: () => (
                  <ColumnHeader>
                    <span>{translateLabels("Permission")}</span>
                  </ColumnHeader>
                ),
                Cell: ({ value, row: { original } }: any) => {
                  return (
                    <ColumnField>
                      <span>{findPermission(original)}</span>
                    </ColumnField>
                  );
                },
              },
              {
                id: "actions",
                acessor: "actions",
                Header: () => <></>,
                Cell: ({ row: { original } }: any) => {
                  return (
                    <ColumnField>
                      <DeleteUserFacilityButton
                        onClick={() => handleDelete(original)}
                      >
                        <MdDelete />
                      </DeleteUserFacilityButton>
                    </ColumnField>
                  );
                },
              },
            ] as Column<object>[]
          }
          data={list}
          renderEmptyResult={
            <span>{t("Representatives.Companies.noCompanies")}</span>
          }
        />
      </ModalCompound.Body>
      <ModalCompound.Footer>
        <ModalCompound.Cancel handleClose={handleClose} />
      </ModalCompound.Footer>
    </ModalCompound>
  );
}
