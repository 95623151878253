import { useEffect, useState } from "react";
import { ConfirmDeleteBoltProps } from "./types";
import { useTranslation } from "react-i18next";
import { ConfirmRecall } from "../ConfirmRecall";

const ConfirmDeleteBolt = ({
  id,
  show,
  handleClose,
  handleConfirm,
  context,
  required,
  action,
  processing,
  isActivator,
  hideComments,
  positionSensorTypeId,
}: ConfirmDeleteBoltProps) => {
  const [comments, setComments] = useState("");
  const [error, setError] = useState("");
  const [selectError, setSelectError] = useState("");
  const [type, setType] = useState<string>("RECALL");
  const [selectedOption, setSelectedOption] = useState<string>("");

  const parameters: any[] = id ? [id, comments] : [comments, type];

  const { t } = useTranslation();

  const handleComments = (event: any) => {
    if (error) {
      setError("");
      setSelectError("");
    }
    setComments(event.target.value);
  };
  const handleModalConfirm = (
    comments?: string,
    type?: string,
    selectedOption?: string,
  ) => {
    if (isActivator) {
      parameters.push(selectedOption);
    }

    if (!processing) {
      if (required && !comments && type === "") {
        setError(t("confirmModalDelete.requiredComment") || "");
      }
      if (
        required &&
        type === "RECALL" &&
        selectedOption === "Other" &&
        !comments
      ) {
        setSelectError("Especifique o recall na área de observações" || "");
      } else if (required && type === "RECALL" && selectedOption === "") {
        setSelectError("Selecione uma opção de recall" || "");
      } else if (!processing) {
        if (required && type !== "RECALL" && !comments) {
          setError(t("confirmModalDelete.requiredComment") || "");
        } else {
          handleConfirm(...parameters);
        }
      }
    }
  };

  const pageLevel = () => {
    if (context?.toLowerCase().includes("empresa")) {
      return `${t("confirmModalDelete.deleteCompany")}`;
    } else if (context?.includes("unidade")) {
      return `${t("confirmModalDelete.deleteFacility")}`;
    } else if (context?.includes("equipamento")) {
      return `${t("confirmModalDelete.deleteAsset")}`;
    }
  };

  useEffect(() => {
    pageLevel();
    // eslint-disable-next-line
  }, []);

  const recallLabel = [
    {
      label: t("ConfirmDeleteForm.recallSelectEmpty"),
      value: "",
    },
    {
      label: t("confirmDeleteBolt.Sinal 4G"),
      value: "4gSignal",
    },
    {
      label: t("confirmDeleteBolt.Sinal Wi-fi"),
      value: "wifiSignal",
    },
    {
      label: t("confirmModalDelete.Antena"),
      value: "Antenna",
    },
    {
      label: "Case",
      value: "Case",
    },
    {
      label: t("confirmModalDelete.Infiltração"),
      value: "Infiltração",
    },
    {
      label: t("confirmDeleteBolt.Pigtail solto"),
      value: "LoosePigtail",
    },
    {
      label: t("confirmDeleteBolt.Cabo de alimentação"),
      value: "powerCable",
    },
    {
      label: t("confirmModalDelete.Outro"),
      value: "Other",
    },
  ];

  const inputRadioChange = () => {
    setType("");
    setSelectedOption("");
    setSelectError("");
  };

  const inputRadioRecallChange = () => {
    setType("RECALL");
    setError("");
  };

  return (
    <ConfirmRecall
      show={show}
      handleClose={handleClose}
      handleConfirm={() => handleModalConfirm(comments, type, selectedOption)}
      confirmLabel={action || t("commonText.confirm") || ""}
    >
      <ConfirmRecall.ReasonContent
        title={t("confirmDeleteBolt.Justifique a Exclusão deste Bolt")}
      >
        <ConfirmRecall.InputRadio
          label="Recall"
          checked={type === "RECALL"}
          onChange={inputRadioRecallChange}
          handleClick={inputRadioRecallChange}
        />

        <ConfirmRecall.FormSelect
          optionSelect={recallLabel}
          value={selectedOption}
          handleChange={(e) => {
            setSelectedOption(e.target.value);
            setType("RECALL");
          }}
          selectError={selectError}
        />
        <ConfirmRecall.InputRadio
          label={t("commonText.others")}
          checked={type === ""}
          onChange={inputRadioChange}
          handleClick={inputRadioChange}
        />
      </ConfirmRecall.ReasonContent>

      {!hideComments && (
        <ConfirmRecall.CommentArea
          error={error}
          spellCheck={false}
          rows={5}
          cols={40}
          placeholder={`${t("ocurrenceRegisterModal.observation")}`}
          onChange={handleComments}
          value={comments}
        />
      )}
    </ConfirmRecall>
  );
};

export default ConfirmDeleteBolt;
