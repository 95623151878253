import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled(Link)<{ selected?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: ${(props) => (props.selected ? "#314334" : "transparent")};

  @media screen and (max-width: 1280px) {
      width: 2rem;
      height: 2rem;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
  }
`;
