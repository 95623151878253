import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { Button } from "./styles";
import { error, success } from "../Toast";
import {
  appSelect,
  appSetCurrentCompany,
  setColorTheme,
} from "../../store/slices/appSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { PROFILES, userSelect } from "../../store/slices/userSlice";
import { useTranslation } from "react-i18next";

interface Type {
  color?: string;
  handleUnselectButton: () => void;
}

export const ResetColorButton = ({ color, handleUnselectButton }: Type) => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelect);
  const app = useAppSelector(appSelect);

  const { t } = useTranslation();

  const { companyId } = params;

  const { request, processing } = useApi({
    path: `/companies/${companyId}/theme`,
  });
  const { request: requestThemeRepresentative } = useApi({
    path: "/representative-theme",
  });

  function reloadNavbar() {
    if ([PROFILES.REPRESENTANTE].includes(user.profile)) {
      requestThemeRepresentative({ method: "get" }).then(
        ({ logoUrl, theme }) => {
          dispatch(setColorTheme(theme));
        },
      );
    } else {
      request({ method: "get" }).then(({ theme }) => {
        dispatch(setColorTheme({ navbarColor: "" }));
        dispatch(appSetCurrentCompany({ ...app.currentCompany, theme }));
      });
    }
  }

  const fetchData = () => {
    if (!processing) {
      request({ method: "put", body: { navbarColor: "" } })
        .then((response) => {
          success(`${t("ResetColorButton.edit")}`);
          handleUnselectButton();
          reloadNavbar();
        })
        .catch((response) => error(`${t("ResetColorButton.error")}`));
    }
  };

  return (
    <div>
      <Button onClick={fetchData}>{t("ResetColorButton.default")}</Button>
    </div>
  );
};
