import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  & > div {
    border-radius: 0.5rem;
    position: relative;
    & > div:first-child {
      background: ${(props) => props.theme.backgroundColors.background1};
      padding: 0.75rem;
      border-radius: 6px;
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    }

    & > label {
      width: 100%;
      text-align: center;
      ${(props) => props.theme.fonts.primary.H4Regular};
    }
  }
`;

export const Title = styled.label`
  ${(props) => props.theme.fonts.primary.H2Bold};
  margin-bottom: 1rem;
`;

export const Question = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme.fonts.primary.H4Regular};
`;

export const Comments = styled.div`
  label {
    ${(props) => props.theme.fonts.primary.H4Regular};
  }
  textarea {
    width: 100%;
    margin-top: 0.5rem;
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors3.color0};
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    padding: 12px;
    border-radius: 4px;
    resize: none;
  }
`;

export const ErrorLabel = styled.label`
  font-size: 1rem;
  color: red;
  position: absolute;
  bottom: -1.5rem;
`;
