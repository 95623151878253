import { useEffect } from "react";
import { AppLoading } from "../../components/Loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userSelect } from "../../store/slices/userSlice";
import { useAppSelector } from "../../store/hooks";

export const ClearCache = () => {
  const user = useAppSelector(userSelect);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // if (!user.retinaVersion) return;

    let from = searchParams.get("from") || "/";
    const hasReloaded = searchParams.get("reloaded") || "";

    if (from === "/clear-cache") {
      from = "/"
    }
    
    if (!!from && !!hasReloaded) {
      navigate(from);

      return;
    }

    try {
      global.navigator.serviceWorker
        .getRegistrations()
        .then((registrations: any) => {
          if (process.env.REACT_APP_ENV === "development") {
            console.log("registrations", registrations);
          }

          for (let registration of registrations) {
            registration.unregister();
          }
        });

      let newSearchParams = new URLSearchParams({
        from,
        reloaded: "true",
      } as any);
      setSearchParams(newSearchParams);

      global.document.location.reload();
    } catch (error) {}

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.retinaVersion]);

  return <AppLoading />;
};
