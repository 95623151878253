export type ConfigurationFormProps = {
  show: boolean;
  handleClose: () => void;
};

export type ConfigurationModel = {
  batteryVoltageAlarm: string;
  signalQualityAlarm: string;
};

export const ConfigurationEmpty = {
  batteryVoltageAlarm: "",
  signalQualityAlarm: "",
};
