import styled, { css } from "styled-components";

type HeaderProps = {
  hasMargin?: boolean;
  fixed?: boolean;
  esgMargin?: boolean;
  bgColor?: boolean;
  customColor?: string | null;
  iconsColor?: string;
};

export const NavBarContainer = styled.div<HeaderProps>`
  /* background-color: ${(props) => props.theme.colors.headerBackground}; */
  background-color: ${(props) =>
    props.customColor || props.theme.colors.headerBackground};
  height: 60px;
  margin-bottom: ${(props) =>
    props.hasMargin && !props.esgMargin ? "10px" : "0px"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;

  @media screen and (max-width: 767px) {
    padding: 0 0.5rem;
    gap: 1rem;
  }

  @media screen and (min-width: 767px) and (max-width: 1279px) {
    height: 38px;
  }
`;

// Breadcrumb
export const FrameHeaderCenter = styled.div<HeaderProps>`
  color: ${(props) => props.theme.colors.iconsSecondary};
  padding: 12px;
  width: calc(100% - 190px);

  & > div {
    div {
      color: ${(props) =>
        !props.bgColor && props.iconsColor
          ? props.iconsColor
          : props.theme.colors.iconsSecondary};
    }
    a {
      color: ${(props) =>
        props.bgColor
          ? "#f6f8ee"
          : props.iconsColor || props.theme.colors.iconsSecondary};
    }
  }
  @media screen and (max-width: 767px) {
    flex: 1;
    padding: 0;
    margin-left: 2.25rem;
  }
`;

export const FrameHeaderCenterShort = styled.div`
  display: none;
  text-align: center;
  width: 100%;
  @media (max-width: 767px) {
    display: block;
  }
`;

// Menu usuário
export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.iconsSecondaryAlpha};
  position: relative;
  width: 0.5px;
  display: inline-block;
  margin: 2px 0px;
`;

interface IFrameHeaderRight {
  isMobile?: boolean;
  navbarColor?: string;
  iconsColor?: string;
  isCurrentEsgPage?: boolean;
}

export const FrameHeaderRight = styled.div<IFrameHeaderRight>`
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors3.color0};
  text-align: center;
  width: 336px;
  margin-right: 16px;
  position: relative;
  gap: 8px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: filter scale 0.3s ease-in-out;
    border-radius: 50%;
    border: 1px solid transparent;

    ${({ isCurrentEsgPage }) =>
      isCurrentEsgPage &&
      css`
        & > a {
          svg {
            color: #d5eed0;
          }
          &:hover {
          }
        }

        & > div > button {
          svg {
            color: #d5eed0;
          }
          &:hover {
          }
        }

        & > div > div {
          svg {
            color: #d5eed0;
          }
          &:hover {
          }
        }
      `}

    &:hover {
      & > a > svg {
        transform: scale(1.1);
      }

      & > div > button > svg {
        transform: scale(1.1);
      }

      & > div > div > svg {
        transform: scale(1.1);
      }
    }
  }

  svg {
    color: ${(props) => props.iconsColor || props.theme.colors.iconsSecondary};
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    width: fit-content;
    gap: 4px;
    margin-right: 0;
  }

  @keyframes scale-in-hor-right {
    0% {
      transform: scaleX(0);
      transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      transform: scaleX(1);
      transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  & > a {
    &:hover {
      svg {
        z-index: 10 !important;
      }
    }
  }

  & > * {
    position: relative;
  }
`;

export const FullHeader = styled.div<{ fixed?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  position: ${({ fixed }) => fixed && "fixed"};
  z-index: ${({ fixed }) => fixed && 1000};
  background-color: transparent !important;
  padding: 0;
  margin: 0;
`;

export const FixedSpace = styled.div<{ height?: any }>`
  height: ${({ height }) => height + "px"};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const CompanyLogo = styled.div`
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 60px;

  @media screen and (min-width: 767px) and (max-width: 1279px) {
    height: 38px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
