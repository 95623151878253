import { useState, useRef, useEffect, useMemo } from "react";
import { BsGear, BsLightbulbFill } from "react-icons/bs";
import { useTheme } from "styled-components";
import useApi from "../../../hooks/useApi";
import { Loading } from "../../Loading";
import { ContainerTop } from "../GraphicHeader/styles";
import {
  LuminaStatusBulbContainer,
  Containers,
  StatusIcon,
  StatusLabel,
  ContainerLuminaStatusActions,
  ContainerLuminaStatusTitle,
} from "./styles";
import GraphicCompound from "../../GraphicCompound";
import { isMobile } from "react-device-detect";
import TimeRangeConfigModal from "./TimeRangeConfigModal";
import { useAppSelector } from "../../../store/hooks";
import { userSelect } from "../../../store/slices/userSlice";

interface ILuminaStatus {
  positionId: number;
  isNotAnalysis?: boolean;
}

const LuminaStatus = ({ positionId, isNotAnalysis }: ILuminaStatus) => {
  const { colors } = useTheme();
  const ref = useRef<any>(null);
  const [status, setStatus] = useState("OFF");
  const [timeModal, setTimeModal] = useState<any>(false);
  const user = useAppSelector(userSelect);

  const path = `/positions/${positionId}/charts/LUM-STT`;
  const { request, processing } = useApi({ path });

  const color = useMemo(() => {
    if (status === "FALHA") {
      return colors.statusRisk;
    } else if (status === "ON") {
      return colors.statusNormal;
    }

    return colors.iconsSecondary;
  }, [status, colors]);

  function getChartsData() {
    const queryStringParameters = {
      maxDate: new Date().getTime(),
    };
    request({ method: "get", queryStringParameters }).then((response) => {
      setStatus(response.status);
    });
  }

  useEffect(() => {
    getChartsData();

    const interval = setInterval(() => {
      getChartsData();
    }, 300000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const analysisLink = `/positions/${positionId}/analysis?chartName=LUM-STT`;

  function copyToClipboard(text: string) {
    const input = document.createElement("textarea");
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  }

  function getEmbededAnalysisLink() {
    const baseLink = analysisLink;
    const url = `${baseLink}&embeded=1`;
    copyToClipboard(url);
  }

  return (
    <>
      {timeModal && (
        <TimeRangeConfigModal
          show={timeModal}
          handleClose={() => setTimeModal(false)}
          positionId={positionId}
          refresh={getChartsData}
        />
      )}
      <Containers ref={ref} isNotAnalysis={isNotAnalysis}>
        {isNotAnalysis && (
          <ContainerTop>
            <ContainerLuminaStatusTitle>Status</ContainerLuminaStatusTitle>
            <ContainerLuminaStatusActions>
              {user.admin && (
                <button
                  className="graphic_button"
                  onClick={() => setTimeModal(true)}
                >
                  <BsGear size={18} />
                </button>
              )}
            </ContainerLuminaStatusActions>
          </ContainerTop>
        )}
        <LuminaStatusBulbContainer>
          {processing ? (
            <Loading />
          ) : (
            <>
              <StatusLabel color={color}>{status}</StatusLabel>
              <StatusIcon color={color}>
                <BsLightbulbFill />
              </StatusIcon>
            </>
          )}
          {!isMobile && !processing && isNotAnalysis && (
            <GraphicCompound.CopyToClipboard
              getLinkToCopy={getEmbededAnalysisLink}
            />
          )}
          {isNotAnalysis && !isMobile && (
            <GraphicCompound.AnalysisLink
              getAnalysisLink={() => analysisLink}
            />
          )}
        </LuminaStatusBulbContainer>
      </Containers>
    </>
  );
};

export default LuminaStatus;
