import { IoTimeOutline } from "react-icons/io5";
import { HourTextInputContainer } from "./styles";
import { LoadingInput } from "../LoadingInput";

interface IHourTextInput {
  value: string;
  label: string;
  handleChange: (value: string) => void;
  isLoading?: boolean;
}

export function HourTextInput({
  label,
  handleChange,
  value,
  isLoading,
}: IHourTextInput) {
  function formatHourInput(event: any): string {
    // Remove non-digit characters
    let cleanedInput = event.target.value.replace(/\D/g, "");

    if (cleanedInput.length === 5) return value;

    //Validação para não permitir horas erradas (maior que 23)
    if (+cleanedInput[0] > 2) {
      cleanedInput = "0" + cleanedInput;
    }
    if (+cleanedInput[0] === 2 && +cleanedInput[1] > 3) return value;

    //Validação para não permitir minutos errados (maior que 59)
    if (+cleanedInput[2] > 5) return value;

    // Add a colon after the first two digits if it exists
    if (cleanedInput.length > 2) {
      cleanedInput = cleanedInput.slice(0, 2) + ":" + cleanedInput.slice(2);
    }

    // const onWhen = config.onWhen || {};
    // setConfig({ ...config, onWhen: { ...onWhen, [input]: cleanedInput } });

    return cleanedInput;
  }

  return (
    <HourTextInputContainer>
      <label>{label}</label>
      {isLoading && <LoadingInput />}
      {!isLoading && (
        <div>
          <input
            onChange={(e) => handleChange(formatHourInput(e))}
            value={value}
            placeholder="--:--"
          />
          <IoTimeOutline size={22} />
        </div>
      )}
    </HourTextInputContainer>
  );
}
