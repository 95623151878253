import { useMemo } from "react";
import { Form } from "react-bootstrap";
import { StampsSelectorContainer } from "./styles";
import { useTranslation } from "react-i18next";
import { IAnalysis } from "../AnalysisTools/types";

export type EnumBearings = "GAIOLA" | "BPFI" | "BPFO" | "BSF";
export interface IBearingStamps {
  stamps: any;
  harmonics: any;
  visible: EnumBearings[];
}

interface IStampsSelector {
  analysis: IAnalysis;
  setAnalysis: (args: IAnalysis) => void;
}
const bearingsOptions: EnumBearings[] = ["GAIOLA", "BPFI", "BPFO", "BSF"];

function StampsSelector({ analysis, setAnalysis }: IStampsSelector) {
  const bearingStamps: IBearingStamps = useMemo(() => {
    return (
      analysis.bearingStamps || {
        harmonics: [],
        stamps: [],
        visible: [],
      }
    );
  }, [analysis]);

  function filterVisible(checked: EnumBearings): EnumBearings[] {
    const visible: EnumBearings[] = bearingStamps.visible || [];
    if (visible.includes(checked)) {
      return bearingStamps.visible.filter((val: any) => val !== checked);
    }
    return [...visible, checked];
  }

  function handleCheck(item: EnumBearings) {
    setAnalysis({
      bearingStamps: {
        ...bearingStamps,
        visible: filterVisible(item),
      },
    });
  }
  const { t } = useTranslation();
  return (
    <StampsSelectorContainer>
      <h1>{t("StampsSelector.bearingTag")}</h1>
      <Form.Group>
        {bearingsOptions.map((item: EnumBearings) => (
          <Form.Label>
            <Form.Check
              checked={(bearingStamps.visible || []).includes(item)}
              onChange={() => handleCheck(item)}
            />
            {item}
          </Form.Label>
        ))}
      </Form.Group>
    </StampsSelectorContainer>
  );
}

export default StampsSelector;
