import styled from "styled-components";

export const StampsSelectorContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  margin-top: -24px;
  display: flex;
  flex-direction: column;
  h1 {
    ${(props) => props.theme.fonts.primary.H3SemiBold};
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  div {
    display: flex;
    gap: 10px;
    label {
      display: flex;
      padding: 0;
      margin: 0;
      gap: 5px;
      align-items: center;
      ${(props) => props.theme.fonts.primary.H4Regular};
      color: ${(props) => props.theme.colors.iconsPrimary};

      input {
        height: 15px;
        margin: 0;
      }
    }
  }

  .form-check {
    min-height: 0;
    display: flex;
    align-items: center;
  }
  
  .form-check-input:checked {
    background-color: ${props => props.theme.colors.ibbxOrange};
    border-color: ${props => props.theme.colors.ibbxOrange};
  }

  .form-check-input {
    background-color: ${props => props.theme.colors.iconsSecondary};
    border-color: ${props => props.theme.colors.iconsSecondary};
    margin-top: 0;
  }
`;