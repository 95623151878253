import { Container } from "./styles";

interface IOccurrenceListContainer {
  children: JSX.Element | JSX.Element[];
}

export default function ListPageDesktop({
  children,
}: IOccurrenceListContainer) {
  return <Container id="scrollableDiv">{children}</Container>;
}
