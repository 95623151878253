import styled from "styled-components";

export const BoltFormContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  padding: 1rem;
  width: 100%;
  max-height: 70vh;
  overflow: auto;
  opacity: ${({ disabled }) => (disabled ? 0.2 : "")};

  /* Phone Portrait */
  @media screen and (max-width: 767px) {
    padding: 0;
    h1 {
      ${(props) => props.theme.fonts.primary.H2Bold};
    }
  }

  /* Tablet Portrait */
  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
    padding: 0;
    h1 {
      ${(props) => props.theme.fonts.primary.H2Bold};
    }
  }

  /* Tablet Landscape */
  @media screen and (min-width: 827px) and (max-width: 1279px) {
    padding: 1rem;
    gap: 1rem;
  }
`;

export const BoltFormBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px;

  @media screen and (max-width: 767px) {
    overflow: auto;
    overflow-x: hidden;
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 100%;

    & > div {
      width: 100%;
    }

    input,
    select,
    textarea {
      font-size: 1rem;
    }

    & > div:last-child {
      margin-bottom: 1rem;
    }
  }

  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
    overflow: auto;
    overflow-x: hidden;
    flex-direction: column;
    align-items: center;
    padding: 0;

    input,
    select,
    textarea {
      font-size: 1rem;
    }

    & > div:last-child {
      margin-bottom: 1rem;
    }
  }
`;

export const InputBoltID = styled.div<{ qrCode?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  align-items: flex-start;
  width: 100%;
  gap: 8px;

  & > div:nth-child(1) {
    flex: 1 0 1px;
  }
  & > button:nth-child(2) {
    margin-top: 22px;
    flex: 0 0 100px;
  }

  @media screen and (max-width: 767px) {
    & > button:nth-child(2) {
      flex: 0 0 40px;
      margin-top: 18px;
    }
  }
`;

export const QrCodeButton = styled.button`
  color: ${(props) => props.theme.colors.IconsTools};
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    ${(props) => props.theme.fonts.primary.H3SemiBold};
  }

  :hover {
    color: ${(props) => props.theme.colors.ibbxOrange};
  }

  @media screen and (max-width: 767px) {
    span {
      display: none;
    }

    svg {
      width: 2.4rem;
      height: auto;
    }
  }
`;

export const BoltImageContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.cardHighlight};
  color: ${(props) => props.theme.colors.iconsSecondary};
  width: 240px;
  min-height: 200px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-height: 260px;

  & > div {
    height: 100%;
    img {
      height: 100% !important;
    }

    &:hover {
      opacity: 0.75;
      cursor: pointer;
    }
    svg {
      max-width: 240px;
      max-height: 200px;
    }

    & > div {
      width: inherit;
      height: inherit;
    }
  }

  svg:last-child {
    margin: auto;
    font-size: 100px;
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media screen and (max-width: 767px) {
    & > div {
      width: 100%;
      height: auto;
    }
  }
`;

export const BoltFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1 1 100px;
`;
