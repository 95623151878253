function cnpjMask(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d)/, "$1.$2");
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
  value = value.replace(/(\d{4})(\d)/, "$1-$2");
  if (value.length > 18) {
    value = value.substring(0, 18);
  }
  return value;
}

function brazillianPhoneMask(value: string) {
  if (value.length <= 15) {
    value = value.replace(/\D/g, ""); // Remove every character non-numeric
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2"); // Parenthesis around the 2 first numbers like (19)
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
  } else {
    value = value.substring(0, 15);
  }

  return value;
}

function brazillianZipCodeMask(value: string) {
  value = value.replace(/\D/g, ""); 
  value = value.replace(/^(\d{5})(\d)/g, "$1-$2"); 
  if (value.length > 9) {
    value = value.substring(0, 9);
  }

  return value;
}

function boltCodeMask(value: string) {
  return value.replaceAll("-", "");
}

function powerFactorMaximum(value: any) {
  if (+value > 1) {
    return "";
  }
}

export function applyMaskByName(name: string, value: any) {
  if (name.includes("phone")) {
    return brazillianPhoneMask(value);
  }

  switch (name) {
    case "document":
      return cnpjMask(value);
    case "zipCode":
      return brazillianZipCodeMask(value);
    case "boltCode":
      return boltCodeMask(value);
    case "details[powerFactor]":
      return powerFactorMaximum(value);
    default:
      return value;
  }
}
