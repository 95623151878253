import styled from "styled-components";
import { HideMenu } from "../../pages/Companies/CardCompany/styles";

export const InfoButtonCustomWrapper = styled.div`
  position: relative;
`;



export const InfoButtonContainer = styled.button`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.colors.iconsSecondary};

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 60px;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    width: 20px;
    height: 20px;

    svg {
      width: 22px;
      height: auto;
    }
  }

  // Disable inside clickable elements
  & > * {
    pointer-events: none;
  }
`;

export const TriangleInfoButton = styled.div<{ left?: number }>`
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  left: ${(props) => props.left && props.left - -11 + "px"};
  top: 12px;
  left: 8px;
  border-left: 6px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  position: absolute;
  transform: rotate(270deg);

  @media screen and (max-width: 767px) {
    top: 16px;
  }
`;

export const InfoContainer = styled.div<{ left?: number }>`
  width: 300px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: ${(props) => props.theme.colors.iconsSecondary};
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.cardBackground};
  border-radius: 6px;
  padding: 4px;
  left: ${(props) => props.left && props.left - 135 + "px"};
  top: 24px;
  flex-direction: column;
  gap: 8px;
  z-index: 2;
  span {
    ${(props) => props.theme.fonts.primary.H5Regular};
    border: 1px solid transparent;
  }

  p {
    margin: 0px;
    padding: 0px;
  }

  @media screen and (max-width: 767px) {
    top: 25px;
    /* left: ${({ left }) => left && `${left - 400}px`}; */
    left: -105px;
  }
`;
