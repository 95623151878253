import { ReactNode } from "react";
import { Error, RecallSelect } from "./styles";
import { Form } from "react-bootstrap";

interface IFormSelect {
  label?: ReactNode;
  checked?: boolean;
  handleChange?: (e: any) => void;
  value?: string | number | readonly string[] | undefined;
  optionSelect: { value: string; label: ReactNode }[];
  disabled?: boolean;
  selectError?: string;
}

export function FormSelect({
  handleChange,
  value,
  optionSelect,
  disabled,
  selectError,
}: IFormSelect) {
  return (
    <RecallSelect>
      <Form.Select
        value={value}
        onChange={handleChange}
        disabled={disabled}
        size="lg"
      >
        {optionSelect &&
          optionSelect.map((item: any) => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          ))}
      </Form.Select>
      {selectError && (
        <Error>
          <span>{selectError}</span>
        </Error>
      )}
    </RecallSelect>
  );
}
