type options = {
  integer?: boolean;
  positive?: boolean;
  activator?: boolean;
  value?: any;
};

export const blockInvalidCharacter = (
  e: {
    key: string;
    preventDefault: () => any;
  },
  options?: options,
) => {
  let patterns: string[] = [];

  if (options?.activator) {
    patterns.push("[+-]");
  } else {
    patterns.push("[+= ]");
  }

  if (options?.positive) {
    patterns.push("[^0-9A-Za-z]");
  }

  if (options?.integer) {
    patterns.push("[,.]");
  }

  const regex = new RegExp(patterns.join("|"));

  if (options?.value?.length === 0 && [",", "."].includes(e.key)) {
    e.preventDefault();
  }

  if (regex.test(e.key)) {
    e.preventDefault();
  }
};