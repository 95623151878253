import { useMemo } from "react";
import { useTheme } from "styled-components";
import { Select } from "../../../../components/Select";
import queryString from "../../../../utils/queryString";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SelectPeriod() {
  const location = useLocation();
  const { colors } = useTheme();
  const { t } = useTranslation();

  const queryParams = queryString.parse(location.search);

  const periodOptions = [
    "ENV-P",
    "AC-P",
    "AC-GLOBAL",
    "V-GLOBAL",
    "ENV-GLOBAL",
  ].includes(queryParams?.chartName)
    ? [
        { value: "WEEK", label: t("commonText.week") },
        { value: "MONTH", label: t("commonText.month") },
        { value: "YEAR", label: t("commonText.year") },
        { value: "CUSTOM", label: t("commonText.custom") },
      ]
    : [
        { value: "DAY", label: t("commonText.day") },
        { value: "WEEK", label: t("commonText.week") },
        { value: "MONTH", label: t("commonText.month") },
        { value: "YEAR", label: t("commonText.year") },
        { value: "CUSTOM", label: t("commonText.custom") },
      ];

  const value = useMemo(() => {
    return (
      periodOptions.find(({ value }) => value === queryParams.period) || null
    );
    // eslint-disable-next-line
  }, [queryParams.period]);

  const customStyles = {
    input: (provided: any, state: any) => ({
      ...provided,
      width: "200px",
    }),

    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: colors.cardBackground,
      borderColor: colors.cardHighlight,
      color: colors.iconsSecondary,
      boxShadow: "none",
      borderRadius: "6px",
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: colors.iconsSecondary,
    }),

    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: "none",
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      background: colors.cardBackground,
      fontWeight: "600",
      fontSize: "12px",
      color: colors.iconsSecondary,
    }),
  };

  const [, setSearchParams] = useSearchParams();

  function handleChange(e: any) {
    if (e?.value) {
      setSearchParams({ ...queryParams, period: e.value });
    }
  }

  return (
    <Select
      value={value}
      options={periodOptions}
      styles={customStyles}
      handleChange={handleChange}
      placeholder={t("commonText.period") as string}
    />
  );
}

export default SelectPeriod;
