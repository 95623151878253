import { getUrlPattern } from "../../utils/getUrlPattern";
import { useLocation, useParams } from "react-router-dom";
import { ButtonReturn } from "./styles";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { isIOS } from "react-device-detect";

interface ButtonProps {
  isOccurrencePanel?: boolean;
}

export function ReturnButton({ isOccurrencePanel }: ButtonProps) {
  const params = useParams();
  const location = useLocation();
  const urlPattern = getUrlPattern(location, params);
  const { facilityId: facilityIdPathParams } = useParams();

  const app = useAppSelector(appSelect);

  const companyId = app.currentCompany.id;
  const facilityId = app.currentFacility.id;
  const assetId = app.currentAsset.id;

  const navigate = useNavigate();

  // eslint-disable-next-line
  const returnPreviousUrl = () => {
    switch (urlPattern) {
      case "/companies/:companyId/facilities":
        return "/companies";
      case "/companies/:companyId/facilities/:facilityId/assets":
        return `/companies/${companyId}/facilities`;
      case "/companies/:companyId/facilities/:facilityId/assets/:assetId":
        return `/companies/${companyId}/facilities/${facilityId}/assets`;
      case "/positions/:positionId":
        return `/companies/${companyId}/facilities/${facilityId}/assets/${assetId}`;
      default:
        return "/";
    }
  };

  const handleReturn = () => {
    if (urlPattern.includes("connectivity") && !facilityIdPathParams) {
      return navigate(`/companies/${companyId}/facilities`);
    }

    return navigate(-1);
  };

  return (
    <ButtonReturn
      onClick={handleReturn}
      specialStyle={isOccurrencePanel}
      isIosDevices={isIOS}
    >
      <FaArrowLeft />
      {/* <span>{t("ReturnButton.return")}</span> */}
    </ButtonReturn>
  );
}

export default ReturnButton;
