import { Form } from "formik";
import styled from "styled-components";

export const Container = styled(Form)``;

export const Header = styled.header`
  ${(props) => props.theme.fonts.primary.H3SemiBold};
  margin-bottom: 16px;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > input {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input::placeholder {
      /* color: ${({ theme }) => theme.colors.cardHighlight}; */
    }

    input[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }

    input {
      height: 36px;
      &:focus {
        border: 1px solid ${(props) => props.theme.colors.white} !important;
      }
    }

    input,
    textarea {
      ${(props) => props.theme.fonts.primary.H4Regular};
      width: 100%;
      min-height: 34px;
      color: ${(props) => props.theme.colors.iconsPrimary};
      background: ${(props) => props.theme.colors.background};
      border-radius: 4px;
      padding: 8px;
      border: 1px solid ${(props) => props.theme.colors.cardHighlight};

      &:hover {
        border: 1px solid ${(props) => props.theme.colors.IconsTools};
      }

      &:focus-visible {
        outline: none;
        border: 1px solid ${(props) => props.theme.colors.IconsTools};
      }
    }
  }
`;

export const InputContainer = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 4px;

  label {
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${(props) => props.color || props.theme.colors.iconsPrimary};
  }

  span {
    ${(props) => props.theme.fonts.secondary.H3Regular}
    color: red !important;
  }

  label {
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${({ theme }) => theme.colors.iconsPrimary};
    /* margin-bottom: 4px; */
  }

  span {
    ${(props) => props.theme.fonts.secondary.H3Regular};
    color: red;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input::placeholder {
    /* color: ${({ theme }) => theme.colors.cardHighlight}; */
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input {
    height: 36px;
    &:focus {
      border: 1px solid ${(props) => props.theme.colors.white} !important;
    }
  }

  textarea {
    height: 100px;
    resize: none;

    &::-webkit-scrollbar {
      width: 6px !important;
      height: 6px;
      @media screen and (max-width: 767px) {
        width: 3px !important;
      }
    }

    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.colors.background};
      width: 20px;
      border-radius: 100vw;
      margin-block: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }

    &::-webkit-scrollbar-thumb:active {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }
  }

  input,
  textarea {
    ${(props) => props.theme.fonts.primary.H4Regular};
    width: 100%;
    min-height: 34px;
    color: ${(props) => props.theme.colors.iconsPrimary};
    background: ${(props) => props.theme.colors.background};
    border-radius: 4px;
    padding: 8px;
    border: 1px solid
      ${(props) =>
        props.error ? "red !important" : props.theme.colors.cardHighlight};

    &:hover {
      border: 1px solid ${(props) => props.theme.colors.IconsTools};
    }

    &:focus-visible {
      outline: none;
      border: 1px solid ${(props) => props.theme.colors.IconsTools};
    }
  }
`;
