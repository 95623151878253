import styled from "styled-components";

export const Container = styled.div`
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    height: 16vh;
  } ;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > span {
    margin-top: 50px;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20vh;
    & > span {
      margin-top: 0.3rem;
    }
  } ;
`;
export const DownloadPDF = styled.div`
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    background-color: ${(props) => props.theme.colors.iconsSecondary};
    border-radius: 0.5rem;
    width: 60%;
    height: 3rem;

    span {
      text-decoration: none;
      color: ${(props) => props.theme.colors.cardHighlight};
      ${(props) => props.theme.fonts.primary.H3SemiBold}

      &:hover {
        color: ${(props) => props.theme.colors.cardBackground};
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.cardBackground};

      span {
        color: ${(props) => props.theme.colors.iconsSecondary};
      }
    }
  }
`;
