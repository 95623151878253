import { useEffect, useState } from "react";
import { ConfirmDeleteFormProps } from "./types";
import { useTranslation } from "react-i18next";
import { ConfirmRecall } from "../ConfirmRecall";

const ConfirmDeleteModal = ({
  id,
  show,
  handleClose,
  handleConfirm,
  context,
  required,
  action,
  processing,
  isActivator,
  hideComments,
  positionSensorTypeId,
  isPosition,
  clearValues,
}: ConfirmDeleteFormProps) => {
  const [comments, setComments] = useState("");
  const [error, setError] = useState("");
  const [selectError, setSelectError] = useState("");
  const [type, setType] = useState<string>("RECALL");
  const [selectedOption, setSelectedOption] = useState<string>("");

  const parameters: any[] = id ? [id, comments] : [comments, type];

  const { t } = useTranslation();

  const handleComments = (event: any) => {
    if (error) {
      setError("");
      setSelectError("");
    }
    setComments(event.target.value);
  };

  const handleModalConfirm = (
    comments?: string,
    type?: string,
    selectedOption?: string,
  ) => {
    if (isActivator) {
      parameters.push(selectedOption);
    }

    if (!processing && !isPosition) {
      if (required && !comments) {
        setError(t("confirmModalDelete.requiredComment") || "");
      } else {
        handleConfirm(...parameters);
        handleClose();
        clearValues();
      }
    } else if (!processing && isPosition) {
      if (
        required &&
        type === "RECALL" &&
        selectedOption === "Other" &&
        !comments
      ) {
        setSelectError("Especifique o recall na área de observações" || "");
      } else if (required && type === "RECALL" && selectedOption === "") {
        setSelectError("Selecione uma opção de recall" || "");
      } else if (!processing && isPosition) {
        if (required && type !== "RECALL" && !comments) {
          setError(t("confirmModalDelete.requiredComment") || "");
        } else {
          handleConfirm(...parameters);
          handleClose();
          clearValues();
        }
      }
    }
  };

  const pageLevel = () => {
    if (context?.toLowerCase().includes("empresa")) {
      return `${t("confirmModalDelete.deleteCompany")}`;
    } else if (context?.includes("unidade")) {
      return `${t("confirmModalDelete.deleteFacility")}`;
    } else if (context?.includes("equipamento")) {
      return `${t("confirmModalDelete.deleteAsset")}`;
    }
  };

  const showRedAlert = () => {
    if (
      context?.toLowerCase().includes("excluir") &&
      (context?.toLowerCase().includes("empresa") ||
        context?.toLowerCase().includes("unidade") ||
        context?.toLowerCase().includes("equipamento"))
    ) {
      return true;
    }
  };
  useEffect(() => {
    pageLevel();
    // eslint-disable-next-line
  }, []);

  const renderText = (context?: string) => {
    if (context) {
      return `
        ${t("confirmModalDelete.customText1")} ${context}, ${t(
        "confirmModalDelete.customText2",
      )}
      `;
    }

    if (hideComments) {
      return t("confirmModalDelete.defaultTextConfirm");
    }

    return t("confirmModalDelete.defaultText");
  };

  const spactraSensor = positionSensorTypeId && positionSensorTypeId === 1;

  const recallLabel = [
    {
      label: t("ConfirmDeleteForm.recallSelectEmpty"),
      value: "",
    },
    {
      label: t("confirmModalDelete.Sincronização"),
      value: "Synchronization",
    },
    {
      label: t("confirmModalDelete.Bateria"),
      value: "Battery",
    },
    {
      label: "Case",
      value: "Case",
    },
    {
      label: t("confirmModalDelete.Antena"),
      value: "Antenna",
    },
    {
      label: t("confirmModalDelete.Temperatura"),
      value: "Temperature",
    },
    {
      label: spactraSensor
        ? t("confirmModalDelete.Resina")
        : t("confirmModalDelete.Infiltração"),
      value: spactraSensor ? "Resin" : "Infiltração",
    },
    {
      label: t("confirmModalDelete.Transmissão"),
      value: "Transmission",
    },
    {
      label: t("confirmModalDelete.Outro"),
      value: "Other",
    },
  ];

  const inputRadioChange = () => {
    setType("");
    setSelectedOption("");
    setSelectError("");
  };

  return (
    <ConfirmRecall
      show={show}
      handleClose={handleClose}
      handleConfirm={() => handleModalConfirm(comments, type, selectedOption)}
      confirmLabel={action || t("commonText.confirm") || ""}
    >
      {!isPosition ? (
        <strong>{renderText(context)}</strong>
      ) : (
        <ConfirmRecall.ReasonContent title={t("positionsTab.justify")}>
          <ConfirmRecall.InputRadio
            label="Recall"
            checked={type === "RECALL"}
            onChange={() => setType("RECALL")}
            handleClick={() => setType("RECALL")}
          />
          <ConfirmRecall.FormSelect
            optionSelect={recallLabel}
            value={selectedOption}
            handleChange={(e) => {
              setSelectedOption(e.target.value);
              setType("RECALL");
            }}
            selectError={selectError}
          />

          <ConfirmRecall.InputRadio
            label={t("commonText.others")}
            checked={type === ""}
            handleClick={inputRadioChange}
            onChange={inputRadioChange}
          />
        </ConfirmRecall.ReasonContent>
      )}

      {showRedAlert() && (
        <div>
          <label>{pageLevel()}</label>
        </div>
      )}

      {!hideComments && (
        <ConfirmRecall.CommentArea
          error={error}
          spellCheck={false}
          rows={5}
          cols={40}
          placeholder={`${t("ocurrenceRegisterModal.observation")}`}
          onChange={handleComments}
          value={comments}
        />
      )}
    </ConfirmRecall>
  );
};

export default ConfirmDeleteModal;
