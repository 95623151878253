import styled from "styled-components";

export const PositionTittle = styled.div`
  ${(props) => ({ ...props.theme.fonts.primary.H1Regular })};
  margin: 0px 0 24px 0;

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    ${(props) => ({ ...props.theme.fonts.primary.H2Bold })}
  }
`;

export const Container = styled.div`
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  gap: 10px;
  height: 386px;
  width: 100%;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 5px;

  & > div {
    height: 20px;
    & > div {
      width: 150px;
      justify-content: flex-start;
      ${({ theme }) => theme.fonts.primary.H5SemiBold};
    }
  }
`;

export const EmptyChart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  ${({ theme }) => theme.fonts.primary.H3SemiBold};
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  & span {
    margin-top: 10px;
  }
`;

export const BatteryStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
`;

export const ModalSensorStatusTitleRight = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
  & > div {
    margin-right: 48px;
    @media screen and (max-width: 767px) {
      margin-right: 0px;
    }
  }

  button {
    position: absolute;
    top: -12px;
    right: -12px;
    height: 40px;
    width: 40px;
    svg {
      font-size: 28px;
    }
  }
`;

export const SensorStatusTitle = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) => ({ ...props.theme.fonts.primary.H1Regular })};
  margin: 0px 0 24px 0;

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    ${(props) => ({ ...props.theme.fonts.primary.H2Bold })};
    flex-direction: column;
    row-gap: 16px;
  }
`;
