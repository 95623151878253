import styled, { css } from "styled-components";
import { FrameBodyRight } from "../../components/frame/styles";
import { SidebarList } from "../../components/Sidebar/styles";

export const ContainerQRCode = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    ${(props) => ({ ...props.theme.fonts.primary.H2Bold })};
    margin-bottom: 10px;
  }

  button {
    \ &:hover {
      opacity: 0.7;
    }
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    display: none;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const SidebarListAsset = styled(SidebarList)`
  @media screen and (max-width: 767px) {
    border: 0;
    background-color: transparent;
    box-shadow: none;
    margin: 0;
  }
`;

export const FrameBodyRightAsset = styled(FrameBodyRight)`
  @media screen and (max-width: 767px) {
    & > div:nth-child(2) {
      .carousel-control-prev {
        opacity: 0;
      }
      .carousel-control-next-icon {
        opacity: 0;
      }
    }
  }
  ${(props) =>
    !props.isMobile &&
    css`
      overflow: hidden;
      & > div:first-child {
        display: flex;

        & > div {
          padding: 0;
          background-color: transparent;
          border: none;
        }
      }

      .marquee-container {
        height: 100%;
      }
    `};
`;

export const BrokenButton = styled.button<{ isBroken?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  border-radius: 100%;
  padding: 3px;
  margin-right: 3px;

  color: ${(props) =>
    props.isBroken
      ? props.theme.colors.statusRisk
      : props.theme.colors.iconsSecondary};

  :hover {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    color: ${(props) => props.theme.colors.ibbxOrange};
  }
`;
