import { useEffect, useState, useMemo } from "react";
import { FiChevronDown } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { GroupBase, StylesConfig } from "react-select";
import { Select } from "../Select";
import {
  DropdownIndicatorContainer,
  FormSelectContainer,
  LabelContainer,
  SelectClearButton,
  SelectListRemoveItem,
} from "./styles";
import { FormSelectProps, selectItemModel } from "./types";
import { components } from "react-select";
import { useField, useFormikContext } from "formik";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import InfoButton from "../../modals/PositionForm/InputRender/InfoButton";
import { LoadingInput } from "../LoadingInput";

const FormSelect = ({
  index,
  label,
  options,
  handleChange,
  name,
  placeholder,
  error: propsError,
  customColors,
  filter,
  notClearable,
  disabled,
  defaultValue,
  menuHeight,
  searchable,
  handleRemove,
  important,
  displayImage,
  title,
  loading,
}: FormSelectProps) => {
  const [{ value }] = useField(name || "");

  const [{ value: parentValue }] = useField(filter?.parentName || "");
  const { setFieldValue, errors } = useFormikContext<any>();
  const { colors, fonts } = useTheme();
  const [item, setItem] = useState<selectItemModel | null>(
    defaultValue || null,
  );

  const error = useMemo(() => {
    return propsError ?? errors[name ?? ""];
    // eslint-disable-next-line
  }, [errors[name ?? ""], propsError]);

  useEffect(() => {
    if (options) {
      setItem(
        options?.find(
          (item) =>
            item.value === (typeof value === "object" ? value?.value : value),
        ) || null,
      );
    }

    // eslint-disable-next-line
  }, [options, value]);

  useEffect(() => {
    if (!value && defaultValue && notClearable) {
      setFieldValue(name || "", defaultValue?.value ?? null);
    }
    // eslint-disable-next-line
  }, []);

  const optionsMemo = useMemo(() => {
    if (filter) {
      return options?.filter((item: any) => {
        return item[filter?.props || ""] === parentValue;
      });
    } else {
      return options;
    }
    // eslint-disable-next-line
  }, [options, parentValue]);

  const handleChangeValue = (value: any) => {
    if (value && (value?.value ?? null) !== null) {
      handleChange(value, index);
    } else {
      handleChange({ value: null, label: null }, index);
    }
  };

  const styles: StylesConfig<any, false, GroupBase<any>> = {
    control: (base) => ({
      ...base,
      ...fonts.primary.H4Regular,
      backgroundColor: customColors?.control?.background || colors.background,
      border: `1px solid ${
        error ? "red" : customColors?.control?.border || colors.cardHighlight
      }`,
      outline: "none",
      color: customColors?.control?.color || colors.iconsPrimary,
      height: displayImage ? "85px" : "36px",
      borderRadius: "4px",
      minWidth: "100px",
      boxShadow: "none",
      paddingRight: "8px",
      "&:hover": {
        borderColor: customColors?.control?.borderHovered || colors.textPrimary,
        outline: "none",
      },
      opacity: disabled ? 0.9 : 1,
    }),
    clearIndicator: (base, props) => ({
      ...base,
      backgroundColor: "inherit",
      marginRight: "8px",
    }),
    menu: (base) => ({
      ...base,
      minWidth: "200px",
      overflow: "auto",
      zIndex: 20,
    }),
    menuList: (base) => ({
      ...base,
      width: "100%!important",
      backgroundColor: customColors?.menuList?.background || colors.background,
      maxHeight: menuHeight || "200px",
      overflow: "auto",
    }),
    option: (base, props) => ({
      ...base,
      ...fonts.primary.H5Regular,
      width: "100%!important",
      color: customColors?.option?.color || colors.textPrimary,
      fontWeight: props.isSelected ? 600 : 400,
      ":hover": {
        backgroundColor:
          customColors?.option?.backgroundHovered || colors.cardHighlight,
      },
      backgroundColor: `${props.isSelected ? colors.cardHighlight : "inherit"}`,
      opacity: props.isDisabled ? "0.2" : "1",
    }),
    valueContainer: (base) => ({
      ...base,
      color: customColors?.control?.color || colors.iconsPrimary,
      height: "100%",
      display: "flex",
      position: "relative",
      "& > div": {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
      [`input[type="text"]:focus`]: {
        boxShadow: "none",
        border: "none !important",
      },
    }),
    input: (base, props) => ({
      ...base,
      position: "absolute",
      backgroundColor: "transparent",
      ":focus-visible": {
        outline: "none",
      },
    }),
    placeholder: (base) => ({
      ...base,
      // color: customColors?.placeholder?.color || colors.textPrimary,
      ...fonts.primary.H4Regular,
      fontSize: "13px",
    }),
    singleValue: (base) => ({
      ...base,
      ...fonts.primary.H4Regular,
      color: customColors?.control?.color || colors.iconsPrimary,
    }),
    indicatorSeparator: (base, props) => ({
      display: "none",
    }),
    dropdownIndicator: (base, props) => ({
      ...base,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "inherit",
      margin: 0,
      padding: 0,
    }),
  };

  const clearSelect = () => {
    handleChangeValue({ value: undefined, label: "" });
  };

  // eslint-disable-next-line
  const { t } = useTranslation();

  return (
    <FormSelectContainer color={customColors?.label?.color} title={title}>
      <LabelContainer>
        <label>{label}</label>
        {important && (
          <InfoButton
            name="select-important-button"
            hasValue={!!item?.label?.length}
            content={
              !!item?.label?.length ? (
                <>
                  <strong>
                    {t("PositionForm.InputRender.inputAdviseStrong")}
                  </strong>{" "}
                  {t("PositionForm.InputRender.fullfilled")}
                </>
              ) : (
                <>
                  <strong>
                    {t("PositionForm.InputRender.inputAdviseStrong")}
                  </strong>{" "}
                  {t("PositionForm.InputRender.inputAdvise")}
                </>
              )
            }
          />
        )}
      </LabelContainer>
      {loading ? (
        <LoadingInput />
      ) : (
        <Select
          value={item}
          isSearchable={!!searchable}
          options={optionsMemo || []}
          handleChange={handleChangeValue}
          styles={styles}
          placeholder={placeholder || ""}
          isClearable={!notClearable}
          displayImage={displayImage}
          disabled={disabled}
          components={{
            DropdownIndicator: (props: any) => (
              <components.DropdownIndicator {...props}>
                <DropdownIndicatorContainer>
                  <FiChevronDown size={20} />
                </DropdownIndicatorContainer>
              </components.DropdownIndicator>
            ),
            ClearIndicator: (props: any) => (
              <components.ClearIndicator {...props}>
                <SelectClearButton
                  type="button"
                  onClick={clearSelect}
                  onTouchEnd={clearSelect}
                  {...customColors?.clearButton}
                >
                  <MdClose />
                </SelectClearButton>
              </components.ClearIndicator>
            ),
          }}
        />
      )}
      {!!handleRemove && (
        <SelectListRemoveItem disabled={disabled}>
          <button type="button" onClick={handleRemove} disabled={disabled}>
            {t("FormSelect.remove")}
          </button>
        </SelectListRemoveItem>
      )}
      {error && <span>{error}</span>}
    </FormSelectContainer>
  );
};

export default FormSelect;
