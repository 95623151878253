import { Placeholder } from "react-bootstrap";
import { useTheme } from "styled-components";

interface ILoadingInput {
  height?: string;
}

export const LoadingInput = ({ height }: ILoadingInput) => {
  const { colors } = useTheme();
  return (
    <Placeholder
      as="div"
      animation="glow"
      style={{ height: height ? height : "36px" }}
    >
      <Placeholder
        style={{
          width: "100%",
          height: "95%",
          background: colors.iconsSecondaryAlpha,
          borderRadius: "4px",
        }}
      />
    </Placeholder>
  );
};
