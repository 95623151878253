import styled, { css } from "styled-components";
import { GraphicFooterButton } from "../../components/GraphicCompound/styles";


export const ContainerGeneral = styled.div<{ ref: any }>`
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.background};
`;

export const ContainerHeader = styled.div`
  width: 98%;
  margin-left: 1%;
  height: 6%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerTitle = styled.div`
  margin-top: 0.5%;
  display: flex;
  width: 100%;
  height: 30%;
  align-items: center;
  justify-content: left;
  ${(props) => props.theme.fonts.primary.H2Bold};
  color: ${(props) => props.theme.colors.iconsSecondary};
`;

export const CloseButton = styled.button`
  color: ${(props) => props.theme.colors.iconsSecondary};
  width: 90px;
  height: 32px;
  background-color: ${(props) => props.theme.colors.cardBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  ${(props) => props.theme.fonts.primary.H5Bold};

  svg {
    margin-right: 5px;
  }
`;

export const LogoBottom = styled.div`
  width: 100%;
  height: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoImage = styled.img`
  margin-top: 5px;
  height: 12px;
`;

export const ChartContainer = styled.div<{ embeded?: boolean }>`
  width: 98%;
  height: 93.5%;
  margin-left: 1%;
  ${({ embeded }) =>
    embeded &&
    css`
      width: 100%;
      height: 100%;
      margin-left: 0%;
    `}
`;
/* GRAPH */

export const GraphContainer = styled.div<{ embeded?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.divBackground};
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  border-radius: 6px;
  padding: 4px 0 4px 0;

  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.cardBackground};
  }
  position: relative;

  ${({ embeded }) =>
    embeded &&
    css`
      & > div:first-child {
        margin-bottom: 0;
        gap: 1rem;
        padding: 0 0.5rem;
        font-size: 0.75rem;

        span {
          font-size: 0.75rem;
        }
        & > div:first-child {
          min-width: 12rem;
          flex: 1;
          overflow: auto hidden;
          div {
            height: 1.5rem;
            padding: 0;
            svg {
              width: 1rem !important;
            }
          }

          &::-webkit-scrollbar {
            width: 6px !important;
            height: 3px;
            @media screen and (max-width: 767px) {
              width: 3px !important;
            }
          }

          &::-webkit-scrollbar-track {
            background: ${(props) => props.theme.colors.background};
            width: 20px;
            border-radius: 100vw;
            margin-block: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
            height: 100%;
            max-height: 5rem;
            border-radius: 100vw;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: ${(props) => props.theme.colors.iconsSecondary};
          }

          &::-webkit-scrollbar-thumb:active {
            background: ${(props) => props.theme.colors.iconsSecondary};
          }
        }
        button {
          width: 2rem;
          height: 2rem;
          padding: 0 0.5rem;
          margin-right: 0;
        }
        & > div {
          height: 2rem !important;
        }
      }

      & > div:last-child {
        margin-bottom: 0;
        position: relative;
      }
    `}
`;

/* GraphHeader */
export const GraphHeader = styled.div<{ absolute?: boolean }>`
  ${(props) =>
    props.absolute
      ? {
          position: "absolute",
          top: 4,
          "z-index": 10,
        }
      : ""};

  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin-top: 6px;
  padding: 0 24px;

  & > div:first-child {
    flex: 1;
  }
`;

export const ContainerTools = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  .tooltip-comments {
    width: 2%;
  }

  color: ${(props) => props.theme.colors.iconsSecondary};

  div {
    display: flex;
    width: 56px;
    height: 35px;
    cursor: pointer;
  }
  & > div[data-id="tooltip"] {
    width: auto;
  }
`;

export const Tools = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  background: ${(props) => props.theme.colors.cardBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  /* margin: 22px 8px 0 0; */
  margin-right: 8px;
  &:first-child {
    /* margin-left: 24px; */
  }

  &:hover {
    border: ${(props) =>
      !props.disabled && "1px solid " + props.theme.colors.divBackground};

    svg {
      stroke: ${(props) => !props.disabled && props.theme.colors.ibbxOrange};
      fill: ${(props) => !props.disabled && props.theme.colors.ibbxOrange};
      color: ${(props) => !props.disabled && props.theme.colors.ibbxOrange};
    }
  }

  svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
    font-size: 22px;
    ${(props) => props.selected && "color: " + props.theme.colors.ibbxOrange};
  }
`;

export const GraphFooter = styled.div<{ absolute?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
  width: 100%;
  height: 50px;
  padding: 0 24px;

  ${({ absolute }) =>
    absolute ? { position: "absolute", bottom: 4, "z-index": 1 } : ""};
`;

export const EmbededLink = styled(GraphicFooterButton)`
  width: fit-content;
  height: fit-content;
`;

export const ToolsChartBottom = styled.div`
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  background: ${(props) => props.theme.colors.cardBackground};
  border-radius: 6px;
  width: 72px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* width: 30%; */
  height: 100%;
  gap: 8px;
`;
export const AxisDiv = styled.div`
  color: ${(props) => props.theme.colors.iconsSecondary};
  margin-bottom: 10px;

  & > div:nth-child(1) {
    label {
      font-size: 11px;
      margin-bottom: 8px;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;

    label {
      font-size: 13px;
      margin-right: 36px;
    }
  }
`;

export const ButtonAxis = styled.button<{ selected?: boolean }>`
  max-height: 10px;
  margin-right: 8px;
  padding: 4px 6px;
  background: ${(props) =>
    props.selected
      ? props.theme.colors.iconsSecondary
      : props.theme.colors.cardBackground};
  border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
  border-radius: 6px;
  color: ${(props) =>
    props.selected
      ? props.theme.colors.ibbxOrange
      : props.theme.colors.iconsSecondary};

  &:hover {
    border: ${(props) =>
      !props.disabled && "1px solid " + props.theme.colors.ibbxOrange};

    color: ${(props) => props.theme.colors.ibbxOrange};
  }
`;

export const EmptyChart = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  & span {
    margin-top: 10px;
  }
`;

export const ButtonDownlaod = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  background: ${(props) => props.theme.colors.cardBackground};

  padding: 6px 10px;
  background: ${(props) => props.theme.colors.cardBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  margin-right: 22px;

  &:hover {
    border: ${(props) =>
      !props.disabled && "1px solid " + props.theme.colors.divBackground};
  }

  svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
    font-size: 22px;
  }
  svg {
    &:hover {
      color: ${(props) => !props.disabled && props.theme.colors.ibbxOrange};
      border-radius: 4px;
    }
    &:active {
      padding: ${(props) => !props.disabled && "1px"};
    }
  }
`;
