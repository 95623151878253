import styled from "styled-components";
import lightTheme from "../../styles/themes/light";
import { componentColorsModel } from "./types";

export const FormSelectContainer = styled.div<{ color?: string }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 4px;

  label {
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${(props) => props.color || "unset"};
  }

  span {
    ${(props) => props.theme.fonts.secondary.H3Regular};
    color: red !important;
  }

  & > div > div {
    width: 100%;
  }
  
  & > div {
    width: 100%;
    & > div:nth-child(2) {
      margin-top: 0px;
      padding: 0;
    }

    & > div > div {
      &::-webkit-scrollbar {
        width: 6px !important;
      }

      &::-webkit-scrollbar-track {
        background: ${(props) => lightTheme.colors.background};
        width: 20px;
        border-radius: 100vw;
        margin-block: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${(props) => lightTheme.colors.iconsSecondaryAlpha};
        height: 100%;
        max-height: 5rem;
        border-radius: 100vw;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${(props) => lightTheme.colors.iconsSecondary};
      }

      &::-webkit-scrollbar-thumb:active {
        background: ${(props) => lightTheme.colors.iconsSecondary};
      }
    }
  }
`;

export const SelectClearButton = styled.button<componentColorsModel>`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: inherit;
  border: none;
  color: ${(props) => props.color || props.theme.colors.iconsPrimary};
  background-color: ${(props) =>
    props.background || props.theme.colors.background};

  @keyframes button-on {
    0% {
      transform: rotateY(70deg);
      transform-origin: right;
      opacity: 0;
    }
    100% {
      transform: rotateY(0);
      transform-origin: right;
      opacity: 1;
    }
  }

  svg {
    font-size: 17px;
  }
`;

export const DropdownIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const SelectListRemoveItem = styled.div<{ disabled?: boolean }>`
  position: absolute;
  top: -6px;
  right: 0px;

  button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.iconsSecondary};
    opacity: ${({ disabled }) => disabled && 0.7};
    ${({ theme }) => theme.fonts.primary.H5Regular};
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  vertical-align: center;
  gap: 8px;

  & > label {
    color: ${({ theme }) => theme.colors.iconsPrimary};
    display: flex;
    margin: 0;
    align-items: center;
  }
`;
