import styled, { css } from "styled-components";

interface IContainer {
  showSidebar?: boolean;
  isFiltered?: boolean;
}

export const Container = styled.div<IContainer>`
  background: ${({ theme }) => theme.colors.divBackground};
  border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
  border-radius: 6px;
  height: 100%;
  display: grid !important;
  grid-template-columns: 0.1fr repeat(4, 1fr);
  grid-template-rows: 0.3fr 0.4fr repeat(3, 1fr);
  height: 100%;
  padding: 10px;

  ${(props) =>
    props.showSidebar &&
    css`
      display: grid !important;
      grid-template-columns: 0.5fr repeat(4, 1fr);
      gap: 8px 0px;
    `}

  & > div:nth-child(2) > div > div {
    padding: 0px 10px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 2px 0 2px;
    overflow-x: hidden;
    grid-template-rows: ${(props) =>
      props.isFiltered
        ? "0.3fr 0.15fr repeat(4, 1fr)"
        : "0.3fr auto repeat(4, 1fr)"};
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    grid-template-rows: 0.1fr 0.4fr repeat(3, 1fr);
  }
`;

export const FilterButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 37px;
  grid-area: 1 / 1 / 2 / 2;
  svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 50%;
  }
  @media screen and (max-width: 767px) {
    svg {
      width: 1.8rem;
      height: auto;
    }
  }
`;

export const SidebarFilter = styled.div<{ showSidebar?: boolean }>`
  width: 242px;
  height: 100%;
  background-color: lightgray;
  position: relative;
  background-color: ${(props) => props.theme.colors.headerBackground};
  border-radius: 8px 0 0 8px;
  overflow: hidden auto;
  padding: 8px;
  padding-left: 10px;
  margin-right: 12px;

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  ${(props) =>
    props.showSidebar &&
    css`
      grid-area: 1 / 1 / 6 / 2;
    `}

  @media only screen and (max-width: 1536px) {
    width: 236px;
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    top: 4rem;
    left: 0rem;
    width: 20rem;
    height: 100vh;
    z-index: 3;
    padding: 1rem;
    border-radius: 0px 8px 8px 0px;
  }

  @media screen and (min-width: 799px) and (max-width: 826px) {
    position: fixed;
    top: 4rem;
    left: 0rem;
    width: 22rem;
    height: 100vh;
    z-index: 5;
    padding: 0rem;
    border-radius: 0px 8px 8px 0px;
  }
`;

export const CloseSidebarButton = styled.button`
  position: absolute;
  left: 6px;
  top: 6px;
  border: none;
  color: ${(props) => props.theme.colors.iconsPrimary};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 50%;
  }

  @media screen and (max-width: 767px) {
    top: 0.8rem;
    width: 2rem;
    left: 17.3rem;
    height: 2rem;

    svg {
      width: 2rem;
      height: auto;
    }
  }

  @media screen and (min-width: 799px) and (max-width: 826px) {
  }
`;

interface IOccurrenceListContainer {
  isOccurrencePage?: boolean;
  showSidebar?: boolean;
  isFiltered?: boolean;
}

export const OccurrenceListContainer = styled.div<IOccurrenceListContainer>`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  grid-area: 3 / 1 / 6 / 6;

  ${(props) =>
    props.showSidebar &&
    css`
      grid-area: 3 / 2 / 6 / 6;
    `}

  & > div {
    overflow-x: hidden;
    width: 100%;
    padding-bottom: ${(props) => props.isOccurrencePage && "10rem"};

    & > div {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
      /* padding-bottom: 160px; */
    }
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media screen and (max-width: 767px) {
    &::-webkit-scrollbar {
      display: none;
    }

    width: max-content;
    scrollbar-width: none;

    margin-top: ${(props) => (props.isFiltered ? "1.5rem" : "0.2rem")};
  }
  @media screen and (min-width: 799px) and (max-width: 826px) {
    & > div > div {
      padding: 0px;
    }
  }
`;

export const OccurrenceListRow = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  /* margin-right: 0.3rem; */
  text-decoration: none;

  a {
    text-decoration: none;
    &:hover {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }

  &:last-child {
    margin-bottom: 2rem;
  }

  &:hover {
    filter: brightness(1.1);
  }

  @media screen and (max-width: 767px) {
    display: flex;
    width: 100%;
    padding: 4px;
    gap: 0.2rem;
    margin: 0;
    &:last-child {
      margin-bottom: 6rem;
    }
  }
`;

export const OccurreceListCheckContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OccurrenceSelectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  grid-area: 1 / 2 / 2 / 6;

  @media screen and (max-width: 767px) {
    padding: 0 !important;
    flex-direction: column;

    & > div:first-child {
      order: 2;
    }

    & > div:last-child {
      justify-content: space-between;
      padding: 0 0.2rem;
      flex-wrap: nowrap;
    }
  }

  @media screen and (max-width: 1536px) {
    padding: 0 0 0 8px;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    & > div:last-child {
      width: auto;
    }
  }
`;

export const SelectOccurrenceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  /* width: 55%; */

  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 10px;
    padding: 0 8px !important;
    ${({ theme }) => theme.fonts.primary.H4Regular};
    color: ${({ theme }) => theme.colors.iconsSecondary};

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > div:nth-child(2) {
      flex-wrap: nowrap;

      span {
        white-space: nowrap;

        @media screen and (max-width: 1536px) {
          white-space: normal;
          font-size: 12px;
        }
      }
    }
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 94px;
    gap: 0.8rem;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: flex-end !important;

    .selectButton {
      button {
        padding: 0.2rem 0.3rem;
      }
    }

    & > div:nth-child(1) {
      gap: 0.3rem;

      padding: 0 !important;
    }
    & > div:nth-child(2) {
      min-width: 2rem;
      padding: 0 !important;
      gap: 0.3rem;
      div {
        width: auto;
      }
      button {
        width: auto;
        padding: 0.2rem 0.3rem;
      }
    }
    & > div:nth-child(3) {
    }
  }

  @media screen and (max-width: 1536px) {
    width: 100%;
  }

  @media screen and (min-width: 799px) and (max-width: 826px) {
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 6px;
  }
`;

export const SelectAllOccurrenceButton = styled.button`
  ${({ theme }) => theme.fonts.primary.H4Bold};
  height: 100%;
  padding: 4px 12px;
  border-radius: 8px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.iconsPrimary};
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};

  &:hover {
    background-color: ${({ theme }) => theme.colors.cardHighlight};
    transition: 0.2s ease-in-out;
  }

  @media screen and (max-width: 767px) {
    height: 2rem;

    span {
      white-space: nowrap;
    }
  }

  @media screen and (max-width: 1536px) {
    font-size: 12px;
  }
`;

export const Pagination = styled.div`
  ${({ theme }) => theme.fonts.primary.H4Regular};
  color: ${({ theme }) => theme.colors.iconsSecondary};
  padding: 0 16px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    white-space: nowrap;
    @media screen and (max-width: 1536px) {
      white-space: normal;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 1536px) {
    label {
      white-space: normal;
      font-size: 12px;
    }
  }
`;

export const UnselectButton = styled.button`
  border-radius: 100%;
  background-color: transparent;
  height: 28px;
  width: 28px !important;
  border: none;
  color: ${({ theme }) => theme.colors.iconsPrimary};

  svg {
    font-size: 18px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.cardHighlight};
  }

  @media screen and (max-width: 767px) {
    width: 20px !important;
    height: 20px !important;
  }
`;

export const OccurrenceListCheck = styled.button<{ checked?: boolean }>`
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
  background-color: ${({ theme, checked }) =>
    checked ? theme.colors.iconsSecondary : "transparent"};

  svg {
    display: ${({ checked }) => !checked && "none"};
    color: ${({ theme }) => theme.colors.background};
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

export const OccurrenceSelectAll = styled(OccurrenceListCheck)``;

export const EmptyOccurrenceList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};

  gap: 20px;
`;

export const GroupingSelectArea = styled.div`
  ${({ theme }) => theme.fonts.primary.H4Bold};
  color: ${({ theme }) => theme.colors.iconsPrimary};
  padding: 0 12px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  gap: 6px;
  width: 220px;

  span {
    ${({ theme }) => theme.fonts.secondary.H1BoldCard};
  }

  button {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: brightness(0.6);
      transition: filter 0.1s ease-in-out;
    }
  }

  svg {
    width: 22px;
    height: auto;
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
  @media screen and (max-width: 767px) {
    height: auto;

    svg {
      width: 28px;
      height: auto;
    }
  }
`;

export const FilteredItemContainer = styled.div`
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 4px;
  padding-top: 4px;

  button {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0;

    button {
      padding: 4px 6px;
      gap: 0px;

      svg {
        width: 1rem;
        height: auto;
      }
    }
  }
`;

export const FilteredButton = styled.button<{ disable?: boolean }>`
  ${(props) => ({ ...props.theme.fonts.primary.H4Regular })};
  padding: 2px 12px;
  /* background-color: ${(props) =>
    props.disable
      ? props.theme.colors.iconsPrimary
      : props.theme.colors.iconsSecondaryAlpha};
  color: ${(props) =>
    props.disable
      ? props.theme.colors.background
      : props.theme.colors.iconsSecondary}; */
  background-color: ${(props) => props.theme.colors.iconsPrimary};
  color: ${(props) => props.theme.colors.background};
  border-radius: 50px;
  margin: 0 4px 4px 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  /* cursor: ${(props) =>
    props.disable ? "pointer" : "context-menu"} !important; */

  display: ${(props) => (props.disable ? "visible" : "none")};

  & > span {
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    width: 18px;
    height: auto;
  }

  &:hover {
    svg {
      transform: scale(1.2);
      transition: all 0.2s ease-in-out;
    }
  }

  @media only screen and (max-width: 1536px) {
    ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};
    font-size: 10px;
  }

  @media screen and (max-width: 767px) {
    margin: 0.2rem 0.1rem;
    background-color: transparent;
    color: ${(props) => props.theme.colors.iconsPrimary};
    text-decoration: underline;
    padding: 0 0.1rem !important;
    max-width: fit-content;
    min-width: 2.2rem;
    flex: 1;

    span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const ClearAllFilters = styled.button`
  text-transform: uppercase;
  background-color: transparent !important;
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  padding: 0 !important;
  border-radius: 0 !important;
  margin: 0 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: ${(props) => props.theme.colors.iconsPrimary};
    font-weight: 700;
    font-size: 12px;
  }

  &:hover {
    span {
      text-decoration: underline;
      transition: all 0.2s ease-in-out;
    }
  }

  @media screen and (max-width: 767px) {
    background-color: ${(props) => props.theme.colors.iconsPrimary} !important;
    color: ${(props) => props.theme.colors.background};
    border-radius: 50px !important;
    padding: 0.1rem !important;
  }
`;

export const OccurrenceSelectAdd = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0 !important;

  @media only screen and (max-width: 1536px) {
    /* width: 280px !important; */
    padding-left: 0 !important;
  }

  & > div {
    margin-right: 8px;

    & > div {
      margin-right: 0px;

      .react-datepicker__input-container {
        height: 28px;

        input {
          background-color: transparent;
          padding: 6px 8px;
          text-align: center;
          width: fit-content;
          @media screen and (max-width: 767px) {
            text-align: left;
            width: 100%;
          }
        }
      }
      button {
        display: flex;
        align-items: center;
      }
    }
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: ${(props) => props.theme.colors.ibbxOrange};
    border-radius: 100%;
    border: none;
    color: white;

    svg {
      width: 22px;
      height: auto;
    }
  }
`;

export const CalendarContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
  width: 186px;

  z-index: 2;

  .react-datepicker {
    transform: scale(0.95) translateX(-10px) translateY(-10px);
    font-size: 0.85rem;
  }

  .react-datepicker__triangle {
    display: none;
  }

  & > div {
    border-radius: 0;
    margin-right: 6px;
    & > button {
      background: transparent;
      border: none;
      color: ${(props) => props.theme.colors.iconsSecondary};

      svg {
        width: 26px;
        height: auto;
      }

      &:hover {
        color: ${(props) => props.theme.colors.ibbxOrange};
      }
    }

    & > div {
      width: 182px;
      & > div {
        input {
          border-radius: 6px;
          width: 100%;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: 7rem;

    .react-datepicker__input-container {
      width: 6.5rem;
      margin-right: 0;
    }

    & > div > div {
      width: 7rem;
    }
  }
`;

export const ListFilteredContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  grid-area: 2 / 1 / 2 / 6;
  & > div {
    padding: 0.5rem 0 !important;
  }
`;

export const OccurrenceMobile = styled.div`
  grid-area: 3 / 1 / 6 / 6;
  width: 100%;
  height: max-content;
  overflow: auto;
`;
