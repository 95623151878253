import styled from "styled-components";

export const CardOccurrenceContainer = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  color: ${(props) => props.theme.colors.iconsSecondary};

  span {
    margin-left: 10px;
    ${(props) => props.theme.fonts.primary.H3SemiBold}
  }
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OccurrenceBox = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OccurrenceData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  margin-bottom: 10px;

  span {
    ${(props) => props.theme.fonts.primary.H1boldCard};
    margin: 0;
  }

  label {
    ${(props) => props.theme.fonts.primary.H4Bold};
  }
`;
