import { TbAppsFilled } from "react-icons/tb";
import {
  BetaBedge,
  BodyContent,
  Elipse,
  Header,
  HubContainer,
  Section,
} from "./styles";

import { ReactComponent as IbbxLogo } from "../../images/ibbx.svg";

import { StardardHubApp } from "./StandardHubApp";
import { HomePageButton } from "../../components/HomePageButton";

import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";

export function Hub() {
  const { apps } = useAppSelector(appSelect);

  return (
    <HubContainer>
      <Header>
        <BetaBedge>
          <span>Beta</span>
        </BetaBedge>
        <IbbxLogo />
        <HomePageButton tooltipTitle="Ir para o Retina" />
      </Header>
      <Section>
        <TbAppsFilled />
        <span>Meus Aplicativos</span>
      </Section>
      <BodyContent>
        {(apps || []).map((app) => (
          <StardardHubApp
            id={app.id}
            image={app.image}
            title={app.title}
            available={app.available}
            url={app.url}
            appDescription={app.description}
            app={app}
            handleOpenEdit={() => {}}
            handleFavorite={() => {}}
            handleDelete={() => {}}
          />
        ))}

        {/* <StardardHubApp /> */}
      </BodyContent>

      <Elipse width="60%" height="10%" left="50%" top="50%" opacity="0.75" />
      <Elipse width="40%" height="40%" left="0%" top="10%" opacity="0.50" />
      <Elipse width="20%" height="30%" left="40%" top="50%" opacity="0.35" />
    </HubContainer>
  );
}
