import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { InfoButtonContainer } from "./styles";
import { InfoButtonProps } from "./types";
import { AiOutlineCheckCircle } from "react-icons/ai";

const InfoButton = ({ name, content, hasValue }: InfoButtonProps) => {
  return (
    <OverlayTrigger
      key={name}
      placement="top"
      overlay={
        <Tooltip id={`tooltip-${name}}`} style={{ zIndex: 10000 }}>
          {content}
        </Tooltip>
      }
    >
      <InfoButtonContainer type="button" hasValue={hasValue}>
        {!hasValue ? (
          <IoMdInformationCircleOutline size={24} />
        ) : (
          <AiOutlineCheckCircle size={24} />
        )}
      </InfoButtonContainer>
    </OverlayTrigger>
  );
};

export default InfoButton;
