import { useMemo } from "react";
import queryString from "../../../utils/queryString";
import { useLocation } from "react-router-dom";
import SelectPeriod from "./SelectPeriod";
import GlobalPeriodPicker from "./GlobalPeriodPicker";
import { GlobalPeriodSelectorContainer } from "./styles";

type GlobalPeriodSelectorProps = {
  dateRange: [any, any];
  setDateRange: (value: [any, any]) => void;
};

function GlobalPeriodSelector({
  dateRange,
  setDateRange,
}: GlobalPeriodSelectorProps) {
  const location = useLocation();
  const queryStringParameters = queryString.parse(location.search);
  const isCustom = useMemo(() => {
    return queryStringParameters.period === "CUSTOM";
  }, [queryStringParameters.period]);

  return (
    <GlobalPeriodSelectorContainer>
      <SelectPeriod />
      {isCustom && <GlobalPeriodPicker dateRange={dateRange} setDateRange={setDateRange}/>}
    </GlobalPeriodSelectorContainer>
  );
}

export default GlobalPeriodSelector;
