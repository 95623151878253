import { Form, Formik } from "formik";

import { FormikTextInput } from "../../../../components/textInput";
import useApi from "../../../../hooks/useApi";

import { cnpjValidator } from "../../../../utils/cnpjValidator";

import { Button, Footer } from "./styles";

import { FormEditRepresentativeProps } from "./types";
import { useTranslation } from "react-i18next";

const FormEditRepresentative = ({
  values,
  onRefresh,
}: FormEditRepresentativeProps) => {
  const { request } = useApi({ path: "/representatives" });

  const handleSubmit = (values: any) => {
    request({ method: "put", pathParameters: values.id, body: values }).then(
      () => {
        onRefresh();
      },
    );
  };

  const { t } = useTranslation();

  return (
    <Formik
      initialValues={values}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values) => {
        const errors: any = {};
        if (!values.name.trim()) {
          errors.name = t(
            "Representative.FormEditRepresentative.mandatoryName",
          );
        }

        if (!values.document) {
          errors.document = t(
            "Representative.FormEditRepresentative.mandatoryCnpj",
          );
        } else if (values.document && !cnpjValidator(values.document)) {
          errors.document = t(
            "Representative.FormEditRepresentative.invalidCnpj",
          );
        }

        return errors;
      }}
      onSubmit={handleSubmit}
    >
      {({ errors }) => (
        <Form>
          <FormikTextInput
            name="name"
            label={`${t("Representatives.FormEditRepresentative.name")}`}
            error={errors.name}
          />
          <FormikTextInput
            name="document"
            label={`${t("Representatives.FormEditRepresentative.cnpj")}`}
            inputMode="numeric"
            error={errors.document}
          />
          <Footer>
            <Button type="submit">
              {t("Representatives.FormEditRepresentative.update")}
            </Button>
          </Footer>
        </Form>
      )}
    </Formik>
  );
};

export default FormEditRepresentative;
