import styled, { css } from "styled-components";
import { FrameBodyMain, FrameBodyRight } from "../../components/frame/styles";

export const FrameBodyMainEsg = styled(FrameBodyMain)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  position: relative;

  @keyframes jello-horizontal {
    0% {
      transform: scale3d(1, 1, 1);
    }
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes rotate-scale-up {
    0% {
      transform: scale(1) rotateZ(0);
    }
    50% {
      transform: scale(1.2) rotateZ(180deg);
    }
    100% {
      transform: scale(1) rotateZ(360deg);
    }
  }

  @keyframes bg-animation {
    0% {
      transform: scale(1) translateY(0);
      transform-origin: 50% 84%;
    }
    100% {
      transform: scale(1.25) translateY(15px);
      transform-origin: bottom;
    }
  }

  .shape1 {
    position: absolute;
    width: 31rem;
    height: auto;
    bottom: 17rem;
    left: 0rem;
    z-index: 1;
    animation: jello-horizontal 80s ease-in-out infinite both;

    @media screen AND (max-width: 1920px) {
      width: 28rem;
      bottom: 16rem;
    }
  }
  .shape2 {
    position: absolute;
    width: 30rem;
    height: auto;
    bottom: 16rem;
    right: -4rem;
    transform: rotate(270deg);
    z-index: 1;
    animation: rotate-scale-up 60s ease-in-out infinite both;
    @media screen AND (max-width: 1920px) {
      width: 26rem;
      bottom: 14.5rem;
    }
  }

  .tree-full {
    position: absolute;
    width: 30rem;
    height: auto;
    bottom: 8rem;
    left: 0rem;
    z-index: 1;

    @media screen AND (max-width: 1920px) {
      width: 28rem;
      bottom: 7rem;
    }

    @media screen AND (min-width: 1921px) {
      bottom: 12rem;
    }

    @media screen and (max-width: 767px) {
      /* display: none; */
      width: 12rem;
      height: auto;
      bottom: 16rem;
    }

    @media screen and (min-width: 767px) and (max-width: 1229px) {
      width: 20rem;
      height: auto;
      bottom: 8rem;
    }
  }

  .no-leaf-tree {
    position: absolute;
    width: 20rem;
    height: auto;
    bottom: 14rem;
    right: 0rem;
    z-index: 1;

    @media screen and (max-width: 767px) {
      width: 9rem;
      height: auto;
      bottom: 16rem;
    }

    @media screen AND (min-width: 767px) and (max-width: 1920px) {
      width: 19rem;
      bottom: 11rem;
    }
  }

  .bg-grass {
    position: absolute;
    width: 100vw;
    height: auto;
    bottom: 6rem;
    left: 0rem;
    z-index: 1;

    @media screen and (max-width: 767px) {
      display: none;
    }

    @media screen AND (max-width: 1920px) {
      bottom: 5.5rem;
    }
  }

  .bg-grass-mobile {
    position: absolute;
    width: 100vw;
    height: auto;
    bottom: 6rem;
    left: 0rem;
    z-index: 1;
  }

  .bg-esg {
    position: absolute;
    width: 100vw;
    height: auto;
    z-index: 0;
    animation: bg-animation 35s ease-in-out 0.8s infinite both;

    @media screen AND (max-width: 1920px) {
      bottom: -15rem;
    }
    @media screen and (max-width: 767px) {
      width: auto;
      height: 100vh;
    }
  }
`;

export const FrameBodyRightEsg = styled(FrameBodyRight)`
  color: #314d4a;
  width: 100%;

  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    opacity: 1;
    z-index: 2;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  width: 92%;
  height: 90%;
  color: #314d4a;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  z-index: 3;

  strong {
    ${(props) => props.theme.fonts.primary.H3SemiBold}
  }

  span {
    ${(props) => props.theme.fonts.primary.H4Bold}
  }

  p {
    ${(props) => props.theme.fonts.primary.H5Regular}
  }

  @media screen and (max-width: 767px) {
    height: 80%;
    width: 100%;
    justify-content: flex-start; // após retorno do retina coin, remover
  }

  @media screen and (min-width: 1229px) and (max-width: 1536px) {
    height: 100%;
  }

  @media screen and (min-width: 767px) and (max-width: 1229px) {
    height: 100%;
    max-width: 100%;
  }
`;

export const ContentTitle = styled.div`
  width: 100%;
  height: 13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #314d4a;
  padding: 1.5rem 0rem;
  margin-bottom: 1.5rem;
  z-index: 6;
  position: relative;
  span {
    ${(props) => props.theme.fonts.primary.H1boldCard}
    font-weight: 600;
    font-size: 65px;
    line-height: 80px;
  }

  @media screen and (max-width: 767px) {
    align-items: center;
    height: 5rem;
    margin-top: 0.5rem; // Sem retina coin, após, voltar valor para 2rem.
    margin-bottom: 2rem; // Sem retina coin, após, voltar valor para 0.2rem.

    span {
      ${(props) => props.theme.fonts.primary.H2Bold}
      font-size: 26px;
      margin-bottom: 0.4rem;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    padding-top: 1rem;
    height: 10rem;
    span {
      ${(props) => props.theme.fonts.primary.H1boldCard}
      font-weight: 600;
      font-size: 50px;
      line-height: 60px;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1560px) {
    margin-bottom: 0rem;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 12rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding-top: 16px;
  /* background-color: #254330; */
  color: #f6f8ee;
  z-index: 3;

  strong {
    ${(props) => props.theme.fonts.primary.H3SemiBold}
  }

  &:before {
    content: "";
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: -1rem;
    left: 0;
    background-size: auto;
    background-repeat: repeat no-repeat;
    background-position: 32vw bottom;
    background-color: #254330;

    @media screen and (min-width: 1229px) and (max-width: 1397px) {
      bottom: 1.2rem;
    }
    @media screen and (max-width: 767px) {
      bottom: -4rem;
    }

    @media screen and (min-width: 767px) and (max-width: 1229px) {
      bottom: -2rem;
    }
  }

  @media screen and (max-width: 767px) {
    bottom: 8.5rem;
  }

  @media screen AND (min-width: 1229px) and (max-width: 1397px) {
    bottom: -1rem;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    height: 9rem;
  }
  @media screen and (min-width: 767px) and (max-width: 1229px) {
    bottom: 1rem;
  }
`;

export const FooterContent = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  & > span {
    ${(props) => props.theme.fonts.primary.H3SemiBold}
    font-weight: 500;
    font-size: 18px;
    color: #254330;
  }

  gap: 28px;

  @media screen and (max-width: 767px) {
    margin-bottom: 0;
    padding: 1rem 0;

    span {
      ${(props) => props.theme.fonts.primary.H3SemiBold}
    }
  }

  @media screen and (min-width: 1229px) and (max-width: 1536px) {
    gap: 18px;
  }

  @media screen and (min-width: 767px) and (max-width: 1229px) {
    gap: 12px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 10;

  @media screen and (max-width: 767px) {
    width: 100%;

    justify-content: center;

    .carousel-indicators {
      bottom: -2.5rem;
      display: none;
    }

    .carousel-inner {
      overflow: inherit;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1560px) {
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
  }
`;

export const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  /* background-color: #d5eed0; */
  position: relative;
  box-shadow: 0px 2px 7px 1px rgba(49, 77, 74, 0.61);

  svg {
    z-index: 2;
    width: 96px;
    height: auto;
    position: absolute;
    bottom: 8px;
  }

  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  // animação árvore

  @keyframes animate-svg-fill-1 {
    0% {
      fill: transparent;
    }

    60% {
      fill: rgb(107, 129, 65);
    }
  }

  .svg-elem-1 {
    animation: animate-svg-fill-1 4s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s
      infinite both;
  }

  @keyframes animate-svg-fill-2 {
    0% {
      fill: transparent;
    }

    50% {
      fill: rgb(107, 129, 65);
    }
  }

  .svg-elem-2 {
    animation: animate-svg-fill-2 4s cubic-bezier(0.47, 0, 0.745, 0.715) 2s
      infinite both;
  }

  @keyframes animate-svg-fill-3 {
    0% {
      fill: transparent;
    }

    40% {
      fill: rgb(107, 129, 65);
    }
  }

  .svg-elem-3 {
    animation: animate-svg-fill-3 4s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s
      infinite both;
  }

  @keyframes animate-svg-fill-4 {
    0% {
      fill: transparent;
    }

    30% {
      fill: rgb(37, 67, 48);
    }
  }

  .svg-elem-4 {
    animation: animate-svg-fill-4 4s cubic-bezier(0.47, 0, 0.745, 0.715) 8s
      infinite both;
  }

  @keyframes animate-svg-fill-5 {
    0% {
      fill: transparent;
    }

    30% {
      fill: rgb(88, 80, 58);
    }
  }

  .svg-elem-5 {
    animation: animate-svg-fill-5 5s cubic-bezier(0.47, 0, 0.745, 0.715) 8s both;
  }

  @keyframes slide-out-top {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    50% {
      transform: translateY(-26px);
      opacity: 1;
    }
    100% {
      transform: translateY(-80px);
      opacity: 0;
    }
  }

  .co2 {
    animation: slide-out-top 5s ease-in-out infinite both;
  }
  .co2-2 {
    width: 70px;
    height: auto;
    right: 9px;
    animation: slide-out-top 5s ease-in-out 2.5s infinite both;
  }
  .co2-3 {
    width: 60px;
    height: auto;
    left: 9px;
    animation: slide-out-top 5s ease-in-out 2.8s infinite both;
  }

  .tree-compensated {
  }

  .battery {
    width: 128px;
    height: auto;
  }

  .toxic-trash {
    width: 128px;
    height: auto;
  }

  .energy-saving {
    width: 100px;
    height: auto;
  }

  .energy-cost {
    width: 106px;
    height: auto;
  }

  .data-decision {
    width: 96px;
    height: auto;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    .battery {
      width: 98px;
      height: auto;
    }

    .toxic-trash {
      width: 98px;
      height: auto;
    }
    .energy-saving {
      width: 90px;
      height: auto;
    }

    .energy-cost {
      width: 96px;
      height: auto;
    }
    .data-decision {
      width: 86px;
      height: auto;
    }
  }
`;

export const ShowExtraInfo = styled.button`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0.4rem;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  color: #859988;
  opacity: 0;
  z-index: 100;
  transition: all 0.3s ease;

  svg {
    width: 1.1rem;
    height: auto;
  }

  @media screen and (min-width: 1229px) and (max-width: 1536px) {
    align-items: flex-start;
  }

  &:hover {
    filter: brightness(1.3);
    scale: calc(1.2);
  }
`;

export const CardEsg = styled.div<{ noPointerCursor?: boolean }>`
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f6f8ee;
  color: #314d4a;
  border-radius: 16px;
  margin: 0 8px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 10px 33px -3px rgba(20, 31, 28, 0.3);
  transition: all 0.3s;
  flex-direction: column;
  transition: transform background-color 0.5s ease;
  outline: 2px solid transparent;

  & > div {
    height: 50%;
  }

  cursor: ${(props) => (props.noPointerCursor ? "default" : "pointer")};

  &:hover {
    outline: 2px solid #859988;
    transform: translateY(-10px);
    box-shadow: 0px 10px 33px -3px rgba(20, 31, 28, 0.6);

    & > button {
      opacity: 1 !important;
      transition: opacity 0.3s ease-in-out;
    }

    & > div:last-child {
      background: #ffffffad;
    }
  }

  @media screen and (max-width: 767px) {
    width: 21rem;
    margin: 0;
    &:hover {
      transform: translateY(0px);
      box-shadow: none;
    }
  }

  @media screen and (min-width: 1229px) and (max-width: 1536px) {
    flex-direction: row;
    width: 278px;
    height: 136px;
    /* display: grid; */
    /* grid-template-columns: 40% 60%;
    grid-template-rows: 1fr; */

    & > div {
      justify-content: center !important;
      align-items: flex-end !important;
      height: 100%;
    }

    & > div:first-child {
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1229px) {
    width: 268px;
    height: 126px;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 1fr;
  }
`;

export const InfoButton = styled.button`
  position: absolute;
  border: 1px solid #4b6c5799;
  background-color: transparent;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  right: 0.5rem;
  top: 0.5rem;
  transition: background-color 0.2s ease-in-out;
  opacity: 0.5;

  & > svg {
    color: #314d4a;
    width: 1rem;
    height: auto;
  }

  &:hover {
    opacity: 1;
    background-color: #4b6c5791;
    border: none;
    filter: opacity(0.9);

    & > svg {
      color: #f6f8ee;
    }
  }
`;

export const EsgOdsContainer = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  & > div {
    width: 100%;

    & > div {
      & > div {
        & > div {
          width: 100%;
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    & > div {
      width: 26rem;
      gap: 0;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1229px) {
    & > div {
      width: 100%;

      & > div {
        & > div {
          & > div {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
          }
        }
      }
    }
  }
`;

export const OdsContainer = styled.div`
  width: 230px;
  height: 56px;
  border-radius: 8px;
  padding: 6px 16px;
  gap: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #f6f8ee;

  svg {
    width: 50px;
    height: auto;
  }

  span {
    ${(props) => props.theme.fonts.secondary.H3Bold}
    font-size: 11px;
    text-transform: uppercase;
  }

  @media screen and (max-width: 767px) {
    width: 12rem;
    height: 3rem;
    padding: 6px;

    svg {
      width: 2rem;
      height: auto;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1229px) {
    width: 180px;
    height: 46px;
    gap: 8px;
  }
`;

export const RetinaCoinContainer = styled.div`
  width: 364px;
  height: 180px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 3;
  border-radius: 16px;
  border: 2px solid #859988;
  background: #e8ebe3;
  box-shadow: -6px 7px 23px -5px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  & > div {
    display: flex;
    padding: 8px 16px;
  }

  & > div:first-child {
    width: 100%;
    height: 20%;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, #d5eed0 0.04%, #9dc3a1 100%);
    box-shadow: rgba(49, 77, 74, 0.41) 0px 2px 7px 1px;
    position: relative;

    span {
      ${(props) => props.theme.fonts.primary.H3SemiBold}
      color: #254330;
    }

    button {
      position: absolute;
      right: 8px;
      border: none;
      background-color: transparent;
      padding: 3px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 20px;
        height: auto;
        color: #254330;
      }

      &:hover {
        border: 2px solid #9dc3a1;

        svg {
          filter: brightness(0.5);
        }
      }

      &:active {
        transform: scale(0.9);
      }
    }
  }

  & > div:last-child {
    justify-content: space-between;
    align-items: center;
    flex: 1;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      transform: translatey(-50%);
      right: 8px;
    }

    & > div {
      display: flex;
      width: 220px;
      height: 80px;
      justify-content: flex-end;
      align-items: center;
      padding: 0 8px;
      border-bottom: 2px solid #4b6c57;

      span {
        ${(props) => props.theme.fonts.primary.H1boldCard}
        font-size: 46px;
        line-height: 60px;
        color: #4b6c57;
        opacity: 1;
      }

      svg {
        opacity: 0.6;
        left: 22px;
        top: 72px;
        width: 28px;
        height: auto;
      }
    }
  }

  @media screen and (min-width: 1229px) and (max-width: 1536px) {
    height: 160px;

    & > div:last-child {
      & > div {
        height: 90px;
      }
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1229px) {
    width: 270px;
    height: 130px;

    & > div:last-child {
      & > div {
        width: 166px;
        height: 80px;
        span {
          ${(props) => props.theme.fonts.primary.H1boldCard}
          font-size: 40px;
          line-height: 40px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: 95%;
    height: 8.5rem;
    right: 2.5%;

    & > div:last-child {
      & > div {
        width: 15.8rem;
        height: 5rem;
        padding-right: 0;
        span {
          ${(props) => props.theme.fonts.primary.H1boldCard}
          font-size: 3rem;
        }
        svg {
          left: 26px;
          top: 52px;
        }
      }
      & > svg:last-child {
        width: 5.8rem;
        height: auto;
      }
    }
  }
`;

export const EditButton = styled.button`
  position: absolute;
  border-radius: 0.3rem;
  background: none;
  width: 32px;
  height: 32px;
  display: block;
  top: -1.5rem;
  left: -4rem;
  z-index: 10;
  border: 1px solid #9dc3a1;
  svg {
    display: block;
    margin: auto;
    color: #314d4a;
  }

  :hover {
    border: 1px solid #314d4a;
    filter: brightness(1.2);
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: 767px) {
    top: -3rem;
    left: 0rem;
    width: 2.2rem;
    height: 2.2rem;
    svg {
      width: 2rem;
      height: auto;
    }
  }

  @media screen and (min-width: 1229px) and (max-width: 1536px) {
    top: 1rem;
    left: -3.5rem;
  }

  @media screen and (min-width: 767px) and (max-width: 1229px) {
    top: 1rem;
    left: 1rem;
  }
`;

export const Co2Emission = styled(EditButton)<{ showCo2Info?: boolean }>`
  right: -4rem;
  left: auto;
  border: none;
  display: flex;
  color: #314d4a;
  opacity: 0.75;
  svg {
    width: 1.25rem;
    height: auto;
  }

  span {
    ${(props) => props.theme.fonts.primary.H2Bold}
    position: absolute;
    top: 0rem;
    right: 2.85rem;
  }

  ${({ showCo2Info }) =>
    !showCo2Info &&
    css`
      width: 3rem;
      height: 3rem;
      svg {
        width: 3rem;
        height: auto;
      }
    `}

  &:hover {
    opacity: 1;
    border: none;
    svg,
    span {
    }
    scale: 1.05;
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 767px) {
    top: -3.5rem;
    right: 0.5rem;
    width: 2.2rem;
    height: 2.2rem;
    z-index: 11;
    svg {
      width: 2rem;
      height: auto;
    }
    span {
      ${(props) => props.theme.fonts.primary.H2Bold}
      position: absolute;
      top: 0rem;
      right: 2.05rem;
    }
  }

  @media screen and (min-width: 1229px) and (max-width: 1536px) {
    top: 1rem;
    right: -3rem;
  }

  @media screen and (min-width: 767px) and (max-width: 1229px) {
    top: 1rem;
    left: 1rem;
  }
`;

export const Co2EmissionContent = styled.div<{ showInfo?: boolean }>`
  position: absolute;
  border: 2px solid #4b6c5799;
  border-radius: 6px;
  width: 0;
  background-color: #f6f8ee;
  opacity: 0;
  transition: all 0.3s ease;

  strong,
  p {
    display: none;
  }

  ${({ showInfo }) =>
    showInfo &&
    css`
      width: 12rem;
      opacity: 1;
      right: -4rem;
      top: -1.5rem;
      padding: 1.75rem;
      padding-bottom: 0.25rem;
      strong,
      p {
        display: block;
      }
    `}

  @media screen and (max-width:767px) {
    transition: none;
    ${({ showInfo }) =>
      showInfo &&
      css`
        width: 95%;
        opacity: 1;
        right: 0.5rem;
        top: -3.75rem;
        padding: 0.75rem;
        padding-bottom: 0.25rem;
        z-index: 10;
      `}
  }
`;
