export type ConfirmDeleteFormProps = {
  id?: number | string;
  show: boolean;
  handleClose: () => void;
  handleConfirm: Function;
  context?: string;
  required?: boolean;
  action?: string;
  processing?: boolean;
  isOccurrence?: boolean;
  isActivator?: boolean;
  hideComments?: boolean;
  positionSensorTypeId?: number | string;
  isPosition?: boolean;
  clearValues?: any;
};

export type DeleteModalState = {
  show: boolean;
  id?: number | string;
};

export const closed: DeleteModalState = {
  show: false,
  id: 0,
};
