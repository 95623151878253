import styled from "styled-components";

interface IContainer {
  show?: boolean;
  isVisible?: boolean;
}

export const Container = styled.div<IContainer>`
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  :hover {
    bottom: 0.5rem;
    right: 0.5rem;
    width: 2.75rem;
    height: 2.75rem;
  }

  & > div:first-child {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: ${(props) =>
      props.show ? "transparent" : props.theme?.colors?.ibbxOrange};
    z-index: 2;
    border: 1px solid
      ${(props) =>
        props.show ? "transparent" : props.theme?.colors?.lightOrange};

    clip-path: polygon(
      5% 0%,
      95% 0%,
      100% 5%,
      100% 80%,
      100% 100%,
      70% 85%,
      5% 85%,
      0% 80%,
      0% 5%
    );

    svg {
      margin-top: -0.25rem;
      width: 1.5rem;
      height: auto;
      color: ${(props) => props.theme.colors.white};
    }

    :hover {
      cursor: ${(props) => (props.show ? "default" : "pointer")};
      svg {
        width: 1.75rem;
        height: auto;
      }
    }
  }

  @media screen and (max-width: 767px) {
    bottom: 0.8rem;
    right: 1.2rem;
    height: 3.5rem;
    width: 3.5rem;
  }
`;

export const Chat = styled.div`
  z-index: 15;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 1.5rem;
  bottom: 1rem;
  border-radius: 0.5rem;
  width: 26rem;
  height: 35rem;
  background: ${(props) => props.theme.colors.cardHighlight};
  border: 3px solid ${(props) => props.theme.colors.cardHighlight};

  header {
    ${(props) => props.theme.fonts.primary.H3SemiBold}
    background: ${(props) => props.theme.colors.cardBackground};
    border-bottom: 1px solid ${(props) => props.theme.colors.cardHighlight};
    height: 4rem;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 2rem;
    padding-right: 1.5rem;
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;

      & > label:nth-child(2) {
        ${(props) => props.theme.fonts.secondary.H3Regular};
        font-size: 13px !important;
        color: ${(props) => props.theme.colors.iconsSecondary};
        display: flex;
        align-items: flex-end;
        font-weight: 100;
        margin-left: 0.8rem;
        border-bottom: 1px solid transparent;
      }
    }

    span {
      color: ${(props) => props.theme.colors.iconsPrimary};
      margin-left: 0.8rem;
      :hover {
        cursor: pointer;
      }

      svg {
        color: ${(props) => props.theme.colors.iconsPrimary};
      }
    }
  }

  body {
    border-bottom: 1px solid ${(props) => props.theme.colors.cardBackground};
    width: 100%;
    height: 73%;
    background: ${(props) => props.theme.backgroundColors.background1};
    margin-bottom: 0.8rem;
    padding: 0.8rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px !important;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.colors.background};
      width: 20px;
      border-radius: 100vw;
      margin-block: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }

    &::-webkit-scrollbar-thumb:active {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }
  }

  @media screen and (max-width: 767px) {
    right: -1rem;
    height: 41rem;
    width: 25.5rem;
    bottom: -1rem;
  }
`;

export const PromptContainer = styled.div<{ errorOnSend?: boolean }>`
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.8rem;
  position: relative;

  textarea {
    color: ${(props) => props.theme.colors.iconsPrimary};
    max-height: 90%;
    width: 83%;
    border-radius: 0.4rem;
    background: ${(props) => props.theme.backgroundColors.background1};
    border: none;
    overflow-y: hidden;
    display: flex;
    padding-left: 0.6rem;
    padding-top: 0.7rem;
    resize: none;

    :focus {
      box-shadow: 0 0 0 0;
      border: ${(props) => (props.errorOnSend ? "1px solid red" : "0 none")};
      outline: 0;
    }
  }

  button {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    width: 3rem;
    height: 3rem;
    right: 0.5rem;
    border-radius: 100%;
    border: none;
    background: ${(props) => props.theme?.colors?.ibbxOrange};

    :disabled {
      cursor: not-allowed;
    }
  }
`;

export const Message = styled.div<{ isQuestion?: boolean; isLast?: boolean }>`
  margin-top: 0.8rem;
  display: flex;
  justify-content: ${(props) => (props.isQuestion ? "flex-end" : "flex-start")};

  div {
    border-radius: ${(props) =>
      props.isQuestion
        ? "1.25rem 0 1.25rem 1.25rem"
        : "0 1.25rem 1.25rem 1.25rem "};
    padding: 0.5rem 1rem;
    background: ${(props) =>
      props.isQuestion
        ? props.theme.colors.iconsSecondaryAlpha
        : props.theme.colors.headerCard};
    height: 100%;
    max-width: 75%;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    overflow: hidden;
  }
`;

export const StopGenerating = styled.span`
  position: absolute;

  bottom: 6.4rem;
  right: 5rem;
  z-index: 12;
  border: 1px solid ${(props) => props.theme.colors.headerCard};
  background: ${(props) => props.theme.colors.headerCard};
  padding: 0.15rem;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.iconsSecondary};
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  }

  @media screen and (max-width: 767px) {
    bottom: 6.8rem;
  }
`;

export const Options = styled.div`
  position: absolute;
  top: 16px;
  right: 85px;
  border: none;
  border-radius: 15px;
  padding: 5px;
  background: ${(props) => props.theme.colors.inputBlock};

  svg {
    color: ${(props) => props.theme.colors.iconsSecondary}!important;
  }
`;

export const CloseOptions = styled.span``;

export const DotsAnimation = styled.div`
  display: flex;
  padding: 10px;

  @keyframes jump {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  & > div:nth-child(1) {
    animation: jump 1s -0.48s linear infinite;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: ${(props) => props.theme.colors.ibbxOrange};
  }

  & > div:nth-child(2) {
    animation: jump 1s -0.32s linear infinite;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: ${(props) => props.theme.colors.ibbxOrange};
  }

  & > div:nth-child(3) {
    animation: jump 1s -0.16s linear infinite;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: ${(props) => props.theme.colors.ibbxOrange};
  }
`;

export const NotificationSpan = styled.span`
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background: ${(props) => props.theme.colors.statusRisk};
  top: -0.6rem;
  right: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 9px;
  z-index: 3;

  border: 1px solid white;

  @keyframes oscilate {
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1.2);
    }
  }

  @media screen and (max-width: 767px) {
    z-index: 2;
  }
`;
