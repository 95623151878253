import styled from "styled-components";

export const MarqueeContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  & > div {
    margin: 0 8px;

    background-color: ${(props) => props.theme.colors.divBackground};
    border-radius: 8px;
  }
`;

export const SpeedButtonsContainer = styled.button<{
  hideDashboardCharts?: boolean;
}>`
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  z-index: 2;
  width: 86px;
  height: 86px;
  background-color: ${(props) => props.theme.colors.cardBackground};
  transition: opacity 0.2s;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: ${(props) => props.theme.colors.iconsPrimary};
  box-shadow: inset 9px 5px 35px -10px rgba(0, 0, 0, 0.1);

  svg {
    width: 32px;
    height: auto;
  }

  span {
    ${(props) => props.theme.fonts.primary.H4Regular};
    font-size: 22px;
    margin-bottom: 2px;
  }

  &:hover {
    opacity: 1;
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    background-color: ${(props) => props.theme.colors.divBackgroundAlpha};
    transition: 0.3s;
  }

  &:active {
    filter: brightness(1.1);
    opacity: 1;
  }
`;

export const SpeedContainerLeft = styled(SpeedButtonsContainer)`
  left: 0px;
  flex-direction: column;
  @keyframes slide-left {
    0% {
      transform: translateX(10px);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translateX(-10px);
      opacity: 0;
    }
  }

  svg {
    animation: slide-left 1.2s ease-out infinite both;
  }
`;

export const SpeedContainerRight = styled(SpeedButtonsContainer)`
  right: 0px;
  flex-direction: column;
  @keyframes slide-right {
    0% {
      transform: translateX(-10px);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translateX(10px);
      opacity: 0;
    }
  }

  svg {
    animation: slide-right 1.2s ease-out infinite both;
  }
`;

export const PauseButton = styled(SpeedButtonsContainer)`
  right: 47%;
  @keyframes scale-up-center {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }

  &:hover {
    opacity: 1;
  }

  svg {
    &:hover {
      animation: scale-up-center 0.2s ease-in-out 0.2s both;
    }
  }
`;

export const ChangeBetweenCarousel = styled.button`
  position: absolute;
  opacity: 0;
  bottom: 10%;
  /* right: 50%; */
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  padding: 6px 8px 6px 16px;
  background-color: transparent;
  border-radius: 42px;
  display: flex;
  gap: 4px;
  width: 146px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid transparent;
  box-shadow: inset 9px 5px 35px -10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease-in-out;

  span {
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${(props) => props.theme.colors.iconsPrimary};
    opacity: 0.8;
    margin-right: 8px;
  }

  &:hover {
    opacity: 1;
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    background-color: ${(props) => props.theme.colors.divBackgroundAlpha};
    transition: 0.3s;
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    display: none;
  }
`;

export const ToggleCircle = styled.div<{ switchCarousel?: boolean }>`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.colors.colorTools};
  position: absolute;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  right: ${(props) => (props.switchCarousel ? "10%" : "55%")};
  transition: 0.4s;

  svg {
    color: ${(props) => props.theme.colors.cardUnderlineLight} !important;
  }
`;

export const SwitchToggle = styled.div<{ switchCarousel?: boolean }>`
  position: relative;
  width: 54px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
  background-color: ${(props) => props.theme.colors.cardSectorBackground};
  border-radius: 16px;

  svg {
    color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  }
`;
