import styled, { css } from "styled-components";

export const HeaderTableContainer = styled.div<{
  showSidebar?: boolean;
  customField?: boolean;
  customFieldtoClassName?: string | undefined;
}>`
  grid-area: 2 / 1 / 6 / 6;
  width: 100%;

  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H5SemiBold};
  text-transform: uppercase;
  top: 0;
  height: fit-content;
  z-index: 1;
  position: relative;

  border-bottom: 2px solid ${(props) => props.theme.colors.headerCard};
  background-color: ${(props) => props.theme.colors.headerCardSecondary};
  border-radius: 6px 6px 0 0;

  table {
    height: 3rem;
    width: 100%;
  }

  table > thead {
    display: flex;
    padding-right: 0.5rem;

    & > th {
      padding: 0 0.25rem;
      display: flex;
      height: 100%;
      height: 3rem;
      overflow: hidden;
    }
  }

  ${(props) =>
    props.showSidebar &&
    css`
      grid-area: 2 / 2 / 6 / 6;
    `}

  & > div {
    display: flex;
    align-items: center;
  }

  .occurrence-sysStatusId {
    padding-left: 0.5rem;
    width: 6rem;
  }
  .occurrence-id {
    width: 7rem;
  }
  .occurrence-${(props) => props.customFieldtoClassName} {
    width: 8rem;

    button {
      width: 100%;
    }
    span {
      width: 100%;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .occurrence-createdAt {
    width: 7rem;
  }
  .occurrence-assetName {
    flex: 1;
  }
  .occurrence-evidence {
    width: 15rem;
  }
  .occurrence-diagnostic {
    width: 15rem;
  }
  .occurrence-expiring {
    width: 10rem;
  }

  .occurrence-closed {
    display: none;
  }

  @media screen and (max-width: 767px) {
    width: 100%;

    th {
      position: sticky !important;
    }

    th:first-child {
      width: 25%;
    }
    th:nth-child(2) {
      width: 25%;
    }
    th:nth-child(3) {
      width: 50%;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    padding: 0;
    .occurrence-sysStatusId {
      width: 5rem;
    }
    .occurrence-id {
      width: 6rem;
    }

    .occurrence-createdAt {
      width: 7rem;
    }

    .occurrence-evidence {
      width: 13rem;
    }
    .occurrence-diagnostic {
      width: 10rem;
    }
    .occurrence-expiring {
      width: 8rem;
    }

    .occurrence-closed {
      display: none;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1280px) {
    span {
      width: 100%;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
