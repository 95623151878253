import styled from 'styled-components';

export const BatteryInfoContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-content: center;
  justify-content: center;
  font-size: small;
  font-weight: 800;
  

  label {
    font-weight: 400;
  }
`;