import styled from "styled-components";

export const Container = styled.button<{
  light?: boolean;
  show?: boolean;
  status?: "HIGH" | "OFF";
}>`
  border: none;
  background: transparent;
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  right: 38px;
  margin-top: 13px;
  @keyframes flashlight {
    0%,
    50%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0;
    }
  }

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    background-color: ${(props) => (props.light ? "transparent" : "yellow")};
    filter: ${(props) => (props.light ? "none" : "blur(1px)")};
    border-radius: 20px;
    left: 3px;
    top: 2px;
    opacity: 0.5;

    animation: flashlight 3s infinite both;

    @media screen and (max-width: 767px) {
      top: 3px;
      left: 6px;
    }
  }

  svg {
    /* color: ${(props) =>
      props.light ? props.theme.colors.textPrimary : "yellow"}; */
    color: ${(props) => props.theme.colors.textPrimary};
    animation: flashlight 3s infinite both;

    z-index: 10;

    &:hover {
      animation: ${(props) =>
        props.show ? "none" : "flashlight 3s infinite both"};
    }
  }
  @media screen and (max-width: 1537px) {
    right: 12px;
  }

  @media screen and (max-width: 767px) {
    right: 0rem;
    margin-top: -0.9rem;
    border: 1px solid ${(props) => props.theme.colors.cardBackground};
    display: ${(props) => (props.show ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.backgroundColors.background1};
    border-radius: 10rem;
    width: 1.8rem !important;
    height: 1.8rem;

    svg {
      width: 1.2rem;
      height: auto;
      z-index: 0;
    }
  }
`;

export const ContainerOccurrenceAsset = styled(Container)`
  right: 0;
  margin-top: 0;

  &:before {
    content: "";
    display: none;
  }

  & > svg {
    animation: bulbFlash 5s infinite both;
    color: ${(props) => props.theme.colors.alarmAlerta};
    & > path:last-child {
      stroke: ${(props) => props.theme.colors.insightBulb};
      stroke-width: 1px;
    }
  }

  @keyframes bulbFlash {
    0%,
    50%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0;
    }
  }
`;

export const TooltipContainer = styled.div`
  width: 100px;
  position: absolute;
  top: 0;
  right: 25px;
  color: white;
  font-size: 0.7rem;
  background: black;
  border-radius: 3px;
  padding: 4px;
`;
