import styled, { css } from "styled-components";
import lightTheme from "../../styles/themes/light";

export const Container = styled.div<{
  background?: string;
  error?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 3px;
  margin-bottom: 20px;

  & > label {
    font-size: inherit;
    font-size: 12px;
  }

  span {
    ${(props) => props.theme.fonts.secondary.H3Regular};
    color: red;
  }

  input {
    border-radius: 6px;
    height: 36px;
    &:focus {
      border: 1px solid
        ${(props) => (props.error ? "red" : props.theme.colors.white)} !important;
    }
    margin-bottom: unset;
  }

  .PhoneInput {
    ${({ disabled }) =>
      disabled &&
      css`
        pointer-events: none;
        opacity: 0.7;
      `};

    .PhoneInputInput {
      border: ${(props) => props.error && "1px solid red"};
    }
  }

  input {
    ${(props) => props.theme.fonts.primary.H4Regular};
    width: 100%;
    min-height: 34px;
    color: ${(props) => props.theme.colors.iconsPrimary};
    background: ${(props) => props.theme.colors.background};
    border-radius: 4px;
    padding: 8px;
    border: ${(props) =>
      props.error
        ? "1px solid red !important"
        : "1px solid transparent !important"};

    &:hover {
      border: 1px solid ${(props) => props.theme.colors.IconsTools};
    }

    &:focus-visible {
      outline: none;
      border: 1px solid ${(props) => props.theme.colors.IconsTools};
    }
  }

  & > div {
    width: 100%;

    & > div {
      padding: 0 5px;
      border-radius: 4px;
      background: ${(props) =>
        props.background ? props.background : props.theme.colors.background};
      height: 36px;

      svg {
        color: ${(props) => props.background && lightTheme.colors.textPrimary};
      }
    }
  }
`;
