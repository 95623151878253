import { useRef, useState } from "react";
import { DrawerComponent } from "../../DrawerComponent";
import { Container } from "./styles";
import { useTranslation } from "react-i18next";
import useApi from "../../../hooks/useApi";
import { useParams } from "react-router-dom";
import { FormikProps } from "formik";
import { AnalyticsComponent } from "../AnalyticsComponent";
import { FormikTextInput } from "../../textInput";
import FormSelect from "../../FormSelect";
import { InputListMosaic } from "./InputListMosaic";
import { useAppSelector } from "../../../store/hooks";
import { userSelect } from "../../../store/slices/userSlice";
import { useSegment } from "../../../hooks/useSegmentTrackEvent ";
// import { InputListMosaicV2 } from "../../InputListMosaicV2";

interface IMosaicTab {
  url?: string;
  tabs?: any;
  refresh: any;
  selectPermissionOptions?: any;
  blocked?: any;
  onCloseMenu?: () => void;
}

export function MosaicTab({
  url,
  tabs,
  refresh,
  selectPermissionOptions,
  blocked,
  onCloseMenu,
}: IMosaicTab) {
  const { t } = useTranslation();
  const { request } = useApi({ path: "/tabs" });
  const params = useParams();
  const formRef = useRef<FormikProps<any>>(null);
  const user = useAppSelector(userSelect);

  const { handleTrackEvent } = useSegment();

  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer((prevS) => !prevS);

    handleTrackEvent({
      event: "Menu > Add novo Mosaico",
    });
  };

  const handleConfirm = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current?.click();
    }
  };

  const handleCancel = () => {
    setOpenDrawer(false);
    formRef.current?.setValues({ label: "" });
  };

  const returnLevelToSaveTab = () => {
    if (params.assetId) {
      return "asset";
    }

    if (params.facilityId) {
      return "facility";
    }
    if (params.companyId) {
      return "company";
    }

    return "company";
  };

  const handleSubmit = (values: any) => {
    const id = new Date().getTime();

    const inputValues = {
      id: id,
      key: String(id),
      label: values.label,
      level: returnLevelToSaveTab(),
      table: values.table,
      screens: values.screens,
      isMosaic: true,
      createdBy: user.id,
    };

    request({
      method: "post",
      body: inputValues,
      queryStringParameters: params,
    }).then(() => {
      onCloseMenu && onCloseMenu();
      setOpenDrawer(false);
      refresh();
    });
  };

  const handleSelectChange = (option: any) => {
    formRef.current?.setFieldValue("table", option.value || null);
  };

  return (
    <Container>
      <DrawerComponent
        label={`${t("Analytics.Adicionar Mosaico")}`}
        direction="right"
        isOpen={openDrawer}
        handleOpen={toggleDrawer}
        handleClose={toggleDrawer}
        size={600}
      >
        <AnalyticsComponent>
          <AnalyticsComponent.Title
            title={`${t("Analytics.Adicionar Mosaico")}`}
          />
          <AnalyticsComponent.Beta />

          <AnalyticsComponent.Body>
            <AnalyticsComponent.UserForm
              initialValues={{}}
              isEditableTab
              handleSubmit={handleSubmit}
              formRef={formRef}
              validateForm={(values: any) => {
                const errors: any = {};

                if (!values.label) {
                  errors.label = `${t("Analytics.Preencha o nome da aba")}`;
                }

                return errors;
              }}
            >
              {({ resetForm, errors }: any) => (
                <>
                  <FormikTextInput
                    placeholder={`${t("Analytics.Nome da Aba")}`}
                    name={`label`}
                    label={`${t("Analytics.Como deseja Chamar:")}`}
                    error={errors?.label}
                    disabled={blocked}
                  />

                  <FormSelect
                    handleChange={handleSelectChange}
                    name={"table"}
                    label={`${t("Analytics.Permissão de visualização")}`}
                    options={selectPermissionOptions}
                    placeholder={`${t("Analytics.Selecione")}`}
                    disabled={blocked}
                    notClearable
                  />

                  {/* <InputList
                    name="screens"
                    label="Adicionar quadros"
                    empty={{ title: "", url: "" }}
                    neverEmpty
                    build={buildMosaics}
                  /> */}

                  <InputListMosaic
                    formRef={formRef}
                    name={"screens"}
                    disabled={blocked}
                  />
                  {/* <InputListMosaicV2 formRef={formRef} name={"screens"} /> */}

                  <button
                    className="hidenButton"
                    type="submit"
                    ref={submitButtonRef}
                  />
                  {/* <button
                    onClick={() => resetForm()}
                    className="hidenButton"
                    type="button"
                    ref={submitButtonRef}
                  /> */}
                </>
              )}
            </AnalyticsComponent.UserForm>
          </AnalyticsComponent.Body>
          <AnalyticsComponent.Footer>
            <AnalyticsComponent.CancelButton handleCancel={handleCancel} />
            <AnalyticsComponent.ConfirmButton handleConfirm={handleConfirm} />
          </AnalyticsComponent.Footer>
        </AnalyticsComponent>
      </DrawerComponent>
    </Container>
  );
}
