import { BatteryInfoContainer } from "./style";

interface BatteryInfoProps {
  label: string;
  value: number | string;
}

export function BatteryInfo({ label, value }: BatteryInfoProps) {
  return (
    <BatteryInfoContainer>
      {label}: <label>{value}</label>
    </BatteryInfoContainer>
  );
}
