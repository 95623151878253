import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

type occurencesStatusDataType = {
  total: number;
  alert: number;
  risk: number;
  critical: number;
  switchTotalValues?: boolean;
};
type positionStatusDataType = {
  normal: number;
  alertAnalysis: number;
  alert: number;
  riskAnalysis: number;
  risk: number;
  analysis: number;
  shouldShowAnalysis: boolean;
  critical: number;
};

type iotConnectivityDataType = {
  activatedPositions: number;
  connectedPositions: number;
};

type diagnosticsType = Array<{ diagnostic: string; count: number }>;

type assetTypesType = Array<{ name: string; count: number }>;

type assetRiskRankType = Array<{
  id: number;
  name: string;
  risk: number;
}>;

type storedSensorsType = {
  total: number;
  activated: number;
  connected: number;
};
interface DashboardState {
  hideDashboardCharts: boolean;
  //Companies
  occurrencesStatus?: { data: occurencesStatusDataType; processing: boolean };
  assetsStatus?: { data: positionStatusDataType; processing: boolean };
  positionStatus?: { data: positionStatusDataType; processing: boolean };
  iotConnectivity?: { data: iotConnectivityDataType; processing: boolean };
  assetTypes?: { data: assetTypesType; processing: boolean };
  diagnostics?: { data: diagnosticsType; processing: boolean };
  storedSensors?: { data: storedSensorsType; processing: boolean };
  // Facilities
  assetRiskRank?: { data: assetRiskRankType; processing: boolean };
  occurrencesHistory?: { data: any; processing: boolean };
  //Assets
  assetStops?: { data: any; processing: boolean };
  reliability?: { data: any; processing: boolean };
  electricConsumption?: { data: any; processing: boolean; period: string };
  //Asset
  hourMeter?: { data: any; processing: boolean; period: string };
  riskChart?: { data: any; processing: boolean };
  occurrencesCount?: { data: any; processing: boolean };
  processing: boolean;
}

const initialState: DashboardState = {
  hideDashboardCharts: true,
  processing: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setHideDashboardCharts: (state, action: PayloadAction<boolean>) => {
      state.hideDashboardCharts = action.payload;
    },
    clearDashboards: (state) => {
      state = {
        hideDashboardCharts: state.hideDashboardCharts,
        processing: true,
      };
      // state.occurrencesStatus
      // state.positionStatus
      // state.iotConnectivity
      // state.assetTypes
      // state.diagnostics
      // state.assetRiskRank
      // state.occurrencesHistory
      // state.assetStops
      // state.reliability
      // state.electricConsumption
      // state.hourMeter
      // state.riskChart
    },
    setDashBoards: (state, action: PayloadAction<any>) => {
      state = {
        hideDashboardCharts: state.hideDashboardCharts,
        processing: false,
        ...action.payload,
      };
    },
    setOccurrencesStatus: (state, action) => {
      state.occurrencesStatus = action.payload;
    },
    setAssetsStatus: (state, action) => {
      state.assetsStatus = action.payload;
    },
    setOccurrencesCount: (state, action) => {
      state.occurrencesCount = action.payload;
    },
    setPositionStatus: (state, action) => {
      state.positionStatus = action.payload;
    },
    setIotConnectivity: (state, action) => {
      state.iotConnectivity = action.payload;
    },
    setAssetTypes: (state, action) => {
      state.assetTypes = action.payload;
    },
    setDiagnostics: (state, action) => {
      state.diagnostics = action.payload;
    },
    setAssetRiskRank: (state, action) => {
      state.assetRiskRank = action.payload;
    },
    setOccurrencesHistory: (state, action) => {
      state.occurrencesHistory = action.payload;
    },
    setAssetStops: (state, action) => {
      state.assetStops = action.payload;
    },
    setFacilityReliability: (state, action) => {
      state.reliability = action.payload;
    },
    setElectricConsumption: (state, action) => {
      state.electricConsumption = action.payload;
    },
    changeElectricConsumptionPeriod: (state, action) => {
      state.electricConsumption = {
        data: { data: [] },
        processing: false,
        ...(state.electricConsumption || {}),
        period: action.payload,
      };
    },
    setRiskChart: (state, action) => {
      state.riskChart = action.payload;
    },
    setStoredSensors: (state, action) => {
      state.storedSensors = action.payload;
    },
    setHourMeter: (state, action) => {
      state.hourMeter = action.payload;
    },
    changeHourMeterPeriod: (state, action) => {
      state.hourMeter = {
        data: {},
        processing: false,
        ...(state.hourMeter || {}),
        period: action.payload,
      };
    },
    setProcessing: (state, action) => {
      state.processing = action.payload;
    },
  },
});

export const dashboardSelect = (state: RootState) => state.dashboard;

export const {
  setHideDashboardCharts,
  clearDashboards,
  setDashBoards,
  setOccurrencesStatus,
  setAssetsStatus,
  setOccurrencesCount,
  setPositionStatus,
  setIotConnectivity,
  setAssetTypes,
  setDiagnostics,
  setAssetRiskRank,
  setOccurrencesHistory,
  setAssetStops,
  setFacilityReliability,
  setElectricConsumption,
  setRiskChart,
  setHourMeter,
  setStoredSensors,
  setProcessing,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
