import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useApi from "../../../../hooks/useApi";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { userSelect } from "../../../../store/slices/userSlice";

import {
  Container,
  Footer,
  ContainerOptions,
  SaveButton,
  ContainerOption,
  Checkbox,
} from "./styles";
import { appSelect, setCustomItems } from "../../../../store/slices/appSlice";
import { error, success } from "../../../../components/Toast";

export function MetricUnits() {
  const { id } = useAppSelector(userSelect);
  const { customItems } = useAppSelector(appSelect);
  const { t } = useTranslation();
  const { request, processing } = useApi({ path: `/users/${id}/metric-units` });
  const dispatch = useAppDispatch();

  const [frequencyUnit, setFrequencyUnit] = useState("HZ");

  function handleChangeVibrationMetricUnit(event: any) {
    setFrequencyUnit(event.target.value);
  }

  function handleSubmit() {
    if (!processing) {
      request({
        method: "put",
        body: {
          frequencyUnit,
        },
        skipToast: true,
      })
        .then(() => {
          success(`${t("metricUnits.successMessage")}`);
          dispatch(
            setCustomItems({
              ...customItems,
              frequencyUnit,
            }),
          );
        })
        .catch(() => error(`${t("metricUnits.errorMessage")}`));
    }
  }

  useEffect(() => {
    if (
      customItems?.frequencyUnit &&
      customItems?.frequencyUnit !== frequencyUnit
    ) {
      setFrequencyUnit(customItems?.frequencyUnit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customItems]);

  return (
    <Container>
      <h3>{t("metricUnits.frequencyUnit")}</h3>

      <ContainerOptions onChange={handleChangeVibrationMetricUnit}>
        <ContainerOption onClick={() => setFrequencyUnit("HZ")}>
          <Checkbox
            type="radio"
            name="vibrationMetricUnit"
            value="HZ"
            checked={frequencyUnit === "HZ"}
          />
          <span>Hertz (Hz)</span>
        </ContainerOption>
        <ContainerOption onClick={() => setFrequencyUnit("CPM")}>
          <Checkbox
            type="radio"
            name="vibrationMetricUnit"
            value="CPM"
            checked={frequencyUnit === "CPM"}
          />
          <span>{`${t("metricUnits.labelCPM")} (cpm)`}</span>
        </ContainerOption>
      </ContainerOptions>
      <Footer>
        <SaveButton
          type="button"
          onClick={() => handleSubmit()}
          disabled={processing}
        >
          {t(processing ? "commonText.Saving" : "CustomNavbarIcons.save")}
        </SaveButton>
      </Footer>
    </Container>
  );
}
