import React, { useEffect, useState, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import useApi from "../../hooks/useApi";
import { Loading } from "../Loading";

import {
  Container,
  ContainerFilter,
  ContainerList,
  EmptyPositions,
} from "./styles";
import CardSensor from "../CardSensor";
import { SensorListProps } from "./types";
import { MdClose, MdOutlineSyncDisabled, MdSync } from "react-icons/md";
import { RiDeviceLine } from "react-icons/ri";
import CountUp from "react-countup";
import { TbDatabaseOff } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const SensorList = ({
  filteredBolt,
  handleClearFilteredBolt,
}: SensorListProps) => {
  const params = useParams();

  const { processing, request } = useApi({
    path: `/gateways-positions`,
  });

  const [pagination, setPagination] = useState<any>({});
  const [positions, setPositions] = useState<any>([]);
  const [filter, setFilter] = useState<any>("SYNCHRONIZED");

  const { t } = useTranslation();

  useEffect(() => {
    getPositions({ page: 1, noProcessing: false });

    const interval = setInterval(
      () => getPositions({ page: 1, noProcessing: true }),
      15 * 1000,
    );

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, filteredBolt]);

  const getTxTime = (position?: any) => {
    try {
      const { parameters } = position?.bolt;

      const positionSchedule = position.schedule.schedulePosition;

      const globalTxWindow = 5 * 1000; // Duração de transmissão via RF entre sensor e bolt ( fica enviando para o bolt)
      const dynamicTxWindow = 180 * 1000; // 3 min dinamica
      const configWindow = (parameters?.configWindow || 600) * 1000; //ou é customizado ou é travado em 10min
      const radioWindowInterval =
        (parameters?.radioWindowInterval || 3600) * 1000; //tempo total de transmissão de um ciclo : De quanto em quanto tempo vai chegar globais
      const radioWindowNumBoardSlots =
        parameters?.radioWindowNumBoardSlots || 240; //

      const date = new Date().getTime();

      const globalWindow = radioWindowNumBoardSlots * globalTxWindow; // janela que vai ser enviado global de todos os sensores cadastrados no bolt

      const numBoardsPerCycle =
        (radioWindowInterval - globalWindow - configWindow) / dynamicTxWindow;

      const numCycles = radioWindowNumBoardSlots / numBoardsPerCycle;

      const txTotalCycles = radioWindowInterval * numCycles;

      const startWindow = new Date(
        Math.floor(date / txTotalCycles) * txTotalCycles,
      ).getTime();

      const endWindow = new Date(
        Math.ceil(date / txTotalCycles) * txTotalCycles,
      ).getTime();

      const startCycle = new Date(
        Math.floor(date / radioWindowInterval) * radioWindowInterval,
      ).getTime();

      const endCycle = new Date(
        Math.ceil(date / radioWindowInterval) * radioWindowInterval,
      ).getTime();

      const dynamicPos = Math.floor(positionSchedule / numBoardsPerCycle);
      const dynamicPosMod = positionSchedule % numBoardsPerCycle;

      let TxGlobal = startCycle + positionSchedule * globalTxWindow;

      if (TxGlobal < new Date().getTime()) {
        TxGlobal = endCycle + positionSchedule * globalTxWindow;
      }

      let TxDynamic =
        startWindow +
        dynamicPos * radioWindowInterval +
        dynamicPosMod * dynamicTxWindow +
        globalWindow +
        configWindow;

      if (TxDynamic < new Date().getTime()) {
        TxDynamic =
          endWindow +
          dynamicPos * radioWindowInterval +
          dynamicPosMod * dynamicTxWindow +
          globalWindow +
          configWindow;
      }

      const txTimeArray = [];

      txTimeArray.push({
        date: TxGlobal,
        label: "G",
      });

      txTimeArray.push({
        date: TxDynamic,
        label: "D",
      });

      const sortedArray = txTimeArray.sort((a, b) => a.date - b.date);
      return sortedArray;
    } catch {
      return [];
    }
  };

  const paginationLimit = window.innerWidth >= 1921 ? 50 : 35;

  const getPositions = useCallback(
    ({ page = 1, limit = paginationLimit, noProcessing = true }: any) => {
      const queryStringParameters: any = { ...params, page, limit };

      if (filter) {
        queryStringParameters.filter = filter;
      }

      if (filteredBolt) {
        queryStringParameters.gatewayId = filteredBolt.gatewayId;
      }

      request({
        method: "get",
        queryStringParameters,
        noProcessing,
        skipToast: true,
      }).then((response) => {
        setPagination(response?.pagination || {});
        setPositions(
          page === 1
            ? response?.data || []
            : [...positions, ...(response.data || [])],
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request, positions, filter, filteredBolt],
  );

  const renderLoadPagination =
    window.innerWidth >= 1921 ? positions.length > 50 : positions.length > 35;

  const renderList = () => {
    if (positions.length === 0) {
      return (
        <EmptyPositions>
          <TbDatabaseOff size={60} />
          <span>{t("SensorList.noSensor")}</span>
        </EmptyPositions>
      );
    }

    return positions.map((position: any) => (
      <CardSensor
        key={position.positionId}
        position={position}
        txTime={getTxTime(position)}
      />
    ));
  };

  return (
    <Container>
      <ContainerFilter disabled={!!filteredBolt}>
        <div>
          <button
            onClick={() => !filteredBolt && setFilter("SYNCHRONIZED")}
            disabled={!!filteredBolt}
          >
            {!!filteredBolt ||
            (filter.includes("SYNCHRONIZED") &&
              !filter.includes("NOT_SYNCHRONIZED")) ? (
              <>
                <MdSync size={20} color={"#FF9B30"} />
                <span style={{ color: "#FF9B30" }}>
                  {t("SensorList.synchronized")}
                </span>
              </>
            ) : (
              <>
                <MdSync size={20} />
                <span>{t("SensorList.synchronized")}</span>
              </>
            )}
          </button>
          <button
            onClick={() => !filteredBolt && setFilter("NOT_SYNCHRONIZED")}
            disabled={!!filteredBolt}
          >
            {!filteredBolt && filter.includes("NOT_SYNCHRONIZED") ? (
              <>
                <MdOutlineSyncDisabled size={20} color={"#FF9B30"} />
                <span style={{ color: "#FF9B30" }}>
                  {t("SensorList.unSynchronized")}
                </span>
              </>
            ) : (
              <>
                <MdOutlineSyncDisabled size={20} />
                <span>{t("SensorList.unSynchronized")}</span>
              </>
            )}
          </button>
          <button
            onClick={() => !filteredBolt && setFilter("")}
            disabled={!!filteredBolt}
          >
            {!filteredBolt &&
            filter.includes("") &&
            !filter.includes("SYNCHRONIZED") ? (
              <span style={{ color: "#FF9B30" }}>{t("SensorList.all")}</span>
            ) : (
              <span>{t("SensorList.all")}</span>
            )}
          </button>
          <div>
            <RiDeviceLine size={20} />
            <span>
              <CountUp
                start={pagination?.totalResults || 0}
                end={pagination?.totalResults}
                duration={0.8}
                separator={"."}
              />
            </span>
            <span>&nbsp;Sensor{+pagination?.totalResults !== 1 && "es"}</span>
          </div>
          {filteredBolt && (
            <button onClick={() => handleClearFilteredBolt()}>
              <span>{filteredBolt.name}</span>

              <MdClose size={16} />
            </button>
          )}
        </div>
      </ContainerFilter>
      <ContainerList id="scrollableDiv">
        <InfiniteScroll
          next={() => getPositions({ page: +pagination.page + 1 })}
          dataLength={positions.length}
          hasMore={+pagination.page < +pagination.totalPages}
          loader={renderLoadPagination && +pagination.page > 1 && <Loading />}
          scrollableTarget="scrollableDiv"
        >
          {processing ? <Loading height="100%" /> : renderList()}
        </InfiniteScroll>
      </ContainerList>
    </Container>
  );
};

export default SensorList;
