import { useEffect, useMemo, useState } from "react";
import { RetinaMapsEmbbeded } from "../RetinaMapsEmbbeded";
import {
  TabContainer,
  TabContent,
  TabNameDiv,
  TabName,
  TabIcon,
  TabDescription,
  TabErrorBall,
} from "./styles";
import { TabProps, TabsProps } from "./types";
import { useLocation } from "react-router-dom";
import queryString from "../../utils/queryString";
import { isIOS } from "react-device-detect";

export const TabsNavigation = ({
  children,
  handleChangeTab,
  currentTab,
  adjustHeight,
  invisible,
  marginLeft,
  facilityLevel,
  companyLevel,
  alterSpace,
}: TabsProps) => {
  const tabChildrens = Array.isArray(children) ? children : [children];
  const tabsControl = tabChildrens.map(({ key, props }) => ({
    key,
    label: props.label,
    hidden: props.hidden,
    icon: props.icon,
    errors: props.errors || 0,
  }));
  const selectedTab = currentTab || tabChildrens[0].key;

  const [showHopara, setShowHopara] = useState<boolean>(false);
  const [showHoparaConnectivity, setShowHoparaConnectivity] =
    useState<boolean>(false);

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const isConnectivity = useMemo(() => {
    if (queryParams?.sidetab && queryParams?.sidetab === "bolt") {
      return true;
    }
    return false;
  }, [queryParams]);

  useEffect(() => {
    if (currentTab === "MAPS" && !isConnectivity) {
      setShowHopara(true);
      setShowHoparaConnectivity(false);
    } else if (currentTab === "MAPS" && isConnectivity) {
      setShowHopara(false);
      setShowHoparaConnectivity(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, isConnectivity]);

  return (
    <>
      {!invisible && (
        <TabContainer
          facilityLevel={facilityLevel}
          companyLevel={companyLevel}
          iosDevices={isIOS}
          alterSpace={alterSpace}
        >
          {tabsControl.map(
            (item) =>
              !item.hidden && (
                <TabNameDiv
                  alterSpace={alterSpace}
                  selected={item.key === selectedTab}
                  key={item.key}
                  onClick={() => {
                    if (handleChangeTab) {
                      handleChangeTab(item.key as string);
                    }
                  }}
                >
                  <TabDescription selected={item.key === selectedTab}>
                    <TabIcon>{item.icon}</TabIcon>
                    <TabName selected={item.key === selectedTab}>
                      <span>{item.label}</span>
                      {/* <Selection selected={item.key === selectedTab} /> */}
                      {item.errors ? (
                        <TabErrorBall>{item.errors}</TabErrorBall>
                      ) : (
                        <></>
                      )}
                    </TabName>
                  </TabDescription>
                </TabNameDiv>
              ),
          )}
        </TabContainer>
      )}
      <TabContent adjustHeight={adjustHeight || "70%"}>
        {selectedTab !== "MAPS" &&
          tabChildrens.map((tab) => tab.key === selectedTab && tab)}
        {showHopara && !isConnectivity && (
          <RetinaMapsEmbbeded hide={selectedTab !== "MAPS"} />
        )}
        {showHoparaConnectivity && isConnectivity && (
          <RetinaMapsEmbbeded
            hide={selectedTab !== "MAPS"}
            isConnectivity={true}
          />
        )}
      </TabContent>
    </>
  );
};

export const Tab = ({ children }: TabProps) => {
  return children;
};
