import { useEffect, useMemo } from "react";
import { ISerieControl } from "../../../hooks/useSeriesControl";
import { IChart } from "../../../store/interfaces";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useAppSelector } from "../../../store/hooks";
import { appSelect } from "../../../store/slices/appSlice";
import { getSerieName, setTooltips } from "../../../utils/charts/general";

type useGoogleChartTooltipsParams = {
  dataRaw: any[];
  chart: IChart;
  seriesControl: ISerieControl[];
  customTooltipBySerie?: any[];
  extraTooltipInfos?: extraTooltipInfo[];
};

function getMetricUnit(unit: string | string[], index?: number): string {
  return typeof unit === "string" ? unit : unit[index || 0];
}

type buildCartesianAxisXTooltipParams = {
  value: any;
  chart: IChart;
  dateFormat?: any;
};

type buildCartesianAxisYTooltipParams = {
  value: any;
  chart: IChart;
  serie: any;
  customValueBuilder?: (value: any) => any;
  tooltipConfig?: any;
};

type extraTooltipInfo = {
  serieIndex: number;
  label?: string;
  labels?: string[];
  values: any[];
};

type buildAllExtraTooltipInfosInSerieParams = {
  extraTooltipInfos: extraTooltipInfo[] | undefined;
  serieIndex: number;
  rowIndex: number;
};

type buildTooltipParams = {
  dataRow: any[];
  chart: IChart;
  serie: any;
  customValueBuilder?: (value: any) => any;
  dateFormat?: any;
  extraTooltipInfos?: extraTooltipInfo[];
  serieIndex: number;
  rowIndex: number;
};

export default function useGoogleChartTooltips({
  chart,
  dataRaw,
  seriesControl,
  extraTooltipInfos = [],
}: useGoogleChartTooltipsParams) {
  const { customItems } = useAppSelector(appSelect);
  const { t } = useTranslation();

  function getCartesianAxisXLabel(chart: IChart) {
    if (chart.type === "DYNAMIC" && chart.metricUnits.x === "s") {
      return `${t("commonText.Time")}: `;
    }

    if (chart.type === "DYNAMIC") {
      return `${t("commonText.Frequency")}: `;
    }

    return "";
  }

  function getCartesianAxisXValue(value: any, chart: IChart, dateFormat?: any) {
    const unit = chart.metricUnits.x;

    if (chart.type === "DYNAMIC") {
      return `${value} ${unit}`;
    }

    dateFormat =
      dateFormat ||
      `'${t("chartSeries.Data")}: 'dd/MM/yyyy '\n' '${t(
        "chartSeries.Hora",
      )}: 'HH:mm`;

    return format(value, dateFormat);
  }

  function buildCartesianAxisXTooltip({
    value,
    chart,
    dateFormat,
  }: buildCartesianAxisXTooltipParams) {
    const label = getCartesianAxisXLabel(chart);
    const valueWithUnit = getCartesianAxisXValue(value, chart, dateFormat);
    return `<p>${label}${valueWithUnit}</p>`;
  }

  function roundDecimal(value: any, decimals: number = 2) {
    if (!value?.toString()) {
      return null;
    }

    try {
      if (typeof value === "string") {
        value = +value;
      }
      return +value.toFixed(decimals);
    } catch (err) {
      console.error(`${value} of type ${typeof value}, Failed to Round`);
    }
  }

  function buildCartesianAxisYTooltip({
    value,
    serie,
    chart,
    customValueBuilder,
    tooltipConfig,
  }: buildCartesianAxisYTooltipParams) {
    if (customValueBuilder) {
      return `<p>${customValueBuilder(value)}</p>`;
    }

    let unit = chart.metricUnits.y;

    if (chart.type === "DYNAMIC") {
      return `<p>${t("commonText.Amplitude")}: ${value} ${unit}</p>`;
    }

    const serieName = t(getSerieName(serie, chart));
    const roundedValue = roundDecimal(value, tooltipConfig?.decimals || 2);
    return `<p> ${`${serieName}: ${roundedValue} ${unit}`} </p>`;
  }

  function buildAllExtraTooltipInfosInSerie({
    extraTooltipInfos = [],
    serieIndex,
    rowIndex,
  }: buildAllExtraTooltipInfosInSerieParams) {
    const serieTooltipsInfo = extraTooltipInfos.filter(
      (item) => item.serieIndex === serieIndex,
    );

    return serieTooltipsInfo.map((item: any) => {
      const label = item.labels ? item.labels[rowIndex] : item.label || "";
      const value = item.values[rowIndex];

      if (!label && item.values?.length) {
        return `<p>${item.values[rowIndex]}</p>`;
      }

      if (label && item.values?.length) {
        return `<p>${label}: ${value}</p>`;
      }

      return "";
    });
  }

  function buildTooltip({
    dataRow,
    chart,
    serie,
    serieIndex,
    customValueBuilder,
    dateFormat,
    rowIndex,
    extraTooltipInfos,
  }: buildTooltipParams) {
    return `
    <div style="padding: 6px">
      ${buildCartesianAxisXTooltip({ chart, value: dataRow[0], dateFormat })}
      <hr/>
      ${buildCartesianAxisYTooltip({
        chart,
        serie,
        value: dataRow[serieIndex + 1],
        customValueBuilder,
      })}
      ${buildAllExtraTooltipInfosInSerie({
        extraTooltipInfos,
        serieIndex,
        rowIndex,
      })}
      </div>
    `;
  }

  const seriesWithTooltip = useMemo(() => {
    return seriesControl.reduce((acc: any, item) => {
      const { name, alarm } = item;
      if (!alarm) {
        return {
          ...acc,
          [name]: { type: "string", role: "tooltip", p: { html: true } },
        };
      } else {
        return acc;
      }
    }, {});
    // eslint-disable-next-line
  }, []);

  const tooltipsForData = useMemo(() => {
    return [seriesWithTooltip].concat(
      dataRaw.map((row: any[], rowIndex: number) => {
        const rowSeries: any = {};

        row.forEach((value: any, columnIndex: number) => {
          if (columnIndex === 0) {
            return;
          }

          const serieIndex = columnIndex - 1;
          const serieControl = seriesControl[serieIndex];

          if (serieControl.alarm) {
            return;
          }

          if (!value?.toString()) {
            rowSeries[serieControl.name] = null;
          }

          rowSeries[serieControl.name] = buildTooltip({
            chart,
            dataRow: row,
            serie: serieControl.name,
            serieIndex: serieIndex,
            rowIndex: rowIndex,
            extraTooltipInfos: extraTooltipInfos,
          });
        });

        return rowSeries;
      }),
    );
    // eslint-disable-next-line
  }, [dataRaw]);

  function mergeTooltipWithData(data: any[]) {
    const result = data.map((initialRow: any[], indexRow: number) => {
      const tooltip = tooltipsForData[indexRow];

      const row = (initialRow || []).reduce(
        (acc: any[], value: any, index: number) => {
          acc.push(value);
          const tooltipsAsArray = Object.values(tooltip);
          const hasTooltip = index !== 0 && index - 1 < tooltipsAsArray.length;

          if (hasTooltip) {
            acc.push(value === null ? null : tooltipsAsArray[index - 1]);
          }
          return acc;
        },
        [],
      );

      return row;
    });

    return result;
  }

  return { tooltipsForData, mergeTooltipWithData };
}
