import styled from "styled-components";

export const Container = styled.div`
  & input {
    display: none;
  }

  & > span {
    width: 100%;
    text-align: left;
    ${(props) => props.theme.fonts.primary.H1Semibold};
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media screen and (max-width: 767px) {
    & > span {
      ${(props) => props.theme.fonts.primary.H2Bold};
    }
    padding: 0;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    & > span {
      font-size: 1.5rem;
    }
  }
`;

export const ImageContainer = styled.div<{ isPdf?: boolean }>`
  width: 100%;
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  overflow: hidden;
  div > div {
    cursor: ${({ isPdf }) => (isPdf ? "pointer" : "zoom-in")};
    &:active {
      cursor: grab;
    }
  }
  label {
    ${(props) => props.theme.fonts.primary.H4Regular};
    color: ${(props) => props.theme.colors.iconsSecondary};
    padding: 0 0 0.5rem 0.2rem;
  }
  img {
    max-height: 410px;
    height: 400px;
    max-width: 100%;
  }

  @media screen and (max-width: 767px) {
    height: auto;

    img {
      height: 18rem;
      width: 22rem;
    }
  }

  .pdf {
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    height: 25rem;
    margin: 0.5rem 0;
    position: relative;
  }
`;

export const EmptyImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > span {
    margin-top: 20px;
    color: ${(props) => props.theme.colors3.color0};
    ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  }
`;

export const GalleryContainer = styled.div<{ isEvidence?: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  height: ${({ isEvidence }) => (isEvidence || true ? "150px" : "125px")};
  overflow-x: auto;
  overflow-y: hidden;
  background-color: ${(props) => props.theme.colors.headerBackground};
  border-radius: 6px;
  padding: 13px 8px;

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    padding: 0.5rem;
  }
`;

export const AddImageButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;

  padding: 20px;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;

  background-color: transparent;

  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};

  & > span {
    margin-top: 10px;
  }

  @media screen and (max-width: 767px) {
    & > span {
      display: none;
    }
    padding: 0.5rem;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    span {
      font-size: 0.65rem;
    }
  }
`;

export const ButtonImage = styled.button<{ selected?: boolean }>`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: ${(props) => props.theme.colors.headerBackground};
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  border-radius: 5px;

  ${(props) =>
    props.selected && `border: 2px solid ${props.theme.colors.ibbxOrange}`};

  position: relative;

  & > svg {
    font-size: 4rem;
    color: ${({ theme }) => theme.colors.iconsSecondary};
  }
  & > img {
    width: 99px;
    max-height: 99px;
    margin: auto;
    border: 2px solid transparent;
    border-radius: 5px;
  }

  label {
    ${(props) => props.theme.fonts.primary.H4Bold};
    /* position: absolute;
    top: -20px; */
    color: ${(props) => props.theme.colors.iconsPrimary};
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70%;
  }
`;

export const CoverLabel = styled.div<{ favorite?: boolean }>`
  display: ${(props) => !props.favorite && "none"};
  color: ${(props) => props.theme.colors3.color0};
  padding: 5px;

  position: absolute;
  top: 0;
  right: 0;

  svg {
    font-size: 20px;
    color: ${(props) => props.theme.colors.ibbxOrange};
  }
`;

export const ImageActions = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 10px;

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 40px;
    padding: 5px 15px;
    ${(props) => props.theme.fonts.primary.H4Regular};
    background-color: ${(props) => props.theme.colors.cardBackground};
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    color: ${(props) => props.theme.colors.iconsPrimary};

    &:hover {
      border: 1px solid transparent;
    }
  }

  @media screen and (max-width: 767px) {
    & > button {
      & > span {
        display: none;
      }
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    margin-bottom: 0;

    & > button {
      padding: 0.5rem;

      span {
        font-size: 0.75rem;
      }
    }
  }
`;

export const PrincipalImageButton = styled.button<{ disabled?: boolean }>`
  :hover {
    border: ${({ disabled }) => disabled && "1px solid red"}!important;
    opacity: ${({ disabled }) => disabled && "0.5"};
  }
`;

export const ImageGalleryFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  padding: 0 8px;

  & > button:nth-child(2) {
    height: 40px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 1.5rem;
    gap: 1rem;

    & > button:nth-child(2) {
      min-width: 4rem !important;
      border: 1px solid ${(props) => props.theme.colors.cardHighlight};
      margin: 0;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    margin-top: 1rem;
    padding: 0;
  }
`;

export const GalleryTools = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin-bottom: 16px;

  button {
    height: 40px;
    width: 40px;
    display: flex;
    border-radius: 100%;
    background-color: ${(props) => props.theme.colors.cardBackground};
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    &:hover {
      border: none;
    }
    svg {
      margin: auto;
      font-size: 20px;
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    position: absolute;
    top: 1rem;
    right: 1rem;

    button {
      height: 2rem;
      width: 2rem;

      svg {
        font-size: 1rem;
      }
    }
  }
`;

export const GalleryPdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  cursor: pointer;

  & > svg {
    font-size: 4rem;
    color: ${({ theme }) => theme.colors.iconsSecondary};
  }

  span {
    ${({ theme }) => theme.fonts.primary.H2Bold};
    color: ${({ theme }) => theme.colors.iconsSecondary};
  }
`;

export const AutomaticEvidence = styled.div`
  position: absolute;
  top: -10px;
  right: 0;
  color: ${(props) => props.theme.colors.ibbxOrange};
  background: ${(props) => props.theme.colors.headerBackground};

  :hover {
    filter: brightness(1.4);
  }
`;

export const TooltipContainer = styled.div`
  background: ${(props) => props.theme.backgroundColors.background0};
  color: ${(props) => props.theme.colors.iconsPrimary};
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
`;

export const ContainerEvidences = styled.div`
  label {
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${(props) => props.theme.colors.iconsPrimary};
    border-bottom: 1px solid
      ${(props) => props.theme.colors.iconsSecondaryAlpha};
    opacity: 0.7;
    font-weight: 400;
    border-radius: 4px;
    padding: 3px 0 3px 8px;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
