import styled from "styled-components";
import { ButtonCancel, ButtonSave } from "../../../../components/Modal/styles";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 40px;

  input {
    margin-bottom: 6px;
    :disabled {
      background: ${(props) => props.theme.backgroundColors.background1};
      :hover {
        border: 1px solid ${(props) => props.theme.colors.cardHighlight};
      }
    }
  }

  & > h2 {
    margin-bottom: 20px;
    margin-top: 20px;
    ${(props) => props.theme.fonts.primary.H2Bold};
    color: ${(props) => props.theme.colors.iconsPrimary};
  }
  h2 {
    margin-right: auto;
  }

  form {
    margin-top: 24px;
    width: 60%;
    align-self: center;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 16px;
  @media screen and (max-width: 767px) {
    justify-content: center;
    margin-top: 2.5rem;
  } ;
`;

export const SaveButton = styled(ButtonSave)`
  height: 2.5rem;
`;

export const CancelButton = styled(ButtonCancel)`
  height: 2.5rem;
`;
