import webhook from "webhook-discord";

export const notifyDiscordError = async ({ title, description }: any) => {
  if (process.env.REACT_APP_IS_OFFLINE) return;
  
  try {
    const Hook = new webhook.Webhook(
      `${process.env.REACT_APP_DISCORD_NOTIFY_ERROR}`,
    );

    return Hook.err(title, description);
  } catch (e: any) {
    console.error(`[notifyDiscord] - ${e.stack}`);
  }
};
