import styled from "styled-components";

export const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  label {
    ${(props) => props.theme.fonts.primary.H4Regular}
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`;

export const Input = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 1rem;
  height: 1rem;
  border: 2px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  border-radius: 100%;
  outline: none;
  background-color: ${(props) => props.theme.colors.lightBackground};
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: ${(props) => props.theme.colors.ibbxOrange};
    border: 2px solid ${(props) => props.theme.colors.lightOrange};
    transition: all 0.2s ease;
  }

  &:before {
    content: "";
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid white;
    border-top: none;
    border-right: none;
    background: white;
    border-radius: 100%;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:checked:before {
    opacity: 0.75;
  }
`;
