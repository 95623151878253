import queryString from "../utils/queryString";
import { useCallback, useMemo, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import useApi from "./useApi";
import { useAppSelector } from "../store/hooks";
import { userSelect } from "../store/slices/userSlice";

export default function useOccurrenceList() {
  const [records, setRecords] = useState<any>([]);
  const [pagination, setPagination] = useState<any>(null);
  const location = useLocation();
  const params = useParams();
  const user = useAppSelector(userSelect);

  const { request, processing } = useApi({ path: "/asset-occurrences" });

  const queryParams = useMemo(() => {
    let result = queryString.parse(location.search);
    delete result.tab;
    return result;
  }, [location.search]);

  function getRecords(nextPage?: number) {
    const queryStringParameters = {
      page: nextPage || 1,
      limit: 20,
      ...queryParams,
      ...params,
    } as any;

    let openedBy: any = localStorage.getItem("occurrenceAssetOpenedByFilter");
    openedBy = openedBy ? JSON.parse(openedBy) : "";

    if (!nextPage && openedBy && user.ibbx) {
      queryStringParameters.openedBy = openedBy.value;
    }

    request({
      method: "get",
      queryStringParameters,
    })
      .then((response: any) => {
        const { data, pagination } = response;

        if (!!pagination) {
          setPagination(pagination);
          setRecords((records: any) => {
            if (nextPage) {
              return [...records, ...data];
            } else {
              return data;
            }
          });
        } else {
          setRecords(response);
        }
      })
      .finally(() => {
        // setProcessing(false);
      });
  }

  const handleGetRecords = useCallback(() => {
    getRecords(pagination?.page + 1);
    // eslint-disable-next-line
  }, [pagination]);

  useEffect(() => {
    setRecords([]);
    getRecords();
    // eslint-disable-next-line
  }, [location.search, params?.positionId]);

  return { handleGetRecords, getRecords, processing, records, pagination };
}
