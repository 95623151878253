import Switch from "react-switch";
import { FaMoon, FaSun } from "react-icons/fa";
import { useState } from "react";

import { Container, ContainerOutside, Moon, SunDark, SunLight } from "./styles";

interface SwitchChangeProps {
  handleChange: (value: boolean) => void;
  darkMode: boolean;
}

export const SwitchChange = (props: SwitchChangeProps) => {
  const [checked, setChecked] = useState(props.darkMode);

  const handleOnChange = (value: boolean) => {
    setChecked(value);
    props.handleChange(value);
  };

  return (
    <ContainerOutside>
      <Container htmlFor="small-radius-switch">
        <label>{checked ? 'Light mode' : 'Dark mode'}</label>
        <Switch
          checked={checked}
          onChange={handleOnChange}
          handleDiameter={20}
          offColor="#252A3C"
          onColor="#E0E9F0"
          offHandleColor="#656A85"
          onHandleColor="#656A85"
          height={26}
          width={54}
          borderRadius={20}
          activeBoxShadow="0px 0px 1px 1px #3A4058"
          uncheckedIcon={
            <SunDark>
              <FaSun/>
            </SunDark>
          }
          checkedIcon={
            <SunLight>
              <FaMoon />
            </SunLight>
          }
          uncheckedHandleIcon={
            <Moon>
              <FaMoon />
            </Moon>
          }
          checkedHandleIcon={
            <Moon>
              <FaSun />
            </Moon>
          }
          className="react-switch"
          id="small-radius-switch"
        />
      </Container>
    </ ContainerOutside>
  );
};
