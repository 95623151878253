import { error as errorToast } from "../components/Toast";
import { IRequest } from "../hooks/useApi";

interface Credentials {
  email: string;
  password: string;
  url: string;
  setAnalyticsUrl: (url: string) => void;
  request: ({ method, pathParameters, body }: IRequest) => Promise<any>;
}

export const transparentAuthenticate = async (credentials: Credentials) => {
  const { setAnalyticsUrl, email, password, request, url } = credentials;

  const cleanedUrl = url.replace(/^https?:\/\//, "");
  const storedData = localStorage.getItem(cleanedUrl);
  const now = Math.floor(Date.now() / 1000);

  if (storedData) {
    const { exp, token } = JSON.parse(storedData);

    if (exp !== null && exp >= now) {
      setAnalyticsUrl(
        `${url}?${url.includes("gamaya") ? "token" : "access_token"
        }=${token}`,
      );

      return;
    }
  }

  const bodyCredentials = {
    email,
    password,
    url
  };

  try {
    const { token, exp } = await request({
      method: "post",
      pathParameters: "transparent-login",
      body: bodyCredentials,
    });

    if (exp !== null) {
      localStorage.setItem(cleanedUrl, JSON.stringify({ token, exp }));
    }

    let analyticsUrl = `${url}?${url.includes("gamaya") ? "token" : "access_token"
      }=${token}`

    const urlLogoGamaya = encodeURIComponent("https://prod-ibbx-retina-images.s3.amazonaws.com/logos/logoatvos.jpg");

    if (url.includes("gamaya")) {
      analyticsUrl += `&logo=${urlLogoGamaya}`;
    }

    setAnalyticsUrl(
      analyticsUrl
    );

  } catch (error: any) {
    errorToast(error);
  }
};