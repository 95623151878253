import styled from "styled-components";

type elipseTypes = {
  width: string;
  height: string;
  opacity: string;
  left: string;
  top: string;
};

export const HubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #151820;
  color: #adb2cf;
  overflow: hidden;
`;

export const Header = styled.header`
  width: 100%;
  height: 74px;
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1e222f;
  position: relative;

  a {
    position: absolute;
    right: 1rem;
    color: #adb2cf;
  }
`;

export const BetaBedge = styled.div`
  width: fit-content;
  display: flex;
  justify-self: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  height: 2rem;
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.background};
  background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  ${(props) => props.theme.fonts.primary.H4Regular}
  position: absolute;
  top: 1.25rem;
  left: 2rem;
`;

export const Section = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.5rem 2rem;
  gap: 0.25rem;
  color: #dddfed;
  ${(props) => props.theme.fonts.primary.H2Bold};
  border-bottom: 2px solid #3a405860;

  svg {
    width: 1.5rem;
    height: auto;
  }
`;

export const BodyContent = styled.body`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  width: 100%;
  height: 100%;
  gap: 2rem;
  padding: 1rem 2rem 2rem;
  color: #adb2cf;
  background-color: transparent;
  overflow: auto;
  z-index: 1;
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const Footer = styled.footer`
  width: 100%;
  padding: 0.75rem;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: white;
  text-align: center;
  background-color: #1e222f;

  .aws-logo {
    width: auto;
    height: 1.75rem;
  }
`;

export const Elipse = styled.div<elipseTypes>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  background: #f37035;
  opacity: ${(props) => props.opacity};
  filter: blur(164px);
  transform: rotate(25.53deg);
  position: absolute;
  z-index: 0;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;
