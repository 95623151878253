import { useField, useFormikContext } from "formik";
import { Fragment } from "react";
import { FormCheck } from "react-bootstrap";
import { FormikTextInput } from "../textInput";
import {
  InputRadioCheckContainer,
  InputRadioContainer,
  InputRadioOthersWrap,
  LabelContainer,
  RadioGroup,
} from "./styles";
import { InputRadioProps } from "./types";
import { useTranslation } from "react-i18next";
import InfoButton from "../../modals/PositionForm/InputRender/InfoButton";

export const InputRadio = ({
  name = "",
  nameChecked = "",
  label,
  options,
  boolean,
  others,
  error,
  yesLabel,
  notLabel,
  othersError,
  disabled,
  important,
}: InputRadioProps) => {
  const { setFieldValue } = useFormikContext<any>();
  const [{ value: checked }] = useField(nameChecked || name);
  const [{ value }] = useField(name);

  const { t } = useTranslation();

  const handleCheck = (event: any) => {
    if (boolean) {
      setFieldValue(name, event.target.name === "yes");
    } else if (options) {
      setFieldValue(name, event.target.value);
      if (nameChecked) {
        setFieldValue(nameChecked, event.target.value);
      }
    } else {
      setFieldValue(name, "");
    }
  };

  function handleOthers() {
    setFieldValue(name, "");
    if (nameChecked) {
      setFieldValue(nameChecked, "Outros");
    }
  }

  const renderLabel = () => {
    if (important) {
      return (
        <LabelContainer>
          <label>{label}</label>
          <InfoButton
            name="infobutton"
            hasValue={!!value || value !== null}
            content={
              !!value ? (
                <>
                  <strong>
                    {t("PositionForm.InputRender.inputAdviseStrong")}
                  </strong>{" "}
                  {t("PositionForm.InputRender.fullfilled")}
                </>
              ) : (
                <>
                  <strong>
                    {t("PositionForm.InputRender.inputAdviseStrong")}
                  </strong>{" "}
                  {t("PositionForm.InputRender.inputAdvise")}
                </>
              )
            }
          />
        </LabelContainer>
      );
    }

    return <label>{label}</label>;
  };

  function mapOptionsValues() {
    if (!options?.length) {
      return [];
    }

    if (typeof options[0] === "object" && "value" in options[0]) {
      return options.map(({ value }: any) => value);
    }

    return options;
  }

  function isOthersOptionSelected(value: any) {
    const optionsValues: any[] = mapOptionsValues();

    return typeof checked === "string" && !optionsValues.includes(value);
  }

  return (
    <InputRadioContainer>
      {renderLabel()}
      {boolean ? (
        <InputRadioCheckContainer>
          <label>
            <FormCheck
              type="checkbox"
              checked={checked === true}
              name="yes"
              onChange={handleCheck}
              disabled={disabled}
            />
            {yesLabel || `${t("commonText.radio.yes")}`}
          </label>
          <label>
            <FormCheck
              type="checkbox"
              checked={checked === false}
              name="not"
              onChange={handleCheck}
              disabled={disabled}
            />
            {notLabel || `${t("commonText.radio.no")}`}
          </label>
        </InputRadioCheckContainer>
      ) : (
        <>
          <RadioGroup>
            {(options || []).map((item: any, index: number) => {
              const value = typeof item === "object" ? item.value : item;
              const label = typeof item === "object" ? item.label : item;

              function inputOnChange(event: any) {
                setFieldValue(name, value + " " + event.target.value?.trim());
              }

              return (
                <Fragment key={index}>
                  <label key={1}>
                    <input
                      checked={value === checked}
                      onChange={() => setFieldValue(name, value)}
                      type="radio"
                      disabled={disabled}
                    />
                    {label}
                  </label>
                  {item.input && checked && checked.includes(item.value) && (
                    <input
                      key={index}
                      type="text"
                      placeholder={item.input.label || ""}
                      value={(checked || "").replace(checked, "")}
                      onChange={inputOnChange}
                      multiple
                      disabled={disabled}
                    />
                  )}
                </Fragment>
              );
            })}
            {others && !boolean && options && (
              <label key={1}>
                <input
                  name="Outros"
                  checked={
                    nameChecked
                      ? checked === "Outros"
                      : isOthersOptionSelected(value)
                  }
                  onChange={handleOthers}
                  type="radio"
                  disabled={disabled}
                />
                {t("PositionFormModel.spectraForm.other")}
              </label>
            )}
          </RadioGroup>
          {others && isOthersOptionSelected(checked) && (
            <InputRadioOthersWrap>
              <FormikTextInput
                label={`${t("PositionFormModel.spectraForm.other")}: `}
                name={name}
                placeholder={others.label || ""}
                error={othersError || ""}
                noMessage
                disabled={disabled}
              />
            </InputRadioOthersWrap>
          )}
        </>
      )}
      {error && <span>{error}</span>}
    </InputRadioContainer>
  );
};
