import styled, { css } from "styled-components";

export const Container = styled.div<{
  kwh?: any;
  energySavingValue?: any;
  value?: boolean;
  theresContentDescription?: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  flex-direction: column;
  z-index: 6;
  position: relative;

  & > div {
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: ${(props) =>
      props.theresContentDescription ? "flex-start" : "center"};
    align-items: center;
  }

  & > div:first-child {
    align-items: baseline;

    ${(props) =>
      props.energySavingValue &&
      css`
        &:hover {
          position: relative;
          &::after {
            content: "${(props) =>
              //@ts-ignore
              props.energySavingValue}";
            position: absolute;
            border: 2px solid #d5eed0;
            display: flex;
            top: 3.5rem;
            ${(props) => props.theme.fonts.primary.H5Regular}
            background-color: #f6f8ee;
            color: #254330;
            padding: 0.2rem 0.8rem;
            border-radius: 4px;
            animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
              both;
          }
        }
        @keyframes slide-in-top {
          0% {
            transform: translateY(-12px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}

    span {
      ${(props) => props.theme.fonts.primary.H1boldCard}
      /* font-size: ${(props) => (props.kwh >= 7 ? "32px" : "45px")}; */
      font-size: 45px;
      line-height: 60px;
    }
    & > span:first-child,
    & > span:last-child {
      font-size: 20px;
      line-height: 20px;
    }
  }

  & > div:last-child {
    padding: 8px;
    display: flex;
    width: 100%;
    height: 100%;
    gap: 0.5rem;
    flex-direction: column;

    p {
      word-wrap: break-word;
      text-align: center;
      margin-bottom: 0;

      overflow: auto;
      &::-webkit-scrollbar {
        width: 2px !important;
        height: 4px;

        @media screen and (max-width: 767px) {
          width: 3px !important;
        }
      }

      &::-webkit-scrollbar-track {
        background: #d5eed0;
        width: 3px;
        border-radius: 100vw;
        margin-block: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #859988;
        height: 100%;
        max-height: 2rem;
        border-radius: 100vw;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #9dc3a1;
      }

      &::-webkit-scrollbar-thumb:active {
        background: #4b6c57;
      }
    }

    span {
      text-align: center;
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
    align-items: center;

    & > div:first-child {
      span {
        ${(props) => props.theme.fonts.primary.H1boldCard}
        /* font-size: ${(props) => (props.kwh >= 7 ? "3rem" : "4rem")}; */
        font-size: 4rem;
        line-height: 70px;
      }
      & > span:first-child,
      & > span:last-child {
        font-size: 20px;
        line-height: 20px;
      }
    }

    & > div:last-child {
      span {
      }
    }
  }

  @media screen and (min-width: 1229px) and (max-width: 1536px) {
    & > div:first-child {
      align-items: flex-end;

      span {
        ${(props) => props.theme.fonts.primary.H1boldCard}
        font-size: ${(props) => (props.kwh >= 7 ? "24px" : "35px")};
        line-height: ${(props) => (props.kwh >= 7 ? "30px" : "40px")};
      }
      & > span:first-child,
      & > span:last-child {
        font-size: 16px;
        line-height: 26px;
      }
    }
    & > div:last-child {
      padding: 0px;

      span {
      }
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1229px) {
    & > div:first-child {
      align-items: flex-end;

      span {
        ${(props) => props.theme.fonts.primary.H1boldCard}
        font-size: 30px;
        line-height: 40px;
      }
      & > span:first-child,
      & > span:last-child {
        font-size: 16px;
        line-height: 34px;
      }
    }
    & > div:last-child {
      padding: 0px;

      span {
        font-size: 12px;
      }
    }
  }
`;
