import styled, { css } from "styled-components";
import lightTheme from "../../../../../../styles/themes/light";
import darkTheme from "../../../../../../styles/themes/dark";

interface IContainer {
  disabled?: boolean;
}

export const UserFacilitiesSelectorContainer = styled.div<IContainer>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  & > div > label {
    margin-top: 0;
    padding-top: 0;
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${lightTheme.colors.iconsPrimary};
  }

  & > div > div {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    height: 38px!important;
    padding-right: 8px;
    background-color: #f3f6f8 !important;
    border: 1px solid ${({ theme }) => theme.colors.cardHighlight};

    input {
      background-color: transparent;
      width: 100%;
      border: none;
      outline: none;
      outline: none;
      padding: 8px;
      height: 38px;
      cursor: default;
      ${({ theme }) => theme.fonts.primary.H4Regular};
      color: ${lightTheme.colors.textPrimary};

    }

    button {
      background-color: transparent;
      border-radius: 100%;
      height: 26px;
      width: 26px;
      display: flex;  
      justify-content: center;
      align-items: center;
      border: ${({ theme }) => darkTheme.colors.cardHighlight};

      svg {
        color: black;
        font-size: 16px;
      }

      &:hover {
        svg {
          font-size: 18px;
        }
      }
    }
  }

  ${({ disabled }) =>
    !!disabled &&
    css`
      & > div {
        input,
        button {
          cursor: not-allowed;
        }
      }
    `}
`;
