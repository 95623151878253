import { useState, useEffect, useRef } from "react";
import Frame from "../../../components/frame";
import { FrameBodyMain } from "../../../components/frame/styles";
import { Loading } from "../../../components/Loading";
import { IoMdTrash } from "react-icons/io";

import { SidebarConfiguration } from "../../../components/SidebarConfiguration";
import { Tab, Tabs } from "../../../components/Tabs";
import useApi from "../../../hooks/useApi";
import RepresentativeCompanies from "./Companies";
import FormAddNewRepresentative from "./FormAddNewRepresentative";
import FormEditRepresentative from "./FormEditRepresentative";
import FormUserRepresentative from "./FormUserRepresentative";

import {
  Content,
  RepresentativeItem,
  ContainerPage,
  ContainerRepresentatives,
  SideBarRepresentatives,
  TabContent,
  RepresentativeContainer,
  Header,
  ButtonNewRepresentative,
  TabContentComanyData,
  TabContentSummary,
  CompanyMenuMobile,
  CompanyMenuDropdown,
  ShowCompaniesButton,
  ButtonDeleteRepresentative,
} from "./styles";
import Summary from "./Summary";

import { RepresentativeModel } from "./types";
import UsersRepresentatives from "./Users";
import { MdAdd } from "react-icons/md";
// import { isMobile  } from "../../../utils/mediaQuery";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ConfirmDeleteModal from "../../../modals/ConfirmDeleteForm";
import { success } from "../../../components/Toast";
import { useAppSelector } from "../../../store/hooks";
import { PROFILES, userSelect } from "../../../store/slices/userSlice";
import useOnClickOutSide from "../../../hooks/useOnClickOutside";
import { useSegment } from "../../../hooks/useSegmentTrackEvent ";
import { useTranslation } from "react-i18next";
import {  isMobileOnly } from "react-device-detect";
import { TabsNavigation } from "../../../components/TabsNavigation";

const Representatives = () => {
  const user = useAppSelector(userSelect);
  const { handleTrackPageView } = useSegment();

  const { t } = useTranslation();
  const [representativeList, setRepresentativeList] = useState<
    RepresentativeModel[]
  >([]);
  const [selectedRepresentative, setSelectedRepresentative] =
    useState<RepresentativeModel | null>(null);
  const [currentTab, setCurrentTab] = useState("SUMMARY");

  const [showFormAddUser, setShowFormAddUser] = useState(false);
  const [showFormAddNewRepresentative, setShowFormAddNewRepresentative] =
    useState(false);
  const [showCompanyMenu, setShowCompanyMenu] = useState<boolean>(false);
  const [, setInicialCompanyName] = useState<any>();

  const [userToEdit, setUserToEdit] = useState<any>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<any>({
    id: 0,
    show: false,
  });

  const [refreshPage, setRefreshPage] = useState(false);

  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useOnClickOutSide(dropdownRef, () => setShowCompanyMenu(false), inputRef);

  const { request, processing: processingRepresentatives } = useApi({
    path: "/representatives",
  });

  const handleOpenFormUser = (userId?: any) => {
    setShowFormAddUser(true);
    setUserToEdit(null);

    if (typeof userId === "string") {
      setUserToEdit(userId);
    }
  };

  const fetchRepresentatives = () => {
    request({ method: "get" }).then((response: any) => {
      setRepresentativeList(response);
      setSelectedRepresentative(response[0]);
    });
  };

  const handleDeleteRepresentative = () => {
    request({ method: "del", pathParameters: showConfirmModal.id }).then(() => {
      success(`${t("Representatives.Representatives.repDeleted")}`);
      setRefreshPage(true);
    });

    setShowConfirmModal(false);
  };

  useEffect(() => {
    fetchRepresentatives();
    handleTrackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  useEffect(() => {
    setInicialCompanyName(representativeList[0]?.name);
  }, [representativeList]);

  function menu(representative: any) {
    setShowCompanyMenu(!showCompanyMenu);
    setSelectedRepresentative(representative);
  }

  function renderCompanyMobile() {
    return (
      <>
        <CompanyMenuMobile ref={inputRef}>
          <div
            onClick={() => {
              setShowCompanyMenu(!showCompanyMenu);
            }}
          >
            <span>{t("Representatives.Representatives.rep")}:&nbsp; </span>
            <h3>{selectedRepresentative?.name || ""}</h3>

            <ButtonNewRepresentative
              onClick={() => setShowFormAddNewRepresentative(true)}
            >
              <MdAdd size={32} />
            </ButtonNewRepresentative>
            <ShowCompaniesButton>
              {showCompanyMenu ? (
                <FaChevronUp size={22} />
              ) : (
                <FaChevronDown size={22} />
              )}
            </ShowCompaniesButton>
          </div>
          <ButtonDeleteRepresentative
            onClick={() => {
              setShowConfirmModal({
                id: selectedRepresentative?.id || 0,
                show: true,
              });
            }}
          >
            <IoMdTrash />
          </ButtonDeleteRepresentative>
        </CompanyMenuMobile>
        {showCompanyMenu && (
          <>
            <CompanyMenuDropdown ref={dropdownRef}>
              {representativeList.map((representative) => (
                <RepresentativeItem
                  selected={representative.id === selectedRepresentative?.id}
                  onClick={() => menu(representative)}
                >
                  <div>
                    <span>{representative.name}</span>
                  </div>
                </RepresentativeItem>
              ))}
            </CompanyMenuDropdown>
          </>
        )}
      </>
    );
  }

  function renderContent() {
    if (processingRepresentatives) {
      return <Loading />;
    }

    if (showFormAddUser) {
      return (
        <FormUserRepresentative
          userToEdit={userToEdit}
          representative={selectedRepresentative}
          handleCancel={() => setShowFormAddUser(false)}
        />
      );
    }

    if (showFormAddNewRepresentative) {
      return (
        <FormAddNewRepresentative
          onCancel={() => setShowFormAddNewRepresentative(false)}
          onRefresh={fetchRepresentatives}
        />
      );
    }

    return (
      <>
        {isMobileOnly ? (
          <>{renderCompanyMobile()}</>
        ) : (
          <>
            <SideBarRepresentatives>
              <div>
                <h3>{`${t("Representatives.Representatives.repList")}`}</h3>
                <ButtonNewRepresentative
                  onClick={() => setShowFormAddNewRepresentative(true)}
                >
                  {isMobileOnly ? (
                    <MdAdd size={32} />
                  ) : (
                    t("Representatives.Representatives.new")
                  )}
                </ButtonNewRepresentative>
              </div>
              <hr />
              <div>
                <div>
                  {representativeList.map((representative) => (
                    <RepresentativeItem
                      selected={
                        representative.id === selectedRepresentative?.id
                      }
                      onClick={() => setSelectedRepresentative(representative)}
                    >
                      <div>
                        <span>{representative.name}</span>
                      </div>
                      {[
                        PROFILES.EXECUTIVO_MASTER,
                        PROFILES.MESA_MASTER,
                      ].includes(user.profile) && (
                        <button
                          onClick={() =>
                            setShowConfirmModal({
                              id: representative.id,
                              show: true,
                            })
                          }
                        >
                          <IoMdTrash />
                        </button>
                      )}
                    </RepresentativeItem>
                  ))}
                </div>
              </div>
            </SideBarRepresentatives>
          </>
        )}

        <Content>
          {isMobileOnly ? (
            <TabsNavigation
              handleChangeTab={(tab: string) => setCurrentTab(tab)}
              adjustHeight="100%"
              currentTab={currentTab}
            >
              <Tab key="SUMMARY" label="Geral">
                <TabContentSummary>
                  <Summary representative={selectedRepresentative} />
                </TabContentSummary>
              </Tab>
              <Tab key="DETAILS" label="Detalhes">
                <TabContentComanyData>
                  <FormEditRepresentative
                    values={selectedRepresentative}
                    onRefresh={fetchRepresentatives}
                  />
                </TabContentComanyData>
              </Tab>
              <Tab
                key="USERS"
                label={`${t("Representatives.Representatives.users")}`}
              >
                <TabContent>
                  <UsersRepresentatives
                    representative={selectedRepresentative}
                    onClickNew={handleOpenFormUser}
                  />
                </TabContent>
              </Tab>
              <Tab
                key="COMPANIES"
                label={`${t("Representatives.Representatives.companies")}`}
              >
                <TabContent>
                  <RepresentativeCompanies
                    representative={selectedRepresentative}
                  />
                </TabContent>
              </Tab>
            </TabsNavigation>
          ) : (
            <Tabs
              handleChangeTab={(tab: string) => setCurrentTab(tab)}
              adjustHeight="100%"
              currentTab={currentTab}
            >
              <Tab
                key="SUMMARY"
                label={`${t("Representatives.Representatives.new")}`}
              >
                <TabContentSummary>
                  <Summary representative={selectedRepresentative} />
                </TabContentSummary>
              </Tab>
              <Tab
                key="DETAILS"
                label={`${t("Representatives.Representatives.repData")}`}
              >
                <TabContentComanyData>
                  <FormEditRepresentative
                    values={selectedRepresentative}
                    onRefresh={fetchRepresentatives}
                  />
                </TabContentComanyData>
              </Tab>
              <Tab
                key="USERS"
                label={`${t("Representatives.Representatives.users")}`}
              >
                <TabContent>
                  <UsersRepresentatives
                    representative={selectedRepresentative}
                    onClickNew={handleOpenFormUser}
                  />
                </TabContent>
              </Tab>
              <Tab
                key="COMPANIES"
                label={`${t("Representatives.Representatives.companies")}`}
              >
                <TabContent>
                  <RepresentativeCompanies
                    representative={selectedRepresentative}
                  />
                </TabContent>
              </Tab>
            </Tabs>
          )}
        </Content>
      </>
    );
  }

  return (
    <Frame hasMargin={false}>
      <FrameBodyMain>
        <SidebarConfiguration />
        <RepresentativeContainer visible>
          <ContainerPage>
            <Header>
              <h2>{t("Representatives.Representatives.reps")}</h2>
            </Header>

            <ContainerRepresentatives>
              {renderContent()}
              {showConfirmModal.show && (
                <ConfirmDeleteModal
                  id={showConfirmModal.id}
                  show={showConfirmModal.show}
                  handleClose={() => setShowConfirmModal({ show: false })}
                  context={`${t("Representatives.Representatives.deletRep")}`}
                  handleConfirm={handleDeleteRepresentative}
                  required
                />
              )}
            </ContainerRepresentatives>
          </ContainerPage>
        </RepresentativeContainer>
      </FrameBodyMain>
    </Frame>
  );
};

export default Representatives;
