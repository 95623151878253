import styled from "styled-components";

import { Leaf } from "../Leaf";

const Container = styled.div`
  position: absolute;
`;

const Tree = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: transparent;
  z-index: 2;
`;

const TreeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;

const TreeLeaves = () => {
  const leaves = [];

  for (let i = 0; i < 10; i++) {
    const duration = Math.random() * 5 + 20;
    const left = Math.random() * 100;
    leaves.push(<Leaf key={i} left={left} duration={duration} />);
  }

  return <div>{leaves}</div>;
};

export function TreeComponent() {
  return (
    <Container>
      <Tree>
        <TreeContainer>
          <TreeLeaves />
        </TreeContainer>
      </Tree>
    </Container>
  );
}
