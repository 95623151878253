import styled, { keyframes } from "styled-components";
import theme from "../../../styles/themes/default";
import {
  Contract,
  HideMenu,
  MenuExpanded,
} from "../../Companies/CardCompany/styles";

export const CardBoltBodyDiv = styled.div`
  background-color: ${(props) => props.theme.colors.cardBackground};
  color: ${(props) => props.theme.colors.textPrimary};
  text-align: left;
  height: 90px;
  width: 100%;
  max-width: 260px;
  border-radius: 4px;
  padding: 6px 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  @media (max-width: 767px) {
    margin-left: 6%;
    width: 200px;
    padding: 0px 8px 2px 4px;
  }
`;

export const SidebarItemBolt = styled.div<{ sectorColor?: string | null }>`
  background-color: ${(props) => props.theme.colors.cardBackground};
  color: ${(props) => props.theme.colors.textPrimary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  padding: 0px;
  height: auto;
  text-align: center;
  display: flex;
  /* border-bottom: 1px solid
    ${(props) => props.sectorColor || props.theme.backgroundColors.background4};
  border-right: 1px solid
    ${(props) =>
    props.sectorColor || props.theme.backgroundColors.background4}; */
  &:hover {
    border-color: ${(props) => props.theme.colors.textPrimary};
  }

  @media (max-width: 767px) {
    margin-left: 0;
    width: 90vw;

    & > &:last-child {
      margin-bottom: 10rem;
    }
  }
`;

export const MenuExpandedBolt = styled(MenuExpanded)`
  right: 36px;
  top: 0px;
  @media screen and (max-width: 767px) {
    right: 35px !important;
    top: 0 !important;
  }
`;

export const SectorLabel = styled.label<{ sectorColor?: string | null }>`
  color: ${(props) => props.sectorColor || props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H4Bold};
`;

export const DotArea = styled(Contract)`
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  width: fit-content;
`;

export const HideMenuAsset = styled(HideMenu)`
  top: 0;
`;

export const FavoriteAsset = styled.div<{ favorite?: boolean }>`
  ${(props) => props.favorite && "color: yellow;"};
`;

export const CardBoltDivName = styled.div`
  width: 88%;

  & > label {
    ${(props) => props.theme.fonts.primary.H4Bold};
    color: ${(props) => props.theme.colors.textPrimary}!important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CardBoltDivId = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start !important;
  align-items: center;

  & > span {
    max-width: 100%;
    ${(props) => props.theme.fonts.secondary.H1Regular}
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto !important;
  }
`;

export const statusPulse = keyframes`
from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
`;

export const onlinePulse = keyframes`
 0% {
    transform: scale(0.5);
    opacity: .8;
  }
  80% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

export const StatusBolt = styled.div`
  ${(props) => props.theme.fonts.primary.H6Bold};
  background-color: ${(props) => props.theme.colors.cardHighlight};
  width: 8%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusBoltText = styled.div<{ online?: boolean }>`
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  gap: 4px;
  color: ${(props) =>
    props.online ? theme.colors.statusNormal : theme.colors.statusRisk};

  svg {
    font-size: 16px;
    height: 14px;
    animation: ${onlinePulse} 3s ease-in-out infinite both;
  }
  span {
    height: auto;
  }
`;

export const CardAssetBodyDivInfo = styled.div`
  ${(props) => props.theme.fonts.secondary.H1Regular}
  color: ${(props) => props.theme.colors.textSecondary};
  height: 14px;
  margin-top: 4px;
  margin-bottom: 25px;
  display: flex;
  gap: 15px;
`;

export const pulse = keyframes`
0% {
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
`;

export const PulseCircleConnected = styled.div`
  position: absolute;
  border: 3px solid ${(props) => props.theme.colors.statusNormal};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  top: 7px;
  right: 23px;
  animation: ${pulse} 1.5s ease-in-out infinite both;
`;

export const SecondPulseCircleConnected = styled(PulseCircleConnected)`
  top: 11px;
  right: 11px;
  animation: ${pulse} 1.5s ease-in-out 0.67s infinite both;
`;

export const CardAssetImageDiv = styled.div`
  position: relative;
  display: flex;
  width: 56px;
  height: auto;
  background-color: ${(props) => props.theme.colors.cardHighlight};
  border-left: 1px solid ${(props) => props.theme.colors.cardBackground};
  border-right: 1px solid ${(props) => props.theme.colors.cardBackground};

  img {
    margin: auto;
    max-width: 56px;
    max-height: 80px;
    padding: 2px;
    z-index: 1;
  }

  .boltimg {
    width: 55px;
    height: 55px;
  }
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  right: 30px;
  top: 6px;
  border-left: 6px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  position: absolute;

  @media screen and (max-width: 767px) {
    right: 29px !important;
  }
`;

export const CardBoltFooter = styled.div`
  ${(props) => props.theme.fonts.secondary.H1Regular}
  display: flex;
  flex-direction: column;
  gap: 3px;

  & > div {
    display: flex;
    flex-direction: column;
  }
  & > div:first-child {
    width: 100%;
    span {
      height: auto !important;
    }
  }
  & > div:last-child {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${(props) => props.theme.fonts.secondary.H3Bold}

    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 6px;

      svg {
        color: ${(props) => props.theme.colors.iconsPrimary};
      }
      span {
        height: auto !important;
      }
    }

    & > div:last-child {
      justify-content: flex-end;
    }
  }
`;

export const CardBoltWrap = styled.a`
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
  margin-left: 0 4px;
`;

export const IconBase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 16px !important;
  height: 16px;
  background-color: ${(props) => props.theme.colors.iconsPrimary};
  color: ${(props) => props.theme.colors.cardBackground};
  ${(props) => props.theme.fonts.primary.H6Bold}
  line-height: 0;
`;

export const RssiContainer = styled.div`
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 6px;
  }

  span {
    ${(props) => props.theme.fonts.secondary.H3Bold}
    margin-right: 8px;
  }
  svg {
    color: ${(props) => props.theme.colors.iconsPrimary};
    width: 18px;
    height: auto;
  }
`;

export const WifiStatus = styled.div<{ status?: "HIGH" | "OFF" }>`
  @keyframes risk {
    0%,
    50%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0;
    }
  }
  svg {
    color: ${(props) => {
      if (props.status === "HIGH") {
        return props.theme.colors.statusNormal;
      } else if (props.status === "OFF") {
        return props.theme.colors.statusRisk;
      }
      return;
    }} !important;

    ${(props) =>
      props.status === "OFF"
        ? { animation: "risk 2.5s ease-in-out infinite both" }
        : {}}
  }
`;

export const DetailsContainer = styled.div`
  padding: 5px 10px;

  background-color: ${(props) => props.theme.colors.cardBackground};

  position: relative;
  cursor: default;

  & > span {
    max-width: 100%;
    ${(props) => props.theme.fonts.secondary.H1SemiBold}
    color: ${(props) => props.theme.colors.textSecondary};
    display: inline-block;
    margin-top: 5px;
  }
`;

export const DetailsCopy = styled.div`
  & > span {
    ${(props) => props.theme.fonts.secondary.H2Regular}
    color: ${(props) => props.theme.colors.textSecondary};
  }

  & > button {
    background-color: inherit;
    border: none;
    color: ${(props) => props.theme.colors.textSecondary};
    margin-left: 5px;
  }
`;

export const DetailsHeader = styled.div`
  & > span {
    max-width: 100%;
    ${(props) => props.theme.fonts.secondary.H1SemiBold}
    color: ${(props) => props.theme.colors.textSecondary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > button {
    position: absolute;
    background-color: inherit;
    border: none;
    color: ${(props) => props.theme.colors.textSecondary};
    right: 5px;
  }
`;

export const DetailsContent = styled.div`
  display: flex;
  height: 20px;

  & > span {
    max-width: 100%;
    ${(props) => props.theme.fonts.secondary.H2Regular}
    color: ${(props) => props.theme.colors.textSecondary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DetailsFooter = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-around;

  & > button {
    ${(props) => props.theme.fonts.secondary.H2Regular}
    color: ${(props) => props.theme.colors.textSecondary};
    background-color: inherit;
    border: 1px solid ${(props) => props.theme.colors.textSecondary};
    color: ${(props) => props.theme.colors.textSecondary};

    padding: 4px 6px;
    background: ${(props) => props.theme.colors.cardBackground};
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
    border-radius: 6px;

    &:hover {
      background: ${(props) => props.theme.colors.divBackground};
    }
  }
`;

export const BoltOperationModeContainer = styled.div`
  line-height: 0;

  & > span {
    height: auto;
    font-size: 9px;
  }
`;
