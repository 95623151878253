import { Body, Container, FrameBodyMain } from "./styles";

import { FrameProps } from "./types";
import { NavBar } from "./NavBar";

function Frame({
  children,
  hasMargin = true,
  scrollable,
  headerFixed,
  runJoyride,
  setRunJoyride,
}: FrameProps) {
  return (
    <Container scrollable={scrollable}>
      <NavBar
        fixed={headerFixed}
        runJoyride={runJoyride}
        setRunJoyride={setRunJoyride}
      />
      <Body hasMargin={hasMargin}>{children}</Body>
    </Container>
  );
}

Frame.BodyMain = FrameBodyMain;
export default Frame;
