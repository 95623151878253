import styled, { css } from "styled-components";

interface notificationsProps {
  hasntBackground?: boolean;
  checkType?: "switch" | "checkbox" | "radio";
}

export const Container = styled.div<notificationsProps>`
  width: 100%;
  display: flex;
  border-radius: 32px;
  background-color: ${({ hasntBackground, theme }) =>
    hasntBackground ? "transparent" : theme.colors.itemBackground};
  gap: 1rem;
  min-height: 2.85rem;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: 0 1rem;

  h2 {
    ${({ theme }) => theme.fonts.primary.H2Bold};
    margin-bottom: 1.75rem;
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  // telas com 125% de zoom:
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    gap: 0rem;
    min-height: 2.45rem;
    padding: 0 0.5rem !important;
  }

  //mobile:
  @media screen and (max-width: 767px) {
  }
`;

export const Subject = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  & > div {
    display: flex;
    width: 100%;
  }
`;

export const Deliver = styled.div`
  width: 26%;
  display: flex;
  flex-direction: column;
  h2 {
    margin-left: 1rem;
  }
  & > div {
    display: flex;
  }
`;

export const Check = styled.div<notificationsProps>`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.75rem;
    width: 3.25rem;
    padding: 0 !important;
  }

  ${({ checkType }) =>
    checkType === "checkbox" &&
    css`
      & > div {
        height: 1.5rem;
        width: 1.5rem;
      }

      .form-check-input {
        margin-left: 0.1rem;
        scale: 0.85;
      }
    `}

  .form-check-input[type="checkbox"] {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    cursor: pointer;
  }

  .form-check-input {
    margin: 0;
    width: 100%;
    height: 100%;
    border: 1px solid ${(props) => props.theme.colors.BackgroundShadow};
  }

  .form-check-input[type="checkbox"]:checked {
    background-color: ${(props) => props.theme.colors.ibbxOrange};
    border: transparent;
    stroke: red;
  }

  .form-check-input[type="checkbox"]:focus {
    box-shadow: 0 0 0 0.25rem rgb(243, 112, 53, 0.25);
    border-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
  }

  // telas com 125% de zoom:
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    & > div {
      height: 1.5rem;
      width: 2.5rem;
    }

    ${({ checkType }) =>
      checkType === "checkbox" &&
      css`
        & > div {
          height: 1.25rem;
          width: 1.55rem;
        }
      `}
  }

  //mobile:
  @media screen and (max-width: 767px) {
    & > div {
      height: 1.5rem;
      width: 2.6rem;
    }

    ${({ checkType }) =>
      checkType === "checkbox" &&
      css`
        & > div {
          height: 1.25rem;
          width: 1.55rem;
        }
      `}
  }
`;
export const Description = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 1rem;

  span {
    ${(props) => ({ ...props.theme.fonts.primary.H4Regular })};
  }

  //mobile:
  @media screen and (max-width: 767px) {
    span {
      ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    padding-left: 0.5rem !important;
  }
`;

export const OptionsType = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
  align-items: center;

  //mobile:
  @media screen and (max-width: 767px) {
    width: 42.5%;
    padding: 0;
  }
`;

export const StandardContent = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
`;

export const CollapseLine = styled.div`
  height: 2rem;
  position: absolute !important;
  display: flex;
  justify-content: flex-end !important;
  right: 1rem;
  top: 0.25rem;
  transition: all 0.3s ease;
  cursor: pointer;
  color: ${(props) => props.theme.colors.iconsSecondary};
  z-index: 2;
  opacity: 0.25;
  top: 50%;
  transform: translatey(-50%);

  svg {
    width: 1.8rem;
    height: auto;
  }
  :hover {
    border-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 30px;
    opacity: 0.75;
  }
  @media screen and (max-width: 767px) {
    right: 0.5rem;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    height: auto;
    svg {
      width: 1.4rem;
      height: auto;
    }
  }
`;

export const CollapseContainer = styled.div<{ hasCollapse?: any }>`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1rem;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.itemBackground};
  margin-top: -4rem;
  padding: 2.5rem 3rem 2rem;
  border-radius: 16px 16px 32px 32px;

  // telas com 125% de zoom:
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    padding: 2rem 1.5rem !important;
  }

  //mobile:
  @media screen and (max-width: 767px) {
    padding: 2rem 1.5rem;
    gap: 0.25rem;
  }
`;
