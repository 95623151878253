import { UrlContainer } from "./styles";
import { Form, Formik } from "formik";

interface IUserInput {
  children: (...props: any) => any;
  initialValues?: any;
  isEditableTab?: boolean;
  handleSubmit?: any;
  formRef?: any;
  validateForm?: any;
}

export function UserForm({
  children,
  initialValues,
  isEditableTab,
  handleSubmit,
  formRef,
  validateForm,
}: IUserInput) {
  return (
    <UrlContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={formRef}
        validate={(values) => validateForm(values)}
        validateOnMount={false}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ resetForm, errors, values }) => (
          <Form>{children({ resetForm, errors, values })}</Form>
        )}
      </Formik>
    </UrlContainer>
  );
}
