import { useTranslation } from "react-i18next";
import { Container } from "./styles";

interface GetButtonProps {
  handleCancel: () => void;
}

export function CancelButton({ handleCancel }: GetButtonProps) {
  const { t } = useTranslation();
  return (
    <Container onClick={handleCancel}>
      <span>{t("Analytics.Cancelar")}</span>
    </Container>
  );
}
