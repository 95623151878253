import { addAnnotations, annotationsFirstRow } from "./general";

const getPercentInBetween = (value: number, start: number, end: number) => {
  const difFromStart = Math.abs(start - value);
  const difToEnd = Math.abs(end - value);
  return difFromStart / (difFromStart + difToEnd);
};

const getDegreeFromTime = (data: Array<number | any>, time: any) => {
  var thisDegree;
  for (let index = 0; index < data.length; index++) {
    const row = data[index];
    const [curTime] = row;
    if (!data[index + 1]) {
      continue;
    }
    var nextTime = data[+index + 1][0];

    if (time >= curTime && time <= nextTime && thisDegree === undefined) {
      const difFromStart = Math.abs(curTime - time);
      const difToEnd = Math.abs(nextTime - time);
      const percent = difFromStart / (difFromStart + difToEnd);

      thisDegree = { time, index, percent };
    }
  }

  return thisDegree;
};

const setDegreesInBetween = ({
  degreesMap,
  data,
  total,
  interval,
  startTime,
  endTime,
  lastDegree,
}: any) => {
  const phaseDif = endTime - startTime;
  for (let i = 1; i <= total; i++) {
    const curDegree = lastDegree + i * interval;
    const curTime = endTime - (phaseDif / total) * (total - i);
    degreesMap.set(`${curDegree}º`, getDegreeFromTime(data, curTime));
  }
  return degreesMap;
};

const getDegrees = (data: any) => {
  data = data.filter((item:any[]) => item[1] !== null)
  var degreesMap: any = new Map();
  var lastDegree = null;
  var degree0Time;
  var degree180Time;
  var degree360Time;

  for (let index = 0; index < data.length; index++) {
    const row = data[index];
    const [time, val] = row;
    var nextVal = index + 1 === data.length ? row[1] : data[index + 1][1];
    var nextTime = index + 1 === data.length ? row[0] : data[index + 1][0];

    var index0Degree = 0;
    if (
      lastDegree === null &&
      val <= 0 &&
      nextVal >= 0
    ) {
      lastDegree = 0;
      const percent = getPercentInBetween(0, val, nextVal);
      degree0Time = time + percent * (nextTime - time);
      degreesMap.set("0º", { time, index, percent });
      index0Degree = index;
    }
    if (
      lastDegree === 0 &&
      val >= 0 &&
      nextVal <= 0 &&
      index - index0Degree > 20
    ) {
      const percent = getPercentInBetween(0, val, nextVal);
      degree180Time = time + percent * (nextTime - time);

      degreesMap = setDegreesInBetween({
        degreesMap,
        data,
        total: 9,
        interval: 20,
        startTime: degree0Time,
        endTime: degree180Time,
        lastDegree,
      });
      lastDegree = 180;
      degreesMap.set("180º", { time, index, percent });
    }
    if (
      lastDegree === 180 &&
      val <= 0 &&
      nextVal >= 0 &&
      time - degree180Time > (degree180Time - degree0Time) / 2
    ) {
      const percent = getPercentInBetween(0, val, nextVal);
      degree360Time = time + percent * (nextTime - time);
      degreesMap = setDegreesInBetween({
        degreesMap,
        data,
        total: 9,
        interval: 20,
        startTime: degree180Time,
        endTime: degree360Time,
        lastDegree,
      });

      lastDegree = 360;
      degreesMap.set("360º", { time, index, percent });
    }
  }

  var degrees = [...degreesMap.entries()].map(([key, values]: any) => {
    return values ? { degree: key, ...values } : null;
  });

  return degrees.filter((e: any) => e !== null);
};

const applyPercentInDifferenceToNext = (
  cur: number,
  next: number,
  percent: number,
) => {
  return cur + percent * (next - cur);
};

export const getPhasesAnalysis = (data: any) => {
  if (data.length < 1 || data[0].length < 1) {
    return data;
  }

  var degrees = getDegrees(data);

  degrees = degrees.sort((a: any, b: any) => a.time - b.time);
  var degreesUsed = 0;

  data = data.reduce((agg: any, row: Array<any>, index: number) => {
    const curTime = row[0];
    if (!data[index + 1]) {
      return agg;
    }
    var nextTime = data[index + 1][0];
    var nextRow = data[index + 1];
    var nextDegree = degrees[degreesUsed];

    if (index === 0) {
      agg.push(addAnnotations(row, annotationsFirstRow));
    } else {
      agg.push(addAnnotations(row, [null, null]));
      if (
        nextDegree &&
        nextDegree.time >= curTime &&
        nextDegree.time <= nextTime
      ) {
        const linesFiltered = degrees.filter(
          (i: any) => i.time >= curTime && i.time < nextTime,
        );

        linesFiltered.forEach((line: any) => {
          var lineData = row.map((val: any, i: number) =>
            i === 0
              ? applyPercentInDifferenceToNext(val, nextRow[i], line.percent)
              : null,
          );
          agg.push(addAnnotations(lineData, [`${line.degree}`, "X"]));
          degreesUsed++;
        });
      }
    }

    return agg;
  }, []);

  return data;
};
