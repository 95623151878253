import styled from "styled-components";

export const RegressionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 1rem;

  & > div:first-child {
    width: 75%;
  }
`;

export const ParametersContainer = styled.div`
  width: 25%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  border-radius: 1rem;
`;

export const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  & > h5 {
    display: flex;
    gap: 1rem;
    ${(props) => props.theme.fonts.primary.H3SemiBold};
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    & > label {
      ${(props) => props.theme.fonts.primary.H5Regular};
      color: ${({ theme }) => theme.colors.iconsPrimary};
    }

    input {
      background-color: ${({ theme }) => theme.colors.background};
      border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
      color: ${(props) => props.theme.colors.iconsPrimary};
      ${({ theme }) => theme.fonts.primary.H4Regular};
      border-radius: 4px;
      padding: 8px;
      height: 34px;
      border-radius: 8px;

      &:focus {
        outline: none;
      }

      &:disabled {
        opacity: 0.7;
      }
    }

    input[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  span {
    font-size: 10px;
    font-weight: 400;
    color: red;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AxisSelector = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
`;

export const Button = styled.button<{ isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.theme.fonts.primary.H5Regular};
  padding: 6px 10px;
  border: none;
  border-radius: 6px;
  background: ${(props) =>
    props.isSelected
      ? props.theme.colors.ibbxOrange
      : props.theme.backgroundColors.background4};
  color: white;
`;

export const TendencyTag = styled.div<{ tendencyColor?: string }>`
  & > label {
    font-size: 12px;
    color: white;
    padding: 6px;
    border-radius: 6px;
    /* background: ${({ tendencyColor }) => tendencyColor || "transparent"}; */
  }
`;

export const Result = styled.div`
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 8px;
  padding: 7px;

  & > label {
  }

  & > div {
    ${(props) => props.theme.fonts.primary.H4Regular};
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      gap: 10px;
    }
  }
`;

export const BetaBedge = styled.div`
  width: fit-content;
  display: flex;
  justify-self: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  height: 1.5rem;
  color: ${(props) => props.theme.colors.iconsSecondary};
  background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  ${(props) => props.theme.fonts.primary.H4Regular};
`;
