import { useRef, useState } from "react";
import useOnClickOutSide from "../../hooks/useOnClickOutside";
import { IoMdInformationCircleOutline } from "react-icons/io";
import {
  InfoButtonContainer,
  InfoButtonCustomWrapper,
  InfoContainer,
  TriangleInfoButton,
} from "./styles";

type InfoButtonProps = {
  children: any;
};

function InformativeButton({ children }: InfoButtonProps) {
  const [showInfo, setShowInfo] = useState(false);
  const optionsRef = useRef<any>(null);
  const menuRef = useRef<any>(null);
  useOnClickOutSide(menuRef, () => setShowInfo(false), optionsRef);

  const handleClick = () => {
    setShowInfo(!showInfo)
  }

  return (
    <InfoButtonCustomWrapper>
      <InfoButtonContainer
        ref={optionsRef}
        onClick={handleClick}
        type="button"
      >
        <IoMdInformationCircleOutline size={24} />
      </InfoButtonContainer>
      {showInfo ? (
        <>
          <TriangleInfoButton left={optionsRef.current?.offsetLeft} />
          <InfoContainer ref={menuRef} left={optionsRef.current?.offsetLeft}>
            {children}
          </InfoContainer>
        </>
      ) : null}
    </InfoButtonCustomWrapper>
  );
}

export default InformativeButton;
