import {
  MdSignalWifiStatusbar3Bar,
  MdSignalWifiStatusbarConnectedNoInternet1,
  MdToggleOn,
} from "react-icons/md";

import "react-datepicker/dist/react-datepicker.css";
import { BiToggleLeft } from "react-icons/bi";
import {
  TbBattery1,
  TbBattery4,
  TbPlug,
  TbPlugOff,
  TbPlus,
} from "react-icons/tb";
import { useTheme } from "styled-components";
import { Select } from "../Select";
import {
  Button,
  Container,
  FilterContainer,
  NewOccurrenceContainer,
  SelectDiv,
} from "./styles";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import queryString from "../../utils/queryString";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { appSelect, setOccurrenceRegister } from "../../store/slices/appSlice";
import { getUrlPattern } from "../../utils/getUrlPattern";
import { PROFILES, userSelect } from "../../store/slices/userSlice";
import { isMobile } from "../../utils/mediaQuery";
import { ROUTES_PATH } from "../../routes/RoutesPath";
import FilterPeriodPicker from "./FilterPeriodPicker";
import { statusOptions, updateQueryString } from "./types";
import OccurrenceHiearchyModal from "../../modals/OccurrenceHiearchyModal";
import { GroupBase, StylesConfig } from "react-select";
import useFilters from "../../hooks/useFilters";
import { useTranslation } from "react-i18next";
import { selectOptionsSelect } from "../../store/slices/selectOptionsSlice";

export const FilterBar = ({ assetList, occurrenceList, url, fixed }: any) => {
  const app = useAppSelector(appSelect);
  const user = useAppSelector(userSelect);
  const { sensorTypes } = useAppSelector(selectOptionsSelect);

  const { currentCompany, currentFacility, currentAsset, currentPosition } =
    app;

  const theme = useTheme();
  const location = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const urlPattern = getUrlPattern(location, params);

  const { t } = useTranslation();

  const [, setSearchParams] = useSearchParams();
  const query: any = queryString.parse(location.search);

  const { CLIENTE_COMUM } = PROFILES;
  const isClient = user.isClient;
  const isUserCompanyLevel = [
    PROFILES.CLIENTE_COMUM,
    PROFILES.CLIENTE_MASTER_VIEW,
  ].includes(user.profile);
  const isCommonClient = CLIENTE_COMUM === user.profile;
  const isOccurrencePage = urlPattern.includes("occurrences");
  // const isCompaniesPage = ROUTES_PATH.COMPANIES === urlPattern;
  const isFacilitiesPage = ROUTES_PATH.FACILITIES === urlPattern;
  const isAssetsPage = ROUTES_PATH.ASSETS === urlPattern;
  const isAssetPage = ROUTES_PATH.ASSET === urlPattern;
  const isPagePosition = ROUTES_PATH.POSITIONS === urlPattern;

  const skipCompany = isFacilitiesPage;
  const skipFacility = skipCompany || isAssetsPage;
  const skipAsset = skipFacility || isAssetPage;

  const showFilterBar = urlPattern.includes("occurrences");

  function findItem(options: any[], value: number) {
    const item = options.find((item) => item.value === value);
    return item;
  }

  const {
    changeCompany,
    changeFacility,
    changeSector,
    changeAsset,
    companyOptions,
    facilityOptions,
    assetOptions,
    sectorOptions,
  } = useFilters({});

  const company = useMemo(() => {
    if (!companyOptions?.length) {
      return null;
    }

    if (query.companyId || (isClient && isOccurrencePage)) {
      const id = query?.companyId || user.company;
      const item = findItem(companyOptions, +id);
      if (isOccurrencePage) {
        changeCompany(item);
      }
      return item;
    }

    if (currentCompany.id) {
      return { value: currentCompany.id, label: currentCompany.name };
    }

    return null;
    // eslint-disable-next-line
  }, [companyOptions, query.companyId, currentCompany]);

  const facility = useMemo(() => {
    let item = null;
    if (!facilityOptions.length) {
      return item;
    }

    if (query.facilityId || (isCommonClient && isOccurrencePage)) {
      const id = query?.facilityId || user.facility;
      item = facilityOptions.find(({ value }) => value === +id);
      if (item && isOccurrencePage) {
        changeFacility(item);
      }
      return item;
    }

    if (currentFacility.id) {
      return { value: currentFacility.id, label: currentFacility.name };
    }
    return null;
    // eslint-disable-next-line
  }, [facilityOptions, query.facilityId, currentFacility]);

  const sector = useMemo(() => {
    if (!sectorOptions.length) {
      return null;
    }

    if (query.sectorId) {
      const item = sectorOptions.find(({ value }) => value === +query.sectorId);

      return item;
    }

    return null;
    // eslint-disable-next-line
  }, [sectorOptions, query.sectorId]);

  const asset = useMemo(() => {
    if (!assetOptions.length) {
      return null;
    }

    if (query.assetId) {
      const item = assetOptions.find(({ value }) => value === +query.assetId);
      if (item && isOccurrencePage) {
        changeAsset(item);
      }

      return item;
    }

    if (currentAsset.id) {
      return { value: currentAsset.id, label: currentAsset.name };
    }
    return null;
    // eslint-disable-next-line
  }, [assetOptions, query.assetId, currentAsset]);

  const {
    loadCompanyOptions,
    loadFacilityOptions,
    loadSectorOptions,
    loadAssetOptions,
    loadMonitoringOptions,
  } = useFilters({
    companyId: company?.value,
    facilityId: facility?.value,
    sectorId: sector?.value,
    assetId: asset?.value,
  });

  const [status, setStatus] = useState<any>(null);
  const [monitoringType, setMonitoringType] = useState<any>(null);

  const [filters, setFilters] = useState<any>({
    battery: 0,
    connectivity: 0,
    connected: 0,
    activated: 0,
  });

  const [hiearchyModal, setHiearchyModal] = useState(false);

  useEffect(() => {
    if (query.statusId) {
      const item = statusOptions.find(({ value }) => value === +query.statusId);
      setStatus(item);
    }
  }, [query.statusId]);

  useEffect(() => {
    if (!sensorTypes.options.length) {
      return;
    }

    if (query["monitoring-type"]) {
      const item = findItem(sensorTypes.options, +query["monitoring-type"]);
      setMonitoringType(item);
    }
    // eslint-disable-next-line
  }, [query["monitoring-type"], sensorTypes]);

  useEffect(() => {
    loadCompanyOptions(skipCompany);
    loadFacilityOptions(skipFacility);
    loadSectorOptions();
    loadAssetOptions(skipAsset);
    loadMonitoringOptions();

    // if (currentCompany.id && isOccurrencePage) {
    //   if (currentFacility.id) {
    //     query.facilityId = currentFacility.id;
    //     updateQueryString(query, "facilityId", setSearchParams);
    //   }

    //   if (currentAsset.id) {
    //     query.assetId = currentAsset.id;
    //     updateQueryString(query, "assetId", setSearchParams);
    //   }
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!facility) {
      loadFacilityOptions();
    }
    if (!sector) {
      loadSectorOptions();
    }
    if (!asset) {
      loadAssetOptions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, facility, sector]);

  const changeQueryParameters = (field: string, e?: any) => {
    const query: any = queryString.parse(location.search);
    switch (field) {
      case "COMPANY":
        query.companyId = e?.value;
        updateQueryString(query, "companyId", setSearchParams);
        return;
      case "FACILITY":
        query.facilityId = e?.value;
        updateQueryString(query, "facilityId", setSearchParams);
        return;
      case "SECTOR":
        query.sectorId = e?.value;
        updateQueryString(query, "sectorId", setSearchParams);
        return;
      case "ASSET":
        query.assetId = e?.value;
        updateQueryString(query, "assetId", setSearchParams);
        return;
      case "STATUS":
        query.statusId = e?.value;
        updateQueryString(query, "statusId", setSearchParams);

        return;
      case "SENSOR_TYPE":
        query["sensorTypeId"] = e?.value;
        updateQueryString(query, "sensorTypeId", setSearchParams);
        return;
    }
  };

  const handleChange = (field: string, e: any) => {
    switch (field) {
      case "COMPANY":
        if (isOccurrencePage) {
          changeCompany(e);
        }
        break;
      case "FACILITY":
        if (isOccurrencePage) {
          changeFacility(e);
        }
        break;
      case "SECTOR":
        changeSector(e);
        break;
      case "ASSET":
        if (isOccurrencePage) {
          changeAsset(e);
        }
        break;
      case "STATUS":
        setStatus(e);
        break;
      case "SENSOR_TYPE":
        setMonitoringType(e);
        break;
    }
    changeQueryParameters(field, e);
  };

  function handleFilter(filter: string, value: number) {
    const params: any = queryString.parse(location.search);
    const newValue = filters[filter] === value ? 0 : value;
    params[filter] = newValue;
    setFilters({ ...filters, [filter]: newValue });
    updateQueryString(params, filter, setSearchParams);
  }

  function handleAdd() {
    if (isPagePosition) {
      dispatch(
        setOccurrenceRegister({
          show: true,
          id: null,
          companyId: currentCompany.id,
          facilityId: currentFacility.id,
          assetId: currentAsset.id,
          positionId: currentPosition.id,
          sysSensorTypeId: currentPosition.positionSensorTypeId,
        }),
      );
    } else {
      setHiearchyModal(true);
    }
  }

  const styles: StylesConfig<any, false, GroupBase<any>> = {
    control: (base) => ({
      ...base,
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.cardHighlight,
      boxShadow: "none",
      fontWeight: 800,
      margin: 0,
      padding: 0,
      overflowY: "hidden",
    }),

    singleValue: (base) => ({
      ...base,
      color: theme.colors.iconsPrimary,
      fontSize: "12px",
      maxWidth: "120px",
    }),

    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
      color: theme.colors.iconsPrimary,
    }),

    valueContainer: (base) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
      color: "red",
      height: "30px",
      minWidth: "80px",
      position: "relative",
      "& > div": {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
      [`input[type="text"]:focus`]: {
        boxShadow: "none",
      },
    }),

    placeholder: (base) => ({
      ...base,
      color: theme.colors.iconsSecondary,
      alignItems: "center",
      fontSize: isMobile() ? "8px" : "12px",
      display: "flex",
    }),

    input: (base) => ({
      ...base,
      ...(company !== null ? { display: "none" } : {}),
      color: theme.colors.iconsSecondary,
      position: "absolute",
    }),

    menu: (base) => ({
      ...base,
      backgroundColor: theme.colors.background,
      borderRadius: "8px",
      fontColor: theme.colors.iconsPrimary,
      fontWeight: 800,
      fontSize: "12px",
      width: "200px",
      zIndex: 3,
      border: "1px solid",
      borderColor: theme.colors.cardHighlight,
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "200px",
      overflowX: "hidden",
    }),
    option: (base, props) => ({
      ...base,
      color: `${props.isSelected && theme.colors.textPrimary}`,
      fontWeight: `${props.isSelected && 600}`,
      ":hover": { backgroundColor: theme.colors.headerBackground },
      backgroundColor: props.isSelected
        ? theme.colors.cardBackground
        : "transparent",
    }),
  };

  const indicator = { DropdownIndicator: () => null };
  return (
    <>
      <Container hasMarginBottom={showFilterBar}>
        {hiearchyModal && (
          <OccurrenceHiearchyModal
            show={hiearchyModal}
            handleClose={() => setHiearchyModal(false)}
            handleConfirm={() => null}
          />
        )}
        <div>
          <SelectDiv>
            <Select
              handleChange={(e) => {
                handleChange("COMPANY", e);
              }}
              options={companyOptions}
              value={company}
              placeholder={`${t("FilterBar.companies")}`}
              styles={styles}
              isSearchable
              isClearable={!isFacilitiesPage}
              components={
                (isUserCompanyLevel ||
                  isFacilitiesPage ||
                  isAssetsPage ||
                  isAssetPage ||
                  isPagePosition) &&
                indicator
              }
              disabled={
                isUserCompanyLevel ||
                isFacilitiesPage ||
                isAssetsPage ||
                isAssetPage ||
                isPagePosition
              }
            />
            <Select
              handleChange={(e) => {
                handleChange("FACILITY", e);
              }}
              value={facility}
              options={facilityOptions}
              placeholder={`${t("FilterBar.facilities")}`}
              styles={styles}
              isSearchable
              isClearable={
                !isCommonClient &&
                !isAssetsPage &&
                !isAssetPage &&
                !isPagePosition
              }
              components={
                isCommonClient || isAssetsPage || isAssetPage || isPagePosition
                  ? indicator
                  : {}
              }
              disabled={
                isCommonClient || isAssetsPage || isAssetPage || isPagePosition
              }
            />
            {!isAssetPage && !isPagePosition && !assetList && (
              <Select
                handleChange={(e) => {
                  handleChange("SECTOR", e);
                }}
                value={sector}
                options={sectorOptions}
                placeholder={"Setor"}
                styles={styles}
                isSearchable
                isClearable={!isAssetPage || !isPagePosition}
                components={isAssetPage || isPagePosition ? indicator : {}}
                disabled={isAssetPage || isPagePosition}
              />
            )}
            {!assetList && (
              <Select
                handleChange={(e) => {
                  handleChange("ASSET", e);
                }}
                value={asset}
                options={assetOptions}
                placeholder={`${t("FilterBar.assets")}`}
                styles={styles}
                isSearchable
                isClearable={!isAssetPage || !isPagePosition}
                components={isAssetPage || isPagePosition ? indicator : {}}
                disabled={isAssetPage || isPagePosition}
              />
            )}
            <Select
              handleChange={(e) => handleChange("STATUS", e)}
              value={status}
              options={statusOptions.filter(
                (option) => assetList || option.value !== 1,
              )}
              placeholder={"Status"}
              styles={styles}
              isClearable
            />
            {!isPagePosition && !assetList && (
              <Select
                handleChange={(e) => handleChange("SENSOR_TYPE", e)}
                value={monitoringType}
                options={sensorTypes.options}
                placeholder={`${t("FilterBar.monitoring")}`}
                styles={styles}
                isClearable
              />
            )}
          </SelectDiv>
        </div>
        <div>
          {!isOccurrencePage && !occurrenceList && !assetList && (
            <FilterContainer>
              <span>|</span>
              <div>
                <Button
                  onClick={() => handleFilter("battery", 1)}
                  selected={filters.battery === 1}
                >
                  <TbBattery4 size={20} />
                </Button>
                <Button
                  onClick={() => handleFilter("battery", -1)}
                  selected={filters.battery === -1}
                >
                  <TbBattery1 size={20} />
                </Button>
              </div>
              <span>|</span>
              <div>
                <Button
                  onClick={() => handleFilter("connectivity", -1)}
                  selected={filters.connectivity === -1}
                >
                  <MdSignalWifiStatusbarConnectedNoInternet1 size={20} />
                </Button>
                <Button
                  onClick={() => handleFilter("connectivity", 1)}
                  selected={filters.connectivity === 1}
                >
                  <MdSignalWifiStatusbar3Bar size={20} />
                </Button>
              </div>
              <span>|</span>
              <div>
                <Button
                  onClick={() => handleFilter("connected", 1)}
                  selected={filters.connected === 1}
                >
                  <TbPlug />
                </Button>
                <Button
                  onClick={() => handleFilter("connected", -1)}
                  selected={filters.connected === -1}
                >
                  <TbPlugOff />
                </Button>
              </div>
              <span>|</span>
              <div>
                <Button
                  onClick={() => handleFilter("activated", -1)}
                  selected={filters.activated === -1}
                >
                  <BiToggleLeft size={20} />
                </Button>
                <Button
                  onClick={() => handleFilter("activated", 1)}
                  selected={filters.activated === 1}
                >
                  <MdToggleOn size={20} />
                </Button>
              </div>
              <span>|</span>
            </FilterContainer>
          )}
          {(isOccurrencePage || occurrenceList) && (
            <FilterPeriodPicker updateQueryString={updateQueryString} />
          )}
          {!assetList && !app.isReadOnly && (
            <NewOccurrenceContainer>
              <button onClick={handleAdd}>
                <TbPlus />
              </button>
            </NewOccurrenceContainer>
          )}
        </div>
      </Container>
    </>
  );
};
