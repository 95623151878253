import { Link } from "react-router-dom";
import styled from "styled-components";
import { getStatusColorById } from "../../../utils/getStatusColor";

export const StatusRiskAlert = styled.div<{
  statusId: 1 | 2 | 3 | 4 | 5;
  contractSOLaaS?: 1 | 2 | 3;
}>`
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.divBackground};
  border-radius: 6px;
  overflow: hidden;
  padding: 8px 12px;
  border: 1px solid
    ${({ statusId, theme, contractSOLaaS }) => {
      if (statusId > 1) {
        return getStatusColorById(theme, statusId);
      } else if (contractSOLaaS && contractSOLaaS === 3) {
        return getStatusColorById(theme, contractSOLaaS);
      }
      return theme.colors.cardHighlight;
    }};

  &:hover {
    border: 1px solid
      ${({ statusId, theme }) => {
        if (statusId > 1) {
          return getStatusColorById(theme, statusId);
        }
        return theme.colors.cardHighlight;
      }};
  }

  @keyframes borderAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: 160px;
    height: 600%;
    filter: blur(16px);
    animation: borderAnimation 5s linear infinite;
    background-color: ${({ statusId, theme }) => {
      if (statusId > 1) {
        return getStatusColorById(theme, statusId);
      }
      return "transparent";
    }};
  }
  &::after {
    content: "";
    position: absolute;
    background-color: ${({ theme }) => theme.colors.divBackground};
    inset: 2px;
    border-radius: 4px;
  }
`;

export const Containers = styled.div<{
  ref: any;
  statusRisk: 1 | 2 | 3 | 4 | 5;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.divBackground};
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  border-radius: 6px;
  padding: 4px 0 4px 0;

  /* padding: 12px; */
  z-index: 2;

  overflow: hidden;

  /* 
  border: 2px solid
    ${({ statusRisk }) =>
    statusRisk > 3 ? "red" : statusRisk < 1 ? "transparent" : "yellow"}; */
`;

export const ContainerChart = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyChart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  & span {
    margin-top: 10px;
  }
`;

export const ChartFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-top: 8px;

  & > div:first-child {
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 6px;
  }
  & > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  @media screen and (max-width: 767px) {
    & > div {
      margin: 0 !important;
      justify-content: flex-start;

      & > div {
        margin: 0 !important;
        gap: 10px;

        & > button {
          display: flex;
          align-items: center;
          div {
            min-width: 0.9rem;
            min-height: 0.9rem;
          }

          span {
            font-size: 11px;
          }
        }
      }
    }
  }
`;

export const AnalisysButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.divBackground};
  border: 1px solid transparent !important;
  color: ${(props) => props.theme.colors.iconsSecondary};
  padding: 3px;

  &:hover {
    border-radius: 6px;
    color: ${(props) => props.theme.colors.ibbxOrange};
    border: 1px solid ${(props) => props.theme.colors.cardHighlight} !important;
  }
  &:active {
    color: ${(props) => props.theme.colors.ibbxOrange};
    background-color: ${(props) => props.theme.colors.cardHighlight};
    font-size: 25px;
    transform: scale(0.9);
  }

  @media screen and (max-width: 767px) {
    padding: 0 0.5rem;
  }
`;

export const RedirectLink = styled(Link)`
  display: none;
`;
