import { addAnnotations, annotationsFirstRow } from "./general";

export function getGearingStamps(
  data: any,
  gearing: number,
  frequencyUnit?: string,
) {
  var stampUsed = 0;

  const frequencyUnitLabel = frequencyUnit === "CPM" ? "cpm" : "Hz";

  let stampPositionHarmonic = [
    {
      label: `${gearing.toFixed(0)} ${frequencyUnitLabel} GMF`,
      value: gearing,
    },
  ];

  const harmonicData = data.reduce((agg: any, item: any, index: number) => {
    const nextRow = data[index + 1];

    // Próxima Harmônica para Plotar
    var nextHarmonic = stampPositionHarmonic[stampUsed];

    if (index === 0) {
      agg.push(addAnnotations(item, annotationsFirstRow));
    } else if (nextHarmonic && item[0] === nextHarmonic.value) {
      agg.push(addAnnotations(item, [`${nextHarmonic.label}`, "teste2"]));

      stampUsed++;
    } else if (
      nextHarmonic &&
      item[0] < nextHarmonic.value &&
      (!nextRow || nextRow[0] > nextHarmonic.value)
    ) {
      const filterHarmonicsToAdd = stampPositionHarmonic.filter(
        (i: any) => i.value > item[0] && nextRow && i.value < nextRow[0],
      );

      filterHarmonicsToAdd.forEach((i: any) => {
        let newItem = [...item];
        newItem[0] = +i.value;

        agg.push(addAnnotations(newItem, [`${i.label}`, "teste2"]));
        stampUsed++;
      });
      agg.push(addAnnotations(item, [null, null]));
    } else {
      agg.push(addAnnotations(item, [null, null]));
    }

    var sortedArray = agg.sort(function (a: any, b: any) {
      return b[0] - a[0];
    });

    return sortedArray;
  }, []);
  return harmonicData;
}
