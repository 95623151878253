export interface ICompany {
  id: number;
  name: string;
  logoUrl?: string;
  companyName?: string;
  document?: string;
  taxEmail?: string;
  phone?: string;
  taxAddress?: string;
  zipCode?: string;
  complement?: string;
  activity?: string;
  successManages?: string;
  number?: string;
  city?: string;
  state?: string;
  contacts?: any[];
  contractUrl?: string;
  representativeId?: number;
  averageKWhConsumption?: number;
  theme?: any;
  hideGraphics?: string[];
  customLabels?: any;
  assetCustomFields?: any;
  contract?: "PaaS" | "SOLaaS";
  tabs: any[];
  isReadOnly: boolean;
  deniedPermissions: any;
}

export const EMPTY_COMPANY: ICompany = {
  id: 0,
  name: "",
  contract: "PaaS",
  tabs: [],
  isReadOnly: false,
  deniedPermissions: {},
};

export interface IFacilityListItem {
  facilityLogoUrl: string;
  id: number;
  companyId: number;
  name: string;
  statusName: string;
  statusCaption: string;
  countAssets: number;
  countPositions: number;
  statusId: 1 | 2 | 3 | 4 | 5;
  contract: string;
  facilityAnalysisOwner?: any;
  isReadOnly: boolean;
  deniedPermissions: any;
}

export const EMPTY_FACILITY_CARD: IFacilityListItem = {
  id: 0,
  name: "",
  companyId: 0,
  statusName: "",
  statusCaption: "",
  countAssets: 0,
  countPositions: 0,
  facilityLogoUrl: "",
  statusId: 1,
  contract: "",
  isReadOnly: false,
  deniedPermissions: {},
};

export interface ITab {
  id: number;
  default: string;
  label: string;
  block?: boolean;
  url?: string;
  key: string;
  isMosaic?: boolean;
  screens?: any[];
  hidden?: boolean;
  createdBy?: string;
}
