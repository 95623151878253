import { useField, useFormikContext } from "formik";
import { useEffect } from "react";
import { CloudConnectionContainer } from "./styles";
import { CloudConnectionFormProps } from "./types";

// const ipOptions = ["IP Dinâmico", "IP Estático"];

// const emptyWIFI = {
//   type: "WI-FI",
//   owner: "Própria",
//   password: "",
//   ipType: "",
//   ipAddress: "",
//   highPortLowPort: "",
//   ssid: "",
//   checked: { specialConnectivity: false },
//   specialConnectivity: "",
//   mask: "",
//   dns: "",
// };

// const empty4G = {
//   type: "4G",
//   provider: undefined,
// };

export const CloudConnectionForm = ({ name }: CloudConnectionFormProps) => {
  const { setFieldValue, errors }: any = useFormikContext<any>();
  const [{ value: cloudConnection }] = useField(name);

  useEffect(() => {
    if (cloudConnection.specialConnectivity) {
      setFieldValue(`${name}[checked][specialConnectivity]`, true);
    }
    // eslint-disable-next-line
  }, []);

  const cloudConnectionErrors = errors?.implantation?.cloudConnection || {};

  return (
    <CloudConnectionContainer>
      {/* <InputRadio
        name={`${name}[type]`}
        label="Conexão com Internetche"
        options={cloudConnectionTypes}
        error={errors?.implantation?.cloudConnection?.type}
      /> */}
      {/* {cloudConnection?.type === "4G" && (
        <InputRadio
          name="implantation[cloudConnection][provider]"
          label="Provedor:"
          othersError={cloudConnectionErrors?.checked?.provider}
          options={provider4GOptions}
          others
        />
      )} */}
      {(!!cloudConnectionErrors?.provider ||
        cloudConnectionErrors?.checked?.provider) && (
        <label style={{ fontSize: "10px", color: "red", paddingTop: "-20px" }}>
          {cloudConnectionErrors?.checked?.provider ||
            cloudConnectionErrors?.provider}
        </label>
      )}

      {/* {cloudConnection?.type === "WI-FI" && (
        <>
          <FormikTextInput
            name="implantation[cloudConnection][ssid]"
            error={cloudConnectionErrors.ssid}
            label="SSID*"
          />
          <FormikTextInput
            name="implantation[cloudConnection][password]"
            label="Senha"
          />
          <InputRadio
            name={`${name}[ipType]`}
            label="Tipo de IP"
            options={ipOptions}
            error={cloudConnectionErrors.ipType ? "Selecione um Opção" : ""}
          />
          {cloudConnection?.ipType === "IP Estático" && (
            <>
              <FormikTextInput
                name="implantation[cloudConnection][ipAddress]"
                error={cloudConnectionErrors.ipAddress}
                label="Endereço de IP*"
                inputMode="numeric"
                handleChange={(value: string) => {
                  let stringArray = value.split("").filter((char: string) => {
                    return validCharsIp.includes(char);
                  });
                  return stringArray.join("");
                }}
              />
              <FormikTextInput
                name="implantation[cloudConnection][gatewayAddress]"
                error={cloudConnectionErrors.gatewayAddress}
                label="Endereço Gateway*"
                inputMode="numeric"
                handleChange={(value: string) => {
                  let stringArray = value.split("").filter((char: string) => {
                    return validCharsIp.includes(char);
                  });
                  return stringArray.join("");
                }}
              />
              <FormikTextInput
                name="implantation[cloudConnection][mask]"
                label="Máscara de sub-rede*"
                error={cloudConnectionErrors.mask}
                inputMode="numeric"
                handleChange={(value: string) => {
                  let stringArray = value.split("").filter((char: string) => {
                    return validCharsIp.includes(char);
                  });
                  return stringArray.join("");
                }}
              />
              <FormikTextInput
                name="implantation[cloudConnection][dns]"
                error={cloudConnectionErrors.dns}
                label="DNS*"
              />
              <InputOptional
                label="Conectividade Especial"
                name="implantation[cloudConnection][specialConnectivity]"
                isCheckedEdit={!!cloudConnection?.checked?.specialConnectivity}
                nameChecked="implantation[cloudConnection][checked][specialConnectivity]"
              >
                <FormikTextInput name="implantation[cloudConnection][specialConnectivity]" />
              </InputOptional>
            </>
          )}
        </>
      )} */}
    </CloudConnectionContainer>
  );
};
