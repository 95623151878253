import {
  Button,
  Container,
  ContentNotifications,
  DropdownContainerNotifications,
  EmptyNotification,
  HeaderNotifications,
  NotificationContainer,
  QuantitySpan,
  ReadAllButton,
  TooltipContainer,
  Triangle,
  UnreadSpan,
} from "./styles";
import { VscBell } from "react-icons/vsc";
import { useEffect, useRef, useState } from "react";
import theme from "../../styles/themes/default";
import useApi from "../../hooks/useApi";
import { MdClose } from "react-icons/md";
import useOnClickOutSide from "../../hooks/useOnClickOutside";
import { ImFileEmpty } from "react-icons/im";
import { NOTIFICATION_TYPES } from "./types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  appSetCurrentCompany,
  setShowAnnouncement,
} from "../../store/slices/appSlice";
import { PROFILES, userSelect } from "../../store/slices/userSlice";
import { useTranslation } from "react-i18next";
import { NewRelease } from "../NewRelease";

interface HomePageInterface {
  navbarColor?: string;
}

export const NotificationButton = ({ navbarColor }: HomePageInterface) => {
  const [openDropdown, setOpenDropDown] = useState<boolean>(false);
  const [, setIsHovered] = useState(false);
  const [notifications, setNotifications] = useState<any>();
  const [showTooltip, setShowTooltip] = useState<any>({
    id: "",
    show: false,
  });

  const [announcementProperties, setAnnouncementProperties] = useState<{
    show: boolean;
    announcement: any;
  }>({ show: false, announcement: null });

  const ref = useRef<HTMLDivElement>(null);
  const { request } = useApi({ path: "/notifications" });
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelect);

  const handleMarkAllAsRead = () => {
    request({ method: "put", pathParameters: `/read-all` }).then((response) =>
      getNotification(),
    );
  };

  const handleMarkAsRead = (date: number, read?: boolean) => {
    if (!read) {
      request({ method: "put", pathParameters: `/read/${date}` }).then(
        (response) => {
          getNotification();
        },
      );
    }
  };

  const clearCurrentCompany = ({ type }: any) => {
    if (
      [PROFILES.EXECUTIVO_MASTER, PROFILES.REPRESENTANTE].includes(
        user.profile,
      ) &&
      type === NOTIFICATION_TYPES.TO_EXPIRE_INTEGRATED_PEOPLE
    ) {
      dispatch(appSetCurrentCompany(null));
    }
  };

  const { t } = useTranslation();

  const translatedStatus: any = {
    "1": t("dictionary.status.NORMAL"),
    "3": t("dictionary.status.ALERTA"),
    "5": t("dictionary.status.RISCO"),
    "7": t("dictionary.status.CRITICAL") ?? "CRÍTICO",
  };

  const notificationDetails = ({
    param,
  }: any): { text: string; link: string } => {
    const { type, parameters }: any = param || {};

    switch (type) {
      case NOTIFICATION_TYPES.RESPONSIBLE_USER_CREATED: {
        const { facilityName, facilityId, companyName, companyId } = parameters;
        return {
          text: `Atenção | Você agora é responsável pela Unidade: ${facilityName}, contida na Empresa: ${companyName}`,
          link: `/companies/${companyId}/facilities/${facilityId}/assets`,
        };
      }

      case NOTIFICATION_TYPES.RESPONSIBLE_USER_DELETED: {
        const { facilityName, facilityId, companyName, companyId } = parameters;
        return {
          text: `Atenção | Você foi removido como Usuário Responsável pela Unidade: ${facilityName}, contida na Empresa: ${companyName}`,
          link: `/companies/${companyId}/facilities/${facilityId}/assets`,
        };
      }

      case NOTIFICATION_TYPES.OCCURRENCE_TO_EXPIRE:
        const isExpired = parameters.daysLeft < 0;

        const optionText = isExpired
          ? `${t("NotificationButton.expired")}`
          : `${t("NotificationButton.closeToExpire")}`;

        return {
          text: `${parameters.assetName} - ${t(
            "NotificationButton.expireAsset",
          )} ${optionText}! ${t("NotificationButton.clickToKnow")}`,
          link: `/positions/${parameters.positionId}?tab=occurrences`,
        };

      case NOTIFICATION_TYPES.TO_EXPIRE_INTEGRATED_PEOPLE:
        let { name, daysToExpire, companyId } = parameters || {};

        if (daysToExpire) {
          return {
            text: ` ${name} - ${t(
              "NotificationButton.companyHasIntegration",
            )} ${daysToExpire} ${t("NotificationButton.daysExpiring")}. ${t(
              "NotificationButton.clickToKnow",
            )}`,
            link: `/configuration/${companyId}/details?tab=people`,
          };
        }

        return {
          text: ` ${name} - ${t("NotificationButton.integratedPeopleNext")} ${t(
            "NotificationButton.clickToKnow",
          )}`,
          link: "",
        };

      case NOTIFICATION_TYPES.NEW_RELEASE:
        return {
          text: `${t("NotificationButton.newUpdate")}`,
          link: "",
        };
      case NOTIFICATION_TYPES.BOLTS_OFFLINE:
        return {
          text: `${parameters?.name} - ${t(
            "NotificationButton.boltIsOffline",
          )}`,
          link: `/companies/${parameters?.companyId}/facilities/${parameters?.facilityId}/assets?sidetab=bolt`,
        };
      case NOTIFICATION_TYPES.SENSORS_OFFLINE:
        let companyName = parameters?.companyName;
        const totalPositions = parameters?.positions?.length;

        const lost =
          totalPositions > 1
            ? `${t("NotificationButton.losts")}`
            : `${t("NotificationButton.lost")}`;
        const sensor =
          totalPositions > 1
            ? `${t("NotificationButton.sensors")}`
            : `${t("NotificationButton.sensor")}`;
        return {
          text: `${totalPositions} ${sensor} ${lost} ${t(
            "NotificationButton.conectionCompany",
          )} ${companyName}`,
          link: `/companies/${parameters?.companyId}/facilities?tab=positions`,
        };
      case NOTIFICATION_TYPES.SENSORS_RECALL:
        const totalRecall = parameters?.positions?.length;
        const sensors =
          totalRecall > 1
            ? `${t("NotificationButton.sensors")}`
            : `${t("NotificationButton.sensor")}`;
        const addressed =
          totalRecall > 1
            ? `${t("NotificationButton.addresseds")}`
            : `${t("NotificationButton.addressed")}`;

        return {
          text: `${t("NotificationButton.company")} ${
            parameters?.companyName
          } ${t(
            "NotificationButton.has",
          )} ${totalRecall} ${sensors} ${addressed} ${t(
            "NotificationButton.toRecall",
          )}`,
          link: `/companies/${parameters?.companyId}/facilities?tab=positions`,
        };

      case NOTIFICATION_TYPES.SENSORS_LOW_BATTERY:
        return {
          text: `${t("NotificationButton.company")} ${
            parameters?.companyName
          } ${t("NotificationButton.hasLowBattery")} `,
          link: `/companies/${parameters?.companyId}/facilities?tab=positions`,
        };

      case NOTIFICATION_TYPES.ASSET_STATUS_UPDATED: {
        const {
          companyId,
          facilityId,
          assetId,
          assetName,
          isResponsibleUserNotification,
          companyName,
          facilityName,
        } = parameters;

        if (isResponsibleUserNotification) {
          return {
            text: `Atenção - Mudança de Status - 
             \nEmpresa: ${companyName} \nUnidade: ${facilityName} Ativo: ${assetName}  `,
            link: `/companies/${companyId}/facilities/${facilityId}/assets/${assetId}`,
          };
        }

        return {
          text: `${t("NotificationButton.Atenção - O ativo")} ${assetName} ${t(
            "NotificationButton.teve seu status alterado",
          )}

          ${t("NotificationButton.De")}: 
          ${translatedStatus?.[parameters?.previousSysStatusId]}
          ${t("NotificationButton.Para")}: 
          ${translatedStatus?.[parameters?.currentSysStatusId]}`,
          link: `/companies/${companyId}/facilities/${facilityId}/assets/${assetId}?tab=occurrences`,
        };
      }
    }

    const { assetName, occurrenceDiagnostic, assetId, companyId, facilityId } =
      parameters || {};

    return {
      text: `${t("NotificationButton.yourAsset")} ${assetName} ${t(
        "NotificationButton.hasProblem",
      )} ${occurrenceDiagnostic}`,
      link: `/companies/${companyId}/facilities/${facilityId}/assets/${assetId}?tab=occurrences`,
    };
  };

  const getNotification = () => {
    if (process.env.REACT_APP_IS_LOCAL === "true") {
      return;
    }
    request({ method: "get", skipToast: true }).then((response) => {
      setNotifications(response);
    });
  };

  useEffect(() => {
    getNotification();
    const interval = setInterval(() => {
      getNotification();
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnClickOutSide(ref, () => setOpenDropDown(false));

  // useEffect(() => {
  //   if (!isMobile && openDropdown && !isHovered) {
  //     let closeMenu = setTimeout(() => setOpenDropDown(false), 2000);
  //     return () => {
  //       clearTimeout(closeMenu);
  //     };
  //   }
  //   // eslint-disable-next-line
  // }, [openDropdown, isHovered]);

  const handleShowAnnouncement = ({ type, announcement }: any) => {
    // type === NOTIFICATION_TYPES.NEW_RELEASE &&
    //   dispatch(setShowAnnouncement(true));
    setAnnouncementProperties({ show: true, announcement });
  };

  return (
    <Container ref={ref}>
      <Button
        onClick={() => setOpenDropDown(!openDropdown)}
        selected={openDropdown}
        navbarColor={navbarColor}
      >
        {notifications?.notifications?.filter(
          (notification: any) => !notification.read,
        ).length && !openDropdown ? (
          <QuantitySpan>
            {
              notifications?.notifications?.filter(
                (notification: any) => !notification.read,
              ).length
            }
          </QuantitySpan>
        ) : null}
        {openDropdown ? (
          <MdClose size={30} color={theme.colors.ibbxOrange} />
        ) : (
          <VscBell size={22} />
        )}
      </Button>

      <>
        <Triangle visible={openDropdown} />
        <DropdownContainerNotifications
          visible={openDropdown}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <HeaderNotifications>
            <label>{t("NotificationButton.notifications")}</label>
            <ReadAllButton
              onClick={handleMarkAllAsRead}
              disabled={
                !(notifications?.notifications || []).filter(
                  (item: any) => !item.read,
                ).length
              }
            >
              {t("NotificationButton.markAsRead")}
            </ReadAllButton>
          </HeaderNotifications>
          <ContentNotifications>
            {notifications?.notifications?.length ? (
              notifications?.notifications
                ?.sort((a: any, b: any) => b.date - a.date)
                .map((notification: any, index: number) => (
                  <NotificationContainer
                    key={index}
                    to={
                      notificationDetails({
                        param: notification,
                      })?.link
                    }
                    onClick={() => {
                      handleMarkAsRead(notification.date, notification.read);
                      clearCurrentCompany({ type: notification.type });
                      handleShowAnnouncement({
                        type: notification.type,
                        announcement: notification,
                      });
                    }}
                  >
                    <div>
                      <label>
                        {/* {notification.parameters
                          .isResponsibleUserNotification && (
                          <ContractType>
                            <span>SOLaaS</span>
                          </ContractType>
                        )} */}
                        {
                          notificationDetails({
                            param: notification,
                          })?.text
                        }
                      </label>
                      <span>
                        {new Intl.DateTimeFormat("pt-BR", {
                          dateStyle: "short",
                          timeStyle: "medium",
                        }).format(notification.date)}
                      </span>
                    </div>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        handleMarkAsRead(notification.date, notification.read);
                      }}
                    >
                      <UnreadSpan
                        isRead={notification?.read}
                        onMouseOver={() =>
                          setShowTooltip({ id: index, show: true })
                        }
                        onMouseLeave={() =>
                          setShowTooltip({ id: index, show: false })
                        }
                      ></UnreadSpan>
                      {!notification?.read &&
                        showTooltip.id === index &&
                        showTooltip.show && (
                          <TooltipContainer show={false}>
                            {t("NotificationButton.markAsRead")}
                          </TooltipContainer>
                        )}
                    </div>
                  </NotificationContainer>
                ))
            ) : (
              <EmptyNotification>
                <ImFileEmpty size={60} />
                {t("NotificationButton.noNotification")}
              </EmptyNotification>
            )}
          </ContentNotifications>
        </DropdownContainerNotifications>

        <NewRelease
          announcements={announcementProperties.announcement}
          show={announcementProperties.show}
          setShow={() =>
            setAnnouncementProperties({ show: false, announcement: null })
          }
        />
      </>
    </Container>
  );
};
