import { useEffect, useState, useMemo } from "react";
import Plot from "react-plotly.js";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { Loading } from "../../Loading";

import { Container, InfoContainer, RotationContainer } from "./styles";
import { CascadeGraphProps, ChartData, getCascadeLayout } from "./types";
import { cascadeMediaScreenSize } from "../../../utils/mediaQuery";
import {
  BiDownArrowAlt,
  BiLeftArrowAlt,
  BiRightArrowAlt,
  BiUpArrowAlt,
} from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { appSelect } from "../../../store/slices/appSlice";
import useApi from "../../../hooks/useApi";
import { isEdge, isOpera } from "react-device-detect";

export const CascadeGraph = ({
  chart,
  selectDate,
  selectedSerie,
}: CascadeGraphProps) => {
  // Hooks
  const params = useParams();
  const { customItems } = useAppSelector(appSelect);
  const theme = useTheme();
  const { colors } = theme;
  const [rotation, setRotation] = useState<any>({
    x: 0.5,
    y: 0.4,
    z: 2,
  });

  const { t } = useTranslation();
  // State
  const [processing, setProcessing] = useState(true);
  const [chartData, setChartData] = useState([]);

  const path = `/positions/${params.positionId}/charts/${chart.name}/cascade`;
  const { request } = useApi({ path });

  const metricUnits = useMemo(() => {
    const { metricUnits } = chart;
    if (metricUnits.x === "Hz") {
      metricUnits.x = customItems?.frequencyUnit || "Hz";
    }
    return metricUnits;
  }, [chart, customItems]);

  const data: any[] = useMemo(
    () =>
      chartData.map(({ data, series, date }: ChartData, index) => {
        const xAxisTemp: number[] = [];
        const yAxisTemp: number[] = [];
        const zAxisTemp = [];

        const dateFormat = new Intl.DateTimeFormat("pt-BR", {
          dateStyle: "short",
          timeStyle: "medium",
        }).format(new Date(date));

        const axisIndex = selectedSerie ? series.indexOf(selectedSerie) : 1;

        //percorre todo os pontos e monta um array
        for (let i = 0; i < data.length; i++) {
          let collectTemp = data[i];
          const value = collectTemp[axisIndex];
          const isNotNull = !!value?.toString();
          if (isNotNull) {
            xAxisTemp.push(collectTemp[0]);
            yAxisTemp.push(value);
            zAxisTemp.push(dateFormat);
          }
        }

        var trace: any = {
          name: dateFormat,
          x: xAxisTemp,
          y: yAxisTemp,
          z: zAxisTemp,
          Text: ["Frequência", "Amplitude", "Data"],
          type: "scatter3d",
          mode: "lines",
          line: {
            width: 3,
            color: index,
            reversescale: false,
          },
          hovertemplate:
            `${t("commonText.Frequency")}: %{x:} ${metricUnits.x}<br>` +
            `${t("commonText.Amplitude")}: %{y:} ${metricUnits.y}<br>`,
        };

        return trace;
      }),
    [chartData, selectedSerie, metricUnits, t],
  );

  const handleChangeRotation = (e: any) => {
    if (e.target.id === "x") {
      setRotation({ ...rotation, x: +e.target.value });
    }

    if (e.target.id === "y") {
      setRotation({ ...rotation, y: +e.target.value });
    }

    if (e.target.id === "z") {
      setRotation({ ...rotation, z: +e.target.value });
    }
  };

  useEffect(() => {
    setProcessing(true);
    const queryStringParameters: { [key: string]: any } = {
      date: selectDate,
    };

    request({ method: "get", queryStringParameters }).then((data) => {
      setChartData(data);
      setProcessing(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cascadeSize = cascadeMediaScreenSize(
    window.innerWidth,
    window.innerHeight,
  );

  return (
    <Container
      data-tooltip-title={t("cascade.download")}
      isOpera={isOpera}
      isEdge={isEdge}
    >
      {processing ? (
        <Loading />
      ) : (
        <>
          <Plot
            divId="cascade-plot"
            data={data}
            onAfterExport={() => null}
            onBeforeExport={() => null}
            layout={getCascadeLayout(
              colors,
              metricUnits,
              cascadeSize,
              rotation,
            )}
            config={{
              modeBarButtons: [["toImage"]],
              scrollZoom: true,
              displaylogo: false,
              showTips: false,
              modeBarButtonsToRemove: [
                "tableRotation",
                "resetCameraDefault3d",
                "resetCameraLastSave3d",
              ],
              displayModeBar: true,
              toImageButtonOptions: {
                format: "jpeg", // one of png, svg, jpeg, webp
                filename: `cascata-espectral-${chart.label}`,
                height: 750,
                width: 1050,
                scale: 1, // Multiply title/legend/axis/canvas sizes by this factor
              },
            }}
          />
          <InfoContainer isOpera={isOpera} isEdge={isEdge}>
            <RotationContainer>
              <div>
                <label>{t("cascade.rotate")}</label>
              </div>
              <div>
                <BiLeftArrowAlt />
                <BiRightArrowAlt />
              </div>
              <input
                id="x"
                type="range"
                step={0.1}
                min={-0.5}
                max={1.9}
                value={rotation.x}
                onChange={handleChangeRotation}
              />
              <div>
                <BiDownArrowAlt />
                <BiUpArrowAlt />
              </div>
              <input
                id="y"
                type="range"
                step={0.1}
                min={0}
                max={1.9}
                value={rotation.y}
                onChange={handleChangeRotation}
              />
            </RotationContainer>
          </InfoContainer>
        </>
      )}
    </Container>
  );
};
