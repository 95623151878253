import { useEffect, useMemo, useRef, useState } from "react";
import { BiDotsVerticalRounded, BiSend } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { CgFileDocument } from "react-icons/cg";
import { RxSpeakerLoud, RxSpeakerOff, RxTextAlignLeft } from "react-icons/rx";
import useApi from "../../hooks/useApi";
import useOnClickOutSide from "../../hooks/useOnClickOutside";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";
import { PROFILES, userSelect } from "../../store/slices/userSlice";

import {
  Chat,
  CloseOptions,
  Container,
  DotsAnimation,
  Message,
  NotificationSpan,
  Options,
  PromptContainer,
  StopGenerating,
} from "./styles";
import { TypeAnimation } from "react-type-animation";
import ReactTooltip from "react-tooltip";
import { ModalPrintChat } from "./ModalPrintChat";
import { CiSquareCheck } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { HiChevronRight } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useSegment } from "../../hooks/useSegmentTrackEvent ";
import { useTranslation } from "react-i18next";

interface IRetinaChart {
  isVisible?: boolean;
}

export const RetinaChat = ({ isVisible }: IRetinaChart) => {
  const user = useAppSelector(userSelect);
  const app = useAppSelector(appSelect);
  const menuChat = useRef(null);

  const location = useLocation();

  const { handleTrackEvent } = useSegment();

  const { currentCompany, currentFacility } = app;
  const { name } = user;

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [showPDFModal, setShowPDFModal] = useState<boolean>(false);
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [question, setQuestion] = useState<any>();
  const [sentMessageList, setSentMessageList] = useState<any>([]);
  const [deliveredMessageList, setDeliveredMessageList] = useState<any>([]);
  const [conversation, setConversation] = useState<any>([]);
  const [summaryMessage, setSummaryMessage] = useState<any>();
  const [occurrenceCount, setOccurrenceCount] = useState<any>();
  const [assetsStatus, setAssetStatus] = useState<any>();
  const [talkMessage, setTalkMessage] = useState<boolean>(true);
  const [utterance, setUtterance] = useState<SpeechSynthesisUtterance | null>(
    null,
  );
  const [isLoadedAfter2Secs, setIsLoadedAfter2Secs] = useState(false);
  const [notification, setNotification] = useState<boolean>(true);
  const [stopAutoScroll, setStopAutoScroll] = useState<boolean>(false);
  const [summaryMessageWasRead, setSummaryMessageWasRead] =
    useState<boolean>(false);

  const [errorOnSend, setErrorOnSend] = useState<boolean>(false);

  const { request, processing } = useApi({ path: "/send-message" });
  const { request: requestAssetStatus } = useApi({
    path: "/assets-status",
  });

  const { request: requestOccurrences } = useApi({
    path: "/dashboards/occurrences-count",
  });

  const { pathname } = location;

  const shouldNotAppear = ["occurrences", "configuration", "esgPanel"].includes(
    pathname.split("/")[1],
  );

  const chatContainerRef = useRef<any>(null);
  const ref = useRef<any>(null);

  useOnClickOutSide(ref, () => {
    setIsChatOpen(false);
    stopSpeak();
  });

  useOnClickOutSide(menuChat, () => {
    setShowOptions(false);
  });

  const startSpeak = (answer: any) => {
    const newUtterance = new SpeechSynthesisUtterance(answer);
    newUtterance.lang = "pt-BR";
    newUtterance.rate = 2.3;
    setUtterance(newUtterance);
    window.speechSynthesis.speak(newUtterance);
  };

  const stopSpeak = () => {
    if (utterance) {
      window.speechSynthesis.cancel();
      setUtterance(null);
    }
  };

  const getSummary = () => {
    const queryStringParameters = {
      companyId: +currentCompany.id !== 0 ? currentCompany.id : null,
      facilityId: +currentFacility.id !== 0 ? currentFacility.id : null,
    };

    Promise.all([
      requestAssetStatus({ method: "get", queryStringParameters }),
      requestOccurrences({ method: "get", queryStringParameters }),
    ]).then(([responseAsset, responseOccurrence]) => {
      setAssetStatus(responseAsset);
      setOccurrenceCount(responseOccurrence.countOpen);
    });
  };

  const { t } = useTranslation();

  const formatContext = () => {
    return conversation
      .filter((item: any) => !item.isSummary)
      .map(({ message }: any, index: number) =>
        index % 2 === 0
          ? t("RetinaChat.question") + ":" + message
          : t("RetinaChat.answer") + ":" + message,
      )
      .join("\n");
  };

  const askToChat = async (question: any) => {
    const context = conversation.length ? formatContext() : null;

    request({
      method: "post",
      body: { question: question + " ?", context, maxTokens: 2000 },
      skipToast: true,
    }).then((response) => {
      setDeliveredMessageList([
        ...deliveredMessageList,
        {
          id: new Date().getTime(),
          message:
            response.answer.replace("\n", "") || t("RetinaChat.redoQuestion"),
          isQuestion: false,
          read: false,
        },
      ]);
    });
  };

  const handleSubmit = async () => {
    const isLastResponseRead = deliveredMessageList.length
      ? deliveredMessageList[deliveredMessageList.length - 1].read
      : true;

    const isSummaryMessageRead = summaryMessage?.read ? true : false;

    if (
      !processing &&
      isLastResponseRead &&
      question?.trim() !== "" &&
      (isSummaryMessageRead || summaryMessage === null)
    ) {
      setErrorOnSend(false);
      setSentMessageList([
        ...sentMessageList,
        {
          id: new Date().getTime(),
          message: question,
          isQuestion: true,
          read: true,
        },
      ]);
      setQuestion("");
      await askToChat(question);
    } else {
      setErrorOnSend(true);
    }
  };

  const handlePressEnter = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit();
      e.preventDefault();
    }
  };

  const handleCloseAndCleanChat = () => {
    stopSpeak();
    setSentMessageList([]);
    setDeliveredMessageList([]);
    setConversation([]);
    setSummaryMessage(null);
  };

  const setAsRead = (isSummary?: boolean, id?: number, message?: any) => {
    if (isSummary && summaryMessage) {
      setSummaryMessage({ ...summaryMessage, read: true });
    } else if (!isSummary && conversation && id) {
      const deliveredMessagesWithoutTheCurrentMessage =
        deliveredMessageList.filter((item: any) => item.id !== id);

      const AllMessageReadTrue = [
        ...deliveredMessagesWithoutTheCurrentMessage,
        { ...message, read: true },
      ].sort((a: any, b: any) => a.id - b.id);

      setDeliveredMessageList(AllMessageReadTrue);
    }
  };

  const renderMessage = ({ message, isSummary, id }: any) => {
    return (
      <div>
        {message.read ||
        message.id < conversation[conversation?.length - 1]?.id ? (
          <label>{message?.message}</label>
        ) : (
          <TypeAnimation
            sequence={[
              message.message,
              100,
              () => setAsRead(isSummary, id, message),
            ]}
            speed={50}
            style={{ fontSize: "1rem" }}
            wrapper="span"
          />
        )}
      </div>
    );
  };

  const handleSkipTypingAnimation = (
    id: any,
    isSummary?: any,
    message?: any,
  ) => {
    setAsRead(isSummary, id, message);
    stopSpeak();
  };

  const handleMute = () => {
    setTalkMessage(!talkMessage);
    localStorage.setItem("isMute", String(!talkMessage));
  };

  const handleDownloadCSVChat = () => {
    setShowPDFModal(true);
  };

  const timeoutRef = useRef<any>(null);

  const handleScroll = (event: any) => {
    setStopAutoScroll(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setStopAutoScroll(false);
    }, 1000); // Adjust the delay time to your preference
  };

  const handleCleanNotification = () => {
    setNotification(false);
  };

  useEffect(() => {
    getSummary();

    if (localStorage.getItem("isMute")) {
      localStorage.getItem("isMute") === "true"
        ? setTalkMessage(true)
        : setTalkMessage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.currentCompany.id, app.currentFacility.id]);

  const messageByLevel = useMemo(
    () => {
      if (
        app.currentCompany.id &&
        !app.currentFacility.id &&
        ![PROFILES.CLIENTE_MASTER, PROFILES.CLIENTE_COMUM].includes(
          user.profile,
        )
      ) {
        return `${t("RetinaChat.companyText.part1")} ${
          app.currentCompany.name
        } ${t("RetinaChat.companyText.part2")}
        ${t("RetinaChat.companyText.part3")} ${occurrenceCount},
        ${t("RetinaChat.companyText.part4")} ${assetsStatus?.alert},
        ${t("RetinaChat.companyText.part5")} ${assetsStatus?.risk}`;
      }
      if (
        app.currentCompany.id &&
        app.currentFacility.id &&
        ![PROFILES.CLIENTE_COMUM].includes(user.profile)
      ) {
        return `${t("RetinaChat.facilityText.part1")} ${
          app.currentFacility.name
        } ${t("RetinaChat.facilityText.part2")}
        ${t("RetinaChat.facilityText.part3")} ${occurrenceCount},
        ${t("RetinaChat.facilityText.part4")} ${assetsStatus?.alert},
        ${t("RetinaChat.facilityText.part5")} ${assetsStatus?.risk}`;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [occurrenceCount, assetsStatus],
  );

  useEffect(() => {
    const isCompanyPage = app.currentCompany.id && !app.currentFacility.id;
    const isFacilityPage = !!app.currentFacility.id;

    const shouldAddMoreCompanySummaryMessage = !deliveredMessageList?.filter(
      (item: any) =>
        item.isCompanyPage && item.companyId === app.currentCompany.id,
    ).length;
    const shouldAddMoreFacilitySummaryMessage = !deliveredMessageList?.filter(
      (item: any) =>
        item.isFacilityPage && item.facilityId === app.currentFacility.id,
    ).length;

    if (occurrenceCount && assetsStatus && summaryMessage?.read) {
      const id = new Date().getTime();

      if (
        (isCompanyPage && shouldAddMoreCompanySummaryMessage) ||
        (isFacilityPage && shouldAddMoreFacilitySummaryMessage)
      ) {
        setDeliveredMessageList([
          ...deliveredMessageList,
          {
            id: id,
            message: messageByLevel,
            read: false,
            isCompanyPage,
            isFacilityPage,
            companyId: app.currentCompany.id,
            facilityId: app.currentFacility.id,
            isSummary: true,
          },
        ]);

        // setNotification(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageByLevel]);

  useEffect(() => {
    const lastSummaryMessage =
      deliveredMessageList[deliveredMessageList?.length - 1];
    if (lastSummaryMessage?.isSummary && !lastSummaryMessage.read) {
      setNotification(true);
    }
  }, [deliveredMessageList]);

  useEffect(() => {
    if (occurrenceCount && assetsStatus && !summaryMessage?.read) {
      setSummaryMessage({
        id: -1,
        message: `${t("RetinaChat.summary.part1")} ${name.split(" ")[0]}${t(
          "RetinaChat.summary.part2",
        )}\n${t("RetinaChat.summary.part3")}\n${t(
          "RetinaChat.summary.part4",
        )} ${occurrenceCount}, \n${t("RetinaChat.summary.part5")}${
          assetsStatus?.alert
        },\n${t("RetinaChat.summary.part6")} ${assetsStatus?.risk}.`,
        read: false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occurrenceCount, assetsStatus]);

  useEffect(() => {
    if (summaryMessage?.read) {
      getSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.currentCompany.id, app.currentFacility.id]);

  useEffect(() => {
    setConversation(
      [...sentMessageList, ...deliveredMessageList].sort((a, b) => a.id - b.id),
    );
  }, [sentMessageList, deliveredMessageList]);

  useEffect(() => {
    const lastAnswer = deliveredMessageList[deliveredMessageList.length - 1];

    if (talkMessage && !lastAnswer?.read && isChatOpen) {
      startSpeak(lastAnswer?.message);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveredMessageList, isChatOpen]);

  useEffect(() => {
    if (
      talkMessage &&
      !summaryMessage?.read &&
      !summaryMessageWasRead &&
      isChatOpen &&
      !shouldNotAppear
    ) {
      setSummaryMessageWasRead(true);
      startSpeak(summaryMessage?.message);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryMessage, isChatOpen]);

  useEffect(() => {
    stopSpeak();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talkMessage]);

  useEffect(() => {
    if (isChatOpen && !stopAutoScroll && !shouldNotAppear) {
      const intervalId = setInterval(() => {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current?.scrollHeight;
      }, 500);

      return () => {
        clearInterval(intervalId);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, isChatOpen, stopAutoScroll]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoadedAfter2Secs(true);
    }, 2000); // Set delay time to 2 seconds

    return () => clearTimeout(delay);
  }, []);

  if (shouldNotAppear) {
    return null;
  }

  const handleShowChat = () => {
    setIsChatOpen(true);
    handleCleanNotification();
    handleTrackEvent({
      event: "RetinaChat",
    });
  };

  return (
    <Container show={isChatOpen} title="Retina Chat" isVisible={isVisible}>
      <div onClick={handleShowChat}>{!isChatOpen && <RxTextAlignLeft />}</div>
      {isLoadedAfter2Secs && notification && (
        <NotificationSpan>!</NotificationSpan>
      )}
      {isChatOpen && (
        <Chat ref={ref}>
          <header>
            <div>
              <label>Retina Chat</label>
              <label>Beta</label>
            </div>
            <div>
              {showOptions && (
                <>
                  <Options ref={menuChat}>
                    {!isMobile && (
                      <>
                        <span
                          data-tip
                          data-for="report"
                          onClick={() => handleDownloadCSVChat()}
                        >
                          <CgFileDocument size={20} />
                        </span>
                        <ReactTooltip
                          id="report"
                          type="dark"
                          place="top"
                          effect="solid"
                        >
                          {t("RetinaChat.print")}
                        </ReactTooltip>
                      </>
                    )}

                    <span
                      onClick={() => {
                        handleCloseAndCleanChat();
                      }}
                      data-tip
                      data-for="delete"
                    >
                      <MdDeleteOutline size={20} />
                    </span>
                    <ReactTooltip
                      id="delete"
                      type="dark"
                      place="top"
                      effect="solid"
                    >
                      {t("RetinaChat.clear")}
                    </ReactTooltip>
                    <CloseOptions onClick={() => setShowOptions(false)}>
                      <HiChevronRight size={20} />
                    </CloseOptions>
                  </Options>
                </>
              )}

              <span
                onClick={() => {
                  setShowOptions(true);
                }}
              >
                <BiDotsVerticalRounded size={20} />
              </span>
              <span onClick={() => handleMute()} data-tip data-for="speaker">
                {talkMessage ? (
                  <RxSpeakerLoud size={20} />
                ) : (
                  <RxSpeakerOff size={20} />
                )}
              </span>
              <ReactTooltip id="speaker" type="dark" place="top" effect="solid">
                {talkMessage ? "Voz Habilitada" : "Voz Desabilitada"}
              </ReactTooltip>
              <span
                onClick={() => {
                  setIsChatOpen(false);
                  stopSpeak();
                }}
                data-tip
                data-for="closeAndClean"
              >
                <CgClose size={20} />
              </span>
              <ReactTooltip
                id="closeAndClean"
                type="dark"
                place="top"
                effect="solid"
              >
                {t("RetinaChat.close")}
              </ReactTooltip>
            </div>
          </header>
          <body
            ref={chatContainerRef}
            onClick={() => handleSkipTypingAnimation(0, !conversation?.length)}
            onScroll={handleScroll}
          >
            {summaryMessage && (
              <Message>
                {renderMessage({
                  message: summaryMessage,
                  isSummary: true,
                })}
              </Message>
            )}
            {conversation?.map((msg: any, index: number) => (
              <>
                <Message
                  isQuestion={msg?.isQuestion}
                  isLast={index + 1 === conversation.length}
                >
                  {msg?.isQuestion ? (
                    <div>
                      <label>{msg.message}</label>
                    </div>
                  ) : (
                    renderMessage({ message: msg, id: msg.id })
                  )}
                </Message>

                {!msg.read && !msg.isSummary && (
                  <StopGenerating
                    onClick={() =>
                      handleSkipTypingAnimation(msg.id, msg.read, msg)
                    }
                    data-tip
                    data-for="skip"
                  >
                    <CiSquareCheck size={20} />
                  </StopGenerating>
                )}
              </>
            ))}
            {processing ? (
              <DotsAnimation>
                <div></div>
                <div></div>
                <div></div>
              </DotsAnimation>
            ) : null}
          </body>
          <PromptContainer errorOnSend={errorOnSend}>
            <textarea
              spellCheck="false"
              onChange={(e) => {
                if (
                  errorOnSend &&
                  deliveredMessageList[deliveredMessageList.length - 1]?.read
                ) {
                  setErrorOnSend(false);
                }
                setQuestion(e?.target?.value);
              }}
              value={question}
              onKeyDown={(e) => handlePressEnter(e)}
              placeholder={`${t("RetinaChat.askme")}`}
            ></textarea>
            <button onClick={handleSubmit} disabled={processing}>
              <BiSend />
            </button>
          </PromptContainer>
        </Chat>
      )}
      {showPDFModal && (
        <ModalPrintChat
          show={showPDFModal}
          handleClose={() => setShowPDFModal(false)}
          conversation={conversation}
        />
      )}
    </Container>
  );
};
