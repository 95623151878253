import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "react-bootstrap";

import {
  MdClose,
  MdDelete,
  MdOutlineEdit,
  MdWifi,
  MdContentCopy,
  MdFiberManualRecord,
} from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  TbHeartRateMonitor,
  TbWifi,
  TbWifi1,
  TbWifi2,
  TbWifiOff,
} from "react-icons/tb";
import { FaImages, FaExchangeAlt } from "react-icons/fa";

import BoltImg from "../../../images/Bolt.svg";

import {
  CardBoltWrap,
  CardAssetImageDiv,
  CardBoltBodyDiv,
  CardBoltDivId,
  CardBoltDivName,
  DetailsContainer,
  DetailsContent,
  DetailsHeader,
  DetailsCopy,
  DotArea,
  HideMenuAsset,
  IconBase,
  PulseCircleConnected,
  RssiContainer,
  SecondPulseCircleConnected,
  SidebarItemBolt,
  StatusBolt,
  StatusBoltText,
  Triangle,
  WifiStatus,
  DetailsFooter,
  MenuExpandedBolt,
  CardBoltFooter,
  BoltOperationModeContainer,
} from "./styles";

import { CardBoltProps } from "./types";

import { NamePlusTooltip } from "../../../components/NamePlusTooltip";
import useOnClickOutSide from "../../../hooks/useOnClickOutside";
import useApi from "../../../hooks/useApi";
import { success } from "../../../components/Toast";
import { useAppSelector } from "../../../store/hooks";
import { ImageGallery } from "../../../components/ImageGallery";
import { appSelect } from "../../../store/slices/appSlice";
import { ModalBoltMonitoring } from "../../../components/ModalBoltMonitoring";

const CardBolt = ({
  bolt,
  handleEdit,
  handleDelete,
  handleClick,
  selected,
  reloadBolt,
}: CardBoltProps) => {
  const app = useAppSelector(appSelect);
  const optionsRef = useRef<any>(null);
  const menuRef = useRef<any>(null);
  const detailsRef = useRef<any>(null);
  const wholeRef = useRef<any>(null);

  const { t } = useTranslation();

  const [showMenu, setShowMenu] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [showMonitoringModal, setShowMonitoringModal] = useState(false);

  const { request: requestReset } = useApi({
    path: `/companies/${bolt.companyId}/facilities/${bolt.facilityId}/bolts/${bolt.gatewayId}/reset`,
  });

  const { request: requestResetChip } = useApi({
    path: `/companies/${bolt.companyId}/facilities/${bolt.facilityId}/bolts/${bolt.gatewayId}/reset-chip`,
  });

  const { request: requestBoltOperationMode } = useApi({
    path: `/companies/${bolt.companyId}/facilities/${bolt.facilityId}/gateways/${bolt.gatewayId}/operation-mode`,
  });

  let connection = "";
  let connectionCompany = "";
  if (bolt.implantation) {
    connection = bolt.implantation.cloudConnection.type;
    connectionCompany = bolt.implantation.cloudConnection.provider;
  }

  const connectionDetails =
    bolt.implantation?.cloudConnection?.type !== "WI-FI"
      ? connectionCompany
      : connection;

  const sectorColor = bolt?.sectorDetail?.length
    ? bolt?.sectorDetail[0]?.color
    : null;

  // click outside
  useOnClickOutSide(menuRef, () => setShowMenu(false), optionsRef);

  useOnClickOutSide(wholeRef, () => setShowDetails(false));

  const handleClickCard = useCallback(
    (event: any) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as HTMLElement) &&
        !menuRef?.current?.contains(event.target as HTMLElement) &&
        !detailsRef?.current?.contains(event.target as HTMLElement)
      ) {
        handleClick();
      }

      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as HTMLElement) &&
        !menuRef?.current?.contains(event.target as HTMLElement) &&
        !detailsRef?.current?.contains(event.target as HTMLElement)
      ) {
        setShowDetails(!showDetails);
      }
    },
    // eslint-disable-next-line
    [handleClick, showDetails],
  );

  const handleClickResetBolt = useCallback(() => {
    requestReset({
      method: "put",
    }).then(() => {
      success(`${t("cardBolt.boltResetMessage")}`);
    });
    // eslint-disable-next-line
  }, []);

  const handleClickResetChip = useCallback(() => {
    requestResetChip({
      method: "put",
    }).then(() => {
      success(`${t("cardBolt.boltChipResetMessage")}`);
    });
    // eslint-disable-next-line
  }, []);

  const handleClickChangeOperationMode = useCallback(
    (operationMode: "AUTOMATIC" | "CONFIGURATION") => {
      requestBoltOperationMode({
        method: "put",
        body: {
          operationMode,
        },
      }).then(() => {
        success(`${t("cardBoltsOperationMode.changeOperationModeSuccess")}`);
        reloadBolt();
      });
    },
    [requestBoltOperationMode, t, reloadBolt],
  );

  const isOffline = !bolt.isOnline;

  const boltId = bolt.gatewayId;
  const boltIdArray = Array.from(boltId);
  const latsSix = boltIdArray.slice(-6);

  const { firmwareVersion } = bolt?.healthCheckDetails?.system || {};

  const isWIFI = String(firmwareVersion).toUpperCase().includes("WIFI");
  const isGSM = String(firmwareVersion).toUpperCase().includes("GSM");

  const getBoltConnectionRSSI = () => {
    var rssi;

    if (isOffline) {
      return (
        <NamePlusTooltip tooltip={t("cardBolt.noSignal")} position={"top"}>
          <WifiStatus status="OFF">
            <TbWifiOff />
          </WifiStatus>
        </NamePlusTooltip>
      );
    }

    if (isWIFI) {
      rssi = bolt?.healthCheckDetails?.wiFiNetwork?.rssi;

      if (rssi >= -55) {
        return (
          <NamePlusTooltip
            tooltip={t("cardBolt.excellentSignal")}
            position={"top"}
          >
            <WifiStatus status="HIGH">
              <span>RSSI: {rssi}</span>
              <TbWifi />
            </WifiStatus>
          </NamePlusTooltip>
        );
      } else if (rssi >= -65) {
        return (
          <NamePlusTooltip tooltip={t("cardBolt.goodSignal")} position={"top"}>
            <div>
              <span>RSSI: {rssi}</span>
              <TbWifi2 />
            </div>
          </NamePlusTooltip>
        );
      } else {
        return (
          <NamePlusTooltip tooltip={t("cardBolt.badSignal")} position={"top"}>
            <div>
              <span>RSSI: {rssi}</span>
              <TbWifi1 />
            </div>
          </NamePlusTooltip>
        );
      }
    } else if (isGSM) {
      rssi = bolt?.healthCheckDetails?.mobileNetwork?.rssi;
      if (rssi >= 20) {
        return (
          <NamePlusTooltip
            tooltip={t("cardBolt.excellentSignal")}
            position={"top"}
          >
            <WifiStatus status="HIGH">
              <span>RSSI: {rssi}</span>
              <TbWifi />
            </WifiStatus>
          </NamePlusTooltip>
        );
      } else if (rssi >= 15) {
        return (
          <NamePlusTooltip tooltip={t("cardBolt.goodSignal")} position={"top"}>
            <div>
              <span>RSSI: {rssi}</span>
              <TbWifi2 />
            </div>
          </NamePlusTooltip>
        );
      } else {
        return (
          <NamePlusTooltip tooltip={`Sinal Ruim`} position={"top"}>
            <div>
              <span>RSSI: {rssi}</span>
              <TbWifi1 />
            </div>
          </NamePlusTooltip>
        );
      }
    }
    return (
      <NamePlusTooltip tooltip={t("cardBolt.badSignal")} position={"top"}>
        <WifiStatus status="OFF">
          <TbWifiOff />
        </WifiStatus>
      </NamePlusTooltip>
    );
  };

  const handleEditClick = () => {
    setShowMenu(false);
    handleEdit();
  };

  const handleDeleteClick = () => {
    setShowMenu(false);
    handleDelete();
  };

  const { colorOperationMode, translateOperationMode } = useMemo(() => {
    let translateOperationMode = t(
      `cardBoltsOperationMode.status.${bolt.operationMode}`,
      bolt.operationMode,
    );
    let colorOperationMode;

    switch (bolt.operationMode) {
      case "AUTOMATIC":
        colorOperationMode = "success";
        break;
      case "CONFIGURATION":
        colorOperationMode = "warning";
        break;
      default:
        colorOperationMode = "secondary";
        break;
    }

    return { translateOperationMode, colorOperationMode };
  }, [t, bolt]);

  return (
    <>
      {showGallery && (
        <ImageGallery
          id={bolt.gatewayId}
          show={showGallery}
          type="GATEWAY"
          handleClose={() => setShowGallery(false)}
        />
      )}
      {showMonitoringModal && (
        <ModalBoltMonitoring
          show={showMonitoringModal}
          handleClose={() => setShowMonitoringModal(false)}
          gateway={bolt}
        />
      )}
      <CardBoltWrap onClick={handleClickCard} ref={wholeRef}>
        <SidebarItemBolt sectorColor={sectorColor}>
          <StatusBolt>
            {isOffline ? (
              <StatusBoltText>
                <MdFiberManualRecord size={12} />
                <span>OFFLINE</span>
              </StatusBoltText>
            ) : (
              <StatusBoltText online>
                <MdFiberManualRecord size={12} />
                <span>ONLINE</span>
              </StatusBoltText>
            )}
          </StatusBolt>
          <CardAssetImageDiv>
            {isOffline ? null : (
              <>
                <PulseCircleConnected></PulseCircleConnected>
                <SecondPulseCircleConnected></SecondPulseCircleConnected>
              </>
            )}
            <img className="boltimg" src={BoltImg} alt="" />
          </CardAssetImageDiv>
          <CardBoltBodyDiv>
            <DotArea>
              {!app.isReadOnly && (
                <HideMenuAsset
                  onClick={() => setShowMenu(!showMenu)}
                  ref={optionsRef}
                  name="dropdown-button"
                >
                  {showMenu ? (
                    <MdClose size={20} />
                  ) : (
                    <BsThreeDotsVertical size={18} />
                  )}
                </HideMenuAsset>
              )}
              {showMenu ? (
                <>
                  <Triangle />
                  <MenuExpandedBolt ref={menuRef}>
                    <div onClick={handleEditClick}>
                      <MdOutlineEdit size={18} />
                      <span>{t("cardBolt.edit")}</span>
                    </div>
                    {!bolt.isReadOnly && bolt.operationMode === "AUTOMATIC" && (
                      <div
                        onClick={() =>
                          handleClickChangeOperationMode("CONFIGURATION")
                        }
                      >
                        <FaExchangeAlt size={18} />
                        <span>
                          {t("cardBoltsOperationMode.status.CONFIGURATION")}
                        </span>
                      </div>
                    )}
                    {!bolt.isReadOnly &&
                      bolt.operationMode === "CONFIGURATION" && (
                        <div
                          onClick={() =>
                            handleClickChangeOperationMode("AUTOMATIC")
                          }
                        >
                          <FaExchangeAlt size={18} />
                          <span>
                            {t("cardBoltsOperationMode.status.AUTOMATIC")}
                          </span>
                        </div>
                      )}
                    <div onClick={() => setShowGallery(true)}>
                      <FaImages size={18} />
                      <span>{t("cardBolt.Imagens")}</span>
                    </div>
                    <div onClick={() => setShowMonitoringModal(true)}>
                      <TbHeartRateMonitor size={18} />
                      <span>{t("cardBolt.Monitoramento")}</span>
                    </div>
                    {!bolt.isReadOnly && (
                      <div onClick={handleDeleteClick}>
                        <MdDelete size={18} />
                        <span>{t("cardBolt.delete")}</span>
                      </div>
                    )}
                  </MenuExpandedBolt>
                </>
              ) : null}
            </DotArea>

            <CardBoltDivName>
              <NamePlusTooltip tooltip={bolt.name} position={"top"}>
                <label>{bolt.name}</label>
              </NamePlusTooltip>
              {bolt.operationMode && bolt.isOnline && (
                <BoltOperationModeContainer>
                  <Badge pill bg={colorOperationMode}>
                    <span>{`${translateOperationMode}`}</span>
                  </Badge>
                </BoltOperationModeContainer>
              )}
            </CardBoltDivName>
            <CardBoltDivId>
              <span>ID:&nbsp; </span>
              <span>{latsSix.join("")}</span>
            </CardBoltDivId>
            <CardBoltFooter>
              <div>
                <span>
                  {bolt.synchronizedPositions}/
                  {bolt.parameters?.radioWindowNumBoardSlots || "120"}
                  &nbsp;{t("commonText.position")}
                  {bolt.synchronizedPositions > 1 && "s"}{" "}
                  {t("cardBolt.synchronized")}
                  {bolt.synchronizedPositions > 1 && "s"}
                </span>
              </div>

              <div>
                <div>
                  {bolt.healthCheckDetails?.wiFiNetwork?.ssid ? (
                    <>
                      <MdWifi size={15} />
                      <span>WI-FI</span>
                    </>
                  ) : bolt?.healthCheckDetails?.mobileNetwork?.simCCID ? (
                    <>
                      <IconBase>4G</IconBase>
                      <span>{connectionDetails}</span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
                <div>
                  <span>{bolt.operationFrequency} MHz</span>
                </div>
                <RssiContainer>{getBoltConnectionRSSI()}</RssiContainer>
              </div>
            </CardBoltFooter>
          </CardBoltBodyDiv>
        </SidebarItemBolt>
        {selected && (
          <DetailsContainer ref={detailsRef}>
            <DetailsHeader>
              <span>{t("cardBolt.gatewayDetails")}</span>
            </DetailsHeader>
            <DetailsContent>
              <DetailsCopy>
                <span>Mac Address:&nbsp; </span>
                {bolt?.healthCheckDetails?.wiFiNetwork?.macAddress && (
                  <>
                    <span>
                      {bolt?.healthCheckDetails?.wiFiNetwork?.macAddress}
                    </span>
                    <button
                      title={`${t("cardBolt.copy")}`}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          bolt?.healthCheckDetails?.wiFiNetwork?.macAddress,
                        );
                      }}
                    >
                      <MdContentCopy size={13} />
                    </button>
                  </>
                )}
              </DetailsCopy>
            </DetailsContent>
            {isWIFI ? (
              <DetailsContent>
                <span>SSID:&nbsp; </span>
                <span>{bolt?.healthCheckDetails?.wiFiNetwork?.ssid}</span>
              </DetailsContent>
            ) : (
              <>
                <DetailsContent>
                  <DetailsCopy>
                    <span>{t("cardBolt.operator")}:&nbsp; </span>
                    <span>
                      {bolt?.healthCheckDetails?.mobileNetwork?.operator}
                    </span>
                  </DetailsCopy>
                </DetailsContent>
                <DetailsContent>
                  <DetailsCopy>
                    <span>IMEI:&nbsp; </span>
                    {bolt?.healthCheckDetails?.mobileNetwork?.imei && (
                      <>
                        <span>
                          {bolt?.healthCheckDetails?.mobileNetwork?.imei}
                        </span>
                        <button
                          title={`${t("cardBolt.copy")}`}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              bolt?.healthCheckDetails?.mobileNetwork?.imei,
                            );
                          }}
                        >
                          <MdContentCopy size={13} />
                        </button>
                      </>
                    )}
                  </DetailsCopy>
                </DetailsContent>
                <DetailsContent>
                  <DetailsCopy>
                    <span>sim CCID:&nbsp; </span>
                    {bolt?.healthCheckDetails?.mobileNetwork?.simCCID && (
                      <>
                        <span>
                          {bolt?.healthCheckDetails?.mobileNetwork?.simCCID}
                        </span>
                        <button
                          title={`${t("cardBolt.copy")}`}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              bolt?.healthCheckDetails?.mobileNetwork?.simCCID,
                            );
                          }}
                        >
                          <MdContentCopy size={13} />
                        </button>
                      </>
                    )}
                  </DetailsCopy>
                </DetailsContent>
              </>
            )}
            <DetailsFooter>
              {!isOffline && (
                <button onClick={handleClickResetBolt}>
                  {t("cardBolt.resetGatewayButton")}
                </button>
              )}
              {isGSM && (
                <button onClick={handleClickResetChip}>
                  {t("cardBolt.resetChipButton")}
                </button>
              )}
            </DetailsFooter>
          </DetailsContainer>
        )}
      </CardBoltWrap>
    </>
  );
};

export default CardBolt;
