import { Modal as RBModal } from "react-bootstrap";
import { ConfirmModal } from "../../modals/ConfirmModal";
import { ButtonCancel, ButtonSave, ModalFrame, RBModalBody } from "./styles";
import { ModalProps } from "./types";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { RefObject } from "react";
import Message from "./components/Message";
// import 'bootstrap/dist/css/bootstrap.min.css';

interface IForm {
  children: JSX.Element;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
  ref: RefObject<FormikProps<any>>;
}

function Form({ children, onSubmit, ref }: IForm) {
  return (
    <Formik innerRef={ref} initialValues={{}} onSubmit={onSubmit}>
      {children}
    </Formik>
  );
}

interface IBody {
  children: JSX.Element | JSX.Element[];
  ajustes?: string;
  styles?: any;
}

function Body({ children, ajustes = "", styles = {} }: IBody) {
  return (
    <RBModalBody className={`border-0 ${ajustes}`} styles={styles}>
      {children}
    </RBModalBody>
  );
}

interface IFooter {
  children: JSX.Element | JSX.Element[];
}

function Footer({ children }: IFooter) {
  return <RBModal.Footer className="border-0">{children}</RBModal.Footer>;
}

interface IConfirmButton {
  handleClose: () => void;
  text?: string;
  isEsgPanel?: boolean;
}

function ConfirmButton({ handleClose, text, isEsgPanel }: IConfirmButton) {
  const { t } = useTranslation();
  text = text ?? (t("commonText.wishToCancel") as string);

  return (
    <ConfirmModal onConfirm={handleClose} text={text} isEsgPanel={isEsgPanel} />
  );
}

interface ISave {
  children?: JSX.Element | string;
  disabled?: boolean;
  handleConfirm?: () => void;
  type?: "button" | "submit";
}

function Save({ children, disabled, handleConfirm = () => null, type = "button" }: ISave) {
  const { t } = useTranslation();
  return (
    <ButtonSave type={type} disabled={!!disabled} onClick={handleConfirm}>
      {children ?? t("commonText.save")}
    </ButtonSave>
  );
}

interface ICancel {
  children?: JSX.Element | string;
  isEsgPanel?: boolean;
  handleClose: () => void;
}

function Cancel({ children, isEsgPanel, handleClose }: ICancel) {
  const { t } = useTranslation();
  return (
    <ButtonCancel
      type="button"
      onClick={handleClose}
      isEsgPanel={isEsgPanel}
      id="cancel"
    >
      {children ?? t("commonText.cancel")}
    </ButtonCancel>
  );
}

function ModalCompound({
  show,
  handleClose,
  hideSaveButton,
  hideCancelButton,
  handleConfirm,
  confirmLabel,
  cancelLabel,
  children,
  height,
  ajustes,
  borda,
  circular,
  color,
  changeFotter,
  confirmCancel,
  disabled,
  backgroundColor,
  borderColor,
  fontColor,
  ...rest
}: ModalProps) {
  return (
    <RBModal
      style={{ zIndex: "10000", padding: 0 }}
      show={show}
      onHide={handleClose}
      centered
      contentClassName="border-0"
      dialogClassName="modal-100w"
      {...rest}
    >
      <ModalFrame
        className={`${circular} border-${borda || 1} ${color}`}
        height={height}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
      >
        {/* <RBModal.Header style={{border: 'none',}}>
            <RBModal.Title></RBModal.Title>
          </RBModal.Header> */}
        {children}
      </ModalFrame>
    </RBModal>
  );
}

ModalCompound.Form = Form;
ModalCompound.Footer = Footer;
ModalCompound.Body = Body;
ModalCompound.Message = Message;
ModalCompound.ConfirmButton = ConfirmButton;
ModalCompound.Cancel = Cancel;
ModalCompound.Save = Save;

export default ModalCompound;
