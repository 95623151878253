import { DefaultTheme } from "styled-components";
import { STATUS_OCCURRENCE } from "../store/slices/occurrenceSlice";
import { STATUS_POSITION } from "../store/slices/positionSlice";

export const getStatusColor = (theme: DefaultTheme, status: string) => {
  switch (status) {
    case STATUS_POSITION.RISK:
    case STATUS_OCCURRENCE.RISK:
    case STATUS_OCCURRENCE.RISK_ANALYSIS:
      return theme.colors.statusRisk;
    case STATUS_POSITION.ALERT:
    case STATUS_OCCURRENCE.ALERT:
    case STATUS_OCCURRENCE.ALERT_ANALYSIS:
      return theme.colors.statusAlert;
    default:
      return theme.colors.normalStatus;
  }
};

export const getStatusColorById = (
  theme: DefaultTheme,
  sysStatusId: number,
  processing?: boolean,
) => {
  const STATUS_COLORS: any = {
    0: theme.colors.iconsSecondaryAlpha,
    1: theme.colors.normalStatus,
    2: theme.colors.statusAlert,
    3: theme.colors.statusAlert,
    4: theme.colors.ibbxOrange,
    5: theme.colors.statusRisk,
    6: theme.colors.statusRisk,
    7: theme.colors.statusRisk,
  };

  return STATUS_COLORS[sysStatusId] ?? theme.colors.normalStatus;
};

export const getStatusById = (id: number) => {
  switch (id) {
    case 5:
      return STATUS_OCCURRENCE.RISK;
    case 4:
      return STATUS_OCCURRENCE.RISK_ANALYSIS;
    case 3:
      return STATUS_OCCURRENCE.ALERT;
    case 2:
      return STATUS_OCCURRENCE.ALERT_ANALYSIS;
    default:
      return STATUS_OCCURRENCE.NORMAL;
  }
};

export const getStylesByStatusId = (statusId: 1 | 2 | 3 | 4 | 5 | 6 | 7) => {
  switch (statusId) {
    case 5:
    case 4:
      return "risk 2.5s ease-in-out infinite both;";
    case 3:
    case 2:
      return "alert 3s ease-in-out infinite both;";
    default:
      return "alert 3s ease-in-out infinite both;";
  }
};

export const getStylesByStatus = (status: string | undefined) => {
  switch (status) {
    case STATUS_POSITION.RISK:
    case STATUS_OCCURRENCE.RISK:
    case STATUS_OCCURRENCE.RISK_ANALYSIS:
      return "risk 2.5s ease-in-out infinite both;";
    case STATUS_POSITION.ALERT:
    case STATUS_OCCURRENCE.ALERT:
    case STATUS_OCCURRENCE.ALERT_ANALYSIS:
      return "alert 3s ease-in-out infinite both;";

    case STATUS_OCCURRENCE.NORMAL:
      return "alert 3s ease-in-out infinite both;";
  }
};

export const getBadgeColorByOwner = (
  theme: DefaultTheme,
  analysisOwner: any,
) => {
  const OWNER_COLORS: any = {
    NOC: theme.colors.ibbxOrange,
    INTEGRATOR: theme.colors.statusBroken,
    CLIENT: theme.colors.statusAlert,
    null: "",
  };
  return OWNER_COLORS[analysisOwner] ?? "";
};
