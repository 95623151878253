import { useEffect, useState, useCallback } from "react";
import {
  BatteryChart,
  BatteryChartContainer,
  BatteryDetails,
  BatteryDetailsContainer,
  BatteryInfoContainer,
  BatteryInfoEmpty,
  BatteryStatusChartContainer,
  BatteryStatusLoadingContainer,
} from "./styles";
import {
  formatDate,
  labeledNumber,
  roundNumber,
} from "../../../utils/utilsFunctions";
import { Loading } from "../../Loading";
import { SensorStatusComponentProps } from "../types";
import { chartDataEmpty, chartDataModel } from "../../charts/chart/types";
import useApi from "../../../hooks/useApi";
import { useTranslation } from "react-i18next";
import { EmptyChart, Footer } from "../styles";
import { RiFileChartLine } from "react-icons/ri";
import { Chart } from "../../charts/chart";
import { SeriesSelector } from "../../charts/seriesSelector";
import { chartAreaType } from "../../../utils/charts/getOptions";
import useSeriesControl from "../../../hooks/useSeriesControl";
import { IChart } from "../../../store/interfaces";
import { BatteryInfo } from "./components/BatteryInfo";

const chartArea: chartAreaType = {
  height: "85%",
  width: "90%",
  bottom: 40,
  top: 15,
};

export const BatteryStatus = ({
  position,
  period,
}: SensorStatusComponentProps) => {
  const [processing, setProcessing] = useState(false);
  const [batteryInfo, setBatteryInfo] = useState<any>(null);

  const [voltageData, setVoltageData] =
    useState<chartDataModel>(chartDataEmpty);
  const [percentData, setPercentData] =
    useState<chartDataModel>(chartDataEmpty);

  const {
    seriesControl: percentSeries,
    buildSeriesControl: buildPercentSeries,
    handleSeries: handlePercentSeries,
  } = useSeriesControl();

  const {
    seriesControl: voltageSeries,
    buildSeriesControl: buildVoltageSeries,
    handleSeries: handleVoltageSeries,
  } = useSeriesControl();

  const { request } = useApi({
    path: `/positions/${position.id || position.positionId}/battery`,
  });

  useEffect(() => {
    getChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position.id || position.positionId, period]);

  const { t } = useTranslation();

  const translatedSeriesNames: any = {
    voltage: `${t("modalSensorStatus.voltage")}`,
    percent: `${t("modalSensorStatus.percent")}`,
    expected: `${t("modalSensorStatus.calculated")}`,
    Bateria: `${t("modalSensorStatus.battery")}`,
    Alerta: `${t("dictionary.status.Alert")}`,
  };

  function getChartData() {
    if (!position) {
      return;
    }

    setProcessing(true);

    const queryStringParameters = { period };
    request({ method: "get", queryStringParameters })
      .then((response) => {
        setBatteryInfo(response);
        const voltage = response["voltage"];
        voltage.data = voltage.data.map(([date, ...rest]: any[]) => [
          new Date(date),
          ...rest,
        ]);

        buildVoltageSeries(voltage);
        setVoltageData(voltage);

        const percent = response["percent"];
        percent.data = percent.data.map(([date, ...rest]: any[]) => [
          new Date(date),
          ...rest,
        ]);

        buildPercentSeries(percent);
        setPercentData(percent);
      })
      .finally(() => setProcessing(false));
  }

  let rate =
    (batteryInfo?.measuredAverageCurrent / batteryInfo?.nominalAverageCurrent) *
    100;
  rate = +rate.toFixed(2);
  rate = 100 - rate;

  if (
    batteryInfo?.measuredAverageCurrent > batteryInfo?.nominalAverageCurrent
  ) {
    rate = rate * -1;
  } else {
    rate = rate * -1;
  }

  const day = t("modalSensorStatus.day");
  const days = t("modalSensorStatus.day") + "s";

  function getBatteryLifeTime() {
    if (!!batteryInfo?.expectedMeasuredTotalHours?.toString()) {
      return (
        labeledNumber(
          hoursToDay(batteryInfo.expectedMeasuredTotalHours),
          day,
          days,
        ) || ""
      );
    }
    return "Inválido";
  }

  function hoursToDay(hours: number) {
    return Math.ceil(hours / 24);
  }

  const voltageChart: IChart = {
    name: "battery",
    type: "GLOBAL",
    positionId: position?.id || 0,
    label: `${t("modalSensorStatus.battery")}`,
    series: ["voltage", "expected"],
    seriesName: [
      translatedSeriesNames["voltage"],
      translatedSeriesNames["expected"],
    ],
    metricUnits: { x: "date", y: "V" },
  };

  const percentChart: IChart = {
    name: "battery",
    type: "GLOBAL",
    positionId: position?.id || 0,
    label: `${t("modalSensorStatus.battery")}`,
    series: ["percent", "expected"],
    seriesName: [
      translatedSeriesNames["percent"],
      translatedSeriesNames["expected"],
    ],
    metricUnits: percentData.metricUnits || { x: "date", y: "%" },
  };

  if (!voltageData?.data?.length && !percentData?.data?.length && !processing) {
    return (
      <BatteryInfoEmpty>
        <EmptyChart>
          <RiFileChartLine size={45} />
          <span>{t("modalSensorStatus.noDataOnPeriod")}</span>
        </EmptyChart>
      </BatteryInfoEmpty>
    );
  }

  return (
    <>
      {processing ? (
        <BatteryStatusLoadingContainer>
          <div>
            <Loading />
          </div>
        </BatteryStatusLoadingContainer>
      ) : (
        <BatteryStatusChartContainer>
          <div>
            <BatteryDetailsContainer>
              <BatteryDetails>
                <BatteryInfo
                  label={t("modalSensorStatus.initialVoltage")}
                  value={
                    `${roundNumber(batteryInfo?.firstMeasuredVoltage, 3)} V` ??
                    ""
                  }
                />
                <BatteryInfo
                  label={t("modalSensorStatus.batteryVoltage")}
                  value={
                    `${roundNumber(batteryInfo?.lastMeasuredVoltage, 3)} V` ??
                    ""
                  }
                />
                <BatteryInfo
                  label={t("modalSensorStatus.consumption")}
                  value={`${batteryInfo?.measuredAverageCurrent} mA`}
                />
                <BatteryInfo
                  label={t("modalSensorStatus.calculatedConsumption")}
                  value={`${
                    roundNumber(batteryInfo?.nominalAverageCurrent, 2) || ""
                  } mA`}
                />
                <BatteryInfo
                  label={t("modalSensorStatus.lifeTime")}
                  value={getBatteryLifeTime()}
                />
                <BatteryInfo
                  label={t("modalSensorStatus.calculatedLifeTime")}
                  value={
                    labeledNumber(
                      hoursToDay(batteryInfo?.expectedNominalTotalHours),
                      day,
                      days,
                    ) || ""
                  }
                />
                <BatteryInfo
                  label={t("modalSensorStatus.batteryDrain")}
                  value={
                    formatDate(batteryInfo?.expectedMeasuredDeathTimestamp) ||
                    ""
                  }
                />
                <BatteryInfo
                  label={t("modalSensorStatus.ratio")}
                  value={roundNumber(rate) || ""}
                />
              </BatteryDetails>
            </BatteryDetailsContainer>
          </div>
          <BatteryInfoContainer>
            <BatteryChartContainer>
              {voltageData?.data?.length ? (
                <BatteryChart>
                  <h2>{`${t("modalSensorStatus.voltage")}`} (V)</h2>
                  <Chart
                    chartData={voltageData}
                    seriesControl={voltageSeries}
                    chartArea={chartArea}
                    chart={voltageChart}
                    yTicks={[0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]}
                  />
                  <Footer>
                    <SeriesSelector
                      seriesControl={voltageSeries}
                      handleSeries={handleVoltageSeries}
                      chart={voltageChart}
                    />
                  </Footer>
                </BatteryChart>
              ) : (
                <EmptyChart>
                  <RiFileChartLine size={45} />
                  <span>{t("modalSensorStatus.noDataOnPeriod")}</span>
                </EmptyChart>
              )}

              {percentData?.data?.length ? (
                <BatteryChart>
                  <h2>{`${t("modalSensorStatus.percent")}`} (%)</h2>
                  <Chart
                    chartData={percentData}
                    seriesControl={percentSeries}
                    chartArea={chartArea}
                    chart={percentChart}
                    yTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                  />
                  <Footer>
                    <SeriesSelector
                      seriesControl={percentSeries}
                      handleSeries={handlePercentSeries}
                      chart={percentChart}
                    />
                  </Footer>
                </BatteryChart>
              ) : (
                <EmptyChart>
                  <RiFileChartLine size={45} />
                  <span>{t("modalSensorStatus.noDataOnPeriod")}</span>
                </EmptyChart>
              )}
            </BatteryChartContainer>
          </BatteryInfoContainer>
        </BatteryStatusChartContainer>
      )}
    </>
  );
};
