import styled from "styled-components";

export const ContainerAsset = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 24px;
  padding: 24px;
  width: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.2 : "")};
  max-height: 70vh;
  overflow: auto;

  h1 {
    width: 100%;
    text-align: left;
    ${(props) => props.theme.fonts.primary.H1Semibold};
    color: ${(props) => props.theme.colors.textPrimary};
  }

  h2 {
    ${(props) => props.theme.fonts.primary.H2Bold};
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  /* Phone Portrait */
  @media screen and (max-width: 767px) {
    padding: 0;

    h1 {
      ${(props) => props.theme.fonts.primary.H2Bold};
    }
  }

  /* Tablet Portrait */
  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
    padding: 0;
    overflow: hidden;

    h1 {
      ${(props) => props.theme.fonts.primary.H2Bold};
    }
  }

  /* Tablet Landscape */
  @media screen and (min-width: 827px) and (max-width: 1279px) {
    padding: 1rem;
    gap: 1rem;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    display: block;
    padding: 0;

    & > div {
      gap: 1rem;
    }

    & > div:nth-child(1) {
      right: 0;
    }

    & > span {
      ${(props) => props.theme.fonts.primary.H2Bold};
    }

    div {
      ::-webkit-scrollbar {
        display: none;
      }
      -webkit-scrollbar-width: none;
      -moz-scrollbar-width: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
    & > span {
      ${(props) => props.theme.fonts.primary.H2Bold};
    }

    & > div:nth-child(2) {
      flex-direction: column;
      display: block;
    }
  }
`;

export const Line = styled.div`
  @media screen and (max-width: 767px) {
    width: 100%;
    background: ${(props) => props.theme.colors.iconsSecondary};
    height: 0.07rem;
    border-radius: 10rem;
    margin: 2rem 0;
  }
  @media screen and (min-width: 1023px) {
    display: none;
  }
`;

export const AssetInputRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  & > div {
    flex: 1 0 200px;
  }
`;

export const SideDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px !important;
  max-width: 265px;
  width: 100%;
  max-height: 550px;
  height: auto;
  overflow-x: hidden;
  padding-right: 5px;
  border-radius: 12px;

  & > div {
    display: flex;
    background-color: ${(props) => props.theme.colors.cardHighlight};
    color: ${(props) => props.theme.colors.iconsSecondary};
    width: 100%;
    width: 240px;
    min-height: 240px;
    border-radius: 12px;

    div {
      &:hover {
        opacity: 0.75;
        cursor: pointer;
      }
      svg {
        max-width: 240px;
        max-height: 240px;
      }
    }

    svg:last-child {
      margin: auto;
      font-size: 100px;
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    border-radius: 0;
    margin-top: 1rem;
    padding-bottom: 1rem;
    align-items: center;
    margin: auto;

    & > div {
      & > div {
        width: 100%;
        height: auto;
      }
    }

    &::-webkit-scrollbar {
      display: none;
      width: 0 !important;
    }
    -webkit-scrollbar-width: none;
    -moz-scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
    order: 2;
    margin: 0 auto 1rem auto;
  }
`;

export const FormsContainer = styled.div`
  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    height: auto;
    input,
    select,
    textarea {
      font-size: 1rem;
    }
    & > div {
      margin-top: 16px;
      // (Tag / Modelo / Fabricante)
      & > div:nth-child(5) {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;

        & > div:nth-child(1) {
          // TAG
          width: 100%;
        }
        & > div:nth-child(2) {
          // Modelo
          width: 100%;
        }
        & > div:nth-child(3) {
          // Fabricante
          width: 100%;
        }
      }

      // (Valor / Função / Potência)
      & > div:nth-child(6) {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        /* margin: 0.6rem 0 0.8rem 0; */
        & > div:nth-child(3) {
          width: 100%;
          order: 1;
        }
        & > div:nth-child(2) {
          width: 100%;
          order: 2;
        }
        & > div:nth-child(1) {
          width: 100%;
          order: 3;
        }
      }

      // (Criticidade / Idade do Equipamento)
      & > div:nth-child(7) {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        & > div:nth-child(3) {
          width: 100%;
          order: 1;
        }
        & > div:nth-child(2) {
          width: 47%;
          order: 2;
        }
        & > div:nth-child(1) {
          width: 47%;
          order: 3;
        }
      }
    }
  }
  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
  }
`;

export const FormAssetFooter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-right: 24px;
  gap: 24px;
  margin: 21px 0;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const FormAssetBody = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  width: 100%;
  gap: 45px;
  overflow-y: scroll;
  max-height: 500px;

  ::-webkit-scrollbar {
    display: none;
  }
  -webkit-scrollbar-width: none;
  -moz-scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  & > div {
    padding: 0px 14px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-height: 520px;
    overflow-y: auto;

    & > div {
      display: flex;
      flex-direction: column;
      label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    display: block;

    & > div {
      max-height: initial;
      padding: 0;
      overflow: auto;
      ::-webkit-scrollbar {
        display: none;
      }
      -webkit-scrollbar-width: none;
      -moz-scrollbar-width: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
`;

export const MobileImagesDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div:last-child {
    margin: auto;
    background-color: ${(props) => props.theme.colors.cardHighlight};
    color: ${(props) => props.theme.colors.iconsSecondary};
    width: 100%;
    width: 240px;
    /* min-height: 240px; */
    border-radius: 12px;

    div {
      &:hover {
        opacity: 0.75;
        cursor: pointer;
      }
      svg {
        max-width: 240px;
        max-height: 240px;
      }
    }

    svg:last-child {
      margin: auto;
      font-size: 100px;
      color: ${(props) => props.theme.colors.iconsPrimary};
    }
  }
`;

export const ImportantFieldsCounterContainer = styled.div`
  width: 100%;
  height: 30px;
  padding-left: 38px;
`;

export const ImportantCountContainer = styled.div<{ full?: boolean }>`
  width: 200px;
  height: 30px;
  z-index: 100;
  position: absolute;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  ${(props) => props.theme.fonts.primary.H5SemiBold};
  color: ${(props) =>
    props.full
      ? props.theme.colors.itemsHighlight
      : props.theme.colors.StatusTools};
  background-color: ${(props) => props.theme.colors.inputBlock};
  -webkit-box-shadow: inset 6px 6px 14px -8px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: inset 6px 6px 14px -8px rgba(0, 0, 0, 0.24);
  box-shadow: inset 6px 6px 14px -8px rgba(0, 0, 0, 0.24);
  br {
    display: none;
  }

  @media screen and (max-width: 767px) {
    margin-top: 0px;
    z-index: 100;
    left: 4px;
    bottom: 4px;
    margin-top: 1.5rem;
    width: 6rem;
    height: 2.5rem;

    & > div:last-child {
      & > span:first-child {
        display: none;
      }
    }

    flex-direction: column;
    br {
      display: block;
    }
  }
`;

export const LabelContainer = styled.label`
  display: flex;
  gap: 5px;
  position: relative;
  z-index: 2;

  & > span {
    color: ${(props) => props.theme.colors.iconsSecondary} !important;
  }

  & > svg {
    width: 18px;
    height: 18px;
  }

  & > button {
    position: absolute;
    top: 1rem;
    right: -4px;
  }
`;
