import styled from "styled-components";
import { ScollableDiv } from "../frame/styles";
import { css } from "styled-components";

export const PositionListContainer = styled.div<{
  showSidebar?: boolean;
  isTablet?: boolean;
  isExpanded?: boolean;
}>`
  background-color: ${(props) => props.theme.colors.divBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  display: grid !important;
  grid-template-columns: 0.1fr repeat(4, 1fr);
  grid-template-rows: 0.3fr repeat(4, 1fr);
  height: 100%;
  padding: 8px;

  ${(props) =>
    props.showSidebar &&
    css`
      display: grid !important;
      grid-template-columns: 0.5fr repeat(4, 1fr);
      grid-template-rows: 0.3fr repeat(4, 1fr);
    `}

  @media screen and (max-width: 767px) {
    grid-template-rows: 0.4fr repeat(4, 1fr);
    background-color: ${(props) => props.theme.colors.divBackgroundAlpha};
    border: none;
    width: 100%;
    padding: 10px 2px 0px;
    position: relative;
  }

  @media screen and (min-width: 768px) and (max-width: 1280px) {
    height: 100%;
  }

  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
    width: 99%;
  }
`;

export const FilteredListName = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 4px;

  button {
    ${(props) => ({ ...props.theme.fonts.primary.H4Regular })};
    padding: 2px 12px;
    background-color: ${(props) => props.theme.colors.iconsPrimary};
    color: ${(props) => props.theme.colors.background};
    border-radius: 50px;
    margin: 0 4px 4px 4px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    svg {
      width: 18px;
      height: auto;
    }

    &:hover {
      svg {
        transform: scale(1.2);
        transition: all 0.2s ease-in-out;
      }
    }
  }

  @media only screen and (max-width: 1536px) {
    button {
      ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};
      font-size: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0;

    button {
      margin: 0.2rem 0.1rem;
      background-color: transparent;
      color: ${(props) => props.theme.colors.iconsPrimary};
      text-decoration: underline;
      padding: 0 0.1rem !important;
      max-width: 6rem;
      min-width: 2.2rem;

      span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const ClearAllFilters = styled.button`
  text-transform: uppercase;
  background-color: transparent !important;
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  padding: 0 !important;
  border-radius: 0 !important;
  margin: 0 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: ${(props) => props.theme.colors.iconsPrimary};
    font-weight: 700;
    font-size: 12px;
  }

  &:hover {
    span {
      text-decoration: underline;
      transition: all 0.2s ease-in-out;
    }
  }

  @media screen and (max-width: 767px) {
    background-color: ${(props) => props.theme.colors.iconsPrimary} !important;
    color: ${(props) => props.theme.colors.background} !important;
    border-radius: 50px !important;
    padding: 0.3rem !important;
    max-width: 1.2rem !important;
    min-width: 1.2rem !important;
    height: 1.2rem !important;
  }
`;

export const DisplayCardsAndFilter = styled.div<{ showSidebar?: boolean }>`
  display: flex;
  height: 100%;
  overflow: hidden;
  grid-area: 2 / 1 / 6 / 6;

  ${(props) =>
    props.showSidebar &&
    css`
      grid-area: 2 / 2 / 6 / 6;
    `}
`;

export const FilterButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  grid-area: 1 / 1 / 2 / 2;
  margin-left: 2px;
  margin-top: 1px;

  svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 50%;
  }
  @media screen and (max-width: 767px) {
    margin: 0;
    svg {
      width: 1.8rem;
      height: auto;
    }
  }
`;

//Filtro dos pontos monitorados/cards
export const ContainerFilter = styled.div<{ showSidebar?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 2px;
  grid-area: 1 / 2 / 2 / 6;

  & > div {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    /* background-color: ${(props) => props.theme.colors.cardBackground}; */
    /* border: 1px solid ${(props) => props.theme.colors.cardHighlight}; */
    border-radius: 6px;
    padding: 0;
    position: relative;

    & > div {
      flex-direction: column;
      align-items: flex-start;

      & > div:first-child {
        & > div:last-child {
          border: 1px solid blue;
          width: auto;
        }
      }
    }
  }

  & > div {
    display: flex;
  }
`;

export const SidebarFilter = styled.div<{ showSidebar?: boolean }>`
  width: 242px;
  height: 100%;
  background-color: lightgray;
  position: relative;
  background-color: ${(props) => props.theme.colors.headerBackground};
  border-radius: 8px 0 0 8px;

  ${(props) =>
    props.showSidebar &&
    css`
      grid-area: 1 / 1 / 6 / 2;
    `}

  @media only screen and (max-width: 1536px) {
    width: 236px;
    padding-top: 2.5rem;
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    top: 4rem;
    left: 0rem;
    width: 20rem;
    height: 100vh;
    z-index: 2;
    padding: 1rem;
    border-radius: 0px 8px 8px 0px;
  }
`;

export const CloseSidebarButton = styled.button`
  position: absolute;
  left: 0.5rem;
  top: 6px;
  border: none;
  color: ${(props) => props.theme.colors.iconsPrimary};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 50%;
  }

  @media screen and (max-width: 767px) {
    left: auto;
    right: 1rem;
    top: 1rem;
    width: 2rem;
    height: 2rem;
    svg {
      width: 2rem;
      height: auto;
    }
  }
`;

export const FramePositionsBoxes = styled(ScollableDiv)`
  overflow-y: auto;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & > div:last-child {
    flex: 1;
  }

  & > button {
    width: fit-content;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    opacity: 0.3;
    border: none;
    background-color: transparent;
    margin: 0 auto;
    color: ${(props) => props.theme.colors.iconsSecondary};
    ${(props) => ({ ...props.theme.fonts.primary.H4Regular })};
    svg {
      width: 1rem;
      height: auto;
    }

    &:hover {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
  & > div > div {
    padding-top: 10px;
    height: calc(100% - 48px);
    overflow-y: auto;
    column-gap: 12px;
    row-gap: 16px;
    padding: 2px;
    &::-webkit-scrollbar {
      width: 0 !important;
      background: transparent;
    }
    scrollbar-width: none;
  }

  @media screen and (max-width: 767px) {
    & > div {
      width: 100%;
    }
    &::-webkit-scrollbar {
      width: 0 !important;
      background: transparent;
      scrollbar-width: none;
    }
  }
`;

export const FramePositionsBox = styled.div`
  color: ${(props) => props.theme.colors.textPrimary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  width: 300px;
  height: 140px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border-radius: 5px;
`;

// ASSETS VAZIOS -------------------------

export const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  padding: 5rem;

  gap: 20px;

  @media screen and (max-width: 767px) {
    margin-top: 2.5rem;
  }
`;

export const ListedPoints = styled.div`
  ${(props) => ({ ...props.theme.fonts.primary.H4Bold })};
  background-color: ${(props) => props.theme.colors.cardBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 50px;
  padding: 6px;
  min-width: 116px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;

  svg {
    margin-right: 5px;
    stroke: ${(props) => props.theme.colors.iconsPrimary};
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    & > span:last-child {
      display: none;
    }
    width: 68px;

    /* margin-right: 4px; */
  }

  @media only screen and (max-width: 1536px) {
    min-width: 60px;
    height: 26px;

    & > span:last-child {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
    & > span:last-child {
      display: none;
    }
    width: 2.5rem;
    margin: 0 2px;
  }
  svg {
    display: none;
  }
`;

export const SeverityFilter = styled.button`
  ${(props) => ({ ...props.theme.fonts.primary.H4Bold })};
  background-color: ${(props) => props.theme.colors.cardBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 5px;
  padding: 6px;
  width: 116px;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  svg {
    margin-right: 5px;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    & > span:last-child {
      display: none;
    }
    width: 68px;
    /* margin-right: 4px; */
  }

  @media screen and (max-width: 767px) {
    & > span:last-child {
      display: none;
    }
    width: 2.5rem;
    margin: 0 0.4rem 0 0.2rem;
  }
  svg {
    margin-right: 0;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  width: 300px;
  height: 32px;
  border-radius: 6px;
  padding: 6px;
  background-color: ${(props) => props.theme.colors.inputBlock};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight} !important;
  position: relative;

  input {
    width: 268px;
    height: 32px;
    border: none;
    background-color: transparent;
    border-width: 1px 0;
    border-radius: 6px;
    position: relative;
    caret-color: ${(props) => props.theme.colors.textPrimary};
    color: ${(props) => props.theme.colors.textPrimary};

    &::placeholder {
      color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    }

    &:focus {
      outline: none;
    }
  }

  svg {
    position: absolute;
    width: 22px;
    height: auto;
    right: 1px;
  }
`;

export const SearchButtonMobile = styled.button`
  right: 0.25rem;

  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.buttonSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight} !important;
  box-shadow: inset -7px 0px 17px -5px rgba(0, 0, 0, 0.3);
  z-index: 2;

  svg {
    width: 2rem;
    height: auto;
  }
`;

export const SearchContainerMobile = styled.div`
  position: absolute;
  right: 1rem;
  top: 45%;
  transform: translateY(-50%);
  display: flex;
  height: 2.5rem;
  width: 86%;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8.5rem;
  background-color: ${(props) => props.theme.colors.inputBlock};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight} !important;
  padding: 0.5rem 0.8rem;

  input {
    width: 88%;
    height: 2rem;
    border: none;
    background-color: transparent;
    border-width: 1px 0;
    position: relative;
    caret-color: ${(props) => props.theme.colors.textPrimary};
    color: ${(props) => props.theme.colors.textPrimary};

    &::placeholder {
      color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    }

    &:focus {
      outline: none;
    }
  }
`;

export const QRCodeBUtton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  grid-area: 1 / 1 / 2 / 2;
  margin-left: 2px;
  margin-right: 5px;
  margin-top: 1px;

  svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 50%;
  }
  @media screen and (max-width: 767px) {
    margin: 0;
    svg {
      width: 1.8rem;
      height: auto;
    }
  }
`;

export const QrCodeAndSearchContainer = styled.div`
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;

  & button + button {
    margin: 0 8px;
  }
`;
