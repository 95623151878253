import styled, { css } from "styled-components";

//menu gráficos globais, dinâmicos e lista de ocorrência
export const TabContainer = styled.div<{ openEditable?: boolean }>`
  display: flex;
  margin-top: 12px;
  /* width: calc(100% - 4.5rem); */
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 4px;
    @media screen and (max-width: 767px) {
      width: 3px !important;
    }

    @media screen and (min-width: 767px) and (max-width: 1536px) {
      display: none;
    }
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0.4rem;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 1rem;
    width: 100%;

    & > div:first-child {
      margin-left: 1rem;
    }
  }
`;

//Áreas dos cards, gráficos globais e dinâmicos
export const TabContent = styled.div<{
  adjustHeight: string;
  isTablet?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(${(props) => props.adjustHeight} - 38px);
  transition: all 0.3s;
  & > div:first-child {
    display: flex;
  }

  /* @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    height: 86%;
  } */

  @media screen and (max-width: 767px) {
    /* overflow: auto; */
    height: 100%;
    width: 100%;
  }

  /* @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
    width: 99%;
  } */

  /* Landscape */
  @media screen and (min-width: 827px) and (max-width: 1280px) {
    width: 100%;
    height: calc(${(props) => props.adjustHeight} - 1.91rem);
  }
  /* Portrait */
  @media screen and (min-width: 799px) and (max-width: 826px) {
    width: 100%;
    height: calc(${(props) => props.adjustHeight} - 0px);
  }
  ${({ isTablet }) =>
    isTablet &&
    css`
      height: calc(80% + 0.25rem);
    `}
`;

export const TabNameDiv = styled.div<{ marginLeft?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 26px;
  margin-left: ${(props) => props.marginLeft || "16px"};

  @media screen and (max-width: 767px) {
    margin-top: 6px;
    height: 30px;
    z-index: 1;
    margin-left: 10px;
  }
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

export const TabIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 4px;
    width: 18px;
    height: auto;
    color: ${(props) => props.theme.colors.iconsSecondary};
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 767px) {
      width: 20px;
    }
  }
`;

export const TabDescription = styled.div<{ selected?: boolean }>`
  display: flex;
`;

export const TabName = styled.div<{
  selected?: boolean;
  maxContentWidth?: boolean;
}>`
  ${(props) => props.theme.fonts.primary.H4Regular}
  color: ${(props) => props.theme.colors.iconsSecondary};
  font-weight: ${(props) => props.selected && 700};
  height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ maxContentWidth }) =>
    maxContentWidth &&
    css`
      max-width: 10rem;
    `}

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    font-size: 0.75rem;
    ${({ maxContentWidth }) =>
      maxContentWidth &&
      css`
        max-width: 8rem;
      `}
  }
  @media screen and (min-width: 767px) and (max-width: 1280px) {
    font-size: 0.75rem;
    ${({ maxContentWidth }) =>
      maxContentWidth &&
      css`
        max-width: 8rem;
      `}
  }
`;

export const Selection = styled.div<{ selected?: boolean; error?: boolean }>`
  display: ${(props) => (props.selected ? "block" : "none")};
  width: 100%;
  background-color: ${({ theme, error }) =>
    error ? "red" : theme.colors.iconsSecondary};
  height: 2px;
  border-radius: 10px;

  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

export const TabErrorBall = styled.div`
  position: absolute;
  top: 0px;
  right: -19px;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  background-color: red;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  ${({ theme }) => theme.fonts.secondary.H1Bold};
  line-height: 8px;
`;

export const OpenMenuContainer = styled.div<{ openedMenu?: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  z-index: 2;
  /* margin-top: -0.5rem; */
  margin-top: 0.1rem;
  position: relative;
  border: 1px solid
    ${({ openedMenu, theme }) =>
      openedMenu ? theme.colors.iconsSecondaryAlpha : "transparence"};
  border-radius: 50%;

  & > button {
    border: none;
    background-color: transparent;
    color: ${(props) => props.theme.colors.iconsSecondary};
    opacity: ${({ openedMenu }) => (openedMenu ? "1" : "0.4")};
    line-height: 0;
    svg {
      width: ${({ openedMenu }) => (openedMenu ? "1rem" : "1.35rem")};
      height: auto;
      transition: all 0.4s ease-in-out;
    }

    &:hover {
      opacity: 1;
      transition: all 0.3s;
    }
  }
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  right: -0.65rem;
  transform: rotate(180deg);
  border-left: 6px solid ${(props) => props.theme.colors.headerCardSecondary};
  position: absolute;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const EditableTabMenu = styled.div`
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background-color: ${(props) => props.theme.colors.cardBackground};
  ${(props) => props.theme.fonts.primary.H4Regular};
  left: 2rem;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  z-index: 3;
  border-radius: 6px;
  overflow: hidden;

  span {
    white-space: nowrap;
    text-align: left;
  }

  & > div {
    width: 100%;
    cursor: pointer;
    color: ${(props) => props.theme.colors.iconsSecondary};
    &:hover {
      background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      ${(props) => props.theme.fonts.primary.H4Bold};
      transition: all 0.3s ease;
    }

    button {
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: 767px) {
    width: 65%;
    height: 100vh;
    top: 0;
    right: 0;
    border-radius: 6px 0 6px;
    justify-content: flex-start;
    padding: 1rem;
    border-right: 1px solid transparent;
    z-index: 11;
  }
`;

export const LoadingContainer = styled.div`
  padding-bottom: 4px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TabHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 88%;

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    width: 80%;
  }
`;
