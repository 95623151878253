import useApi from "../../../hooks/useApi";
import { success } from "../../Toast";
import {
  MenuItem,
  MenuButton,
  MenuContaner,
  OccurrencePanelHeaderContainer,
  Triangle,
  MenuItemsContainer,
  MenuMobile,
  MenuMobileBackground,
  CategoryContainer,
  CriticalityButton,
} from "./styles";
import { MdClose } from "react-icons/md";
import { FaArrowUpShortWide } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import ConfirmDeleteModal from "../../../modals/ConfirmDeleteForm";
import ConfirmDeleteOccurrence from "../../../modals/ConfirmDeleteOccurrence";
import { ConfirmOccurrenceClose } from "../../../modals/ConfirmOccurrenceClose";
import {
  appSelect,
  setOccurrenceRegister,
} from "../../../store/slices/appSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ModalOccurrenceReport } from "../ModalOccurrenceReport";
import { PROFILES, userSelect } from "../../../store/slices/userSlice";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import { useSegment } from "../../../hooks/useSegmentTrackEvent ";
import OccurrenceForm from "../../../modals/OccurrenceForm";
import { NamePlusTooltip } from "../../NamePlusTooltip";
import { ConfirmModalV2 } from "../../../modals/ConfirmModalV2";
import { useAssetOperationStatus } from "../../../hooks/useOperationStatus";
import { useParams } from "react-router-dom";

export default function OccurrencePanelHeader({
  occurrence,
  occurrenNumber,
  diagnostics,
  evidences,
  categoryName,
  refresh = () => null,
  statusId,
}: any) {
  const { handleTrackEvent } = useSegment();

  const user = useAppSelector(userSelect);
  const dispatch = useAppDispatch();
  const { occurrenceRegister, isReadOnly } = useAppSelector(appSelect);

  const { updateAssetOperationStatus } = useAssetOperationStatus();

  const { assetId, occurrenceId: id } = useParams();

  const { assetOperationStatus, companyId, facilityId } = occurrence;

  const { request, processing } = useApi({
    path: `/assets/${assetId}/occurrences/${id}`,
  });

  const [openMenu, setOpenMenu] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [modalReport, setModalReport] = useState(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const menuRef = useRef<HTMLDivElement | null>(null);

  const userAdmin = [
    PROFILES.EXECUTIVO_MASTER,
    PROFILES.MESA_MASTER,
    PROFILES.MESA_ANALISTA,
    PROFILES.REPRESENTANTE,
  ].includes(user.profile);

  const { t } = useTranslation();

  const handleClickOutside = (event: any) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as HTMLElement)
    ) {
      setOpenMenu(false);
    }
  };

  const isClient = [
    PROFILES.CLIENTE_COMUM,
    PROFILES.CLIENTE_MASTER,
    PROFILES.CLIENTE_MASTER_VIEW,
  ].includes(user.profile);

  const handleDowngradeCriticality = () => {
    request({ method: "put", body: { sysStatusId: 5 } }).then(() => {
      success("Atualização realizada com sucesso");
      refresh();
      setConfirmModal(false);
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleCloseOccurrence(comments: any, closeOptions: any) {
    if (processing) {
      return;
    }

    const body = { comments, ...closeOptions };
    const action = !!occurrence?.closedAt ? "reopen" : "close";
    request({ method: "put", pathParameters: action, body }).then(
      async (response) => {
        success(
          !!occurrence?.closedAt
            ? `${t("occurrencePanel.header.Ocorrência Reaberta com sucesso")}`
            : `${t("occurrencePanel.header.Ocorrência Fechada com sucesso")}`,
        );

        if (
          response.assetStatusChanged &&
          response.sysStatusId === 7 &&
          assetOperationStatus === "UNPLANNED_STOP" &&
          assetId
        ) {
          await updateAssetOperationStatus({
            assetId: Number(assetId),
            companyId,
            facilityId,
            operationStatus: "AVAILABLE",
          });
        }

        refresh();
        setCloseModal(false);
      },
    );

    handleTrackEvent({
      event: "PAINEL OCORRÊNCIA: Fechar Ocorrência",
    });
  }

  function handleDeleteOccurrence(comments?: string, reason?: string) {
    if (processing) {
      return;
    }

    handleTrackEvent({
      event: "PAINEL OCORRÊNCIA: Excluir Ocorrência",
    });

    request({ method: "del", body: { comments, reason } }).then(
      async (response) => {
        if (
          response.assetStatusChanged &&
          response.sysStatusId === 7 &&
          assetOperationStatus === "UNPLANNED_STOP" &&
          assetId
        ) {
          await updateAssetOperationStatus({
            assetId: Number(assetId),
            companyId,
            facilityId,
            operationStatus: "AVAILABLE",
          });
        }

        refresh();
        success(
          `${t("occurrencePanel.header.Ocorrência Deletada com sucesso")}`,
        );
        setDeleteModal(false);
      },
    );
  }

  function handleRestoreOccurrence(comments?: string) {
    if (processing) {
      return;
    }

    handleTrackEvent({
      event: "PAINEL OCORRÊNCIA: Restaurar Ocorrência",
    });

    request({
      method: "put",
      body: { comments },
      pathParameters: "restore",
    }).then(() => {
      refresh();
      success(
        `${t("occurrencePanel.header.Ocorrência Restaurada com sucesso")}`,
      );
      setRestoreModal(false);
    });
  }

  function handleEdit() {
    dispatch(setOccurrenceRegister({ show: true, id: occurrence.id }));
    handleTrackEvent({
      event: "PAINEL OCORRÊNCIA: Editar Ocorrência",
    });
  }

  const getCategoryName = (categoryName: string) => {
    switch (categoryName) {
      case "SENSITIVE_INSPECTION":
        return `${t("Checklist de Inspeção Sensitiva")}`;
      case "MANUAL_COLLECT":
        return `${t("OccurrenceForm.Coleta Manual")}`;
      default:
        return "";
    }
  };

  const renderMenuItens = () => {
    return (
      <>
        {!isReadOnly && (
          <>
            <MenuItem
              onClick={handleEdit}
              disable={!!occurrence.closedAt || !!occurrence.deletedAt}
            >
              {/* <FaEdit /> */}
              <span>{t("occurrencePanel.header.Editar Ocorrência")}</span>
            </MenuItem>
          </>
        )}

        <MenuItem
          disable={!!occurrence.deletedAt}
          onClick={() => {
            setModalReport(true);
            handleTrackEvent({
              event: "PAINEL OCORRÊNCIA: Relatório",
            });
          }}
        >
          {/* <HiDocumentReport /> */}
          <span>{t("occurrencePanel.header.Relatório")}</span>
        </MenuItem>
        {!isReadOnly && (
          <MenuItem
            onClick={() => setCloseModal(true)}
            disable={!!occurrence.deletedAt}
          >
            {/* <FaWindowClose /> */}
            <span>
              {occurrence.closedAt
                ? t("occurrencePanel.header.Reabrir")
                : t("occurrencePanel.header.Fechar")}{" "}
              {t("occurrencePanel.header.Ocorrência")}
            </span>
          </MenuItem>
        )}
        {userAdmin && occurrence.deletedAt && (
          <MenuItem onClick={() => setRestoreModal(true)}>
            <span>
              {t("occurrencePanel.header.Restaurar")}{" "}
              {t("occurrencePanel.header.Ocorrência")}
            </span>
          </MenuItem>
        )}
        {userAdmin && !occurrence.deletedAt && (
          <MenuItem onClick={() => setDeleteModal(true)}>
            {/* <MdDelete /> */}
            <span>
              {t("occurrencePanel.header.Excluir")}{" "}
              {t("occurrencePanel.header.Ocorrência")}
            </span>
          </MenuItem>
        )}
      </>
    );
  };

  const displayedModals = () => {
    return (
      <>
        {modalReport && (
          <ModalOccurrenceReport
            show={modalReport}
            handleClose={() => setModalReport(false)}
            occurrence={occurrence}
            evidences={evidences}
          />
        )}

        {closeModal && !occurrence.closedAt && (
          <ConfirmOccurrenceClose
            show={closeModal}
            handleClose={() => setCloseModal(!closeModal)}
            handleConfirm={handleCloseOccurrence}
            occurrence={occurrence}
          />
        )}

        {closeModal && occurrence.closedAt && (
          <ConfirmDeleteModal
            show={closeModal}
            handleClose={() => setCloseModal(!closeModal)}
            handleConfirm={handleCloseOccurrence}
          />
        )}

        {deleteModal && (
          <ConfirmDeleteOccurrence
            show={deleteModal}
            handleClose={() => setDeleteModal(!deleteModal)}
            handleConfirm={handleDeleteOccurrence}
          />
        )}

        {restoreModal && (
          <ConfirmDeleteModal
            show={restoreModal}
            handleClose={() => setRestoreModal(!restoreModal)}
            handleConfirm={handleRestoreOccurrence}
            required
          />
        )}

        {occurrenceRegister.show && (
          <OccurrenceForm
            refresh={refresh}
            show={occurrenceRegister.show}
            contract={occurrence?.contract}
            isEdit={occurrence.id}
            occurrenceData={occurrence}
          />
        )}
      </>
    );
  };

  const renderToggleMenu = () => {
    return (
      <>
        {openMenu ? (
          <MenuButton onClick={() => setOpenMenu(!openMenu)} openMenu>
            <MdClose size={20} />
          </MenuButton>
        ) : (
          <MenuButton onClick={() => setOpenMenu(!openMenu)}>
            <BsThreeDotsVertical size={18} />
          </MenuButton>
        )}

        {openMenu && (
          <>
            <Triangle />
            <MenuContaner isMobile={isMobileOnly} ref={menuRef}>
              {renderMenuItens()}
            </MenuContaner>
            {isMobileOnly && <MenuMobileBackground />}
          </>
        )}
      </>
    );
  };

  return (
    <OccurrencePanelHeaderContainer>
      <div>
        <span>
          {t("occurrencePanel.header.Ocorrência nº")} {occurrenNumber}
        </span>
        {statusId === 6 && (
          <NamePlusTooltip
            tooltip={
              isClient
                ? `${t("OccurrenceForm.title.Agravamento")}`
                : `${t("OccurrenceForm.title.Remover Agravamento")}`
            }
            position={"top"}
          >
            <CriticalityButton
              disabled={isClient}
              onClick={() => setConfirmModal(true)}
            >
              <FaArrowUpShortWide />
            </CriticalityButton>
          </NamePlusTooltip>
        )}
        {categoryName && (
          <CategoryContainer categoryType={categoryName}>
            <span>{getCategoryName(categoryName)}</span>
          </CategoryContainer>
        )}
      </div>

      {isMobileOnly ? (
        <MenuMobile>{renderToggleMenu()}</MenuMobile>
      ) : user.admin || isReadOnly ? (
        <MenuItemsContainer>{renderMenuItens()}</MenuItemsContainer>
      ) : (
        renderToggleMenu()
      )}

      {displayedModals()}
      <ConfirmModalV2
        show={confirmModal}
        handleClose={() => setConfirmModal(false)}
        handleConfirm={handleDowngradeCriticality}
        text={`${t(
          "OccurrenceForm.title.Tem certeza que deseja remover o Agravamento dessa Ocorrência?",
        )}`}
      />
    </OccurrencePanelHeaderContainer>
  );
}
