import { Container } from "./styles";
import { VscListSelection } from "react-icons/vsc";
import { useLocation, useParams } from "react-router-dom";
import { getUrlPattern } from "../../utils/getUrlPattern";
import { ROUTES_PATH } from "../../routes/RoutesPath";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";
import { useSegment } from "../../hooks/useSegmentTrackEvent ";

interface HomePageInterface {
  navbarColor?: string;
}

export const OccurrenceGoTo = ({ navbarColor }: HomePageInterface) => {
  const params = useParams();
  const location = useLocation();
  const urlPattern = getUrlPattern(location, params);

  const { handleTrackEvent } = useSegment();

  const app = useAppSelector(appSelect);

  const { currentCompany, currentFacility, currentAsset, currentPosition } =
    app;

  const isCurrentPageOccurrences = urlPattern.includes(ROUTES_PATH.OCCURRENCES);

  const handleClick = (e: any) => {
    if (isCurrentPageOccurrences) {
      e.preventDefault();
    }

    handleTrackEvent({
      event: "Ocorrências",
    });
  };

  let url = "/occurrences";

  if (currentCompany.id) {
    url = url + `?companyId=${currentCompany.id}`;

    if (currentFacility.id) {
      url = url + `&facilityId=${currentFacility.id}`;

      if (currentAsset.id) {
        url = url + `&assetId=${currentAsset.id}`;

        if (currentPosition.id) {
          url = url + `&positionId=${currentPosition.id}`;
        }
      }
    }
  }

  return (
    <Container
      onClick={handleClick}
      to={url}
      selected={isCurrentPageOccurrences}
      navbarcolor={navbarColor}
    >
      <VscListSelection size={20} strokeWidth={1} />
    </Container>
  );
};
