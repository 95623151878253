import styled, { css } from "styled-components";

interface devideProps {
  isOpera?: boolean;
  isEdge?: boolean;
}

export const Container = styled.div<devideProps>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  .user-select-none {
    max-height: 750px !important;
  }

  .main-svg:first-child {
    display: none;
  }

  .main-svg:last-child {
    display: none;
  }

  .user-select-none {
    max-height: 750px !important;
  }

  .main-svg:first-child {
    display: none;
  }

  .main-svg:last-child {
    display: none;
  }

  .user-select-none {
    max-height: 750px !important;
  }

  .main-svg:first-child {
    display: none;
  }

  & #scene {
    max-height: 86vh !important;
    left: -75px !important;
    top: -206px !important;
    height: 100vh !important;
    width: 100% !important;

    @media screen and (min-width: 1536px) and (max-width: 1919px) {
      left: -75px !important;
      top: -151px !important;
      height: 100vh !important;
      width: 100% !important;
    }
    @media screen and (min-width: 2133px) {
      top: -400px !important;
    }

    ${({ isOpera }) =>
      isOpera &&
      css`
        @media screen and (min-width: 1536px) and (max-width: 1919px) {
          top: -208px !important;
        }
        @media screen and (min-width: 2133px) {
          top: -400px !important;
        }
      `}

    ${({ isEdge }) =>
      isEdge &&
      css`
        width: 100%;
        @media screen and (min-width: 1536px) and (max-width: 1919px) {
          top: -198px !important;
        }
        @media screen and (min-width: 2133px) {
          top: -400px !important;
        }
      `}
  }

  & #cascade-plot {
    ${({ isOpera }) =>
      isOpera &&
      css`
        & > div > div {
          & > svg {
            width: 100%;
          }
          width: 1200px !important;
        }
      `}
    ${({ isEdge }) =>
      isEdge &&
      css`
        & > div > div {
          & > svg {
            width: 100%;
          }
          width: 1200px !important;
        }
      `}

    & .modebar-container {
      bottom: 4.6rem !important;
      top: auto !important;
      z-index: 1000;

      @media screen and (min-width: 1536px) and (max-width: 1919px) {
        bottom: 12rem !important;
        right: 7.5rem !important;
      }

      & .modebar {
        position: absolute;
        /* top: -10rem; */
        right: 0;

        @media screen and (min-width: 2133px) {
          right: 7.5rem;
          top: -7rem;
        }

        ${({ isOpera }) =>
          isOpera &&
          css`
            right: -96px;
            top: 160px;
            & > .modebar-group:first-child {
              margin-right: 0 !important;
            }
          `}
        ${({ isEdge }) =>
          isEdge &&
          css`
            right: -96px;
            top: 160px;
            & > .modebar-group:first-child {
              margin-right: 0 !important;
            }
          `}

        & > .modebar-group:first-child {
          margin-right: 15px;
        }

        & > .modebar-group {
          padding: 0;
          cursor: pointer;

          &:hover:after {
            content: "Baixar como imagem";
            font-weight: 100;
            font-size: 0.8rem;
            color: ${({ theme }) => theme.colors.iconsSecondary};
            position: absolute;
            bottom: -42px;
            left: 50%;
            transform: translateX(-50%);
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            background-color: rgba(0, 0, 0, 0.8);
          }

          & > a {
            &::before {
              display: none !important;
            }
            &::after {
              display: none !important;
            }
          }

          & {
            padding: 10px;

            background: ${(props) => props.theme.colors.cardBackground};
            border: 1px solid ${(props) => props.theme.colors.cardHighlight};
            border-radius: 10px;
          }

          & svg {
            width: 25px;
            height: 25px;

            & > path {
              fill: ${(props) => props.theme.colors.iconsSecondary};
            }
          }
        }
      }
    }

    & div {
      div {
        svg {
          .infolayer {
            .legend {
              transform: translate(625px, 0px) !important;
            }

            @media screen and (min-width: 1536px) and (max-width: 1919px) {
              .legend {
                transform: translate(1000px, 50px) !important;
              }
            }

            @media screen and (min-width: 2133px) {
              .legend {
                transform: translate(1000px, 50px) !important;
              }
            }
          }
        }
      }
    }
  }
`;

export const RotationContainer = styled.div`
  color: ${(props) => props.theme.colors.iconsSecondary};
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  & > input[type="range"] {
    background-color: transparent;
    outline: none;
    -webkit-appearance: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.theme.colors.iconsSecondary};

    border: none;
    border-radius: 50%;
    cursor: pointer;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    background-color: ${(props) => props.theme.colors.inputBlock};
    border-radius: 16px;
    height: 16px;
  }

  & > div {
    display: flex;
    justify-content: space-around;

    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const HelpContainer = styled.div<{ show?: boolean }>`
  font-weight: 400;
  font-size: 0.8rem;
  top: 1.6rem;
  position: absolute;
  padding: 1rem;
  width: 9rem;
  border: 1px solid ${(props) => props.theme.colors.cardBackground};
  border-radius: 0.5rem;
  background: ${(props) => props.theme.backgroundColors.background1};

  & > label {
    font-size: 0.7rem;
    margin-bottom: 0.3rem;
  }
`;

export const InfoContainer = styled.div<devideProps>`
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  color: ${(props) => props.theme.colors.iconsPrimary};
  border-radius: 8px;
  position: absolute;
  width: 10rem;
  padding: 1rem;
  right: -26%;
  top: 30%;

  @media screen and (min-width: 1536px) and (max-width: 1919px) {
    right: -5%;
    top: 15%;
  }

  @media screen and (min-width: 2133px) {
    right: -5%;
    top: 15%;
  }

  ${({ isOpera }) =>
    isOpera &&
    css`
      @media screen and (min-width: 1536px) and (max-width: 1919px) {
        right: 3%;
        top: 15%;
      }
    `}

  ${({ isEdge }) =>
    isEdge &&
    css`
      @media screen and (min-width: 1536px) and (max-width: 1919px) {
        right: 3%;
        top: 15%;
      }
    `}
`;
