import styled from "styled-components";

export const Circle = styled.div`
  background: ${(props) => props.theme.colors.ibbxOrange};
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 100%;

  svg {
    color: white;
  }

  bottom: 2px;
  left: 2px;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;
