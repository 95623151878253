import styled from "styled-components";

export const Container = styled.div`
  max-width: 100%;
  text-align: center;
  position: relative;
  padding: 40px;

  & input {
    display: none;
  }
`;

export const ButtonSelectFile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  background-color: transparent;
  color: ${(props) => props.theme.colors.iconsPrimary};

  border: 1px dashed ${(props) => props.theme.colors.iconsPrimary};
  border-radius: 16px;

  padding: 40px;

  & > span {
    margin-top: 20px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
