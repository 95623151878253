import styled, { css } from "styled-components";

export const Container = styled.div``;

export const ImageChangerContainer = styled.div<{
  isConfigPage?: boolean;
  disabled?: boolean;
}>`
  position: relative;
  cursor: pointer;
  max-width: 240px;
  max-height: 200px;

  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  button {
    display: flex;
    position: absolute;
    right: 4px;
    top: 32px;
    background-color: transparent;
    z-index: 2;
    border-radius: 100%;
    border: 2px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
    height: 1.5rem;
    width: 1.5rem;
    /* opacity: 0; */

    svg {
      font-size: 1.25rem !important;
      color: ${({ theme }) => theme.colors.iconsSecondaryAlpha} !important;
    }

    ${({ isConfigPage }) =>
      isConfigPage &&
      css`
        top: 28px;
        right: 16px;
      `}

    &:hover {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      border: 2px solid ${({ theme }) => theme.colors.iconsPrimary};
      svg {
        color: ${({ theme }) => theme.colors.iconsPrimary} !important;
      }
      transition: all 0.3s ease;
    }
  }

  img {
    width: 240px;
    height: 240px;
    max-height: inherit;
    border-radius: 12px;
  }

  div {
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    align-items: center !important;
    justify-content: center !important;
    width: 240px;
    height: 240px;

    span {
      color: ${(props) => props.theme.colors.iconsPrimary};
      ${(props) => props.theme.fonts.primary.H2Bold};
    }

    svg {
      color: ${(props) => props.theme.colors.iconsPrimary};
      font-size: 45px !important;
    }
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center !important;
    justify-content: center !important;

    max-width: none;
    & > div {
      width: 100%;
      height: 100%;
      max-width: none;
    }

    img {
      width: inherit;
      height: inherit;
      border-radius: inherit;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    button {
      right: 28px;
      top: 38px;
    }   
  }
`;
