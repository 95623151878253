import styled from 'styled-components'

export const Root = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const Title = styled.b`
  font-size: 16px;
`

export const Content = styled.span`

`

export const List = styled.ul`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const ListItems = styled.li`
`