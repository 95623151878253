import { MdOpenInNew } from "react-icons/md";
import { GetApiContainer } from "./styles";
import { Link } from "react-router-dom";

interface GetApiKeyProps {
  label: string;
  handleGetApi: any;
  target?: string;
}

export function GetApiKey({ handleGetApi, label, target }: GetApiKeyProps) {
  return (
    <GetApiContainer>
      <Link
        to={handleGetApi}
        style={{ textDecoration: "none" }}
        target={target}
      >
        <span>{label}</span>
        <button>
          <MdOpenInNew />
        </button>
      </Link>
    </GetApiContainer>
  );
}
