import styled from "styled-components";

export const HourTextInputContainer = styled.div<{ error?: boolean }>`
  width: 100%;

  & > label {
    padding-left: 5px;
    margin-bottom: 5px;
  }

  & > div {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    & > svg {
      position: absolute;
      right: 10px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input {
    height: 36px;
    &:focus {
      border: 1px solid ${(props) => props.theme.colors.white} !important;
    }
  }

  input {
    ${(props) => props.theme.fonts.primary.H4Regular};
    width: 100%;
    min-height: 34px;
    color: ${(props) => props.theme.colors.iconsPrimary};
    background: ${(props) => props.theme.colors.background};
    border-radius: 4px;
    padding: 8px;
    border: 1px solid
      ${(props) =>
        props.error ? "red !important" : props.theme.colors.cardHighlight};

    &:hover {
      border: 1px solid ${(props) => props.theme.colors.IconsTools};
    }

    &:focus-visible {
      outline: none;
      border: 1px solid ${(props) => props.theme.colors.IconsTools};
    }
  }

  @media screen and (max-width: 767px) {
    & > input {
      font-size: 1rem !important;
    }
  }
`;