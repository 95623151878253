import { ReactNode } from "react";
import { Container, Input } from "./styles";

interface IInputCheckbox {
  label?: ReactNode;
  checked?: boolean;
  onChange?: () => void;
}

export function InputCheckbox({ label, checked, onChange }: IInputCheckbox) {
  return (
    <Container>
      <Input type="checkbox" checked={checked} onChange={onChange} />
      <label>{label}</label>
    </Container>
  );
}
