import { ReactNode } from "react";
import { Body } from "./styles";

interface FooterContainerProps {
  children: ReactNode;
}

export function BodyContainer({ children }: FooterContainerProps) {
  return <Body>{children}</Body>;
}
