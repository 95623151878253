import { Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import FormSelect from "../../../../components/FormSelect";
import Frame from "../../../../components/frame";
import { FrameBodyMain } from "../../../../components/frame/styles";
import { ButtonCancel, ButtonSave } from "../../../../components/Modal/styles";
import { SidebarConfiguration } from "../../../../components/SidebarConfiguration";
import { FormikTextInput } from "../../../../components/textInput";
import useApi from "../../../../hooks/useApi";
import DecisionModal from "../../../../modals/DecisionModal";
import { useAppSelector } from "../../../../store/hooks";
import { PROFILES, userSelect } from "../../../../store/slices/userSlice";
import { Container, Header } from "../styles";
import { Content, FormFooter, UserFormBody, UserFormLoading } from "./styles";
import { customSelectProfileColors } from "../types";
import {
  ContainerUserForm,
  CustomFormInputWrapper,
  FormSection,
} from "./styles";
import { userFormEmpty, userProfiles } from "./types";
import { Loading } from "../../../../components/Loading";
import lightTheme from "../../../../styles/themes/light";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { PhoneInputComponent } from "../../../../components/PhoneInputComponent";
import useRetinaTranslation from "../../../../hooks/useRetinaTranslation";
import UserCompanySection from "./UserCompanySection";
import useUserForm from "./useUserForm";

const UserForm = () => {
  const { companyId, userId } = useParams();
  const user = useAppSelector(userSelect);

  const { t } = useTranslation();
  const { translateActions } = useRetinaTranslation();
  const { request } = useApi({ path: "/users" });

  const formRef = useRef<FormikProps<any>>(null);

  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const [showDecisionModal, setShowDecisionModal] = useState(false);
  const { validate, handleSubmit } = useUserForm({
    formRef,
    setShowDecisionModal,
  });

  useEffect(() => {
    if (userId) {
      request({ method: "get", pathParameters: userId })
        .then((response: any) => {
          response.originalFacilities = response.userFacilities || [];
          response.originalCompanies = response.userCompanies || [];
          formRef.current?.setValues(response);
        })
        .finally(() => {
          setProcessing(false);
        });
    }

    // eslint-disable-next-line
  }, []);

  const goBack = () => {
    navigate(`/configuration/${companyId}/users`);
  };

  const limitProfileSelector = (userProfiles: any) => {
    let profiles = userProfiles.map((item: any) => ({
      ...item,
      label: t(item.value),
    }));

    switch (user.profile) {
      case PROFILES.EXECUTIVO_MASTER:
        return profiles;
      case PROFILES.MESA_MASTER:
        return [
          { value: "MESA_MASTER", label: t("MESA_MASTER") },
          { value: "MESA_ANALISTA", label: t("MESA_ANALISTA") },
          { value: "CLIENTE_MASTER", label: t("CLIENTE_MASTER") },
          {
            value: "CLIENTE_MASTER_VIEW",
            label: t("CLIENTE_MASTER_VIEW"),
          },
          { value: "CLIENTE_COMUM", label: t("CLIENTE_COMUM") },
        ];
      case PROFILES.REPRESENTANTE:
        return [
          { value: "REPRESENTANTE", label: t("REPRESENTANTE") },
          { value: "CLIENTE_MASTER", label: t("CLIENTE_MASTER") },
          {
            value: "CLIENTE_MASTER_VIEW",
            label: t("CLIENTE_MASTER_VIEW"),
          },
          { value: "MESA_ANALISTA", label: t("MESA_ANALISTA") },
          { value: "CLIENTE_COMUM", label: t("CLIENTE_COMUM") },
        ];
      case PROFILES.MESA_ANALISTA:
        return [
          { value: "MESA_ANALISTA", label: t("MESA_ANALISTA") },
          { value: "CLIENTE_MASTER", label: t("CLIENTE_MASTER") },
          {
            value: "CLIENTE_MASTER_VIEW",
            label: t("CLIENTE_MASTER_VIEW"),
          },
          { value: "CLIENTE_COMUM", label: t("CLIENTE_COMUM") },
        ];
      case PROFILES.CLIENTE_MASTER:
        return [
          { value: "CLIENTE_MASTER", label: t("CLIENTE_MASTER") },
          {
            value: "CLIENTE_MASTER_VIEW",
            label: t("CLIENTE_MASTER_VIEW"),
          },
          { value: "CLIENTE_COMUM", label: t("CLIENTE_COMUM") },
        ];
      default:
        return "";
    }
  };

  const registerNewUser = () => {
    setShowDecisionModal(false);
    formRef.current?.setValues(userFormEmpty);
  };

  // const isSubmitting = formRef.current?.setSubmitting(true);

  const handleChangeProfile = (option: any) => {
    formRef.current?.setFieldValue("profile", option?.value || null);
    formRef.current?.setFieldValue("facilityId", null);
    formRef.current?.setFieldValue("companyId", null);
  };

  const handleChangeLanguage = (option: any) => {
    formRef.current?.setFieldValue("language", option.value || null);
  };

  const languageOptions = [
    { value: "PORTUGUESE", label: t("userForm.portuguese") },
    { value: "ENGLISH", label: t("userForm.english") },
    { value: "SPANISH", label: t("userForm.spanish") },
  ];

  useEffect(() => {
    if (i18n.resolvedLanguage === "pt-BR") {
      formRef.current?.setFieldValue("language", "PORTUGUESE" || null);
    } else if (i18n.resolvedLanguage === "en") {
      formRef.current?.setFieldValue("language", "ENGLISH" || null);
    } else if (i18n.resolvedLanguage === "es") {
      formRef.current?.setFieldValue("language", "SPANISH" || null);
    }
  }, []);

  return (
    <Frame hasMargin={false}>
      <FrameBodyMain>
        <SidebarConfiguration />
        <UserFormBody visible backgroundWhite>
          <Container>
            <Header>
              <h2>
                {userId ? `${t("userForm.edit")}` : `${t("userForm.new")}`}{" "}
                {t("userForm.user")}
              </h2>
            </Header>
            <Content>
              <Formik
                innerRef={formRef}
                initialValues={userFormEmpty}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
                onSubmit={handleSubmit}
              >
                {({ errors, values }) => (
                  <Form>
                    <ContainerUserForm>
                      {processing ? (
                        <UserFormLoading>
                          <Loading marginTop="0px" />
                        </UserFormLoading>
                      ) : (
                        <>
                          <FormSection
                            background={lightTheme.colors.background}
                          >
                            <h3>{t("userForm.userData")}</h3>
                            <div>
                              <FormSelect
                                name="profile"
                                label={`${t("userForm.profile")}`}
                                placeholder={`${t("userForm.profile")}`}
                                options={limitProfileSelector(userProfiles)}
                                handleChange={handleChangeProfile}
                                error={errors.profile}
                                customColors={customSelectProfileColors}
                              />
                              <CustomFormInputWrapper>
                                <FormikTextInput
                                  name="name"
                                  label={`${t("userForm.name")}`}
                                  error={errors.name}
                                />
                              </CustomFormInputWrapper>
                              <CustomFormInputWrapper>
                                <FormikTextInput
                                  name="email"
                                  label="E-Mail"
                                  disabled={!!userId}
                                  error={errors.email}
                                />
                              </CustomFormInputWrapper>
                              <CustomFormInputWrapper>
                                <PhoneInputComponent
                                  label={
                                    t("configuration.companyDetails.phone") ||
                                    ""
                                  }
                                  name="phone"
                                  background={"#f3f6f8"}
                                />
                                {/* <FormikTextInput
                                  name="phone"
                                  label={`${t("userForm.phone")}`}
                                  inputMode="numeric"
                                /> */}
                              </CustomFormInputWrapper>
                              <FormSelect
                                name="language"
                                label={`${t("userForm.language")}`}
                                placeholder={`${t("userForm.language")}`}
                                options={languageOptions}
                                handleChange={handleChangeLanguage}
                                error={errors.language}
                                notClearable
                                customColors={customSelectProfileColors}
                              />
                            </div>
                          </FormSection>

                          <UserCompanySection />
                        </>
                      )}
                      <FormFooter>
                        <ButtonCancel
                          as={Link}
                          to={`/configuration/${companyId}/users`}
                          type="button"
                        >
                          {translateActions("Cancel")}
                        </ButtonCancel>
                        <ButtonSave>
                          {translateActions(userId ? "Save" : "Create User")}
                        </ButtonSave>
                      </FormFooter>
                    </ContainerUserForm>
                  </Form>
                )}
              </Formik>
              {showDecisionModal && (
                <DecisionModal
                  show={showDecisionModal}
                  text={`${t("userForm.wishToCreateUser")}`}
                  cancelLabel={`${t("userForm.no")}`}
                  confirmLabel={`${t("userForm.yes")}`}
                  onCalcel={goBack}
                  onConfirm={registerNewUser}
                />
              )}
            </Content>
          </Container>
        </UserFormBody>
      </FrameBodyMain>
    </Frame>
  );
};

export default UserForm;
