import { useEffect, useMemo, useState } from "react";
import ModalCompound from "../../../../../../../components/ModalCompound";
import useApi from "../../../../../../../hooks/useApi";
import { Select } from "../../../../../../../components/Select";
import { useField, useFormikContext } from "formik";
import useRetinaTranslation from "../../../../../../../hooks/useRetinaTranslation";
import { extractProperty } from "../../../../../../../utils/utilsFunctions";
import { UserFacilitiesAddFields } from "./styles";

interface IUserFacilitiesAdd {
  show: boolean;
  handleClose: () => void;
  companyId: number | null;
  disabled?: boolean;
}

export default function UserFacilitiesAdd({
  show,
  handleClose,
  companyId,
}: IUserFacilitiesAdd) {
  const { translateLabels } = useRetinaTranslation();

  const [{ value: profile }] = useField("profile");
  const [{ value: userFacilities }] = useField("userFacilities");

  const { setFieldValue } = useFormikContext();
  const { request: requestCompanies } = useApi({
    path: "/companies-options",
  });
  const { request: requestFacilities } = useApi({
    path: "/facilities-options",
  });

  const [companiesOptions, setCompanies] = useState<any[]>([]);
  const [faciltiesOptions, setFacilities] = useState<any[]>([]);

  const [facility, setFacility] = useState<any>(null);
  const [company, setCompany] = useState<any>(null);
  const [permission, setPermission] = useState<any>({
    value: "DEFAULT",
    label: translateLabels("Editor"),
  });
  // eslint-disable-next-line
  const [deniedPermission, setDeniedPermission] = useState<any>(null);

  function loadCompanies() {
    requestCompanies({
      method: "get",
    }).then((response: any[]) => {
      setCompanies(response);
    });
  }

  function loadFacilities(args?: any) {
    requestFacilities({
      method: "get",
      queryStringParameters: { companyId: args || companyId },
    }).then((response: any[]) => {
      setFacilities(response);
    });
  }

  useEffect(() => {
    loadCompanies();
    if (companyId) {
      loadFacilities();
      return;
    }
    setFacilities([]);
    // eslint-disable-next-line
  }, [companyId]);

  const handleChangeCompany = (value: any) => {
    setCompany(value || null);
    setFacilities([]);
    if (value) {
      loadFacilities(value?.value);
    }
  };

  const handleChangeFacility = (value: any) => {
    setFacility(value || null);
  };

  const handleChangePermission = (value: any) => {
    setPermission(value);
  };

  function handleSubmit() {
    if (!facility?.value) {
      return;
    }
    const result = (userFacilities || []).concat({
      facilityId: facility?.value,
      facilityName: facility?.label,
      companyName: company?.label,
      companyId: company?.id,
      isReadOnly: permission?.value === "READ_ONLY",
      deniedPermissions: {},
      action: "CREATE",
    });

    setFieldValue("userFacilities", result);
    closeForm();
  }

  const facilities = useMemo(() => {
    const facilitiesId = extractProperty(userFacilities, "facilityId");
    return faciltiesOptions.filter(({ value }) => {
      return !facilitiesId.includes(value);
    });
  }, [faciltiesOptions, userFacilities]);

  const permissions = useMemo(() => {
    return [
      { value: "DEFAULT", label: translateLabels("Editor") },
      { value: "READ_ONLY", label: translateLabels("Viewer") },
    ];
  }, [translateLabels]);

  const renderPermission = () => {
    switch (true) {
      case profile === "CLIENTE_COMUM":
        return (
          <Select
            label={translateLabels("Permission")}
            value={permission}
            handleChange={handleChangePermission}
            options={permissions}
          />
        );
      default:
        return <></>;
    }
  };

  function closeForm() {
    setFacility(null);
    setPermission({ value: "DEFAULT", label: translateLabels("Editor") });
    handleClose();
  }

  return (
    <ModalCompound show={show} handleClose={closeForm}>
      <ModalCompound.Body>
        <h1>{translateLabels("Add")}</h1>
        <UserFacilitiesAddFields>
          {profile === "MESA_ANALISTA" && (
            <Select
              label={translateLabels("Company")}
              value={company}
              handleChange={handleChangeCompany}
              options={companiesOptions}
            />
          )}
          <Select
            label={translateLabels("Facility")}
            value={facility}
            handleChange={handleChangeFacility}
            options={facilities}
          />
          {renderPermission()}
        </UserFacilitiesAddFields>
      </ModalCompound.Body>
      <ModalCompound.Footer>
        <ModalCompound.Cancel handleClose={closeForm} />
        <ModalCompound.Save handleConfirm={handleSubmit} />
      </ModalCompound.Footer>
    </ModalCompound>
  );
}
