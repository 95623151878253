import { endsWith, startsWith } from "lodash";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { useCallback, useMemo } from "react";
import ptBR from "date-fns/locale/pt-BR";
import enUS from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import { Locale } from "date-fns";
import { formatDate } from "../utils/utilsFunctions";
import { appSelect } from "../store/slices/appSlice";
import { useAppSelector } from "../store/hooks";
import { SENSOR_TYPE } from "../store/enums";

export default function useRetinaTranslation() {
  const { t } = useTranslation();
  const { diagnostics } = useAppSelector(appSelect);

  const lang = useMemo(() => {
    return i18n.language;
    // eslint-disable-next-line
  }, [i18n, i18n.language]);

  const datepickerLocale: [string, Locale] = useMemo(() => {
    switch (i18n.language) {
      case "en": {
        return ["enUS", enUS];
      }
      case "es": {
        return ["es", es];
      }
      default:
        return ["ptBR", ptBR];
    }
    // eslint-disable-next-line
  }, [i18n.language]);

  const dict = useCallback(
    (key: string) => {
      const response = t(`dictionary.${key}`);
      if (response === `dictionary.${key}`) {
        const words = key.split(".").reverse();
        return words[0] as string;
      }
      return response as string;
    },
    // eslint-disable-next-line
    [lang],
  );

  const translateLabels = useCallback(
    (key: string) => {
      return dict(`labels.${key}`);
    },
    [dict],
  );

  const translateActions = useCallback(
    (key: string) => {
      return dict(`actions.${key}`);
    },
    [dict],
  );

  const translateDiagnostic = useCallback(
    (name: any) => {
      const diagnostic = diagnostics.find((item) => item.name === name);
      if (diagnostic) {
        return diagnostic?.translation?.[lang] || diagnostic.name;
      }
      return t("occurrenceAssetListItem.Anomalia");
    },
    // eslint-disable-next-line
    [diagnostics, lang],
  );

  const translateSugestion = useCallback(
    (name: any) => {
      const diagnostic = diagnostics.find(
        (item) => item.sugestion === name?.trim(),
      );

      if (diagnostic) {
        return diagnostic.translationSugestion[lang] || diagnostic.sugestion;
      }
      return "";
    },
    // eslint-disable-next-line
    [diagnostics, lang],
  );

  const translateAssetType = useCallback(
    (name: any, list: any[]) => {
      const assetType = list.find((item) => item.name === name);

      if (name === "Não Informado") {
        return t("dictionary.labels.Uninformed");
      }

      if (assetType) {
        return assetType.translation?.[lang] || assetType.name;
      }

      return t("dictionary.labels.Uninformed");
    },
    // eslint-disable-next-line
    [lang],
  );

  function buildStatusCaptionById(
    id: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7,
    changeStatusLabel?: boolean,
  ) {
    switch (id) {
      case 7:
        return t("dictionary.status.CRITICAL");
      case 6:
        return t("dictionary.status.RISCO");
      case 5:
        return t(
          `dictionary.status.${changeStatusLabel ? "EXTREMO" : "RISCO"}`,
        );
      case 4:
        return t("dictionary.status.ANÁLISE");
      case 3:
      case 2:
        return t(
          `dictionary.status.${changeStatusLabel ? "LIMITE" : "ALERTA"}`,
        );
      case 0:
        return "";
      default:
        return t("dictionary.status.NORMAL");
    }
  }

  function buildEvidenceLabel(label: string, metadata: any) {
    const { condition, openedByAI, chartType, periodName } = metadata ?? {};
    const conditionKey = condition === "DECREASE" ? "Redução" : "Aumento";
    const conditionLabel = t(
      `occurrencePanel.OccurrenceEvidence.${conditionKey}`,
    );

    let date = formatDate(metadata?.date, { type: "FULL" });
    const graphic = getGraphicLabel(metadata?.chartLabel ?? "");

    if (openedByAI) {
      return `${t("OccurrenceEvidence.Anomalia")} ${graphic} ${date}`;
    }
    if (chartType && metadata.alarmType === "VARIATION") {
      date = formatDate(+metadata?.maxDate, { type: "FULL" });
      const periodVariation = t(`OccurrenceEvidence.Variation.${periodName}`);
      return `${periodVariation} ${graphic} ${date}`;
    }
    if (chartType) {
      const labelOf = t("occurrencePanel.OccurrenceEvidence.da");
      return `${conditionLabel} ${labelOf} ${graphic} ${date}`;
    }

    return `${label ?? ""} ${date}`;
  }

  function tr(prefix: string, label: string) {
    if (!endsWith(prefix, ".") && !startsWith(label, ".")) {
      prefix = prefix + ".";
    }
    const key = `${prefix}${label}`;
    return t(key) === key ? label : t(key);
  }

  function getGraphicLabel(label: string) {
    return tr(`GraphicHeader.`, label);
  }

  return {
    tr,
    lang,
    dict,
    translateLabels,
    translateActions,
    buildEvidenceLabel,
    buildStatusCaptionById,
    translateDiagnostic,
    translateSugestion,
    translateAssetType,
    getGraphicLabel,
    datepickerLocale,
  };
}
