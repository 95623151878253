import { useMemo, useState } from "react";
import UserHiearchyComponent from "../UserHiearchyComponent";
import UserCompaniesAdd from "./UserCompaniesAdd";
import { useField, useFormikContext } from "formik";
import { UserFacilitiesSelectorContainer } from "./styles";
import { MdAdd } from "react-icons/md";
import { RiFullscreenFill } from "react-icons/ri";
import useRetinaTranslation from "../../../../../../hooks/useRetinaTranslation";

interface IUserFacilitiesSelector {
  disabled?: boolean;
  error?: string;
}

export default function UserCompaniesSelector({
  disabled,
}: IUserFacilitiesSelector) {
  const { errors } = useFormikContext<any>();
  const { translateLabels, lang } = useRetinaTranslation();
  const [{ value: userCompanies }] = useField("userCompanies");
  const [dialog, setDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const text = useMemo(() => {
    switch (true) {
      case !(userCompanies || []).length:
        return translateLabels("None");
      case (userCompanies || []).length === 1:
        return userCompanies[0].companyName;
      default:
        const total = (userCompanies || []).length;
        return `${total} ${translateLabels("Companies")}`;
    }
    // eslint-disable-next-line
  }, [userCompanies, lang]);

  return (
    <UserFacilitiesSelectorContainer disabled={disabled}>
      <div>
        <label>{translateLabels("Company")}</label>
        <div>
          <input readOnly value={text} />
          <button
            type="button"
            onClick={() => setDialog(true)}
            disabled={disabled}
          >
            <MdAdd />
          </button>
          <button
            type="button"
            onClick={() => setExpanded(true)}
            disabled={disabled}
          >
            <RiFullscreenFill />
          </button>
          <UserCompaniesAdd
            show={dialog}
            handleClose={() => setDialog(false)}
          />

          <UserHiearchyComponent
            show={expanded}
            handleClose={() => setExpanded(false)}
          />
        </div>
        {errors?.companyId && (
          <span className="error">{errors.companyId as string}</span>
        )}
      </div>
    </UserFacilitiesSelectorContainer>
  );
}
