import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import appReducer from "./slices/appSlice";
import userReducer from "./slices/userSlice";
import positionReducer from "./slices/positionSlice";
import occurrenceReducer from "./slices/occurrenceSlice";
import dashboardToggleReducer from "./slices/dashboardToggleSlice";
import dashboardReducer from "./slices/dashboardSlice";
import navbarIconsReducer from "./slices/navbarIconsSlice";
import selectOptionsReducer from "./slices/selectOptionsSlice";
import sidebarReducer from "./slices/sidebarSlice";
import addSensorDeviceReducer from "./slices/addNewDeviceSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    position: positionReducer,
    occurrence: occurrenceReducer,
    dashboardToggle: dashboardToggleReducer,
    dashboard: dashboardReducer,
    navbarIcons: navbarIconsReducer,
    selectOptions: selectOptionsReducer,
    sidebar: sidebarReducer,
    addSensorDevice: addSensorDeviceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
