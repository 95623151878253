import styled from "styled-components";

export const ReasonContainer = styled.div`
  ${(props) => props.theme.fonts.primary.H3SemiBold};
  display: flex;
  width: 100%;
  flex-direction: column;
  background: none !important;
  gap: 1rem;
  border-radius: 4px;
  padding: 0.75rem;
  background-color: ${(props) => props.theme.colors.inputBlock};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};

  strong {
    text-align: center;
    ${(props) => ({ ...props.theme.fonts.primary.H2Bold })};
  }
`;
