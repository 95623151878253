import { useState } from "react";
import queryString from "../../../utils/queryString";
import { useLocation, useSearchParams } from "react-router-dom";
import { updateQueryString } from "../../FilterBar/types";
import DatePicker, { registerLocale } from "react-datepicker";
import { ptBR } from "date-fns/locale";
import { CalendarIcon, DateContainer, DatePickerContainer } from "./styles";
import { BiCalendar } from "react-icons/bi";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

function FilterPeriodPickerMobile() {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  registerLocale("ptBR", ptBR);

  function handleDatePicker(value: any) {
    const params: any = queryString.parse(location.search);

    if (value[0] === null && value[1] === null) {
      setShowDatePicker(false);
      params.startDate = null;
      params.endDate = null;
    }

    if (value[0]) {
      params.startDate = value[0].getTime();
    }
    if (value[1]) {
      params.endDate = value[1].getTime();
    }

    setDateRange(value);

    updateQueryString(params, "startDate", setSearchParams);
    updateQueryString(params, "endDate", setSearchParams);
  }

  const { t } = useTranslation();

  return (
    <DateContainer selected={showDatePicker}>
      <DatePickerContainer>
        <DatePicker
          popperPlacement="top"
          // autoFocus
          locale={ptBR}
          dateFormat="dd/MM/yyyy"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(value) => handleDatePicker(value)}
          isClearable={true}
          onFocus={(e) => (e.target.readOnly = true)}
          placeholderText={`${t("FilterPeriodPicker.select")}`}
        />
        {startDate === null && endDate === null && (
          <CalendarIcon>
            <BiCalendar size={25} />
          </CalendarIcon>
        )}
      </DatePickerContainer>
    </DateContainer>
  );
}

export default FilterPeriodPickerMobile;
