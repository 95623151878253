import styled from "styled-components";

export const Body = styled.div`
  h2 {
    ${(props) => props.theme.fonts.primary.H3SemiBold};
    color: ${(props) => props.theme.colors.ibbxOrange};
  }

  span {
    ${(props) => props.theme.fonts.primary.H4Regular};
    color: ${(props) => props.theme.colors.iconsPrimary};
  }
`;
