import { Modal } from "../../components/Modal";
import { CardsContainer, Container, Title } from "./styles";
import { useTranslation } from "react-i18next";

type EditMarqeeProps = {
  show: boolean;
  handleClose: () => void;
  handleCancel: () => void;
  listDashboardCards: any[];
  handleClick: (item: string, value: boolean) => void;
  handleConfirmEdit: () => void;
  dashboardCards: any;
  error?: boolean | undefined;
  setError?: any;
};

export const EditMarqee = ({
  show,
  handleClose,
  listDashboardCards,
  handleClick,
  dashboardCards,
  error,
  setError,
  handleCancel,
  handleConfirmEdit,
}: EditMarqeeProps) => {
  const { t } = useTranslation();

  const getCardsName = (name: string) => {
    switch (name) {
      case "CardAssetRiskRank":
        return t("cards.assetRisk.title");
      case "CardIoTConnectivity":
        return t("cards.iotConnectivity.title");
      case "CardOccurrenceHistory":
        return t("cards.occurrenceHistory.title");
      case "CardDiagnosticHistory":
        return t("cards.diagnosticHistory.title");
      case "CardAssetTypesCount":
        return t("cards.assetTypes.title");
      case "CardOccurrencesStatus":
        return t("cards.occurrencesStatus.title");
      case "CardReliability":
        return t("cards.cardReliability.title");
      case "CardComsumption":
        return t("cards.CardComsumption.title");
      case "CardHourmeterBar":
        return t("cards.CardHourmeterBar.title");
      case "CardFacilityReliability":
        return t("cards.CardFacilityReliability.title");
      case "CardAssetsStops":
        return t("cards.CardAssetsStops.title");
      case "CardPositionStatus":
        return t("cards.CardPositionStatus.title");
      case "CardOccurrencesCount":
        return t("cards.CardOccurrencesCount.title");
      case "CardAlarmQuantityRank":
        return t("cards.CardRiskRankingByPosition.title");
      case "CardAlarmRankByAsset":
        return t("cards.CardAlarmRankByAsset.title");
      case "CardAssetStatus":
        return t("CardAssetStatus.assetStatus");

      case "CardStoredSensors":
        return "Sensores";
      default:
        return "-";
    }
  };

  const handleConfirm = async () => {
    handleConfirmEdit();
    handleClose();
  };

  const checkedQuantity = Object.values(dashboardCards).filter(
    (item) => item,
  ).length;

  return (
    <Modal
      show={show}
      handleClose={handleCancel}
      size="lg"
      handleConfirm={handleConfirm}
      confirmLabel={"OK"}
    >
      <>
        <Container>
          <Title>{t("modals.editMarqee.title")}</Title>
          <CardsContainer>
            {(listDashboardCards || []).map((item: any, index: number) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    name={item}
                    checked={dashboardCards?.[item] || false}
                    onChange={() => {
                      (checkedQuantity !== 1 || !dashboardCards?.[item]) &&
                        handleClick(item, !dashboardCards?.[item]);
                    }}
                  />
                  {getCardsName(item).toUpperCase()}
                </label>
              </div>
            ))}
          </CardsContainer>
        </Container>
      </>
    </Modal>
  );
};
