import { useMemo } from "react";
import queryString from "../../utils/queryString";
import { MdMonitor } from "react-icons/md";
import { useLocation, useParams } from "react-router-dom";
import { ROUTES_PATH } from "../../routes/RoutesPath";
import {
  appSelect,
  appSetCurrentAsset,
  appSetCurrentCompany,
  appSetCurrentFacility,
  appSetCurrentPosition,
} from "../../store/slices/appSlice";
import { getUrlPattern } from "../../utils/getUrlPattern";
import { Container } from "./styles";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useSegment } from "../../hooks/useSegmentTrackEvent ";
import { isUserClienteMaster } from "../../utils/userUtils";
import { userSelect } from "../../store/slices/userSlice";

interface HomePageInterface {
  navbarColor?: string;
  tooltipTitle?: string;
}

export const HomePageButton = ({
  navbarColor,
  tooltipTitle,
}: HomePageInterface) => {
  const app = useAppSelector(appSelect);
  const user = useAppSelector(userSelect);
  
  const params = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { handleTrackEvent } = useSegment();

  const urlPattern = getUrlPattern(location, params);
  const query: any = queryString.parse(location.search);

  const dashBoardPages = [
    ROUTES_PATH.COMPANIES,
    ROUTES_PATH.FACILITIES,
    ROUTES_PATH.ASSETS,
    ROUTES_PATH.ASSET,
    ROUTES_PATH.POSITIONS,
  ];

  const isCurrentPageHome = dashBoardPages.includes(urlPattern);
  const isOccurrencePage = urlPattern === ROUTES_PATH.OCCURRENCES;
  const isESGPage = urlPattern === ROUTES_PATH.ESG;
  const isConfigurationPage = urlPattern.includes("configuration");

  function handleNavigate(e: any) {
    if (isCurrentPageHome) {
      e.preventDefault();
    } else if (isOccurrencePage) {
      return;
    } else if (isConfigurationPage && params.companyId === "ibbx") {
      dispatch(appSetCurrentCompany(null));
      dispatch(appSetCurrentFacility(null));
      dispatch(appSetCurrentAsset(null));
      dispatch(appSetCurrentPosition(null));
    } else if (isConfigurationPage) {
      dispatch(appSetCurrentFacility(null));
      dispatch(appSetCurrentAsset(null));
      dispatch(appSetCurrentPosition(null));
    }
    if (isESGPage) {
      dispatch(appSetCurrentCompany(null));
      dispatch(appSetCurrentFacility(null));
      dispatch(appSetCurrentAsset(null));
      dispatch(appSetCurrentPosition(null));
    }

    handleTrackEvent({
      event: "Dashboard",
    });
  }

  const to = useMemo(() => {
    if (location.pathname === "/hub" && isUserClienteMaster(user.profile)) {
      return app.homeUrl;
    }

    if (query.assetId && isOccurrencePage) {
      return `/companies/${query.companyId}/facilities/${query.facilityId}/assets/${query.assetId}`;
    }
    if (query.facilityId && isOccurrencePage) {
      return `/companies/${query.companyId}/facilities/${query.facilityId}/assets`;
    }
    if (params?.companyId && params?.facilityId) {
      return `/companies/${params.companyId}/facilities/${params.facilityId}/assets`;
    }
    if (query.companyId) {
      return `/companies/${query.companyId}/facilities`;
    }

    if (params.companyId && params.companyId !== "ibbx") {
      return `/companies/${params.companyId}/facilities`;
    }

    return "/";
    // eslint-disable-next-line
  }, [query.companyId, query.facilityId, query.assetId, params.companyId]);

  return (
    <Container
      to={to}
      onChange={handleNavigate}
      selected={isCurrentPageHome}
      navbarcolor={navbarColor}
      title={tooltipTitle}
    >
      <MdMonitor size={24} />
    </Container>
  );
};
