import styled from "styled-components";

export const CompanySelectContainer = styled.div`
  display: flex;
  width: 100%;
  border: 1px ${(props) => props.theme.colors.iconsSecondaryAlpha};
  border-radius: 32px;
  & > div {
    width: 100%;

    .css-10r148m-Input {
      width: 100%;
      padding: 0 0.5rem;
    }
    .css-144ilgt-singleValue {
      padding: 0 0.25rem;
    }

    & > div:nth-child(2) {
      margin-top: 0px;
      padding: 0;
    }

    & > div > div {
      &::-webkit-scrollbar {
        width: 6px !important;
      }

      &::-webkit-scrollbar-track {
        background: ${(props) => props.theme.colors.background};
        width: 20px;
        border-radius: 100vw;
        margin-block: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
        height: 100%;
        max-height: 5rem;
        border-radius: 100vw;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.colors.iconsSecondary};
      }

      &::-webkit-scrollbar-thumb:active {
        background: ${(props) => props.theme.colors.iconsSecondary};
      }
    }
  }
`;
