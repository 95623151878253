import ReactPlayer from "react-player";
import useApi from "../../hooks/useApi";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setShowAnnouncement } from "../../store/slices/appSlice";
import { userSelect } from "../../store/slices/userSlice";
import { Modal } from "../Modal";
import { ButtonSave } from "../Modal/styles";
import { Container, Footer, Header, ReleaseContainer } from "./styles";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
interface Announcement {
  parameters: {
    version: string;
    videoUrl: string;
    title: string;
    items: string[];
    list: string[];
    detailsLink: string;
    detailsTitleButton: string;
    closedTitleButton: string;
  };
  date: string;
}

interface NewReleaseProps {
  announcements: Announcement;
  show?: boolean;
  setShow?: () => void;
}
export const NewRelease = ({
  announcements,
  show = false,
  setShow,
}: NewReleaseProps) => {
  const user = useAppSelector(userSelect);
  const dispatch = useAppDispatch();

  // Modal New Release:

  const { request } = useApi({ path: "/users" });

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  const setNewReleaseAsTrue = () => {
    request({
      method: "put",
      pathParameters: `${user.id}/announcements/${announcements.date}`,
    });
  };

  return (
    <Modal handleClose={() => null} show={show} size="lg" changeFotter>
      <Container>
        <Header>
          <span>
            {t("NewRelease.update")} Retina Web{" "}
            {get(announcements?.parameters?.version, language)}
          </span>
        </Header>
        {announcements?.parameters?.videoUrl && (
          <>
            {isMobile ? (
              <ReactPlayer
                url={announcements?.parameters?.videoUrl}
                width={362}
                height={220}
              />
            ) : (
              <ReactPlayer
                url={announcements?.parameters?.videoUrl}
                width={760}
              />
            )}
          </>
        )}

        <ReleaseContainer>
          <p>{get(announcements?.parameters?.title, language)}</p>
          <ul>
            {(
              announcements?.parameters?.items ??
              announcements?.parameters?.list
            )?.map((item: any, index: number) => {
              const value = item.hasOwnProperty("name") ? item.name : item;
              return <li key={index}>{get(value, language)}</li>;
            })}
          </ul>
        </ReleaseContainer>
        <Footer hasExternaLink={!!announcements?.parameters?.detailsLink}>
          {announcements?.parameters?.detailsLink && (
            <a
              href={announcements?.parameters?.detailsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {announcements?.parameters?.detailsTitleButton &&
              get(
                announcements?.parameters?.detailsTitleButton,
                language,
              )?.trim() !== ""
                ? get(announcements?.parameters?.detailsTitleButton, language)
                : t("NewRelease.details")}
              &rarr;
            </a>
          )}
          <ButtonSave
            onClick={() => {
              dispatch(setShowAnnouncement(false));
              setShow && setShow();
              setNewReleaseAsTrue();
            }}
          >
            {announcements?.parameters?.closedTitleButton &&
            get(
              announcements?.parameters?.closedTitleButton,
              language,
            )?.trim() !== ""
              ? get(announcements?.parameters?.closedTitleButton, language)
              : "OK"}
          </ButtonSave>
        </Footer>
      </Container>
    </Modal>
  );
};
