import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";

// https://react.i18next.com/guides/quick-start

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use<HttpBackend>(HttpBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next); // init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
i18n
  .init<HttpBackendOptions>({
    debug: false,
    fallbackLng: "pt",
    lng: "pt",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .catch((err) => {
    console.error("Translation Init Error", err);
  });

export default i18n;
