import { ReactNode } from "react";
// import { Formik, Form, FormikProps } from "formik";

import { UrlContainer } from "./styles";
// import { FormikTextInput } from "../../../../textInput";

interface AnalysisInputProps {
  handleChange: any;
  label?: string;
  analyticsUrl?: string;
  placeholder?: string;
  value?: any;
  isUrlValid?: boolean;
  icon?: ReactNode;
  handleReset?: any;
  resetTitle?: string;
  name: string;
}

export function AnalysisInput({
  handleChange,
  placeholder,
  label,
  analyticsUrl,
  value,
  isUrlValid,
  name,
}: AnalysisInputProps) {
  return (
    <UrlContainer isUrlValid={isUrlValid}>
      <div>
        {/* <FormikTextInput placeholder={placeholder} name={name} label={label} /> */}
        {/* {analyticsUrl && (
          <FormikTextInput
            placeholder={placeholder}
            name={name}
            label={analyticsUrl}
          />
        )} */}

        {/* <input
          placeholder={placeholder}
          type="text"
          onChange={handleChange}
          value={value}
        /> */}
      </div>
    </UrlContainer>
  );
}
