import styled from "styled-components";

export const GetButton = styled.button`
  border: none;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.ibbxOrange};
  border-radius: 6px;
  min-width: 7.75rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center !important;
  margin-left: auto;
  position: relative;

  :hover {
    filter: brightness(1.1);
    transition: all 0.3s ease;
  }
  :active {
    scale: calc(0.98);
  }

  span {
    ${({ theme }) => theme.fonts.primary.H4Regular}
    font-size: 0.875rem;
  }
`;
