import queryString from "../utils/queryString";
import { useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export default function useQueryParams() {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  function setQueryParams(params: any) {
    setSearchParams(params);
  }

  return { queryParams, setQueryParams };
}
