import {
  axisModel,
  axisXDataModel,
  dataMapModel,
  dataTypeModel,
} from "../../components/charts/chart/types";
import { ISerieControl } from "../../hooks/useSeriesControl";
import { IChart } from "../../store/interfaces";
import getTooltip from "./getTooltip";

export function hideInvisibleSerie(
  data: any[],
  seriesControl: ISerieControl[],
  minValue: number,
) {
  return data.map((row: any[], indexRow: number) => {
    if (indexRow > 0) {
      row = row.map((item: any, index: number) => {
        const { visible, alarm } = seriesControl[index - 1] || {};
        if (index !== 0 && !visible && !alarm) {
          return indexRow === data.length - 1 ? minValue : null;
        } else {
          return item;
        }
      });
    }

    return row;
  });
}

type getTooltipsParams = {
  dataRaw: any[];
  seriesControl: ISerieControl[];
  chart: IChart;
  tooltipConfig?: any;
  t?: any;
  frequencyUnitCPM?: boolean;
  position?: any;
};

export function getTooltips({
  dataRaw,
  seriesControl,
  chart,
  tooltipConfig,
  t,
  position,
  frequencyUnitCPM,
}: getTooltipsParams) {
  var series = seriesControl.reduce((acc: any, item) => {
    const { name, alarm } = item;
    if (!alarm) {
      return {
        ...acc,
        [name]: { type: "string", role: "tooltip", p: { html: true } },
      };
    } else {
      return acc;
    }
  }, {});

  return [
    series,
    ...dataRaw.map((row: any[]) => {
      const axisX: axisModel = {
        value: row ? row[0] : null,
        unit: chart.metricUnits.x,
      };

      const valuesY = row.filter((_: any, i: number) => i > 0);
      const rowSeries: any = {};

      valuesY.forEach((value: any, index: number) => {
        const { name, alarm } = seriesControl[index] || {};

        if (!alarm && value?.toString()) {
          const axisY: axisModel = {
            value: value,
            unit: getMetricUnit(chart.metricUnits.y, index),
          };

          rowSeries[name] = getTooltip({
            axisX,
            axisY,
            chart,
            serie: name,
            tooltipConfig,
            t: t,
            frequencyUnitCPM,
            position,
          });
        } else if (!alarm) {
          rowSeries[name] = null;
        }
      });

      return rowSeries;
    }),
  ];
}

export function setTooltips(data: any[], tooltips: any[]) {
  const result = data.map((initialRow: any[], indexRow: number) => {
    const tooltip = tooltips[indexRow];

    const row = (initialRow || []).reduce(
      (acc: any[], value: any, index: number) => {
        acc.push(value);
        const tooltipsAsArray = Object.values(tooltip);
        const hasTooltip = index !== 0 && index - 1 < tooltipsAsArray.length;

        if (hasTooltip) {
          acc.push(value === null ? null : tooltipsAsArray[index - 1]);
        }
        return acc;
      },
      [],
    );

    return row;
  });

  return result;
}

export function getDataMap(
  seriesControl: ISerieControl[],
  chart: IChart,
  type?: dataTypeModel,
): dataMapModel[] {
  const isDynamic = chart.type === "DYNAMIC";
  var dataMap: dataMapModel[] = [
    getChartHorizontalAxisType(isDynamic, chart.metricUnits.x),
  ];

  for (let serie of seriesControl) {
    if (!serie.alarm && serie.visible) {
      dataMap.push("VALUE");
      dataMap.push("TOOLTIP");
    } else if (!serie.alarm && !serie.visible) {
      dataMap.push("NULL");
      dataMap.push("NULL");
    } else {
      dataMap.push("ALARM");
    }
  }

  switch (type || "") {
    case "BEARINGS":
    case "HARMONICS":
    case "PHASES":
      const [axisX, ...rest] = dataMap;
      return [axisX, "ANNOTATION", "ANNOTATION", ...rest];
    case "COMMENTS":
      return dataMap.map((item) => (item === "TOOLTIP" ? "STYLE" : item));
    default:
      return dataMap;
  }
}

export function getChartHorizontalAxisType(
  dynamic: boolean,
  unit: string,
): axisXDataModel {
  if (!dynamic) {
    return "DATE";
  } else if (unit === "Hz") {
    return "FREQUENCY";
  } else if (unit === "s") {
    return "SECONDS";
  } else {
    return "UNKNOWN";
  }
}

export function addAnnotations(item: any, annotations: any) {
  return item.reduce((agg: any, value: any, index: number) => {
    if (index === 0) {
      agg = [value, ...annotations];
    } else {
      agg.push(value);
    }
    return agg;
  }, []);
}

export const annotationsFirstRow = [
  { type: "string", role: "annotation" },
  { type: "string", role: "annotationText" },
];

export function getMetricUnit(unit: string | string[], index?: number): string {
  return typeof unit === "string" ? unit : unit[index || 0];
}

export function getSerieName(serieName: string, chart: IChart, t?: any) {
  if (["Alerta", "Risco"].includes(serieName)) {
    return serieName;
  }

  const chartSeriesString = chart?.series.map((serie) => serie.toString());

  let seriesName = chart?.seriesName || [];
  if (!seriesName.length) {
    seriesName = chart?.series || [];
  }

  const indexSerie = chartSeriesString.indexOf(serieName);
  const label: any = seriesName[indexSerie];

  if (label?.toString() === "1") {
    return "Amplitude";
  } else if (indexSerie === -1) {
    return serieName;
  } else {
    if (t) {
      return t("chartSeries." + label);
    }
    return label;
  }
}

export function formatGlobalResponse(response: any) {
  if (!response) {
    return { data: [] };
  }
  response.data = (response.data || []).map(([date, ...rest]: any) => [
    new Date(date),
    ...rest,
  ]);

  if (response.ticks) {
    response.ticks = response.ticks.map((date: any) => new Date(date));
  }

  return response;
}
