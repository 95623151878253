import { useState, useRef, useCallback } from "react";
import { DrawerComponent } from "../../DrawerComponent";
import { useTranslation } from "react-i18next";
import { Container } from "./styles";
import { AnalyticsComponent } from "../AnalyticsComponent";
import { FormikTextInput } from "../../textInput";
import FormSelect from "../../FormSelect";
import useApi from "../../../hooks/useApi";
import { useParams } from "react-router-dom";
import { FormikProps } from "formik";
import { success } from "../../Toast";
import { InputListMosaic } from "../MosaicTab/InputListMosaic";
import { useSegment } from "../../../hooks/useSegmentTrackEvent ";
import { checkCredentials } from "./use-case/checkCredentials";
import { transparentLoginUrls } from "../../../utils/transparentLoginUrls";

interface IEditableTab {
  url?: string;
  tabs?: any;
  icon?: any;
  selectPermissionOptions?: any;
  refresh: any;
  blocked?: any;
  clearNavigate: (values: any) => void;
  onCloseMenu?: () => void;
}

export function EditableTab({
  url,
  tabs,
  icon,
  selectPermissionOptions,
  refresh,
  blocked,
  clearNavigate,
  onCloseMenu,
}: IEditableTab) {
  const { t } = useTranslation();
  const { request } = useApi({ path: "" });
  const params = useParams();
  const formRef = useRef<FormikProps<any>>(null);
  const { handleTrackEvent } = useSegment();

  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [updatedTabs, setUpdatedTabs] = useState(tabs);
  const [tabsToDelete, setTabsToDelete] = useState<any[]>([]);
  const [tabsToHide, setTabsToHide] = useState<any[]>([]);

  const handleSelectChange = (option: any, index: any) => {
    const currentValue = option.value;
    formRef.current?.setFieldValue(`${index}[table]`, option.value || null);

    if (currentValue !== tabs[index].table) {
      formRef.current?.setFieldValue(
        `${index}[oldTable]`,
        tabs[index].table || null,
      );
    } else {
      formRef.current?.setFieldValue(`${index}[oldTable]`, null);
    }
  };

  const handleHiddenTab = useCallback(
    (option: any, index: number, id: any) => {
      formRef.current?.setFieldValue(`${index}[hidden]`, option);

      const updatedTabsEdited = updatedTabs.filter(
        (item: any, indexArray: number) => indexArray !== index,
      );

      const changedTab = updatedTabs.find(
        (item: any, indexArray: number) => indexArray === index,
      );

      const sorted = [
        ...updatedTabsEdited,
        { ...changedTab, hidden: option },
      ].sort((a: any, b: any) => a?.order - b?.order);

      setTabsToHide([...tabsToHide, changedTab.id]);

      handleTrackEvent({
        event: `Ocultar/Mostrar Aba - ${changedTab.key}`,
      });

      setUpdatedTabs(sorted);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updatedTabs, tabsToHide],
  );

  const handleDeleteTab = useCallback(
    (tabToDelete: any) => {
      const updatedTabsCopy = updatedTabs.filter(
        (tab: any) => tab.id !== tabToDelete.id,
      );

      setUpdatedTabs(updatedTabsCopy);

      setTabsToDelete([...tabsToDelete, tabToDelete]);

      handleTrackEvent({
        event: `Deletar Analytics - ${tabToDelete.label}`,
      });

      formRef.current?.resetForm({ values: updatedTabsCopy });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabsToDelete],
  );

  const toggleDrawer = () => {
    setOpenDrawer((prevS) => !prevS);

    handleTrackEvent({
      event: "Menu > Editar Analytics",
    });
  };

  const handleConfirm = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current?.click();
      handleTrackEvent({
        event: "Editar Analytics > Salvar",
      });
    }
  };

  const handleCancel = () => {
    setOpenDrawer(false);
    setUpdatedTabs(tabs);
  };

  const handleSubmit = useCallback(
    (values: any) => {
      if (tabsToDelete.length) {
        request({
          method: "del",
          pathParameters: "tabs",
          queryStringParameters: params,
          body: tabsToDelete,
        }).then(() => {
          clearNavigate(tabsToDelete);
          setTabsToDelete([]);
          refresh();
        });
      }

      if (tabsToHide.length) {
        request({
          method: "put",
          pathParameters: "tabs/hide",
          queryStringParameters: params,
          body: tabsToHide,
        }).then(() => {
          const hiddenTabKeys = updatedTabs.filter((tab: any) =>
            tabsToHide.includes(tab.id),
          );
          clearNavigate(hiddenTabKeys);
          setTabsToHide([]);
          refresh();
        });
      }

      const { newValues } = checkCredentials(values, request);

      request({
        method: "put",
        pathParameters: "tabs",
        body: newValues,
        queryStringParameters: params,
      }).then(() => {
        setOpenDrawer(false);
        refresh();
        success(`${t("Analytics.Edição feita com sucesso!")}`);
        onCloseMenu && onCloseMenu();
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updatedTabs, tabsToDelete, tabsToHide, params],
  );

  const handleSetFirt = useCallback(
    (index: any) => {
      const currentFirstOrder = updatedTabs[0].order || 0;

      const shifted = updatedTabs.map((tab: any) => {
        return { ...tab, order: tab.order + 1 };
      });

      const filtered = shifted.filter(
        (item: any, indexTab: number) => indexTab !== index,
      );

      const newOrder = [
        { ...updatedTabs[index], order: currentFirstOrder },
        ...filtered,
      ];

      setUpdatedTabs(newOrder);
      formRef?.current?.setValues(newOrder);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updatedTabs],
  );

  const handleSetUp = useCallback(
    (index: number) => {
      const formValues = formRef.current?.values;

      const current = formValues[index];
      const target = formValues[index - 1];

      const filtered = updatedTabs.filter(
        (value: any) => ![current.id, target.id].includes(value.id),
      );

      const newOrder = [
        ...filtered,
        { ...current, order: target.order },
        { ...target, order: current.order },
      ].sort((a: any, b: any) => a.order - b.order);

      setUpdatedTabs(newOrder);
      formRef?.current?.setValues(newOrder);
    },

    [updatedTabs],
  );

  const handleSetDown = useCallback(
    (index: number) => {
      const formValues = formRef.current?.values;

      const current = formValues[index];
      const target = formValues[index + 1];

      const filtered = updatedTabs.filter(
        (value: any, indexTab: number) =>
          ![current.id, target.id].includes(value.id),
      );

      const newOrder = [
        ...filtered,
        { ...current, order: target.order },
        { ...target, order: current.order },
      ].sort((a: any, b: any) => a.order - b.order);

      setUpdatedTabs(newOrder);
      formRef?.current?.setValues(newOrder);
    },
    [updatedTabs],
  );

  const handleSetLast = useCallback(
    (index: number) => {
      const currentLastTabOrder =
        updatedTabs[updatedTabs.length - 1].order || 6;

      const filtered = updatedTabs.filter(
        (item: any, indexTab: number) => indexTab !== index,
      );
      setUpdatedTabs([
        ...filtered,
        { ...updatedTabs[index], order: currentLastTabOrder + 1 },
      ]);
      formRef?.current?.setValues([
        ...filtered,
        { ...updatedTabs[index], order: currentLastTabOrder + 1 },
      ]);
    },
    [updatedTabs],
  );

  return (
    <Container>
      <DrawerComponent
        label={t("Analytics.Editar Abas") || ""}
        direction="right"
        isOpen={openDrawer}
        handleOpen={toggleDrawer}
        handleClose={toggleDrawer}
        size={600}
      >
        <AnalyticsComponent>
          <AnalyticsComponent.Title
            title={`${t("Analytics.Configuração de Abas")}`}
          />
          <AnalyticsComponent.Beta />
          <AnalyticsComponent.Body>
            <AnalyticsComponent.UserForm
              initialValues={tabs}
              isEditableTab
              handleSubmit={handleSubmit}
              validateForm={(values: any) => {
                return {};
              }}
              formRef={formRef}
            >
              {({ resetForm }) => {
                return (
                  <>
                    {updatedTabs.map((tab: any, index: any) => {
                      return (
                        <AnalyticsComponent.AccordionSection
                          label={tab.default ? t(tab.default) : tab.label}
                          icon={tab.icon}
                          handleDeleteTab={() => handleDeleteTab(tab)}
                          hiddenTab={tab.hidden}
                          handleHideTab={handleHiddenTab}
                          isBlock={tab.block}
                          index={index}
                          moveFirst={() => handleSetFirt(index)}
                          moveUp={() => handleSetUp(index)}
                          moveDown={() => handleSetDown(index)}
                          moveLast={() => handleSetLast(index)}
                          createdBy={tab.createdBy}
                          createdByName={tab.createdByName}
                          tabId={tab.id}
                          key={index}
                          createdDate={tab.id}
                        >
                          <FormikTextInput
                            placeholder={""}
                            name={`${index}[label]`}
                            label={`${t("Analytics.Nome da Aba")}`}
                            disabled={blocked}
                          />

                          {tab.hasOwnProperty("url") && (
                            <FormikTextInput
                              placeholder={`${t("Analytics.Adicione a Url")}`}
                              name={`${index}[url]`}
                              label={`${t(
                                "Analytics.Adicione a URL do seu Analytics",
                              )}`}
                              disabled={blocked}
                            />
                          )}

                          {tab.isMosaic || tab.hasOwnProperty("url") ? (
                            <FormSelect
                              handleChange={handleSelectChange}
                              name={`${index}[table]`}
                              label={`${t(
                                "Analytics.Permissão de visualização",
                              )}`}
                              options={selectPermissionOptions}
                              placeholder={`${t("Analytics.Selecione")}`}
                              index={index}
                              disabled={blocked}
                              notClearable
                            />
                          ) : (
                            <> </>
                          )}

                          {transparentLoginUrls.some((url: string) =>
                            tab.url?.includes(url),
                          ) && (
                            <>
                              <FormikTextInput
                                placeholder={`Email`}
                                name={`${index}[credentials][email]`}
                                label={`Email`}
                                disabled={blocked}
                                type="email"
                              />

                              <FormikTextInput
                                placeholder={`${t("Analytics.Senha")}`}
                                name={`${index}[credentials][password]`}
                                label={`${t("Analytics.Senha")}`}
                                disabled={blocked}
                                type="password"
                              />
                            </>
                          )}

                          {tab.isMosaic && (
                            <InputListMosaic
                              formRef={formRef}
                              index={index}
                              name={`${index}[screens]`}
                              isEdit
                            />
                          )}
                        </AnalyticsComponent.AccordionSection>
                      );
                    })}
                    <button
                      className="hidenButton"
                      type="submit"
                      ref={submitButtonRef}
                    />
                  </>
                );
              }}
            </AnalyticsComponent.UserForm>
          </AnalyticsComponent.Body>
          {!blocked && (
            <AnalyticsComponent.Footer>
              <AnalyticsComponent.CancelButton handleCancel={handleCancel} />
              <AnalyticsComponent.ConfirmButton handleConfirm={handleConfirm} />
            </AnalyticsComponent.Footer>
          )}
        </AnalyticsComponent>
      </DrawerComponent>
    </Container>
  );
}
