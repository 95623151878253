import { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import {
  DeviceContent,
  DevicesContainer,
  EditingMode,
  FrameBodyMyAPI,
  Header,
  SearchContainer,
  TableContent,
} from "./styles";
import { SidebarConfiguration } from "../../../components/SidebarConfiguration";
import Frame from "../../../components/frame";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { MdDelete, MdEdit, MdSearch } from "react-icons/md";
import { AddNewSensor } from "../../../components/AddNewSensor";
import queryString from "../../../utils/queryString";
import { useTranslation } from "react-i18next";
import { DebounceInput } from "react-debounce-input";
import { success } from "../../../components/Toast";
import { ConfirmModalV2 } from "../../../modals/ConfirmModalV2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setShowNewDeviceDrawer } from "../../../store/slices/addNewDeviceSlice";
import { useAppSelector } from "../../../store/hooks";
import { userSelect } from "../../../store/slices/userSlice";
import { FrameBodyMain } from "../../../components/frame/styles";

export default function Devices() {
  const { request } = useApi({ path: "/monitoring-solutions" });
  const { t } = useTranslation();
  const location = useLocation();
  const { companyId } = useParams();
  const [, setSearchParams] = useSearchParams();
  const { request: requestFilters } = useApi({ path: `/companies` });

  const [devices, setDevices] = useState<any>([]);
  const [editSensorData, setEditSensorData] = useState<any>(null);
  const [companies, setCompanies] = useState<any>([]);
  const [, setFacilities] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deviceToDeleteId, setDeviceToDeleteId] = useState<any>(null);

  const showNewDeviceDrawer = useSelector(
    (state: RootState) => state.addSensorDevice.showNewDeviceDrawer,
  );

  const dispatch = useDispatch();

  const SensorTypeDescription = (sysSensorTypeId: any) => {
    const getSensorTypeDescription = (sensorTypeId: number) => {
      switch (sensorTypeId) {
        case 9:
          return (
            t("PositionFormModel.formTitle.Analógico Modular") || devices.type
          );
        case 8:
          return t("PositionFormModel.formTitle.Mod Bus") || devices.type;
        case 7:
          return t("PositionFormModel.formTitle.Nível") || devices.type;
        case 6:
          return t("PositionFormModel.formTitle.Vazão") || devices.type;
        case 5:
          return t("PositionFormModel.formTitle.Iluminação") || devices.type;
        case 4:
          return t("PositionFormModel.formTitle.Temperatura") || devices.type;
        case 3:
          return t("PositionFormModel.formTitle.Pressão") || devices.type;
        case 2:
          return (
            t("PositionFormModel.formTitle.Energia Elétrica") || devices.type
          );
        case 1:
          return (
            t("PositionFormModel.formTitle.Vibração e temperatura") ||
            devices.type
          );
        default:
          return devices.type;
      }
    };

    return <>{getSensorTypeDescription(sysSensorTypeId)}</>;
  };

  function loadDevices() {
    const params = queryString.parse(location.search);
    request({ method: "get", queryStringParameters: params }).then((response) =>
      setDevices(response || []),
    );
  }

  const handleEditSensor = (sensorData: any) => {
    setEditSensorData(sensorData);
    dispatch(setShowNewDeviceDrawer(!showNewDeviceDrawer));
  };

  const handleOpenDeleteConfirmModal = (deviceId: number) => {
    setDeviceToDeleteId(deviceId);
    setOpenConfirmModal(true);
  };

  const handleDeleteSensor = (deviceId: number) => {
    request({ method: "del", pathParameters: deviceId }).then(() => {
      setDevices(
        devices.filter(
          (device: any) => device.monitoringSolutionId !== deviceId,
        ),
      );
      loadDevices();
      setOpenConfirmModal(false);
      success(`${t("newSensor.Sensor Excluído com sucesso")}`);
    });
  };

  const handleSearch = (event: any) => {
    const params = queryString.parse(location.search);
    params.search = event.target.value;
    delete params.page;
    if (!event.target.value) {
      delete params.search;
    }

    let newSearchParams = new URLSearchParams(params as any);
    setSearchParams(newSearchParams);
  };

  const loadCompanies = () => {
    if (companyId === "ibbx") {
      const queryStringParameters = { orderByName: 1 };
      requestFilters({ method: "get", queryStringParameters }).then(
        (response) => {
          const formatted = response.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
          setCompanies(formatted);
        },
      );
    }
  };

  const loadFacilities = (companyId: any) => {
    if (companyId) {
      const queryStringParameters = { orderByName: 1 };
      requestFilters({
        method: "get",
        pathParameters: `${companyId}/facilities`,
        queryStringParameters,
      }).then((response) => {
        const facilityList = response.data.map((item: any) => ({
          value: item.id,
          label: item.name,
        }));

        setFacilities(facilityList);
      });
    }
  };

  const handleCompany = (item: any) => {
    const params = queryString.parse(location.search);
    params.companyId = item?.value;
    delete params.page;

    if (item === null) {
      delete params.companyId;
      delete params.facilityId;
      setFacilities([]);
    } else {
      delete params.facilityId;
      loadFacilities(item.value);
    }
    let newSearchParams = new URLSearchParams(params as any);
    setSearchParams(newSearchParams);
  };

  const handleFacility = (value: any) => {
    const params = queryString.parse(location.search);
    params.facilityId = value?.value;
    delete params.page;
    if (value === null) {
      delete params.facilityId;
    }
    let newSearchParams = new URLSearchParams(params as any);
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    const params = queryString.parse(location.search) as any;

    if (params.search && !search) {
      setSearch(params?.search);
    }
    if (!companies?.length) {
      loadCompanies();
    }

    if (companyId !== "ibbx") {
      loadFacilities(companyId);
    }

    //eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    loadDevices();
    // eslint-disable-next-line
  }, [companyId, search, location.search]);

  const user = useAppSelector(userSelect);

  return (
    <Frame hasMargin={false}>
      <FrameBodyMain>
        <SidebarConfiguration />
        <DevicesContainer visible backgroundWhite>
          <div>
            <h2>{`${t("newSensor.Dispositivos")}`}</h2>
            <AddNewSensor
              loadDeviceList={loadDevices}
              initialSensorData={editSensorData}
              resetSensorData={() => setEditSensorData({ charts: [] })}
            />
          </div>
          <DeviceContent>
            <Header>
              {/* <div>
                {companyId === "ibbx" && (
                  <FilterSelect
                    name="companyId"
                    label={`${t("users.company")}`}
                    options={companies}
                    handleChange={handleCompany}
                    isSearchable
                  />
                )}
                <FilterSelect
                  name="facilityId"
                  label={`${t("users.facility")}`}
                  options={facilties}
                  handleChange={handleFacility}
                  isSearchable
                />
              </div> */}
              <div>
                <SearchContainer>
                  <DebounceInput
                    value={search}
                    debounceTimeout={300}
                    onChange={handleSearch}
                    placeholder={`${t("users.search")}`}
                  />
                  <MdSearch />
                </SearchContainer>
              </div>
            </Header>
            <TableContent>
              <table>
                <thead>
                  <tr>
                    <th>{`${t("newSensor.Ações")}`}</th>
                    <th>{`${t("newSensor.Nome")}`}</th>
                    <th>{`${t("newSensor.Tipo do sensor")}`}</th>
                    {/* <th>Permissão</th>
                    <th>Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {devices.map((item: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <EditingMode
                          onClick={() => handleEditSensor(item)}
                          title={`Editar`}
                          disabled={item.monitoringSolutionId <= 9}
                          block={item.monitoringSolutionId <= 9}
                        >
                          <MdEdit />
                        </EditingMode>
                        <EditingMode
                          onClick={() =>
                            handleOpenDeleteConfirmModal(
                              item.monitoringSolutionId,
                            )
                          }
                          title={`Excluir`}
                          disabled={item.monitoringSolutionId <= 9}
                          block={item.monitoringSolutionId <= 9}
                        >
                          <MdDelete />
                        </EditingMode>
                      </td>
                      <td>{item.name}</td>
                      <td>{SensorTypeDescription(item.sysSensorTypeId)}</td>
                      {/* <td>{item.permission}</td>
                      <td>{item.status}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContent>
          </DeviceContent>
        </DevicesContainer>
        {openConfirmModal && (
          <ConfirmModalV2
            show={openConfirmModal}
            handleClose={() => setOpenConfirmModal(false)}
            text={`${t("commonText.wantToDelete")}`}
            handleConfirm={handleDeleteSensor}
            indexNumber={deviceToDeleteId}
          />
        )}
      </FrameBodyMain>
    </Frame>
  );
}
