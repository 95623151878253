import {
  ContainerTimeModal,
  HourContainer,
  Subtitle,
  TitleContainer,
} from "../../../../modals/GlobalAlarmModal/styles";
import ModalCompound from "../../../ModalCompound";
import useApi from "../../../../hooks/useApi";
import { useEffect, useState } from "react";
import { success } from "../../../Toast";
import {
  LUMINA_STATUS_ON_WHEN_FINAL_HOUR,
  LUMINA_STATUS_ON_WHEN_INITIAL_HOUR,
} from "../../../../constants/lumina";
import { HourTextInput } from "../../../HourTextInput";
import { useTranslation } from "react-i18next";

interface ITimeRangeConfigModal {
  show: boolean;
  handleClose: () => void;
  positionId: number;
  refresh: () => void;
}

export default function TimeRangeConfigModal({
  show,
  handleClose,
  positionId,
  refresh,
}: ITimeRangeConfigModal) {
  // States
  const [config, setConfig] = useState<any>({});

  // Hooks
  const { t } = useTranslation();
  const { request, processing } = useApi({
    path: `/positions/${positionId}/charts/LUM-STT`,
  });

  useEffect(() => {
    loadChartDetails();
    // eslint-disable-next-line
  }, []);

  function loadChartDetails() {
    request({ method: "get", pathParameters: "details" }).then((response) => {
      const currentConfig: any = response.config || {};
      if (!currentConfig.onWhen) {
        currentConfig.onWhen = {};
      }
      if (!currentConfig.onWhen.initialHour) {
        currentConfig.onWhen.initialHour = LUMINA_STATUS_ON_WHEN_INITIAL_HOUR;
      }
      if (!currentConfig.onWhen.finalHour) {
        currentConfig.onWhen.finalHour = LUMINA_STATUS_ON_WHEN_FINAL_HOUR;
      }

      setConfig(currentConfig);
    });
  }

  const handleConfirm = () => {
    if (!processing) {
      request({ method: "put", body: { config } }).then(() => {
        success(`Status Atualizado com Sucesso!`);
        handleClose();
        refresh();
      });
    }
  };

  return (
    <ModalCompound show={show} handleClose={handleClose}>
      <ModalCompound.Body>
        <ContainerTimeModal>
          <TitleContainer>
            <label>{t("TimeRangeConfigModal.title")}</label>
          </TitleContainer>
          <Subtitle>{t("TimeRangeConfigModal.explanation")}</Subtitle>
          <HourContainer>
            <HourTextInput
              label={t("dictionary.labels.Start Time")}
              value={config.onWhen?.initialHour || ""}
              handleChange={(value) =>
                setConfig({
                  ...config,
                  onWhen: { ...(config.onWhen || {}), initialHour: value },
                })
              }
              isLoading={processing}
            />
            <HourTextInput
              label={t("dictionary.labels.End Time")}
              value={config.onWhen?.finalHour || ""}
              handleChange={(value) =>
                setConfig({
                  ...config,
                  onWhen: { ...(config.onWhen || {}), finalHour: value },
                })
              }
              isLoading={processing}
            />
          </HourContainer>
        </ContainerTimeModal>
      </ModalCompound.Body>
      <ModalCompound.Footer>
        <ModalCompound.Cancel handleClose={handleClose} />
        <ModalCompound.Save handleConfirm={handleConfirm} />
      </ModalCompound.Footer>
    </ModalCompound>
  );
}
