import styled from "styled-components";

export const Container = styled.button`
  background-color: transparent;
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background-color: ${(props) => props.theme.colors.cardUnderlineLightAlpha};
  border-radius: 6px;
  min-width: 7.75rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center !important;
  margin-right: auto;

  :hover {
    filter: brightness(1.1);
    transition: all 0.3s ease;
  }

  :active {
    scale: calc(0.98);
  }

  span {
    ${({ theme }) => theme.fonts.primary.H4Regular}
    font-size: 0.875rem;
  }
`;
