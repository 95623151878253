export type IModalSensorStatusProps = {
  show: boolean;
  handleClose: () => void;
  gateway: {
    gatewayId: string;
    companyId: string;
    facilityId: string;
  };
};

export enum ChartType {
  BATTERY = "BATTERY",
  RSSI = "RSSI",
  CONNECTIVITY = "CONNECTIVITY",
}
