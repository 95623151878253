// Hooks
import useApi from "../../hooks/useApi";
import { useState, useEffect } from "react";

// Libraries
import ReactDatePicker, { registerLocale } from "react-datepicker";
import Select from "react-select";

import ptBr from "date-fns/locale/pt-BR/index";
import { endOfDay, isSameDay, startOfDay } from "date-fns";

// Styles
import { DynamicDateSelectProps } from "./types";

// Styles
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  CalendarIcon,
  ContainerDatePicker,
  RenderLabelContainer,
} from "./styles";
import { useTheme } from "styled-components";
import { formatDate } from "../../utils/utilsFunctions";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import queryString from "../../utils/queryString";
import useQueryParams from "../../hooks/useQueryParams";
import { isMobile } from "react-device-detect";

registerLocale("pt-BR", ptBr);

export const DynamicDateSelect = ({
  positionId,
  handleDateChange,
  absolute,
  dateFromGlobalPoint,
  chart,
}: DynamicDateSelectProps) => {
  // Hooks
  const { colors } = useTheme();
  const { request } = useApi({ path: `/positions/${positionId}` });

  const { t } = useTranslation();
  // React hooks
  const [dateList, setDateList] = useState([]);
  const [dynamicDateDay, setDynamicDateDay] = useState<any>(() => {
    return dateFromGlobalPoint?.value ? dateFromGlobalPoint.value : null;
  });
  const [selectedDate, setSelectedDate] = useState<any>(null);

  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const { queryParams } = useQueryParams();

  const customStyles = {
    input: (provided: any, state: any) => ({
      ...provided,
      width: "200px",
    }),

    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: colors.cardBackground,
      borderColor: colors.cardHighlight,
      color: colors.iconsSecondary,
      boxShadow: "none",
      borderRadius: "6px",
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: colors.iconsSecondary,
    }),

    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: "none",
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      background: colors.cardBackground,
      fontWeight: "600",
      fontSize: "12px",
      color: colors.iconsSecondary,
    }),
  };

  useEffect(() => {
    if (queryParams.date) {
      const urlDateValue = new Date(+queryParams.date);
      setDynamicDateDay(urlDateValue);
      getDynamicDates(urlDateValue);
    } else if (absolute && !dateFromGlobalPoint?.dontRefresh) {
      getLastDynamicDay();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.date]);

  function getLastDynamicDay() {
    request({ method: "get", pathParameters: "last-dynamic-day" }).then(
      (response) => {
        setDynamicDateDay(new Date(response));
        getDynamicDates(new Date(response).getTime());
      },
    );
  }

  function handleSwitchDates(e: any) {
    if (isNaN(queryParams.txDate)) {
      delete queryParams.txDate;
    } else if (!isSameDay(e, +queryParams.txDate)) {
      delete queryParams.txDate;
    } else {
      return;
    }

    setSearchParams({ ...queryParams, date: new Date(e).getTime() });
  }

  function findDayStart(date: any) {
    if (!date) {
      return null;
    }
    return startOfDay(date).getTime();
  }

  function handleLoadDates(response: any[], today: any) {
    const dayStart = findDayStart(today);
    const [lastDate = { value: null }] = response;
    const { dontRefresh, ref } = dateFromGlobalPoint ?? {};

    if (dontRefresh && findDayStart(ref) === dayStart) {
      return;
    }

    const query = queryString.parse(location.search);

    if (!query.txDate) {
      handleChange(lastDate);
      return;
    } else {
      handleChange(findInDateList(query.txDate, response) || lastDate);
    }
  }

  const getDynamicDates = (day?: any) => {
    const selectedDay = day || dynamicDateDay || new Date();
    const electric = ["SEN-CORR", "HAR-CORR"];

    const queryStringParameters = {
      minDate: startOfDay(selectedDay).getTime(),
      maxDate: endOfDay(selectedDay).getTime(),
      sensorTypeId: electric.includes(chart?.name || "") ? 2 : 1,
    };

    request({
      method: "get",
      pathParameters: "charts-date",
      queryStringParameters,
    }).then((data = []) => {
      const response = data.map((e: any) => {
        const acquisitionDateValue = e.acquisitionDate || e.date;
        const dateValue = e.date || null;

        const acquisitionDateLabel = `${
          isMobile ? t("analysis.Coleta") : t("analysis.Data de coleta")
        }: ${formatDate(acquisitionDateValue, { type: "FULL" })}`;
        const dateLabel = `${t("analysis.Transmissão")}: ${formatDate(
          dateValue,
          {
            type: "HOUR",
          },
        )}`;
        const value = acquisitionDateValue;

        const label = (
          <RenderLabelContainer>
            <span>{dateLabel}</span> <div /> <span>{acquisitionDateLabel}</span>
          </RenderLabelContainer>
        );

        return { value, label, txDate: dateValue };
      });

      setDateList(response);
      handleLoadDates(response, selectedDay);
    });
  };

  const handleChange = (inputValue?: any) => {
    handleDateChange(inputValue, true);
    setSelectedDate(inputValue);
  };

  function findInDateList(date: any, list: any[]) {
    if (isNaN(date)) {
      return null;
    }
    return list.find((d: any) => d.txDate === +date) || null;
  }

  return (
    <Container absolute={absolute}>
      <ContainerDatePicker>
        <ReactDatePicker
          selected={dynamicDateDay}
          onChange={(e) => handleSwitchDates(e)}
          locale="pt-BR"
          className="DatePicker-container"
          calendarClassName="DatePicker-container-calendar"
          dateFormat="dd/MM/yyyy"
          showPopperArrow={false}
          maxDate={new Date()}
        />
        <CalendarIcon size={25} />
      </ContainerDatePicker>
      <Select
        value={selectedDate}
        options={dateList}
        styles={customStyles}
        onChange={handleChange}
        placeholder={`${t("DynamicDateSelect.period")}`}
      />
    </Container>
  );
};
