import { GoogleChartOptions } from "react-google-charts";
import { chartDataModel } from "../chart/types";
import { ISerieControl } from "../../../hooks/useSeriesControl";
import { IChart } from "../../../store/interfaces";

export type ColumnChartProps = {
  positionId?: number;
  chart: IChart;
  date?: number;
  chartData?: chartDataModel;
  seriesControl: ISerieControl[];
  hourmeter?: boolean;
  data?: Array<any>;
  options?: GoogleChartOptions;
};

export const hourmeterChartArea = {
  backgroundColor: "transparent",
  left: "30",
  right: "30",
  height: "82%",
  width: "98%",
  bottom: "12%",
};

export const columnChartOptions = {
  chartArea: {
    width: "100%",
    height: "100%",
    top: 10,
    bottom: 20,
    right: 20,
    left: 30,
  },
  backgroundColor: "transparent",
  aggregationTarget: "none",
  legend: "none",
  animation: {
    startup: true,
    duration: 700,
    easing: "out",
  },
  hAxis: {
    minorGridlines: { color: "transparent" },
    direction: 1,
    textStyle: {
      fontSize: 10,
    },
    gridlines: {
      color: "transparent",
    },
  },
  vAxis: {
    minorGridlines: { color: "transparent" },
    textStyle: {
      fontSize: 10,
    },
    format: "short",
    viewWindowMode: "explicit",
    gridlines: {
      color: "transparent",
    },
  },
  tooltip: { isHtml: true },
  visibleInLegend: false,
};
