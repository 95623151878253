// Libraries
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

// Reducers
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";

// Types
import { AssetOccurrence } from "./types";

// Components
import Frame from "../../components/frame";
import { FrameBodyMain } from "../../components/frame/styles";
import { FrameBodyRightAsset } from "./styles";

// Hooks
import { useSegment } from "../../hooks/useSegmentTrackEvent ";

// Components
import ReturnButton from "../../components/ReturnButton";
import OccurrencePanel from "../../components/OccurrencePanel";

// Custom HooksEvid
import useApi from "../../hooks/useApi";
import usePageLevel from "../../hooks/usePageLevel";

export default function Occurrence() {
  // Router DOM
  const params = useParams();
  const navigate = useNavigate();

  //Store
  const app = useAppSelector(appSelect);

  // Hooks
  const { handleTrackPageView } = useSegment();

  const [rendering, setRendering] = useState(false);
  const [occurrence, setOcurrence] = useState<AssetOccurrence | { assetId: 0 }>(
    { assetId: 0 },
  );
  const [diagnosticOptions, setDiagnosticOptions] = useState<any>([]);
  const { assetId, occurrenceId } = params || { assetId: 0 };

  // Custom Hooks
  const path = `/assets/${assetId}`;
  const { request, processing } = useApi({ path });
  const { request: requestSysDiagnostics } = useApi({ path: "/diagnostics" });
  const { loadAsset } = usePageLevel(path);

  // Carrega detalhes do asset quando carrega a pagina
  useEffect(() => {
    if (!assetId) {
      return navigate("/");
    }

    if (!processing) {
      Promise.all([loadAsset(), loadOccurrence(), loadDiagnosticsOptions()]);
    }
    handleTrackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (occurrence.assetId) {
      const assetIdFromOccurrence = occurrence?.assetId;

      const currentAssetId = app.currentAsset.id;

      if (currentAssetId !== assetIdFromOccurrence) {
        navigate(
          `/assets/${assetIdFromOccurrence}/occurrences/${occurrenceId}`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occurrence, app.currentAsset.id]);

  function loadDiagnosticsOptions() {
    requestSysDiagnostics({ method: "get" }).then(setDiagnosticOptions);
  }

  function loadOccurrence() {
    request({
      method: "get",
      pathParameters: `occurrences/${occurrenceId}`,
    }).then((response: any) => {
      setOcurrence(response);
    });
  }

  useEffect(() => {
    if (rendering) {
      setTimeout(() => {
        setRendering(false);
      }, 500);
    }
  }, [rendering]);

  return (
    <Frame>
      <FrameBodyMain>
        <FrameBodyRightAsset visible={app.sidebarOpen} isMobile={isMobile}>
          {<ReturnButton isOccurrencePanel />}
          <OccurrencePanel
            occurrence={occurrence as AssetOccurrence}
            asset={app.currentAsset}
            diagnostics={diagnosticOptions}
            occurrenceId={occurrenceId}
            refresh={loadOccurrence}
          />
        </FrameBodyRightAsset>
      </FrameBodyMain>
    </Frame>
  );
}
