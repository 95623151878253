import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: ${(props) => props.theme.colors.IconsTools};

  & > span:nth-child(1) {
    ${(props) => props.theme.fonts.primary.H1Regular};
  }

  & > span:nth-child(2) {
    ${(props) => props.theme.fonts.primary.H4Regular};
  }

  @media screen and (max-width: 767px) {
    margin: 1rem 0;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    & > span {
      font-size: 1rem !important;
      line-height: 1rem !important;
    }
  }
`;

export const ReleaseContainer = styled.div`
  background: ${(props) => props.theme.colors.inputBlock};
  color: ${(props) => props.theme.colors3.color0};
  border: 1px solid ${(props) => props.theme.colors.cardBackground};
  border-radius: 3px;
  width: 100%;
  height: auto;
  text-align: left;
  padding: 1rem;
  overflow: auto;
  max-height: 200px;

  p {
    ${(props) => props.theme.fonts.primary.H1Regular};
    font-size: 18px;
    margin-bottom: 0.5rem;
  }

  li {
    ${(props) => props.theme.fonts.primary.H4Regular};
    color: ${(props) => props.theme.colors.iconsSecondary};
    line-height: 26px;
  }
  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    padding: 0.75rem;

    p {
      font-size: 1rem;
      line-height: 1rem;
    }

    li {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
  }
`;

export const Footer = styled.div<{ hasExternaLink?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.hasExternaLink ? "space-between" : "flex-end"};
  align-items: center;
  margin: 16px 0;

  a {
    text-decoration: none;
    ${(props) => props.theme.fonts.primary.H4Regular};
    color: ${(props) => props.theme.colors.iconsSecondary};

    padding: 8px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};

    &:hover {
      filter: brightness(0.7);
      transition: all 0.2s;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 1rem 0;
    button {
      /* width: 100%; */
      height: 2.15rem;
      ${(props) => props.theme.fonts.primary.H3SemiBold};
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    margin: 0;
    a {
      font-size: 0.75rem;
    }
  }
`;
