import styled, { css, keyframes } from "styled-components";

const circleFirst = keyframes`
    0% {
      r: 9;
      opacity: 1;
    }
    25%{
      r: 10;
      opacity: 1;
    }
    50% {
      r: 11;
      opacity: 1;
    }
    75% {
      r: 12;
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;

const circleLast = keyframes`
    0% {
      r: 3;
    }
    25%{
      r: 7;
    }
    50% {
      r: 3;
    }
    75% {
      r: 7;
    }
    100% {
      r: 3;
    }
`;

export const ContainerChart = styled.div<{
  isHovered?: boolean;
  isGlobalNotAnalysis?: boolean;
  hasHarmonics?: boolean;
}>`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100% !important;

  /* height: 400px !important; */

  ${(props) =>
    props.hasHarmonics &&
    css`
      padding-right: 20px;
    `}

  div > svg > g > g > g:nth-child(1) {
    circle:first-child {
      animation: ${circleFirst}
        ${(props) => props.isGlobalNotAnalysis && `2s infinite;`};
    }
    circle:last-child {
      animation: ${circleLast}
        ${(props) => props.isGlobalNotAnalysis && `2s infinite;`};
    }
  }

  div > svg > g > g > g:nth-child(2) {
    circle:first-child {
      animation: ${circleFirst}
        ${(props) => props.isGlobalNotAnalysis && `2s infinite;`};
    }
    circle:last-child {
      animation: ${circleLast}
        ${(props) => props.isGlobalNotAnalysis && `2s infinite;`};
    }
  }

  div > svg > g > g > g:nth-child(3) {
    circle:first-child {
      animation: ${circleFirst}
        ${(props) => props.isGlobalNotAnalysis && `2s infinite;`};
      r: 7;
    }
    circle:last-child {
      animation: ${circleLast}
        ${(props) => props.isGlobalNotAnalysis && `2s infinite;`};
    }
  }

  &:hover {
    div > svg > g > g > g:nth-child(1) {
      circle:first-child {
        animation: ${circleFirst}
          ${(props) => props.isGlobalNotAnalysis && `0s infinite;`};
        r: 7;
      }
      circle:last-child {
        animation: ${circleLast}
          ${(props) => props.isGlobalNotAnalysis && `0s infinite;`};
      }
    }

    div > svg > g > g > g:nth-child(2) {
      circle:first-child {
        animation: ${circleFirst}
          ${(props) => props.isGlobalNotAnalysis && `0s infinite;`};
        r: 7;
      }
      circle:last-child {
        animation: ${circleLast}
          ${(props) => props.isGlobalNotAnalysis && `0s infinite;`};
      }
    }

    div > svg > g > g > g:nth-child(3) {
      circle:first-child {
        animation: ${circleFirst}
          ${(props) => props.isGlobalNotAnalysis && `0s infinite;`};
        r: 7;
      }
      circle:last-child {
        animation: ${circleLast}
          ${(props) => props.isGlobalNotAnalysis && `0s infinite;`};
      }
    }
  }

  div.google-visualization-tooltip {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    background-color: ${(props) => props.theme.colors.headerBackground};
    ${(props) => props.theme.fonts.secondary.H2Regular}
    border-radius: 6px;
    box-shadow: none;
    width: 100%;
    min-width: fit-content;
    max-width: max-content;

    p {
      margin-bottom: 3px !important;
    }

    hr {
      color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      margin-top: 3px !important;
      margin-bottom: 3px !important;
    }
  }

  div.google-visualization-tooltip > ul > li > span {
    color: ${(props) => props.theme.colors.iconsSecondary} !important;
  }

  div > svg {
    &:active {
      cursor: crosshair;
      & > rect {
        fill: lightblue;
        fill-opacity: 0.15;
      }
    }
    & > rect {
      fill: transparent;
    }
  }
`;

export const ChartArea = styled.div<{ hasHarmonics?: boolean }>`
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  & > div {
    width: inherit;
    height: 100% !important;
    position: absolute;
    flex: 1;
  }

  ${(props) =>
    props.hasHarmonics &&
    css`
      /* border: 1px solid gold; */
    `}
`;

export const TableArea = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: auto;
`;

export const TableButton = styled.button`
  width: fit-content;
  height: 36px;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  margin-bottom: 8px;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background-color: ${(props) => props.theme.colors.cardUnderlineLightAlpha};

  svg {
    width: 20px;
    height: auto;
    color: ${(props) => props.theme.colors.iconsSecondary};
    pointer-events: none;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;

    span {
      color: ${(props) => props.theme.colors.iconsSecondary};
      ${(props) => props.theme.fonts.primary.H4Regular}
      margin-right: 6px;
    }
  }

  &:hover {
    border: 1px solid transparent;
    svg {
      color: ${(props) => props.theme.colors.ibbxOrange};
      transition: all 0.3s ease-in-out;
    }

    transition: all 0.2s ease-in-out;
  }
`;
