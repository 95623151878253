import { useTranslation } from "react-i18next";
import { RoundButton } from "./styles";

interface IRoundIconButton {
  children: JSX.Element;
  handleClick: (e?: any) => void;
  buttonRef?: any;
  type?: "button" | "submit";
  styles?: any;
  disabled?: boolean;
  title?: string;
}

const RoundIconButton = ({
  children,
  handleClick,
  buttonRef,
  type,
  styles,
  disabled,
  title,
}: IRoundIconButton) => {
  const { t } = useTranslation();

  return (
    <RoundButton
      disabled={disabled}
      ref={buttonRef}
      onClick={handleClick}
      name="dropdown-button"
      title={title || `${t("RoundIconButton.options")}`}
      type={type || "button"}
      style={styles}
    >
      {children}
    </RoundButton>
  );
};

export default RoundIconButton;
