import { useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { userSelect } from "../../../store/slices/userSlice";
import OccurrenceEvidence from "../../OccurrencePanel/OccurrenceEvidence";
import { EvidenceContainer } from "../../OccurrencePanel/styles";
import {
  ExpandedEvidencesContainer,
  ExpandedOccurrenceActionButton,
  ExpandedOccurrenceActions,
} from "./styles";
import { useTranslation } from "react-i18next";
import ConfirmDeleteModal from "../../../modals/ConfirmDeleteForm";
import { ConfirmOccurrenceClose } from "../../../modals/ConfirmOccurrenceClose";
import { ModalOccurrenceReport } from "../../OccurrencePanel/ModalOccurrenceReport";
import useApi from "../../../hooks/useApi";
import { success } from "../../Toast";
import { Link } from "react-router-dom";
import { useSegment } from "../../../hooks/useSegmentTrackEvent ";
import ConfirmDeleteOccurrenceModal from "../../../modals/ConfirmDeleteOccurrence";
import { useAssetOperationStatus } from "../../../hooks/useOperationStatus";
import { IAssetStatusOperation } from "../../../types/globalTypes";
import { useAsset } from "../../../hooks/useAsset";

interface IExpandedOccurrenceItem {
  evidences: any;
  occurrence: {
    assetId: number;
    facilityId: number;
    companyId: number;
    id: number;
    closedAt?: string;
    assetOperationStatus: IAssetStatusOperation;
    isReadOnly?: boolean;
  };

  refresh?: () => void;
}

export const ExpandedOccurrenceItem = ({
  evidences,
  occurrence,
  refresh = () => null,
}: IExpandedOccurrenceItem) => {
  const { handleTrackEvent } = useSegment();
  const user = useAppSelector(userSelect);
  const filteredEvidences = evidences.filter((evd: any) => !!evd);
  const { t } = useTranslation();

  const [modalReport, setModalReport] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const { assetId, id, assetOperationStatus, companyId, facilityId } =
    occurrence;
  const { request } = useApi({ path: `/assets/${assetId}/occurrences/${id}` });

  const { getAsset } = useAsset();

  const { updateAssetOperationStatus } = useAssetOperationStatus();

  function handleCloseOccurrence(comments: any, closeOptions: any) {
    const body = { comments, ...closeOptions };
    const action = !!occurrence?.closedAt ? "reopen" : "close";
    request({ method: "put", pathParameters: action, body }).then(
      async (response) => {
        success(
          !!occurrence?.closedAt
            ? `${t("occurrencePanel.header.Ocorrência Reaberta com sucesso")}`
            : `${t("occurrencePanel.header.Ocorrência Fechada com sucesso")}`,
        );

        //Caso o status tenha sido alterado, e o status da operação atual seja UNPLANNED_STOP, vai voltar para AVAILABLE
        if (
          response.assetStatusChanged &&
          response.sysStatusId === 7 &&
          assetOperationStatus === "UNPLANNED_STOP"
        ) {
          await updateAssetOperationStatus({
            assetId,
            companyId,
            facilityId,
            operationStatus: "AVAILABLE",
          });
        }

        getAsset({ assetId });
        refresh();
        setCloseModal(false);
      },
    );
    handleTrackEvent({
      event: "TAB OCCURRENCE ITEM: Fechar Ocorrência",
    });
  }

  function handleDeleteOccurrence(comments?: string) {
    request({ method: "del", body: { comments } }).then(
      async (response: {
        assetStatusChanged?: boolean;
        sysStatusId: number;
      }) => {
        success(
          `${t("occurrencePanel.header.Ocorrência Deletada com sucesso")}`,
        );

        if (
          response.assetStatusChanged &&
          response.sysStatusId === 7 &&
          assetOperationStatus === "UNPLANNED_STOP"
        ) {
          await updateAssetOperationStatus({
            assetId,
            companyId,
            facilityId,
            operationStatus: "AVAILABLE",
          });
        }

        getAsset({ assetId });
        refresh();
        setDeleteModal(false);
      },
    );
    handleTrackEvent({
      event: "TAB OCCURRENCE ITEM: Excluir Ocorrência",
    });
  }

  return (
    <>
      <ExpandedEvidencesContainer>
        {filteredEvidences.map((evd: any) => (
          <EvidenceContainer>
            <OccurrenceEvidence
              evidence={evd}
              refresh={refresh}
              positionName={evd?.position}
            />
          </EvidenceContainer>
        ))}
      </ExpandedEvidencesContainer>
      <ExpandedOccurrenceActions>
        <ExpandedOccurrenceActionButton
          as={Link}
          to={`/assets/${occurrence.assetId}/occurrences/${occurrence.id}`}
        >
          {/* <HiDocumentReport /> */}
          <span>{t("occurrencePanel.header.Ocorrência Completa")}</span>
        </ExpandedOccurrenceActionButton>
        <ExpandedOccurrenceActionButton
          onClick={(e) => {
            setModalReport(true);
            handleTrackEvent({
              event: "TAB OCCURRENCE ITEM: Relatório",
            });
          }}
        >
          {/* <HiDocumentReport /> */}
          <span>{t("occurrencePanel.header.Relatório")}</span>
        </ExpandedOccurrenceActionButton>
        {!occurrence.isReadOnly && (
          <ExpandedOccurrenceActionButton onClick={(e) => setCloseModal(true)}>
            {/* <FaWindowClose /> */}
            <span>
              {occurrence.closedAt
                ? t("occurrencePanel.header.Reabrir")
                : t("occurrencePanel.header.Fechar")}{" "}
              {t("occurrencePanel.header.Ocorrência")}
            </span>
          </ExpandedOccurrenceActionButton>
        )}
        {!occurrence.isReadOnly && user.isAnalyst && (
          <ExpandedOccurrenceActionButton
            onClick={(e) => {
              e.preventDefault();
              setDeleteModal(true);
            }}
          >
            {/* <MdDelete /> */}
            <span>{t("occurrencePanel.header.Excluir Ocorrência")}</span>
          </ExpandedOccurrenceActionButton>
        )}
      </ExpandedOccurrenceActions>
      {modalReport && (
        <ModalOccurrenceReport
          show={modalReport}
          handleClose={() => setModalReport(false)}
          assetId={occurrence.assetId}
          occurrenceId={occurrence.id}
        />
      )}

      {closeModal && !occurrence.closedAt && (
        <ConfirmOccurrenceClose
          show={closeModal}
          handleClose={() => setCloseModal(!closeModal)}
          handleConfirm={handleCloseOccurrence}
          occurrence={occurrence}
        />
      )}

      {closeModal && occurrence.closedAt && (
        <ConfirmDeleteModal
          show={closeModal}
          handleClose={() => setCloseModal(!closeModal)}
          handleConfirm={handleCloseOccurrence}
          required
        />
      )}

      {deleteModal && (
        <ConfirmDeleteOccurrenceModal
          show={deleteModal}
          handleClose={() => setDeleteModal(!deleteModal)}
          handleConfirm={handleDeleteOccurrence}
        />
      )}
    </>
  );
};
