export function TextTermsAndPoliciesES() {
  return (
    <>
      <p>
        <strong>TÉRMINOS GENERALES Y CONDICIONES DE USO</strong>
      </p>
      <p>
        Este instrumento contiene los términos generales y condiciones de uso de
        los servicios de <em>Plataforma como Servicio</em> (PaaS) proporcionados
        por IBBX Inovação em Sistemas de Software e Hardware LTDA, empresa
        registrada en el CNPJ con el número 31.958.399/0001-41, con sede en Rua
        Elizabeta Armelin Rossi, 69, Capivari, São Paulo, CEP 13.363-212, en
        adelante denominada simplemente IBBX.
      </p>
      <p>
        <strong>PREÁMBULO</strong>
      </p>
      <p>
        <strong>
          LA ACEPTACIÓN DE ESTOS TÉRMINOS Y CONDICIONES GENERALES ES
          ABSOLUTAMENTE INDISPENSABLE PARA EL USO DEL
        </strong>
        <em>
          {" "}
          <strong>SOFTWARE</strong>
        </em>
        <strong>.</strong>
      </p>
      <p>
        Al hacer clic en la casilla que indica su acuerdo, usted acepta los
        términos, declarando que ha leído y está de acuerdo con la versión más
        reciente de los términos de uso del <em>software</em>, quedando
        automáticamente vinculado a las reglas aquí contenidas. El usuario debe
        leer estos términos de uso, prestando atención a todas las normas y
        políticas relacionadas, incluyendo sus normas específicas, restricciones
        de uso y otros procedimientos o condiciones antes de formalizar el
        contrato.
      </p>
      <p>
        <strong>
          Este contrato entra en vigencia para el usuario en la fecha en que
          hace clic en el botón "acepto", siendo esta la "fecha efectiva" del
          contrato.
        </strong>
      </p>
      <p>
        El usuario declara estar consciente de que las operaciones que
        corresponden a la aceptación de este contrato, ciertas condiciones y
        opciones, así como la posible rescisión del presente instrumento y otros
        cambios, serán registrados en las bases de datos del <em>software</em>,
        junto con la fecha y hora en que fueron realizadas por el usuario,
        pudiendo dicha información utilizarse como prueba por las partes,
        independientemente de cumplir con cualquier otra formalidad.
      </p>
      <p>
        <strong>El</strong>
        <em>
          {" "}
          <strong>software</strong>
        </em>
        <strong>
          no proporciona asesoramiento profesional en áreas legales,
          financieras, contables, fiscales u otros servicios o consultoría
          profesional, debiendo el usuario consultar a un profesional competente
          cuando necesite asistencia de este tipo.
        </strong>
      </p>
      <p>
        Si el usuario está aceptando este término en nombre de terceros, declara
        y garantiza que: (i) tiene plena autoridad legal para obligar a su
        empleador (o entidad en cuestión) a estos términos y condiciones; (ii)
        ha leído y entendido este contrato y (iii) acepta, en nombre de la parte
        que representa, los términos de este instrumento.
      </p>
      <p>
        <strong>
          IBBX se reserva el derecho de modificar este contrato en cualquier
          momento, y dichas modificaciones entrarán en vigor cuando se divulguen
          a través de su
        </strong>
        <em>
          {" "}
          <strong>sitio web</strong>
        </em>
        <strong>
          o cuando se informe al usuario por otros medios. En estos casos, el
          uso continuado por parte del usuario indicará su acuerdo con las
          modificaciones.
        </strong>
      </p>
      <p>
        Al registrarse, acceder y utilizar el <em>software</em> de cualquier
        manera, incluyendo navegación, visualización, <em>descarga</em>,
        generación, recepción y transmisión de datos, información o mensajes,
        usted manifiesta su expreso acuerdo, en su nombre y en nombre de su
        empresa o empleador, con estos términos, tal como se actualizan
        periódicamente, comprometiéndose a respetar y cumplir todas las
        disposiciones aquí contenidas, así como las disposiciones de los avisos
        legales que regulan el uso de las aplicaciones y servicios.
      </p>
      <p>
        <strong>1. Definiciones</strong>
      </p>
      <p>
        1.1. A los efectos de este instrumento, todas las palabras y expresiones
        enumeradas a continuación deberán entenderse con el significado
        respectivo:
      </p>
      <p>
        1.1.1. <strong>Usuario:</strong> persona física responsable de la
        gestión del registro y del uso en nombre del establecimiento empresarial
        que desea gestionar sus datos mediante el uso del servicio ofrecido por
        el <em>software</em>.
      </p>
      <p>
        1.1.2.{" "}
        <em>
          {" "}
          <strong>Software</strong>
        </em>
        <strong>:</strong> herramienta denominada "retina". La{" "}
        <strong>Plataforma IBBX de monitoreo industrial</strong> opera en el
        modelo de <em>Plataforma como Servicio</em> (PaaS). El PaaS funciona
        mediante la recopilación de información a través de sensores y{" "}
        <em>gateways</em> (<em>hardware</em>). La Plataforma IBBX recopila datos
        a través de sensores <em>inalámbricos</em>, transmitiendo estos datos a
        los <em>gateways</em> IBBX mediante el protocolo de comunicación
        propietario IBBX L2RC. A partir de una comunicación genérica entre los{" "}
        <em>gateways</em> y la nube, los datos recopilados serán analizados por
        el <em>software</em> IBBXRetina.
      </p>
      <p>
        1.1.3. <strong>Información de cuenta:</strong> información y datos
        relativos a los colaboradores, el establecimiento empresarial,
        incluyendo accesos, contraseñas y demás información necesaria para
        acceder, recopilar, almacenar y procesar la información obtenida a
        través del <em>software</em>.
      </p>
      <p>
        1.1.4. <strong>Información personal:</strong> cualquier información
        proporcionada por el usuario que lo identifique a él o al
        establecimiento empresarial que representa, como nombre, dirección,
        teléfono, correo electrónico, número de documentos, etc.
      </p>
      <p>
        1.1.5. <strong>Infraestructura:</strong> equipamiento de almacenamiento
        del <em>software</em> y de la base de datos utilizada por este,
        incluyendo el servidor y demás equipamiento necesario.
      </p>
      <p>
        1.1.6. <strong>Implementación:</strong> capacitación del usuario clave
        del establecimiento empresarial contratante para el uso correcto y
        perfecto del sistema, utilizando una base de datos de pruebas.
      </p>
      <p>
        1.1.7. <strong>Personal calificado:</strong> colaboradores del
        establecimiento empresarial contratante con conocimientos básicos de
        informática, conocimiento del proceso productivo de la empresa y
        entrenamiento operativo del <em>software</em>, certificados por IBBX
        para operarlo de la mejor manera para obtener los resultados para los
        que está destinado el <em>software</em>.
      </p>
      <p>
        1.1.8. <strong>Mejoras (</strong>
        <em>
          <strong>upgrades)</strong>
        </em>
        : cambios en el <em>software</em> que mejoran su rendimiento y
        funcionalidad o incluyen nuevas características.
      </p>
      <p>
        <strong>2. Objeto</strong>
      </p>
      <p>
        2.1. El objeto de este contrato es establecer las condiciones de uso del{" "}
        <em>software</em> Retina de IBBX, en forma de{" "}
        <em>&quot;Plataforma como Servicio</em>&quot;, que permite al usuario
        gestionar <em>online</em> información empresarial, especialmente
        vinculada a actividades de monitoreo industrial.
      </p>
      <p>
        2.1.1. El objeto contratado no incluye de ninguna manera el acceso a
        internet o infraestructura local para el usuario. Por lo tanto, será
        responsabilidad exclusiva del usuario obtener de forma independiente y a
        su cargo, el equipo, <em>software</em> y servicios necesarios para
        garantizar su conexión a internet y al <em>software</em> que proporciona
        acceso a los servicios.
      </p>
      <p>
        2.2. Cualquier persona que desee utilizar los servicios deberá aceptar
        estos términos y demás políticas eventualmente disponibles.
      </p>
      <p>
        <strong>3. Capacidad para contratar y registro</strong>
      </p>
      <p>
        3.1. Para utilizar el <em>software,</em> es necesario realizar un
        registro previo, en el cual el usuario debe proporcionar un correo
        electrónico válido, que será confirmado posteriormente mediante el envío
        de un correo electrónico automático, junto con otros datos requeridos.
      </p>
      <p>
        3.2. El usuario debe tener al menos 18 (dieciocho) años de edad,
        capacidad legal y declarar que ha leído, comprendido, aceptado y está
        completamente de acuerdo con lo dispuesto en este documento.
      </p>
      <p>
        3.3. El usuario declara ser responsable legal y/o tener autorización
        para realizar la gestión necesaria para la adhesión al <em>software</em>
        .
      </p>
      <p>
        3.4. Cuando se solicite, el usuario se compromete a proporcionar
        información veraz, correcta, actualizada y completa (los "datos de
        registro"), según lo solicitado en el formato de registro disponible,
        bajo pena de responsabilidad según lo dispuesto por la legislación
        aplicable vigente.
      </p>
      <p>
        3.5. IBBX dependerá de sus datos de registro para evaluar su situación
        comercial, proporcionar información sobre los servicios, o identificar
        y/o contactar al usuario.
        <strong>
          Si los datos de registro no son verdaderos y correctos, o están
          desactualizados e incompletos, IBBX puede rescindir la licencia y
          todos los usos actuales o futuros de los servicios, según lo dispuesto
          en el contrato de licencia celebrado entre las partes.
        </strong>
      </p>
      <p>
        3.6. El usuario recibirá una contraseña y un nombre de cuenta al
        completar el proceso de registro, y dichos datos son personales e
        intransferibles (los "datos de acceso"). El usuario es el único y
        exclusivo responsable de mantener la confidencialidad de dichos datos,
        así como de todas las actividades que ocurran mediante el uso de sus
        datos de acceso.
      </p>
      <p>3.6.1. En este sentido, el usuario se compromete a:</p>
      <p>
        <strong>(i)</strong> Notificar inmediatamente a IBBX sobre cualquier uso
        no autorizado de sus datos de acceso o cualquier otra violación de
        seguridad, incluyendo, pero no limitado a, pérdida, robo o divulgación
        no autorizada de sus datos de acceso; y
      </p>
      <p>
        <strong>(ii)</strong> Cerrar sesión en su cuenta al final de cada sesión
        de uso;
      </p>
      <p>
        <strong>(iii)</strong> Notificar a IBBX sobre la terminación de usuarios
        del <em>software;</em>
      </p>
      <p>
        <strong>(iv)</strong> Mantener actualizados los datos de los usuarios
        del <em>software</em>.
      </p>
      <p>
        3.6.2. IBBX no será responsable por cualquier pérdida o daño resultante
        de su incumplimiento de esta sección.
      </p>
      <p>
        <strong>
          4. De la responsabilidad de inserción y gestión de datos en el
        </strong>
        <em>
          {" "}
          <strong>software</strong>
        </em>
      </p>
      <p>
        4.1. De la responsabilidad sobre los datos proporcionados y el uso del{" "}
        <em>software</em>:
      </p>
      <p>
        <strong>
          4.2. IBBX no se hace responsable de la existencia, cantidad, calidad,
          estado, integridad o legitimidad de los datos, contenidos e
          información insertados por el usuario, ya que no realiza ningún
          control sobre el uso de los datos, limitándose a garantizar el
          funcionamiento del sistema.
        </strong>
      </p>
      <p>
        <strong>
          4.3. En ningún caso IBBX será responsable por lucro cesante o
          cualquier otro daño y/o perjuicio que el usuario pueda sufrir debido a
          las configuraciones establecidas en el
        </strong>
        <em>
          {" "}
          <strong>software</strong>
        </em>
        <strong>.</strong>
      </p>
      <p>
        <strong>
          4.4. La responsabilidad por los servicios prestados se limita a la
          provisión de un
        </strong>
        <em>
          {" "}
          <strong>software</strong>
        </em>
        <strong>
          de monitoreo industrial, así como al soporte técnico y resolución de
          problemas, si fuera necesario y de acuerdo con el contrato de
          licencia.
        </strong>
      </p>
      <p>
        4.5. IBBX no se responsabiliza por posibles errores y/o fallos
        presentados por el <em>software</em> debido a problemas en los
        ordenadores, dispositivos móviles o redes utilizadas por el usuario,
        siendo el usuario el único responsable de cualquier error y/o fallo
        presentado en estos casos.
      </p>
      <p>
        4.6. El usuario es responsable de toda actividad que ocurra en su cuenta
        de acceso a los servicios.
      </p>
      <p>
        <strong>
          4.7. El usuario es exclusivamente responsable de cumplir con todas las
          leyes aplicables a su negocio, incluidas leyes y regulaciones, y
          cualquier licencia o contrato obligatorio.
        </strong>
      </p>
      <p>
        4.8. El usuario garantiza que sus archivos están libres de cualquier
        <em>malware</em>, virus, troyanos, <em>spyware</em>, gusanos u otro
        código malicioso o dañino.
      </p>
      <p>
        4.9. El usuario es responsable de: (a) mantener la confidencialidad de
        la contraseña y las cuentas de administrador; (b) designar quién está
        autorizado a acceder a las cuentas de administrador y (c) garantizar que
        todas las actividades que ocurran en conexión con las cuentas de
        administrador cumplan con los derechos y obligaciones contenidos en
        estos términos.
      </p>
      <p>
        4.10. IBBX se compromete a mantener en secreto la información de la
        cuenta, la información administrativa y la información personal del
        usuario, así como sus registros de acceso, almacenándolos en un entorno
        seguro y respetando la intimidad, la vida privada, el honor y la imagen
        del usuario, de acuerdo con las disposiciones de la ley n.º 12.965/2014
        y la política de privacidad.
      </p>
      <p>
        4.11. Excepto en caso de conducta dolosa probada, en ningún momento IBBX
        será responsable de pérdidas o daños de cualquier naturaleza que surjan
        como resultado del mal uso del <em>software</em> o de cualquier otra
        información, datos o servicios proporcionados a través de esta
        infraestructura.
      </p>
      <p>
        4.12. En ningún caso IBBX será responsable por cualquier daño indirecto
        de cualquier naturaleza que pueda, directa o indirectamente, atribuirse
        al uso o incapacidad de uso de la <em>PaaS</em> o servicio proporcionado
        a través de la infraestructura, incluso si se advirtió sobre la
        posibilidad de tales daños o si eran previsibles.
      </p>
      <p>
        4.13. IBBX no es responsable ante el usuario por ningún retraso o falta
        de ejecución del <em>software</em>, o por la imposibilidad de acceder a
        la infraestructura, debido a cualquier causa fuera de su control
        razonable.
      </p>
      <p>
        4.14. El usuario es responsable de todas las obligaciones frente a
        terceros derivadas del uso del <em>software</em>, incluidas las
        contractuales, laborales, fiscales y de cumplimiento regulatorio.
      </p>
      <p>
        4.15. IBBX no garantiza que el <em>software</em> será ininterrumpido o
        estará libre de errores, que los defectos serán corregidos o que el
        servidor en el que se aloja o cualquier sistema conectado a él esté
        libre de virus u otros componentes perjudiciales.
      </p>
      <p>
        <strong>4.16. IBBX no se hace responsable de:</strong>
      </p>
      <p>
        <strong>
          1. Fallos en la operación, operación por personas no autorizadas o
          cualquier otra causa ajena a su culpa;
        </strong>
      </p>
      <p>
        <strong>
          2. Cumplimiento de los plazos legales del usuario para la entrega de
          documentos fiscales o pagos;
        </strong>
      </p>

      <p>
        <strong>
          3. Daños o perjuicios derivados de decisiones administrativas,
          gerenciales o comerciales tomadas basadas en la información provista
          por el
        </strong>{" "}
        <em>
          <strong>software</strong>
        </em>
        <strong>;</strong>
      </p>
      <p>
        <strong>
          4. Problemas derivados de casos fortuitos o de fuerza mayor, según lo
          establecido por la legislación;
        </strong>
      </p>
      <p>
        <strong>
          5. Problemas eventuales originados por acciones de terceros que puedan
          interferir en la calidad;
        </strong>
      </p>
      <p>
        <strong>
          6. Daños causados a terceros debido a la culpa o dolo del usuario;
        </strong>
      </p>
      <p>
        <strong>
          7. Revisar la información de cuenta proporcionada por el usuario, así
          como cualquier otra información obtenida por el usuario o por
        </strong>
        <em>
          {" "}
          <strong>sitios</strong>{" "}
        </em>
        <strong>
          de terceros, en relación con la precisión de los datos y su legalidad,
          amenazas de violación, etc.
        </strong>
      </p>
      <p>
        4.17. El usuario declara en este acto que tiene la autoridad para
        proporcionar la información necesaria para el <em>software</em> y acepta
        revisar y cumplir con todos los términos de productos y servicios de
        terceros, no utilizará los servicios y productos de terceros de manera
        que infrinja o viole los derechos del <em>software</em> o de terceros,
        asumiendo los costos adicionales que terceros puedan cobrar por excesos
        de acceso a los datos.
      </p>
      <p>
        4.18. IBBX se compromete a adoptar las medidas de seguridad adecuadas al
        estándar del mercado para proteger la información del usuario. Sin
        embargo, el usuario reconoce desde ya que ningún sistema, servidor o{" "}
        <em>
          <strong>software</strong>
        </em>{" "}
        está absolutamente libre de ataques, y por lo tanto, el{" "}
        <em>
          <strong>software</strong>
        </em>{" "}
        no es responsable por la eliminación, obtención, utilización o
        divulgación no autorizada de información que resulte de ataques.
      </p>
      <p>4.19. El usuario declara y garantiza expresamente que:</p>
      <ol>
        <li>
          <p>
            No utilizará el <em>software</em> para fines ilegales;
          </p>
        </li>
        <li>
          <p>
            No transmitirá información sobre actividades ilegales o incitación
            al crimen;
          </p>
        </li>
        <li>
          <p>No transmitirá material pornográfico;</p>
        </li>
        <li>
          <p>
            No divulgará información relacionada con la piratería del{" "}
            <em>software</em>;
          </p>
        </li>
        <li>
          <p>
            No divulgará material protegido por derechos de autor o
            confidencialidad sin la autorización de sus titulares;
          </p>
        </li>
        <li>
          <p>No se hará pasar por otra persona, empresa o entidad;</p>
        </li>
        <li>
          <p>No recopilará ni divulgará datos de usuarios;</p>
        </li>
        <li>
          <p>
            No modificará, adaptará ni realizará ingeniería inversa del{" "}
            <em>software</em>;
          </p>
        </li>
        <li>
          <p>
            No enviará mensajes no solicitados, conocidos como "<em>spam</em>" o
            "<em>junk mail</em>" o cadenas de correos;
          </p>
        </li>
        <li>
          <p>
            No utilizará el <em>software</em> para enviar ningún tipo de virus
            informático;
          </p>
        </li>
        <li>
          <p>No obtendrá ni intentará obtener acceso no autorizado;</p>
        </li>
        <li>
          <p>
            No interferirá con el funcionamiento normal de la infraestructura.
          </p>
        </li>
      </ol>
      <p>
        4.20. IBBX se reserva el derecho de suspender o bloquear de inmediato el
        acceso del usuario a la infraestructura y de eliminar cualquier
        información o datos que considere una violación de cualquiera de estos
        términos generales, sin previo aviso y/o de disponibilizar dicha
        información cuando lo soliciten organismos públicos o por orden
        judicial.
      </p>
      <p>
        4.21. IBBX se esfuerza y hará todo lo posible para mantener la
        infraestructura y los servicios en funcionamiento; sin embargo, todos
        los servicios en línea están sujetos a interrupciones y paradas
        ocasionales.
      </p>
      <p>
        4.22. IBBX se compromete a proporcionar los servicios objeto de este
        contrato con personal calificado y observando las mejores prácticas
        aplicables. Sin embargo, el usuario será responsable de verificar la
        idoneidad de los datos de entrada e inserción de parámetros operativos
        configurables por el usuario, así como del control de calidad y
        consistencia de los datos de salida y materiales generados mediante el
        uso del <em>software</em>, los cuales deben ser verificados antes de su
        uso en cualquier aplicación crítica para asegurar su exactitud.
      </p>
      <p>
        4.23. IBBX se compromete a cumplir con las normas laborales, de
        seguridad social e higiene y seguridad en el trabajo con respecto a todo
        el personal que, directa o indirectamente, bajo cualquier modalidad,
        utilice para cumplir con el objeto de este contrato.
      </p>
      <p>
        4.24. IBBX se compromete a repetir, sin costo adicional para el usuario,
        cualquier servicio realizado de manera deficiente, así como a reparar
        cualquier daño derivado de este tipo de eventos, siempre y cuando el
        usuario haya cumplido previamente con todas las condiciones establecidas
        en este contrato.
      </p>
      <p>
        4.25. IBBX no se responsabiliza, en ningún caso, de pérdidas o pasivos
        fiscales, laborales o de seguridad social para el establecimiento
        empresarial del usuario debido a decisiones judiciales o cambios
        legislativos municipales, estatales o sindicales que puedan modificar
        los parámetros de cálculo en el <em>software</em>, siendo responsable
        únicamente de los cambios en las legislaciones federales.
      </p>
      <p>
        4.26. IBBX no se hace responsable de ninguna acción generada antes de la
        firma del presente contrato, especialmente de cualquier cálculo o
        proceso realizado por{" "}
        <em>
          <strong>software</strong>
        </em>{" "}
        previo, cuyos datos hayan sido migrados al sistema de IBBX.
      </p>
      <p>4.27. Por último, son responsabilidades del usuario:</p>
      <p>
        a) Operar el <em>software</em> de acuerdo con las especificaciones y
        orientaciones de IBBX, incluyendo el conjunto de actividades de
        preparación, selección y entrada de la información necesaria para
        cumplir con los objetivos establecidos;
      </p>
      <p>
        b) Proporcionar la infraestructura necesaria para el funcionamiento del{" "}
        <em>software</em>, es decir, garantizar el entorno básico para la
        ejecución de los módulos del <em>software</em>, identificados en el
        anexo I, como el hardware adecuado (procesador, memoria, espacio en
        disco, entre otros), sistema operativo, base de datos u otros{" "}
        <em>software</em> interdependientes, infraestructura de comunicación
        (enlaces, equipos de red) y ambiente de trabajo;
      </p>
      <p>
        c) Realizar el mantenimiento de los archivos necesarios para acceder al
        entorno del <em>PaaS</em>, así como de cualquier dato almacenado en la
        infraestructura del establecimiento empresarial del usuario, asegurando
        su total seguridad, incluyendo la realización regular de copias de
        seguridad con los medios apropiados y medios comúnmente utilizados para
        este propósito;
      </p>
      <p>
        d) Controlar la calidad de la información introducida y producida por el{" "}
        <em>software</em> y la correcta aplicación de la legislación vigente,
        pertinente al uso del <em>software</em>;
      </p>
      <p>e) Proporcionar únicamente información correcta y completa a IBBX;</p>
      <p>
        f) Garantizar la disponibilidad de profesionales para aclarar dudas,
        resolver problemas y llevar a cabo todas las actividades previamente
        definidas, así como esforzarse por cumplir con los plazos establecidos;
      </p>
      <p>
        g) Resolver cualquier problema derivado del acceso a través de la web,
        ya que las partes acuerdan que IBBX no tiene control ni responsabilidad
        sobre la estructura del establecimiento empresarial del usuario ni sobre
        las herramientas de protección contra intrusiones remotas, y cualquier
        intrusión o acceso remoto a datos bajo su custodia no significará en
        ningún caso una falla en el <em>software</em> o en la prestación de
        servicios de IBBX;
      </p>
      <p>
        h) Tomar todas las medidas necesarias para garantizar que el sistema se
        utilice de acuerdo con los términos generales y condiciones de uso, y
        será responsable de cualquier violación de la propiedad intelectual de
        IBBX o de cualquier tercero.
      </p>
      <p>
        <strong>5. Nivel de Servicio</strong>
      </p>
      <p>
        <strong>5.1. A través del</strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>{" "}
        <strong>
          IBBX realizará esfuerzos comercialmente razonables para que el
          software esté disponible, como mínimo, el 99,7% (noventa y nueve coma
          siete por ciento) del tiempo durante cada año de servicio.
        </strong>
      </p>
      <p>
        5.1.1. En caso de que IBBX no cumpla con el compromiso de nivel de
        servicio, el usuario tendrá derecho a recibir un crédito equivalente a
        01 (un) mes de tarifa mensual.
      </p>
      <p>
        5.1.1.1. Por "año de servicio" se entienden los 365 (trescientos sesenta
        y cinco) días anteriores a la fecha de una reclamación relacionada con
        el nivel de servicio.
      </p>
      <p>
        5.2. Si el usuario utiliza el <em>software</em> durante un período
        inferior a 365 días, el año de servicio que le corresponde se
        considerará aún como los 365 días anteriores; sin embargo, los días
        anteriores a su uso del servicio se considerarán con una disponibilidad
        del 100%. Los períodos de inactividad operativa que ocurran antes de una
        reclamación de crédito de servicio válida no podrán utilizarse para
        reclamaciones futuras.
      </p>
      <p>
        <strong>
          5.3. El compromiso de nivel de servicio no se aplica si las
          circunstancias de indisponibilidad resultan (i) de una interrupción en
          el suministro eléctrico o paradas de emergencia no superiores a 2
          (dos) horas o que ocurran entre las 24:00 y las 6:00 (hora de
          Brasilia); (ii) son causadas por factores fuera del control razonable
          del
        </strong>{" "}
        <em>
          {" "}
          <strong>software</strong>
        </em>
        <strong>
          , incluyendo casos de fuerza mayor o problemas de acceso a Internet y
          problemas relacionados; (iii) resultan de cualquier acto u omisión del
          usuario o de terceros; (iv) resultan del equipo,
        </strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>
        <strong>
          u otras tecnologías que el usuario utilice y que impidan el acceso
          regular; (v) resulten de fallos en instancias individuales no
          atribuibles a la indisponibilidad del usuario; (vi) resulten de
          cambios realizados en la forma de acceder a la información de la
          cuenta del usuario; (vii) resulten de prácticas de gestión de red que
          puedan afectar su calidad.
        </strong>
      </p>
      <p>
        <strong>6. Notificación de Infracciones</strong>
      </p>
      <p>
        6.1. En caso de que cualquier persona, usuario o no, se sienta
        perjudicada con respecto a cualquier problema generado por el{" "}
        <em>software</em>, podrá enviar a IBBX una notificación por escrito
        informando la reclamación, la cual notificará al usuario con las medidas
        correspondientes, sin que se le atribuya responsabilidad alguna.
      </p>
      <p>
        6.2. La eliminación de cualquier contenido o usuario por parte del{" "}
        <em>software</em>, de forma unilateral, dependerá de una comprobación
        efectiva o fuerte evidencia de ilegalidad o infracción de la ley,
        derechos de terceros y/o términos de uso.
      </p>
      <p>
        6.3. Las notificaciones deberán incluir la identificación del material
        que presuntamente representa la infracción o la información necesaria
        para la correcta identificación del contenido y las medidas a tomar con
        respecto al usuario.
      </p>
      <p>
        6.3.1. El notificante declarará que la información contenida en la
        notificación es precisa y verídica, bajo pena de incurrir en las
        responsabilidades civiles y penales correspondientes, y que el
        notificante está autorizado para actuar en nombre del titular del
        derecho supuestamente infringido.
      </p>
      <p>
        6.4. Las notificaciones deberán enviarse a la plataforma al siguiente
        correo electrónico:{" "}
        <a href="mailto:suporte@ibbx.tech">suporte@ibbx.tech</a>.
      </p>
      <p>
        <strong>7. Vigencia</strong>
      </p>
      <p>
        7.1. El presente contrato permanecerá vigente mientras dure el contrato
        de licencia firmado entre las partes por separado.
      </p>
      <p>
        <strong>8. Soporte Técnico</strong>
      </p>
      <p>
        8.1. El servicio de soporte técnico consistirá en consultoría, a través
        de <em>chat</em> o correo electrónico, para resolver dudas
        exclusivamente operativas de los usuarios del <em>software</em>, a
        personas autorizadas debidamente por el establecimiento empresarial del
        usuario, y será proporcionado por IBBX, en su sede, en horario
        comercial, de 09:00 a 12:00 y de 13:30 a 18:00, hora de Brasilia/BR, de
        lunes a viernes. IBBX se reserva el derecho de solicitar material para
        análisis que considere necesario, con los estándares y nomenclaturas
        originales del <em>software</em>.
      </p>
      <p>
        8.2. Todas las consultas deben realizarse a través de empleados o
        representantes debidamente identificados del establecimiento empresarial
        contratante, habilitados para trabajar con el <em>software</em>, que
        tengan conocimiento de su operación, del equipo utilizado, del sistema
        operativo y de los programas utilitarios.
      </p>
      <p>
        8.3. IBBX podrá requerir, a su discreción, el registro previo del
        personal autorizado como condición para acceder al soporte técnico,
        negándose a proporcionar dicho servicio a aquellos que no hayan recibido
        una capacitación específica para la operación del <em>software</em>.
      </p>
      <p>
        8.4. En caso de necesidad de enviar material para análisis a IBBX, en
        los casos en que no se configure un error o fallo de IBBX, los tiempos
        dedicados a la resolución de problemas se facturarán como asesoría
        operativa, con la presentación del respectivo informe de atención al
        establecimiento empresarial contratante y previa notificación de este
        último.
      </p>
      <p>
        8.5. El soporte técnico, así como la asesoría operativa, podrá prestarse
        mediante acceso remoto, solución que utiliza herramientas de
        comunicación y conexión que permiten acceder a la computadora del
        contratante para realizar verificaciones, ajustes y otras actividades
        inherentes al soporte técnico y asesoría operativa sobre el{" "}
        <em>software</em> con licencia.
      </p>
      <p>
        <strong>9. Terminación del Contrato</strong>
      </p>
      <p>
        9.1. El usuario y el <em>software</em> podrán rescindir estos términos y
        condiciones en cualquier momento mediante notificación, de acuerdo con
        lo establecido en el contrato de licencia firmado entre las partes.
      </p>
      <p>
        9.2. IBBX se reserva el derecho de rescindir el contrato, restringir o
        suspender el uso de los servicios por parte del usuario, mediante
        notificación en cualquier momento, cuando el uso de los servicios viole
        los términos del presente acuerdo y conforme al contrato de licencia.
      </p>
      <p>
        9.3. El usuario autoriza expresamente a IBBX a mantener en su registro
        la información proporcionada por él, así como a proporcionar la
        información contenida en dicho registro a las autoridades públicas que
        lo soliciten según lo permitido por la ley vigente y a sus socios
        estratégicos, comerciales o técnicos, con el fin de ofrecer mejores
        condiciones de promociones y/o contenido, siempre que no individualice a
        los usuarios ni a los establecimientos empresariales, según lo
        establecido en la política de privacidad.
      </p>
      <p>
        <strong>10. Uso de la Base de Datos</strong>
      </p>
      <p>
        10.1. El usuario acepta expresamente que IBBX y/o cualquiera de sus
        socios envíen mensajes de correo electrónico con carácter informativo,
        relacionados con comunicaciones específicas referentes a servicios ya
        disponibles o que vayan a estar disponibles, así como otros mensajes de
        naturaleza comercial, según lo establecido en la política de privacidad.
        En caso de que el usuario no desee seguir recibiendo dichos mensajes,
        deberá solicitar la cancelación de su envío a IBBX.
      </p>
      <p>
        10.2. La información sobre la fecha y hora de acceso y la dirección IP
        utilizada por el usuario para acceder al <em>software</em> permanecerá
        almacenada durante 06 (seis) meses a partir de la fecha de cada acceso
        realizado, independientemente de la finalización de la relación jurídica
        y comercial entre las partes, en cumplimiento con lo establecido en el
        artículo 15 de la ley n.º 12.965/2014, pudiendo ser almacenada por un
        período mayor bajo orden judicial.
      </p>
      <p>
        <strong>11. Modificaciones</strong>
      </p>
      <p>
        11.1. IBBX se reserva el derecho de modificar los términos y condiciones
        de este contrato o sus políticas relacionadas con el servicio en
        cualquier momento, efectivas tras la publicación de una versión
        actualizada del contrato sobre el servicio, de acuerdo con este
        contrato.
      </p>
      <p>
        <strong>11. Derechos de Propiedad Intelectual</strong>
      </p>
      <p>
        11.1. Todos los derechos de propiedad intelectual relacionados con el{" "}
        <em>software</em> y la tecnología permanecerán en propiedad exclusiva de
        la empresa mencionada en la identificación de este contrato, incluso si
        desarrolla nuevas funcionalidades a pedido y pago del usuario. Por lo
        tanto, la tecnología objeto de la licencia en virtud de este contrato,
        incluidos sus programas, diagramas de flujo, mejoras, adaptaciones y
        demás funcionalidades, así como toda la documentación técnica, son de
        propiedad total y definitiva de la empresa mencionada, de modo que los
        derechos de autor y otros derechos de propiedad intelectual relacionados
        son iguales a los conferidos a las obras literarias según la legislación
        de derechos de autor vigente en el país, según lo establecido en el
        artículo 2 y siguientes de la Ley 9.609/98.
      </p>
      <p>
        11.2. Al adquirir el derecho de uso del <em>software</em>, al usuario
        solo se le autoriza a actualizarlo según lo establecido en este
        documento, estando prohibido el uso de métodos como ingeniería inversa,
        descompilación o cualquier otro que permita acceder al código fuente del{" "}
        <em>software</em>.
      </p>
      <p>
        11.3. El usuario no podrá ceder a terceros el <em>software</em> y/o la
        documentación proporcionada por IBBX bajo ningún pretexto,
        comprometiéndose a mantener en buen resguardo los accesos al{" "}
        <em>software</em> y la documentación recibida por parte de sus empleados
        o representantes.
      </p>
      <p>
        11.4. El <em>software</em> está configurado de manera estándar,
        considerando la interpretación predominante sobre la aplicación de la
        legislación a la que se refiere, aunque en algunos casos será posible
        modificar parámetros para adaptarlo a situaciones específicas o para
        reflejar una interpretación particular del usuario sobre la legislación,
        mediante la firma de un acuerdo de consentimiento y/o responsabilidad
        por parte de este.
      </p>
      <p>
        <strong>
          11.5. Siempre que los parámetros originales del sistema sean
          modificados por orden del usuario, IBBX estará completamente exenta de
          cualquier responsabilidad por la generación de datos resultante, según
          el acuerdo de responsabilidad que la contratante deberá firmar.
        </strong>
      </p>
      <p>
        <strong>
          11.6. Si la contratante firma un acuerdo de consentimiento sobre los
          parámetros utilizados, IBBX estará exenta de cualquier responsabilidad
          por la generación de datos resultante.
        </strong>
      </p>
      <p>
        11.7. Mediante este contrato, se cede al usuario solo el derecho de uso
        de la tecnología en cuestión, sin la necesidad de presentar o
        proporcionar el código fuente o la estructura interna del producto.
      </p>
      <p>
        11.8. El usuario también acepta no utilizar robots u otros dispositivos
        automatizados o procesos manuales para monitorear o copiar contenido del
        servicio, bajo pena de responder ante IBBX por las pérdidas y daños
        causados, incluyendo honorarios legales, cuando sean necesarios para la
        defensa de los intereses de IBBX.
      </p>
      <p>
        11.9. IBBX se reserva el derecho de modificar, suspender, terminar o
        descontinuar cualquier aspecto del <em>software</em> en cualquier
        momento, incluyendo la disponibilidad de cualquier servicio,
        información, características o funcionalidades, así como de imponer
        limitaciones a ciertas características, funcionalidades o servicios o
        restringir el acceso del usuario a partes o la totalidad del{" "}
        <em>software</em>, previa notificación.
      </p>
      <p>
        11.9.1. IBBX se reserva también el derecho, en cualquier momento y por
        cualquier motivo, de controlar y/o modificar la apariencia, desarrollo
        y/u operaciones del <em>software</em> a su exclusivo criterio, así como
        de establecer y modificar los procedimientos de contacto entre las
        partes, sin necesidad de notificación previa.
      </p>
      <p>
        11.10. Cualquier violación a los derechos de autor del <em>software</em>{" "}
        resultará en una multa equivalente a 100 (cien) veces el valor mensual
        de uso del <em>software</em>, independientemente de las pérdidas y daños
        determinados judicialmente y de las interpelaciones penales.
      </p>
      <p>
        <strong>12. Asesoría Operacional</strong>
      </p>
      <p>
        12.1. IBBX prestará servicios de asesoría operacional al usuario
        mediante la disponibilidad de uno o más técnicos para atención local o
        remota, que no estén cubiertos por la garantía del <em>software</em> ni
        por ninguno de los otros servicios especificados en este contrato, por
        ejemplo, para la capacitación del equipo del establecimiento empresarial
        contratante, copias de seguridad, entre otros.
      </p>
      <p>
        12.2. En caso de asesoría para recuperaciones de procesamiento debido a
        errores operativos por la adopción de técnicas y métodos distintos de
        los instruidos en la capacitación o indicados en la documentación
        proporcionada por IBBX, estos servicios estarán sujetos a análisis
        técnico previo y a la determinación de costos caso por caso.
      </p>
      <p>
        12.3. El tiempo dedicado a consultas relacionadas con el sistema
        operativo, aplicaciones de terceros o utilidades y productos no
        pertenecientes a IBBX se considerará asesoría operacional.
      </p>
      <p>
        12.4. La hora técnica de asesoría operacional será equivalente a 60
        (sesenta) minutos y el servicio se prestará en horario comercial, de
        08:00 a 12:00 y de 13:15 a 18:00, según el horario de Brasilia/BR, de
        lunes a viernes.
      </p>
      <p>
        12.4.1. Los servicios prestados fuera del horario mencionado
        anteriormente tendrán un recargo del 50% (cincuenta por ciento) por
        horas extraordinarias, y cuando se presten en sábados, domingos o
        feriados nacionales o de la ciudad de Capivari-SP, la tarifa por hora se
        incrementará en un 100% (cien por ciento).
      </p>
      <p>
        12.5. Los honorarios por hora técnica de asesoría operacional se
        facturarán según los precios vigentes al momento de la prestación de los
        servicios, conforme a los términos de este contrato.
      </p>
      <p>
        12.6. Los tiempos dedicados a desplazamientos se facturarán según la
        unidad de IBBX más cercana al usuario, previa negociación entre las
        partes.
      </p>
      <p>
        12.7. El usuario no podrá solicitar cambios estructurales en el{" "}
        <em>software</em> o el desarrollo de otros módulos o funciones no
        previstos en el proyecto original a través de la asesoría operacional, y
        IBBX no estará obligada a aceptar solicitudes en este sentido.
      </p>
      <p>
        12.8. En caso de aceptar los cambios estructurales mencionados
        anteriormente, IBBX presentará un presupuesto previo para la realización
        del trabajo, debiendo firmarse un contrato específico para tal fin.
      </p>
      <p>
        12.9. Los servicios como recuperación de datos, configuración de
        periféricos e instalación de <em>software</em> de terceros se
        considerarán servicios de asesoría técnica, sujetos a cobro según
        presupuesto previo.
      </p>
      <p>
        <strong>13. De la implementación del</strong>{" "}
        <em>
          {" "}
          <strong>software</strong>{" "}
        </em>
      </p>
      <p>
        13.1. El proceso de implementación seguirá la metodología estándar de
        IBBX, salvo acuerdo entre las partes debidamente documentado y aprobado
        en actas de reunión y planes de acción. La definición de las actividades
        a desarrollar, la metodología empleada y el nivel respectivo de
        prioridades son competencia y responsabilidad de IBBX, debiendo ser
        seguidas íntegramente por el usuario.
      </p>
      <p>
        13.2. En caso de cambiar cualquier componente del entorno técnico de
        operación que implique actividades adicionales a las previstas en este
        contrato, se informará a IBBX con al menos 30 (treinta) días de
        anticipación, reservándose el derecho de mantener o no la implementación
        en el nuevo entorno, así como de cobrar o no, previa cotización, los
        costos de adaptación del cronograma. En este caso, el plazo será
        establecido por IBBX, que también determinará el nuevo valor de la
        actualización.
      </p>
      <p>
        13.3. El establecimiento empresarial contratante designará un
        colaborador o representante como coordinador responsable del proyecto de
        implementación y mantenimiento del <em>software</em>, con conocimientos
        del entorno operativo relacionado con el contrato de licenciamiento.
      </p>
      <p>
        13.4. Corresponderá a IBBX capacitar a los empleados o representantes
        del establecimiento empresarial contratante para habilitarlos,
        capacitarlos y certificarlos en el manejo de todas las rutinas del{" "}
        <em>software</em>.
      </p>
      <p>
        13.5. IBBX no se responsabiliza del entrenamiento de los usuarios en la
        operación del equipo y su sistema operativo respectivo, y se reserva el
        derecho de solicitar la sustitución de aquellos que presenten un
        desempeño insatisfactorio.
      </p>
      <p>
        13.6. Si es necesario retrabajar en actividades de implementación
        después de la entrega del <em>software</em>, el tiempo dedicado a la
        ejecución de estas tareas se facturará como asesoría operacional.
      </p>
      <p>
        13.7. IBBX coordinará y llevará a cabo el proceso de implementación del{" "}
        <em>software</em>, insertando y parametrizando información y reglas de
        negocio en total conformidad con la legislación vigente, debiendo el
        establecimiento empresarial contratante firmar un acuerdo de
        consentimiento después de analizar y validar los parámetros introducidos
        en el <em>software</em>.
      </p>
      <p>
        <strong>14. De la actualización y personalización del software</strong>
      </p>
      <p>
        14.1. La actualización del <em>software</em> comprende todos los cambios
        en programas y su documentación respectiva que IBBX cree y que requieran
        actualización, complementación o reprogramación, buscando mejoras o la
        instalación de nuevas operaciones o por cambios en la legislación.
      </p>
      <p>
        14.2. La interpretación legal de las normas emitidas por el gobierno y
        su implementación en el <em>software</em> se realizarán con base en
        publicaciones especializadas y divulgadas sobre cada materia en medios
        de comunicación de dominio público. Sin embargo, la responsabilidad de
        aprobar los parámetros será siempre del establecimiento empresarial
        contratante y del usuario.
      </p>
      <p>
        14.3. Las interpretaciones divergentes por parte del establecimiento
        empresarial contratante y del usuario, cuando se implementen, se
        considerarán como personalizaciones del <em>software</em>, y IBBX no
        estará obligada a implementar cambios basados únicamente en la
        evaluación de un usuario, pero sí lo hará según el requerimiento de la
        mayoría de sus clientes empresariales.
      </p>
      <p>
        14.4. Las modificaciones impuestas por asociaciones, sindicatos u otros
        grupos específicos podrán implementarse previa aprobación
        presupuestaria.
      </p>
      <p>
        14.5. Las mejoras y nuevas funciones introducidas por IBBX en el{" "}
        <em>software</em> originalmente licenciado se distribuirán para dotar al
        usuario siempre con la última versión del <em>software</em>.
      </p>
      <p>
        14.6. IBBX podrá realizar complementos en el <em>software</em> por
        solicitud escrita del usuario. En este caso, se facturarán como
        personalizaciones las horas trabajadas en la sede del establecimiento
        empresarial contratante para levantamientos, implementación y
        capacitación, presentando un informe de atención.
      </p>
      <p>
        14.7. Las actividades de análisis, programación, pruebas, documentación
        y distribución de los cambios realizados en la sede de IBBX se
        facturarán según presupuesto previo o presentación de informes y
        resultados de cada fase.
      </p>
      <p>
        14.8. La garantía de actualización del <em>software</em> expresada en
        esta cláusula no cubre las personalizaciones realizadas en el{" "}
        <em>software</em> por solicitud del usuario.
      </p>
      <p>
        14.9. La Propiedad Intelectual de las personalizaciones realizadas por
        IBBX en el <em>software</em>, incluso por solicitud del usuario, será
        exclusiva de IBBX y podrá ser incorporada en las versiones distribuidas
        a otros clientes de esta, sin que quepa ningún tipo de reembolso o
        compensación al usuario o al establecimiento empresarial contratante.
      </p>
      <p>
        <strong>15. De la confidencialidad</strong>
      </p>
      <p>
        15.1. La contratada declara que almacenará la información del
        establecimiento empresarial contratante y del usuario con la misma
        seguridad y dedicación para su protección como si fuera propia,
        utilizando equipos y <em>software</em> adecuados para la custodia de
        todos los documentos e información en su posesión.
      </p>
      <p>
        15.2. Las demás regulaciones sobre la recopilación, custodia y uso de la
        información están debidamente tratadas en la Política de Privacidad,
        siendo parte integral de este documento.
      </p>
      <p>
        <strong>16. Disposiciones generales</strong>
      </p>
      <p>
        16.1. El usuario acepta que IBBX puede divulgar la celebración de este
        instrumento para fines comerciales, mencionando el nombre y la marca del
        usuario en campañas comerciales, y puede divulgar mensajes enviados por
        escrito o verbalmente, por teléfono, para uso en sitios web, periódicos,
        revistas y otras campañas, incluso después de la terminación de estos
        términos.
      </p>
      <p>
        16.2. Los casos no previstos y cualquier duda relacionada con la
        ejecución de este contrato se resolverán mediante consultas y
        entendimientos mutuos entre las partes, firmando un acuerdo adicional
        siempre que sea necesario.
      </p>
      <p>
        16.3. Las partes declaran expresamente que la demora o la omisión en el
        ejercicio de los derechos que les sean conferidos por ley o por este
        instrumento no constituirá una renuncia o novación de tales derechos, ni
        perjudicará su eventual y oportuno ejercicio.
      </p>
      <p>
        16.4. La renuncia a derechos que les asistan en virtud de ley o del
        presente contrato solo será válida si se formaliza por escrito.
      </p>
      <p>
        16.5. La nulidad o invalidez de cualquiera de las cláusulas del presente
        contrato no afectará la validez y eficacia de las demás, las cuales
        permanecerán en pleno vigor y efecto. En tal caso, las partes deberán
        negociar de buena fe la redacción de una nueva cláusula en sustitución
        de la considerada nula o inválida, reflejando la intención de las partes
        en el presente contrato.
      </p>
      <p>
        16.6. Toda correspondencia, notificación y comunicación entre las partes
        deberá realizarse por correo electrónico, carta certificada,
        notificación notarial u otro medio idóneo que permita confirmación de
        recepción, dirigidas a las direcciones indicadas en el preámbulo del
        presente contrato. La parte que cambie de dirección, correo electrónico
        o teléfono deberá comunicarlo inmediatamente por escrito a la otra
        parte.
      </p>
      <p>
        16.7. El usuario no podrá ceder, sublicenciar, subcontratar, transferir
        o disponer de ningún derecho u obligación en el ámbito de estos términos
        de uso sin el consentimiento previo de IBBX. IBBX podrá ceder el
        contrato o los derechos derivados del mismo a cualquiera de las
        sociedades del grupo económico del cual forma parte o pueda formar parte
        en el futuro, comprometiéndose a comunicar su intención al usuario.
      </p>
      <p>
        16.8. Los impuestos y demás cargas fiscales que sean debidos, directa o
        indirectamente, en virtud de los valores pagados o recibidos a través de
        este contrato o en virtud de su ejecución serán responsabilidad del
        contribuyente definido según la normativa tributaria, sin derecho a
        reembolso.
      </p>
      <p>
        16.9. Estos términos de uso (junto con cualquier otro término y
        condición acordados entre las partes, incluyendo la propuesta comercial,
        la Política de Privacidad y el contrato de licenciamiento) constituyen
        el acuerdo completo entre las partes y reemplazan todos los acuerdos
        previos.
      </p>
      <p>
        <strong>13. Del foro y la legislación aplicable</strong>
      </p>
      <p>
        13.1. Este contrato se regirá por la legislación brasileña, eligiendo
        las partes el foro de Capivari, São Paulo, como único competente para
        conocer y resolver cualquier disputa derivada del presente contrato, con
        renuncia expresa a cualquier otro foro por más privilegiado que sea.
      </p>
      <p>
        13.2. Estos términos de uso y todas las relaciones derivadas de los
        mismos están sujetos a las leyes de la República Federativa de Brasil.
      </p>
      <p>
        <strong>POLÍTICA DE PRIVACIDAD</strong>
      </p>
      <p>
        En línea con su política de transparencia hacia los clientes y en
        cumplimiento de lo establecido por la Ley 13.709 del 14 de agosto de
        2018, IBBX describe e implementa su Política de Privacidad de la
        siguiente manera.
      </p>
      <p>
        <strong>Definiciones</strong>
      </p>
      <p>
        <strong>Ley General de Protección de Datos (LGPD):</strong> dispone
        sobre el tratamiento de datos personales, incluso en medios digitales,
        por persona natural o jurídica de derecho público o privado, con el
        objetivo de proteger los derechos fundamentales de libertad y privacidad
        y el libre desarrollo de la personalidad de la persona natural.
      </p>
      <p>
        <strong>Dato personal:</strong> información relacionada con una persona
        natural identificada o identificable.
      </p>
      <p>
        <strong>Dato personal sensible:</strong> dato personal sobre origen
        racial o étnico, creencias religiosas, opiniones políticas, afiliación
        sindical u organización de carácter religioso, filosófico o político,
        datos relacionados con la salud o vida sexual, datos genéticos o
        biométricos cuando están vinculados a una persona natural.
      </p>
      <p>
        <strong>Dato anonimizado:</strong> dato relativo a un titular que no
        puede ser identificado, considerando el uso de medios técnicos
        razonables y disponibles en el momento de su tratamiento.
      </p>
      <p>
        <strong>Titular:</strong> persona natural a quien se refieren los datos
        personales que son objeto de tratamiento.
      </p>
      <p>
        <strong>Controlador:</strong> persona natural o jurídica, de derecho
        público o privado, responsable de las decisiones relacionadas con el
        tratamiento de datos personales.
      </p>
      <p>
        <strong>Operador:</strong> persona natural o jurídica, de derecho
        público o privado, que realiza el tratamiento de datos personales en
        nombre del controlador.
      </p>
      <p>
        <strong>Agentes de tratamiento:</strong> el controlador y el operador.
      </p>
      <p>
        <strong>Tratamiento:</strong> toda operación realizada con datos
        personales.
      </p>
      <p>
        <strong>Consentimiento:</strong> manifestación libre, informada e
        inequívoca mediante la cual el titular acepta el tratamiento de sus
        datos personales para una finalidad determinada.
      </p>
      <p>
        <strong>Transferencia internacional de datos:</strong> transferencia de
        datos personales a un país extranjero u organismo internacional del cual
        el país sea miembro.
      </p>
      <p>
        <strong>Uso compartido de datos:</strong> comunicación, difusión,
        transferencia internacional, interconexión de datos personales o
        tratamiento compartido de bases de datos personales por organismos
        públicos en el cumplimiento de sus competencias legales, o entre estos y
        entidades privadas, reciprocamente, con autorización específica, para
        una o más modalidades de tratamiento permitidas por esos entes públicos,
        o entre entes privados.
      </p>
      <p>
        <strong>
          Informe de impacto a la protección de datos personales (DPIA):
        </strong>{" "}
        documentación del controlador que describe los procesos de tratamiento
        de datos personales que pueden generar riesgos a las libertades civiles
        y derechos fundamentales, así como medidas, salvaguardias y mecanismos
        de mitigación de riesgos.
      </p>
      <p>
        <strong>Autoridad nacional (ANPD):</strong> órgano de la administración
        pública indirecta responsable de velar, implementar y fiscalizar el
        cumplimiento de esta Ley.
      </p>
      <p>
        <strong>Encargado (DPO):</strong> persona natural designada por el
        controlador que actúa como canal de comunicación entre el controlador,
        los titulares y la autoridad nacional.
      </p>
      <p>
        <strong>Principios</strong>
      </p>
      <p>
        En cuanto al tratamiento de datos personales de sus clientes y en
        conformidad con lo establecido en el artículo 6 de la Ley 13.709/2018,
        IBBX observa los siguientes principios:
      </p>
      <p>
        I – finalidad: realizar el tratamiento para propósitos legítimos,
        específicos, explícitos e informados al titular, sin posibilidad de
        tratamiento posterior de manera incompatible con estas finalidades;
      </p>
      <p>
        II – adecuación: compatibilidad del tratamiento con las finalidades
        informadas al titular, según el contexto del tratamiento;
      </p>
      <p>
        III – necesidad: limitar el tratamiento al mínimo necesario para
        alcanzar sus finalidades, abarcando datos pertinentes, proporcionales y
        no excesivos en relación con las finalidades del tratamiento de datos;
      </p>
      <p>
        IV – libre acceso: garantizar a los titulares el acceso fácil y gratuito
        para consultar la forma y duración del tratamiento, así como la
        integridad de sus datos personales;
      </p>
      <p>
        V – calidad de los datos: asegurar a los titulares la exactitud,
        claridad, relevancia y actualización de los datos, según sea necesario y
        para cumplir con la finalidad de su tratamiento;
      </p>
      <p>
        VI – transparencia: proporcionar a los titulares información clara,
        precisa y fácilmente accesible sobre el tratamiento realizado y los
        respectivos agentes de tratamiento, respetando los secretos comerciales
        e industriales;
      </p>
      <p>
        VII – seguridad: emplear medidas técnicas y administrativas adecuadas
        para proteger los datos personales contra accesos no autorizados y
        situaciones accidentales o ilícitas de destrucción, pérdida, alteración,
        comunicación o difusión;
      </p>
      <p>
        VIII – prevención: adoptar medidas para prevenir daños derivados del
        tratamiento de datos personales;
      </p>
      <p>
        IX – no discriminación: impedir el tratamiento con fines
        discriminatorios ilícitos o abusivos;
      </p>
      <p>
        X – responsabilidad y rendición de cuentas: demostrar, por parte del
        agente, la implementación de medidas eficaces y capaces de comprobar el
        cumplimiento de las normas de protección de datos personales, incluyendo
        la eficacia de dichas medidas.
      </p>
      <p>
        <strong>
          Tratamiento de datos personales (Ciclo de vida de los datos
          personales)
        </strong>
      </p>
      <p>
        La Plataforma de monitorización industrial IBBX opera bajo el modelo{" "}
        <em>Plataforma como Servicio</em> (PaaS). El <em>PaaS</em> funciona
        mediante la recolección de información a través de sensores y pasarelas
        (hardware). La Plataforma IBBX recopila datos mediante sensores
        inalámbricos, transmitiendo estos datos a las pasarelas IBBX a través
        del protocolo de comunicación propietario IBBX L2RC. A partir de una
        comunicación genérica entre las pasarelas y la nube, los datos
        recopilados son analizados por el software IBBX Retina, brindando al
        cliente la capacidad de monitorear en tiempo real los activos
        supervisados y apoyando la toma de decisiones. Después de la
        introducción de datos, el titular tendrá sus datos personales tratados
        de manera estandarizada, según se describe a continuación:
      </p>
      <p>
        <strong>1. Origen de los datos</strong>
      </p>
      <p>
        Los datos personales y sensibles que IBBX se compromete a controlar y
        proteger provienen de las siguientes fuentes:
      </p>
      <p>(i) datos personales de los colaboradores de IBBX;</p>
      <p>(ii) datos personales de clientes potenciales y clientes de IBBX;</p>
      <p>(iii) datos personales de proveedores y socios de IBBX.</p>
      <p>
        <strong>2. Finalidad</strong>
      </p>
      <p>Considerando:</p>
      <p>a) los datos personales de los colaboradores de IBBX</p>
      <p>
        La recolección de datos personales de los colaboradores de IBBX no se
        utilizará para ningún tratamiento posterior diferente al ya expuesto,
        salvo en casos de necesidad legal (respuesta a pasivos laborales,
        solicitud judicial para fines procesales, etc.).
      </p>
      <p>
        b) los datos personales de clientes potenciales, clientes y socios de
        IBBX
      </p>
      <p>
        La recolección de datos personales de clientes potenciales, clientes y
        socios de IBBX tiene como finalidad específica la comercialización de
        productos y servicios ofrecidos por IBBX y la prestación de servicios.
        Los datos personales se utilizarán para enviar mensajes con contexto
        comercial, así como información relacionada con los productos y
        servicios ofrecidos por IBBX.
      </p>
      <p>
        Los datos no se utilizarán para ningún tratamiento posterior diferente
        al ya expuesto, como enriquecimiento de la base de datos, venta de la
        base de datos a terceros, etc.
      </p>
      <p>c) los datos personales de proveedores y socios de IBBX.</p>
      <p>
        La recolección de datos personales de proveedores y socios de IBBX tiene
        como finalidad específica la contratación comercial para la prestación
        de servicios. Los datos personales se utilizarán para cumplir con la
        legislación específica en cuanto a la emisión de facturas y temas
        relacionados, contando con respaldo legal.
      </p>
      <p>
        Los datos no se utilizarán para ningún tratamiento posterior diferente
        al ya expuesto, salvo en casos de necesidad legal (respuesta a pasivos
        laborales, solicitud judicial para fines procesales, etc.).
      </p>
      <p>
        <strong>3. Recolección</strong>
      </p>
      <p>
        Los datos descritos en el ítem 1 (Origen de los datos) serán
        recolectados a través de sensores inalámbricos, transmitiendo estos
        datos a las pasarelas IBBX mediante el protocolo de comunicación
        propietario IBBX L2RC.
      </p>
      <p>
        <strong>4. Clasificación</strong>
      </p>
      <p>Considerando:</p>
      <p>a) los datos personales de los colaboradores de IBBX</p>
      <p>
        Para la recolección de datos personales de los colaboradores de IBBX no
        se requiere el consentimiento de sus titulares debido a que los datos
        personales cuentan con respaldo legal.
      </p>
      <p>b) los datos personales de potenciales clientes y clientes de IBBX</p>
      <p>
        Al registrar los datos personales para acceder a las pruebas y/o
        adquirir los productos y servicios de IBBX, el titular es informado
        sobre la Política de Privacidad de IBBX (disponible en el sitio{" "}
        <a href="https://ibbx.tech\">ibbx.tech</a>) y sobre cómo se tratarán sus
        datos.
      </p>
      <p>
        Al aceptar la Política de Privacidad de IBBX, el titular consiente
        formalmente el uso de sus datos personales y empresariales, conforme a
        lo descrito en este documento.
      </p>
      <p>
        La falta de consentimiento formal para el uso de sus datos personales
        por parte de IBBX y/o sus operadores puede resultar en impedimentos o
        restricciones de acceso a los productos y servicios ofrecidos para
        potenciales clientes o clientes de IBBX.
      </p>
      <p>c) los datos personales de proveedores y socios de IBBX</p>
      <p>
        Al iniciar una relación con IBBX, los proveedores y socios son
        informados sobre la Política de Privacidad de IBBX.
      </p>
      <p>
        La formalización del consentimiento ocurre al firmar el contrato de
        prestación de servicios entre las partes.
      </p>
      <p>
        Al aceptar la Política de Privacidad de IBBX, el titular consiente
        formalmente el uso de sus datos personales, conforme a lo descrito en
        este documento.
      </p>
      <p>
        La falta de consentimiento formal para el uso de sus datos personales
        por parte de IBBX y/o sus operadores puede resultar en la inviabilidad
        de la prestación de servicios para potenciales proveedores o socios de
        IBBX.
      </p>
      <p>
        <strong>5. Acceso</strong>
      </p>
      <p>
        IBBX asegura al titular libre acceso a sus datos mediante solicitud
        previa por escrito. Con un inicio de sesión y contraseña únicos
        proporcionados por IBBX al titular, este podrá acceder a sus datos
        personales en cualquier momento y solicitar la eliminación,
        modificación/actualización u obtener cualquier información relacionada
        con dicho contenido.
      </p>
      <p>
        <strong>6. Modificación</strong>
      </p>
      <p>
        Solo IBBX está autorizada a modificar, corregir o alterar los datos
        personales del titular, previa solicitud formal (autorización) del
        mismo.
      </p>
      <p>
        IBBX se reserva el derecho de realizar las modificaciones solicitadas en
        un plazo máximo de 30 días a partir de la fecha de la solicitud formal.
      </p>
      <p>
        <strong>7. Actualización</strong>
      </p>
      <p>
        Cuando sea necesario, y para asegurar que los datos personales del
        titular permanezcan vigentes, IBBX podrá actualizarlos mediante correo
        electrónico o contacto telefónico.
      </p>
      <p>
        <strong>8. Reproducción</strong>
      </p>
      <p>
        La reproducción de los datos personales a los que IBBX tiene acceso
        considera:
      </p>
      <p>a) los datos personales de los colaboradores de IBBX</p>
      <p>
        Los datos personales de los colaboradores son reproducidos para la
        empresa de contabilidad de IBBX (operadora), responsable del
        procesamiento de pagos y otros derechos laborales garantizados por ley.
      </p>
      <p>b) los datos personales de potenciales clientes y clientes de IBBX</p>
      <p>
        IBBX reproduce los datos personales de sus potenciales clientes y
        clientes para empresas asociadas (operadoras), las cuales están
        instruidas a tratar los datos de acuerdo con lo establecido por IBBX
        (controlador), conforme a lo descrito en este documento.
      </p>
      <p>c) los datos personales de proveedores y socios de IBBX</p>
      <p>
        Los datos personales de proveedores y socios de IBBX son reproducidos
        para la empresa de contabilidad de IBBX (operadora), responsable del
        procesamiento de pagos y otros derechos comerciales garantizados por
        ley.
      </p>
      <p>
        <strong>9. Transmisión</strong>
      </p>
      <p>
        Todos los datos personales a los que IBBX tiene acceso son transmitidos
        a las operadoras mediante hojas de cálculo protegidas por contraseña y/o
        por correo electrónico.
      </p>
      <p>
        <strong>10. Procesamiento</strong>
      </p>
      <p>
        Las empresas operadoras relacionadas con IBBX están instruidas a tratar
        los datos personales de acuerdo con la finalidad explícita en este
        documento (Política de Privacidad de IBBX), prohibiéndose el
        enriquecimiento de la base de datos y el uso para cualquier otra
        finalidad no comunicada y formalizada por IBBX.
      </p>
      <p>
        <strong>11. Archivado</strong>
      </p>
      <p>
        Los datos personales controlados por IBBX se almacenan en la base de
        datos de IBBX y en los servidores de las empresas asociadas operadoras (
        <em>cloud</em>).
      </p>
      <p>
        <strong>12. Eliminación</strong>
      </p>
      <p>
        Los datos personales controlados por IBBX permanecen archivados en la
        base de datos de IBBX y en los servidores de las empresas asociadas
        operadoras (<em>cloud</em>) por tiempo indefinido, a menos que el
        titular solicite su eliminación.
      </p>
      <p>
        Los datos personales de los colaboradores de IBBX solo se eliminan
        después de expirar los plazos legales aplicables a cada tipo de
        información.
      </p>
    </>
  );
}
