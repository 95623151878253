import styled from "styled-components";

type BodyProps = {
  hasMargin?: boolean;
};

export const Container = styled.div<{ scrollable?: boolean }>`
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  max-height: auto;

  @media screen and (max-width: 767px) {
    height: calc(100vh - 4px);
    margin-bottom: 6px;

    & > div:last-child {
      overflow-y: hidden;
    }
  } ;
`;

export const Body = styled.div<BodyProps>`
  position: relative;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
`;
