import { Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";

import { FormikTextInput } from "../../../../components/textInput";
import { success } from "../../../../components/Toast";
import useApi from "../../../../hooks/useApi";
import { PROFILES } from "../../../../store/slices/userSlice";

import { CancelButton, Content, Footer, SaveButton } from "./styles";

import { FormUserRepresentativesProps } from "./types";
import { useTranslation } from "react-i18next";
import { PhoneInputComponent } from "../../../../components/PhoneInputComponent";

const FormUserRepresentative = ({
  handleCancel,
  representative,
  userToEdit,
}: FormUserRepresentativesProps) => {
  const { request } = useApi({ path: "/users" });
  const { request: requestUserData, processing } = useApi({
    path: `/users/${userToEdit}`,
  });

  const [userData, setUserData] = useState<any>();

  const formRef = useRef<FormikProps<any>>(null);

  const { t } = useTranslation();

  const handleSubmit = (values: any) => {
    let body = {
      ...values,
      profile: PROFILES.REPRESENTANTE,
      representativeId: representative.id,
    };

    if (userToEdit) {
      body = {
        name: values.name,
        phone: values.phone,
      };

      requestUserData({ method: "put", body }).then(() => {
        handleCancel();
        success(`${t("Representatives.FormUserRepresentative.edited")}`);
      });
    } else {
      request({ method: "post", body })
        .then(() => {
          handleCancel();
          success(`${t("Representatives.FormUserRepresentative.registered")}`);
        })
        .catch((err) => {
          const { data } = err?.response || {};
          if (!data) {
            handleCancel();
          } else {
            data.map(({ field, message }: any) =>
              formRef.current?.setFieldError(field, message),
            );
          }
        });
    }
  };

  const initialValues = {
    name: userData?.name || "",
    email: userData?.email || "",
    phone: userData?.phone || "",
    profile: PROFILES.REPRESENTANTE,
  };

  const getUserData = () => {
    requestUserData({ method: "get" }).then((response) => {
      setUserData(response);
    });
  };

  useEffect(() => {
    if (userToEdit) {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToEdit]);

  return (
    <Content>
      <h2>
        {userToEdit
          ? `${t("Representatives.FormUserRepresentative.edit")}`
          : `${t("Representatives.FormUserRepresentative.new")}`}{" "}
        {t("Representatives.FormUserRepresentative.repUser")}{" "}
      </h2>

      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        validate={(values) => {
          const errors: any = {};
          if (!values.name.trim()) {
            errors.name = t(
              "Representatives.FormUserRepresentative.mandatoryName",
            );
          }

          if (!values.email) {
            errors.email = t(
              "Representatives.FormUserRepresentative.mandatoryEmail",
            );
          }

          return errors;
        }}
        onSubmit={handleSubmit}
      >
        {({ errors }) => (
          <Form>
            <FormikTextInput
              name="name"
              label={`${t("Representatives.FormUserRepresentative.name")}`}
              error={errors.name}
              disabled={processing}
              loading={processing}
            />
            <FormikTextInput
              name="email"
              label={!!userToEdit ? "Email" : "Email *"}
              error={errors.email}
              disabled={!!userToEdit}
              type="email"
              loading={processing}
            />
            <PhoneInputComponent
              name="phone"
              error={errors}
              label={t("Representatives.FormUserRepresentative.phone") || ""}
              loading={processing}
            />
            <FormikTextInput
              name="profile"
              label={`${t("Representatives.FormUserRepresentative.profile")}`}
              disabled={true}
              loading={processing}
            />
            <Footer>
              <CancelButton type="button" onClick={handleCancel}>
                {t("Representatives.FormUserRepresentative.cancel")}
              </CancelButton>
              <SaveButton type="submit">
                {userToEdit
                  ? t("Representatives.FormUserRepresentative.edit")
                  : t("Representatives.FormUserRepresentative.create")}
              </SaveButton>
            </Footer>
          </Form>
        )}
      </Formik>
    </Content>
  );
};

export default FormUserRepresentative;
