import { ChangeEventHandler, ReactNode, useState } from "react";
import { Form, Collapse } from "react-bootstrap";

import {
  Check,
  CollapseContainer,
  CollapseLine,
  Container,
  Deliver,
  Description,
  OptionsType,
  StandardContent,
  Subject,
} from "./styles";
import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";
interface NotificationItemProps {
  description?: string | undefined;
  formChecked?: boolean;
  handleChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  hasntBackground?: boolean;
  children?: ReactNode;
  renderCollapse?: ReactNode;
  checkType: "switch" | "checkbox" | "radio";
  subject?: any;
  deliver?: any;
}

export function NotificationItem({
  children,
  description,
  formChecked,
  handleChange,
  hasntBackground,
  renderCollapse,
  checkType,
  subject,
  deliver,
}: NotificationItemProps) {
  const [openCollapse, setOpenCollapse] = useState(
    renderCollapse ? true : false,
  );

  return (
    <>
      <Container hasntBackground={hasntBackground}>
        <StandardContent>
          <Subject>
            {subject && <h2>{subject}</h2>}
            <div>
              <Check checkType={checkType}>
                <Form.Check
                  name="selectAll"
                  type={checkType}
                  checked={formChecked}
                  label={``}
                  onChange={handleChange}
                />
              </Check>
              <Description>
                <span>{description}</span>
              </Description>
            </div>
          </Subject>
          <Deliver>
            {deliver && <h2>{deliver}</h2>}
            <div>
              <OptionsType>{children}</OptionsType>
            </div>
          </Deliver>
        </StandardContent>
        {renderCollapse && (
          <CollapseLine>
            {openCollapse ? (
              <IoChevronUpSharp
                aria-controls="collapse"
                onClick={() => setOpenCollapse(!openCollapse)}
              />
            ) : (
              <IoChevronDownSharp
                aria-controls="collapse"
                onClick={() => setOpenCollapse(!openCollapse)}
              />
            )}
          </CollapseLine>
        )}
      </Container>
      <Collapse in={openCollapse}>
        <CollapseContainer id="collapse" hasCollapse={renderCollapse}>
          {renderCollapse}
        </CollapseContainer>
      </Collapse>
    </>
  );
}
