import { useCallback, useMemo } from "react";
import { Amplify, API } from "aws-amplify";

import { awsConfig } from "../store/awsConfig";

interface IUseRequest {
  path: string;
  body?: any;
  queryStringParameters?: any;
}

type UseRequestReturn = {
  cancel: () => void;
  request: Promise<any>;
};

Amplify.configure(awsConfig);
const apiRetinaMonitorator = awsConfig?.API?.endpoints?.[0]?.name;

export function useRequest({
  path,
  body,
  queryStringParameters,
}: IUseRequest): UseRequestReturn {
  const request: Promise<any> = useMemo(() => {
    return API.get(apiRetinaMonitorator, path, {
      body: body || {},
      queryStringParameters: queryStringParameters || {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancel = useCallback(() => {
    API.cancel(request, "Cancel Request");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { request, cancel };
}

export default useRequest;
