import { useRef } from "react";
import { MdClose, MdOutlineUpload } from "react-icons/md";
import { Container, ImageChangerContainer } from "./styles";
import { ImageUploadCardProps } from "./types";
import Resizer from "react-image-file-resizer";
import { error } from "../Toast";
import { useTranslation } from "react-i18next";

export const ImageUploadCard = ({
  image,
  changeImage,
  important,
  value,
  label,
  accept,
  hideRemove,
  isConfigPage,
  name,
  disabled = false,
}: ImageUploadCardProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation();

  const handleInputChange = function (
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    event.preventDefault();

    if (!event.target || !event.target.files || !event.target.files[0]) {
      return;
    }

    try {
      Resizer.imageFileResizer(
        event.target.files[0],
        300,
        300,
        "PNG",
        300,
        0,
        (uri) => {
          changeImage(uri as string);
        },
        "base64",
        200,
        200,
      );
    } catch (err) {
      error(`${t("companyForm.toast.invalidFormat")}`);
      console.error(err);
    }
  };

  function removeImage() {
    changeImage("");
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }

  return (
    <Container>
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        style={{ display: "none" }}
        accept={accept || ""}
        onChange={handleInputChange}
        name={name}
      />

      <ImageChangerContainer
        disabled={disabled}
        isConfigPage={isConfigPage}
        className="upload-button"
        onClick={(e: any) => {
          if (buttonRef.current && buttonRef.current.contains(e.target)) {
            return;
          }
          if (inputRef.current) {
            inputRef.current.click();
          }
        }}
      >
        {image ? (
          <>
            {!hideRemove && (
              <button type="button" ref={buttonRef} onClick={removeImage}>
                <MdClose />
              </button>
            )}
            <img src={image} alt="imagem" />
          </>
        ) : (
          <div>
            <MdOutlineUpload />
            <span>{label ? label : t("companyForm.companyTab.addPhoto")}</span>
          </div>
        )}
      </ImageChangerContainer>
    </Container>
  );
};
