import styled from "styled-components";

export const Container = styled.div<{ hide?: boolean }>`
  background-color: ${(props) => props.theme.colors.divBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  height: 100%;
  padding: 10px;
  border-radius: 6px;
  display: ${(props) => props.hide && "none"};

  iframe {
    display: ${(props) => props.hide && "none"};
  }
  @media screen and (max-width: 767px) {
    & > div {
      height: calc(100% - 7rem) !important;
    }
  }
`;
