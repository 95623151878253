import { useState, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Modal } from "../../components/Modal";
import { Select } from "../../components/Select";
import useApi from "../../hooks/useApi";
import { ROUTES_PATH } from "../../routes/RoutesPath";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { appSelect, setOccurrenceRegister } from "../../store/slices/appSlice";
import { PROFILES, userSelect } from "../../store/slices/userSlice";
import { getUrlPattern } from "../../utils/getUrlPattern";
import { OccurrenceHiearchyModalContainer } from "./styles";
import { useSegment } from "../../hooks/useSegmentTrackEvent ";
import { useTranslation } from "react-i18next";

interface IOccurrenceHiearchyModal {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

function OccurrenceHiearchyModal({
  show,
  handleClose,
}: IOccurrenceHiearchyModal) {
  const location = useLocation();
  const params = useParams();
  const { profile } = useAppSelector(userSelect);
  const { currentCompany, currentFacility, currentAsset } =
    useAppSelector(appSelect);
  const { handleTrackEvent } = useSegment();

  const { CLIENTE_COMUM } = PROFILES;
  const urlPattern = getUrlPattern(location, params);
  const isCommonClient = CLIENTE_COMUM === profile;
  const isFacilitiesPage = ROUTES_PATH.FACILITIES === urlPattern;
  const isAssetsPage = ROUTES_PATH.ASSETS === urlPattern;
  const isAssetPage = ROUTES_PATH.ASSET === urlPattern;

  function getInitialValues() {
    const hiearchy: any = {},
      options: any = {};

    if (currentCompany.id) {
      hiearchy.company = {
        value: currentCompany.id,
        label: currentCompany.name,
      };
      options.company = [hiearchy.company];
    }
    if (currentFacility.id) {
      hiearchy.facility = {
        value: currentFacility.id,
        label: currentFacility.name,
        contract: currentFacility.contract,
      };
      options.facility = [hiearchy.facility];
    }
    if (currentAsset.id) {
      hiearchy.asset = {
        value: currentAsset.id,
        label: currentAsset.name,
      };
      options.asset = [hiearchy.asset];
    }
    return { hiearchy, options };
  }

  const [hiearchy, setHiearchy] = useState<any>({});
  const [options, setOptions] = useState<any>({});

  useEffect(() => {
    const result = getInitialValues();
    setHiearchy(result.hiearchy);
    setOptions(result.options);
    // eslint-disable-next-line
  }, []);

  const companiesApi = useApi({ path: "/companies-options" });
  const facilitiesApi = useApi({ path: "/facilities-options" });
  const assetsApi = useApi({ path: "/assets-options" });

  const companyId = useMemo(() => {
    return hiearchy?.company?.value || currentCompany.id;
  }, [hiearchy?.company?.value, currentCompany.id]);

  const facilityId = useMemo(() => {
    return hiearchy?.facility?.value || currentFacility.id;
  }, [hiearchy?.facility?.value, currentFacility.id]);

  function loadCompanies() {
    const queryStringParameters = { orderByName: 1 };

    companiesApi
      .request({ method: "get", queryStringParameters })
      .then((response) => {
        const notReadOnly = response.filter((item: any) => !item.isReadOnly);
        updateOptions("company", notReadOnly);
      });
  }

  function loadFacilities() {
    const queryStringParameters = { orderByName: 1, companyId };

    facilitiesApi
      .request({ method: "get", queryStringParameters })
      .then((response) => {
        const notReadOnly = response.filter((item: any) => !item.isReadOnly);
        updateOptions("facility", notReadOnly);
      });
  }

  function loadAssets() {
    const queryStringParameters = { orderByName: 1, facilityId };

    assetsApi
      .request({ method: "get", queryStringParameters })
      .then((response) => {
        updateOptions(
          "asset",
          response.map((item: any) => ({
            value: item.value,
            label: item.label,
            occurrenceCustomFields: item.occurrenceCustomFields,
            companyId: item.companyId,
          })),
        );
      });
  }

  function updateOptions(name: string, value: any) {
    const newOptions = { [name]: value };

    if (name === "facility") {
      newOptions.company = options.company;
    } else if (name === "asset") {
      newOptions.company = options.company;
      newOptions.facility = options.facility;
    } else if (name === "position") {
      newOptions.company = options.company;
      newOptions.facility = options.facility;
      newOptions.asset = options.asset;
    }
    setOptions(newOptions);
  }

  useEffect(() => {
    loadCompanies();

    if (currentCompany.id && !currentFacility.id) {
      loadFacilities();
    }
    if (currentFacility.id && !currentAsset.id) {
      loadAssets();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (hiearchy.company && !hiearchy.facility) {
      loadFacilities();
    }
    if (hiearchy.facility && !hiearchy.asset) {
      loadAssets();
    }
    // eslint-disable-next-line
  }, [hiearchy]);

  function handleChange(name: string, value: any) {
    const newHiearchy = { [name]: value };

    if (name === "facility") {
      newHiearchy.company = hiearchy.company;
    } else if (name === "asset") {
      newHiearchy.company = hiearchy.company;
      newHiearchy.facility = hiearchy.facility;
    } else if (name === "position") {
      newHiearchy.company = hiearchy.company;
      newHiearchy.facility = hiearchy.facility;
      newHiearchy.asset = hiearchy.asset;
    }

    setHiearchy(newHiearchy);
  }

  const disabled = !(hiearchy.company && hiearchy.facility && hiearchy.asset);

  const dispatch = useAppDispatch();

  function handleConfirm() {
    dispatch(
      setOccurrenceRegister({
        show: true,
        id: null,
        assetId: hiearchy.asset.value,
        occurrenceCustomFields: hiearchy.asset.occurrenceCustomFields,
        contract: hiearchy?.facility?.contract,
        facilityId: hiearchy.facility.value,
        companyId: hiearchy?.asset?.companyId,
      }),
    );
    handleClose();
    handleTrackEvent({
      event: "Botão Add Ocorrência",
    });
  }

  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      disabled={disabled}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
    >
      <OccurrenceHiearchyModalContainer>
        <span>{t("ocurrenceRegisterModal.create")}</span>
        <div>
          {!isFacilitiesPage && !isAssetsPage && !isAssetPage && (
            <Select
              label={t("commonText.company") || ""}
              placeholder={t("commonText.company") || ""}
              options={options.company || []}
              handleChange={(e) => handleChange("company", e)}
              isSearchable
              value={hiearchy.company || null}
              isLoading={companiesApi.processing}
            />
          )}
          {
            // !isCommonClient &&
            !isAssetsPage && !isAssetPage && (
              <div data-test="new-occurrence-select-test-facilitie">
              <Select
                label={t("commonText.facility") || ""}
                placeholder={t("commonText.facility") || ""}
                options={options.facility || []}
                handleChange={(e) => handleChange("facility", e)}
                isSearchable
                value={hiearchy.facility || null}
                isLoading={facilitiesApi.processing}
              />
              </div>
            )
          }
          <div data-test="new-ocurrence-select-test">
          <Select
            label={t("commonText.asset") || ""}
            placeholder={t("commonText.asset") || ""}
            options={options.asset || []}
            handleChange={(e) => handleChange("asset", e)}
            isSearchable
            value={hiearchy.asset || null}
            disabled={isAssetPage}
            isLoading={assetsApi.processing}
          />
          </div>
        </div>
      </OccurrenceHiearchyModalContainer>
    </Modal>
  );
}

export default OccurrenceHiearchyModal;
