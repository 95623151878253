// @ts-ignore
import { Menu, MenuButton } from "@szhsin/react-menu";
import styled from "styled-components";
import { ConfigurationPagesContent } from "../../../components/frame/styles";
import darkTheme from "../../../styles/themes/dark";
import lightTheme from "../../../styles/themes/light";

export const UsersContainer = styled(ConfigurationPagesContent)`
  width: 100%;
  @media screen and (max-width: 767px) {
    opacity: 1;
    width: 100vw;
    margin-left: 0;
    height: calc(100vh - 7.5rem);
    overflow: hidden;
  }
`;

export const Container = styled.div`
  padding: 2% 4% 4%;
  height: calc(100% - 2px);
  background-color: ${(props) => props.theme.colors.background};

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 4px;
    height: 100%;
    position: relative;
    overflow-y: auto;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  & > h2 {
    ${(props) => props.theme.fonts.primary.H1Semibold};
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
  @media screen and (max-width: 767px) {
    margin: 0.6rem 0 1rem 4rem;

    & > h2 {
      font-size: 1.25rem;
    }
  }
`;

export const Content = styled.div`
  /* position: fixed; */
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.passwordBackground};
  background-color: ${(props) => props.theme.colors.divBackground};
  border-radius: 6px;
  height: 100%;

  div.spinner-border {
    color: ${(props) => props.theme.colors.background};
  }

  & > div:nth-child(1) {
    display: flex;
    gap: 5px;
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    height: calc(100% - 8rem);
  }
`;

export const TableWrapper = styled.div`
  margin-top: 10px;
  height: 100%;
  overflow: auto;
  padding: 0 16px;


  .table {
    background: transparent!important;
    --bs-table-bg: transparent!important;
  }

  & > div {
    min-height: 240px;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => lightTheme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => lightTheme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => lightTheme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => lightTheme.colors.iconsSecondary};
  }

  & > div {
    thead > tr > th {
      background-color: transparent !important;
    }
    tbody > tr > td {
      ${lightTheme.fonts.secondary.H1Regular};
      color: ${(props) => props.theme.colors.iconsSecondary};
      background-color: transparent !important;
    }

    tbody > tr > td:last-child {
      width: 60px;
    }
  }

  @media screen and (max-width: 767px) {
    &::-webkit-scrollbar {
      display: none;
    }
    overflow-y: auto;
    scrollbar-width: none;
    margin-top: 24px;
    width: 100%;
    height: 50vh;
    padding: 0;
    margin-bottom: 1rem;
    & > div {
      table {
        tbody > tr > td:last-child {
          padding-left: 0.2rem;
        }
        tbody > tr > td {
          padding: 0.48rem;
          overflow-x: hidden;
        }
        thead {
          display: none;
        }
      }
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 767px) {
    height: 40px;
    padding-top: 10px;

    & > div {
      margin-bottom: 1rem;
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    gap: 0 !important;
  }
`;

export const FilterButtons = styled.div<{ isCompany?: boolean }>`
  display: flex;
  gap: 8px;

  @media screen and (max-width: 767px) {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    & > div {
      margin: 0;
    }

    & > div:first-child {
      & > div > div {
        margin-top: 0.5rem;
        width: 24rem;
      }
    }

    & > div:nth-child(2) {
      width: ${(props) => (props.isCompany ? "24rem" : "11.5rem")};
      & > div {
        width: 100%;
      }
    }
    & > div:nth-child(3) {
      & > div > div {
        width: 11.5rem;
      }
    }

    #react-select-4-listbox {
      right: 0;
    }

    & > div:last-child {
    }
  }
`;

export const ColumnHeader = styled.div`
  margin: 8px 0 4px 0;
  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H5Bold};

  & > span {
    display: flex;
    align-items: center;
  }
`;

type UserStatusProps = {
  active?: boolean;
};

export const UserStatus = styled.div<UserStatusProps>`
  background-color: ${(props) => (props.active ? "#368B82" : "#BBB")};
  border-radius: 28px;
  padding: 5px 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    ${(props) => props.theme.fonts.secondary.H1Bold};
    text-transform: uppercase;
    color: #fff;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 6rem;
  }
`;

export const TableActionsRow = styled.div`
  display: flex;
  button {
    background-color: transparent;
    border: none;
    border-radius: 100%;
    &:hover {
      background-color: ${(props) =>
        props.theme.colors.cardHighlight}!important;
    }
    svg {
      font-size: 20px;
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }

  ul {
    left: -128px !important;
  }
`;

export const ButtonMenu = styled(MenuButton)`
  width: 28px;
  height: 28px;
`;

export const MenuContext = styled(Menu)<{ isLast?: boolean }>`
  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    min-width: 160px;
    min-height: 60px;
    width: auto;
    border-radius: 16px;
    height: auto;
    background-color: ${(props) => props.theme.colors.headerBackground};
    z-index: 100;
    padding: 16px 0px;

    a,
    li {
      text-align: left;
      border: none;
      background-color: inherit;
      padding-left: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      text-decoration: none !important;
      cursor: pointer;
      ${darkTheme.fonts.primary.H5Bold};
      color: ${(props) => props.theme.colors.iconsPrimary};

      &:hover {
        background-color: ${(props) => props.theme.colors.background};
        color: ${(props) => props.theme.colors.iconsPrimary};
      }
    }
  }

  @media screen and (max-width: 767px) {
    ul {
      border: 1px solid ${(props) => props.theme.colors.divBackgroundAlpha};
    }
  }
`;

export const ButtonNewUser = styled.button`
  height: 40px;
  width: 160px;
  background-color: ${(props) => props.theme.colors.IconsTools};
  border-radius: 8px;
  ${(props) => props.theme.fonts.primary.H4Bold};
  color: ${(props) => props.theme.colors.cardHighlight};
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  text-decoration: none;

  &:hover {
    border: none;
    scale: 0.99;
    transition: 0.3s;
    opacity: 0.85;
    color: ${(props) => props.theme.colors.cardHighlight};
    border: 1px solid ${(props) => props.theme.colors.white};
  }

  @media screen and (max-width: 767px) {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 5.2rem;
    margin-top: 0.15rem;
  }
`;

export const UserSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid ${(props) => props.theme.colors.iconsPrimary};
  align-items: flex-end;
  margin-top: 20px;
  margin-right: 20px;

  input {
    height: 24px;
    border: none;
    outline: none;
    ${lightTheme.fonts.primary.H4Regular};
    width: 100%;
    padding-left: 10px;
    background-color: transparent;
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  svg {
    font-size: 25px;
    width: 30px;
    height: 24px;
    margin-bottom: 4px;
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  &:focus {
    border-bottom: 1px solid ${lightTheme.colors.ibbxOrange};
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const SearchMobileContainer = styled.div`
  display: flex;

  div {
    position: absolute;
    right: 1.5rem;
    top: -4rem;
    width: 19.4rem;
    height: 2.8rem;
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;

    right: 2rem;
    background-color: ${(props) => props.theme.colors.cardHighlight};
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3);

    input {
      background-color: transparent;
      height: 70%;
      width: 82%;
      border: none;
      caret-color: ${(props) => props.theme.colors.iconsPrimary};
      color: ${(props) => props.theme.colors.iconsPrimary};
      border-bottom: 1px solid ${(props) => props.theme.colors.IconsTools};
      &:focus {
        outline: none;
      }
    }
  }

  button {
    z-index: 2;
    position: fixed;
    right: 1rem;
    top: 5.35rem;
    background-color: transparent;
    border: none;
    width: 100%;
    width: 3rem;
    height: 3rem;
    border-radius: 100rem;
    color: ${(props) => props.theme.colors.cardHighlight};

    background-color: ${(props) => props.theme.colors.IconsTools};

    -webkit-box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3);
  }
`;

export const ContainerMenuEidtUser = styled.div<{
  top?: number;
  left?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: ${(props) => props.top + "px"};
  left: ${(props) => props.left && props.left + 120 + "px"};
`;

export const MenuEditUser = styled.div``;

export const TriangleUser = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 6px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  margin-right: 18px;

  div {
    &:hover {
      background-color: ${(props) => props.theme.colors.cardBackground};
    }
  }
`;
