import styled from "styled-components";

// export const FilteredButton = styled.button`
//   border: none;
//   background-color: transparent;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 38px;
//   height: 37px;
//   grid-area: 1 / 1 / 2 / 2;
//   svg {
//     color: ${(props) => props.theme.colors.iconsSecondary};
//   }

//   &:hover {
//     background-color: ${(props) => props.theme.colors.cardHighlight};
//     border-radius: 50%;
//   }
//   @media screen and (max-width: 767px) {
//     svg {
//       width: 1.8rem;
//       height: auto;
//     }
//   }
// `;

export const FilteredButton = styled.button<{ disable?: boolean }>`
  ${(props) => ({ ...props.theme.fonts.primary.H4Regular })};
  padding: 2px 12px;
  /* background-color: ${(props) =>
    props.disable
      ? props.theme.colors.iconsPrimary
      : props.theme.colors.iconsSecondaryAlpha};
  color: ${(props) =>
    props.disable
      ? props.theme.colors.background
      : props.theme.colors.iconsSecondary}; */
  background-color: ${(props) => props.theme.colors.iconsPrimary};
  color: ${(props) => props.theme.colors.background};
  border-radius: 50px;
  margin: 0 4px 4px 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  /* cursor: ${(props) =>
    props.disable ? "pointer" : "context-menu"} !important; */

  display: ${(props) => (props.disable ? "visible" : "none")};

  & > span {
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    width: 18px;
    height: auto;
  }

  &:hover {
    svg {
      transform: scale(1.2);
      transition: all 0.2s ease-in-out;
    }
  }

  @media only screen and (max-width: 1536px) {
    ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};
    font-size: 10px;
  }

  @media screen and (max-width: 767px) {
    margin: 0.2rem 0.1rem;
    background-color: transparent;
    color: ${(props) => props.theme.colors.iconsPrimary};
    text-decoration: underline;
    padding: 0 0.1rem !important;
    max-width: fit-content;
    min-width: 2.2rem;
    flex: 1;

    span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const FilteredItemContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 4px;
  padding-top: 4px;

  button {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0;

    button {
      padding: 4px 6px;
      gap: 0px;

      svg {
        width: 1rem;
        height: auto;
      }
    }
  }
`;

export const ClearAllFilters = styled.button`
  text-transform: uppercase;
  background-color: transparent !important;
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  padding: 0 !important;
  border-radius: 0 !important;
  margin: 0 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: ${(props) => props.theme.colors.iconsPrimary};
    font-weight: 700;
    font-size: 12px;
  }

  &:hover {
    span {
      text-decoration: underline;
      transition: all 0.2s ease-in-out;
    }
  }

  @media screen and (max-width: 767px) {
    background-color: ${(props) => props.theme.colors.iconsPrimary} !important;
    color: ${(props) => props.theme.colors.background};
    border-radius: 50px !important;
    padding: 0.1rem !important;
  }
`;
