import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  height: 2rem;
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.background};
  background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  ${(props) => props.theme.fonts.primary.H4Regular}
  position: absolute;
  top: 1.75rem;
  left: 2rem;
`;
