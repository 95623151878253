import styled from "styled-components";

export const AnalisysToolsContainer = styled.div<{ absolute?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  z-index: 11;

  .tooltip-comments {
    width: 2%;
  }

  color: ${(props) => props.theme.colors.iconsSecondary};

  div {
    display: flex;
    width: 56px;
    height: 35px;
    cursor: pointer;
  }
  & > div[data-id="tooltip"] {
    width: auto;
  }
`;

export const AnalisysTool = styled.div<{
  selected?: boolean;
  disabled?: boolean;
  isCustomSvg?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  background: ${(props) => props.theme.colors.cardBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  /* margin: 22px 8px 0 0; */
  margin-right: 8px;
  &:first-child {
    /* margin-left: 24px; */
  }

  &:hover {
    border: ${(props) =>
      !props.disabled && "1px solid " + props.theme.colors.divBackground};

    svg {
      stroke: ${(props) => !props.disabled && props.theme.colors.ibbxOrange};
      fill: ${(props) => !props.disabled && props.theme.colors.ibbxOrange};
      color: ${(props) => !props.disabled && props.theme.colors.ibbxOrange};
      path {
        fill: ${({ theme, isCustomSvg, disabled }) =>
          !disabled && isCustomSvg && theme.colors.ibbxOrange};
      }
    }
  }

  svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
    font-size: 22px;
    stroke: ${(props) => props.selected && props.theme.colors.ibbxOrange};
    fill: ${(props) => props.selected && props.theme.colors.ibbxOrange};
    color: ${(props) => props.selected && props.theme.colors.ibbxOrange};

    path {
      fill: ${({ theme, isCustomSvg, selected }) =>
        selected && isCustomSvg && theme.colors.ibbxOrange};
    }
  }
`;

export const AlertContent = styled.div`
  width: fit-content !important;
  height: fit-content !important;
  display: flex;
  position: absolute;
  padding: 0.25rem;
  border-radius: 6px;
  top: 3.5rem;
  background: ${(props) => props.theme.colors.cardBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  ${(props) => props.theme.fonts.primary.H5Regular}
`;
