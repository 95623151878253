import { differenceInDays } from "date-fns";
import { formatDate } from "../utils/utilsFunctions";
import { useTranslation } from "react-i18next";
import useRetinaTranslation from "./useRetinaTranslation";

export const useOccurrenceHistory = (isClient?: boolean) => {
  const { t } = useTranslation();
  const { translateDiagnostic, buildEvidenceLabel } = useRetinaTranslation();
  const status: any = {
    "1": t("OccurrenceHistory.Normal"),
    "2": t("OccurrenceHistory.Alerta"),
    "3": t("OccurrenceHistory.Alerta"),
    "4": t("OccurrenceHistory.Análise"),
    "5": t("OccurrenceHistory.Risco"),
    "6": t("OccurrenceHistory.Ocorrência Agravada"),
    "7": t("OccurrenceHistory.Crítico"),
  };

  function getHistory(occurrence: any) {
    let history: any[] = occurrence?.history ?? [];

    history = history.reduce((acc: any[], item) => {
      switch (item.type) {
        case "EDIT_OCCURRENCE": {
          for (let change of item.changes ?? []) {
            if (+change?.old === 6 && isClient) {
              continue;
            }
            const result: any = {
              userName: item.userName,
              date: item.date,
              action: getAction({ ...change }, item.date, occurrence),
            };
            acc.push(result);
          }

          return acc;
        }
        case "CREATE_OCCURRENCE":
          acc.push({
            ...item,
            action: t("OccurrenceHistory.Criou a Ocorrência"),
          });
          return acc;
        case "DELETE_OCCURRENCE":
          acc.push({
            ...item,
            action: t("OccurrenceHistory.Excluiu a Ocorrência"),
          });
          return acc;
        case "RESTORE_OCCURRENCE":
          acc.push({
            ...item,
            action: t("OccurrenceHistory.Restaurou a Ocorrência"),
          });
          return acc;
        case "CLOSE_OCCURRENCE":
          acc.push({
            ...item,
            action: t("OccurrenceHistory.Fechou a Ocorrência"),
          });
          return acc;
        case "REOPEN_OCCURRENCE":
          acc.push({
            ...item,
            action: t("OccurrenceHistory.Reabriu a Ocorrência"),
          });
          return acc;
        case "ADD_DIAGNOSTIC": {
          const action = `${t(
            "OccurrenceHistory.Adicionou o Diagnóstico",
          )} ${translateDiagnostic(item.diagnostic)}`;
          acc.push({ ...item, action });
          return acc;
        }
        case "DIAGNOSE": {
          const action = `${t(
            "OccurrenceHistory.Adicionou o Diagnóstico",
          )} ${translateDiagnostic(item.diagnostic)}`;
          acc.push({ ...item, action });
          return acc;
        }
        case "DIAGNOSTIC_CHANGE": {
          const changedDiagnostic = t(
            "OccurrenceHistory.Alterou o Diagnósitco",
          );
          const oldOccurrence = translateDiagnostic(item.oldDiagnostic);
          const to = t("OccurrenceHistory.para");
          const diagnostic = translateDiagnostic(item.diagnostic);
          const action = `${changedDiagnostic} ${oldOccurrence} ${to} ${diagnostic}`;
          acc.push({ ...item, action });
          return acc;
        }
        case "DELETE_DIAGNOSTIC": {
          acc.push({
            ...item,
            action: `${t("OccurrenceHistory.Removeu o Diagnóstico")} ${
              item.diagnostic
            }`,
          });
          return acc;
        }

        case "CREATE_EVIDENCE": {
          acc.push({
            ...item,
            action: `${t(
              "OccurrenceHistory.CREATE_EVIDENCE",
            )} ${buildEvidenceLabel(item.label, item.metadata)}`,
          });
          return acc;
        }

        case "DELETE_EVIDENCE": {
          if (!isClient) {
            acc.push({
              ...item,
              action: `${t(
                "OccurrenceHistory.DELETE_EVIDENCE",
              )} ${buildEvidenceLabel(item.label, item.metadata)}`,
            });
          }
          return acc;
        }
        case "RESTORE_EVIDENCE": {
          acc.push({
            ...item,
            action: `${t(
              "OccurrenceHistory.RESTORE_EVIDENCE",
            )} ${buildEvidenceLabel(item.label, item.metadata)}`,
          });
          return acc;
        }
        default: {
          acc.push({
            ...item,
            action: `${t(
              "OccurrenceHistory.Alteração não identificada Tipo:",
            )} ${item.type}`,
          });
          return acc;
        }
      }
    }, []);

    return history;
  }

  function getActionTime(
    dueDate: string | null,
    createdAt: number,
    occurrence: any,
  ) {
    if (!dueDate) {
      return t("OccurrenceHistory.Não Atribuído");
    }

    if (!occurrence || !occurrence?.createdAt) {
      return "";
    }

    const difDateEpoch =
      new Date(dueDate).getTime() - new Date(occurrence?.createdAt)?.getTime();
    if (difDateEpoch < 0) {
      return t("OccurrenceHistory.Imediato");
    }

    var label = t("OccurrenceHistory.dia");
    var value = 0;

    if (difDateEpoch < 86400000) {
      label = t("OccurrenceHistory.hora");
      value = Math.floor(difDateEpoch / 3600000);
    } else {
      // value = Math.floor(difDateEpoch / 86400000);
      value = differenceInDays(
        new Date(dueDate),
        new Date(occurrence?.createdAt),
      );
    }

    if (value !== 1) {
      label += "s";
    }

    return `${value} ${label}`;
  }

  function getAction(
    { field, old, new: newVal }: any,
    date: number,
    occurrence?: any,
  ) {
    switch (field) {
      case "sysStatusId":
        return `${t("OccurrenceHistory.Alterou Status de")} ${status[old]} ${t(
          "OccurrenceHistory.para",
        )} ${status[newVal]}`;
      case "dueDate":
        if (!old && newVal) {
          return `${t(
            "OccurrenceHistory.Adicionou o tempo para ação de",
          )} ${getActionTime(newVal, date, occurrence)}`;
        } else if (old && !newVal) {
          return `${t("OccurrenceHistory.Removeu o tempo para ação")}`;
        } else {
          const prefix = t("OccurrenceHistory.Alterou o tempo para ação de");
          const oldValue = getActionTime(old, date, occurrence);
          return `${prefix} ${oldValue} ${t(
            "OccurrenceHistory.para",
          )} ${getActionTime(newVal, date, occurrence)}`;
        }
      case "comments":
        if (!old && newVal) {
          return t("OccurrenceHistory.Comentou a ocorrência");
        } else if (old && !newVal) {
          return t("OccurrenceHistory.Removeu o Comentário");
        } else {
          return t("OccurrenceHistory.Alterou o comentário");
        }
      case "createdAt":
        const previous = formatDate(old, { type: "FULL" });
        const next = formatDate(newVal, { type: "FULL" });
        return `${t(
          "OccurrenceHistory.Alterou a data de criação de",
        )} ${previous} ${t("OccurrenceHistory.para")} ${next}`;
      case "category":
        return `${t("OccurrenceHistory.category")} ${t(
          `OccurrenceHistory.Category.${old}`,
        )}  ${t("OccurrenceHistory.para")} ${t(
          `OccurrenceHistory.Category.${newVal}`,
        )}`;

      default:
        return `${t("OccurrenceHistory.Alterou o campo")} ${field} ${t(
          "OccurrenceHistory.de",
        )} ${old} ${t("OccurrenceHistory.para")} ${newVal}`;
    }
  }

  return {
    getHistory,
    getActionTime,
    getAction,
  };
};
