import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.divBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 100%;

  padding: 10px;

  @media screen and (max-width: 767px) {
    width: fit-content;
    background: transparent;
    border: none;
    width: 100%;
    padding: 5px;
  }

  @media only screen and (max-width: 1240px) {
    height: 65%;
  }

  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
    width: 99%;
  }
`;

export const ContainerList = styled.div`
  overflow-y: auto;
  margin: 12px -5px 0 -5px;
  padding-top: 10px;

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  & > div > div {
    padding-top: 10px;
    height: calc(100% - 48px);
    overflow-y: auto;
    column-gap: 12px;
    row-gap: 16px;
    padding: 2px;
    &::-webkit-scrollbar {
      width: 0 !important;
      background: transparent;
    }
    scrollbar-width: none;
  }

  @media screen and (max-width: 767px) {
    &::-webkit-scrollbar {
      width: 0 !important;
      background: transparent;
      scrollbar-width: none;
    }

    & > div > div {
      & > div:last-child {
        margin-bottom: 16rem;
      }
    }
  }
`;

export const ContainerFilter = styled.div<{ disabled?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  div {
    display: flex;
    gap: 8px;
    align-items: center;
    z-index: 1;

    div {
      background-color: ${(props) => props.theme.colors.cardBackground};
      width: auto;
      padding: 4px 8px;
      height: 30px;
      border-radius: 5px;
      color: ${(props) => props.theme.colors.iconsSecondary};
      border: 1px solid ${(props) => props.theme.colors.cardHighlight};
      ${(props) => props.theme.fonts.primary.H4Bold};
      svg {
        margin-left: -2px;
      }
    }
    button {
      display: flex;
      align-items: center;
      background-color: ${(props) => props.theme.colors.cardBackground};
      width: auto;
      padding: 4px 8px;
      height: 30px;
      border-radius: 5px;
      color: ${(props) => props.theme.colors.iconsSecondary};
      border: 1px solid ${(props) => props.theme.colors.cardHighlight};
      ${(props) => props.theme.fonts.primary.H4Bold};

      svg {
        margin: 0 4px 0 -2px;
      }

      :hover {
        border: ${(props) =>
          !props.disabled && `1px solid ${props.theme.colors.iconsSecondary}`};
        opacity: ${(props) => !props.disabled && 0.8};
        box-shadow: ${(props) =>
          !props.disabled &&
          `2px 1px 1px${props.theme.colors.headerBackground}`};
      }

      :active {
        opacity: 1;
        transform: scale(0.98);
      }
    }

    button:nth-child(5) {
      margin-left: 6px;
      border-radius: 50px;
      background-color: ${(props) => props.theme.colors.iconsSecondary};
      color: ${(props) => props.theme.colors.headerBackground};
      padding: 4px 8px;
      height: 26px;
      display: flex;
      align-items: center;

      svg {
        margin-left: 8px;
      }

      :hover {
        border: 1px solid ${(props) => props.theme.colors.iconsPrimary};
        opacity: 0.9;
      }
      :active {
        transform: scale(0.98);
      }
    }
  }
  @media screen and (max-width: 767px) {
    overflow-x: hidden;
    margin: 0 6px;
    min-height: 2rem;

    svg {
      margin-right: 0 !important;
    }
    div {
      overflow-x: hidden;
      div {
        & > span:nth-child(3) {
          display: none;
        }
      }
      button {
        height: 2rem;
        span:not(:first-child) {
          display: none;
        }
      }

      button:nth-child(5) {
        width: 39%;

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
`;

export const apearEmpty = keyframes`
from{
  opacity: 0;
}
to{
  opacity: 1;
}
`;

export const EmptyPositions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};

  margin-top: 20px;

  gap: 20px;

  animation: ${apearEmpty} 0.8s ease-in normal;
`;
