import { ReactNode } from "react";
import { Container } from "./styles";

interface ISelectType {
  children?: ReactNode;
}

export function SelectType({ children }: ISelectType) {
  return <Container>{children}</Container>;
}
