import { selectCustomColorsModel } from "../../../components/FormSelect/types";
import lightTheme from "../../../styles/themes/light"

export const profiles = [
  {
    value: "EXECUTIVO_MASTER",
    label: "Executivo Master",
  },
  { value:"MESA_MASTER", label: "Mesa Master" },
  { value: "MESA_ANALISTA", label: "Mesa Analista" },
  { value: "CLIENTE_MASTER", label: "Cliente Master" },
  { value: "CLIENTE_COMUM", label: "Cliente Comum" },
]


export const customSelectProfileColors: selectCustomColorsModel = {
  control: {
    color: lightTheme.colors.iconsPrimary,
    background: "#f3f6f8",
    border: lightTheme.colors.cardHighlight,
    borderHovered: lightTheme.colors.background
  },
  option: {
    color: lightTheme.colors.textPrimary,
    backgroundHovered: lightTheme.colors.cardHighlight,
  },
  placeholder: {
    color: lightTheme.colors.textPrimary,
  },
  menuList: {
    background: lightTheme.colors.cardBackground,
  },
  clearButton: {
    color: lightTheme.colors.iconsPrimary,
    background: lightTheme.colors.cardHighlight,
    backgroundHovered: lightTheme.colors.background
  },
  label: {
    color: lightTheme.colors.textPrimary,
  }
}

export const customSelectCompaniesColors: selectCustomColorsModel = {
  ...customSelectProfileColors,
  control: {
    ...customSelectProfileColors.control,
    background: "#f3f6f8"
  }
}

