import { useAppDispatch } from "../store/hooks";
import { appSetCurrentAsset } from "../store/slices/appSlice";
import useApi from "./useApi";

/**
   @describe Hook para operações com o asset
*/
export const useAsset = () => {
  const dispatch = useAppDispatch();

  const { request, processing } = useApi({ path: "" });

  /**
   @describe Função para retornar um único ativo e atualizar o estado global do asset na aplicação.
   @param  assetId : number
*/
  const getAsset = ({ assetId }: { assetId: number }) => {
    request({ method: "get", pathParameters: `assets/${assetId}` }).then(
      (response) => {
        dispatch(appSetCurrentAsset({ ...response }));
      },
    );
  };

  return { getAsset, processing };
};
