import {
  AddNewChartButton,
  AddNewChartHeader,
  Container,
  EditingMode,
  EmptyChartData,
  FirstSection,
  SecondSection,
} from "./styles";
import { useEffect, useMemo, useRef, useState } from "react";
import { DrawerComponent } from "../DrawerComponent";
import { ButtonCancel } from "../Modal/styles";
import { Form, Formik, FormikProps } from "formik";
import { FormikTextInput } from "../textInput";
import FormSelect from "../FormSelect";
import { ImageUploadCard } from "../ImageUploadCard";
import { useAppSelector } from "../../store/hooks";
import { selectOptionsSelect } from "../../store/slices/selectOptionsSlice";
import { useTranslation } from "react-i18next";
import { success } from "../Toast";
import useApi from "../../hooks/useApi";
import ModalCompound from "../ModalCompound";
import { ConfirmModalV2 } from "../../modals/ConfirmModalV2";
import { MdAdd, MdAddChart, MdDelete, MdEdit } from "react-icons/md";
import useFilters from "../../hooks/useFilters";
import { NamePlusTooltip } from "../NamePlusTooltip";
import { useSelector, useDispatch } from "react-redux";
import { setShowNewDeviceDrawer } from "../../store/slices/addNewDeviceSlice";
import { RootState } from "../../store/store";
import { isMobileOnly } from "react-device-detect";
import { SensorCardMobile } from "./SensorCardMobile";
import SensorChartModal from "./SensorChartModal";
import { useSegment } from "../../hooks/useSegmentTrackEvent ";

interface IAddNewSensor {
  initialSensorData?: any;
  openEditMode?: any;
  loadDeviceList: () => void;
  resetSensorData: () => void;
}

const CHART_TYPE_LABEL: any = {
  GLOBAL: "Temporal",
  DYNAMIC: "Static",
};

export function AddNewSensor({
  initialSensorData,
  openEditMode,
  loadDeviceList,
  resetSensorData,
}: IAddNewSensor) {
  const { request } = useApi({ path: "/monitoring-solutions" });
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<any>>(null);
  const { sensorTypes } = useAppSelector(selectOptionsSelect);
  const { loadMonitoringOptions } = useFilters({});
  const { handleTrackEvent } = useSegment();

  // const [showAddNewSensor, setShowAddNewSensor] = useState<boolean>(false);
  const [image, setImage] = useState<any>({ src: "", changed: false });
  const [chartsData, setChartsData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [editingChart, setEditingChart] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [chartIndex, setChartIndex] = useState<number>();

  const deviceId: number | null = useMemo(() => {
    return initialSensorData?.monitoringSolutionId || null;
  }, [initialSensorData]);

  const showNewDeviceDrawer = useSelector(
    (state: RootState) => state.addSensorDevice.showNewDeviceDrawer,
  );

  const dispatch = useDispatch();

  const translatedLabels: any = {
    "Vibração e temperatura": t("positionsTab.positionTypeFilters.vibration"),
    "Energia Elétrica": t("positionsTab.positionTypeFilters.eletric"),
    Pressão: t("positionsTab.positionTypeFilters.pressure"),
    Temperatura: t("positionsTab.positionTypeFilters.temperature"),
    Iluminação: t("positionsTab.positionTypeFilters.lumina"),
    Vazão: t("positionsTab.positionTypeFilters.flow"),
    Nível: t("positionsTab.positionTypeFilters.level"),
    "Mod Bus": t("positionsTab.positionTypeFilters.modBus"),
    "Analógico Modular": t("positionsTab.positionTypeFilters.modularAnalog"),
  };

  function translateOptions(options: any[]) {
    const translatedOptions = options.map((item: any) => {
      return {
        ...item,
        label: translatedLabels[`${item.label}`],
      };
    });

    return translatedOptions;
  }

  const translatedOptions = translateOptions(sensorTypes.options);

  const handleOpemModal = () => {
    setOpenModal(true);
    if (editingChart) {
      setEditingChart(null);
    }
  };

  const handleUploadFile = function (src: string) {
    setImage({ src, changed: true });
  };

  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const clearButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleSelectChange = (option: any) => {
    formRef?.current?.setFieldValue("sysSensorTypeId", option.value || null);
  };

  function reset() {
    resetSensorData();
    formRef.current?.setValues({
      name: "",
      sysSensorTypeId: 1,
      imageUrl: "",
      charts: [],
    });
  }

  const handleEditChart = (chart: any) => {
    setEditingChart(chart);
    setOpenModal(true);
  };

  const handleDeleteChart = (index: number) => {
    const updatedChartsData = [...chartsData];
    updatedChartsData.splice(index, 1);

    setChartsData(updatedChartsData);
    setChartIndex(index);
    setOpenConfirmModal(false);
  };

  const handleConfirmNewSensor = () => {
    handleTrackEvent({
      event: "Salvar Novo Dispositivo",
    });
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };

  const handleSubmit = (values: any) => {
    const sensorData = {
      name: values?.name,
      sysSensorTypeId: values?.sysSensorTypeId,
      imageUrl: image.src || "",
      charts: chartsData,
      image: image.changed ? image.src : undefined,
    };

    const pathParameters = deviceId || undefined;
    request({
      method: deviceId ? "put" : "post",
      pathParameters,
      body: sensorData,
    }).then(() => {
      dispatch(setShowNewDeviceDrawer(false));
      loadDeviceList();
      success("Sensor criado com sucesso");
      reset();
    });
  };

  useEffect(() => {
    if (!sensorTypes.options.length) {
      loadMonitoringOptions();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialSensorData) {
      formRef.current?.setValues({
        name: initialSensorData.name,
        sysSensorTypeId: initialSensorData.sysSensorTypeId || 1,
        charts: initialSensorData?.charts || [],
        image: image || "",
      });

      setImage({ src: initialSensorData.imageUrl, changed: false });
      setChartsData(initialSensorData.charts);
    }
    // eslint-disable-next-line
  }, [initialSensorData]);

  const handleOpenCloseDrawer = () => {
    reset();
    dispatch(setShowNewDeviceDrawer(!showNewDeviceDrawer));
    handleTrackEvent({
      event: "Botão ADD Dispositivo",
    });
  };

  const renderActions = (chart: any, index: number) => {
    return (
      <>
        <EditingMode
          type="button"
          onClick={() => handleEditChart(chart)}
          title={`${t("newSensor.Editar Gráfico")}`}
        >
          <MdEdit />
        </EditingMode>
        <EditingMode
          type="button"
          onClick={() => {
            setChartIndex(index);
            setOpenConfirmModal(true);
          }}
          title={`${t("newSensor.Excluir Gráfico")}`}
        >
          <MdDelete />
        </EditingMode>
      </>
    );
  };

  return (
    <>
      <NamePlusTooltip tooltip={t("newSensor.Adicionar Dispositivo")} position={"right"}>
        <AddNewChartButton onClick={handleOpenCloseDrawer}>
          <MdAdd />
        </AddNewChartButton>
      </NamePlusTooltip>

      <DrawerComponent
        direction="right"
        handleOpen={handleOpenCloseDrawer}
        handleClose={handleOpenCloseDrawer}
        isOpen={showNewDeviceDrawer}
        size={isMobileOnly ? 400 : 750}
      >
        <Formik initialValues={{}} onSubmit={handleSubmit} innerRef={formRef}>
          {({ errors, resetForm }: any) => (
            <Container>
              <header>
                <strong>{t("newSensor.Cadastrar Novo Sensor")}</strong>
              </header>

              <Form>
                <FirstSection>
                  <div>
                    <FormikTextInput
                      name="name"
                      label={`${t("newSensor.Nome do Sensor")}`}
                      placeholder={`${t("newSensor.Nome do Sensor")}`}
                      type="text"
                      error={errors.name}
                    />
                    <FormSelect
                      options={translatedOptions}
                      placeholder={`${t(
                        "newSensor.Selecione o tipo de sensor",
                      )}`}
                      label={`${t("newSensor.Selecione o tipo de sensor")}`}
                      name="sysSensorTypeId"
                      handleChange={handleSelectChange}
                      error={errors.sysSensorTypeId}
                    />
                  </div>
                  <div>
                    <ImageUploadCard
                      name="file"
                      image={image.src}
                      label={`${t("newSensor.Carregar imagem")}`}
                      changeImage={handleUploadFile}
                    />
                  </div>
                </FirstSection>
                <AddNewChartHeader onClick={handleOpemModal} typeof="button">
                  <AddNewChartButton onClick={handleOpemModal} type="button">
                    <MdAddChart />
                  </AddNewChartButton>
                  {t("newSensor.Adicionar gráficos")}
                </AddNewChartHeader>
                {isMobileOnly ? (
                  <SecondSection>
                    {chartsData.map((chart, chartIndex) => (
                      <SensorCardMobile
                        key={chartIndex}
                        id={chart.name}
                        title={chart.label}
                        horizontal={
                          chart.type === "GLOBAL" ? "" : chart?.metricUnits?.x
                        }
                        vertical={chart?.metricUnits?.y}
                        series={chart.series.join(", ")}
                        actions={renderActions(chart, chartIndex)}
                        type={CHART_TYPE_LABEL[chart.type]}
                      />
                    ))}
                  </SecondSection>
                ) : (
                  <SecondSection>
                    <table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>{t("newSensor.Título")}</th>
                          <th>{t("newSensor.Tipo")}</th>
                          <th>Horizontal</th>
                          <th>Vertical</th>
                          <th>{t("newSensor.Séries")}</th>
                          <th>{t("newSensor.Ações")}</th>
                        </tr>
                      </thead>

                      <tbody>
                        {chartsData.map((chart, chartIndex) => (
                          <tr key={chartIndex}>
                            <td>
                              <span>{chart.name}</span>
                            </td>
                            <td>
                              <span>{chart.label}</span>
                            </td>
                            <td>
                              <span>{CHART_TYPE_LABEL[chart.type]}</span>
                            </td>
                            <td>
                              <span>
                                {chart.type === "GLOBAL"
                                  ? ""
                                  : chart?.metricUnits?.x}
                              </span>
                            </td>
                            <td>
                              <span>{chart?.metricUnits?.y}</span>
                            </td>
                            <td>
                              <span>{chart.series.join(", ")}</span>
                            </td>
                            <td>{renderActions(chart, chartIndex)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {chartsData.length === 0 ? (
                      <EmptyChartData>
                        <span>{t("newSensor.Nenhum gráfico adicionado.")}</span>
                        <span onClick={handleOpemModal}>
                          {t("newSensor.Clique aqui para adicionar.")}
                        </span>
                      </EmptyChartData>
                    ) : (
                      <table></table>
                    )}
                  </SecondSection>
                )}

                <footer>
                  <ButtonCancel onClick={handleOpenCloseDrawer} type="button">
                    {t("newSensor.Cancelar")}
                  </ButtonCancel>
                  <button
                    type="submit"
                    ref={submitButtonRef}
                    className="hidenButton"
                  />
                  <button
                    onClick={() => resetForm()}
                    className="hidenButton"
                    type="button"
                    ref={clearButtonRef}
                  />
                  <ModalCompound.Save handleConfirm={handleConfirmNewSensor} />
                </footer>
              </Form>
            </Container>
          )}
        </Formik>
        {openModal && (
          <SensorChartModal
            show={openModal}
            handleClose={() => {
              setEditingChart(null);
              setOpenModal(false);
            }}
            editingChart={editingChart}
            chartsData={chartsData}
            setChartsData={setChartsData}
          />
        )}
        {openConfirmModal && (
          <ConfirmModalV2
            show={openConfirmModal}
            handleClose={() => setOpenConfirmModal(false)}
            text={`${t("commonText.wantToDelete")}`}
            handleConfirm={handleDeleteChart}
            indexNumber={chartIndex}
          />
        )}
      </DrawerComponent>
    </>
  );
}
