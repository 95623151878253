import React, { useEffect } from "react";

function useOnClickOutSide(
  ref: React.RefObject<HTMLDivElement>,
  handler: (event: any) => void,
  secondRef?:
    | React.RefObject<HTMLButtonElement>
    | React.RefObject<HTMLDivElement>,
) {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      if (
        secondRef &&
        (!secondRef.current || secondRef.current.contains(event.target))
      ) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, secondRef]);
}

export default useOnClickOutSide;
