import styled from "styled-components";

export const NavBarLogoContainer = styled.div`
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 60px;

  @media screen and (max-width: 767px) {
    width: 90px;
  };

  @media screen and (min-width: 767px) and (max-width: 1279px) {
    height: 38px;
  }
`;

export const NavBarLogoContent = styled.div`
  background-color: ${(props) => props.theme.colors.ibbxOrange};
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 60px;

  @media screen and (min-width: 767px) and (max-width: 1279px) {
    height: 38px;
  }
`;

export const CompanyLogo = styled.div`
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 60px;

  @media screen and (min-width: 767px) and (max-width: 1279px) {
    height: 38px;
  }
`;
