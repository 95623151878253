import styled from "styled-components";

export const DateContainer = styled.div<{ selected?: boolean }>`

  position: relative;

  svg {
    color: ${(props) =>
      props.selected
        ? props.theme.colors.ibbxOrange
        : props.theme.colors.iconsSecondary};
  }

  & button {
    border: none;
    background: transparent;
  }
  & button:hover {
    cursor: pointer;
    border-radius: 100%;
    svg {
      color: ${(props) => props.theme.colors.ibbxOrange};
    }
  }
`;

export const DatePickerContainer = styled.div`


  input {
    width: 295px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.headerBackground};
    font-size: 14px;
    font-weight: 800;
    padding: 12px;
    padding-right: 15px;
     border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha}; 
     border: none;
    :focus {
      border: none !important;
    }
  }

  & > div {
    border-radius: 8px;
    border: none;    

    & > div {
      & > button {
        ::after {
          background: ${(props) => props.theme.colors.iconsSecondary}!important;
          color: ${(props) => props.theme.colors.cardBackground}!important;
        }
      }
    }
  }

  .react-datepicker {
    border: 2px solid ${(props) => props.theme.colors.cardHighlight};
    
    & > div {
      & > div:first-child {
        div {
          color: ${(props) => props.theme.colors.iconsSecondary}!important;
        }
        background: ${(props) => props.theme.colors.cardBackground}!important;
      }
      & > div:last-child {
        margin: 0;
        padding: 0.4rem;
        background: ${(props) => props.theme.colors.lightBackground}!important;
      }
    }

    div:last-child {
      border: none;
      div:first-child {
      }
      div:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 5;
    
  }

  .react-datepicker__input-container {
    
    input::placeholder {
      color: ${(props) => props.theme.colors.iconsSecondary};
      font-size: 12px;
      display: flex;
      align-items: center;
    }
}
`;

export const CalendarIcon = styled.div`
  position: absolute;
  right: 0.8rem;
  top: 0.5rem;
  display: flex;
  
`;
