import { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdAdd, MdDelete } from "react-icons/md";
import { Column } from "react-table";
import { Loading } from "../../../../components/Loading";
import ReactTable from "../../../../components/ReactTable";
import RoundIconButton from "../../../../components/RoundIconButton";
import { success } from "../../../../components/Toast";
import useApi from "../../../../hooks/useApi";
import ConfirmDeleteModal from "../../../../modals/ConfirmDeleteForm";
import {
  DeleteModalState,
  closed,
} from "../../../../modals/ConfirmDeleteForm/types";
import { useAppSelector } from "../../../../store/hooks";
import { PROFILES, userSelect } from "../../../../store/slices/userSlice";
import { isMobile } from "../../../../utils/mediaQuery";
import { ButtonMenu, MenuContext } from "../../Users/styles";
import {
  ButtonAddUser,
  ColumnHeader,
  TableActionsRowRepresentatives,
  TableWrapper,
  UsersContainer,
  UserStatus,
} from "./styles";
import { UsersRepresentativesProps } from "./types";
import { useTranslation } from "react-i18next";

const UsersRepresentatives = ({
  onClickNew,
  representative,
}: UsersRepresentativesProps) => {
  const user = useAppSelector(userSelect);

  const [users, setUsers] = useState([]);
  const [, setPagination] = useState([]);

  const [deleteModal, setDeleteModal] = useState<DeleteModalState>(closed);

  const { request } = useApi({ path: `/users` });
  const {
    request: requestUsersRepresentative,
    processing: processingUsersRepresentative,
  } = useApi({ path: `/representatives` });

  const { t } = useTranslation();

  const handleDeleteUser = (id: number | string, comments?: string) => {
    const body = { comments };
    request({ method: "del", pathParameters: id, body }).then(() => {
      success(`${t("Representatives.Users.deletedUser")}`);
      setDeleteModal({ show: false });
      // setPagination(null);
      getRecords();
    });
  };

  const handleUserStatus = (userId: string) => {
    request({ method: "put", pathParameters: `${userId}/change-status` }).then(
      () => {
        success(`${t("Representatives.Users.statusUser")}`);
        getRecords();
      },
    );
  };

  const handlePasswordReset = (userId: string) => {
    request({ method: "put", pathParameters: `${userId}/reset-password` }).then(
      () => {
        success(`${t("Representatives.Users.deletedUser")} #Ibbx1234 `);
      },
    );
  };

  const handleEditUser = (userId: string) => {
    onClickNew(userId);
  };

  const getRecords = () => {
    requestUsersRepresentative({
      method: "get",
      pathParameters: `${representative?.id}/users`,
    }).then((response) => {
      setUsers(response.data);
      setPagination(response.pagination);
    });
  };

  useEffect(() => {
    getRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [representative?.id]);

  return (
    <UsersContainer>
      <ButtonAddUser onClick={onClickNew} data-translate={t("Representatives.Users.newUser")}>
        {" "}
        <MdAdd size={32} />{" "}
      </ButtonAddUser>
      {processingUsersRepresentative ? (
        <Loading />
      ) : (
        <TableWrapper>
          <ReactTable
            responsive
            columns={
              [
                {
                  id: "email",
                  accessor: "email",
                  Header: () => (
                    <ColumnHeader>
                      <span>E-mail</span>
                    </ColumnHeader>
                  ),
                },
                {
                  id: "name",
                  accessor: "name",
                  Header: () => (
                    <ColumnHeader>
                      <span>{t("Representatives.Users.name")}</span>
                    </ColumnHeader>
                  ),
                },
                {
                  id: "status",
                  accessor: "status",
                  Header: () => (
                    <ColumnHeader>
                      <span>Status</span>
                    </ColumnHeader>
                  ),
                  Cell: ({ value }: any) => {
                    return (
                      <UserStatus active={value === "ACTIVE"}>
                        <span>{value === "ACTIVE" ? `${t("Representatives.Users.active")}` : `${t("Representatives.Users.inactive")}`}</span>
                      </UserStatus>
                    );
                  },
                },
                {
                  id: "action",
                  accessor: "action",
                  Header: () => null,
                  Cell: ({ row }: any) => {
                    const { original } = row;
                    const { id, status } = original;
                    if ([PROFILES.CLIENTE_COMUM].includes(user.profile)) {
                      return <></>;
                    }
                    return (
                      <>
                        <TableActionsRowRepresentatives>
                          <MenuContext
                            menuButton={
                              <ButtonMenu>
                                <BsThreeDotsVertical />
                              </ButtonMenu>
                            }
                          >
                            <li onClick={() => handleUserStatus(id)}>
                              {status === "ACTIVE"
                                ? t("Representatives.Users.deactivate")
                                : t("Representatives.Users.activate")} {' '}
                              {t("Representatives.Users.user")}
                            </li>
                            <li onClick={() => handleEditUser(id)}>Editar</li>
                            <li onClick={() => handlePasswordReset(id)}>
                              {t("Representatives.Users.resetPass")}
                            </li>
                            {isMobile() && (
                              <li
                                onClick={() =>
                                  setDeleteModal({ show: true, id })
                                }
                              >
                                {t("Representatives.Users.deleteUser")}
                              </li>
                            )}
                          </MenuContext>

                          {!isMobile() && (
                            <RoundIconButton
                              handleClick={() =>
                                setDeleteModal({ show: true, id })
                              }
                            >
                              <MdDelete />
                            </RoundIconButton>
                          )}
                        </TableActionsRowRepresentatives>
                      </>
                    );
                  },
                },
              ] as Column<object>[]
            }
            data={users as object[]}
            renderEmptyResult={<span>{t("Representatives.Users.noUser")}</span>}
          />
          {deleteModal.show && (
            <ConfirmDeleteModal
              id={deleteModal.id}
              show={deleteModal.show}
              handleClose={() => setDeleteModal(closed)}
              context={`${t("Representatives.Users.deleteThisUser")}`}
              handleConfirm={handleDeleteUser}
              required
            />
          )}
        </TableWrapper>
      )}
    </UsersContainer>
  );
};

export default UsersRepresentatives;
