import { useLocation, useParams } from "react-router-dom";
import { Container } from "./styles";
import { getUrlPattern } from "../../../utils/getUrlPattern";

import { ROUTES_PATH } from "../../../routes/RoutesPath";
import { TbAppsFilled } from "react-icons/tb";
import { useAppSelector } from "../../../store/hooks";
import { appSelect } from "../../../store/slices/appSlice";

export function HubGoto({ navbarColor }: { navbarColor?: string }) {
  const params = useParams();
  const location = useLocation();
  const urlPattern = getUrlPattern(location, params);

  const { currentCompany } = useAppSelector(appSelect);

  const isCurrentPage = urlPattern.includes(ROUTES_PATH.HUB);

  const handleClick = (e: any) => {
    if (isCurrentPage) {
      e.preventDefault();
    }
  };

  const { companyId } = params;

  function getUrl() {
    if (currentCompany.id) {
      return `/hub?companyId=${companyId}`;
    }
    return companyId ? `/hub?companyId=${companyId}` : "/hub";
  }

  return (
    <Container
      navbarcolor={navbarColor}
      onClick={handleClick}
      to={getUrl()}
      selected={isCurrentPage}
    >
      <TbAppsFilled />
    </Container>
  );
}
