import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface DashboardState {
  selectedIcons: string[];
}

const initialState: DashboardState = {
  selectedIcons: [],
};

const navbarIconsSlice = createSlice({
  name: "navbarIcons",
  initialState,
  reducers: {
    setSelectedIcons: (state, action: PayloadAction<string[]>) => {
      state.selectedIcons = action.payload;
    },
  },
});

export const { setSelectedIcons } = navbarIconsSlice.actions;

export const navbarIconsSelect = (state: RootState) => state.navbarIcons;

export default navbarIconsSlice.reducer;
