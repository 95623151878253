import styled from "styled-components";

export const Container = styled.div<{ error?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;

  strong {
    text-align: center;
    ${(props) => ({ ...props.theme.fonts.primary.H2Bold })};
  }
`;
