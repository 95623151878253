import styled from "styled-components";

export const CloudConnectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  gap: 8px;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;
