import { useEffect, useMemo, useState } from "react";
import { RiWhatsappFill } from "react-icons/ri";
import Frame from "../../../components/frame";
import { SidebarConfiguration } from "../../../components/SidebarConfiguration";

import {
  NotificationsArea,
  FrameBodyNotifications,
  Header,
  NotificationContainer,
  LoadingContainer,
  NotificationsItemContainer,
  NotificationsCollapseContainer,
  Blocked,
} from "./styles";
import { MdBlock, MdDevices, MdEmail } from "react-icons/md";

import { success, warning } from "../../../components/Toast";
import { useAppSelector } from "../../../store/hooks";
import { PROFILES, userSelect } from "../../../store/slices/userSlice";
import useApi from "../../../hooks/useApi";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../components/Loading";
import { useFetch } from "../../../hooks/useFetch";
import { FaBan, FaMobileAlt } from "react-icons/fa";
import { NotificationItem } from "./NotificationItem";
import { NotificationButton } from "./NotificationButton";

import {
  ADMIN_NOTIFICATIONS,
  ALL_NOTIFICATIONS,
  CLIENT_NOTIFICATIONS,
  NOTIFICATIONS_SETTINGS,
  REPRESENTATIVE_NOTIFICATIONS,
} from "./notificationsTypes";
import { manageNotifications } from "./manageNotifications";
import { isMobileOnly } from "react-device-detect";
import { NamePlusTooltip } from "../../../components/NamePlusTooltip";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FrameBodyMain } from "../../../components/frame/styles";
import { get } from "lodash";

export const Notifications = () => {
  const user = useAppSelector(userSelect);

  const { t } = useTranslation();

  const notificationsName: any = {
    NEW_RELEASE: t("Atualizações do Retina"),
    BOLTS_OFFLINE: t("Bolt Offline"),
    ASSET_STATUS_UPDATED: t("Alteração de Status do Ativo"),
    OCCURRENCE_CREATED: t("Ocorrência Criada"),
    OCCURRENCE_TO_EXPIRE: t("Ocorrência próxima do vencimento"),
    OCCURRENCE_UPDATED_DIAGNOSTIC: isMobileOnly
      ? t("Atualiz. de diag. de Ocorrência")
      : t("Atualização de diagnóstico de Ocorrência"),
    SENSORS_RECALL: t("Recall de sensores"),
    SENSORS_OFFLINE: t("Sensores Offline"),
    SENSORS_LOW_BATTERY: t("Sensores com bateria baixa"),
    TO_EXPIRE_INTEGRATED_PEOPLE: isMobileOnly
      ? t("Integ. de pessoas próx. do vencimento")
      : t("Integração de pessoas próximas do vencimento"),
    RISK: t("dictionary.status.Risk"),
    ALERT: t("dictionary.status.Alert"),
    CRITICAL: t("dictionary.status.Critical"),
    NORMAL: t("dictionary.status.Normal"),
  };

  const { request, processing } = useApi({
    path: `/users/${user.id}/notification-preferences`,
  });

  const {
    data: userDetails,
    refresh: refreshUserDetails,
    processing: processingUserDetails,
  } = useFetch({
    path: `/users/${user.id}`,
  });

  const userNotHavePhoneNumber = !userDetails?.phone;

  const {
    notifications: notificationsTypeByUserProfile,
    groupsWithAllNotifications,
    allNotificationsChecked,
  } = useMemo(() => {
    let notifications: any = ALL_NOTIFICATIONS;
    if (
      [PROFILES.CLIENTE_COMUM, PROFILES.CLIENTE_MASTER].includes(user.profile)
    ) {
      notifications = CLIENT_NOTIFICATIONS;
    }

    if (
      [
        PROFILES.EXECUTIVO_MASTER,
        PROFILES.MESA_MASTER,
        PROFILES.MESA_ANALISTA,
      ].includes(user.profile)
    ) {
      notifications = ADMIN_NOTIFICATIONS;
    }

    if ([PROFILES.REPRESENTANTE].includes(user.profile)) {
      notifications = REPRESENTATIVE_NOTIFICATIONS;
    }

    if (!userDetails) {
      return {
        notifications,
        groupsWithAllNotifications: {},
        allNotificationsChecked: false,
      };
    }

    notifications = notifications.map(
      ([title, notificationTypes]: [any, any]) => {
        const hasConditions = Object.keys(notificationTypes).some(
          (types) => typeof notificationTypes[types] === "object",
        );

        if (hasConditions) {
          // debugger;
          const newValues = Object.keys(notificationTypes).reduce(
            (acc, condition) => {
              const notificationByCondition = Object.keys(
                notificationTypes[condition],
              ).reduce((acc2, type) => {
                return {
                  ...acc2,
                  [type]:
                    userDetails.notificationsPreference[title] &&
                      userDetails.notificationsPreference[title][condition] &&
                      userDetails.notificationsPreference[title][condition][type]
                      ? true
                      : false,
                };
              }, {});
              return {
                ...acc,
                [condition]: notificationByCondition,
              };
            },
            {},
          );

          return [title, newValues];
        }

        const newValue = Object.keys(notificationTypes)
          .map((key) => ({
            [key]:
              userDetails.notificationsPreference[title] &&
                userDetails.notificationsPreference[title][key]
                ? true
                : false,
          }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {});

        return [title, newValue];
      },
    );

    const groupsWithAllNotifications = notifications.reduce(
      (acc: any, notification: any) => {
        const [title, values] = notification;

        const hasConditions = Object.keys(values).some(
          (types) => typeof values[types] === "object",
        );

        if (hasConditions) {
          const groupConditionsChecked = Object.keys(values).reduce(
            (acc, condition) => {
              return {
                ...acc,
                [condition]: Object.keys(values[condition]).every(
                  (type) => values[condition][type] === true,
                ),
              };
            },
            {},
          );
          return { ...acc, [title]: groupConditionsChecked };
        }

        const allGroupNotificationsChecked = Object.keys(values).every(
          (type) => values[type] === true,
        );

        return { ...acc, [title]: allGroupNotificationsChecked };
      },
      {},
    );

    const allNotificationsChecked = Object.keys(
      groupsWithAllNotifications,
    ).every((key) => {
      const hasConditions = typeof groupsWithAllNotifications[key] === "object";

      if (hasConditions) {
        return Object.keys(groupsWithAllNotifications[key]).every(
          (condition) => groupsWithAllNotifications[key][condition] === true,
        );
      }

      return groupsWithAllNotifications[key] === true;
    });

    return {
      notifications,
      groupsWithAllNotifications,
      allNotificationsChecked,
    };
  }, [user.profile, userDetails]);

  const handleToggleNotificationType = ({
    title,
    type,
    condition,
    value,
  }: {
    title: string;
    type: string;
    condition?: string;
    value: boolean;
  }) => {
    if (type === "whatsApp" && userNotHavePhoneNumber) {
      return warning(
        `${t(
          "configuration.notifications.Para habilitar essa opção, primeiro cadastre um número de celular válido . Vá em Meu Perfil e cadastre.",
        )}`,
      );
    }

    const newParameters = manageNotifications({
      notifications: notificationsTypeByUserProfile,
      title,
      type,
      value,
      condition,
    });

    Object.keys(newParameters).forEach((key) => {
      if (newParameters[key]?.hasOwnProperty("blocked")) {
        delete newParameters[key].blocked;
      }
    });

    request({
      method: "put",
      body: newParameters,
    }).then(() => {
      success(`${t("configuration.notifications.updatedSuccessMessage")}`);
      refreshUserDetails();
    });
  };

  const handleToggleGroupNotifications = ({
    title,
    value,
    condition,
  }: {
    title: string;
    condition: string;
    value: boolean;
  }) => {
    const [_, types] = notificationsTypeByUserProfile.find(
      ([notificationTitle]: [string]) => title === notificationTitle,
    );

    const notificationHasWhatsapp = Object.keys(types).includes("whatsApp");
    const notificatioWithConditionHasWhatsapp = Object.values(types).some(
      (conditionTypes: any) => Object.keys(conditionTypes).includes("whatsApp"),
    );

    const notificagtionConditions =
      condition === "ASSET_STATUS_UPDATED"
        ? notificatioWithConditionHasWhatsapp
        : notificationHasWhatsapp;

    if (notificagtionConditions && userNotHavePhoneNumber) {
      return warning(
        `${t(
          "configuration.notifications.Para habilitar essa opção, primeiro cadastre um número de celular válido . Vá em Meu Perfil e cadastre.",
        )}`,
      );
    }

    const newParameters = manageNotifications({
      notifications: notificationsTypeByUserProfile,
      title,
      type: "ALL",
      value,
    });

    Object.keys(newParameters).forEach((key) => {
      if (newParameters[key]?.hasOwnProperty("blocked")) {
        delete newParameters[key].blocked;
      }
    });

    request({
      method: "put",
      body: newParameters,
    }).then(() => {
      success(`${t("configuration.notifications.updatedSuccessMessage")}`);
      refreshUserDetails();
    });
  };

  const handleToggleGroupNotificationsWithCondition = ({
    title,
    condition,
    value,
  }: {
    title: string;
    condition: string;
    value: boolean;
  }) => {
    const [_, types] = notificationsTypeByUserProfile.find(
      ([notificationTitle]: [string]) => title === notificationTitle,
    );

    const notificationHasWhatsapp = Object.values(types).some(
      (conditionTypes: any) => Object.keys(conditionTypes).includes("whatsApp"),
    );

    if (notificationHasWhatsapp && userNotHavePhoneNumber) {
      return warning(
        `${t(
          "configuration.notifications.Para habilitar essa opção, primeiro cadastre um número de celular válido . Vá em Meu Perfil e cadastre.",
        )}`,
      );
    }

    const newParameters = manageNotifications({
      notifications: notificationsTypeByUserProfile,
      title,
      type: "ALL",
      condition,
      value,
    });

    request({
      method: "put",
      body: newParameters,
    }).then(() => {
      success(`${t("configuration.notifications.updatedSuccessMessage")}`);
      refreshUserDetails();
    });
  };

  const handleToggleAllNotifications = () => {
    if (userNotHavePhoneNumber) {
      return warning(
        `${t(
          "configuration.notifications.Para habilitar essa opção, primeiro cadastre um número de celular válido . Vá em Meu Perfil e cadastre.",
        )}`,
      );
    }

    const newParameters = manageNotifications({
      notifications: notificationsTypeByUserProfile,
      title: "ALL",
      value: !allNotificationsChecked,
    });

    Object.keys(newParameters).forEach((key) => {
      if (newParameters[key]?.hasOwnProperty("blocked")) {
        delete newParameters[key].blocked;
      }
    });

    request({
      method: "put",
      body: newParameters,
    }).then(() => {
      success(`${t("configuration.notifications.updatedSuccessMessage")}`);
      refreshUserDetails();
    });
  };

  const getNotificationsName = (name: string) => notificationsName[name || ""];

  function WhatsAppBlocked() {
    return (
      <Blocked>
        <MdBlock className="block" />
        <RiWhatsappFill className="whatsApp" />
      </Blocked>
    );
  }

  const getNotificationsIcon = (type: string) => {
    switch (type) {
      case "email":
        return <MdEmail size={24} />;
      case "push":
        return <MdDevices size={24} />;
      case "whatsApp":
        return <RiWhatsappFill size={24} />;
      case "blocked":
        return <WhatsAppBlocked />;
      default:
        return null;
    }
  };

  const whatsAppBlockedHint = t(
    "configuration.notifications.Este item não permite notificar por whatsApp",
  );

  const whatsappChecker = (conditioner: any[], type: any) => {
    const isWhatsAppActive = type === "whatsApp" && conditioner[type];

    if (isWhatsAppActive && userNotHavePhoneNumber) {
      conditioner[type] = false;
    }
  };

  const renderItemWithConditions = (title: string, value: any) => {
    return (
      <NotificationsCollapseContainer>
        {Object.entries(value).map(([subTitle, condition]: any) => (
          <NotificationItem
            key={subTitle}
            checkType="checkbox"
            hasntBackground
            description={getNotificationsName(subTitle)}
            formChecked={
              groupsWithAllNotifications[title] &&
              groupsWithAllNotifications[title][subTitle]
            }
            handleChange={() =>
              handleToggleGroupNotificationsWithCondition({
                title,
                condition: subTitle,
                value: !groupsWithAllNotifications[title][subTitle],
              })
            }
          >
            {Object.keys(condition).map((type: any) => {
              {
                whatsappChecker(condition, type);
              }
              const whatsappBlock = type === "blocked" in condition;
              const hasHintOnItemBlocked = whatsappBlock && whatsAppBlockedHint;

              return (
                <NotificationButton
                  disabled={whatsappBlock}
                  blocked={whatsappBlock}
                  hint={hasHintOnItemBlocked}
                  key={type}
                  icon={getNotificationsIcon(type)}
                  typeTitle={type}
                  active={condition[type]}
                  handleClick={() =>
                    handleToggleNotificationType({
                      title,
                      type,
                      condition: subTitle,
                      value: !condition[type],
                    })
                  }
                />
              );
            })}
          </NotificationItem>
        ))}
      </NotificationsCollapseContainer>
    );
  };

  const isProcessing = processing || processingUserDetails;

  return (
    <Frame hasMargin={false}>
      <FrameBodyMain>
        <SidebarConfiguration />
        <NotificationsArea visible backgroundWhite>
          <Header>
            <h2>{t("configuration.notifications.notifications")}</h2>
            <NamePlusTooltip
              position="bottom"
              tooltip={t("configuration.notifications.notificationsHint")}
            >
              <div>
                <IoMdInformationCircleOutline />
              </div>
            </NamePlusTooltip>
          </Header>

          <NotificationContainer>
            <NotificationItem
              checkType="switch"
              hasntBackground
              handleChange={handleToggleAllNotifications}
              formChecked={allNotificationsChecked}
              description={
                allNotificationsChecked
                  ? `${t("dictionary.actions.Desativar Todos")}`
                  : `${t("dictionary.actions.Ativar Todos")}`
              }
              subject={t("configuration.notifications.Assunto")}
              deliver={t("configuration.notifications.Envio")}
            >
              <NotificationButton
                icon={<MdDevices />}
                typeTitle="Push"
                active
                hasTitle
                disabled
              />
              <NotificationButton
                icon={<MdEmail />}
                typeTitle="Email"
                active
                hasTitle
                disabled
              />
              <NotificationButton
                icon={<RiWhatsappFill />}
                typeTitle="WhatsApp"
                active
                hasTitle
                disabled
              />
            </NotificationItem>

            <NotificationsItemContainer>
              {notificationsTypeByUserProfile.map(([title, types]: any) => {
                const hasConditions = Object.keys(types).some(
                  (item) => typeof types[item] === "object",
                );
                const allGroupNotificationsChecked =
                  hasConditions && groupsWithAllNotifications[title]
                    ? Object.keys(groupsWithAllNotifications[title]).every(
                      (type) =>
                        groupsWithAllNotifications[title][type] === true,
                    )
                    : groupsWithAllNotifications[title];

                const whatsappBlock = get(NOTIFICATIONS_SETTINGS, title)?.blocked === false;

                const hasHintOnItemBlocked =
                  whatsappBlock && whatsAppBlockedHint;

                return (
                  <NotificationItem
                    renderCollapse={
                      hasConditions && renderItemWithConditions(title, types)
                    }
                    checkType="switch"
                    description={getNotificationsName(title)}
                    key={title}
                    formChecked={allGroupNotificationsChecked}
                    handleChange={() =>
                      handleToggleGroupNotifications({
                        title,
                        condition: title,
                        value: !allGroupNotificationsChecked,
                      })
                    }
                  >
                    {Object.keys(types).map((type: any) => {
                      {
                        whatsappChecker(types, type);
                      }
                      return (
                        <NotificationButton
                          key={type}
                          icon={getNotificationsIcon(type)}
                          typeTitle={type}
                          active={types[type]}
                          handleClick={() =>
                            handleToggleNotificationType({
                              title,
                              type,
                              value: !types[type],
                            })
                          }
                        />
                      );
                    })}
                    {
                      whatsappBlock && (
                        <NotificationButton
                          key={'blocked'}
                          icon={getNotificationsIcon('blocked')}
                          typeTitle={types}
                          active={types['blocked']}
                          blocked={whatsappBlock}
                          hint={hasHintOnItemBlocked}
                          disabled={whatsappBlock}
                          handleClick={() =>
                            handleToggleNotificationType({
                              title,
                              type: 'blocked',
                              value: !types['blocked'],
                            })
                          }
                        />
                      )
                    }
                  </NotificationItem>
                );
              })}
            </NotificationsItemContainer>
          </NotificationContainer>
        </NotificationsArea>
      </FrameBodyMain>
    </Frame>
  );
};
