import { useMemo } from "react";
import CountUp from "react-countup";
import {
  Alerts,
  Box,
  Container,
  DataAlert,
  DataCritical,
  DataNormal,
  DataRisk,
  OwnerBadgeField,
} from "./styles";
import { SeparatorCardDashboard } from "../CardMonitoredPoints/styles";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { dashboardSelect } from "../../store/slices/dashboardSlice";
import useDashboard from "../../hooks/useDashboard";
import { PROFILES, userSelect } from "../../store/slices/userSlice";
import useAnalysisOwner from "../../hooks/useAnalysisOwner";
import { OwnerBadge } from "../OwnerBadge";

function CardOccurrencesStatus({
  parentReady,
}: {
  parentReady?: boolean;
  id?: string;
}) {
  const { t } = useTranslation();
  const dashboard = useAppSelector(dashboardSelect);
  const user = useAppSelector(userSelect);

  // const [switchTotal, setSwitchTotal] = useState(false);

  const { loadOccurrencesStatus } = useDashboard();
  const { filterValue } = useAnalysisOwner({ triggerOnChange });

  function triggerOnChange() {
    loadOccurrencesStatus(filterValue);
  }

  const statusOccurrences = useMemo(() => {
    return (
      dashboard.occurrencesStatus?.data || {
        total: 0,
        alert: 0,
        risk: 0,
        critical: 0,
      }
    );

    // eslint-disable-next-line
  }, [dashboard.occurrencesStatus]);

  const userAdmin = [
    PROFILES.EXECUTIVO_MASTER,
    PROFILES.MESA_MASTER,
    PROFILES.REPRESENTANTE,
  ].includes(user.profile);

  // const handleSwitchTotal = () => {
  //   loadOccurrencesStatus(filterValue);
  //   setSwitchTotal(!switchTotal);
  // };

  return (
    <Container>
      <h3>
        {t("cards.occurrencesStatus.title")}
        {userAdmin && filterValue && (
          <OwnerBadgeField>
            <OwnerBadge
              badgeColor={filterValue}
              badgeDescription={filterValue}
            />
          </OwnerBadgeField>
        )}
      </h3>
      <Alerts>
        <Box>
          <DataNormal>
            <label>{t("cards.occurrencesStatus.total")}</label>
            <span>
              <CountUp
                start={statusOccurrences.total || 0}
                end={statusOccurrences.total}
                duration={0.8}
                separator={"."}
              />
            </span>
            <hr />
          </DataNormal>
          <SeparatorCardDashboard></SeparatorCardDashboard>

          <DataAlert>
            <label>{t("cards.occurrencesStatus.warning")}</label>
            <span>
              <CountUp
                start={statusOccurrences.alert || 0}
                end={statusOccurrences.alert}
                duration={0.8}
                separator={"."}
              />
            </span>
            <hr />
          </DataAlert>

          <SeparatorCardDashboard></SeparatorCardDashboard>
          <DataRisk>
            <label>{t("cards.occurrencesStatus.risk")}</label>
            <span>
              <CountUp
                start={statusOccurrences.risk || 0}
                end={statusOccurrences.risk}
                duration={0.8}
                separator={"."}
              />
            </span>
            <hr />
          </DataRisk>

          <SeparatorCardDashboard></SeparatorCardDashboard>
          <DataCritical>
            <label>{t("cards.occurrencesStatus.Crítico")}</label>
            <span>
              <CountUp
                start={statusOccurrences.critical || 0}
                end={statusOccurrences.critical}
                duration={0.8}
                separator={"."}
              />
            </span>
            <hr />
          </DataCritical>
        </Box>
      </Alerts>
    </Container>
  );
}

export default CardOccurrencesStatus;
