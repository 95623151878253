import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 1rem;

  & > div {
    display: flex;
    gap: 0.5rem;
  }
`;
