import { FaLeaf } from "react-icons/fa";
import { useLocation, useParams } from "react-router-dom";
import { ROUTES_PATH } from "../../routes/RoutesPath";
import { getUrlPattern } from "../../utils/getUrlPattern";
import { Container } from "./styles";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";
import { useSegment } from "../../hooks/useSegmentTrackEvent ";

export const EsgPanelButtonGoTo = ({
  navbarColor,
}: {
  navbarColor?: string;
}) => {
  const params = useParams();
  const location = useLocation();
  const urlPattern = getUrlPattern(location, params);

  const { handleTrackEvent } = useSegment();

  const { currentCompany } = useAppSelector(appSelect);

  const isCurrentEsgPage = urlPattern.includes(ROUTES_PATH.ESG);

  const handleClick = (e: any) => {
    if (isCurrentEsgPage) {
      e.preventDefault();
    }

    handleTrackEvent({
      event: "Painel ESG",
    });
  };

  const { companyId } = params;

  function getUrl() {
    if (currentCompany.id) {
      return `/esgPanel?companyId=${currentCompany.id}`;
    }

    return companyId ? `/esgPanel?companyId=${companyId}` : "/esgPanel";
  }

  return (
    <Container onClick={handleClick} to={getUrl()} selected={isCurrentEsgPage}>
      <FaLeaf size={20} />
    </Container>
  );
};
