import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background-color: ${(props) => props.theme.colors.headerBackground};
  border-radius: 6px;
  gap: 0.25rem !important;
  label {
    ${(props) => props.theme.fonts.secondary.H2Regular};
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
  span {
    ${(props) => props.theme.fonts.primary.H4Regular};
  }

  & > div:first-child {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-between;
    gap: 0.25rem;

    & > div {
      display: flex;
      gap: 0.25rem;
    }
  }

  & > div:last-child {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

export const ChartContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props) => props.theme.colors.BackgroundShadow};
  border-radius: 6px;
`;

export const ChartActionsContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  background-color: ${(props) => props.theme.colors.BackgroundShadow};
  border-radius: 6px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    button {
      border-radius: 6px;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;
