import { useEffect, useState, useRef, useCallback } from "react";

import useApi from "../../../hooks/useApi";
import { Loading } from "../../../components/Loading";

import {
  ButtonChangeMode,
  CirclePulse,
  CirclePulse2,
  Container,
  Content,
  InfoButtonContainer,
  InfoContainer,
  ModetypeContainer,
  OperationDescription,
  OperationModeContainer,
  Title,
  Triangle,
} from "./styles";
import { MdOutlineSync } from "react-icons/md";
import { TbCloudDataConnection } from "react-icons/tb";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useParams } from "react-router";
import { useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import { EmptyBolts } from "../SidebarBolts/styles";
import { ImFileEmpty } from "react-icons/im";
import { isMobile } from "react-device-detect";
import { appSelect } from "../../../store/slices/appSlice";

interface IProps {
  boltAdded: any[];
  facilityId: any;
  refresh: () => void;
}

export const CardBoltOperationMode = ({
  boltAdded,
  facilityId,
  refresh,
}: IProps) => {
  const { isReadOnly } = useAppSelector(appSelect);
  const params = useParams();
  const { t } = useTranslation();

  const [showInfo, setShowInfo] = useState(false);

  const optionsRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const { request: requestChangeMode } = useApi({
    path: `/companies/${params.companyId}/facilities`,
  });

  const { request, processing } = useApi({
    path: `/gateways-operation-mode`,
  });

  const [operationMode, setOperationMode] = useState();
  const [remaningTime, setRemaningTime] = useState("");

  const fetchData = useCallback(
    ({ noProcessing }: { noProcessing: boolean }) => {
      const queryStringParameters = params;
      request({
        method: "get",
        noProcessing,
        skipToast: true,
        queryStringParameters,
      }).then((response) => {
        setOperationMode(response.operationMode);
        setRemaningTime(response.remainingMinutes);
      });
    },
    // eslint-disable-next-line
    [facilityId],
  );

  useEffect(() => {
    fetchData({ noProcessing: false });

    const interval = setInterval(
      () => fetchData({ noProcessing: true }),
      30 * 1000,
    );

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId]);

  const handleClickChangeOperationMode = () => {
    requestChangeMode({
      method: "put",
      pathParameters: `${facilityId}/bolts-operation-mode`,
      body: {
        operationMode:
          operationMode === "AUTOMATIC" ? "CONFIGURATION" : "AUTOMATIC",
      },
    }).then((response) => {
      setOperationMode(response.operationMode);
      refresh();
    });
    setRemaningTime("");
  };

  // click outside
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as HTMLElement) &&
        optionsRef.current &&
        !optionsRef.current.contains(event.target as HTMLElement)
      ) {
        setShowInfo(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  function renderContent() {
    return (
      <>
        {boltAdded.length === 0 ? (
          <EmptyBolts>
            <ImFileEmpty />
            <span>{t("sidebarBolts.emptyMessage")}</span>
          </EmptyBolts>
        ) : (
          <>
            <ModetypeContainer>
              {operationMode !== "AUTOMATIC" && (
                <OperationDescription>
                  <span>{remaningTime && `${remaningTime || ""} Min`}</span>
                </OperationDescription>
              )}
              {operationMode === "AUTOMATIC" ? (
                <>
                  <OperationModeContainer>
                    <span>{t("cardBoltsOperationMode.operation")}</span>
                    <CirclePulse />
                    <CirclePulse2 />
                    <TbCloudDataConnection size={60} />
                  </OperationModeContainer>
                  {operationMode !== "AUTOMATIC" && (
                    <div>
                      <span>{remaningTime && `${remaningTime || ""} Min`}</span>
                    </div>
                  )}
                </>
              ) : (
                <OperationModeContainer syncCard>
                  <span>{t("cardBoltsOperationMode.synchronization")}</span>
                  <MdOutlineSync size={60} />
                </OperationModeContainer>
              )}
            </ModetypeContainer>
            {!isReadOnly && facilityId && (
              <ButtonChangeMode onClick={handleClickChangeOperationMode}>
                {operationMode === "AUTOMATIC" ? (
                  <>
                    <MdOutlineSync size={62} />
                    <span>
                      {isMobile
                        ? "Trocar"
                        : t("cardBoltsOperationMode.changeToSynchronization")}
                    </span>
                  </>
                ) : (
                  <>
                    <TbCloudDataConnection size={62} />
                    <span>
                      {isMobile
                        ? "Trocar"
                        : t("cardBoltsOperationMode.changeToOperation")}
                    </span>
                  </>
                )}
              </ButtonChangeMode>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <Container>
      <Title>
        {isMobile ? (
          <span>Configuração</span>
        ) : (
          <span>{t("cardBoltsOperationMode.title")}</span>
        )}
        <InfoButtonContainer
          ref={optionsRef}
          onClick={() => setShowInfo(!showInfo)}
        >
          <IoMdInformationCircleOutline size={24} />
        </InfoButtonContainer>
      </Title>
      {showInfo ? (
        <>
          <Triangle />
          <InfoContainer ref={menuRef}>
            {isMobile && <span>{t("cardBoltsOperationMode.title")}</span>}
            <span>
              <strong>
                {t("cardBoltsOperationMode.gatewayOperationModeDetails.part1")}
              </strong>{" "}
              {t("cardBoltsOperationMode.gatewayOperationModeDetails.part2")}
            </span>

            <span>
              <strong>
                {t("cardBoltsOperationMode.gatewayOperationModeDetails.part3")}
              </strong>{" "}
              {t("cardBoltsOperationMode.gatewayOperationModeDetails.part4")}
            </span>
          </InfoContainer>
        </>
      ) : null}
      <Content>
        {processing ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading marginTop="0" />
          </div>
        ) : (
          renderContent()
        )}
      </Content>
    </Container>
  );
};
