import { useEffect, useState, useCallback, useRef } from "react";
import CountUp from "react-countup";

import useApi from "../../../hooks/useApi";
import { Loading } from "../../../components/Loading";

import {
  Container,
  Content,
  Data,
  InfoButtonContainer,
  InfoContainer,
  Title,
} from "./styles";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { isMobile, isMobileOnly } from "react-device-detect";

interface ICardGatewayConnectivity {}

export function CardGatewayConnectivity({}: ICardGatewayConnectivity) {
  const [showInfo, setShowInfo] = useState(false);
  const { t } = useTranslation();

  const optionsRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const { request, processing } = useApi({
    path: `/gateways-connectivity`,
  });

  const [gatewaysByMode, setGatewaysByMode] = useState({
    offline: 0,
    operation: 0,
    synchronization: 0,
    restarting: 0,
    updating: 0,
  });

  const params = useParams();

  const fetchData = useCallback(
    ({ noProcessing }: { noProcessing: boolean }) => {
      const queryStringParameters = { ...params };
      request({
        method: "get",
        queryStringParameters,
        noProcessing,
        skipToast: true,
      }).then((response) => {
        setGatewaysByMode(response);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    fetchData({ noProcessing: false });

    const interval = setInterval(
      () => fetchData({ noProcessing: true }),
      15 * 1000,
    );

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // click outside
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as HTMLElement) &&
        optionsRef.current &&
        !optionsRef.current.contains(event.target as HTMLElement)
      ) {
        setShowInfo(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <Container id="gatewayConnectivity">
      <Title>
        <span>{t("CardGatewayConnectivity.Gateway Connectivity")}</span>
        <InfoButtonContainer
          ref={optionsRef}
          onClick={() => setShowInfo(!showInfo)}
        >
          <IoMdInformationCircleOutline size={24} />
        </InfoButtonContainer>
      </Title>
      {showInfo ? (
        <>
          <InfoContainer ref={menuRef}>
            {isMobile && (
              <span>{t("CardGatewayConnectivity.Gateway Connectivity")}</span>
            )}

            <span>
              <strong>{t("CardGatewayConnectivity.info.title2")}</strong>{" "}
              {t("CardGatewayConnectivity.info.content2")}
            </span>

            <span>
              <strong>{t("CardGatewayConnectivity.info.title1")}</strong>{" "}
              {t("CardGatewayConnectivity.info.content1")}
            </span>

            <span>
              <strong>{t("CardGatewayConnectivity.info.title3")}</strong>{" "}
              {t("CardGatewayConnectivity.info.content3")}
            </span>

            <span>
              <strong>{t("CardGatewayConnectivity.info.title4")}</strong>{" "}
              {t("CardGatewayConnectivity.info.content4")}
            </span>
          </InfoContainer>
        </>
      ) : null}
      <Content>
        {processing && (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading marginTop="0" />
          </div>
        )}
        {!processing && (
          <>
            <Data>
              <span>
                <CountUp
                  start={gatewaysByMode.operation || 0}
                  end={gatewaysByMode.operation}
                  duration={0.8}
                  separator={"."}
                />
              </span>
              <label>
                {isMobileOnly
                  ? t("CardGatewayConnectivity.Auto")
                  : t("CardGatewayConnectivity.Automatic")}
              </label>
            </Data>
            <Data>
              <span>
                <CountUp
                  start={gatewaysByMode.synchronization || 0}
                  end={gatewaysByMode.synchronization}
                  duration={0.8}
                  separator={"."}
                />
              </span>
              <label>
                {isMobileOnly
                  ? t("CardGatewayConnectivity.Sync")
                  : t("CardGatewayConnectivity.Synchronization")}
              </label>
            </Data>
            {!isMobileOnly && (
              <Data>
                <span>
                  <CountUp
                    start={gatewaysByMode.updating || 0}
                    end={gatewaysByMode.updating}
                    duration={0.8}
                    separator={"."}
                  />
                </span>
                <label>{t("CardGatewayConnectivity.Update")}</label>
              </Data>
            )}
            <Data>
              <span>
                <CountUp
                  start={gatewaysByMode.offline || 0}
                  end={gatewaysByMode.offline}
                  duration={0.8}
                  separator={"."}
                />
              </span>
              <label>Offline</label>
            </Data>
          </>
        )}
      </Content>
    </Container>
  );
}
