import styled from "styled-components";
import { ButtonSave } from "../../../../components/Modal/styles";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > span {
    ${(props) => props.theme.fonts.primary.H4Regular};
    color: ${(props) => props.theme.colors.iconsPrimary};
    margin-bottom: 0.5rem;
  }

  @media ${({ theme }) => theme.mediaQuery.small} {
    & > span {
      margin-left: 1rem;
    }
  }
`;

export const ContentIcons = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  overflow: hidden auto;

  @media ${({ theme }) => theme.mediaQuery.small} {
    gap: 0.75rem;
    height: auto;
    flex: 1;
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    gap: 0.5rem;
  }
`;

export const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 1rem;
  flex-direction: column;
  padding: 2rem;
  width: 10rem;
  height: fit-content;
  background-color: ${(props) => props.theme.colors.iconsSecondary};
  border-radius: 8px;

  svg {
    width: 2rem;
    height: auto;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  width: 100%;
  padding: 1rem 0;
  float: right;

  @media ${({ theme }) => theme.mediaQuery.small} {
    width: 100%;
    justify-content: flex-end;

    & > div > button {
      width: fit-content;
      padding: 0 0.5rem;
    }
  }
`;

export const SaveButton = styled(ButtonSave)`
  width: 6rem;
  float: left;
`;

export const IconsContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  border: 1px solid transparent;
  padding: 0.5rem;

  cursor: pointer;
  width: fit-content;

  & > span {
    cursor: pointer;

    ${(props) => props.theme.fonts.primary.H4Regular}
  }

  & > svg {
    width: 1.5rem;
    height: auto;
  }

  :hover {
    background: ${(props) => props.theme.colors.cardUnderlineLightAlpha};
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    border-radius: 6px;
    transition: 0.3s;
  }
  :active {
    scale: 0.95;
  }

  @media ${({ theme }) => theme.mediaQuery.small} {
    & > svg {
      width: 1.25rem;
    }
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    & > svg {
      width: 1.25rem;
    }
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    & > svg {
      width: 1.25rem;
    }
    & > span {
      font-size: 0.75rem;
    }
  }
`;

export const Checkbox = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid ${(props) => props.theme.colors.lightOrange};
  border-radius: 3px;
  outline: none;
  background-color: ${(props) => props.theme.colors.lightBackground};
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: ${(props) => props.theme.colors.ibbxOrange};
  }

  &:focus {
    box-shadow: 0 0 3px 3px rgba(255, 165, 0, 0.3);
  }
`;
