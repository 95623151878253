import styled from "styled-components";

export const OccurrenceHiearchyModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > span {
    width: 100%;
    text-align: left;
    ${(props) => props.theme.fonts.primary.H1Semibold};
    color: ${(props) => props.theme.colors.textPrimary};
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  @media screen and (max-width: 767px) {
    & > span {
      font-size: 18px;
      line-height: 22px;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    & > span {
      font-size: 1.5rem;
      line-height: 1.25rem;
    }
  }
`;
