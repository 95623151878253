import { useEffect, useMemo } from "react";
import useQueryParams from "./useQueryParams";

interface IuseAnalysisOwner {
  loadStorage?: boolean;
  triggerOnChange?: Function;
}

export default function useAnalysisOwner({
  loadStorage,
  triggerOnChange = () => null,
}: IuseAnalysisOwner) {
  const { queryParams, setQueryParams } = useQueryParams();

  useEffect(() => {
    if (loadStorage && !queryParams.analisysOwner) {
      const analysisOwner = localStorage.getItem("analysisOwner");
      if (analysisOwner) {
        setQueryParams({ ...queryParams, analysisOwner });
      }
    }
    // eslint-disable-next-line
  }, []);

  const filterValue = useMemo(() => {
    return queryParams.analysisOwner ?? null;
  }, [queryParams]);

  // eslint-disable-next-line
  useEffect(() => {
    if (triggerOnChange) {
      triggerOnChange();
    }
    // eslint-disable-next-line
  }, [filterValue]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const analysisOwner = event.target.value;
    if (!analysisOwner) {
      delete queryParams.analysisOwner;
      setQueryParams(queryParams);
      localStorage.removeItem("analysisOwner");
    } else {
      setQueryParams({ ...queryParams, analysisOwner });
      localStorage.setItem("analysisOwner", analysisOwner);
    }
  };

  return { filterValue, handleFilterChange };
}
