import styled, { css } from "styled-components";
import { FrameBodyRight } from "../../components/frame/styles";
import {
  AddNewContainer,
  FrameSidebarFilters,
  SidebarTitle,
} from "../../components/Sidebar/styles";

export const Header = styled(SidebarTitle)`
  @media screen and (max-width: 767px) {
    height: 4rem;
    & > div:nth-child(3) {
      display: flex;
      justify-content: flex-end;
      width: 6rem;
      margin-left: auto;
    }
  }
`;

export const Content = styled.div`
  @media screen and (max-width: 767px) {
  }
`;

export const FrameBodyRightAssets = styled(FrameBodyRight)`
  @media screen and (max-width: 767px) {
    width: 98%;
    margin: 0 auto;
    overflow-y: auto;
    & > div:nth-child(2) {
      .carousel-control-prev {
        opacity: 0;
      }
      .carousel-control-next-icon {
        opacity: 0;
      }
    }
  }
  ${(props) =>
    !props.isMobile &&
    css`
      overflow: hidden;
      & > div:first-child {
        display: flex;

        & > div {
          padding: 0;
          background-color: transparent;
          border: none;

          & > div {
            & > div {
              & > div {
                height: 100%;
                width: 100%;
                gap: 8px;

                & > div {
                  min-width: 750px;
                  padding: 8px 12px;
                  border-radius: 8px;
                  background-color: ${(props) =>
        props.theme.colors.divBackground};
                }
              }
            }
          }
        }
      }

      .marquee-container {
        height: 100%;
      }
    `};

  ${(props) =>
    props.isBoltTab &&
    !props.isMobile &&
    css`
      & > div:first-child {
        & > div {
          border: 1px solid ${(props) => props.theme.colors.cardHighlight};
          padding: 10px;
          background-color: ${(props) => props.theme.colors.divBackground};

          & > div {
            & > div:last-child {
              & > div {
                height: 140px;
                & > div {
                  min-width: 200px;
                  background: transparent;
                }
              }
            }
          }
        }
      }
    `};
`;

export const MarqueeContainer = styled.div`
  display: flex;

  height: 100%;
  width: 100%;
  & > div {
    margin: 0 8px;
    max-width: 400px;
  }
`;

export const AddNewContainerAssets = styled(AddNewContainer)`
  button {
    border: none !important;
    &:hover {
      background-color: transparent;
    }
  }
`;

export const DotButton = styled.button`
  cursor: pointer;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  color: ${(props) => props.theme.colors.iconsSecondary};
  height: 26px;
  width: 26px;
  border: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 60px;
    cursor: pointer;
  }

  & > * {
    pointer-events: none;
  }
`;

export const PlusItemDiv = styled.div`
  position: absolute;
  width: 26px;
  height: 26px;
  right: 4px;
  top: 38px;

  @media screen and (max-width: 767px) {
    top: 50px;
    right: 4px;
  }
`;

export const MenuSidebarAssets = styled.div`
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column !important;
  height: auto;
  top: 0px;
  padding: 5px 0;
  right: 62px;
  color: ${({ theme }) => theme.colors.iconsSecondary};
  background-color: ${(props) => props.theme.colors.cardBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  ${({ theme }) => theme.fonts.primary.H5SemiBold};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  overflow: hidden;

  div {
    display: flex;
    z-index: 20;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    height: 35px;
    width: fit-content;
    min-width: 136px;
    ${(props) => props.theme.fonts.primary.H4Bold};
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.colors.cardHighlight};
    }

    .csv {
      color: white;
    }
  }

  @media screen and (max-width: 767px) {
    width: 62vw;
    top: 3.5rem;
    right: 4.5rem;
    background-color: ${(props) => props.theme.colors.headerBackground};
    color: white;
    padding: 0.5rem;
    color: ${({ theme }) => theme.colors.iconsSecondary};
    background-color: ${(props) => props.theme.colors.cardBackground};
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    ${({ theme }) => theme.fonts.primary.H5SemiBold};

    & > div:first-child {
      button {
        border: none;
        background-color: transparent;

        &:hover {
          background-color: ${(props) => props.theme.colors.cardHighlight};
          border-radius: 60px;
        }
      }
    }

    & > div {
      border-radius: 0.5rem;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      &:hover {
        background-color: transparent;
      }
    }
  }
`;

export const FacilityTitle = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.fonts.primary.H3SemiBold};

  & > div {
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 767px) {
    justify-content: space-between;
    align-items: center;
    margin-left: 0;

    & > div {
      display: flex;
      gap: 0.8rem;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${(props) => props.theme.colors.cardHighlight};
        color: ${(props) => props.theme.colors.iconsSecondary};
        border-radius: 0.3rem;
        background-color: transparent;
        width: 2rem;
        height: 2rem;
      }
    }
  }
`;

export const ButtonsAssetsContainer = styled.div`
  display: flex;
  /* justify-content: space-between;
    align-items: center; */
  gap: 0.8rem;
  right: 1rem;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    color: ${(props) => props.theme.colors.iconsSecondary};
    border-radius: 0.3rem;
    background-color: transparent;
    width: 2rem;
    height: 2rem;
  }
`;

export const SidebarTitleEquipment = styled(SidebarTitle)`
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0rem;
  }
`;

export const FrameSidebarFiltersEquipment = styled(FrameSidebarFilters)`
  & > div:not(:first-child) {
    width: 100%;
    right: 0;
    gap: 2px;

    & > div {
      padding: 6px 6px 6px 12px;
      height: fit-content;
      font-weight: 400;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100% !important;
  }
`;

export const Separator = styled.div`
  margin: 3px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.cardHighlight};
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const EmptyAsset = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  padding-top: 20px;

  svg {
    margin-bottom: 12px;
  }
`;

export const LearnMoreButton = styled.span`
  padding: 4px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 4px;
  text-align: start;
  width: 100%;

  &:hover {
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  @media screen and (max-width: 767px) {
    padding: 0px 0px 8px  20px;
  }
`
