import { useEffect, useRef } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { userSelect } from "../store/slices/userSlice";
import { appSelect } from "../store/slices/appSlice";
import { getScreenResolution } from "../utils/mediaQuery";

export const useSegment = () => {
  const location = useLocation();
  const user = useAppSelector(userSelect);
  const app = useAppSelector(appSelect);

  // const userId = JSON.stringify({
  //   user: user.name,
  //   email: user.email,
  //   id: user.id,
  // });

  const analytics =
    process.env.REACT_APP_ENV === "production" &&
    AnalyticsBrowser.load({
      writeKey: process.env.REACT_APP_SEGMENT_KEY as string,
    });

  const startTimeRef = useRef<Date | null>(null);
  const endTimeRef = useRef<Date | null>(null);

  // useEffect(() => {
  //   if (analytics) {
  //     analytics.identify(user.id);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleTrackEvent = ({ event, ...props }: any) => {
    if (analytics) {
      analytics.track(event, {
        path: location.pathname,
        search: location.search,
        url: window.location.href,
        referrer: document.referrer,
        traits: {
          name: user.name,
          user: user.profile,
          company: user.company,
          companyName: app.currentCompany.name,
          facility: user.facility,
          facilityName: app.currentFacility.name,
          email: user.email,
          id: user.id,
        },
        ...props, // Qualquer propriedade adicional do evento pode ser adicionada aqui
      });

      // analytics.identify(user.id, {
      //   name: user.name,
      //   email: user.email,
      //   industry: user.company,
      //   facility: user.facility,
      //   id: user.id,
      //   user: user.profile,
      // });
    }
  };

  const handleTrackPageView = () => {
    if (analytics) {
      startTimeRef.current = new Date();
      // Rastreia automaticamente todos os eventos relevantes
      analytics.page({
        path: location.pathname,
        search: location.search,
        url: window.location.href,
        referrer: document.referrer,
        traits: {
          name: user.name,
          user: user.profile,
          company: user.company,
          companyName: app.currentCompany.name,
          facility: user.facility,
          facilityName: app.currentFacility.name,
          email: user.email,
          id: user.id,
          startTime: startTimeRef.current,
        },
      });

      analytics.identify(user.id, {
        name: user.name,
        email: user.email,
        industry: user.company,
        id: user.id,
        user: user.profile,
      });

      // analytics.track("page viewed", {
      //   path: location.pathname,
      //   search: location.search,
      //   url: window.location.href,
      //   referrer: document.referrer,
      // });
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (analytics && startTimeRef.current) {
        endTimeRef.current = new Date(); // Define o endTime no final da sessão

        const timeSpent =
          (endTimeRef.current as any) - (startTimeRef.current as any);
        const timeSpentSec = Math.floor(timeSpent / 1000);
        const timeSpentMin = Math.floor((timeSpentSec as any) / 60);
        const screenResolution = getScreenResolution();

        analytics.track("Session Ended", {
          spentTimeMiliSeconds: timeSpent,
          spentTimeSeconds: timeSpentSec,
          spentTimeMinutes: timeSpentMin,
          name: user.name,
          user: user.profile,
          company: user.company,
          companyName: app.currentCompany.name,
          facility: user.facility,
          facilityName: app.currentFacility.name,
          email: user.email,
          id: user.id,
          screenResolution: screenResolution,
        });
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics]);

  return {
    handleTrackPageView,
    handleTrackEvent,
  };
};
