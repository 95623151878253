import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  padding: 1.5rem 0 2.75rem 0;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background-color: ${(props) => props.theme.colors.occurrenceInfoBackground};
  position: relative;
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .hidenButton {
    display: none;
  }
  @media ${({ theme }) => theme.mediaQuery.small} {
    width: 100%;
    padding: 0;
    padding-bottom: 4rem;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding: 0.5rem 0 5.5rem 0;
    padding-bottom: 2rem;
    height: 90%;
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    gap: 0.25rem;
    width: 85%;
  }
`;
