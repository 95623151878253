import { Fragment, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router";
import { ROUTES_PATH } from "../../../routes/RoutesPath";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  appSelect,
  appSetCurrentAsset,
  appSetCurrentCompany,
  appSetCurrentFacility,
  appSetCurrentPosition,
  iconColors,
} from "../../../store/slices/appSlice";
import { getUrlPattern } from "../../../utils/getUrlPattern";
import {
  BreadcrumbContainer,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from "./styles";
import { breadItemModal } from "./types";
import theme from "../../../styles/themes/default";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { getUserHomeFromUrl } from "../../../utils/userUtils";
import { useSegment } from "../../../hooks/useSegmentTrackEvent ";

const BreadCrumb = ({ navbarColor }: { navbarColor?: string }) => {
  const {
    homeUrl,
    currentCompany: company,
    currentFacility: facility,
    currentAsset: asset,
    currentPosition: position,
  } = useAppSelector(appSelect);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams();

  const urlPattern = getUrlPattern(location, params);

  const { handleTrackEvent } = useSegment();

  const { t } = useTranslation();

  function buildCompanyBreadCrumb() {
    let link = `/companies/${company.id}/facilities`;
    let home = getUserHomeFromUrl(homeUrl);

    if (home === "assets") {
      link = homeUrl;
    }

    if (homeUrl === "/") {
      home = "facilities";
    }

    return { title: company.name, link, level: home };
  }

  function buildFacilityBreadCrumb() {
    let link = `/companies/${company.id}/facilities/${facility.id}/assets`;

    return { title: facility.name, link, level: "assets" };
  }

  function buildAssetBreadCrumb() {
    let link = `/companies/${company.id}/facilities/${facility.id}/assets/${asset.id}`;

    return { title: asset.name, link, level: "asset" };
  }

  function buildPositionBreadCrumb() {
    let link = `/positions/${position.id}`;
    return { title: position.positionName, link, level: "positions" };
  }

  function formatterBreadcrumb() {
    const breadcrumb: breadItemModal[] = [
      {
        title: t("breadcrumb.start"),
        link: homeUrl,
        level: getUserHomeFromUrl(homeUrl),
      },
    ];

    if (urlPattern === ROUTES_PATH.FACILITIES) {
      breadcrumb.push(buildCompanyBreadCrumb());
    }
    if (urlPattern === ROUTES_PATH.COMPANY_CONNECTIVY) {
      breadcrumb.push(buildCompanyBreadCrumb());
    }
    if (urlPattern === ROUTES_PATH.ASSETS) {
      breadcrumb.push(buildCompanyBreadCrumb());
      breadcrumb.push(buildFacilityBreadCrumb());
    }
    if (urlPattern === ROUTES_PATH.FACILITY_CONNETIVITY) {
      breadcrumb.push(buildCompanyBreadCrumb());
      breadcrumb.push(buildFacilityBreadCrumb());
    }
    if (urlPattern === ROUTES_PATH.ASSET) {
      breadcrumb.push(buildCompanyBreadCrumb());
      breadcrumb.push(buildFacilityBreadCrumb());
      breadcrumb.push(buildAssetBreadCrumb());
    }
    if (urlPattern === ROUTES_PATH.POSITIONS) {
      breadcrumb.push(buildCompanyBreadCrumb());
      breadcrumb.push(buildFacilityBreadCrumb());
      breadcrumb.push(buildAssetBreadCrumb());
      breadcrumb.push(buildPositionBreadCrumb());
    }
    if (urlPattern === ROUTES_PATH.OCCURRENCE) {
      breadcrumb.push(buildCompanyBreadCrumb());
      breadcrumb.push(buildFacilityBreadCrumb());
      breadcrumb.push(buildAssetBreadCrumb());
    }

    return breadcrumb;
  }

  function formatterBreadcrumbMobile() {
    const breadcrumb: breadItemModal[] = [
      {
        title: t("breadcrumb.start"),
        link: "/",
        level: getUserHomeFromUrl(homeUrl),
      },
    ];
    if (urlPattern === ROUTES_PATH.FACILITIES) {
      breadcrumb.push(buildCompanyBreadCrumb());
    }

    if (urlPattern.includes("connectivity")) {
      breadcrumb.push(buildCompanyBreadCrumb());
    }

    if (urlPattern === ROUTES_PATH.ASSETS) {
      <span>...</span>;
      breadcrumb.push(buildFacilityBreadCrumb());
    }

    if (urlPattern === ROUTES_PATH.ASSET) {
      <span>...</span>;
      breadcrumb.push(buildAssetBreadCrumb());
    }
    if (urlPattern === ROUTES_PATH.POSITIONS) {
      <span>...</span>;
      breadcrumb.push(buildPositionBreadCrumb());
    }
    if (urlPattern === ROUTES_PATH.OCCURRENCE) {
      <span>...</span>;
      breadcrumb.push(buildAssetBreadCrumb());
    }

    return breadcrumb;
  }

  //disabled in RW2-2023
  // const breadcrumb = isMobile
  //   ? formatterBreadcrumbMobile()
  //   : formatterBreadcrumb();

  const breadcrumb = formatterBreadcrumb();

  function clearCurrentHiearchy(level: string) {
    if (urlPattern === ROUTES_PATH.OCCURRENCES) {
      dispatch(appSetCurrentCompany(null));
      dispatch(appSetCurrentFacility(null));
      dispatch(appSetCurrentAsset(null));
      dispatch(appSetCurrentPosition(null));
    }
    switch (level) {
      case "companies":
        dispatch(appSetCurrentCompany(null));
        break;
      case "facilities":
        dispatch(appSetCurrentFacility(null));
        break;
      case "assets":
        dispatch(appSetCurrentAsset(null));
        break;
      case "asset":
        dispatch(appSetCurrentPosition(null));
        break;
      // case "positions":
      //   dispatch(appSetCurrentPosition(null));
      //   break;
    }
  }

  function handleChange(breadClicked: breadItemModal) {
    let removed = false;
    for (let breadItem of breadcrumb) {
      if (urlPattern === ROUTES_PATH.OCCURRENCES) {
        clearCurrentHiearchy(breadItem.level);
      }
      if (removed) {
        clearCurrentHiearchy(breadItem.level);
      }
      if (breadItem.level === breadClicked.level) {
        handleTrackEvent({
          event: `BreadCrumb lvl: ${breadClicked.level}`,
        });
        removed = true;
      }
    }
  }

  // eslint-disable-next-line
  const returnSelectedIconColor = () => {
    if (
      navbarColor &&
      navbarColor !== theme.colors.headerBackground &&
      navbarColor !== "#1B2B2C"
    ) {
      return iconColors[navbarColor];
    }
    return theme.colors.iconsSecondary;
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
  }, [breadcrumb]);

  return (
    <BreadcrumbContainer isMobile={isMobile} ref={containerRef}>
      {breadcrumb.map((bread, index) => {
        return (
          bread.title && (
            <Fragment key={index}>
              {index > 0 && (
                <BreadcrumbSeparator>
                  {String.fromCharCode(62)}
                  {/* disabled in RW2-2023  
                  {isMobile &&
                    !(urlPattern === ROUTES_PATH.FACILITIES) &&
                    !(urlPattern === ROUTES_PATH.COMPANY_CONNECTIVY) && (
                      <span>...</span>
                    )} */}
                </BreadcrumbSeparator>
              )}
              <div>
                <BreadcrumbLink
                  onClick={() => handleChange(bread)}
                  key={index}
                  to={bread.link}
                >
                  <span>{bread.title}</span>
                </BreadcrumbLink>
              </div>
            </Fragment>
          )
        );
      })}
    </BreadcrumbContainer>
  );
};

export default BreadCrumb;
