import { useTranslation } from "react-i18next";
import { Container, Content, LinkArea } from "./styles";

type ExtraInfoProps = {
  title: string;
  content: string;
  link1?: string;
  link1Name?: string;
  link2?: string;
  link2Name?: string;
};

export const CardExtraInfo = ({
  title,
  content,
  link1,
  link2,
  link2Name,
}: ExtraInfoProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Content>
        <span>{title}</span>
        <div>
          <p>{content}</p>
        </div>
      </Content>

      <LinkArea numberOfLinks={link2 ? 2 : 1}>
        {link2 && (
          <a href={link2} target="_blank" rel="noreferrer">
            {link2Name}
          </a>
        )}
        {link1 && (
          <a href={link1} target="_blank" rel="noreferrer">
            {t("esg.more")} &rarr;
          </a>
        )}
      </LinkArea>
    </Container>
  );
};
