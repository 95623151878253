import { GoogleChartOptions } from "react-google-charts";
import {
  defaultHAxis,
  defaultOptions,
  defaultVAxis,
} from "../../components/charts/chart/types";
import { isNumValid } from "../utilsFunctions";
import { ISerieControl } from "../../hooks/useSeriesControl";
import { IChart } from "../../store/interfaces";
import { IAnalysis } from "../../pages/Analysis/AnalysisTools/types";

export type chartAreaType = any;

type params = {
  chartData: any;
  colors: any;
  seriesControl: any;
  dateFormat?: any;
  isMobile?: boolean;
  ticks?: any;
  analysis?: IAnalysis;
  chartArea?: chartAreaType;
  chart: IChart;
  position: any;
  multipleAxis?: any;
  yTicks?: any[];
};

interface loadScaleParams {
  position: any;
  chart: IChart;
  chartData: any;
  seriesControl: any;
  index?: number;
}

function loadViewWindow({
  position,
  chart,
  chartData,
  seriesControl,
  index = 0,
}: loadScaleParams) {
  const maxValueOfVisibleSeries =
    seriesControl
      .filter((serie: any) => serie.visible && serie.index === index)
      .map((item: any) => {
        return item.maxValue;
      })
      .sort((a: any, b: any) => b - a)?.[0] || null;

  let result: any = { min: undefined, max: undefined };
  if (isNumValid(maxValueOfVisibleSeries)) {
    result.max = (+maxValueOfVisibleSeries || 1) * 1.005;
  } else if (isNumValid(chartData?.maxValue)) {
    result.max = +chartData.maxValue;
  }

  if (isNumValid(chartData?.minValue)) {
    result.min = +chartData.minValue;
  }

  const scaleText = localStorage.getItem("chartScale") || "{}";
  const scale = JSON.parse(scaleText);

  if (isNumValid(scale?.[position.id]?.[chart.name]?.minValue)) {
    result.min = +scale[position.id][chart.name].minValue;
  }

  if (isNumValid(scale?.[position.id]?.[chart.name]?.maxValue)) {
    result.max = +scale[position.id][chart.name].maxValue;
  }

  //Se for gráfico de regressão
  if (chartData.hasOwnProperty("regressionAngle")) {
    result.min = null;
    result.max = null;
  }

  if (chart.name === "SINAL-BRUTO-AC") {
    result.max = +chartData?.maxValue || null;
    result.min = +chartData?.minValue || null;
  }

  if (result.min === 0 && result.max === 0) {
    result.max = 1;
  }

  return result;
}

export function getDynamicOptions({
  chartData,
  colors,
  seriesControl,
  isMobile,
  ticks = null,
  analysis,
  chartArea,
  position,
  chart,
}: params) {
  const {
    bearingStamps,
    commentMode,
    cross,
    phasesAnalysis,
    harmonics,
    gearing,
  } = analysis ?? {};

  const series = seriesControl.reduce(
    (acc: any, serie: ISerieControl, index: number) => {
      const alarmLine = {
        type: "line",
        visibleInLegend: false,
        lineDashStyle: [2, 2, 20, 2, 20, 2],
        tooltip: false,
        pointsVisible: false,
        pointSize: 0,
        enableInteractivity: false,
      };

      acc[index] = {
        lineWidth: serie.visible ? 1.5 : 0,
        color: serie.visible ? serie.color : "none",
        tooltip: serie.visible,
        enableInteractivity: serie.visible,
        pointSize: 0,
        ...(serie.alarm ? alarmLine : {}),
      };

      return acc;
    },
    {},
  );

  let metriUnitX = chart?.metricUnits?.x;
  if (chartData.metricUnits?.x === "cpm") {
    metriUnitX = "CPM";
  }

  const dynamicOptions: GoogleChartOptions = {
    ...defaultOptions,
    hAxis: {
      ...defaultHAxis,
      textStyle: {
        ...defaultHAxis.textStyle,
        color: colors.iconsSecondary,
      },
      gridlines: {
        color: colors.cardHighlight,
      },
      title: metriUnitX || null,
      titleTextStyle: {
        color: colors.iconsSecondary,
        fontName: "Montserrat",
        italic: false,
      },
    },
    vAxis: {
      ...defaultVAxis,
      textStyle: {
        ...defaultVAxis.textStyle,
        color: colors.iconsSecondary,
      },
      gridlines: {
        color: colors.cardHighlight,
      },
      viewWindow: loadViewWindow({ position, chart, chartData, seriesControl }),
      title: chart?.metricUnits?.y || null,
      titleTextStyle: {
        color: colors.iconsSecondary,
        fontName: "Montserrat",
        italic: false,
      },
    },
    series: series,
    ...(cross
      ? {
        crosshair: {
          color: colors.ibbxOrange,
          trigger: "focus",
        },
      }
      : {}),
    explorer: !!analysis
      ? {
        actions: ["dragToZoom", "rightClickToReset"],
        axis: "horizontal",
        keepInBounds: true,
        maxZoomOut: 2.0,
        maxZoomIn: 30.0,
      }
      : undefined,
    annotations: {
      ...(!!harmonics ||
        !!bearingStamps ||
        phasesAnalysis ||
        !!gearing?.toString()
        ? {
          style: "line",
          stem: {
            color: colors.ibbxOrange,
          },
          textStyle: {
            fontSize: 13,
            fontName: "Lexend",
            color: colors.textPrimary,
            opacity: 1,
          },
        }
        : {}),
      ...(commentMode
        ? {
          stem: {
            visible: true,
          },
        }
        : {}),
    },
    ...(!!analysis && !commentMode ? { selectionMode: "multiple" } : {}),
  };

  if (isMobile) {
    dynamicOptions.chartArea.top = 10;
    dynamicOptions.chartArea.left = 70;
    dynamicOptions.chartArea.bottom = 40;
    dynamicOptions.chartArea.right = 30;
  }

  return dynamicOptions;
}

export const getOptions = ({
  chartData,
  colors,
  seriesControl,
  dateFormat,
  isMobile,
  ticks = null,
  analysis,
  chartArea,
  position,
  chart,
  multipleAxis,
  yTicks
}: params) => {
  let options: GoogleChartOptions = { backgroundColor: "transparent" };
  const { cross } = analysis ?? {};

  const chartName = chart?.name;

  const yAxisMetricName =
    chartName === "POT-INST" ? "kW" : chart?.metricUnits?.y;

  const series: any = seriesControl.reduce(
    (acc: any, serie: ISerieControl, index: number) => {
      const alarmLine = {
        type: "line",
        visibleInLegend: false,
        lineDashStyle: [2, 2, 20, 2, 20, 2],
        tooltip: false,
        pointsVisible: false,
        pointSize: 0,
        enableInteractivity: false,
        targetAxisIndex: serie.index,
      };

      acc[index] = {
        lineWidth: serie.visible ? 1.5 : 0,
        color: serie.visible ? serie.color : "none",
        tooltip: serie.visible,
        enableInteractivity: serie.visible,
        pointSize: 0,
        ...(serie.alarm ? alarmLine : {}),
        targetAxisIndex: serie.index,
      };

      if (serie.name === "Previsão (%)") {
        acc[index].lineDashStyle = [5, 3, 5, 3, 5, 3];
      }

      if (serie.name === "Regressão") {
        acc[index].lineWidth = 3;
        acc[index].color = colors.iconsSecondary;
      }

      return acc;
    },
    {},
  );

  if (isMobile) {
    options.chartArea = {
      top: 10,
      left: 70,
      bottom: 40,
      right: 30,
    };
  }

  options = {
    ...defaultOptions,
    ...options,
    vAxes: {
      0: {
        ...defaultVAxis,
        textStyle: {
          ...defaultVAxis.textStyle,
          color: colors.iconsSecondary,
        },
        gridlines: {
          count: ticks.length,
          color: colors.cardHighlight,
        },
        viewWindow: loadViewWindow({
          position,
          chart,
          chartData,
          seriesControl,
        }),
        title: yAxisMetricName || null,
        titleTextStyle: {
          color: colors.iconsSecondary,
          fontName: "Montserrat",
          italic: false,
        },
      },
    },
    hAxis: {
      ...defaultHAxis,
      textStyle: {
        ...defaultHAxis.textStyle,
        color: colors.iconsSecondary,
      },
      gridlines: {
        color: colors.cardHighlight,
      },
      format: dateFormat || "dd/MM/yyyy \n HH:mm",
      ticks: ticks,
      viewWindow: {
        min: chartData?.data?.[0]?.[0] || null,
        max: chartData?.data?.[chartData.data.length - 1][0] || null,
      },
    },
    series: series,
    ...(cross
      ? {
        crosshair: {
          color: colors.ibbxOrange,
          trigger: "focus",
        },
      }
      : {}),
    explorer: !!analysis
      ? {
        actions: ["dragToZoom", "rightClickToReset"],
        axis: "horizontal",
        keepInBounds: true,
        maxZoomOut: 2.0,
        maxZoomIn: 30.0,
      }
      : undefined,
    animation: {
      startup: true,
      duration: 1000,
      easing: "out",
    },
  };

  if (yTicks 
    // && !multipleAxis
  ) {
    options.vAxes[0].ticks = yTicks;
  }

  if (analysis?.temperatureCorrelation) {
    options.vAxes[1] = {
      ...options.vAxes[0],
      viewWindow: loadViewWindow({
        position,
        chart,
        chartData,
        seriesControl,
        index: 1,
      }),
      title: "ºC",
      gridlines: {
        count: 0,
      },
    };
  }

  if (multipleAxis) {
    options.vAxes[1] = {
      ...options.vAxes[0],
      viewWindow: {
        min: chartData?.secondVAxisMinValue,
        max: chartData?.secondVAxisMaxValue,
      },
      title: "dBm",
      gridlines: {
        count: 0,
      },
    };
  }

  if (chartArea && !isMobile) {
    options.chartArea = chartArea;
  }

  return options;
};

export const defaultChartOptions = {
  chartArea: {
    height: "82%",
    width: "88%",
    bottom: "15%",
  },
  selectionMode: "single",
  backgroundColor: "transparent",
  tooltip: {
    isHtml: true,
  },
  aggregationTarget: "none",
  legend: "none",
  interpolateNulls: true,
  vAxes: {
    "0": {
      minorGridlines: {
        color: "transparent",
      },
      textStyle: {
        fontSize: 10,
        color: "#ADB2CF",
      },
      format: "short",
      viewWindowMode: "explicit",
      gridlines: {
        count: 359,
        color: "#3A4058",
      },
      viewWindow: {},
      title: "%",
      titleTextStyle: {
        color: "#ADB2CF",
        fontName: "Montserrat",
        italic: false,
      },
    },
  },
  hAxis: {
    minorGridlines: {
      color: "transparent",
    },
    direction: 1,
    textStyle: {
      fontSize: 10,
      color: "#ADB2CF",
    },
    gridlines: {
      color: "#3A4058",
    },
    format: "dd/MM/yyyy \n HH:mm",
    ticks: [],
    viewWindow: {},
  },
  series: {
    "0": {
      lineWidth: 1.5,
      color: "#01D5BF",
      tooltip: true,
      enableInteractivity: true,
      pointSize: 0,
      targetAxisIndex: 0,
    },
  },
  animation: {
    startup: true,
    duration: 1000,
    easing: "out",
  },
  explorer: {},
};
