import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  width: 460px;
  height: fit-content;
  border-radius: 6px;
  color: ${(props) => props.theme.colors.iconsSecondary};

  table {
    width: 100%;
    font-size: 12px;

    thead {
      display: table;
      width: calc(100% - 0px);
      th {
      }
      tr:first-child {
        background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
        border: 1px solid ${(props) => props.theme.colors.cardHighlight};
      }
    }

    tbody {
      display: block;
      max-height: 500px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: ${(props) => props.theme.colors.background};
        width: 20px;
        border-radius: 100vw;
        margin-block: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
        height: 100%;
        max-height: 5rem;
        border-radius: 100vw;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.colors.iconsSecondary};
      }

      &::-webkit-scrollbar-thumb:active {
        background: ${(props) => props.theme.colors.iconsSecondary};
      }
    }
    td {
      background: transparent;
      padding: 8px;
      text-align: center;
      border-bottom: none;
      border-left: none;
    }
    td,
    th {
      width: 25%;
      padding: 6px;
      word-break: break-all;
    }

    tr {
      display: table;
      width: 100%;
      box-sizing: border-box;
      :hover {
        background: ${(props) => props.theme.colors.cardHighlight};
      }
    }
  }
`;
