import styled, { css } from "styled-components";

export const Container = styled.div<{
  showSidebar?: boolean;
  isFiltered?: boolean;
  isExpanded?: boolean;
}>`
  background: ${({ theme }) => theme.colors.divBackground};
  border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
  border-radius: 6px;
  display: grid !important;
  grid-template-columns: 0.1fr repeat(4, 1fr);
  grid-template-rows: 0.3fr 0.4fr repeat(3, 1fr);
  height: 100%;
  padding: 10px;

  ${(props) =>
    props.showSidebar &&
    css`
      display: grid !important;
      grid-template-columns: 0.5fr repeat(4, 1fr);
      gap: 8px 0px;
    `}

  & > div:nth-child(2) > div > div {
    padding: 0px 10px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 2px 0 2px;
    overflow-x: hidden;
  }

  @media screen and (min-width: 768px) and (max-width: 1280px) {
    grid-template-rows: 0.1fr 0.6fr repeat(3, 1fr);
    height: 100%;
  }

  @media screen and (min-width: 1281px) and (max-width: 1536px) {
    grid-template-rows: 0.1fr 0.4fr repeat(3, 1fr);
  }
`;
