import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { MdOutlineHideImage } from "react-icons/md";
import { RiImageAddFill } from "react-icons/ri";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "@kokarn/react-zoom-pan-pinch";

import { Modal } from "../Modal";
import { error } from "../Toast";

import {
  AddImageButton,
  ButtonImage,
  Container,
  CoverLabel,
  EmptyImageContainer,
  ImageGalleryFooter,
  GalleryContainer,
  GalleryTools,
  ImageActions,
  ImageContainer,
  GalleryPdfContainer,
  AutomaticEvidence,
  TooltipContainer,
  ContainerEvidences,
  PrincipalImageButton,
} from "./styles";

import { Loading } from "../Loading";
import useApi from "../../hooks/useApi";
import { ButtonCancel } from "../Modal/styles";
import {
  BsBookmarkStar,
  BsBookmarkStarFill,
  BsFiletypePdf,
  BsTrash,
} from "react-icons/bs";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { useTheme } from "styled-components";
import ResourceNameModal from "./ResourceNameModal";
import { NamePlusTooltip } from "../NamePlusTooltip";
import { CgCircleci } from "react-icons/cg";
import { useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";
import { appSelect } from "../../store/slices/appSlice";

const initialScale = 1;

type GalleryTypes = "EVIDENCES" | "ASSET" | "POSITION" | "GATEWAY";
interface IResourceGalleryProps {
  id: number;
  show: boolean;
  handleClose: () => void;
  reload?: (imageUrl?: string) => void;
  occurrence?: number;
  title?: string;
  exportResources?: (images?: any) => void;
  selected?: any;
  type: GalleryTypes;
}

function findUrlFromType(id: number, type: GalleryTypes) {
  switch (type) {
    case "ASSET":
      return `/assets/${id}/resources`;
    case "POSITION":
      return `/positions/${id}/resources`;
    case "GATEWAY":
      return `/gateways/${id}/resources`;
  }

  return "";
}

export const ImageGallery = ({
  show,
  handleClose,
  id,
  type,
  reload = (imageUrl?: string) => null,
  title,
  selected = null,
  exportResources = () => null,
}: IResourceGalleryProps) => {
  // Hooks
  const { isReadOnly } = useAppSelector(appSelect);
  const { theme } = useTheme();

  const [scale, setScale] = useState(initialScale);
  const [images, setImages] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<any>(selected);
  const [file, setFile] = useState<File | null>(null);
  const [modalLabel, setModalLabel] = useState<boolean>(false);
  const [showTooltip] = useState<any>({});
  const [image64, setImage64] = useState<any>();

  const inputRef = useRef<HTMLInputElement>(null);
  const zoomRef = useRef<ReactZoomPanPinchRef>(null);

  const { t } = useTranslation();

  const { processing, request } = useApi({ path: findUrlFromType(id, type) });

  const getImageUrl = useCallback(
    (image: any) => {
      if (image?.chartDark && theme === "dark") {
        return image?.chartDark;
      }
      return image?.url;
    },
    [theme],
  );

  const selectedResourceUrl = useMemo(() => {
    if (selectedImage?.chartDark && theme === "dark") {
      return selectedImage?.chartDark;
    }
    return selectedImage?.url;
  }, [selectedImage, theme]);

  useEffect(() => {
    if (show) {
      request({ method: "get" }).then((response) => {
        const order: any = orderImages(response, true);
        setImages(order || []);
        exportResources(order || []);
        if (!selected) {
          setSelectedImage(order[0] || null);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  function orderImages(array: any, checkFavorite?: boolean): any {
    array = array.filter((item: any) => !!item);
    array = array.sort((a: any, b: any) => a.id - b.id);
    if (checkFavorite) {
      array = array.sort((a: any, b: any) => (a.favorite ? -1 : a.id - b.id));
    }

    return array;
  }

  function deleteImage() {
    if (selectedImage?.id) {
      request({ method: "del", pathParameters: `${selectedImage.id}` }).then(
        (response) => {
          const order: any = orderImages(response, true);
          setImages(order);
          if (selectedImage?.favorite) {
            const favoriteImage = response.find(
              (img: { favorite: boolean }) => img?.favorite,
            );

            reload(favoriteImage?.url || "");
          }
          setSelectedImage(order[0] || null);
          exportResources(order);
        },
      );
    }
  }

  function favoriteImage() {
    if (selectedImage?.id || selectedImage.id === 0) {
      request({
        method: "put",
        pathParameters: `${selectedImage.id}/favorite`,
      }).then((response) => {
        const favoriteImage = response.find(
          (img: { favorite: boolean }) => img?.favorite,
        );

        const order: any = orderImages(response, true);
        setImages(order);
        setSelectedImage(order[0] || null);
        reload(favoriteImage?.url || "");
      });
    }
  }

  async function formatImageFile(file: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage64(reader.result);
    };
  }

  async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    if (!event.target || !event.target.files || !event.target.files[0]) {
      return;
    }

    setFile(event.target.files[0]);
    formatImageFile(event.target.files[0]);
    setModalLabel(true);
  }

  async function handleSaveFile(label?: string) {
    if (!file) {
      return;
    }

    const body: any = { type: file.type, label };

    try {
      setModalLabel(false);
      if (file.type === "application/pdf") {
        const buffer = await file.arrayBuffer();

        body.data = await btoa(
          new Uint8Array(buffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            "",
          ),
        );
      } else {
        body.data = image64;
      }

      request({ method: "put", body })
        .then((response) => {
          const newImage = orderImages(response.images).reverse()[0];
          const order: any = orderImages(response.images, true);
          const shouldReload = !images.filter((img) => !!img.id).length;

          setImages(order);
          exportResources(order);
          if (shouldReload) {
            reload();
          }
          setSelectedImage(newImage);
        })
        .catch((err) => {
          error(err);
        });
    } catch {
      setModalLabel(false);
    }
  }

  const handleZoom = (step: number) => {
    setScale((scale: number) => scale + step);
    zoomRef.current?.zoomIn(step);
  };

  return (
    <>
      {modalLabel && (
        <ResourceNameModal
          show={modalLabel}
          handleClose={() => setModalLabel(false)}
          handleConfirm={handleSaveFile}
        />
      )}
      <Modal
        show={show}
        handleClose={() => {
          handleClose();
          setImages([]);
          setSelectedImage(null);
        }}
        handleConfirm={() => {}}
        size="lg"
        backdrop="static"
        changeFotter
      >
        <Container>
          {processing ? (
            <Loading />
          ) : (
            <>
              <input
                ref={inputRef}
                type="file"
                id="input-file-upload"
                onChange={handleChange}
              />
              <span>
                {title || `${t("attachmentsEvidenceModal.resourceGallery")}`}
              </span>
              <ImageContainer isPdf={selectedImage?.type === "PDF"}>
                {selectedImage && selectedImage?.type === "PDF" && (
                  <div className="pdf">
                    <label>{selectedImage?.label}</label>
                    <GalleryPdfContainer
                      onClick={() => window.open(selectedImage.url, "_blank")}
                    >
                      <BsFiletypePdf />
                      <span>{t("attachmentsEvidenceModal.clickToView")}</span>
                    </GalleryPdfContainer>
                  </div>
                )}
                {selectedImage && selectedImage?.type !== "PDF" && (
                  <div>
                    <label>{selectedImage?.label}</label>

                    <TransformWrapper
                      ref={zoomRef}
                      initialScale={initialScale}
                      onZoom={(ref: ReactZoomPanPinchRef) =>
                        setScale(ref.state.scale)
                      }
                      doubleClick={{ disabled: true }}
                      panning={{
                        disabled: scale === 1,
                        velocityDisabled: true,
                      }}
                    >
                      <TransformComponent>
                        <img
                          src={selectedResourceUrl}
                          alt={"foto-equipamento"}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                )}
                {!selectedImage && (
                  <EmptyImageContainer>
                    <MdOutlineHideImage size={60} />
                    <span>
                      {type !== "EVIDENCES"
                        ? `${t("attachmentsEvidenceModal.noPhoto")}`
                        : `${t("attachmentsEvidenceModal.noEvidence")}`}
                    </span>
                  </EmptyImageContainer>
                )}
              </ImageContainer>
              {selectedImage && (
                <GalleryTools>
                  <button onClick={() => handleZoom(0.2)}>
                    <AiOutlineZoomIn />
                  </button>
                  <button onClick={() => handleZoom(-0.2)}>
                    <AiOutlineZoomOut />
                  </button>
                </GalleryTools>
              )}

              <GalleryContainer isEvidence={type === "EVIDENCES"}>
                {!isReadOnly && (
                  <AddImageButton
                    className="upload-button"
                    onClick={() => {
                      if (inputRef.current) {
                        inputRef.current.click();
                      }
                    }}
                  >
                    <RiImageAddFill size={30} />
                    <span>
                      {type !== "EVIDENCES"
                        ? `${t("attachmentsEvidenceModal.addResource")}`
                        : `${t("attachmentsEvidenceModal.newEvidence")}`}
                    </span>
                  </AddImageButton>
                )}
                {images.map((image, index) => (
                  <ContainerEvidences>
                    <ButtonImage
                      key={index}
                      onClick={() => setSelectedImage(image)}
                      selected={selectedImage?.id === image.id}
                    >
                      {image.type === "PDF" ? (
                        <>
                          {showTooltip.id === index && (
                            <TooltipContainer>{image.label}</TooltipContainer>
                          )}
                          <BsFiletypePdf />
                        </>
                      ) : (
                        <>
                          <img
                            src={getImageUrl(image)}
                            alt={`foto-${index}`}
                            key={index}
                          />
                          {image.type === "CHART" && (
                            <NamePlusTooltip
                              position={"top"}
                              tooltip={`${t(
                                "occurrencesTab.occurrenceListItem.evidenceTooltips.autoRetina",
                              )}`}
                            >
                              <AutomaticEvidence>
                                <CgCircleci size={18} />
                              </AutomaticEvidence>
                            </NamePlusTooltip>
                          )}
                        </>
                      )}
                      <CoverLabel favorite={image.favorite}>
                        <BsBookmarkStarFill />
                      </CoverLabel>
                    </ButtonImage>
                    <>
                      {showTooltip?.id === index && (
                        <TooltipContainer>{image.label}</TooltipContainer>
                      )}
                      <NamePlusTooltip position={"top"} tooltip={image.label}>
                        <label style={{ minHeight: "22px" }}>
                          {image.label}
                        </label>
                      </NamePlusTooltip>
                    </>
                  </ContainerEvidences>
                ))}
              </GalleryContainer>
            </>
          )}
          <ImageGalleryFooter>
            <ImageActions>
              {!isReadOnly && (
                <>
                  {/* {type !== "EVIDENCES" && ( */}
                  <PrincipalImageButton
                    disabled={selectedImage?.type === "PDF"}
                    onClick={favoriteImage}
                  >
                    <BsBookmarkStar size={16} />
                    <span>{t("ImageGallery.defineAsCape")}</span>
                  </PrincipalImageButton>
                  {/* )} */}

                  <button onClick={deleteImage} disabled={!selectedImage?.id}>
                    <BsTrash size={16} />
                    <span>{`${t("commonText.erase")}`}</span>
                  </button>
                </>
              )}
            </ImageActions>

            <ButtonCancel
              onClick={() => {
                handleClose();
                setImages([]);
                setSelectedImage(null);
              }}
            >
              {`${t("commonText.close")}`}
            </ButtonCancel>
          </ImageGalleryFooter>
        </Container>
      </Modal>
    </>
  );
};
