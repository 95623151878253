import React from "react";
import { Root, Title, Content, List, ListItems } from "./styles";

const Steps = ({ children }: { children: React.ReactNode }) => {
  return <Root>{children} </Root>;
};

// Subcomponentes
Steps.Root = ({ children }: { children: React.ReactNode }) => {
  return <Root>{children}</Root>;
};

Steps.Title = ({ children }: { children: React.ReactNode }) => {
  return <Title>{children}</Title>;
};

Steps.Content = ({ children }: { children: React.ReactNode }) => {
  return <Content>{children}</Content>;
};

Steps.List = ({ children }: { children: React.ReactNode }) => {
  return <List>{children}</List>;
};

Steps.ListItems = ({ children }: { children: React.ReactNode }) => {
  return <ListItems>{children}</ListItems>;
};

export default Steps;
