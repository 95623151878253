import styled from "styled-components";

export const DateContainer = styled.div<{}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  width: 30px;
  height: 38px;
  border-radius: 100%;
  position: relative;
`;

export const DatePickerContainer = styled.div`
  position: relative;
  position: absolute;
  right: 0px;

  input {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: none;
    background: ${(props) => props.theme.colors.cardBackground};
    color: ${(props) => props.theme.colors.iconsSecondary};
    font-size: 10px;
    font-weight: 800;
    padding: 10px;
    padding-right: 15px;

    :focus {
      border: none !important;
      outline: none !important;
    }
  }

  & > div {
    border-radius: 8px;
    border: none;

    & > div {
      & > button {
        ::after {
          background: ${(props) => props.theme.colors.iconsSecondary}!important;
          color: ${(props) => props.theme.colors.cardBackground}!important;
        }
      }
    }
  }

  .react-datepicker {
    border: none;

    & > div {
      & > div:first-child {
        div {
          color: ${(props) => props.theme.colors.iconsSecondary}!important;
        }
        background: ${(props) => props.theme.colors.cardBackground}!important;
      }
      & > div:last-child {
        margin: 0;
        padding: 0.4rem;
        background: ${(props) => props.theme.colors.lightBackground}!important;
      }
    }

    div:last-child {
      border: none;
      div:first-child {
      }
      div:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 5;
  }

  @media screen and (max-width: 767px) {
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  right: 5px;
`;
