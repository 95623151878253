import styled from "styled-components";

export const Container = styled.div<{ opacity?: string }>`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0 !important;
  opacity: ${({ opacity }) => (opacity ? opacity : "1")};
`;

export const Header = styled.div<{ isHidden?: boolean }>`
  display: flex;
  width: 100%;
  height: 2rem;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) => props.theme.colors.headerCardSecondary};
  border-radius: 6px 6px 0 0;
  position: relative;

  & > div:first-child {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 1rem;
    opacity: ${({ isHidden }) => (isHidden ? "0.4" : "1")};

    p {
      margin: 0;
      font-size: 0.75rem;
    }

    & > svg {
      width: 1.5rem;
      height: auto;
    }
  }
`;

export const OpenMenuButton = styled.div`
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.colors.iconsSecondary};

  &:hover {
    background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    transition: all 0.3s ease;
  }
`;

export const ReturTab = styled(OpenMenuButton)``;

export const Content = styled.div<{ isHidden?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
  background-color: ${(props) => props.theme.colors.headerCard};
  border-radius: 0 0 6px 6px;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
  opacity: ${({ isHidden }) => (isHidden ? "0.4" : "1")};
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  right: 2.9rem;
  top: 23px;
  border-left: 6px solid ${(props) => props.theme.colors.headerCardSecondary};
  position: absolute;
`;

export const MenuContainer = styled.div<{ isHidden?: boolean }>`
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background-color: ${(props) => props.theme.colors.cardBackground};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  top: 0.25rem;
  right: 1.75rem;
  z-index: 10;
  border-radius: 6px;

  & > div {
    width: 100%;
    padding: 0.75rem;
    &:hover {
      background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      transition: all 0.3s ease;
    }
  }
`;
