import { IPosition } from "../../../store/interfaces";

export type ModalBearingProps = {
  show: boolean;
  handleClose: () => void;
  handleConfirm: (stamps: any, harmonics: any) => void;
  bearingType?: any;
  id?: any;
  position: IPosition;
};

export type modalBearingModel = {
  bearingType: any;
  bearingCode: any;
  rpm: number;
  harmonics: number;
};

export const modalBearingTypeEmpty = {
  bearingType: "",
  bearingCode: "",
  rpm: 0,
  harmonics: 4,
};
