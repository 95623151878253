import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

// AWS
import { Amplify, Auth, API } from "aws-amplify";
import { awsConfig } from "../awsConfig";
import { formatterLoginErrorMessage } from "../../utils/loginMessages";
import { error } from "../../components/Toast";
import { notifyDiscordError } from "../../utils/notifyDiscord";

//Configuração para acesso ao Cognito e ao API Gateway
Amplify.configure(awsConfig);
const apiRetinaCustomer = awsConfig?.API?.endpoints?.[0]?.name;

//Modelo de dados do usuário
interface SliceState {
  loginMessage: string;
  loginNewPassword: boolean;
  loginTmpPassword: string;
  id: string;
  name: string;
  email: string;
  company: string;
  facility: string;
  profile: string;
  terms: number;
  home: string;
  homeUrl: string;
  isClient: boolean;
  admin: boolean;
  ibbx: boolean;
  isAnalyst: boolean;
  readonly: boolean;
  dashboardsPreference?: any;
  retinaVersion?: string;
  configurationNotificationTypes: string;
}

//Valor inicial do usuário
const initialState: SliceState = {
  loginMessage: "",
  loginNewPassword: false,
  loginTmpPassword: "",
  id: "",
  name: "",
  email: "",
  company: "",
  facility: "",
  profile: "",
  terms: -1,
  home: "",
  homeUrl: "/",
  isClient: false,
  admin: false,
  ibbx: false,
  isAnalyst: false,
  readonly: false,
  dashboardsPreference: {},
  retinaVersion: "",
  configurationNotificationTypes: "",
};

export const PROFILES_BITS = {
  EXECUTIVO_MASTER:
    "000000000000000000000000000100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000",
  MESA_MASTER:
    "000000000000000000000000000100000000000000000100100000000000000000000000000000000000000000000000000000100000000000010000100000000010000000000000000000",
  MESA_ANALISTA:
    "000000000000000000000000000000000000000001000100000000000000000000000000000000000000000000000000000000100000000000010000100000000010000000000000000000",
  REPRESENTANTE:
    "000000000000000000000000000000000000000001000100000000000000000000000000000000000000000000000000000000100000000000010000100000000010000000000000000001",
  CLIENTE_MASTER:
    "000000000000000000000000000000001000000000000100000000000000000000000000000000000000000000000000000010100000000000010000100000000010000000000000000000",
  CLIENTE_COMUM:
    "000000000000000000000000000000000010000000000100000000000000000000000000000000000000000000000000000010100000000000010000100000000010000000000000000000",
  CLIENTE_MASTER_VIEW:
    "000000000000000000000000000000001000000000000110000000000000000000000000000000000000000000000000000010100000000000010000100000000010000000000000000000",
};

export const PROFILES = {
  EXECUTIVO_MASTER: "EXECUTIVO_MASTER",
  MESA_MASTER: "MESA_MASTER",
  MESA_ANALISTA: "MESA_ANALISTA",
  REPRESENTANTE: "REPRESENTANTE",
  CLIENTE_MASTER: "CLIENTE_MASTER",
  CLIENTE_COMUM: "CLIENTE_COMUM",
  CLIENTE_MASTER_VIEW: "CLIENTE_MASTER_VIEW",
};

// Retorna a homepage de acesso do usuário
// Mapeado pelos bits do profile
/*
REGRAS:

* Executivo Master
bit 27 = 1
Acessa todas companies
Começa no Épico 1

* Mesa Master
bit 27 = 1
Acessa todas companies
Começa no Épico 1

* Mesa Analista
bit 41 = 1
Acessa companies e facilities selecionadas
Começa no Épico 1

* Cliente Company Master
bit 30 = 1
Acessa companies selecionadas e todas suas facilities
Começa no Épico 1

* Cliente Master
bit 32 = 1
Acessa todas as facilities de sua company
Começa no Épico 2

* Cliente Comum
bit 33 = 1
Acessa todas apenas a sua facility
Começa no Épico 3

*/
const getHome = (profile: string) => {
  if (
    [
      PROFILES.EXECUTIVO_MASTER,
      PROFILES.MESA_ANALISTA,
      PROFILES.MESA_MASTER,
      PROFILES.REPRESENTANTE,
      PROFILES.CLIENTE_MASTER,
    ].includes(profile)
  ) {
    return "companies"; //acesso a todas companies e facilities
  } else if (
    [PROFILES.CLIENTE_MASTER_VIEW, PROFILES.CLIENTE_COMUM].includes(profile)
  ) {
    return "facilities"; //acesso a todas facilities da mesma company
  } else {
    return "assets"; // acesso apenas à sua facility
  }
};

export const getProfile = (profile: string) => {
  // @TODO update to get profile type by specific bits
  switch (profile) {
    case PROFILES_BITS.EXECUTIVO_MASTER:
      return PROFILES.EXECUTIVO_MASTER;
    case PROFILES_BITS.MESA_MASTER:
      return PROFILES.MESA_MASTER;
    case PROFILES_BITS.MESA_ANALISTA:
      return PROFILES.MESA_ANALISTA;
    case PROFILES_BITS.CLIENTE_MASTER:
      return PROFILES.CLIENTE_MASTER;
    case PROFILES_BITS.REPRESENTANTE:
      return PROFILES.REPRESENTANTE;
    case PROFILES_BITS.CLIENTE_MASTER_VIEW:
      return PROFILES.CLIENTE_MASTER_VIEW;
    default:
      return PROFILES.CLIENTE_COMUM;
  }
};

const getHomeUrl = ({
  profile,
  company,
  facility,
}: {
  profile: string;
  company: string;
  facility: string;
}) => {
  if (
    [
      PROFILES.EXECUTIVO_MASTER,
      PROFILES.MESA_ANALISTA,
      PROFILES.MESA_MASTER,
      PROFILES.MESA_ANALISTA,
      PROFILES.REPRESENTANTE,
      PROFILES.CLIENTE_MASTER,
    ].includes(profile)
  ) {
    return "/";
  } else if (
    [PROFILES.CLIENTE_MASTER_VIEW, PROFILES.CLIENTE_COMUM].includes(profile)
  ) {
    return `/companies/${company}/facilities`;
  } else {
    return `/companies/${company}/facilities/${facility}/assets`;
  }
};

function isClient(profile: string) {
  return [
    PROFILES.CLIENTE_COMUM,
    PROFILES.CLIENTE_MASTER,
    PROFILES.CLIENTE_MASTER_VIEW,
  ].includes(profile);
}

function isReadOnly(profile: string) {
  return profile === PROFILES.CLIENTE_MASTER_VIEW;
}

function isAdministrator(profile: string) {
  return [
    PROFILES.EXECUTIVO_MASTER,
    PROFILES.MESA_MASTER,
    PROFILES.REPRESENTANTE,
  ].includes(profile);
}

function isIbbxUser(profile: string) {
  return [PROFILES.EXECUTIVO_MASTER, PROFILES.MESA_MASTER].includes(profile);
}

function isAnalyst(profile: string) {
  return [
    PROFILES.EXECUTIVO_MASTER,
    PROFILES.MESA_MASTER,
    PROFILES.MESA_ANALISTA,
    PROFILES.REPRESENTANTE,
  ].includes(profile);
}

// VERIFICA SE HÁ TOKEN NO LOCAL STORAGE --------------------------------------------
export const userSession = createAsyncThunk("user/userSession", async () => {
  const session = await Auth.currentSession();

  const loggedUser = session.getIdToken().payload;

  const authUser = await Auth.currentAuthenticatedUser();
  const userAttributes = await Auth.userAttributes(authUser);

  const userInfo = userAttributes.reduce((acc: any, att: any) => {
    return {
      ...acc,
      [att.Name]: att.Value,
    };
  }, {});

  // Verify if user has accpet terms
  const { zoneinfo } = userInfo;
  const { terms } = JSON.parse(zoneinfo);

  const profile = getProfile(loggedUser.profile);

  const resp: SliceState = {
    loginMessage: "",
    loginNewPassword: false,
    loginTmpPassword: "",
    id: userInfo.sub,
    name: userInfo.name,
    email: userInfo.email,
    company: userInfo.family_name,
    facility: userInfo.given_name,
    profile,
    terms: terms ? 1 : 0,
    home: getHome(profile),
    homeUrl: getHomeUrl({
      profile,
      company: userInfo.family_name,
      facility: userInfo.given_name,
    }),
    isClient: isClient(profile),
    admin: isAdministrator(profile),
    ibbx: isIbbxUser(profile),
    isAnalyst: isAnalyst(profile),
    readonly: isReadOnly(profile),
    configurationNotificationTypes: "",
  };
  return resp;
});

// LOGIN --------------------------------------------
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (params: { email: string; password: string }) => {
    const user = await Auth.signIn(params.email, params.password);

    let terms = false;
    if (user.attributes) {
      const { zoneinfo } = user.attributes;
      terms = JSON.parse(zoneinfo || "{}").terms;
    }

    if (
      user.hasOwnProperty("challengeName") &&
      user.challengeName === "NEW_PASSWORD_REQUIRED"
    ) {
      // Novo usuário, força a troca de senha
      const resp: SliceState = {
        loginMessage: "",
        loginNewPassword: true,
        loginTmpPassword: params.password,
        id: "",
        name: "",
        email: params.email,
        company: "",
        facility: "",
        profile: "",
        terms: terms ? 1 : 0,
        home: "",
        homeUrl: "/",
        isClient: false,
        admin: false,
        ibbx: false,
        isAnalyst: false,
        readonly: false,
        configurationNotificationTypes: "",
      };
      return resp;
    } else {
      // Usuário já existe, faz o login
      const profile = getProfile(user.attributes.profile);
      const resp: SliceState = {
        loginMessage: "",
        loginNewPassword: false,
        loginTmpPassword: "",
        id: user.attributes.sub,
        name: user.attributes.name,
        email: user.attributes.email,
        company: user.attributes.family_name,
        facility: user.attributes.given_name,
        profile,
        terms: terms ? 1 : 0,
        home: getHome(profile),
        homeUrl: getHomeUrl({
          profile,
          company: user.attributes.family_name,
          facility: user.attributes.given_name,
        }),
        isClient: isClient(profile),
        admin: isAdministrator(profile),
        ibbx: isIbbxUser(profile),
        isAnalyst: isAnalyst(profile),
        readonly: isReadOnly(profile),
        configurationNotificationTypes: "",
      };

      return resp;
    }
  },
);

// FORÇA NOVA SENHA - USUÁRIO NOVO --------------------------------------------
export const userNewPassword = createAsyncThunk(
  "user/userNewPassword",
  async (params: {
    email: string;
    loginTmpPassword: string;
    newPassword: string;
  }) => {
    const signInOutput = await Auth.signIn(
      params.email,
      params.loginTmpPassword,
    );
    const user2 = await Auth.completeNewPassword(
      signInOutput,
      params.newPassword,
    );

    const profile = getProfile(user2.challengeParam.userAttributes.profile);
    const resp: SliceState = {
      loginMessage: "",
      loginNewPassword: false,
      loginTmpPassword: "",
      id: user2.username,
      name: user2.challengeParam.userAttributes.name,
      email: user2.challengeParam.userAttributes.email,
      company: user2.challengeParam.userAttributes.family_name,
      facility: user2.challengeParam.userAttributes.given_name,
      profile,
      terms: 0,
      home: getHome(profile),
      homeUrl: getHomeUrl({
        profile,
        company: user2.challengeParam.userAttributes.family_name,
        facility: user2.challengeParam.userAttributes.given_name,
      }),
      isClient: isClient(profile),
      admin: isAdministrator(profile),
      ibbx: isIbbxUser(profile),
      isAnalyst: isAnalyst(profile),
      readonly: isReadOnly(profile),
      configurationNotificationTypes: "",
    };
    return resp;
  },
);

// TROCA SENHA - USUÁRIO EXISTENTE --------------------------------------------
/***********  TERMINAR / TESTAR / CORRIGIR ***************************************/
export const userChangePassword = createAsyncThunk(
  "user/userChangePassword",
  async (params: { oldPassword: string; newPassword: string }) => {
    const authenticated = await Auth.currentAuthenticatedUser();
    if (!authenticated?.err) {
      return Auth.changePassword(
        authenticated,
        params.oldPassword,
        params.newPassword,
      );
    } else {
      throw new Error(authenticated?.err);
    }
  },
);

// RETORNA OS TERMOS DO USUÁRIO: 0 - não aceitou os termos de uso; 1 - aceitou os termos de uso
export const userTerms = createAsyncThunk("user/userTerms", async () => {
  const authUser = await Auth.currentAuthenticatedUser();
  const userAttributes = await Auth.userAttributes(authUser);
  const zoneinfoAttribute = userAttributes.find(
    ({ Name }) => Name === "zoneinfo",
  );

  if (!zoneinfoAttribute) {
    return -1;
  }

  // Verify if user has accpet terms
  const { terms } = JSON.parse(zoneinfoAttribute.Value || "{}");

  return terms ? 1 : 0;
});

// REGISTRA TERMOS DO USUÁRIO: 0 - não aceitou os termos de uso; 1 - aceitou os termos de uso
export const userSetTerms = createAsyncThunk(
  "user/userSetTerms",
  async (terms: boolean) => {
    if (!terms) {
      return 0;
    }

    const session = await Auth.currentSession();
    const userId = session.getIdToken().payload.sub;

    const path = `/users/${userId}/accept-terms`;

    await new Promise((resolve, reject) => {
      API.put(apiRetinaCustomer, path, {})
        .then(() => {
          resolve(1);
        })
        .catch(async (error) => {
          await notifyDiscordError({
            title: `Erro na API`,
            description: path,
          });
          reject(error);
        });
    });

    return 1;
  },
);

// LOGOUT --------------------------------------------
export const userLogout = createAsyncThunk("user/userLogout", async () => {
  await Auth.signOut();
});

// Cria o slice de usuário
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserSlice: (state, action: PayloadAction<any>) => {
      state.loginMessage = action.payload.loginMessage || "";
      state.loginNewPassword = action.payload.loginNewPassword || false;
      state.loginTmpPassword = action.payload.loginTmpPassword || "";
      state.id = action.payload.id || "";
      state.name = action.payload.name || "";
      state.email = action.payload.email || "";
      state.company = action.payload.company || "";
      state.facility = action.payload.facility || "";
      state.profile = action.payload.profile || "";
      state.terms = action.payload.terms;
      state.home = action.payload.home || "";
      state.homeUrl = action.payload.homeUrl || "/";
      state.isClient = action.payload.isClient || false;
      state.admin = action.payload.admin || false;
      state.ibbx = action.payload.ibbx || false;
      state.isAnalyst = action.payload.isAnalyst || false;
      state.readonly = action.payload.readonly || false;
      state.retinaVersion = action.payload.retinaVersion || "";
    },
    userFn1: (state) => {
      state.id = "dummy";
    },
    userFn2: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setUserDashboardsPreferences: (state, action: PayloadAction<any>) => {
      state.dashboardsPreference = action.payload;
    },
    setUserHomeUrl: (state, action: PayloadAction<string>) => {
      state.homeUrl = action.payload;
    },
    setUserHome: (state, action: PayloadAction<string>) => {
      state.home = action.payload;
    },
    setUserRetinaVersion: (state, action: PayloadAction<string>) => {
      state.retinaVersion = action.payload;
    },

    setProfileNotification: (state, action: PayloadAction<string>) => {
      state.configurationNotificationTypes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // userSession ----------------------------------------------------------------------
      .addCase(userSession.fulfilled, (state, action) => {
        state.loginMessage = action.payload.loginMessage;
        state.loginNewPassword = action.payload.loginNewPassword;
        state.loginTmpPassword = action.payload.loginTmpPassword;
        state.id = action.payload.id;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.company = action.payload.company;
        state.facility = action.payload.facility;
        state.profile = action.payload.profile;
        state.terms = action.payload.terms;
        state.home = getHome(action.payload.profile);
        state.homeUrl = getHomeUrl({
          profile: action.payload.profile,
          company: action.payload.company,
          facility: action.payload.facility,
        });
        state.isClient = action.payload.isClient;
        state.admin = action.payload.admin;
        state.ibbx = action.payload.ibbx;
        state.isAnalyst = action.payload.isAnalyst;
        state.readonly = action.payload.readonly;
        state.retinaVersion = action.payload.retinaVersion;
      })

      // userLogin ----------------------------------------------------------------------
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loginMessage = action.payload.loginMessage;
        state.loginNewPassword = action.payload.loginNewPassword;
        state.loginTmpPassword = action.payload.loginTmpPassword;
        state.id = action.payload.id;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.company = action.payload.company;
        state.facility = action.payload.facility;
        state.profile = action.payload.profile;
        state.terms = action.payload.terms;
        state.home = action.payload.home;
        state.homeUrl = action.payload.homeUrl;
        state.isClient = action.payload.isClient;
        state.admin = action.payload.admin;
        state.ibbx = action.payload.ibbx;
        state.isAnalyst = action.payload.isAnalyst;
        state.readonly = action.payload.readonly;
        state.retinaVersion = action.payload.retinaVersion;
      })
      .addCase(userLogin.rejected, (state, action) => {
        error(formatterLoginErrorMessage(action.error?.message));
      })

      // userNewPassword ----------------------------------------------------------------------
      .addCase(userNewPassword.fulfilled, (state, action) => {
        state.loginMessage = action.payload.loginMessage;
        state.loginNewPassword = action.payload.loginNewPassword;
        state.loginTmpPassword = action.payload.loginTmpPassword;
        state.id = action.payload.id;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.company = action.payload.company;
        state.facility = action.payload.facility;
        state.profile = action.payload.profile;
        state.terms = action.payload.terms;
        state.home = action.payload.home;
        state.homeUrl = action.payload.homeUrl;
        state.isClient = action.payload.isClient;
        state.admin = action.payload.admin;
        state.ibbx = action.payload.ibbx;
        state.isAnalyst = action.payload.isAnalyst;
        state.readonly = action.payload.readonly;
        state.retinaVersion = action.payload.retinaVersion;
      })

      // userTerms ----------------------------------------------------------------------
      .addCase(userTerms.fulfilled, (state, action) => {
        state.terms = action.payload;
      })

      // userSetTerms ----------------------------------------------------------------------
      .addCase(userSetTerms.fulfilled, (state, action) => {
        state.terms = action.payload;
      })

      // userLogout ----------------------------------------------------------------------
      .addCase(userLogout.fulfilled, (state) => {
        state.loginMessage = "";
        state.id = "";
        state.name = "";
        state.email = "";
        state.company = "";
        state.facility = "";
        state.profile = "";
        state.terms = -1;
        state.home = "";
        state.homeUrl = "/";
        state.isClient = false;
        state.readonly = false;
        state.isAnalyst = false;
        state.admin = false;
        state.retinaVersion = "";
      });
  },
});

export const userSelect = (state: RootState) => state.user;

export const {
  setUserSlice,
  setUserHome,
  setUserHomeUrl,
  setUserRetinaVersion,
  setUserDashboardsPreferences,
  setProfileNotification,
} = userSlice.actions;

export default userSlice.reducer;
