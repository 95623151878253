import { getSerieName } from "../../../utils/charts/general";
import { AxisDiv, ButtonAxis } from "./styles";
import { useTranslation } from "react-i18next";
import { ISerieControl } from "../../../hooks/useSeriesControl";
import { IChart } from "../../../store/interfaces";

type AxisSelectorProps = {
  series: string[];
  selectedSerie: string;
  setSelectedSerie: (serie: string) => void;
  seriesControl?: ISerieControl[];
  chart: IChart;
  noColors?: boolean;
};

function AxisSelector({
  series,
  setSelectedSerie,
  seriesControl = [],
  selectedSerie,
  chart,
  noColors = false,
}: AxisSelectorProps) {
  const { t } = useTranslation();

  function getColor(serie: string) {
    return seriesControl.find(({ name }) => name === serie)?.color;
  }

  return (
    <AxisDiv>
      <div>
        <label>{t("commonText.axes")}</label>
      </div>
      <div>
        {seriesControl
          .filter(
            (serie) =>
              !serie.hidden &&
              serie.name !== "Limite" &&
              serie.name !== "Extremo",
          )
          .map((serie, index) => (
            <>
              <ButtonAxis
                key={index}
                selected={serie.name === selectedSerie}
                color={getColor(serie.name)}
                onClick={() => setSelectedSerie(serie.name)}
                noColors={noColors}
              >
                <span />
                <label>{getSerieName(serie.name, chart)}</label>
              </ButtonAxis>
            </>
          ))}
      </div>
    </AxisDiv>
  );
}

export default AxisSelector;
