import styled from "styled-components";

export const FrameFiltersMapsDiv = styled.button<{ hide?: boolean }>`
  background-color: transparent;
  color: ${(props) => props.theme.colors.iconsSecondary};
  width: 28px;
  height: 28px;
  display: ${(props) => (props.hide ? "none" : "flex")};
  border: none;
  margin-left: 0.1rem;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;

  .spinner {
    width: 100%;
    display: flex;
  }

  svg path {
    stroke: ${(props) => props.theme.colors.iconsSecondary};
  }

  svg {
    z-index: 10;
  }
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    span:first-child {
      display: none;
    }
    padding: 4px;
    svg {
      width: 1.5rem;
      height: auto;
      padding: 2px;
    }
  }

  @media screen and (max-width: 767px) {
    border: none;
    background: transparent;
    svg {
      width: 1.4rem;
      height: auto;
      padding: 2px;
    }
  }
`;

export const FrameMapsIcon = styled.div``;

export const FrameMapsText = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: 3px;

  @media screen and (max-width: 767px) {
    display: none;
  }
  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
  }
  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
    display: none;
  }
`;
