import styled, { css } from "styled-components";
import { ToogleShowHideSidebar } from "../SidebarConfiguration/styles";

export const Container = styled.div<{ assetList?: boolean }>`
  position: relative;
  background-color: ${(props) => props.theme.colors.headerBackground};

  & > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 0px;
    width: 42px;
    height: 42px;
    z-index: 2;
    background-color: transparent;
    
    &:hover {
      border-radius: 50%;
    }

    svg {
      color: ${(props) => props.theme.colors.iconsSecondary};
     
    }
  }

  ${(props) =>
    props.assetList &&
    css`
      @media screen and (max-width: 767px) {
        & > div:first-child {
          top: 11px;
          left: 1px;
          background-color: transparent;

          &:hover {
            background-color: ${(props) => props.theme.colors.cardBackground};
          }
        }
      }
    `}
`;

export const FilterContainers = styled.div<{
  isDashboard?: boolean;
  assetList?: boolean;
}>`
  position: absolute;
  height: 90vh;
  width: 90vw;
  border-top-right-radius: 6px;
  display: flex;
  justify-content: center;
  z-index: 14;
  top: ${({ isDashboard }) => (isDashboard ? "-14px" : "3.8rem")};
  background-color: ${(props) => props.theme.colors.headerBackground};

  ${(props) =>
    props.assetList &&
    css`
      @media screen and (max-width: 767px) {
      }
    `}

  @media screen and (max-width: 767px) {
    height: 100vh;
    width: 20rem;
  }
  

  & span{
    ${(props) => ({ ...props.theme.fonts.secondary.H2Regular })};
    color: ${(props) => props.theme.colors.iconsSecondary};
    margin-bottom: -5px;
    margin-left: 6px;
    opacity: 0.6;
    width: fit-content;
    &:not(:first-child) {
      margin-top: 0.2px;
    }
  }
`;

export const ToogleShowHideSidebarMobile = styled(ToogleShowHideSidebar)<{
  isOpen?: boolean;
}>`
  transition: none;
  z-index: 12;
  width: 30px;
  height: 30px;
  right: 1rem;
  border-radius: 50%;
  box-shadow: none;
  background-color: transparent;


  svg {
    width: 2rem;
    height: auto;
  }

  &:hover {
    scale: 0.98;
    width: 30px;
    height: 30px;
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding-top: 55px;
  



  @media screen and (max-width: 767px) {
    & > div{  
    }

    div:first-child{
  
}
    
    >div:first-child {
      font-size: 12px !important;

    }
    & > div > div > div  {
     border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha}!important ;
    }

    & > div  {
    
      .css-qkaff8-menu,
      .css-sd6yzx-menu{
        width: 100%;
        margin: 2px 0 0 0;
        transform: translateX(-4px);
      }
     
   }

  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  right: -14rem;

`;


export const AddButton = styled.button`
  background: ${(props) => props.theme.colors.ibbxOrange};
  position: fixed;
  height: 3.5rem;
  width: 3.5rem;
  right: 1.2rem;
  border: none;
  border-radius: 100%;
  top: 44.5rem;
  z-index: 20;

  svg {
    color: white;
    height: 2.5rem;
    width: 2.5rem;
  }
`;

export const GroupingSelectArea = styled.div`
  ${({ theme }) => theme.fonts.primary.H4Bold};
  color: ${({ theme }) => theme.colors.iconsPrimary};
  padding: 0 12px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  gap: 7px;
  width: 220px;
  span {
    ${({ theme }) => theme.fonts.secondary.H1BoldCard};
  }

  button {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover {
      filter: brightness(0.6);
      transition: filter 0.1s ease-in-out;
    }
  }

  svg {
    width: 22px;
    height: auto;
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
  @media screen and (max-width: 767px) {
    height: auto;

    svg {
      width: 28px;
      height: auto;
    }
  }
`;
