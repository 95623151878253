import styled from "styled-components";
import lightTheme from "../../../../styles/themes/light";

export const UsersActionContainer = styled.div`
  margin-bottom: 20px;
`;

export const UsersContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const TableWrapper = styled.div`
  margin-top: 10px;
  height: 100%;
  overflow: auto;

  .table {
    --bs-table-bg: ${props => props.theme.theme};
  }

  & > div {
    min-height: 240px;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => lightTheme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => lightTheme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => lightTheme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => lightTheme.colors.iconsSecondary};
  }

  & > div {
    tbody > tr > td {
      ${lightTheme.fonts.secondary.H1Regular};
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
    tbody > tr > td:last-child {
      width: 110px;
    }
  }

  @media screen and (max-width: 767px) {
    &::-webkit-scrollbar {
      display: none;
    }
    overflow-y: auto;
    scrollbar-width: none;
    margin-top: 24px;
  }
`;

export const ColumnHeader = styled.div`
  margin: 8px 0 4px 0;
  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H5Bold};

  & > span {
    display: flex;
    align-items: center;
  }
`;

type UserStatusProps = {
  active?: boolean;
};

export const UserStatus = styled.div<UserStatusProps>`
  background-color: ${(props) =>
    props.active
      ? lightTheme.colors.statusNormal
      : lightTheme.colors.colorTools};
  border-radius: 28px;
  padding: 5px 10px;
  width: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    ${(props) => props.theme.fonts.secondary.H1Bold};
    text-transform: uppercase;
    color: #fff;
  }

  @media screen and (max-width: 767px) {
    width: 4.5rem;
  }
`;
