import { t } from "i18next";

export const formatterLoginErrorMessage = (
  message: string | undefined,
): any => {
  if (message === "InvalidParameterException") {
    return t("login.Formato de senha inválido.");
  }

  if (message === "Incorrect username or password.") {
    return t("login.Email ou senha inválidos.");
  }

  if (message === "InvalidPasswordException") {
    return t(
      "login.A senha precisa ter no mínimo 8 caracteres, formada por números, letras maiúsculas, minúsculas e caracteres especiais.",
    );
  }

  if (message === "NotAuthorizedException") {
    return t("login.Senha Atual Incorreta!");
  }

  if (message === "Password attempts exceeded") {
    return t("login.Tentativas de senha excedidas");
  }

  if (message === "LimitExceededException") {
    return t("login.Limite de troca de senha excedido!");
  }

  if (
    message ===
    "Temporary password has expired and must be reset by an administrator."
  ) {
    return t("login.Senha temporária expirada.");
  }

  if (
    message ===
    "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+"
  ) {
    return t("login.Há caracteres de espaço no campo de e-mail.");
  }

  return message;
};
