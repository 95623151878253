import { ReactNode } from "react";
import { Container, IconButton } from "./styles";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { NamePlusTooltip } from "../../../../components/NamePlusTooltip";

interface NotificationButtonProps {
  icon: ReactNode;
  typeTitle?: "Email" | "WhatsApp" | "Push";
  hasTitle?: boolean;
  disabled?: boolean;
  active?: boolean;
  buttonName?: string;
  handleClick?: () => void;
  children?: ReactNode;
  hint?: any;
  blocked?: boolean;
}

export function NotificationButton({
  icon,
  typeTitle,
  hasTitle,
  disabled,
  active,
  buttonName,
  handleClick,
  children,
  hint,
  blocked,
}: NotificationButtonProps) {
  return (
    <Container hasHint={hint}>
      <NamePlusTooltip tooltip={hint} position="top">
        <IconButton
          disabled={disabled}
          active={active}
          name={buttonName}
          onClick={handleClick}
          blocked={blocked}
        >
          {icon}
          <span>{children}</span>
        </IconButton>
      </NamePlusTooltip>
    </Container>
  );
}
