import { IChart } from "../../store/interfaces";
import { dynamicSelectDateModel } from "../Position";

const chartList: IChart[] = [
  {
    positionId: 0,
    name: "T",
    label: "Temperatura",
    type: "GLOBAL",
    series: ["1", "Z"],
    seriesName: ["Temperatura"],
    metricUnits: { x: "s", y: "ºC" },
  },
  {
    positionId: 0,
    name: "AC-P",
    label: "Aceleração Pico da FFT",
    type: "GLOBAL",
    series: ["X", "Y", "Z"],
    seriesName: ["Horizontal", "Vertical", "Axial"],
    metricUnits: { x: "date", y: "g" },
  },
  {
    positionId: 0,
    name: "ENV-P",
    label: "Envelope Pico da FFT",
    type: "GLOBAL",
    series: ["X", "Y", "Z"],
    seriesName: ["Horizontal", "Vertical", "Axial"],
    metricUnits: { x: "date", y: "gE" },
  },
  {
    positionId: 0,
    name: "SINAL-BRUTO-AC",
    label: "Time Wave Form (g)",
    type: "DYNAMIC",
    series: ["X", "Y", "Z"],
    seriesName: ["Horizontal", "Vertical", "Axial"],
    metricUnits: { x: "s", y: "g" },
  },
  {
    positionId: 0,
    name: "FFT-AC",
    label: "Aceleração FFT",
    type: "DYNAMIC",
    series: ["X", "Y", "Z"],
    seriesName: ["Horizontal", "Vertical", "Axial"],
    metricUnits: { x: "Hz", y: "g" },
  },
  {
    positionId: 0,
    name: "AC-RMS",
    label: "Aceleração RMS",
    type: "GLOBAL",
    series: ["X", "Y", "Z"],
    seriesName: ["Horizontal", "Vertical", "Axial"],
    metricUnits: { x: "date", y: "g" },
  },
  {
    positionId: 0,
    name: "FFT-V",
    label: "Velocidade FFT",
    type: "DYNAMIC",
    series: ["X", "Y", "Z"],
    seriesName: ["Horizontal", "Vertical", "Axial"],
    metricUnits: { x: "Hz", y: "mm/s" },
  },
  {
    positionId: 0,
    name: "FFT-ENV",
    label: "Envelope FFT",
    type: "DYNAMIC",
    series: ["X", "Y", "Z"],
    seriesName: ["Horizontal", "Vertical", "Axial"],
    metricUnits: { x: "Hz", y: "gE" },
  },
  {
    positionId: 0,
    name: "AC-GLOBAL",
    label: "Aceleração Global",
    type: "GLOBAL",
    series: ["X", "Y", "Z"],
    seriesName: ["Horizontal", "Vertical", "Axial"],
    metricUnits: { x: "date", y: "g" },
  },
  {
    positionId: 0,
    name: "V-GLOBAL",
    label: "Velocidade Global",
    type: "GLOBAL",
    series: ["X", "Y", "Z"],
    seriesName: ["Horizontal", "Vertical", "Axial"],
    metricUnits: { x: "date", y: "mm/s" },
  },
  {
    positionId: 0,
    name: "ENV-GLOBAL",
    label: "Envelope Global",
    type: "GLOBAL",
    series: ["X", "Y", "Z"],
    seriesName: ["Horizontal", "Vertical", "Axial"],
    metricUnits: { x: "date", y: "gE" },
  },
  {
    positionId: 0,
    name: "CORR-INST",
    label: "Corrente Instantânea",
    type: "GLOBAL",
    series: ["1", "2", "3"],
    seriesName: ["A", "B", "C"],
    metricUnits: { x: "date", y: "A" },
  },
];

export function getChartMocked(chartName: string) {
  return chartList.find(({ name }) => name === chartName);
}

export type AnalysisProps = {
  loadedChart: any;
  dynamicDateStart: dynamicSelectDateModel | null | undefined;
  handleCloseModal: () => void;
  chart: IChart;
  absolute?: boolean;
  viewMode: "LINE" | "COLUMN";
  loadedPeriod?: string;
};

export type analysisModel = {
  zoom?: boolean;
  cross?: boolean;
  noiseFilter?: number;
  commentMode?: boolean;
  harmonics?: harmonicsModel;
  phasesAnalysis?: boolean;
  cascade?: boolean;
  bearingStamps?: bearingStampsModel;
  gearing?: any;
  temperatureCorrelation?: boolean;
  twfFrequency?: boolean;
  regression?: boolean;
};

export type bearingOption = "GAIOLA" | "BPFI" | "BPFO" | "BSF";
export type bearingStampsModel = {
  stamps: any;
  harmonics: any;
  visible: bearingOption[];
};

export type harmonicsModel = {
  active: boolean;
  fundamental: number;
  harmonics: number;
  order: number;
};

export const harmonicsEmpty: harmonicsModel = {
  active: false,
  fundamental: 0,
  harmonics: 0,
  order: 1,
};

export const bearingsEmpty: any = {
  active: false,
  stamps: [],
};
