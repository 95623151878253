import { useState } from "react";
import { Modal } from "../../components/Modal";
import { RatingButtons } from "../../components/RatingButtons";
import { Comments, Container, ErrorLabel, Question, Title } from "./styles";
import { ConfirmOccurrenceCloseTypes } from "./types";
import { useTranslation } from "react-i18next";
import { useSegment } from "../../hooks/useSegmentTrackEvent ";

export const ConfirmOccurrenceClose = ({
  show,
  handleClose,
  action,
  occurrence,
  handleConfirm,
  refresh,
}: ConfirmOccurrenceCloseTypes) => {
  const { handleTrackEvent } = useSegment();

  const [comments, setComments] = useState("");
  const [selectedDiagnostic, setSelectedDiagnostic] = useState<any>("");
  const [selectedIntervention, setSelectedIntervention] = useState<any>("");
  const [errorOnSubmit, setErrorOnSubmit] = useState<any>("");

  const isCorrective = occurrence.occurrenceType === "CORRECTIVE";

  const handleConfirmOccurrence = () => {
    if (!isCorrective && (!selectedDiagnostic || !selectedIntervention)) {
      setErrorOnSubmit(`${t("ConfirmOccurrenceClose.submitError")}`);
    } else {
      setErrorOnSubmit("");
      handleConfirm(comments, {
        likedDiagnostic: selectedDiagnostic === "LIKE" ? true : false,
        hadIntervention: selectedIntervention === "LIKE" ? true : false,
      });
    }

    handleTrackEvent({
      event: "PAINEL OCORRÊNCIA: Confirmar Fechar",
    });
  };

  const { t } = useTranslation();

  return (
    <Modal
      size="lg"
      show={show}
      handleClose={handleClose}
      handleConfirm={() => handleConfirmOccurrence()}
      confirmLabel={action || `${t("commonText.confirm")}`}
    >
      <Container>
        <Title>{t("closeForm.title")}</Title>
        <div>
          <div>
            {!isCorrective && (
              <Question>
                <label>{t("closeForm.isValidDiagnostic")}</label>
                <RatingButtons
                  selected={selectedDiagnostic}
                  setSelected={setSelectedDiagnostic}
                  occurrenceId={occurrence?.id}
                />
              </Question>
            )}
            {!isCorrective && (
              <Question>
                <label>{t("closeForm.hadIntervention")}</label>
                <RatingButtons
                  selected={selectedIntervention}
                  setSelected={setSelectedIntervention}
                  occurrenceId={occurrence?.id}
                />
              </Question>
            )}
          </div>

          <Comments>
            <label>{t("ocurrenceRegisterModal.observation")}</label>
            <textarea
              spellCheck={false}
              rows={5}
              cols={40}
              placeholder={"..."}
              onChange={(e) => setComments(e?.target?.value)}
              value={comments}
            />
          </Comments>
          {errorOnSubmit !== "" && <ErrorLabel>{errorOnSubmit}</ErrorLabel>}
        </div>
      </Container>
    </Modal>
  );
};
