import styled, { keyframes } from "styled-components";
import { ReactComponent as LeafImage } from "../../../images/leaf.svg";

type LeafProps = {
  left?: number;
  duration?: number;
};

const LeafAnimation = keyframes`
  0% {
    transform: translateY(-50px) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: translateY(100vh) rotate(360deg);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) rotate(720deg);
    opacity: 0;
  }
`;

const LeafStyle = styled.div<LeafProps>`
  position: absolute;
  top: -50px;
  left: ${(props) => `${props.left}%`};
  animation: ${LeafAnimation} ${(props) => `${props.duration}s`} linear infinite;
  transform-origin: center center;
  opacity: 1;

  svg {
    width: 1.5rem;
    height: auto;

    @media screen AND (min-width: 1920px) {
      width: 2rem;
    }
  }
`;

export function Leaf({ left, duration }: LeafProps) {
  return (
    <LeafStyle left={left} duration={duration}>
      <LeafImage />
    </LeafStyle>
  );
}
