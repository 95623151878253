import styled from "styled-components";

export const FormHarmonicTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 97.5%;
  margin: 0px 0 20px;
  ${(props) => ({ ...props.theme.fonts.primary.H2Bold })};
`;

export const FormInputType = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  & > label {
    ${(props) => props.theme.fonts.primary.H4Regular};
    color: ${(props) => props.theme.colors.iconsPrimary};
    margin-bottom: 8px;
  }
  div {
    display: flex;
    align-items: flex-end;
    gap: 15px;
    input {
      font-size: 13px;
      color: ${(props) => props.theme.colors.ibbxOrange};
    }
    label {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: flex-end;
      ${(props) => props.theme.fonts.primary.H5SemiBold};
      color: ${(props) => props.theme.colors.iconsPrimary};
    }
  }
  .form-check-input:checked {
    background-color: ${(props) => props.theme.colors.ibbxOrange};
    border-color: ${(props) => props.theme.colors.ibbxOrange};
  }
  .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(243, 112, 53, 0.25);
  }
`;

export const FormHarmonic = styled.form`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 0;
  border: none;
  gap: 21px;
  padding: 0px;

  color: ${(props) => props.theme.colors3.color0};
  ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    & > div:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const LabelHours = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors3.color0};
  flex-direction: column;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;

  label {
    margin: 0 0 6px 16px;
    width: 100%;
  }

  input {
    padding: 6px;
    width: 100%;
    height: 34px;
    outline: 0;
    border: none;
    border-radius: 4px;
    color: ${(props) => props.theme.colors3.color0};
    background: ${(props) => props.theme.colors.background};
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;
