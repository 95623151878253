import styled from "styled-components";

export const InfoButtonContainer = styled.button<{ hasValue?: boolean }>`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  border: none;
  color: ${(props) => (props.hasValue ? "green" : "red")};
  opacity: 0.6;

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 60px;
    cursor: pointer;
  }
  // Disable inside clickable elements
  & > * {
    pointer-events: none;
  }

  svg {
    font-size: 16px;
  }

  @keyframes oscilate {
    0% {
      transform: scale(0.95);
    }
    50% {
      opacity: 1;
      transform: scale(1.05);
      color: red;
    }
    100% {
      transform: scale(0.8) 5;
    }
  }

  @media screen and (max-width: 767px) {
    width: 20px;
    height: 20px;
  }
`;
