import styled from "styled-components";
import { ButtonSave } from "../../../../components/Modal/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px 40px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;

  @media screen and (max-width: 767px) {
    justify-content: center;
  } ;
`;

export const Button = styled(ButtonSave)`
  @media screen and (max-width: 767px) {
    width: 80%;
    height: 2.5rem;
  } ;
`;
