import { useRef } from "react";
import { Form, Formik, FormikProps } from "formik";
import { Modal } from "../../Modal";
import { FormikTextInput } from "../../textInput";
import { ResourceNameModalProps } from "./types";
import { useTranslation } from "react-i18next";

function ResourceNameModal({
  show,
  handleClose,
  handleConfirm,
}: ResourceNameModalProps) {
  const formRef = useRef<FormikProps<any>>(null);
  function handleSubmit(values: any) {
    handleConfirm(values?.label || "");
  }

  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleConfirm={() => formRef.current?.handleSubmit()}
    >
      <Formik innerRef={formRef} initialValues={{}} onSubmit={handleSubmit}>
        <Form>
          <FormikTextInput
            name="label"
            label={`${t("ResourceNameModal.name")}`}
          />
        </Form>
      </Formik>
    </Modal>
  );
}

export default ResourceNameModal;
