import { useState, useRef, useEffect } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { StandardButtonEvidence } from "../styles";
import { AssetOccurrence } from "../../../pages/Occurrence/types";
import useApi from "../../../hooks/useApi";
import { useSegment } from "../../../hooks/useSegmentTrackEvent ";
import ModalCompound from "../../ModalCompound";
import { Formik } from "formik";
import { FormikTextInput } from "../../textInput";
import { useTranslation } from "react-i18next";
import { DateInput } from "../../DateInput";
import FormSelect from "../../FormSelect";
import useFilters from "../../../hooks/useFilters";
import {
  EvidenceForm,
  EvidenceFormRow,
  EvidenceImageContainer,
} from "./styles";
import { useParams } from "react-router-dom";

type AddEvidenceButtonProps = {
  occurrence: AssetOccurrence;
  refresh?: () => void;
};

export function AddEvidenceButton({
  occurrence,
  refresh = () => null,
}: AddEvidenceButtonProps) {
  const { handleTrackEvent } = useSegment();
  const { t } = useTranslation();

  const [modalLabel, setModalLabel] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [image64, setImage64] = useState<any>();
  const inputRef = useRef<HTMLInputElement>(null);

  const [position, setPosition] = useState<any>(null);

  const { assetId, occurrenceId } = useParams();

  const { request } = useApi({
    path: `/assets/${assetId}/occurrences/${occurrenceId}/evidences`,
  });

  const { loadPositionOptions, positionOptions } = useFilters({
    assetId: occurrence.assetId,
  });

  const sysStatusOptions = [
    { value: 1, label: t("dictionary.status.Normal") },
    { value: 3, label: t("dictionary.alarms.Limit") },
    { value: 5, label: t("dictionary.alarms.Extreme") },
  ];

  useEffect(() => {
    if (occurrence.assetId) {
      loadPositionOptions(true);
    }
    // eslint-disable-next-line
  }, [occurrence.assetId]);

  async function formatImageFile(file: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage64(reader.result);
    };
  }

  async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    if (!event.target || !event.target.files || !event.target.files[0]) {
      return;
    }

    setFile(event.target.files[0]);

    formatImageFile(event.target.files[0]);

    setModalLabel(true);
  }

  async function handleSaveFile(values: any) {
    if (!file) {
      return;
    }

    const body: any = { type: file.type, ...values };
    if (!body.sysStatusId) {
      delete body.sysStatusId;
    }

    try {
      setModalLabel(false);
      if (file.type === "application/pdf") {
        const buffer = await file.arrayBuffer();

        body.data = await btoa(
          new Uint8Array(buffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            "",
          ),
        );
      } else {
        body.data = image64;
      }

      request({ method: "post", body })
        .then(() => {
          refresh();
          clearInput();
        })
        .catch(() => {
          clearInput();
        });
    } catch {
      setModalLabel(false);
    }
  }

  function clearInput() {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setFile(null);
    setImage64(undefined);
  }

  function handleCloseModalLabel() {
    clearInput();
    setModalLabel(false);
    setPosition(null);
  }

  const formRef = useRef<any>(null);

  function handleSubmit(values: any) {
    handleSaveFile(values);
  }

  function handlePositionChange(e: any) {
    formRef.current?.setFieldValue("positionId", e?.value);
    setPosition(e);

    if (!e?.value) {
      setPosition(null);
    }
  }

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <StandardButtonEvidence
        addEvidence
        title="Adicionar Evidência"
        type="button"
        onClick={() => {
          inputRef.current?.click();
          handleTrackEvent({
            event: "PAINEL OCORRÊNCIA - Add Evidência",
          });
        }}
      >
        <span>{t("BoltForm.formAddBolt")}</span>
        <MdAddCircleOutline />
      </StandardButtonEvidence>

      {modalLabel && (
        <ModalCompound show={!!modalLabel} handleClose={handleCloseModalLabel}>
          <ModalCompound.Body>
            <Formik
              innerRef={formRef}
              initialValues={{ label: "", date: new Date() }}
              onSubmit={handleSubmit}
            >
              <EvidenceForm>
                <h1>{`${t("AddEvidence.Nova Evidência")}`}</h1>
                <EvidenceImageContainer>
                  <img height="180" src={image64} alt="evidence"></img>
                </EvidenceImageContainer>

                <FormikTextInput
                  name="label"
                  label={`${t("dictionary.labels.Name")}`}
                  placeholder={`${t("dictionary.labels.Name")}`}
                />
                <EvidenceFormRow>
                  <DateInput
                    name="date"
                    label={`${t("dictionary.labels.Date")}`}
                    placeholder={`${t("dictionary.labels.Date")}`}
                  />
                  <FormSelect
                    name="positionId"
                    label={`${t("dictionary.labels.Position")}`}
                    placeholder={`${t("dictionary.labels.Position")}`}
                    options={positionOptions}
                    handleChange={handlePositionChange}
                  />
                </EvidenceFormRow>

                {position && (
                  <EvidenceFormRow>
                    <FormSelect
                      name="sysStatusId"
                      label={`${t("dictionary.labels.Status")}`}
                      placeholder={`${t("dictionary.labels.Status")}`}
                      options={sysStatusOptions}
                      handleChange={(e: any) =>
                        formRef.current?.setFieldValue("sysStatusId", e?.value)
                      }
                    />
                    <FormSelect
                      name="chartName"
                      label={`${t("dictionary.labels.Chart")}`}
                      placeholder={`${t("dictionary.labels.Chart")}`}
                      options={position?.charts || []}
                      handleChange={(e: any) =>
                        formRef.current?.setFieldValue("chartName", e?.value)
                      }
                    />
                  </EvidenceFormRow>
                )}
              </EvidenceForm>
            </Formik>
          </ModalCompound.Body>
          <ModalCompound.Footer>
            <ModalCompound.Cancel handleClose={handleCloseModalLabel} />
            <ModalCompound.Save
              handleConfirm={() => formRef.current?.handleSubmit()}
            />
          </ModalCompound.Footer>
        </ModalCompound>
      )}
    </>
  );
}
