import { ReactNode } from "react";
import { Container, Input } from "./styles";

interface IInputRadio {
  label?: ReactNode;
  checked?: boolean;
  value?: string | number | readonly string[] | undefined;
  onChange?: () => void;
  handleClick?: () => void;
  disabled?: boolean;
  id?: string;
}

export function InputRadio({
  label,
  checked,
  onChange,
  handleClick,
  disabled,
  id,
  value,
}: IInputRadio) {
  return (
    <Container disabled={disabled}>
      <Input
        type="radio"
        checked={checked}
        onChange={onChange}
        id={id}
        value={value}
        disabled={disabled}
      />
      <label onClick={handleClick}>{label}</label>
    </Container>
  );
}
