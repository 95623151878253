import { useEffect, useState } from "react";
import { Loading } from "../../../../components/Loading";
import useApi from "../../../../hooks/useApi";
import { CardTotalReport, Container, RepresentativeDataReport } from "./styles";
import { RepresentativeSummaryProps, SUMMARY } from "./types";
import { useTranslation } from "react-i18next";

const RepresentativeSummary = ({
  representative,
}: RepresentativeSummaryProps) => {
  const { request, processing } = useApi({
    path: `/representatives/${representative?.id}/summary`,
  });

  const [summary, setSummary] = useState<SUMMARY | null>(null);

  useEffect(() => {
    if (representative?.id) {
      request({ method: "get" }).then((response) => setSummary(response));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [representative]);

  const { t } = useTranslation();

  return (
    <Container>
      {processing ? (
        <Loading />
      ) : (
        <>
          <RepresentativeDataReport>
            <CardTotalReport>
              <div>{summary?.totalCompanies}</div>
              <div>
                <span>{t("Representatives.Summary.companies")}</span>
                &nbsp;
              </div>
            </CardTotalReport>
            <CardTotalReport>
              <div>{summary?.totalFacilities}</div>
              <div>
                <span>{t("Representatives.Summary.facilities")}</span>&nbsp;
              </div>
            </CardTotalReport>
            <CardTotalReport>
              <div>{summary?.totalAssets}</div>
              <div>
                <span>{t("Representatives.Summary.assets")}</span>&nbsp;
              </div>
            </CardTotalReport>
          </RepresentativeDataReport>
          <RepresentativeDataReport>
            <CardTotalReport>
              <div>{summary?.totalPositions}</div>
              <div>
                <span>
                  {t("Representatives.Summary.position")}
                  <br />
                  {t("Representatives.Summary.registered")}
                </span>
                &nbsp;
              </div>
            </CardTotalReport>
            <CardTotalReport>
              <div>{summary?.totalPositionsActivated}</div>
              <div>
                <span>
                  {t("Representatives.Summary.position")}
                  <br />
                  {t("Representatives.Summary.activated")}
                </span>
                &nbsp;
              </div>
            </CardTotalReport>
            <CardTotalReport>
              <div>{summary?.totalPositionsConnected}</div>
              <div>
                <span>
                  {t("Representatives.Summary.position")}
                  <br />
                  {t("Representatives.Summary.connected")}
                </span>
                &nbsp;
              </div>
            </CardTotalReport>
          </RepresentativeDataReport>
        </>
      )}
    </Container>
  );
};

export default RepresentativeSummary;
