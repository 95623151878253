import { ToastContainer, toast as toastFunction } from "react-toastify";
import styled from "styled-components";

export const Toast = () => {
  return <ToastComponent position="top-right" />;
};

//https://material-react-toastify.netlify.app/how-to-style/
const ToastComponent = styled(ToastContainer)`
  z-index: 10001;
  .Toastify__close-button {
    color: ${(props) => props.theme.colors.textPrimary};
  }

  & > div {
    background-color: ${(props) => props.theme.colors.cardBackground};
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  }

  div {
    color: ${(props) => props.theme.colors.textPrimary};
    ${(props) => props.theme.fonts.primary.H4Regular};
  }
`;

export const error = (message: string | undefined) => {
  toastFunction.error(message || "Algo deu Errado!");
};

export const success = (message: string | undefined) => {
  toastFunction.success(message || "Salvo com sucesso!", { autoClose: 2000 });
};

export const warning = (message: string | undefined) => {
  toastFunction.warning(message || "Alerta!", { autoClose: 2000 });
};

export const toast = (message: string, type?: string) => {
  if (
    type === "warning" ||
    type === "error" ||
    type === "success" ||
    type === "info"
  ) {
    toastFunction[type](message);
  } else {
    toastFunction(message);
  }
};
