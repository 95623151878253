import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { useState, memo, useMemo } from "react";

import { LineChartProps } from "./types";
import { TableHarmonics } from "../../TableHarmonics";
import { ChartArea, ContainerChart, TableArea, TableButton } from "./styles";
import { eventCallbacksArgs } from "../chart/types";
import { eventAnimation } from "../../../utils/charts/chartEvents";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { NamePlusTooltip } from "../../NamePlusTooltip";
import { useTranslation } from "react-i18next";

const LineChartFunction = ({
  data,
  options,
  chartEvents = [],
  animated,
  harmonics,
  chart,
}: LineChartProps) => {
  const [hovered, setHovered] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const { t } = useTranslation();

  const events: ReactGoogleChartEvent[] = useMemo(() => {
    if (animated) {
      const animationEvent: ReactGoogleChartEvent = {
        eventName: "ready",
        callback: ({ chartWrapper }: eventCallbacksArgs) => {
          eventAnimation({
            chartWrapper,
            hovered,
            data,
          });
        },
      };
      return chartEvents.concat([animationEvent]);
    }
    return chartEvents;
    // eslint-disable-next-line
  }, [chartEvents, hovered]);

  return (
    <ContainerChart
      isGlobalNotAnalysis={animated && !hovered}
      hasHarmonics={harmonics}
      isHovered={hovered}
    >
      <ChartArea
        hasHarmonics={harmonics}
        onMouseEnter={() => animated && setHovered(true)}
        onMouseLeave={() => animated && setHovered(false)}
      >
        <Chart
          chartType={"LineChart"}
          style={{ width: "inherit", height: "100%" }}
          data={data}
          options={options}
          chartEvents={events}
          chartLanguage={"pt-Br"}
        />
      </ChartArea>

      {harmonics && (
        <TableArea>
          <TableButton onClick={() => setShowTable(!showTable)}>
            {showTable ? (
              <div>
                <span>{t("LineChart.hideTable")}</span>
                <FaChevronRight />
              </div>
            ) : (
              <NamePlusTooltip
                tooltip={`${t("LineChart.showTable")}`}
                position={"left"}
              >
                <div>
                  <FaChevronLeft />
                </div>
              </NamePlusTooltip>
            )}
          </TableButton>
          {showTable ? (
            <TableHarmonics
              data={data}
              isMechanical={chart?.series.includes("X")}
              chart={chart}
            />
          ) : (
            <></>
          )}
        </TableArea>
      )}
    </ContainerChart>
  );
};

export default memo(LineChartFunction);
