import { ReactNode } from "react";
import { Container } from "./styles";

interface ISelectRecall {
  children?: ReactNode;
  disabled?: boolean;
}

export function SelectRecall({ children, disabled }: ISelectRecall) {
  return <Container disabled={disabled}>{children}</Container>;
}
