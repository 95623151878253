export const NOTIFICATION_TYPES = {
  NEW_RELEASE: "NEW_RELEASE",
  BOLTS_OFFLINE: "BOLTS_OFFLINE",
  SENSORS_RECALL: "SENSORS_RECALL",
  SENSORS_OFFLINE: "SENSORS_OFFLINE",
  OCCURRENCE_CREATED: "OCCURRENCE_CREATED",
  OCCURRENCE_UPDATED_DIAGNOSTIC: "OCCURRENCE_UPDATED_DIAGNOSTIC",
  TO_EXPIRE_INTEGRATED_PEOPLE: "TO_EXPIRE_INTEGRATED_PEOPLE",
  SENSORS_LOW_BATTERY: "SENSORS_LOW_BATTERY",
  OCCURRENCE_TO_EXPIRE: "OCCURRENCE_TO_EXPIRE",
  ASSET_STATUS_UPDATED: "ASSET_STATUS_UPDATED",
  RESPONSIBLE_USER_CREATED: "RESPONSIBLE_USER_CREATED",
  RESPONSIBLE_USER_DELETED: "RESPONSIBLE_USER_DELETED",
};
