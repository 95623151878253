import decodeComponent from "./decodeUriComponent";


function splitOnFirst(string: string, separator: string) {
	if (!(typeof string === 'string' && typeof separator === 'string')) {
		throw new TypeError('Expected the arguments to be of type `string`');
	}

	if (string === '' || separator === '') {
		return [];
	}

	const separatorIndex = string.indexOf(separator);

	if (separatorIndex === -1) {
		return [];
	}

	return [
		string.slice(0, separatorIndex),
		string.slice(separatorIndex + separator.length)
	];
}

function keysSorter(input: any): any {
  if (Array.isArray(input)) {
    return input.sort();
  }

  if (typeof input === "object") {
    return keysSorter(Object.keys(input))
      .sort((a: any, b: any) => Number(a) - Number(b))
      .map((key: any) => input[key]);
  }

  return input;
}

function parseValue(value: any, options: any) {
  if (
    options.parseNumbers &&
    !Number.isNaN(Number(value)) &&
    typeof value === "string" &&
    value?.trim() !== ""
  ) {
    value = Number(value);
  } else if (
    options.parseBooleans &&
    value !== null &&
    (value.toLowerCase() === "true" || value.toLowerCase() === "false")
  ) {
    value = value.toLowerCase() === "true";
  }

  return value;
}

function decode(value: any, options: any) {
  if (options.decode) {
    return decodeComponent(value);
  }

  return value;
}

function parserForArrayFormat() {
  return (key: any, value: any, accumulator: any) => {
    if (accumulator[key] === undefined) {
      accumulator[key] = value;
      return;
    }

    accumulator[key] = [...[accumulator[key]].flat(), value];
  };
}

function validateArrayFormatSeparator(value: string) {
  if (typeof value !== "string" || value.length !== 1) {
    throw new TypeError("arrayFormatSeparator must be single character string");
  }
}

export function parse(query: string) {
  const options = {
    decode: true,
    sort: true,
    arrayFormat: "none",
    arrayFormatSeparator: ",",
    parseNumbers: false,
    parseBooleans: false,
  };

  validateArrayFormatSeparator(options.arrayFormatSeparator);

  const formatter = parserForArrayFormat();

  // Create an object with no prototype
  const returnValue: any = Object.create(null);

  if (typeof query !== "string") {
    return returnValue;
  }

  query = query?.trim().replace(/^[?#&]/, "");

  if (!query) {
    return returnValue;
  }

  for (const parameter of query.split("&")) {
    if (parameter === "") {
      continue;
    }

    const parameter_ = options.decode
      ? parameter.replace(/\+/g, " ")
      : parameter;

    let [key, value] = splitOnFirst(parameter_, "=");

    if (key === undefined) {
      key = parameter_;
    }

    // Missing `=` should be `null`:
    // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
    value =
      value === undefined
        ? null
        : ["comma", "separator", "bracket-separator"].includes(
            options.arrayFormat,
          )
        ? value
        : decode(value, options);
    formatter(decode(key, options), value, returnValue);
  }

  for (const [key, value] of Object.entries(returnValue)) {
    if (typeof value === "object" && value !== null) {
      for (const [key2, value2] of Object.entries(value)) {
        //@ts-ignore
        value[key2] = parseValue(value2, options);
      }
    } else {
      returnValue[key] = parseValue(value, options);
    }
  }

  if (options.sort === false) {
    return returnValue;
  }

  return (
    options.sort === true
      ? Object.keys(returnValue).sort()
      : Object.keys(returnValue).sort(options.sort)
  ).reduce((result, key) => {
    const value = returnValue[key];
    if (Boolean(value) && typeof value === "object" && !Array.isArray(value)) {
      // Sort object keys, not values
      result[key] = keysSorter(value);
    } else {
      result[key] = value;
    }

    return result;
  }, Object.create(null));
}

const queryString = { parse }

export default queryString;
