import { Form } from "formik";
import styled from "styled-components";

export const EvidenceForm = styled(Form)`
  h1 {
    ${({ theme }) => theme.fonts.primary.H2Bold};
  }
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const EvidenceImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 320px;
  }
`;

export const EvidenceFormRow = styled.div`
  display: flex;
  gap: 8px;
  & > div {
    width: 100%;
  }
`
