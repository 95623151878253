import styled from "styled-components";

export const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  gap: 1rem;

  border-radius: 4px;

  & > div {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    label {
      cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    }
  }
`;
