import styled from "styled-components";

export const ContainerOutside = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.label`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 767px) {
    & > label {
      width: 90px;
      margin-right: 20px;
      font-size: 14px;
    }
  }
`;

export const SunDark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  color: #656a85;
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    height: 100%;
    font-size: 14px;
  }
`;

export const SunLight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    height: 100%;
    font-size: 14px;
  }
`;

export const Moon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  color: #ebecf4;
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    height: 100%;
    font-size: 14px;
  }
`;
