import styled from "styled-components";

export const Container = styled.div`
  /* border: 1px solid red;
  height: 80vh; */
`;

export const BlackBoxTerms = styled.div`
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors3.color0};
  border: 1px solid ${(props) => props.theme.colors.cardBackground};
  border-radius: 6px;
  width: 100%;
  max-width: 1180px;
  height: 449px;
  text-align: left;
  padding: 16px;
  gap: 19px;
  overflow: auto;
  margin: auto;

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    height: 210px;
  }

  @media screen and (max-width: 767px) {
    height: 60vh;
    ::-webkit-scrollbar {
      display: none;
    }
    -webkit-scrollbar-width: none;
    -moz-scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }
`;

export const TermsTitle = styled.div`
  ${(props) => props.theme.fonts.primary.H2Bold};
  /* color: ${(props) => props.theme.colors3.color0}; */
  margin: 25px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    ${(props) => props.theme.fonts.primary.H5Regular};
  }

  @media screen and (max-width: 767px) {
    & > div {
      width: 100px;
      text-align: right;
    }
    margin: 0.5rem;
  }
`;

export const TermsParagraph = styled.div`
  color: ${(props) => props.theme.colors3.color0};
  ${(props) => ({ ...props.theme.fonts.primary.H4Regular })};
  font-size: 16px;
  font-style: normal;

  :-webkit-scrollbar {
    width: 5px !important;
    height: 30px;
  }
  @media screen and (max-width: 767px) {
    ${(props) => ({ ...props.theme.fonts.primary.H5Regular })};
  }
`;
