import styled from "styled-components";

export const GetApiContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  a {
    color: ${(props) => props.theme.colors.iconsSecondary};
    display: flex;
    gap: 0.5rem;

    :hover {
      filter: brightness(0.8);
      transition: all 0.3s ease;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    color: ${(props) => props.theme.colors.iconsSecondary};
    :hover {
      filter: brightness(0.8);
      scale: calc(1.025);
      transition: all 0.3s ease;
    }
    :active {
      scale: calc(0.95);
    }
  }
`;
