export type UserFormProps = {};

export const userProfiles = [
  { value: "EXECUTIVO_MASTER", label: "Executivo Master" },
  { value: "MESA_MASTER", label: "Mesa Master" },
  { value: "MESA_ANALISTA", label: "Mesa Analista" },
  { value: "CLIENTE_MASTER", label: "Cliente Master" },
  { value: "CLIENTE_MASTER_VIEW", label: "Cliente Master Visualizador" },
  { value: "CLIENTE_COMUM", label: "Cliente Comum" },
];

export const userFormEmpty = {
  companyId: null,
  facilityId: null,
  name: "",
  email: "",
  phone: "",
  profile: null,
  userCompanies: [],
  userFacilities: [],
};
