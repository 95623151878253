import { IAssetStatusOperation } from "../types/globalTypes";
import useApi from "./useApi";

interface IUpdateOperationStatus {
  operationStatus: IAssetStatusOperation;
  operationStatusReason?: string;
  minDate?: number;
  maxDate?: number;
  companyId: number;
  facilityId: number;
  assetId: number;
}

export const useAssetOperationStatus = () => {
  const { request, processing } = useApi({
    path: ``,
  });
  //operationStatus, operationStatusReason, minDate, maxDate

  const updateAssetOperationStatus = ({
    assetId,
    companyId,
    facilityId,
    operationStatus,
    maxDate,
    minDate,
    operationStatusReason,
  }: IUpdateOperationStatus) => {
    return request({
      method: "put",
      pathParameters: `companies/${companyId}/facilities/${facilityId}/assets/${assetId}/operation-status`,
      body: {
        operationStatus,
        maxDate,
        minDate,
        operationStatusReason,
      },
    });
  };

  return { updateAssetOperationStatus, processing };
};
