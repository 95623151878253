import { useField, useFormikContext } from "formik";
import useRetinaTranslation from "../../../../../hooks/useRetinaTranslation";
import { FormSection, FormSectionCompanyContent } from "../styles";
import { PROFILES, userSelect } from "../../../../../store/slices/userSlice";
import { useCallback, useEffect, useState } from "react";
import { customSelectCompaniesColors } from "../../types";
import UserCompaniesSelector from "./UserCompaniesSelector";
import FormSelect from "../../../../../components/FormSelect";
import useApi from "../../../../../hooks/useApi";
import { useParams } from "react-router-dom";
import UserFacilitiesSelector from "./UserFacilitiesSelector";
import { useAppSelector } from "../../../../../store/hooks";

const UserCompanySection = () => {
  const user = useAppSelector(userSelect);
  const params = useParams();
  const { companyId, userId } = params;

  const { translateLabels: labels, lang } = useRetinaTranslation();

  const { setFieldValue, errors } = useFormikContext<any>();
  const [{ value: profile }] = useField("profile");
  const [{ value: selectedCompanyId }] = useField("companyId");

  const { request } = useApi({ path: `/companies` });

  const [companies, setCompanies] = useState<any[]>([]);

  const isDisabled = [
    null,
    PROFILES.EXECUTIVO_MASTER,
    PROFILES.MESA_MASTER,
    PROFILES.REPRESENTANTE,
  ].includes(profile);

  const loadCompanies = () => {
    const queryStringParameters = { orderByName: 1 };

    request({ method: "get", queryStringParameters }).then((response) => {
      const formatted = response.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setCompanies(formatted);
    });
  };

  useEffect(() => {
    const { CLIENTE_COMUM, CLIENTE_MASTER } = PROFILES;
    if (
      !selectedCompanyId &&
      [CLIENTE_COMUM, CLIENTE_MASTER].includes(profile) &&
      companyId !== "ibbx"
    ) {
      setFieldValue("companyId", +(companyId || 0) || undefined);
    }
    // eslint-disable-next-line
  }, [profile]);

  useEffect(() => {
    loadCompanies();
    // eslint-disable-next-line
  }, []);

  const handleChangeCompany = (option: any) => {
    const { value } = option || {};

    setFieldValue("companyId", value);
    setFieldValue("userFacilities", []);
  };

  const renderFields = useCallback(() => {
    switch (true) {
      case PROFILES.MESA_ANALISTA === profile:
        return (
          <FormSectionCompanyContent disabled={isDisabled}>
            <UserFacilitiesSelector />
          </FormSectionCompanyContent>
        );
      case PROFILES.CLIENTE_MASTER === profile:
        // Campo de Empresa para Cliente Master, um cliente master não pode editar a de outros
        return (
          <FormSectionCompanyContent
            disabled={
              isDisabled ||
              (user.profile === PROFILES.CLIENTE_MASTER && !!userId)
            }
          >
            <UserCompaniesSelector
              disabled={
                [
                  null,
                  PROFILES.EXECUTIVO_MASTER,
                  PROFILES.MESA_MASTER,
                  PROFILES.REPRESENTANTE,
                  PROFILES.MESA_ANALISTA,
                ].includes(profile) ||
                (user.profile === PROFILES.CLIENTE_MASTER && !!userId)
              }
              error={errors?.companyId as string}
            />
          </FormSectionCompanyContent>
        );
      case PROFILES.CLIENTE_COMUM === profile:
        return (
          <FormSectionCompanyContent disabled={isDisabled}>
            <FormSelect
              name="companyId"
              label={labels("Company")}
              searchable
              placeholder={labels("Company Name")}
              handleChange={handleChangeCompany}
              options={companies || []}
              error={errors.companyId}
              customColors={customSelectCompaniesColors}
              disabled={[
                null,
                PROFILES.EXECUTIVO_MASTER,
                PROFILES.MESA_MASTER,
                PROFILES.REPRESENTANTE,
                PROFILES.MESA_ANALISTA,
              ].includes(profile)}
            />
            <UserFacilitiesSelector
              disabled={
                [
                  null,
                  PROFILES.EXECUTIVO_MASTER,
                  PROFILES.MESA_MASTER,
                  PROFILES.REPRESENTANTE,
                  PROFILES.MESA_ANALISTA,
                ].includes(profile) || !selectedCompanyId
              }
              error={errors?.facilityId as string}
            />
          </FormSectionCompanyContent>
        );
      default:
        return (
          <FormSectionCompanyContent disabled={isDisabled}>
            <FormSelect
              name="companyId"
              label={labels("Company")}
              searchable
              placeholder={labels("Company Name")}
              handleChange={handleChangeCompany}
              options={companies || []}
              error={errors.companyId}
              customColors={customSelectCompaniesColors}
              disabled={[
                null,
                PROFILES.EXECUTIVO_MASTER,
                PROFILES.MESA_MASTER,
                PROFILES.REPRESENTANTE,
                PROFILES.MESA_ANALISTA,
              ].includes(profile)}
            />
          </FormSectionCompanyContent>
        );
    }
    // eslint-disable-next-line
  }, [profile, lang, companies, selectedCompanyId]);

  return (
    <FormSection>
      <h3>{labels("Company")}</h3>
      {renderFields()}
    </FormSection>
  );
};

export default UserCompanySection;
