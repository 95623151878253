import { FormGroup } from "react-bootstrap";
import styled from "styled-components";

export const InputRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: fit-content;
  gap: 6px;

  & > label {
    color: ${(props) => props.theme.colors.iconsPrimary};
    ${(props) => props.theme.fonts.primary.H5Regular};
  }

  span {
    ${({ theme }) => theme.fonts.secondary.H2Regular};
    color: red;
  }
`;

export const InputRadioCheckContainer = styled.div`
  display: flex;
  gap: 16px;


  & > label {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 6px;
    height: 13px;
    cursor: pointer;
    ${({ theme }) => theme.fonts.primary.H5Regular};
    line-height: 13px;
    color: ${({ theme }) => theme.colors.textSecondary};
    user-select: none;

    input {
      height: 13px;
      width: 13px;
    }

    & .form-check-input {
      cursor: pointer;
      margin-top: 0;
      padding: 0;
    }

    & .form-check {
      padding-left: 0;
      min-height: 0;
    }

    .form-check-input:checked {
      background-color: ${(props) => props.theme.colors.ibbxOrange} !important;
      border-color: ${(props) => props.theme.colors.ibbxOrange} !important;
    }

    .form-check-input {
      background-color: ${(props) => props.theme.colors.iconsSecondary};
      border-color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }

  & > div {
    display: flex;
    gap: 4px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: row;
    gap: 16px;

    & > label {
      height: 16px;
      input {
        height: 16px;
        width: 16px;
      }
    }
  }
`;

export const RadioGroup = styled(FormGroup)`
  display: flex;
  gap: 4px 16px;
  align-items: flex-end;
  flex-wrap: wrap;

  label {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 20px;
    user-select: none;
    cursor: pointer;
    ${({ theme }) => theme.fonts.primary.H5SemiBold};
    color: ${({ theme }) => theme.colors.iconsSecondary};

    input {
      cursor: pointer;
      height: 18px;
      width: 14px;
    }
  }
`;

export const InputRadioOthersWrap = styled.div`
  & > div {
    padding-left: 16px;
    input {
      padding: 4px;
      min-height: 24px;
    }
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  vertical-align: center;
  gap: 8px;

  & > label {
    margin: 0;
    display: flex;
    align-items: center !important;
  }
`;
