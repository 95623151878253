import { useTranslation } from "react-i18next";
import InputList from "../../InputList";
import { FormikTextInput } from "../../textInput";

interface IInputListMosaic {
  screen?: any;
  index?: number;
  formRef?: any;
  name: string;
  disabled?: any;
  isEdit?: boolean;
}

export function InputListMosaic({
  formRef,
  name,
  index,
  disabled,
  isEdit,
}: IInputListMosaic) {
  const { t } = useTranslation();

  function buildCreateMosaic(screen: any, indexList: number) {
    function handleDelete() {
      const screens = formRef.current?.values.screens || {};

      const removed = screens.filter((_: any, i: number) => i !== indexList);

      formRef.current?.setFieldValue(`screens`, removed);
    }

    return (
      <InputList.Column>
        <InputList.Row>
          <FormikTextInput
            name={`screens[${indexList}][title]`}
            label={`${t("Analytics.Nome do Quadro")}`}
          />
          <FormikTextInput name={`screens[${indexList}][url]`} label="Url" />
          <InputList.Delete
            handleClick={handleDelete}
            hoverLabel={`${t("OccurrenceForm.diagnosticDeleteLabel")}`}
          />
        </InputList.Row>
      </InputList.Column>
    );
  }

  function buildEditableMosaics(screen: any, indexList: number) {
    function handleDelete() {
      let screens = formRef.current?.values[index as number].screens ?? [];

      screens = screens.filter((_: any, i: number) => i !== indexList);

      formRef.current?.setFieldValue(`[${index}][screens]`, screens);
    }

    return (
      <InputList.Column>
        <InputList.Row>
          <FormikTextInput
            name={`[${index}][screens][${indexList}][title]`}
            label="Nome do Quadro"
          />
          <FormikTextInput
            name={`[${index}][screens][${indexList}][url]`}
            label="Url"
          />
          <InputList.Delete
            handleClick={handleDelete}
            hoverLabel={`${t("OccurrenceForm.diagnosticDeleteLabel")}`}
          />
        </InputList.Row>
      </InputList.Column>
    );
  }

  return (
    <InputList
      name={name}
      label={`${t("Analytics.Adicionar quadros")}`}
      empty={{ title: "", url: "" }}
      neverEmpty
      build={isEdit ? buildEditableMosaics : buildCreateMosaic}
      limit={10}
      reverseOrder
      disabled={disabled}
    />
  );
}
