import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  appSelect,
  appSetCurrentAsset,
  appSetCurrentCompany,
  appSetCurrentFacility,
  appSetCurrentPosition,
} from "../../../../store/slices/appSlice";
import { NavBarLogoContainer, NavBarLogoContent } from "./styles";
import LogoWhite from "../../../../images/ibbxWhite.svg";
import { CompanyLogo } from "../styles";
import { useCallback, useMemo } from "react";
import { getUrlPattern } from "../../../../utils/getUrlPattern";
import { ROUTES_PATH } from "../../../../routes/RoutesPath";
import { getUserHomeFromUrl } from "../../../../utils/userUtils";

function NavBarLogo() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const app = useAppSelector(appSelect);

  const params = useParams();
  const location = useLocation();

  // Vai para a primeira página
  function goHome() {
    const isHomeSameUrl = location.pathname === app.homeUrl;
    if (isHomeSameUrl) {
      return;
    }

    const home = getUserHomeFromUrl(app.homeUrl);
    if (!["facilities", "assets"].includes(home)) {
      dispatch(appSetCurrentCompany(null));
    }

    if (home !== "assets") {
      dispatch(appSetCurrentFacility(null));
    }

    dispatch(appSetCurrentAsset(null));
    dispatch(appSetCurrentPosition(null));
    navigate(app.homeUrl);
  }

  const isMasterLevel = useMemo(() => {
    const urlPattern = getUrlPattern(location, params);
    if (
      urlPattern.startsWith("/configuration/") &&
      params.companyId === "ibbx"
    ) {
      return true;
    }

    return ROUTES_PATH.COMPANIES === urlPattern;
    // eslint-disable-next-line
  }, []);

  const logo = useMemo(() => {
    if (isMasterLevel) {
      return app.defaultLogo;
    }

    return app.currentCompany?.logoUrl || app.defaultLogo;
  }, [isMasterLevel, app.currentCompany?.logoUrl, app.defaultLogo]);

  const altName = useMemo(() => {
    if (isMasterLevel) {
      return "Parceiro IBBX";
    }

    return app.currentCompany.name;
  }, [isMasterLevel, app.currentCompany.name]);

  const renderLogo = useCallback(() => {
    if (logo) {
      return (
        <CompanyLogo>
          <img width="100%" height="100%" src={logo} alt={altName} />
        </CompanyLogo>
      );
    }

    return (
      <NavBarLogoContent>
        <img width="42" src={LogoWhite} alt="IBBx" />
      </NavBarLogoContent>
    );

    // eslint-disable-next-line
  }, [logo, altName]);

  return (
    <NavBarLogoContainer onClick={goHome}>{renderLogo()}</NavBarLogoContainer>
  );
}

export default NavBarLogo;
