import styled from "styled-components";

export const SelectorContainer = styled.div`
margin-top: 8px;
  display: flex;
  justify-content: start;
  gap: 20px;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  @media screen and (max-width: 767px) {
    margin-left: 8px;
  } ;
`;

export const Serie = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  height: 15px;
  align-items: flex-end;
  gap: 4px;

  span {
    ${({ theme }) => theme.fonts.secondary.H1Regular};
    width: 100%;
    padding: 0;
    margin: 0;
    line-height: 10px;
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
`;

export const Color = styled.div<{ color: string; toggle: boolean }>`
  width: 20px;
  height: 10px;
  background: ${(props) => (props.toggle ? props.color : "transparent")};
  border: 1px solid ${(props) => props.color};
  border-radius: 8px;
`;
