import { ReactNode } from "react";
import { Footer } from "./styles";

interface FooterContainerProps {
  children: ReactNode;
}

export function FooterContainer({ children }: FooterContainerProps) {
  return <Footer>{children}</Footer>;
}
