import styled from "styled-components";

export const WelcomeContent = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    ${({ theme }) => theme.fonts.primary.H1Semibold}
    font-size: 1.5rem !important;
  }

  p {
    ${({ theme }) => theme.fonts.primary.H4Regular}
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    gap: 0;

    span {
      font-size: 1.5rem !important;
    }
    p {
      font-size: 0.75rem;
    }
    ul {
      font-size: 0.75rem;
    }
  }

  @media screen and (max-width: 767px) {
    gap: 0;

    span {
      font-size: 1.25rem !important;
    }
    p {
      font-size: 0.75rem;
    }
    ul {
      font-size: 0.75rem;
    }
  }
`;
