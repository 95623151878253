import { MdThumbDown, MdThumbUp } from "react-icons/md";
import { useTheme } from "styled-components";
import { Container, RatingButton } from "./stylest";
import { useSegment } from "../../hooks/useSegmentTrackEvent ";

interface RatingInterface {
  occurrenceId?: any;
  selected?: string;
  setSelected?: any;
}

export const RatingButtons = ({
  occurrenceId,
  selected,
  setSelected,
}: RatingInterface) => {
  const { handleTrackEvent } = useSegment();

  const { colors } = useTheme();

  const getThumbsColor = () => {
    const neutralColor = colors.iconsSecondary;

    if (selected === "LIKE") {
      return {
        likeColor: colors.statusNormal,
        dislikeColor: neutralColor,
      };
    } else if (selected === "DISLIKE") {
      return {
        likeColor: neutralColor,
        dislikeColor: colors.statusRisk,
      };
    }

    return {
      likeColor: neutralColor,
      dislikeColor: neutralColor,
    };
  };

  const thumbsColor = getThumbsColor();

  return (
    <Container>
      <RatingButton
        name="like"
        onClick={() => {
          setSelected("LIKE");
          handleTrackEvent({
            event: "PAINEL OCORRÊNCIA: Confirmar Fechar - LIKE",
          });
        }}
        like
      >
        <MdThumbUp size={22} color={thumbsColor?.likeColor} />
      </RatingButton>
      <RatingButton
        name="dislike"
        onClick={() => {
          setSelected("DISLIKE");
          handleTrackEvent({
            event: "PAINEL OCORRÊNCIA: Confirmar Fechar - DISLIKE",
          });
        }}
      >
        <MdThumbDown size={22} color={thumbsColor?.dislikeColor} />
      </RatingButton>
    </Container>
  );
};
