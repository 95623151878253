import { useMemo } from "react";
import useQueryParams from "../../../../hooks/useQueryParams";
import { useAppSelector } from "../../../../store/hooks";
import { selectOptionsSelect } from "../../../../store/slices/selectOptionsSlice";
import { clearQuery } from "../../../FilterDashboardSelect/types";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import useRetinaTranslation from "../../../../hooks/useRetinaTranslation";
import {
  ClearAllFilters,
  FilteredButton,
  FilteredItemContainer,
} from "./styles";

export default function FilteredLabels() {
  const { queryParams, setQueryParams } = useQueryParams();
  const { t } = useTranslation();
  const { tr } = useRetinaTranslation();

  const selectOptions = useAppSelector(selectOptionsSelect);

  const handleClearFiltered = (item: any) => {
    let arryaToDelete = ["companyId", "facilityId", "sectorId", "assetId"];

    if (item === "facility") {
      arryaToDelete = ["facilityId", "sectorId", "assetId"];
    }
    if (item === "sector") {
      arryaToDelete = ["sectorId", "assetId"];
    }
    if (item === "asset") {
      arryaToDelete = ["assetId"];
    }
    if (item === "status") {
      arryaToDelete = ["statusId"];
    }
    if (item === "sensorType") {
      arryaToDelete = ["sensorTypeId"];
    }
    if (item === "closedAt") {
      arryaToDelete = ["closedAt"];
    }
    if (item === "openedBy") {
      arryaToDelete = ["openedBy"];
      localStorage.setItem("occurrenceAssetOpenedByFilter", "");
    }

    if (item === "analysisOwner") {
      arryaToDelete = ["analysisOwner"];
      localStorage.removeItem("analysisOwner");
    }

    clearQuery(queryParams, arryaToDelete, setQueryParams);
  };

  const handleClearAllFiltered = () => {
    let arryaToDelete = [
      "companyId",
      "facilityId",
      "sectorId",
      "assetId",
      "statusId",
      "monitoring-type",
      "sensorTypeId",
      "closedAt",
      "openedBy",
    ];
    localStorage.setItem("occurrenceAssetOpenedByFilter", "");
    clearQuery(queryParams, arryaToDelete, setQueryParams);
  };

  const info: any[] = useMemo(() => {
    const filters = [];

    if (queryParams.companyId) {
      filters.push({
        type: "company",
        clearable: true,
        label: selectOptions.companies.options.find(
          ({ value }) => +value === +(queryParams.companyId ?? 0),
        )?.label,
      });
    }
    if (queryParams.facilityId) {
      filters.push({
        type: "facility",
        clearable: true,
        label: selectOptions.facilities.options.find(
          ({ value }) => +value === +(queryParams.facilityId ?? 0),
        )?.label,
      });
    }
    if (queryParams.sectorId) {
      filters.push({
        type: "sector",
        clearable: true,
        label: selectOptions.sectors.options.find(
          ({ value }) => +value === +(queryParams.sectorId ?? 0),
        )?.label,
      });
    }
    if (queryParams.assetId) {
      filters.push({
        type: "asset",
        clearable: true,
        label: selectOptions.assets.options.find(
          ({ value }) => +value === +(queryParams.assetId ?? 0),
        )?.label,
      });
    }
    if (queryParams.statusId) {
      const statusLabel =
        selectOptions.status.options.find(
          ({ value }) => +value === +(queryParams.statusId ?? 0),
        )?.label ?? "";
      filters.push({
        type: "status",
        clearable: true,
        label: tr("OccurrenceHistory", statusLabel),
      });
    }
    if (queryParams.sensorTypeId) {
      filters.push({
        type: "sensorType",
        clearable: true,
        label: selectOptions.sensorTypes.options.find(
          ({ value }) => +value === +(queryParams.sensorTypeId ?? 0),
        )?.label,
      });
    }

    if (queryParams.closedAt) {
      filters.push({
        type: "closedAt",
        clearable: true,
        label: selectOptions.closedAt.options.find(
          ({ value }) => value === (queryParams.closedAt ?? ""),
        )?.label,
      });
    }

    if (queryParams.openedBy) {
      const openedByOption = selectOptions.openedBy.options.find(
        ({ value }) => value === (queryParams.openedBy ?? ""),
      );
      filters.push({
        type: "openedBy",
        clearable: true,
        label: openedByOption?.label,
      });
    }

    if (queryParams.analysisOwner) {
      filters.push({
        type: "analysisOwner",
        clearable: true,
        label: t(`FilteredLabels.analysisOwner.${queryParams.analysisOwner}`),
      });
    }

    return filters;
    // eslint-disable-next-line
  }, [queryParams]);

  return (
    <FilteredItemContainer>
      {info.map((item: any, index: number) => (
        <FilteredButton
          key={index}
          disable
          onClick={() => {
            item.clearable && handleClearFiltered(item.type);
          }}
        >
          <span>{item.label}</span>
          {item.clearable && <MdClose />}
        </FilteredButton>
      ))}
      {!!info.length && (
        <ClearAllFilters
          onClick={() => {
            handleClearAllFiltered();
          }}
        >
          {isMobileOnly ? (
            <MdClose />
          ) : (
            <span>{t("FilteredLabels.clearFilter")}</span>
          )}
        </ClearAllFilters>
      )}
    </FilteredItemContainer>
  );
}
