import { axisModel } from "../../components/charts/chart/types";
import { format } from "date-fns";
import { getSerieName } from "./general";
import { IChart } from "../../store/interfaces";

type getTooltipParams = {
  axisX: axisModel;
  axisY: axisModel;
  serie: string;
  tooltipConfig?: any;
  chart: IChart;
  dateFormat?: string;
  t?: any;
  frequencyUnitCPM?: boolean;
  position?: any;
};

function dynamicTooltip({
  axisX,
  axisY,
  frequencyUnitCPM,
  t,
}: getTooltipParams) {
  const label = t(
    axisX.unit === "s" ? "commonText.Time" : "commonText.Frequency",
  );
  const axisXUnit =
    axisX.unit === "Hz" && frequencyUnitCPM ? "cpm" : axisX.unit;
  return `<p>${t("commonText.Amplitude")}: ${axisY.value} ${
    axisY.unit
  }</p><hr /><p>${label}: ${axisX.value} ${axisXUnit}</p>`;
}

function roundDecimal(value: any, decimals: number = 2) {
  if (!value?.toString()) {
    return null;
  }

  try {
    if (typeof value === "string") {
      value = +value;
    }
    return +value.toFixed(decimals);
  } catch (err) {
    console.error(`${value} of type ${typeof value}, Failed to Round`);
  }
}

function globalTooltip({
  axisX,
  axisY,
  dateFormat,
  chart,
  serie,
  tooltipConfig,
  t,
}: getTooltipParams) {
  dateFormat =
    dateFormat ||
    `'${t("chartSeries.Data")}: 'dd/MM/yyyy '\n' '${t(
      "chartSeries.Hora",
    )}: 'HH:mm`;
  const { plural, decimals = 2 } = tooltipConfig || {};
  const serieName = t(getSerieName(serie, chart));

  let valueFormatted: any = roundDecimal(axisY?.value, decimals) ?? 0;

  const date = axisX && format(axisX.value, dateFormat);

  const unit = `${axisY?.unit}${plural && axisY?.value !== 1 ? "s" : ""}`;

  return `<p>${date}</p> <hr /> <p> ${`${serieName}: ${valueFormatted} ${unit}`} </p>`;
}

function timeTooltip({ t, axisX, axisY, ...params }: getTooltipParams) {
  params.dateFormat =
    params.dateFormat ||
    `'${t("chartSeries.Data")}: 'dd/MM/yyyy '\n' '${t(
      "chartSeries.Hora",
    )}: 'HH:mm`;

  const date = axisX && format(axisX.value, params.dateFormat);
  const hours = Math.floor(axisY.value);
  const minutesDecimal = axisY.value - hours;
  const minutes = Math.round(60 * minutesDecimal);
  let minutesLabel = "";
  if (minutes) {
    minutesLabel = `e ${minutes} minutos`;
  }
  return `<p>${date}</p> <hr /> <p> ${hours} Horas ${minutesLabel} </p>`;
}

export default function getTooltip(params: getTooltipParams) {
  const { chart } = params;
  const isDynamic = chart.type === "DYNAMIC";

  // @TO-DO Alterar para configuração de gráficos com a medida horas
  const isProduction = process.env.REACT_APP_ENV === "production";
  const isFazendaPalmito = params?.position?.companyId === 145;
  const isTimeChart = chart?.name === "100x0706";

  if (isProduction && isFazendaPalmito && isTimeChart) {
    const tooltip = timeTooltip(params);
    const html = `<div style="padding: 6px">  ${tooltip} </div>`;
    return html.replace(`\n`, "");
  }

  let tooltip = isDynamic ? dynamicTooltip(params) : globalTooltip(params);

  const html = `<div style="padding: 6px">  ${tooltip} </div>`;

  return html.replace(`\n`, "");
}
