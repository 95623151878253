import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  appSelect,
  appSetCurrentAsset,
  appSetCurrentCompany,
  appSetCurrentFacility,
  appSetCurrentPosition,
  filterOptionDataType,
} from "../store/slices/appSlice";
import { EMPTY_COMPANY } from "../pages/Facilities/types";
import { positionEmpty } from "../store/slices/positionSlice";
import useApi from "./useApi";
import {
  selectOptionsSelect,
  setAssetOptions,
  setCompanyOptions,
  setFacilityOptions,
  setSectorOptions,
  setPositionOptions,
  setSensorTypes,
} from "../store/slices/selectOptionsSlice";
import { EMPTY_ASSET } from "../pages/Asset/types";
import { EMPTY_FACILITY } from "../pages/Assets/types";

const queryStringParameters = { orderByName: 1 };

type parameters = {
  companyId?: number | string;
  facilityId?: number | string;
  sectorId?: number | string;
  assetId?: number | string;
  positionId?: number | string;
};

// const hiearchy = ["COMPANY", "FACILITY", "SECTOR", "ASSET", "POSITION"];

function useFilters({ companyId, facilityId, sectorId, assetId }: parameters) {
  // Store
  const dispatch = useAppDispatch();
  const { currentCompany, currentFacility, currentAsset, currentPosition } =
    useAppSelector(appSelect);
  const { companies, facilities, sectors, assets, positions, sensorTypes } =
    useAppSelector(selectOptionsSelect);
  const { request } = useApi({ path: "" });

  function itemsToOptions(items: any, isCompany?: boolean) {
    return items.map((item: any) => ({
      value: item.id || item.value,
      label: item.name || item.label,
      ...(isCompany ? { logoUrl: item.logoUrl } : {}),
    }));
  }

  function loadCompanyOptions(skip?: boolean) {
    if (skip) {
      const options = [
        {
          value: currentCompany.id,
          label: currentCompany.name,
          logoUrl: currentCompany.logoUrl,
        },
      ];

      dispatch(setCompanyOptions({ companyId: currentCompany.id, options }));
    } else if (companies.options.length < 2) {
      const pathParameters = "companies-options";
      request({ method: "get", queryStringParameters, pathParameters }).then(
        (response) => {
          const options = itemsToOptions(response, true);

          dispatch(setCompanyOptions({ options }));
          // if (facilityOptions.data.length) {
          //   dispatch(setFacilityOptions({ data: [] }));
          // }
          // if (sectorOptions.data.length) {
          //   dispatch(setSectorOptions({ data: [] }));
          // }
          // if (assetOptions.data.length) {
          //   dispatch(setAssetOptions({ data: [] }));
          // }
          // if (positionOptions.data.length) {
          //   dispatch(setPositionOptions({ data: [] }));
          // }
        },
      );
    }
  }

  function loadFacilityOptions(skip?: boolean) {
    if (skip) {
      const options = [
        { value: currentFacility.id, label: currentFacility.name },
      ];
      dispatch(setCompanyOptions({ companyId: currentCompany.id, options }));
    } else if (companyId && companyId !== facilities.companyId) {
      const queryStringParameters = { companyId };
      const pathParameters = `facilities-options`;
      request({ method: "get", queryStringParameters, pathParameters }).then(
        (response) => {
          const options = itemsToOptions(response, true);
          dispatch(setFacilityOptions({ companyId, options }));

          if (sectors.options.length) {
            dispatch(setSectorOptions({ options: [] }));
          }
          if (assets.options.length) {
            dispatch(setAssetOptions({ options: [] }));
          }
          if (positions.options.length) {
            dispatch(setSectorOptions({ options: [] }));
          }
        },
      );
    } else if (!companyId) {
      if (facilities.options.length) {
        dispatch(setFacilityOptions({ options: [] }));
      }
      if (sectors.options.length) {
        dispatch(setSectorOptions({ options: [] }));
      }
      if (assets.options.length) {
        dispatch(setAssetOptions({ options: [] }));
      }
      if (positions.options.length) {
        dispatch(setSectorOptions({ options: [] }));
      }
    }
  }

  function loadSectorOptions() {
    const hasParams = companyId && facilityId;
    if (hasParams && facilityId !== sectors.facilityId) {
      const pathParameters = `companies/${companyId}/facilities/${facilityId}/sectors`;
      request({ method: "get", queryStringParameters, pathParameters }).then(
        (response) => {
          const options = itemsToOptions(response, true);
          dispatch(setSectorOptions({ facilityId, options }));

          if (assets.options.length) {
            dispatch(setAssetOptions({ options: [] }));
          }
          if (positions.options.length) {
            dispatch(setSectorOptions({ options: [] }));
          }
        },
      );
    } else if (!hasParams) {
      if (sectors.options.length || sectors.facilityId) {
        dispatch(setSectorOptions({ options: [] }));
      }
    }
  }

  function loadAssetOptions(skip?: boolean) {
    const hasParams = companyId && facilityId;
    const facilityChanged = facilityId !== assets.facilityId;
    const sectorChanged = sectorId !== assets.sectorId;
    if (skip) {
      const options = [{ value: currentAsset.id, label: currentAsset.name }];
      dispatch(setCompanyOptions({ facilityId: currentFacility.id, options }));
    } else if (hasParams && (facilityChanged || sectorChanged)) {
      const pathParameters = `companies/${companyId}/facilities/${facilityId}/assets`;
      const queryStringParameters: any = { orderByName: 1 };
      if (sectorId) {
        queryStringParameters.sectorId = sectorId;
      }
      request({ method: "get", queryStringParameters, pathParameters }).then(
        (response) => {
          const options = itemsToOptions(response.data || [], true);
          dispatch(setAssetOptions({ facilityId, sectorId, options }));

          if (positions.options.length) {
            dispatch(setSectorOptions({ options: [] }));
          }
        },
      );
    } else if (!hasParams) {
      if (assets.options.length || assets.facilityId || assets.sectorId) {
        dispatch(setAssetOptions({ options: [] }));
      }
      if (positions.options.length) {
        dispatch(setSectorOptions({ options: [] }));
      }
    }
  }

  function loadMonitoringOptions() {
    if (!sensorTypes.options.length) {
      request({ method: "get", pathParameters: "monitoringTypes" }).then(
        (response) => {
          const options = itemsToOptions(response, true);
          dispatch(setSensorTypes({ options }));
        },
      );
    }
  }

  function loadPositionOptions(listCharts?: any) {
    if (assetId) {
      const pathParameters = `positions-options`;
      const queryStringParameters: any = { assetId, listCharts };

      request({ method: "get", queryStringParameters, pathParameters }).then(
        (response) => {
          const options = response.map((item: any) => ({
            ...item,
            value: item.id || item.value,
            label: item.name || item.label,
          }));
          dispatch(setPositionOptions({ options }));
        },
      );
    } else {
      if (positions.options.length) {
        dispatch(setPositionOptions({ options: [] }));
      }
    }
  }

  function changeCompany(option: filterOptionDataType | null) {
    if (option === null) {
      dispatch(appSetCurrentCompany(null));
      if (currentFacility.id) {
        dispatch(appSetCurrentFacility(null));
      }
      if (currentAsset.id) {
        dispatch(appSetCurrentAsset(null));
      }
      if (currentPosition.id) {
        dispatch(appSetCurrentPosition(positionEmpty));
      }
    } else if (option?.value && option?.value !== currentCompany.id) {
      if (currentFacility.id) {
        dispatch(appSetCurrentFacility(null));
      }
      if (currentAsset.id) {
        dispatch(appSetCurrentAsset(null));
      }
      if (currentPosition.id) {
        dispatch(appSetCurrentPosition(null));
      }
      dispatch(
        appSetCurrentCompany({
          ...EMPTY_COMPANY,
          id: option.value as number,
          name: option.label,
          logoUrl: option.logoUrl || "",
        }),
      );
    }
  }

  function changeFacility(option: filterOptionDataType | null) {
    if (option === null) {
      dispatch(appSetCurrentFacility(null));
      if (currentAsset.id) {
        dispatch(appSetCurrentAsset(null));
      }
      if (currentPosition.id) {
        dispatch(appSetCurrentPosition(null));
      }
    } else if (option?.value && option?.value !== currentFacility.id) {
      if (currentAsset.id) {
        dispatch(appSetCurrentAsset(null));
      }
      if (currentPosition.id) {
        dispatch(appSetCurrentPosition(null));
      }
      dispatch(
        appSetCurrentFacility({
          ...EMPTY_FACILITY,
          id: option.value as number,
          name: option.label,
        }),
      );
    }
  }

  function changeSector(option: filterOptionDataType | null) {
    if (option === null) {
      if (currentAsset.id) {
        dispatch(appSetCurrentAsset(null));
      }
      if (currentPosition.id) {
        dispatch(appSetCurrentPosition(null));
      }
    } else {
      if (assets.options.length) {
        dispatch(setAssetOptions({ options: [] }));
      }
      if (positions.options.length) {
        dispatch(setSectorOptions({ options: [] }));
      }
      if (currentAsset.id) {
        dispatch(appSetCurrentAsset(null));
      }
      if (currentPosition.id) {
        dispatch(appSetCurrentPosition(null));
      }
    }
  }

  function changeAsset(option: filterOptionDataType | null) {
    if (option === null) {
      dispatch(appSetCurrentAsset(null));
      if (currentAsset.id) {
        dispatch(appSetCurrentAsset(null));
      }
    } else if (option?.value && option?.value !== currentAsset.id) {
      dispatch(
        appSetCurrentAsset({
          ...EMPTY_ASSET,
          id: option.value as number,
          name: option.label,
        }),
      );
    }
  }

  function changePosition(option: filterOptionDataType | null) {
    if (option === null) {
      dispatch(appSetCurrentPosition(positionEmpty));
    } else if (option?.value && option?.value !== currentPosition.id) {
      dispatch(
        appSetCurrentPosition({
          ...positionEmpty,
          id: option.value as number,
          positionName: option.label,
        }),
      );
    }
  }

  return {
    companyOptions: companies.options,
    loadCompanyOptions,
    facilityOptions: facilities.options,
    loadFacilityOptions,
    sectorOptions: sectors.options,
    loadSectorOptions,
    assetOptions: assets.options,
    loadAssetOptions,
    positionOptions: positions.options,
    loadPositionOptions,
    monitoringOptions: sensorTypes,
    loadMonitoringOptions,
    changeCompany,
    changeFacility,
    changeSector,
    changeAsset,
    changePosition,
  };
}

export default useFilters;
