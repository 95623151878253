import styled, { css } from "styled-components";

export const UrlContainer = styled.div<{ isUrlValid?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem !important;

  & > form > div {
    margin-bottom: 0.5rem;
  }
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    form {
      width: 100%;
      color: ${(props) => props.theme.colors.iconsSecondary};
    }

    & > button {
      border: none;
      background-color: transparent;
      color: ${(props) => props.theme.colors.iconsSecondary};

      svg {
        width: 1.25rem;
        height: auto;
      }
      :hover {
        filter: brightness(0.8);
        scale: calc(1.05);
        transition: all 0.3s ease;
      }
      :active {
        scale: calc(0.95);
      }
    }
  }

  & > div:not(:first-child) {
    width: 100%;
    background-color: ${(props) => props.theme.colors.inputBackground};
    padding: 0.25rem;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    border: 1px solid transparent;

    ${({ isUrlValid }) =>
      isUrlValid &&
      css`
        border: 1px solid red;
        transition: all 0.2s ease;
        position: relative;

        :before {
          content: "Insira uma URL válida";
          ${({ theme }) => theme.fonts.primary.H5Regular}

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          border-radius: 3px;
          padding: 0.15rem 0.5rem;
          color: red;
        }
      `}

    input {
      width: 100%;
      background-color: ${(props) => props.theme.colors.inputBackground};
      color: ${(props) => props.theme.colors.textPrimary};
      ${({ theme }) => theme.fonts.primary.H4Regular}
      font-size: 0.75rem;
      border: none;
      :focus-visible {
        outline: none;
      }
      ::placeholder {
        color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      }
    }
  }
`;
