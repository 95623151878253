import styled from "styled-components";

export const Container = styled.textarea<{ error?: string }>`
  width: 100%;
  margin-top: 16px;
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors3.color0};
  border: 1px solid
    ${(props) => (props.error ? "red" : props.theme.colors.cardHighlight)};
  padding: 12px;
  border-radius: 4px;
  resize: none;
`;
