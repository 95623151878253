import styled, { css } from "styled-components";

export const FormInputContainer = styled.div<{
  label?: string;
  labelDistance?: string;
  error?: string;
  disabled?: boolean;
  important?: boolean;
  height?: string;
}>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 4px;

  label {
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${({ theme }) => theme.colors.iconsPrimary};
    /* margin-bottom: 4px; */
  }

  span {
    ${(props) => props.theme.fonts.secondary.H3Regular};
    color: red;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input::placeholder {
    /* color: ${({ theme }) => theme.colors.cardHighlight}; */
  }

  input[type="number"] {
    appearance: textfield !important;
    -moz-appearance: textfield !important;
  }

  input {
    height: 36px;
  }

  input,
  textarea {
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.6;
        &:hover {
          border: 1px solid transparent;
        }
      `};
    ${(props) => props.theme.fonts.primary.H4Regular};
    width: 100%;
    min-height: 34px;
    color: ${(props) => props.theme.colors.iconsPrimary};
    background: ${(props) => props.theme.colors.background};
    border-radius: 4px;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.colors.cardHighlight};

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.IconsTools};
    }

    &:focus {
      outline: none;
      border: 1px solid ${(props) => props.theme.colors.IconsTools};
    }

    &:focus-visible {
      outline: none;
      border: 1px solid ${(props) => props.theme.colors.IconsTools};
    }

    ${({ error }) =>
      error &&
      css`
        border: 1px solid red !important;

        &:hover {
          border: 1px solid red;
        }

        &:focus {
          border: 1px solid red;
        }
      `};
  }

  textarea {
    height: ${({ height }) => (height ? height : "90px")};
    resize: none;

    &::-webkit-scrollbar {
      width: 6px !important;
    }

    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.colors.background};
      width: 20px;
      border-radius: 100vw;
      margin-block: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }

    &::-webkit-scrollbar-thumb:active {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }
  }

  @media screen and (max-width: 767px) {
    & > input {
      font-size: 1rem !important;
    }
  }
`;
export const LabelContainer = styled.div`
  display: flex;
  vertical-align: center;
  gap: 8px;

  & > label {
    margin: 0;
    display: flex;
    align-items: center !important;
  }

  & > span {
    color: ${(props) => props.theme.colors.iconsSecondary} !important;
    opacity: 0.6;
  }
`;
