import styled from "styled-components";

export const PositionTittle = styled.div`
  ${(props) => ({ ...props.theme.fonts.primary.H1Regular })};
  margin: 0px 0 24px 0;

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
    ${(props) => ({ ...props.theme.fonts.primary.H2Bold })}
  }
`;

export const Container = styled.div`
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  gap: 10px;
  height: 386px;
  width: 100%;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }

  @media screen and (max-width: 767px) {
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 5px;
  & > div {
    & > div {
      width: 150px;
      justify-content: flex-start;
      ${(props) => ({ ...props.theme.fonts.primary.H5SemiBold })};
    }
  }
`;

export const EmptyChart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  & span {
    margin-top: 10px;
  }
`;

export const SignalQualityChartWrapp = styled.div`
  display: flex;
  flex-direction: column;
  height: 390px;
  width: 100%;
  padding: 0px 20px;

  h2 {
    flex: 0 0 20px;
    ${({ theme }) => theme.fonts.primary.H3SemiBold};
    margin-bottom: 4px;
  }
  & > div:nth-child(1) {
    flex: 1 1 20px;
    height: 100%;
  }
  & > div:last-child {
    margin: 0;
    flex: 0 0 20px;

    & > div {
      padding: 0;
      margin: 0;
    }
  }
`;
