import styled from "styled-components";
import { FrameBodyRightProps } from "./types";

type BodyProps = {
  hasMargin?: boolean;
};

export const Container = styled.div<{ scrollable?: boolean }>`
  height: 100vh;
  width: 100vw;
  overflow-x: clip;
  overflow-y: ${({ scrollable }) => (scrollable ? "auto" : "clip")};
  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }
`;

export const Body = styled.div<BodyProps>`
  position: relative;
  width: 100%;
  height: 100vh;

  @media screen and (max-width: 767px) {
    height: calc(100vh - 132px);
    margin-bottom: 6px;
  }
`;

// para ser usado em páginas em que há sidebar
export const FrameBodyMain = styled.div`
  display: flex;
  height: 100%;
  padding-bottom: 4rem;

  @media screen and (max-width: 767px) {
    height: calc(100vh - 132px);
    margin-bottom: 6px;
    padding-bottom: 0rem;
    & > div:last-child {
      overflow: clip;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1280px) {
    height: 78vh;
    padding-bottom: 0rem;
  }
`;

// para ser usado em páginas em que há sidebar, é a parte da direita

export const ConfigurationPagesContent = styled.div<FrameBodyRightProps>`
  width: 100%;
  padding-bottom: 1rem;
  @media (max-width: 767px) {
    width: ${(props) => (props.visible ? "0px" : "100%")};
    opacity: ${(props) => (props.visible ? "0" : "1")};
    transition: width 0.3s linear, opacity 0.3s linear;
    padding-bottom: 0rem;
    margin: 0;
    width: 100%;
    overflow: auto;
    overflow-x: clip;

    ::-webkit-scrollbar {
      display: none;
    }
    -webkit-scrollbar-width: none;
    -moz-scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const FrameBodyRight = styled.div<FrameBodyRightProps>`
  padding-bottom: 11px;
  margin-left: ${(props) => (props.backgroundWhite ? "0px" : "0.2rem")};
  margin-right: 0.2rem;
  width: 100%;
  background-color: ${({ backgroundWhite }) =>
    backgroundWhite ? "#FFF" : "transparent"};

  @media (max-width: 767px) {
    width: ${(props) => (props.visible ? "0px" : "100%")};
    opacity: ${(props) => (props.visible ? "0" : "1")};
    transition: width 0.3s linear, opacity 0.3s linear;
    padding-bottom: 0rem;
    margin: 0;
    width: 100%;
    overflow: auto;
    overflow-x: clip;

    ::-webkit-scrollbar {
      display: none;
    }
    -webkit-scrollbar-width: none;
    -moz-scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  & > div:first-child {
    position: relative;

    .carousel-control-prev {
      opacity: 0;
    }
    .carousel-control-next-icon {
      opacity: 0;
    }
    .carousel-indicators {
      z-index: 1;
    }
  }
`;

// FOOTER ---------------------------------------------------------------------------------

export const FrameFooter = styled.div`
  background-color: ${(props) => props.theme.colors.headerBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  font-size: 0.8rem;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: 20px;
  }
`;

// TMP ---------------------------------------------------------------------------------

// para uso provisório
export const FrameTMPtitle = styled.div`
  font-weight: bold;
  font-size: 1.5em;
`;

// para uso provisório
export const FrameTMPlink = styled.div`
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  width: 200px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.iconsSecondary};
  &:hover {
    background-color: ${(props) => props.theme.colors.divBackground};
  }
`;

export const ScollableDiv = styled.div`
  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }
`;

export const ShowChartListButton = styled.button`
  position: absolute;
  right: 16px;
  position: absolute;
  color: ${(props) => props.theme.colors.iconsSecondary};
  background: none;
  border: none;
  border-radius: 100%;
  padding: 5px;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background-color: ${(props) => props.theme.colors.divBackgroundAlpha};

  :hover {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};

    transition: 0.3s;
  }

  @media screen and (max-width: 767px) {
    z-index: 102;
    left: 0.75rem;
    top: -3.5rem;
  }
`;
