import styled, { css, keyframes } from "styled-components";

export const Container = styled.div`
  padding: 1rem;
  ${(props) => props.theme.fonts.primary.H4Regular};
  overflow-y: auto;
  height: 650px;
  max-height: 650px;
  background: ${(props) => props.theme.backgroundColors.background1};
  border-radius: 10px;

  & > div:first-child {
    margin-bottom: 1rem;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }
`;

export const Title = styled.div<{ processing?: boolean }>`
  ${(props) => props.theme.fonts.primary.H3SemiBold};
  padding: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;

  > span {
    @keyframes flashlight {
      0%,
      50%,
      100% {
        opacity: 1;
      }
      25%,
      75% {
        opacity: 0;
      }
    }

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      background-color: yellow;
      filter: blur(1px);
      border-radius: 20px;
      left: 35px;
      top: 33px;
      opacity: 0.5;
      animation: ${({ processing }) =>
        processing && "flashlight 3s infinite both"};
      @media screen and (max-width: 767px) {
        top: 2rem;
        left: 2.15rem;
      }
    }
  }
`;

export const Button = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.iconsSecondary};
`;

export const MotherQuestion = styled.div`
  width: 70%;
  padding: 14px;
  border-radius: 5px;
  background: ${(props) => props.theme.backgroundColors.background0};
  margin-bottom: 10px;
`;

export const Conversation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MessageContainer = styled.div<{ isQuestion?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isQuestion ? "flex-end" : "flex-start")};
`;

export const SlideRight = keyframes`
    0% {
      transform: translateX(-80px);
    }
    100% {
      transform: translateX(0px);
    }
`;

export const SlideLeft = keyframes`
    0% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(-20px);
    }
`;

export const Message = styled.div<{
  isQuestion?: boolean;
  isText?: boolean;
  time?: string;
  isError?: boolean;
  isRating?: boolean;
  isPositiveRating?: boolean;
  blockClick?: boolean | undefined;
}>`
  padding: 10px;
  width: ${(props) => (props.isText ? "75%" : "60%")};
  margin-left: ${(props) => !props.isQuestion && !props.isText && "1rem"};
  border-radius: ${(props) =>
    props.isQuestion ? "1rem 0 1rem 1rem" : "0 1rem 1rem 1rem"};
  background: ${(props) =>
    props.isQuestion && !props.isText
      ? props.theme.backgroundColors.background3
      : props.theme.backgroundColors.background0};

  border: ${(props) =>
    !props.isQuestion &&
    !props.isText &&
    `1px solid ${props.theme.colors.iconsSecondaryAlpha}`};

  border: ${(props) => props.isError && "1px solid red"}!important;

  svg {
    color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    margin-left: 15px;
  }

  & > label:first-child {
    font-weight: ${({ isQuestion }) => isQuestion && "100"}!important;
    opacity: ${({ isQuestion }) => isQuestion && "0.5"};
    margin-bottom: ${({ isQuestion }) => isQuestion && "10px"};
  }

  :hover {
    cursor: ${(props) => !props.isQuestion && !props.isText && "pointer"};

    color: ${(props) => props.isText && props.theme.colors.iconsPrimary};

    background: ${(props) =>
      !props.isQuestion && props.theme.colors.iconsSecondary};

    background: ${(props) =>
      props.blockClick &&
      !props.isRating &&
      props.theme.backgroundColors.background0};

    background: ${(props) =>
      props.isText && props.theme.backgroundColors.background0};

    label {
      cursor: pointer;
    }
  }

  ${({ time }) =>
    css`
      animation: ${SlideRight} ${time} ease;
    `}

  ${({ isQuestion }) =>
    isQuestion &&
    css`
      animation: ${SlideLeft} 0.3s ease;
    `}
`;

export const OptionsContainer = styled.div`
  position: absolute;
  height: 2rem;
  top: 1.1rem;
  right: 1rem;
  display: flex;
  gap: 0.8rem;
  justify-content: space-between;
`;

export const Options = styled.div`
  position: absolute;
  top: -0.1rem;
  right: 1.9rem;
  border-radius: 15px;
  padding: 8px;
  background: ${(props) => props.theme.colors.inputBlock};
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 100px;

  button {
    background: none;
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.iconsSecondary}!important;
  }

  span:hover {
    cursor: pointer;
    svg {
      color: ${(props) => props.theme.colors.ibbxOrange}!important;
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: space-around;
    width: 100px;
    background: transparent;

    span:hover {
      cursor: pointer;
      svg {
        color: ${(props) => props.theme.colors.iconsSecondary}!important;
      }
    }
  }
`;
