import styled, { css } from "styled-components";

export const OccurrencePanelContainer = styled.div`
  background: ${({ theme }) => theme.colors.divBackground};
  border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
  border-radius: 6px;
  height: 100%;
  padding: 10px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  & > div:not(:first-child) {
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &::-webkit-scrollbar {
      width: 6px !important;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.colors.background};
      width: 20px;
      border-radius: 100vw;
      margin-block: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }

    &::-webkit-scrollbar-thumb:active {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }

    @media screen and (max-width: 767px) {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  @media screen and (max-width: 767px) {
  }
`;

export const OccurrenceInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (max-width: 767px) {
    padding-right: 0rem;
  }
`;

export const PrimaryTitle = styled.span`
  width: 100%;
  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H5Regular};
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    font-size: 10px;
  }
`;

export const SecondaryTitle = styled.span<{
  statusRisk?: boolean;
  historyTitle?: boolean;
}>`
  color: ${(props) => props.theme.colors.iconsPrimary};
  ${(props) => props.theme.fonts.primary.H5Regular};
  background-color: ${({ theme, statusRisk }) =>
    statusRisk ? theme.colors.cardUnderlineLightAlpha : "transparent"};
  padding: 0.3rem 0.5rem;
  display: flex;
  gap: 0.5rem;
  border-bottom: 1px solid
    ${({ theme, statusRisk }) =>
      statusRisk ? "transparent" : theme.colors.headerCardSecondary};
  @media screen and (max-width: 767px) {
    font-size: 0.6rem;
    padding: 0.1rem 0.5rem;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    font-size: 10px;
    padding: 0.15rem 0.5rem;
  }
`;

export const InfoContainer = styled.div<{
  extraInfo?: boolean;
  statusRisk?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  type?: "PREDICTIVE" | "CORRECTIVE";
  commentArea?: boolean;
  diagnosticRecomendation?: boolean;
  commentDiagnosticArea?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => (props.extraInfo ? "row" : "column")};
  width: 100%;
  border-radius: 6px;
  gap: 0.25rem !important;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.divBackground};

  span:not(:first-child) {
    color: ${(props) => props.theme.colors.iconsPrimary};
    ${(props) => props.theme.fonts.primary.H4Bold};
    display: block !important;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.5rem 0.5rem;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.iconsPrimary};
    transition: filter 0.3s ease;
    &:hover {
      filter: brightness(1.2);
    }
  }

  ${({ diagnosticRecomendation }) =>
    diagnosticRecomendation &&
    css`
      span:not(:last-child) {
        border-bottom: 1px solid
          ${(props) => props.theme.colors.iconsSecondaryAlpha};
      }
      span:first-child {
        border-bottom: 1px solid transparent;
      }
      span:not(:first-child) {
        min-height: 35px;
      }
    `}

  ${({ statusRisk, type }) =>
    statusRisk &&
    css`
      background-color: ${({ theme }) => {
        const { colors } = theme;
        if (type === "CORRECTIVE") {
          return colors.statusBroken;
        }

        switch (statusRisk) {
          case 7:
          case 6:
          case 5:
            return colors.statusRisk;
          case 4:
            return colors.ibbxOrange;
          case 3:
          case 2:
            return colors.statusAlert;
          case 1:
            return colors.statusNormal;
        }
      }};
      span {
        color: #dddfed !important;
      }
    `}

${({ commentDiagnosticArea }) =>
    commentDiagnosticArea &&
    css`
      background-color: ${(props) => props.theme.colors.inputBackground};
      span:not(:first-child) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    `}

  ${({ commentArea }) =>
    commentArea &&
    css`
      /* background-color: ${(props) => props.theme.colors.inputBackground}; */

      span:last-child {
        font-weight: 400;
        font-size: 12px;
        white-space: wrap;
      }
    `}

  ${({ extraInfo }) =>
    extraInfo &&
    css`
      span {
        ${(props) => props.theme.fonts.primary.H4Regular};
        margin-left: 0 !important;
        padding: 0.5rem 0 !important;
      }
      align-items: center;
      @media screen and (min-width: 767px) and (max-width: 1536px) {
        span {
          font-size: 11px;
          padding: 0 !important;
        }
      }
    `};

  @media screen and (max-width: 767px) {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    gap: 0 !important;
    span:not(:first-child) {
      padding: 0.25rem 0.5rem;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    span:not(:first-child) {
      font-size: 11px;
      padding: 0.25rem 0.5rem;
    }
  }
`;

export const StandardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-right: 0.25rem;
`;

export const StandardOccurrenceStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  gap: 0.5rem;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.occurrenceInfoBackground};

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    padding: 0.5rem;
  }
`;

export const StandardHeaderCollapse = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem;
  height: 2.4rem;
  position: relative;
  background-color: ${(props) => props.theme.colors.passwordBackground};
  ${({ theme }) => theme.fonts.primary.H4Bold};
  border-radius: 8px 8px 0 0;

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    font-size: 12px;
    height: 1.75rem;
    padding: 0.5rem;
  }
`;

export const GeneralInfo = styled(StandardOccurrenceStyled)`
  border-radius: 0 0 8px 8px;
  & > div:first-child {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    & > div:nth-child(-n + 3) {
      flex-basis: 20%;
    }
    & > div:nth-last-child(-n + 4) {
      flex: 1;
    }
  }

  & > div:nth-child(2) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    & > div {
      width: fit-content !important;
      display: flex;
      gap: 0.5rem;
      justify-content: flex-start;
      span {
        white-space: nowrap;
      }
      & > div {
        overflow: visible;
        padding: 0 0.5rem;
        @media screen and (min-width: 767px) and (max-width: 1536px) {
          padding: 0.25rem 0.5rem;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    & > div {
      flex-wrap: wrap;
    }

    & > div:nth-child(2) {
      & > div:nth-child(-n + 3) {
        flex-basis: 100%;
      }
      & > div:nth-last-child(-n + 2) {
        flex: 1;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1280px) {
  }

  @media screen and (min-width: 799px) and (max-width: 826px) {
    & > div:first-child {
      flex-wrap: wrap;
      & > div:nth-child(-n + 3) {
        flex-basis: 32%;
      }
      & > div:nth-last-child(-n + 4) {
        flex-basis: 24%;
      }
    }
  }
`;

export const GeneralInfoHeader = styled(StandardHeaderCollapse)`
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    margin-right: 0rem;
  }
`;

export const ExtraInfo = styled(StandardOccurrenceStyled)`
  padding: 0.5rem 0 0 0;
  & > div:not(:first-child) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    & > div {
      width: fit-content !important;
      display: flex;
      gap: 0.5rem;
      padding: 0.25rem 0.5rem;
      justify-content: flex-start;
      span {
        white-space: nowrap;
      }
    }
  }

  @media screen and (max-width: 767px) {
    & > div:not(:first-child) {
      gap: 0.5rem;
    }
  }
`;

export const DiagnosticHead = styled(StandardHeaderCollapse)``;

export const DiagnosticContainer = styled(StandardOccurrenceStyled)`
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  padding: 0;
  margin-top: 0.25rem;
  @media screen and (max-width: 767px) {
    overflow-x: auto;
  }
`;

export const DiagnosticTable = styled.table`
  max-width: 100%;
  overflow-x: hidden;

  button {
    border: 1px solid transparent;
    background-color: transparent;
    border-radius: 100%;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.iconsSecondary};
    transition: all 0.3s ease;
    opacity: 0.6;
    svg {
      width: 1.25rem;
      height: auto;
    }
    &:hover {
      transform: scale(1.1);
      opacity: 1;
    }
  }
  span {
    padding: 0 1rem;
  }
  th,
  td {
    padding: 0.75rem 0.5rem 0.75rem 0;
    ${(props) => props.theme.fonts.primary.H5Regular};
  }

  th {
    font-weight: 600;
  }

  thead {
    background-color: ${(props) => props.theme.colors.divBackground};
    border-bottom: 2px solid
      ${(props) => props.theme.colors.iconsSecondaryAlpha};

    tr {
      th {
      }
      th:nth-child(-n + 2) {
        width: 25%;
      }
      th:nth-child(3) {
        width: 70%;
      }
      th:last-child {
        flex: 1;
      }
    }
  }

  tbody {
    tr:not(:last-child) {
      border-bottom: 1px solid
        ${(props) => props.theme.colors.headerCardSecondary};
    }
  }

  @media screen and (max-width: 767px) {
    thead {
      tr {
        th:nth-child(-n + 3) {
          min-width: 85vw;
        }

        th:last-child {
          flex: 1;
        }
      }
    }
  }

  @media screen and (min-width: 799px) and (max-width: 826px) {
    span {
      padding: 0;
    }

    th,
    td {
      padding-left: 0.5rem !important;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    th,
    td {
      padding: 0.35rem 0.25rem 0.35rem 0;
      font-size: 11px;
    }
  }
`;

export const OcurrenceData = styled(StandardOccurrenceStyled)`
  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    .hourAndAction {
      flex-basis: 12.5%;
    }
    .comments {
      flex: 1;
    }
    @media screen and (max-width: 767px) {
      .hourAndAction {
        flex: 1;
      }
      .comments {
        flex-basis: 100%;
      }
    }
  }
`;

export const OccurrenceEvidencesListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;

  @media screen and (min-width: 799px) and (max-width: 826px) {
  }
`;

export const HistorycHeader = styled(StandardHeaderCollapse)`
  @media screen and (max-width: 767px) {
    margin-right: 0rem;
  }
`;

export const NoHistory = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    font-size: 0.75rem;
  }
`;

export const OccurrenceEvidencesGroupHeader = styled(StandardHeaderCollapse)<{
  disable?: boolean;
}>`
  & > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: 2.5rem;

    & > div {
      ${({ theme }) => theme.fonts.primary.H4Regular};
      display: flex;
      align-items: center;
      gap: 1rem;
      button {
        padding: 0.25rem 0.5rem;
      }

      div {
        color: ${(props) => props.theme.colors.iconsSecondary};
        ${({ theme }) => theme.fonts.primary.H5Regular};
      }
    }

    & > button {
      border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
      color: ${(props) => props.theme.colors.iconsSecondary};
      background-color: transparent;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  @media screen and (max-width: 767px) {
    margin-right: 0rem;
    z-index: 1;
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    & > div {
      & > div {
        font-size: 12px;
      }

      & > button {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }
`;

export const StandardButtonEvidence = styled.button<{
  disable?: boolean;
  addEvidence?: boolean;
  selectEvidence?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.passwordBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border-radius: 6px;
  padding: 0.25rem 0.5rem;
  border: none;
  pointer-events: ${({ disable }) => (disable ? "none" : "")};
  opacity: ${({ disable }) => (disable ? "0.5" : "1")};
  transition: all 0.3 ease;

  svg {
    width: 1.15rem;
    height: auto;
  }
  ${({ selectEvidence }) => selectEvidence && css``}

  ${({ addEvidence }) =>
    addEvidence &&
    css`
      padding: 0;
      border: none;
      transition: filter 0.3s ease;
      &:hover {
        filter: brightness(0.98);
      }
    `}

  &:hover {
    font-weight: 600;
    svg {
      transform: scale(1.1);
    }
  }
`;

export const RestoreEvidence = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border: none;
  /* background-color: ${(props) => props.theme.colors.passwordBackground}; */
  background-color: transparent;
  color: ${(props) => props.theme.colors.iconsSecondary};
  border-radius: 6px;
  padding: 0.25rem 0.5rem;

  &:hover {
    font-weight: 600;
    svg {
      transition: all 0.3s ease;

      transform: scale(1.1);
    }
  }
`;

export const OccurrenceEvidencesGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  background-color: ${(props) => props.theme.colors.cardSectorBackground};
  position: relative;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    margin-right: 0rem;
  }
`;

export const SelectButtonContainer = styled.div<{ checked?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3rem;
  left: 1.4rem;
  z-index: 2;
  & > button {
    border-radius: 100%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
    background-color: ${({ theme, checked }) =>
      checked ? theme.colors.iconsSecondary : "transparent"};

    svg {
      display: ${({ checked }) => !checked && "none"};
      color: ${({ theme }) => theme.colors.background};
      font-size: 16px;
    }

    @media screen and (max-width: 767px) {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;

export const CollapseLine = styled.div`
  height: 2rem;
  position: absolute !important;
  display: flex;
  justify-content: flex-end !important;
  right: 1rem;
  top: 0.25rem;
  transition: all 0.3s ease;
  cursor: pointer;
  color: ${(props) => props.theme.colors.iconsSecondary};
  z-index: 2;
  opacity: 0.25;

  svg {
    width: 1.8rem;
    height: auto;
  }
  :hover {
    border-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 30px;
    opacity: 0.75;
  }
  @media screen and (max-width: 767px) {
    right: 0.5rem;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    height: auto;
    top: 0.15rem;
    svg {
      width: 1.4rem;
      height: auto;
    }
  }
`;

export const CollapseEvidences = styled(CollapseLine)``;
export const CollapseHistory = styled(CollapseLine)``;
export const CollapseGeneralInfo = styled(CollapseLine)<{
  showStatus?: boolean;
}>`
  ${({ showStatus }) =>
    showStatus &&
    css`
      color: #dddfed;
    `}
`;
export const CollapseDiagnostic = styled(CollapseLine)`
  right: 1rem;
`;

export const OccurrenceEvidencesGroupItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 0.5%;
  padding: 1rem;
  border-radius: 0 0 8px 8px;
  width: 100%;

  @media screen and (min-width: 768px) and (max-width: 1280px) {
    gap: 1rem 1%;
  }

  @media screen and (min-width: 1280px) and (max-width: 1536px) {
    padding: 0.5rem;
  }
`;

interface IEvidenceListCheckContainer {
  checked?: boolean;
  disabled?: boolean;
}

export const EvidenceListCheckContainer = styled.div<IEvidenceListCheckContainer>`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.divBackgroundAlpha};
  position: absolute;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  & > button {
    position: absolute;
    left: 0.5rem;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.iconsSecondary};
    background-color: ${({ theme, checked }) =>
      checked ? theme.colors.iconsSecondary : "transparent"};

    svg {
      display: ${({ checked }) => !checked && "none"};
      color: ${({ theme }) => theme.colors.background};
      font-size: 16px;
    }

    @media screen and (max-width: 767px) {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;

export const EvidenceContainer = styled.div`
  display: flex;
  flex: 0 0 33%;
  border-radius: 6px;
  position: relative;

  background-color: ${({ theme }) => theme.colors.cardBackground};

  @media screen and (max-width: 767px) {
    flex: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1280px) {
    flex-basis: 49%;
  }
`;

export const DeleteEvidence = styled.button<{ disable?: boolean }>`
  pointer-events: ${({ disable }) => (disable ? "none" : "")};
  opacity: ${({ disable }) => (disable ? "0.3" : "1")} !important;
`;

export const GeneralInfoMobile = styled.div`
  display: flex;
  flex-direction: row !important;
  gap: 1rem;
  flex-wrap: nowrap !important;
  margin-bottom: 0.5rem;
`;

export const InfoContainerMobile = styled(InfoContainer)`
  flex-basis: 50% !important;
`;

export const EvidenceMenuMobile = styled.div<{ openEditEvidence?: boolean }>`
  display: flex;
  width: 100%;
  margin-top: ${({ openEditEvidence }) => (openEditEvidence ? "0" : "-2.5rem")};
  opacity: ${({ openEditEvidence }) => (openEditEvidence ? "1" : "0")};
  padding: 0.4rem 0.5rem;
  background-color: ${(props) => props.theme.colors.passwordBackground};
  transition: all 0.3s ease-in-out;
  ${({ theme }) => theme.fonts.primary.H4Bold};
  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    button {
    }

    & > div {
      flex: 1;
      span:nth-child(2) {
        display: none;
      }
    }
  }
`;

export const StatusFlag = styled.div<{
  statusRisk?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  type?: "PREDICTIVE" | "CORRECTIVE";
  showStatus?: boolean;
}>`
  width: 10rem;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0 1rem;
  align-items: center;
  position: absolute;
  right: -8rem;
  opacity: 0;
  transition: all 0.3s ease;

  ${({ statusRisk, type }) =>
    statusRisk &&
    css`
      background-color: ${({ theme }) => {
        const { colors } = theme;
        if (type === "CORRECTIVE") {
          return colors.statusBroken;
        }

        switch (statusRisk) {
          case 5:
            return colors.statusRisk;
          case 4:
            return colors.ibbxOrange;
          case 3:
          case 2:
            return colors.statusAlert;
          case 1:
            return colors.statusNormal;
        }
      }};
      span {
        color: #dddfed !important;
      }
    `}

  ${({ showStatus }) =>
    showStatus &&
    css`
      right: 0;
      opacity: 1;
    `}
`;

export const EmptyEvidenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: fit-content;
  height: fit-content;
  padding: 2rem;
  ${({ theme }) => theme.fonts.primary.H4Bold};
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  svg {
    width: 4rem;
    height: auto;
  }
`;
