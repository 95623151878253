import { useEffect, useState } from "react";
import { ConfirmDeleteOccurrenceProps } from "./types";
import { useTranslation } from "react-i18next";
import { ConfirmRecall } from "../ConfirmRecall";
import useApi from "../../hooks/useApi";
import { useAppSelector } from "../../store/hooks";
import { PROFILES, userSelect } from "../../store/slices/userSlice";
import { LoadingInput } from "../../components/LoadingInput";

const ConfirmDeleteOccurrenceModal = ({
  show,
  handleClose,
  handleConfirm,
  action,
}: ConfirmDeleteOccurrenceProps) => {
  const [comments, setComments] = useState("");
  const [error, setError] = useState<undefined | string>(undefined);
  const [selectError, setSelectError] = useState<undefined | string>(undefined);

  const [exclusionReasonsOptions, setExclusionReasonsOptions] =
    useState<any>(null);
  const [selectedExclusionReasonOption, setSelectedExclusionReasonOption] =
    useState<undefined | string>(undefined);

  const { t } = useTranslation();
  const { request, processing: processingOptions } = useApi({
    path: "/occurrence-exclusion-reason-options",
  });
  const user = useAppSelector(userSelect);

  const showExclusionReasons = [
    PROFILES.EXECUTIVO_MASTER,
    PROFILES.MESA_MASTER,
  ].includes(user?.profile ?? "");

  const handleComments = (event: any) => {
    if (error) {
      setError("");
      setSelectError("");
    }
    setComments(event.target.value);
  };

  const handleModalConfirm = () => {
    if (processingOptions) {
      return;
    }

    if (
      showExclusionReasons &&
      (selectedExclusionReasonOption === "" || !selectedExclusionReasonOption)
    ) {
      setSelectError("Selecione o motivo da exclusão");
      return;
    }

    if (
      showExclusionReasons &&
      selectedExclusionReasonOption === "Outro" &&
      !comments
    ) {
      setError("Comentário Obrigatório");
      return;
    }

    if (!showExclusionReasons && !comments) {
      setError("Comentário Obrigatório");
      return;
    }

    handleConfirm(comments, selectedExclusionReasonOption);
  };

  useEffect(() => {
    if (showExclusionReasons) {
      request({ method: "get" }).then((response) => {
        const exclusionReasons = [
          {
            label: "Selecione o motivo da exclusão",
            value: "",
          },
          ...response,
          { label: "Outro", value: "Outro" },
        ];

        setExclusionReasonsOptions(exclusionReasons);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderExclusionReasons() {
    if (!showExclusionReasons) {
      return;
    }

    if (processingOptions) {
      return <LoadingInput />;
    }

    return (
      <ConfirmRecall.FormSelect
        optionSelect={exclusionReasonsOptions}
        value={selectedExclusionReasonOption}
        handleChange={(e) => {
          setError(undefined);
          setSelectError(undefined);
          setSelectedExclusionReasonOption(e.target.value);
        }}
        selectError={selectError}
      />
    );
  }

  return (
    <ConfirmRecall
      show={show}
      handleClose={handleClose}
      handleConfirm={() => handleModalConfirm()}
      confirmLabel={action || t("commonText.confirm") || ""}
    >
      <ConfirmRecall.ReasonContent title={t("confirmDeleteOccurrence.title")}>
        {renderExclusionReasons()}
        <ConfirmRecall.CommentArea
          error={error}
          spellCheck={false}
          rows={5}
          cols={40}
          placeholder={`${t("ocurrenceRegisterModal.observation")}`}
          onChange={handleComments}
          value={comments}
        />
      </ConfirmRecall.ReasonContent>
    </ConfirmRecall>
  );
};

export default ConfirmDeleteOccurrenceModal;
