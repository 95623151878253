import styled from "styled-components";

export const ContainerChart = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100% !important;
  height: 100% !important;

  & > div {
    width: 100% !important;
  }

  & .google-visualization-tooltip {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    background-color: ${(props) => props.theme.colors.headerBackground};
    ${(props) => props.theme.fonts.secondary.H2Regular}
    border-radius: 6px;
    box-shadow: none;
    width: 100%;
    min-width: fit-content;
    max-width: max-content;

    p {
      margin-bottom: 3px !important;
    }

    hr {
      color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      margin-top: 3px !important;
      margin-bottom: 3px !important;
    }
  }

  div.google-visualization-tooltip > ul > li > span {
    color: ${(props) => props.theme.colors.iconsSecondary} !important;
  }
`;
