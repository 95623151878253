import styled, { keyframes } from "styled-components";
import { FrameBodyRight } from "../../../components/frame/styles";
import { ButtonNewUser } from "../Users/styles";

export const RepresentativeContainer = styled(FrameBodyRight)`
  width: 100%;
  margin: 0;

  @media screen and (max-width: 767px) {
    opacity: 1;
    width: 100vw;
    margin-left: 0;
    height: calc(100vh - 7.5rem);
    overflow: hidden;
  }
`;

export const ContainerPage = styled.div`
  padding: 2% 4% 4%;
  height: calc(100% - 2px);

  background-color: ${(props) => props.theme.colors.background};
  & > h2 {
    ${(props) => props.theme.fonts.primary.H1Semibold};
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
  @media screen and (max-width: 767px) {
    padding: 4px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  & > h2 {
    ${(props) => props.theme.fonts.primary.H1Semibold};
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
  @media screen and (max-width: 767px) {
    margin: 0.6rem 0 1rem 4rem;
    & > h2 {
      font-size: 1.25rem;
    }
  }
`;

export const ButtonNewRepresentative = styled(ButtonNewUser)`
  width: 200px;
  position: absolute;
  top: 2.5rem;
  right: 4.25rem;

  :hover {
    opacity: 0.85;
  }
  @media screen and (max-width: 767px) {
    margin: 0 !important;
    height: 3.5rem;
    width: 3.5rem;
    top: 1rem;
    right: 1rem;
    background-color: ${(props) => props.theme.colors.IconsTools} !important;

    svg {
      color: ${(props) => props.theme.colors.cardHighlight} !important;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    right: 3rem;
    top: 2rem;
  }
`;

export const ContainerRepresentatives = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.iconsPrimary};

  width: 100%;
  height: 100%;
  overflow-y: auto;
  border: 1px solid ${(props) => props.theme.colors.passwordBackground};
  background-color: ${(props) => props.theme.colors.divBackground};
  border-radius: 6px;

  h3 {
    width: 100%;
    ${(props) => props.theme.fonts.primary.H3SemiBold};
    color: ${(props) => props.theme.colors.iconsPrimary};

    margin-bottom: 16px;
  }

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: #e0e9f0;
    width: 20px;
    border-radius: 100vw;
    /* margin-block: 10px; */
  }

  &::-webkit-scrollbar-thumb {
    background: #717a9b;
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #475271;
  }

  &::-webkit-scrollbar-thumb:active {
    background: #475271;
  }

  @media screen and (max-width: 767px) {
    align-items: center;
    flex-direction: column;
  }
  @media screen and (min-width: 767px) and (max-width: 1536px) {
    height: calc(100% - 8rem);
  }
`;

export const SideBarRepresentatives = styled.div`
  background: ${(props) => props.theme.colors.passwordBackground};
  display: flex;
  flex-direction: column;
  width: 460px;
  height: 100%;

  & > div:first-child {
    padding: 20px 24px 0;
    display: flex;
    justify-content: center;
  }

  & > div:nth-child(3) {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px !important;
    }

    &::-webkit-scrollbar-track {
      background: #e0e9f0;
      width: 20px;
      border-radius: 100vw;
      /* margin-block: 10px; */
    }

    &::-webkit-scrollbar-thumb {
      background: #717a9b;
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #475271;
    }

    &::-webkit-scrollbar-thumb:active {
      background: #475271;
    }

    & > div:last-child {
      margin-bottom: 38px;
    }
  }

  @media screen and (max-width: 767px) {
    & > div:first-child {
      padding: 0.8rem 0.6rem 0;
      display: flex;
      justify-content: center;
    }
    hr {
      margin: 0 0.5rem 0.5rem;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    width: 300px;
  }
`;

export const RepresentativeItem = styled.div<{ selected?: boolean }>`
  background: ${(props) => props.selected && props.theme.colors.cardHighlight};
  ${(props) => props.theme.fonts.primary.H4Regular}
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 0 24px;
  align-items: center;
  height: 40px;
  color: ${(props) => props.theme.colors.textPrimary};

  cursor: pointer;

  button {
    display: flex;
    justify-content: center;
    border: none !important;
    background: none !important;

    svg {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }

  &:hover {
    background: #475271;
    color: #f3f6f8;
    svg {
      color: #f3f6f8;
    }
  }

  svg {
    color: ${(props) => props.theme.colors.textPrimary};
    color: ${(props) => props.selected && props.theme.colors.ibbxOrange};
  }
  @media screen and (max-width: 767px) {
    padding: 0 0.2rem 0 0.4rem;

    div {
      width: 100%;

      span {
        display: flex;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      button {
        margin-right: 5px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 32px;

  form {
    max-height: calc(100% - 40px);
    margin-top: 32px;
    width: 100%;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0;
    overflow: none;

    & > div:first-child {
      margin-bottom: 1rem;
      transform: translate(-50%);
      & > div {
        padding: 0.5rem 0;
      }
    }

    & > div:nth-child(2) {
      overflow: visible;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
  }
`;

export const AlarmInnerForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h4 {
    color: ${(props) => props.theme.colors.iconsSecondary};
    ${(props) => props.theme.fonts.primary.H5Bold};
  }
`;

export const GeneralAlarmRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 32px;
`;

export const GeneralAlarmInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  height: 100%;

  & > label {
    color: ${(props) => props.theme.colors.iconsPrimary};
    ${(props) => props.theme.fonts.primary.H5Regular};
  }

  & > input {
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.iconsSecondary};
    background-color: ${(props) => props.theme.colors.inputBackground};
    padding: 5px;
    ${(props) => props.theme.fonts.primary.H4Regular};
  }
`;

export const VerticalSeparator = styled.div`
  width: 0px !important;
  height: 36px;
  background-color: ${(props) => props.theme.colors.inputBlock};
  border: 1px solid ${(props) => props.theme.colors.inputBlock};
  margin: 0;
  padding: 0;
`;

export const MultipleAlarmContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  --columns: 5;
  --gap: 30px;
  --gap-count: calc(var(--columns) - 1);
  gap: var(--gap);

  @media screen AND (max-width: 3840px) {
    --columns: 5;
  }

  @media screen AND (max-width: 2560px) {
    --columns: 4;
  }

  @media screen AND (max-width: 1920px) {
    --columns: 3;
  }

  @media screen AND (max-width: 1366px) {
    --columns: 2;
  }

  @media screen AND (max-width: 1024px) {
    --columns: 1;
  }
`;

export const MultipleAlarmInput = styled.div`
  flex: 0 1
    calc(
      calc(100% / var(--columns)) -
        (calc(calc(var(--gap) * var(--gap-count))) / var(--columns))
    );
  width: calc(100%-15px);

  & > label {
    color: ${(props) => props.theme.colors.iconsPrimary};
    ${(props) => props.theme.fonts.primary.H5Regular};
  }

  & > div {
    display: flex;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.passwordBackground};
    border-radius: 6px;
    overflow: hidden;

    & > div {
      display: flex;
      width: 100%;
      flex-direction: column;
      height: 46px;

      & > input {
        width: 100%;
        height: 100%;
        padding: 2px 8px;
        color: ${(props) => props.theme.colors.iconsSecondary};
        background-color: ${(props) => props.theme.colors.inputBackground};
        ${(props) => props.theme.fonts.primary.H4Regular};
        border: none;
      }
      input:focus {
        outline: none;
        border: none;
      }
      & > label {
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.colors.iconsSecondary};
        ${(props) => props.theme.fonts.secondary.H4Bold};
        background-color: ${(props) => props.theme.colors.inputBackground};
        height: 100%;
        padding-left: 8px;
      }
    }
  }
`;

export const TabContent = styled.div`
  padding-top: 8px;
  position: relative;
`;

export const TabContentSummary = styled(TabContent)`
  padding-top: 32px;
  @media screen and (max-width: 767px) {
  }
`;

export const TabContentComanyData = styled(TabContent)`
  form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > div:first-child,
    & > div:nth-child(2) {
      margin-bottom: 16px;
      width: 90%;
      label {
        ${(props) => props.theme.fonts.secondary.H3Bold};
        margin-left: 6px;
      }
    }
    & > div:last-child {
      width: 90%;
    }
  }
`;

export const CompanyMenuMobile = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.headerBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  padding: 0.3rem;

  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    margin-left: 1rem;
    h3 {
      margin: 0;
    }
    span {
      ${(props) => props.theme.fonts.primary.H4Bold};
      color: ${(props) => props.theme.colors.iconsPrimary};
    }
    & > div {
      z-index: 2;
      top: 0;
    }
  }

  button {
    border: none;
    background-color: transparent;
    width: 2rem;
    height: 2rem;
    svg {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }
`;

export const ButtonDeleteRepresentative = styled.button`
  width: 2rem !important;
  height: 2rem !important;
  svg {
    width: 1.3rem;
    height: 1.3rem;
  }
`;

export const ShowCompaniesButton = styled.button`
  border: none;
  background-color: transparent;
  width: 2rem;
  height: 2rem;

  svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
`;

export const ShowUpToDown = keyframes`
 0% {
    transform: scaleY(0);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }

`;

export const CompanyMenuDropdown = styled.div`
  width: 95%;
  height: auto;
  position: absolute;
  top: 8rem;
  z-index: 2;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  background-color: ${(props) => props.theme.colors.headerBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border-radius: 6px;
  padding: 0.5rem 0;
  animation: ${ShowUpToDown} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  -webkit-box-shadow: 0px 8px 13px -4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 8px 13px -4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 8px 13px -4px rgba(0, 0, 0, 0.25);

  div {
    display: flex;
    align-items: center;
    span {
      margin-left: 0.5rem;
    }
  }
`;
