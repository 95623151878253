import styled from "styled-components";
export const Body = styled.div`
  width: 90%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem !important;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: transparent;

  form {
    width: 100%;
  }
  & > div {
    padding-right: 6px;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
    @media screen and (max-width: 767px) {
      width: 3px !important;
    }
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media screen and (max-width: 767px) {
    height: 100%;
  }
`;
