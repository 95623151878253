import React, { useMemo } from "react";
import {
  useTable,
  useExpanded,
  useFlexLayout,
  HeaderGroup,
  useBlockLayout,
} from "react-table";
import { Table as RBTable } from "react-bootstrap";

import { ReactTableProps } from "./types";
import { EmptyAsset } from "../../pages/Assets/styles";
import { ReactTableEmptyBody } from "./stylets";
import { ImFileEmpty } from "react-icons/im";
import { Loading } from "../Loading";

// Referência:
// https://react-table-v7.tanstack.com/

function ReactTable({
  data,
  columns,
  expanded,
  flexLayout,
  blockedLayout,
  getHeaderProps,
  renderRowSubComponent,
  renderEmptyResult,
  messageWhenTableIsEmpty = "Empty Table",
  isEmpty,
  isLoading,
  ...others
}: ReactTableProps) {
  const memorizedData = useMemo(() => data, [data]);
  const memorizedColumns = useMemo(() => columns, [columns]);

  const plugins = [];
  let options = {};

  if (flexLayout) {
    plugins.push(useFlexLayout);
  }

  if (blockedLayout) {
    plugins.push(useBlockLayout);
  }

  if (expanded) {
    plugins.push(useExpanded);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns: memorizedColumns,
      data: memorizedData,
      ...options,
    },
    ...plugins,
  );

  if (isLoading) {
    return (
      <>
        <RBTable {...getTableProps()} {...others}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: HeaderGroup<object>) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </RBTable>
        <ReactTableEmptyBody>
          <EmptyAsset>
            <Loading />
          </EmptyAsset>
        </ReactTableEmptyBody>
      </>
    );
  }

  if (isEmpty) {
    return (
      <>
        <RBTable {...getTableProps()} {...others}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: HeaderGroup<object>) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </RBTable>
        <ReactTableEmptyBody>
          <EmptyAsset>
            <ImFileEmpty size={60} />
            <span>{messageWhenTableIsEmpty}</span>
          </EmptyAsset>
        </ReactTableEmptyBody>
      </>
    );
  }

  return (
    <RBTable {...getTableProps()} {...others}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: HeaderGroup<object>) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.length > 0 ? (
          rows
            .filter((row: any) => row.depth === 0)
            .map((row: any) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                  {renderRowSubComponent && row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })
        ) : (
          <tr>
            <td className="border-0" colSpan={visibleColumns.length}>
              {renderEmptyResult}
            </td>
          </tr>
        )}
      </tbody>
    </RBTable>
  );
}

export default ReactTable;
