import styled from "styled-components";
import { css } from "styled-components";

//menu gráficos globais, dinâmicos e lista de ocorrência
export const TabContainer = styled.div<{
  facilityLevel?: boolean;
  companyLevel?: boolean;
  iosDevices?: any;
  alterSpace?: any;
}>`
  display: flex;
  width: 82%;
  justify-content: ${({ facilityLevel }) =>
    facilityLevel ? "flex-start" : "space-around"};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: flex-start;
  justify-content: ${({ alterSpace }) =>
    alterSpace > 3 ? "flex-start" : "space-around"};
  padding: 0.25rem;
  gap: 0.25rem;
  position: absolute !important;
  z-index: 10;
  overflow: auto hidden;
  border-radius: 32px;
  left: 50%;
  transform: translate(-42%);
  background: ${(props) => props.theme.colors.backgroundNavigation};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  bottom: 0;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);

  ${({ iosDevices }) =>
    iosDevices &&
    css`
      margin-bottom: 3.5rem;
    `}

  ${({ companyLevel }) =>
    companyLevel &&
    css`
      width: 80%;
      transform: translate(-50%);
    `}

  & > div {
    flex: 1;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    & > div:nth-child(2) {
      display: none;
    }
  }
`;

export const TabNameDiv = styled.div<{ selected?: boolean; alterSpace?: any }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  padding: 0.15rem 0.75rem;
  border-radius: 32px;
  min-width: ${({ alterSpace }) => (alterSpace > 3 ? "7rem" : "2rem")};
  transition: background-color 0.2s ease-in-out;
  scale: 0.9;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  scrollbar-width: none;

  overscroll-behavior: none;

  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0px -1.5px 2px 0px ${(props) => props.theme.colors.divBackground};
      scale: 1;
      transition: all 0.3s ease;
      background: ${(props) => props.theme.colors.cardBackground};
    `}
`;

export const TabDescription = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

export const TabIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 1.8rem;
    height: auto;
    color: ${(props) => props.theme.colors.iconsSecondary};
    &:hover {
      cursor: pointer;
    }
  }
`;

export const TabName = styled.div<{
  selected?: boolean;
}>`
  ${(props) => props.theme.fonts.primary.H4Regular}
  color: ${(props) => props.theme.colors.iconsSecondary};
  font-weight: ${(props) => props.selected && 700};
  height: 18px;
  &:hover {
    cursor: pointer;
  }

  span {
    opacity: ${(props) => (props.selected ? "1" : "0.5")};
    font-size: ${(props) => (props.selected ? "13px" : "12px")};
  }
`;

export const Selection = styled.div<{ selected?: boolean; error?: boolean }>`
  display: ${(props) => (props.selected ? "block" : "none")};
  width: 100%;
  background-color: ${({ theme, error }) =>
    error ? "red" : theme.colors.iconsSecondary};
  height: 2px;
  border-radius: 10px;
`;

export const TabContent = styled.div<{ adjustHeight: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(${(props) => props.adjustHeight} - 38px);
  & > div:first-child {
    display: flex;
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    height: 86%;
  }

  @media screen and (max-width: 767px) {
    overflow: auto;
    height: 100%;
    width: 100%;
    margin-top: 1rem;
  }

  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
    width: 99%;
  }
`;

export const TabErrorBall = styled.div`
  position: absolute;
  top: 0px;
  right: -19px;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  background-color: red;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  ${({ theme }) => theme.fonts.secondary.H1Bold};
  line-height: 8px;
`;
