import styled from "styled-components";
import {
  CardDivInfo,
  CardDivName,
  SidebarItemDiv,
} from "../../../components/Sidebar/styles";

export const Contract = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  padding-right: 4px;
  height: 26px;
  margin-top: 8px;
  ${(props) => props.theme.fonts.primary.H5Bold}
  color: ${(props) => props.theme.colors.textSecondary};

  svg {
  }
`;

export const CompanyInfoContainer = styled(SidebarItemDiv)`
  padding: 8px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 767px) {
    max-width: 100%;
    margin-left: 0.5rem;
  }
`;

export const HideMenu = styled.button`
  position: absolute;
  right: 4px;
  top: 6px;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.colors.iconsSecondary};

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 60px;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    width: 32px;
    height: 32px;

    svg {
      width: 22px;
      height: auto;
    }
  }

  // Disable inside clickable elements
  & > * {
    pointer-events: none;
  }
`;
export const TriangleCardSidebarMenu = styled.div`
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  top: 15px;
  right: 30px;
  border-left: 6px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  position: absolute;

  @media (max-width: 767px) {
    right: 36px;
    top: 15px;
  }
`;
//@ts-ignore
export const MenuExpanded = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column !important;
  height: auto;
  top: 6px;
  padding: 5px 0 0 0;
  right: 36px;
  min-width: 135px;
  color: ${(props) => props.theme.colors.iconsSecondary} !important;
  background-color: ${(props) => props.theme.colors.cardBackground}!important;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight}!important;
  ${(props) => props.theme.fonts.primary.H5SemiBold};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  overflow: hidden;

  & > * {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  svg {
    margin-right: 10px;
  }

  & > div:nth-child(3),
  & > div:nth-child(4) {
    button {
      border: none;
      height: 30px;
      cursor: pointer;
      background: transparent;
      ${(props) => props.theme.fonts.primary.H4Bold};
      color: ${(props) => props.theme.colors.iconsSecondary};
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      svg {
        width: 16px;
        height: auto;
      }
    }
  }

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    height: 35px;
    ${(props) => props.theme.fonts.primary.H4Bold};
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.colors.cardHighlight};
    }

    .csv {
      border: 1 solid red;
      color: white;
    }
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  }
  @media screen and (max-width: 767px) {
    width: 12.2rem;
    background-color: ${(props) => props.theme.colors.divBackground};
    padding: 0.5rem 0rem;
    top: 4px;
    right: 42px;
    div {
      display: flex;
      align-items: center;
      padding: 0px 8px;

      svg {
        width: 16px;
      }
    }

    & > div:first-child {
      margin-bottom: 0.6rem;
    }
  }
`;

export const CardCompanyDivName = styled(CardDivName)`
  @media screen and (min-width: 391px) and (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 292px;

    button {
      border: none;
      width: 2rem;
      background: transparent;
      color: ${(props) => props.theme.colors.iconsSecondary};

      svg {
        width: 1.4rem;
        height: auto;
      }

      &:hover {
        color: ${(props) => props.theme.colors.cardHighlight};
      }
    }

    label {
      max-width: 292px;

      @media screen and (max-width: 360px) {
        max-width: 256px !important;
      }

      @media screen and (min-width: 361px) and (max-width: 390px) {
        max-width: 284px;
      }
    }

    button:last-child {
      margin: 0 0.5rem 0 0.2rem;
    }
  }
`;

export const CardCompanyDivInfo = styled(CardDivInfo)`
  flex-direction: column;
  gap: 8px;
  height: auto;
  position: relative;

  /* & > div:first-child {
    order: 1;
  }
  & > div:nth-child(2) {
    order: 2;
  }

  & > div:nth-child(n + 3) {
    right: 0.5rem;
    bottom: 0rem;
  }

  & > div:nth-child(3) {
    order: 5;
  }
  & > div:nth-child(4) {
    right: 1.5rem;
    order: 4;
  }
  & > div:nth-child(5) {
    right: 2.5rem;

    order: 3;
  } */

  & > div:first-child {
    display: flex;
    gap: 16px;
  }
`;

export const OwnerAnalysisContainer = styled.div`
  position: absolute;
  right: 0.5rem;
  bottom: 0rem;
  display: flex;

  & > div:nth-child(1) {
    margin-right: -0.25rem;
    z-index: 2;
  }
  & > div:nth-child(2) {
    margin-right: -0.5rem;
    z-index: 1;
  }
  & > div:last-child {
    margin-right: 0;
  }
`;
