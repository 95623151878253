import styled from "styled-components";

export const ContainerCardDashboard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  color: ${(props) => props.theme.colors.iconsSecondary};

  span {
    ${(props) => props.theme.fonts.primary.H3SemiBold}
  }
`;

export const BoxCardDashboard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 1445px) {
    flex-wrap: wrap;
    justify-content: space-between;

    & > div {
      width: 45%;
      margin: 0 !important;
    }
  } ;
`;

export const SeparatorCardDashboard = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.cardHighlight};
  background: ${(props) => props.theme.colors.cardHighlight};
  /* font-size: 2rem; */
  width: 0.1rem;
  height: 5rem;
  margin: auto 0.6rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;

  @media screen and (max-width: 1445px) {
    display: none;
  } ;
`;

export const DataCardDashboard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;

  /* &:not(:last-child) {
    margin-right: 10px;
  } */

  span {
    ${(props) => props.theme.fonts.primary.H2boldCard};
    margin: 0;
  }

  label {
    ${(props) => props.theme.fonts.primary.H4Bold};
    text-align: center;
  }

  @media screen and (max-width: 1445px) {
    br {
      display: none;
    }
    span {
      ${(props) => props.theme.fonts.primary.H2boldCard};
      margin: 0;
    }

    label {
      ${(props) => props.theme.fonts.primary.H5Bold};
      text-align: center;
    }
  }
`;
