import { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryString from "../../utils/queryString";
import { getChartMocked } from "./types";
import useApi from "../../hooks/useApi";
import { IChart } from "../../store/interfaces";

export default function useChartAnalysis() {
  const location = useLocation();
  const { positionId } = useParams();
  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
    // eslint-disable-next-line
  }, [location.search]);

  const { request } = useApi({ path: `/positions/${positionId}` });

  const [charts, setCharts] = useState<any>({});

  const config = useMemo(() => {
    const result = {
      TITLE: true,
      ANALYSIS_TOOLS: true,
      CHART_TOOLS: true,
      LOADING: true,
      LOAD_DATA_IN_CHILD: false,
    };

    if (queryParams.embeded) {
      result.TITLE = false;
    }

    if (queryParams.chartName === "LUM-STT") {
      result.ANALYSIS_TOOLS = false;
      result.CHART_TOOLS = false;
      result.LOADING = false;
      result.LOAD_DATA_IN_CHILD = true;
    }

    return result;
    // eslint-disable-next-line
  }, []);

  let type = queryParams.type === "DYNAMIC" ? "DYNAMIC" : "GLOBAL";
  let chartName = queryParams.chartName || "AC-RMS";

  function loadChart() {
    let mockedChart = getChartMocked(chartName);
    if (mockedChart) {
      setCharts({ [mockedChart.type]: [mockedChart] });
    } else {
      request({ method: "get", pathParameters: "/chartList" }).then(
        (response) => setCharts(response?.charts),
      );
    }
  }

  const chart: IChart = useMemo(() => {
    if (!Object.keys(charts).length) {
      return {
        name: "",
        notFound: true,
      };
    }
    return charts?.[type]?.filter((item: any) => item.name === chartName)[0];
    // eslint-disable-next-line
  }, [charts]);

  return { config, chart, loadChart };
}
