import styled from "styled-components";
import { ConfigurationPagesContent } from "../../../../components/frame/styles";
import lightTheme from "../../../../styles/themes/light";

export const UserFormBody = styled(ConfigurationPagesContent)`
  width: 100%;
  @media screen and (max-width: 767px) {
    opacity: 1;
    width: 100vw;
    margin-left: 0;
    height: calc(100vh - 7.5rem);
    overflow: hidden;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.passwordBackground};
  border-radius: 6px;
  height: auto;
`;

export const UserFormLoading = styled.div`
  height: 400px;
  display: flex;
  div.spinner-border {
    color: ${lightTheme.colors.background};
  }
`;

export const ContainerUserForm = styled.div`
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 400px;

  & > div:nth-child(1) {
    border-radius: 3px 3px 0 0;

    background-color: ${(props) => props.theme.colors.passwordBackground};
    color: ${(props) => props.theme.colors.iconsPrimary};
    h3 {
      color: ${(props) => props.theme.colors.iconsPrimary};
    }
    label {
      color: ${(props) => props.theme.colors.iconsPrimary};
    }
  }

  & > div:nth-child(2) {
    color: ${(props) => props.theme.colors.iconsPrimary};
    label,
    h3 {
      color: ${(props) => props.theme.colors.iconsPrimary};
    }

    background-color: ${(props) => props.theme.colors.divBackground};
  }
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 32px;
  padding: 32px 24px 24px 0;
  border-radius: 0 0 3px 3px;

  height: auto !important;
  background-color: ${(props) => props.theme.colors.divBackground};

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.iconsPrimary};

    &:hover {
      border: 1px solid ${(props) => props.theme.colors.iconsPrimary};
      background: transparent !important;
      color: ${(props) => props.theme.colors.iconsPrimary};
    }
    &:active {
      box-shadow: 0 0 0 1px ${(props) => props.theme.colors.iconsPrimary};
    }
  }

  button {
    &:hover {
      border: 1px solid ${lightTheme.colors.iconsPrimary} !important;
    }

    &:active {
      border: none !important;
      box-shadow: 0 0 0 1px ${lightTheme.colors.iconsPrimary};
    }
  }

  @media screen and (max-width: 767px) {
  }
  padding-top: 0;
`;

export const FormSection = styled.div<{
  background?: string;
}>`
  background-color: ${(props) => props.background || null};
  padding: 24px;
  display: flex;
  flex-direction: column;

  h3 {
    ${(props) => props.theme.fonts.primary.H3SemiBold};
    color: ${lightTheme.colors.iconsPrimary};
    margin-bottom: 16px;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    --columns: 2;
    --gap: 32px;
    --gap-count: calc(var(--columns) - 1);
    gap: var(--gap);

    & > div {
      flex: 0 1
        calc(
          calc(100% / var(--columns)) -
            (calc(calc(var(--gap) * var(--gap-count))) / var(--columns))
        );
    }
  }

  @media screen and (max-width: 767px) {
    & > div:nth-child(2) {
      flex-direction: column;
      gap: 1rem;
    }
  }
`;

export const FormSectionCompanyContent = styled.div<{
  disabled?: boolean;
}>`
  & > div {
    opacity: ${(props) => props.disabled && "0.5"};
    cursor: ${(props) => props.disabled && "not-allowed"};
  }
`;

export const CustomFormInputWrapper = styled.div`
  height: 100%;
  & > div {
    height: 100%;

    label {
      margin-top: 0;
      padding-top: 0;
      ${(props) => props.theme.fonts.primary.H5Regular};
      color: ${lightTheme.colors.iconsPrimary};
    }
    
    input {
      background-color: #f3f6f8 !important;
      border: 1px solid ${lightTheme.colors.cardHighlight};
      color: ${lightTheme.colors.textPrimary};
    }

    input:focus {
      outline: ${lightTheme.colors.textPrimary};
    }
  }
`;
