import { ReactNode } from "react";
import { Container } from "./styles";
import { Modal } from "../../components/Modal";
import { ReasonContent } from "./Components/ReasonContent";
import { SelectRecall } from "./Components/SelectRecall";
import { SelectType } from "./Components/SelectType";
import { InputRadio } from "./Components/InputRadio";
import { InputCheckbox } from "./Components/InputCheckbox";
import { CommentArea } from "./Components/CommentArea";
import { FormSelect } from "./Components/FormSelect";

interface IConfirmRecall {
  children: ReactNode;
  show: boolean;
  handleClose: () => void;
  handleConfirm?: () => void;
  confirmLabel?: any;
  error?: string;
}

export function ConfirmRecall({
  children,
  show,
  handleClose,
  handleConfirm,
  confirmLabel,
  error,
}: IConfirmRecall) {
  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      confirmLabel={confirmLabel}
    >
      <Container error={error}>{children}</Container>
    </Modal>
  );
}

ConfirmRecall.ReasonContent = ReasonContent;
ConfirmRecall.SelectRecallContainer = SelectRecall;
ConfirmRecall.TypeContainer = SelectType;
ConfirmRecall.InputRadio = InputRadio;
ConfirmRecall.InputCheckbox = InputCheckbox;
ConfirmRecall.CommentArea = CommentArea;
ConfirmRecall.FormSelect = FormSelect;
