import styled from "styled-components";

export const Container = styled.div<{ hasMarginBottom?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 54px;
  margin-bottom: ${(props) => (props.hasMarginBottom ? "16px" : "0")};
  background-color: ${(props) => props.theme.colors.headerBackground};
  border-top: 1px solid ${(props) => props.theme.colors.cardHighlight};
  padding-left: 26px;
  padding: 24px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: row;
    }
  }

  & > div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    & > div {
      button {
        margin-left: 10px;
        background: transparent;
        border: none;
        color: ${(props) => props.theme.colors.iconsSecondary};

        &:hover {
          color: ${(props) => props.theme.colors.ibbxOrange};
        }
      }
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;

  & span {
    margin: 2px;
    color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    font-weight: 100;
  }

  & > div {
    display: flex;
    justify-content: space-evenly;
    width: 75px;
  }

  button {
    background: none;
    border: none;

    svg {
      height: 22px;
      width: 22px;
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }
`;

export const SelectDiv = styled.div``;

export const Button = styled.button<{ selected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;

  svg {
    color: ${(props) =>
      props.selected
        ? props.theme.colors.ibbxOrange
        : props.theme.colors.iconsSecondary}!important;
    width: 30px;
    height: 30px;
  }

  &:hover {
    scale: 0.98;
    width: 30px;
    height: 30px;
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha}!important;
  }
`;

export const NewOccurrenceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: ${({ theme }) => theme.colors.ibbxOrange};
    border-radius: 100%;
    border: none;
    font-size: 28px;
    color: white;
  }
`;
