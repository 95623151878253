import { Container } from "./styles";

interface ICommentArea {
  error?: string;
  spellCheck?: boolean;
  rows?: number;
  cols?: number;
  placeholder?: string;
  onChange: (event: any) => void;
  value?: any;
}

export function CommentArea({
  error,
  spellCheck,
  rows,
  cols,
  placeholder,
  onChange,
  value,
}: ICommentArea) {
  return (
    <Container
      error={error}
      spellCheck={spellCheck}
      rows={rows}
      cols={cols}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  );
}
