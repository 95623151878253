import { AiOutlineBarChart } from "react-icons/ai";
import { SeriesSelector } from "../charts/seriesSelector";
import {
  GraphicContainer,
  GraphicFooterButton,
  GraphicFooterContainer,
} from "./styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdShowChart } from "react-icons/md";
import { HiChartBar, HiLink } from "react-icons/hi";

function GraphicFooter({ children }: any) {
  return <GraphicFooterContainer>{children}</GraphicFooterContainer>;
}

function FooterButton({ children, ...rest }: any) {
  return <GraphicFooterButton {...rest}>{children}</GraphicFooterButton>;
}

function ViewModeToggle({ viewMode, handleToggle }: any) {
  return (
    <GraphicFooterButton
      title={
        viewMode === "COLUMN"
          ? "Visualizar como Linhas"
          : "Visualizar como Colunas"
      }
      onClick={handleToggle}
    >
      {viewMode === "COLUMN" ? (
        <MdShowChart size={20} />
      ) : (
        <HiChartBar size={20} />
      )}
    </GraphicFooterButton>
  );
}

function AnalysisLink({ getAnalysisLink }: any) {
  const { t } = useTranslation();
  return (
    <GraphicFooterButton
      as={Link}
      title={`${t("Graphic.analysis")}`}
      to={getAnalysisLink()}
    >
      <AiOutlineBarChart size={20} />
    </GraphicFooterButton>
  );
}

function CopyToClipboard({ getLinkToCopy }: any) {
  const { t } = useTranslation();

  return (
    <GraphicFooterButton
      onClick={getLinkToCopy}
      title={`${t("Copiar link embeded")}`}
    >
      <HiLink size={20} />
    </GraphicFooterButton>
  );
}

function GraphicCompound({ children, downloadRef }: any) {
  return <GraphicContainer ref={downloadRef}>{children}</GraphicContainer>;
}

GraphicCompound.Footer = GraphicFooter;
GraphicCompound.SeriesSelector = SeriesSelector;
GraphicCompound.FooterButton = FooterButton;
GraphicCompound.AnalysisLink = AnalysisLink;
GraphicCompound.CopyToClipboard = CopyToClipboard;
GraphicCompound.ViewModeToggle = ViewModeToggle;

export default GraphicCompound;
