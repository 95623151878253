const NOTIFICATIONS = {
  NEW_RELEASE: {
    push: false,
    email: false,
  },
  BOLTS_OFFLINE: {
    push: true,
    email: true,
  },
  SENSORS_RECALL: {
    push: false,
    email: false,
  },
  SENSORS_OFFLINE: {
    push: false,
    email: false,
  },
  OCCURRENCE_CREATED: {
    push: false,
    email: false,
  },
  SENSORS_LOW_BATTERY: {
    push: false,
    email: false,
  },
  ASSET_STATUS_UPDATED: {
    NORMAL: {
      push: false,
      email: false,
      whatsApp: false,
    },
    ALERT: {
      push: false,
      email: false,
      whatsApp: false,
    },
    RISK: {
      push: false,
      email: false,
      whatsApp: false,
    },
    CRITICAL: {
      push: false,
      email: false,
      whatsApp: false,
    },
  },
  OCCURRENCE_TO_EXPIRE: {
    push: false,
    email: false,
  },
  TO_EXPIRE_INTEGRATED_PEOPLE: {
    push: false,
    email: false,
  },
  OCCURRENCE_UPDATED_DIAGNOSTIC: {
    push: false,
    email: false,
    whatsApp: false,
  },
};

export const NOTIFICATIONS_SETTINGS ={ 
  NEW_RELEASE: {
    blocked: false,
  },
  BOLTS_OFFLINE: {
    blocked: false,
  },
  SENSORS_RECALL: {
    blocked: false,
  },
  SENSORS_OFFLINE: {
    blocked: false,
  },
  OCCURRENCE_CREATED: {
    blocked: false,
  },
  SENSORS_LOW_BATTERY: {
    blocked: false,
  },
  OCCURRENCE_TO_EXPIRE: {
    blocked: false,
  },
  TO_EXPIRE_INTEGRATED_PEOPLE: {
    blocked: false,
  },
};


export const ALL_NOTIFICATIONS = Object.entries(NOTIFICATIONS);

export const CLIENT_NOTIFICATIONS: Array<any> = Object.entries(
  NOTIFICATIONS,
).filter(([title]) =>
  [
    "NEW_RELEASE",
    "ASSET_STATUS_UPDATED",
    "OCCURRENCE_TO_EXPIRE",
    "OCCURRENCE_UPDATED_DIAGNOSTIC",
  ].includes(title),
);

export const ADMIN_NOTIFICATIONS: Array<any> = Object.entries(
  NOTIFICATIONS,
).filter(([title]) =>
  [
    "NEW_RELEASE",
    "BOLTS_OFFLINE",
    "SENSORS_RECALL",
    "SENSORS_OFFLINE",
    "OCCURRENCE_CREATED",
    "SENSORS_LOW_BATTERY",
    "ASSET_STATUS_UPDATED",
    "TO_EXPIRE_INTEGRATED_PEOPLE",
  ].includes(title),
);

export const REPRESENTATIVE_NOTIFICATIONS: Array<any> = Object.entries(
  NOTIFICATIONS,
).filter(([title]) =>
  [
    "NEW_RELEASE",
    "OCCURRENCE_CREATED",
    "ASSET_STATUS_UPDATED",
    "OCCURRENCE_TO_EXPIRE",
    "OCCURRENCE_UPDATED_DIAGNOSTIC",
  ].includes(title),
);
