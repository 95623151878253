import { useMemo, useState } from "react";
import UserHiearchyComponent from "../UserHiearchyComponent";
import UserFacilitiesAdd from "./UserFacilitiesAdd";
import { useField, useFormikContext } from "formik";
import { UserFacilitiesSelectorContainer } from "./styles";
import { MdAdd } from "react-icons/md";
import { RiFullscreenFill } from "react-icons/ri";
import useRetinaTranslation from "../../../../../../hooks/useRetinaTranslation";

interface IUserFacilitiesSelector {
  disabled?: boolean;
  error?: string;
}

export default function UserFacilitiesSelector({
  disabled,
}: IUserFacilitiesSelector) {
  const { errors } = useFormikContext<any>();
  const { translateLabels, lang } = useRetinaTranslation();
  const [{ value: companyId }] = useField("companyId");
  const [{ value: userFacilities }] = useField("userFacilities");
  const [dialog, setDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const text = useMemo(() => {
    switch (true) {
      case !(userFacilities || []).length:
        return translateLabels("None");
      case (userFacilities || []).length === 1:
        return userFacilities[0].facilityName;
      default:
        const total = (userFacilities || []).length;
        return `${total} ${translateLabels("Facilities")}`;
    }
    // eslint-disable-next-line
  }, [userFacilities, lang]);

  return (
    <UserFacilitiesSelectorContainer disabled={disabled}>
      <div>
        <label>{translateLabels("Facilities")}</label>
        <div>
          <input readOnly value={text} />
          <button
            type="button"
            onClick={() => setDialog(true)}
            disabled={disabled}
          >
            <MdAdd />
          </button>
          <button
            type="button"
            onClick={() => setExpanded(true)}
            disabled={disabled}
          >
            <RiFullscreenFill />
          </button>
          <UserFacilitiesAdd
            show={dialog}
            handleClose={() => setDialog(false)}
            companyId={companyId}
          />

          <UserHiearchyComponent
            show={expanded}
            handleClose={() => setExpanded(false)}
          />
        </div>
        {errors?.facilityId && (
          <span className="error">{errors.facilityId as string}</span>
        )}
      </div>
    </UserFacilitiesSelectorContainer>
  );
}
