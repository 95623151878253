import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 0 1.25rem;

  & > div {
    border-radius: 0.5rem !important;
    overflow: hidden;
    background-color: transparent !important;
    width: 24rem !important;

    & > div:last-child {
      padding: 0.8rem !important;
      background-color: ${(props) => props.theme.colors.divBackgroundAlpha};
      ${(props) => props.theme.fonts.secondary.H3Bold};

      & > div:first-child > div:first-child {
        margin-right: 1rem;
        & > div {
          width: 100% !important;
          height: 100% !important;
          border-radius: 6px !important;
          margin: 0 !important;
        }
      }
      & > div:first-child > div:last-child {
        margin-top: 0.5rem;
        & > div:last-child {
          display: none;
        }
      }

      & > div:last-child {
        & > div:first-child > div > div {
          input,
          label {
            font-size: 0.9rem !important;
          }
        }
        & > div:last-child > div {
          margin-top: 0 !important;
          svg {
            fill: ${(props) => props.theme.colors.iconsSecondary} !important;
          }
        }
      }
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    & > div {
      width: 16rem !important;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    & > div {
      margin: auto;
      width: 100% !important;
    }
  }
`;
