import { Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { Loading } from "../../components/Loading";

import { Modal } from "../../components/Modal";
import { FormikTextInput } from "../../components/textInput";
import { error, success } from "../../components/Toast";
import useApi from "../../hooks/useApi";

import { Container, Title } from "./styles";

import {
  ConfigurationEmpty,
  ConfigurationFormProps,
  ConfigurationModel,
} from "./types";
import { useTranslation } from "react-i18next";

export const ModalConfigurationForm = ({
  show,
  handleClose,
}: ConfigurationFormProps) => {
  const formRef = useRef<FormikProps<any>>(null);

  const { request, processing } = useApi({
    path: "/configuration",
  });

  const { t } = useTranslation();

  const [configurations, setConfigurations] =
    useState<ConfigurationModel>(ConfigurationEmpty);

  useEffect(() => {
    if (show) {
      request({ method: "get" }).then((response) => {
        if (response) {
          setConfigurations(response);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  function handleSubmitting(values: any) {
    request({ method: "put", body: values })
      .then(() => {
        success(`${t("ConfigurationForm.submitSuccess")}`);
        handleClose();
      })
      .catch(() => error(`${t("ConfigurationForm.submitError")}`));
  }

  return (
    <Modal
      handleClose={handleClose}
      show={show}
      handleConfirm={() => {
        formRef.current?.handleSubmit();
      }}
      confirmLabel={`${t("ConfigurationForm.confirm")}`}
      size="lg"
    >
      {processing ? (
        <Loading />
      ) : (
        <Formik
          innerRef={formRef}
          initialValues={configurations}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmitting}
        >
          {({ isSubmitting, errors, setValues, values, setFieldValue }) => (
            <Container>
              <Title>{`${t("ConfigurationForm.title")}`}</Title>
              <FormikTextInput
                type="number"
                name="batteryVoltageAlarm"
                label={`${t("ConfigurationForm.batteryVoltageAlarm")}`}
                error={null}
                placeholder={`${t("ConfigurationForm.batteryVoltageAlarm")}`}
              />
              <FormikTextInput
                type="number"
                name="signalQualityAlarm"
                label={`${t("ConfigurationForm.signalQualityAlarm")}`}
                error={null}
                placeholder={`${t("ConfigurationForm.signalQualityAlarm")}`}
              />
            </Container>
          )}
        </Formik>
      )}
    </Modal>
  );
};
