import { useState, useEffect } from "react";

function getStorageValue(key: any, defaultValue: any) {
  const saved = localStorage.getItem(key);

  if (!saved) {
    return defaultValue;
  }

  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export function useStateLocalStorage(key: any, defaultValue?: any): [any, any] {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export default useStateLocalStorage;
