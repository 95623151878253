import styled from "styled-components";
import {
  ConfigurationPagesContent,
  FrameBodyMain,
} from "../../../components/frame/styles";

export const FrameBodyProfile = styled(FrameBodyMain)`
  height: 93vh;
  padding: 0;
`;

export const ContainerProfile = styled(ConfigurationPagesContent)`
  padding: 2% 4% 4%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};

  @media screen and (max-width: 767px) {
    opacity: 1;
    padding: 4px;
    margin-left: 0;

    & > div {
      & > div:nth-child(2) {
        margin-bottom: 1rem;
        justify-content: space-evenly;
        div {
          font-size: 1rem;
        }
        & > div:first-child {
          margin-left: 1rem;
        }
        & > div:last-child {
          margin-left: 1rem;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1197px) {
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const HeaderDiv = styled.div`
  width: 100%;
  justify-content: flex-start;
  & > h2 {
    ${(props) => props.theme.fonts.primary.H1Semibold};
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
  margin-bottom: 10px;

  @media screen and (max-width: 767px) {
    margin: 0.6rem 0 1.5rem 8rem;
    & > h2 {
      font-size: 1.25rem;
    }
  }
`;

export const ProfileScreen = styled.div`
  display: flex;
  /* height: 549px; */
  width: 100%;
  font-family: "Lexend";
  background-color: ${(props) => props.theme.colors.divBackground};
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.passwordBackground};

  @media screen and (min-width: 768px) and (max-width: 1197px) {
    flex-direction: column;
    height: max-content;
  }
`;

export const ProfileData = styled.div`
  width: 60%;
  border-right-color: transparent;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  form {
    margin-top: 16px;
  }

  @media screen and (max-width: 767px) {
    background-color: ${(props) => props.theme.colors.passwordBackground};
    height: 100%;
    width: 100%;
    padding: 1.5rem 2rem;
    overflow: hidden;
    border-top-right-radius: 6px;

    & > div {
      padding: 0;
      & > label {
        display: none;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1197px) {
    width: 100%;
    padding: 1rem;
    & > div {
      padding: 0;
    }
    & > div > div > form > div {
      margin: 0;
    }
  }
`;

export const PasswordData = styled.div`
  background-color: ${(props) => props.theme.colors.passwordBackground};
  padding: 24px 44px 128px 24px;
  width: 40%;

  & > form {
    input {
      border: 1px solid ${(props) => props.theme.colors.background} !important;
      color: ${(props) => props.theme.colors.iconsSecondary};
      background-color: ${(props) =>
        props.theme.colors.inputBackground} !important;
    }
    svg {
      color: #3a4058;
    }
  }

  & > label {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    padding: 1.5rem 2rem;
    flex-direction: column;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    & > label {
      display: none;
      color: ${(props) => props.theme.colors.iconsPrimary};
    }
    & > form {
      margin-top: 1rem;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1197px) {
    width: 100%;
    height: 100%;
    padding: 1rem;

    & > label {
      margin-top: 0;
    }

    & > form {
      margin-top: 1rem;
    }
    & > form > div:last-child {
      margin-top: 0;
      position: static;
    }
  }
`;

export const FormDiv = styled.div`
  padding: 24px 44px 128px 32px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div:first-child {
    display: flex;
    align-items: center;
    gap: 1rem;

    & > label {
      position: relative;
      color: ${(props) => props.theme.colors.iconsPrimary};
      font-size: 20px;
      font-weight: 700;
      font-family: "Lexend";
    }
  }
`;

export const InputDivNotEditable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  input {
    background-color: ${(props) => props.theme.colors.inputBlock} !important;
    color: ${(props) => props.theme.colors.iconsSecondary};
    border: none !important;
  }
`;
export const InputDivEditable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  input {
    height: 36px;
    /* border: 1px solid ${(props) => props.theme.colors.background}; */
    color: ${(props) => props.theme.colors.iconsSecondary};
    background-color: ${(props) =>
      props.theme.colors.inputBackground} !important;
  }
`;
